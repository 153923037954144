@import '../../_variables.scss';

.box {
  background-color: #414141;
  display: flex;
  padding: 0 20px;
  margin: 16px 0;
}

.broadcastHeader {
  margin: 10px 0px 10px;
  flex-flow: column;
  justify-content: space-between;
  width: 100%;
  padding: 20px 20px 10px 20px;

  .headerText {
    font-size: 16px;
    font-weight: bold;
    margin: 0px 0px 8px;
    color: #fff;
    align-self: center;
  }

  .otherInfoText {
    margin: 8px 0;
    display: flex;
    color: rgb(234, 234, 234);
    font-family: 'IBMPlexMono-Regular';
    font-size: 12px;

    .icon {
      width: 24px;
      margin-right: 10px;
      text-align: center;
    }

    .text {
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 400px;
      /* height: 1.2em; */
      white-space: nowrap;
    }

    .match-id-icon {
      font-size: 1.3em;
      // Undo larger font size to avoid larger margin to the following element
      line-height: 0.77em;
    }
  }

  .bottomContent {
    align-self: center;
    margin-top: 14px;

    button {
      border-radius: 20px;
      padding: 10px 20px;
    }
  }

  .logoTimer {
    margin: 10px 0;
    display: flex;
    align-items: center;
    font-family: 'DegularText-Regular';
    justify-content: center;
    margin: 10px 0;
    font-size: 18px;

    img {
      height: 70px;
      // background: #00000029;
      // padding: 6px;
      // border: 1px solid #00000021;
      // border-radius: 10px;
    }
  }

  @media (min-width: 1000px) {
    .logoTimer {
      position: absolute;
      right: 80px;
      top: 66px;

      display: flex;
      align-items: center;

      img {
        height: 110px;
        background: #00000029;
        padding: 6px;
        border: 1px solid #00000021;
        border-radius: 10px;
      }
    }
  }
}

.import-actions {
  padding-top: 20px;
  padding-bottom: 20px;
  flex-direction: column;
  gap: 20px;
}

.linkContainer {
  place-content: space-between;
  align-items: center;
  white-space: nowrap;

  .text {
    font-family: 'IBMPlexMono-Regular';
    font-size: 16px;
  }
}

.eventDetail-group {
  margin: -14px 0 0px !important;

  .eventDetail-slider {
    .mbsc-input-wrap {
      margin: 0 -8px;
    }

    .mbsc-progress-track {
      background: #747474 !important;
    }
  }

  .eventDetail-minutes {
    font-family: 'DegularText-Regular';
    margin: -22px 0 0;
    color: #fff;
    font-size: 14px;
    padding: 0 1.2em;
  }
}

.duration-btnList {
  padding-left: 8px;
  margin-top: 14px !important;
  margin-bottom: 50px;

  .activeBtn {
    background: #fff !important;
    border-radius: 100px !important;
    color: rgb(18, 18, 18) !important;
    padding: 10px 14px !important;
    border: 0px !important;
  }

  .Btn {
    border-radius: 100px !important;
    color: #fff !important;
    padding: 10px 14px !important;
    border: 1px solid #fff !important;
  }
}

.treelistWrapper {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  ::-webkit-scrollbar:vertical {
    width: 12px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    border: 2px solid #525252;
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #525252;
  }

  input {
    width: 100%;
    color: #d1d1d1 !important;
    background: #414141;
    padding: 8px;
    margin: 4px 0 !important;
    border: 0;
    font-family: DegularText-Regular;
  }

  input:focus-visible {
    outline: none;
  }

  label {
    font-size: 0.75em;
  }
}

.info-container {
  margin: -20px 1em 20px 1em;
  width: 100%;

  .text {
    font-size: 10px;
  }
}

.sport-wrapper {
  display: flex;
  padding-bottom: 22px;
  overflow-y: hidden;
  margin: 6px 1em 6px 1em;
  font-family: 'DegularText-Regular';

  .sport-icon {
    border: 1px solid #fff;
    min-width: 76px;
    max-width: 76px;
    height: 76px;
    padding: 8px;
    text-overflow: ellipsis;
    margin: 0px 6px;
    font-size: 10px;
  }

  .sport-icon:nth-child(1) {
    margin: 0 6px 0 0;
  }

  .sport-icon:last-child {
    margin: 0 0 0 6px;
  }

  .active {
    color: #000;
    background: #fff;
  }
}

.contractInfo-field {
  text-align: center;
  margin: 4px -20px 0px;
  background: rgba(223, 112, 112, 0.38);
}

.contractInfo-field-font {
  font-size: 28px;
}


////////////// NEW
.page-event {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  margin-bottom: 20px;

  .event-content {
    display: flex;
    max-width: 674px;
    width: 100%;
    padding-top: 24px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;

    .content-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      flex: 1 0 0;
      align-self: stretch;

      .box-headerAf {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        align-self: stretch;

        .teams-top {
          display: flex;
          align-items: center;
          gap: 16px;
          align-self: stretch;

          .top-logos {
            display: flex;
            height: 64px;
            align-items: center;
            gap: -16px;
            flex: 1 0 0;

            .club-logo {
              display: flex;
              width: 64px;
              height: 64px;
              padding: 10px;
              justify-content: center;
              align-items: center;
              border-radius: 100px;
              // background: #C1C1FE;
              position: relative;

              img {
                height: 80%;
                width: 80%;
                z-index: 2;
              }

              .bg {
                height: 100%;
                width: 100%;
                position: absolute;
                border-radius: 100px;
                z-index: 1;
                opacity: 0.2;
                border: 2px solid var(--neutral-n-800, #151315);
              }

              .bg2 {
                background: #121212;
                height: 100%;
                width: 100%;
                position: absolute;
                border-radius: 100px;
                z-index: 2;
              }
            }
          }

          .top-quickInfo {
            display: flex;
            align-items: center;
            gap: 12px;
            // flex-direction: column;

            .quickInfo-box {
              display: flex;
              align-items: center;
              gap: 4px;
              justify-content: flex-end;

              img {
                width: 14px;
                height: 14px;
              }

              .box-title {
                color: var(--neutral-n-100, #F6F6F6);
                font-family: Nekst;
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: 14px; /* 140% */
                letter-spacing: 0.3px;
                text-transform: uppercase;
              }
            }
          }
        }

        .teams-title {
          align-self: stretch;
          color: var(--neutral-n-100, #F6F6F6);
          font-family: Nekst;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 30px; /* 125% */
          letter-spacing: 0.4px;
          text-transform: uppercase;
          text-wrap: nowrap;

        }

        .teams-label {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          gap: 4px;
          align-self: stretch;
          padding-top: 4px;
        }
      }

      .box-header {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
      }

      .box-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;

        
        .content-shareAf {
          border-radius: 4px;
          border: 1px solid var(--neutral-n-600, #363436);
          background: var(--neutral-n-700, #201D20);
        }

        .content-share {
          display: flex;
          padding: 12px 12px 12px 16px;
          align-items: center;
          gap: 16px;
          align-self: stretch;
          border-bottom: 1px solid var(--neutral-n-600, #363436);
          background: var(--neutral-n-700, #201D20);

          .share-left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 8px;
            flex: 1 0 0;

            .left-freeCard {
              color: var(--neutral-n-400, #999);
              /* Link/L-S */
              font-family: Nekst;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 100%; /* 14px */
              text-decoration-line: underline;
              cursor: pointer;
            }
          }

          .share-quickBtn {
            display: flex;
            align-items: center;
            gap: 8px;
            overflow-y: auto;
            padding: 0 0 0 4px;
          }

          .share-quickBtn::-webkit-scrollbar {
            display: none;
          }
          
          /* Hide scrollbar for IE, Edge and Firefox */
          .share-quickBtn {
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
          }
        }

        .content-preview {
          display: flex;
          padding: 24px 16px;
          justify-content: center;
          align-items: flex-start;
          align-self: stretch;
          background: var(--neutral-n-700, #201D20);

          .preview-club {
            display: flex;
            width: 152px;
            padding: 0px 8px;
            flex-direction: column;
            align-items: center;
            gap: 8px;

            .club-logo {
              display: flex;
              width: 64px;
              height: 64px;
              padding: 10px;
              justify-content: center;
              align-items: center;
              border-radius: 100px;
              border: 2px solid var(--neutral-n-0, #FFF);
              // background: #C1C1FE;
              position: relative;

              img {
                height: 100%;
                width: 100%;
                z-index: 2;
              }

              .bg {
                background: aquamarine;
                height: 100%;
                width: 100%;
                position: absolute;
                border-radius: 100px;
                z-index: 1;
                opacity: 0.2;
              }
            }

            .club-name {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              align-self: stretch;
              overflow: hidden;
              color: var(--neutral-n-100, #F6F6F6);
              text-align: center;
              text-overflow: ellipsis;
              /* Paragraph/P-S - Medium */
              font-family: Nekst;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 19.6px; /* 140% */
            }
          }

          .preview-score {
            display: flex;
            height: 90px;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            .score-time {
              color: var(--neutral-n-400, #999);
              text-align: center;
              /* Paragraph/P-XS - Regular */
              font-family: Nekst;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 16.8px; /* 140% */
            }

            .score-result {
              color: var(--neutral-n-100, #F6F6F6);
              text-align: center;
              font-family: Nekst;
              font-size: 42px;
              font-style: normal;
              font-weight: 500;
              line-height: 46px; /* 109.524% */
              text-transform: uppercase;
              text-wrap: nowrap;
            }

            .score-badges {
              display: flex;
              padding-top: 8px;
              justify-content: center;
              align-items: center;
              gap: 4px;
              align-self: stretch;
            }
          }
        }

        .content-player {
          align-self: stretch;
        }

        .content-stopRec {
          margin-top: 8px;
          width: 100%;
        }

        .content-mediaInfo {
          margin-top: 8px;
          display: flex;
          align-items: flex-start;
          gap: 8px;
          align-self: stretch;
          flex-direction: row;

          .mediaInfo-box {
            display: flex;
            padding: 16px;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
            flex: 1 0 0;
            align-self: stretch;
            background: var(--neutral-n-700, #201D20);
            overflow: auto;

            .box-bottomLine {
              background: #363436;
              height: 1px;
              width: 100%;
            }

            .box-bottomLine:last-child {
              display: none;
            }

            .box-title {
              // flex: 1 0 0;
              color: var(--neutral-n-400, #999);
              /* Overline/O01 */
              font-family: Nekst;
              font-size: 10px;
              font-style: normal;
              font-weight: 600;
              line-height: 14px; /* 140% */
              letter-spacing: 0.3px;
              text-transform: uppercase;
            }

            .box-adItem {
              display: flex;
              align-items: center;
              gap: 12px;
              align-self: stretch;
              border-radius: 4px;

              .adItem-img {
                display: flex;
                width: 40px;
                height: 40px;
                align-items: center;
                border-radius: 4px;
                background: var(--Neutral-N600, #363436);

                img {
                  height: 100%;
                  width: 100%;
                  object-fit: cover;
                }
              }

              .adItem-content {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                flex: 1 0 0;
                align-self: stretch;
                flex: 1;
                overflow: hidden;
                
                .content-title {
                  align-self: stretch;
                  color: var(--Neutral-N100, #F6F6F6);
                  /* Paragraph/P-S - Medium */
                  font-family: Nekst;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 19.6px; /* 140% */

                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                }

                .content-info {
                  display: flex;
                  align-items: center;
                  gap: 8px;

                  .banner-containerText {
                    display: flex;
                    align-items: center;
                    gap: 4px;
      
                    .containerText-icon {
                      display: flex;
                      filter: invert(42%) sepia(0%) saturate(1352%) hue-rotate(87deg) brightness(119%) contrast(44%);
                      width: 12px;
                      height: 12px;
                    }
      
                    .containerText-text {
                      color: var(--neutral-n-400, #999);
                      font-family: Nekst;
                      font-size: 10px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 14px; /* 140% */
                      letter-spacing: 0.3px;
                      text-transform: uppercase;
                    }
                  }
                }
              }
            }

            .box-textBox{
              display: flex;
              align-items: center;
              gap: 4px;
              width: 100%;

              .textBox-icon {
                width: 12px;
                height: 12px;
                line-height: 0px;
              }

              .textBox-text {
                color: var(--neutral-n-100, #F6F6F6);
                font-family: Nekst;
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: 14px; /* 140% */
                letter-spacing: 0.3px;
                text-transform: uppercase;
                text-overflow: ellipsis;
                overflow: hidden;
                /* width: 100%; */
                /* height: 1.2em; */
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }
}


@media screen and (min-width: $breakpoints-mobile) {
  .content-box {
    .box-content {
      .content-stopRec {
        display: none;
      }
    }
  }
}

@media screen and (max-width: $breakpoints-mobile) {

  .page-event {
    padding:0 0 16px
  }

  .event-content {
    padding-top: 12px !important;
  }

  .content-box {
    .box-headerAf {
      .teams-top {
        flex-direction: column-reverse;

        .top-logos {
          width: 100%;
          justify-content: flex-start;
        }

        .top-quickInfo {
          width: 100%;
          justify-content: flex-end;
        }
      }
    }

    .box-content {
      .content-preview {
        .preview-score {
          .score-result {
            font-size: 20px !important;
          }
        }
      }
    
    
      .content-mediaInfo {
        flex-direction: column !important;
      }

      .content-share {
        .share-quickBtn {
          flex-flow: row-reverse;

          .quickBtn-stopRec {
            display: none;
          }
        }
      }
    }
  }
}