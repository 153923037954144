.grid-sidebar {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  height: 100vh;
  overflow: hidden;

  border-right: 1px solid var(--neutral-n-600, #363436);
  background: var(--neutral-n-700, #201D20);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.20), 0px 2px 4px 0px rgba(0, 0, 0, 0.28);
  margin-left: -1px;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  ::-webkit-scrollbar:vertical {
    width: 8px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    border: 2px solid #525252;
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #525252;
  }

  .sidebar-logo {
    display: flex;
    padding: 8px 8px 0px 8px;
    flex-direction: row;
    align-items: flex-start;
    align-self: stretch;
    gap: 4px;
  }

  .sidebar-contract {
    display: flex;
    padding: 21px 0px 16px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    border-bottom: 1px solid var(--neutral-n-600, #363436);

    max-height: 100%;
    overflow: hidden;

    .contract-switch {
      display: flex;
      width: 168px;
      padding: 8px;
      align-items: center;
      gap: 8px;
      border-radius: 4px;
      border: 1px solid var(--neutral-n-600, #363436);
      cursor: pointer;

      .switch-logo {
        display: flex;
        width: 24px;
        height: 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex-shrink: 0;
        border-radius: 100px;
        background: var(--neutral-n-600, #363436);

        img {
          width: 70%;
          height: 70%;
        }
      }

      .switch-dropdown {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1 0 0;

        .dropdown-title {
          flex: 1 0 0;
          color: var(--neutral-n-400, #999);
          /* Overline/O01 */
          font-family: Nekst;
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
          line-height: 14px; /* 140% */
          letter-spacing: 0.3px;
          text-transform: uppercase;

          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          img {
            display: none;
            width: 14px;
          }
        }

        .dropdown-titleActive {
          img {
            display: unset;
            transform: rotate(180deg);
          }
        }

        .dropdown-subTitle {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          align-self: stretch;
          overflow: hidden;
          color: var(--neutral-n-100, #F6F6F6);
          text-overflow: ellipsis;
          font-family: Nekst;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16.8px;
        }
      }
    }

    .contract-switch-disabled {
      cursor: default !important;
    }
    .contract-switch-disabled:hover {
      background: transparent !important
    }

    .contract-switch:hover {
      background: #363636;

      .switch-dropdown {
        .dropdown-title {
          img {
            display: unset;
          }
        }
      }
    }

    .contract-list {
      display: flex;
      width: 168px;
      align-items: center;
      gap: 8px;
      border-radius: 4px;
      border: 1px solid var(--neutral-n-600, #363436);
      flex-direction: column;
      max-height: 100%;
      overflow: auto;

      .list-item:hover {
        background: var(--neutral-n-600, #363436);
      }

      .list-item {
        width: 100%;
        display: flex;
        padding: 6px 4px;
        align-items: center;
        gap: 8px;
        align-self: stretch;

        .item-logo {
          display: flex;
          width: 20px;
          height: 20px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 100px;
          background: var(--neutral-n-600, #363436);

          img {
            height: 100%;
            width: 100%;
          }
        }

        .item-title {
          flex: 1 0 0;
          color: var(--neutral-n-100, #F6F6F6);
          font-family: Nekst;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 19.6px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          cursor: pointer;
        }
      }
    }
  }

  .sidebar-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;

    .wrap-mainMenu {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      flex: 1 0 0;
      align-self: stretch;
    }

    .wrap-secondMenu {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      align-self: stretch;
    }

    .app-version {
      display: flex;
      font-size: 10px;
      width: 100%;
      color: rgb(64,61,64);
      justify-content: center;
    }
  }
}



.sidebar-menu-item {
  display: flex;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 6px;

  .item-highlight {
    width: 4px;
    height: 30px;
    border-radius: 6px;
  }

  .item-highlight-active {
    background: var(--orange-o-400, #FF3517);
  }

  a {
    width: 100%;
    text-decoration: none !important;
  }

  .item-wrap {
    display: flex;
    padding: 10px;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    width: 100%;

    background-color: transparent;
    border: 0;
    cursor: pointer;

    border-radius: 4px;
    // background: var(--neutral-n-600, #363436);

    img {
      width: 18px;
      height: 18px;
      filter: invert(0.4);
    }

    .imgActive {
      filter: invert(0);
    }

    .wrap-text {
      color: var(--neutral-n-100, #F6F6F6);
      font-family: Nekst;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 19.6px; /* 140% */
    }
  }

  .item-wrap:hover {
    background: var(--neutral-n-600, #363436);
  }

  .item-wrap:disabled {
    cursor: unset;
    img {
      filter: invert(0.8);;
    }
    .wrap-text {
      color: var(--neutral-n-50, rgba(246, 246, 246, 0.16));
    }
  }

  .item-wrap:disabled:hover {
    background: transparent
  }

  .itme-wrap-active {
    background: var(--neutral-n-600, #363436);
  }
}





.sidebar-menu-dropdown {
  display: flex;
  width: 168px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--neutral-n-600, #363436);
  flex-direction: column;
  max-height: 100%;
  overflow: auto;

  .dropdown-item {
    width: 100%;
    display: flex;
    padding: 8px 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    .item-title {
      flex: 1 0;
      color: var(--neutral-n-100, #F6F6F6);
      font-family: Nekst;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 19.6px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      cursor: pointer;
    }
  }

  .dropdown-item:hover {
    background: var(--neutral-n-600, #363436);
  }
}