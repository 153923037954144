@import '../../../_variables.scss';

.calendar-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 44px;

  .header-leftBar {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    .leftBar-loader {
      width: 10px;
      margin-left: 16px;
    }
  }

  .header-buttonBar {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;

    .buttonBar-days {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-around;
      align-items: center;
    }
  }
}

.calendar-filterBar {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  background-color: #121212;
  padding: 8px 0;
}

.progressbar {
  width: 100px;
  height: 3px;
  margin-top: -3px;

  div:after {
    content: "";
    /* width: 40px; */
    height: 2px;
    background: #FF3517;
      
    position: absolute;
    animation: loader 2s;
    -webkit-animation: loader 2s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    transition-timing-function: linear;
    -webkit-transition-timing-function: linear;
    bottom: 0px;
      
    margin-left: 0;
  }
}


@keyframes loader {
    0% {width: 0%;left:0;right:0}
    50% { width: 100%;left:0;right:0} 
    99% { width: 0%;left:100%;right:0} 
}

@-webkit-keyframes loader {
    0% {width: 0%;left:0;right:0}
    50% { width: 100%;left:0;right:0} 
    99% { width: 0%;left:100%;right:0} 
}


@media screen and (max-width: $breakpoints-mobile) {
  .calendar-filterBar {
    display: none;
  }
}