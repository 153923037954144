@import '../../../_variables.scss';

.modeContainert {
  height: 100%;
  display: flex;
  padding: 32px 16px 16px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex: 1 0 0;
  align-self: stretch;

  .infoText {
    flex: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // flex: 1 0 0;
    color: var(--neutral-n-400, #999);
    text-align: center;
    /* Paragraph/P-S - Regular */
    font-family: Nekst;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.6px;
  }
  
  .modeBox {
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 4px;
    background: var(--neutral-n-600, #363436);

    .modeBox-top {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      align-self: stretch;
    
      .modeBox-top-inner {
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;
    
        .inner-name {
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex: 1 0 0;
          color: var(--neutral-n-400, #999);
          /* Overline/O01 */
          font-family: Nekst;
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
          line-height: 14px;
          letter-spacing: 0.3px;
          text-transform: uppercase;
        }
    
        .inner-icon {
          width: 24px;
          height: 24px;

          img {
            height: 100%
          }
        }
      }
    }
        
    .modeBox-bottomInfoText {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-self: stretch;
      color: var(--neutral-n-400, #999);
      font-family: Nekst;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      padding: 4px 0;
    }

    .modeBox-top-infoText {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-self: stretch;
      color: var(--neutral-n-100, #F6F6F6);
      /* Paragraph/P-M - Regular */
      font-family: Nekst;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22.4px;
    }

    .modeBox-bottom {
      display: flex;
      justify-content: center;
      align-items: center;
      // flex: 1 0 0;
      border: 1px solid var(--neutral-n-100, #F6F6F6);
    }

    
    // large square
    .staige-button-large {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border: 1px solid var(--neutral-n-100, #F6F6F6);
      background: transparent;
      width: 100%;
      cursor: pointer;

      .button-title {
        display: flex;
        padding: 10px 14px;
        justify-content: center;
        align-items: center;
        gap: 4px;

        .title {
          color: var(--neutral-n-100, #F6F6F6);
          /* Button/Button L */
          font-family: Nekst;
          font-size: 14px;
          font-style: normal;
          font-weight: 900;
          line-height: 100%;
          letter-spacing: 0.6px;
          text-transform: uppercase;
        }
      }
      .large {
        color: aqua;
      }
    }

    .staige-button-large:hover {
      background: var(--neutral-n-50, rgba(246, 246, 246, 0.16));
    }

    .staige-button-large:active {
      border: 1px solid var(--neutral-n-200, #D6D6D6);
      background: var(--neutral-n-50, rgba(246, 246, 246, 0.16));
    }

    .staige-button-large:disabled {
      cursor: unset;
      background: transparent;
      border: 1px solid var(--neutral-n-50, rgba(246, 246, 246, 0.16));

      .title {
        color: var(--neutral-n-50, rgba(246, 246, 246, 0.16));
      }
    }

    .staige-button-large-active {
      border: 1px solid var(--neutral-n-200, #D6D6D6);
      background: var(--neutral-n-50, rgba(246, 246, 246, 0.16));
    }
  }
}


@media screen and (max-width: $breakpoints-mobile) {
  .modeContainert {
    // padding: 32px 16px 16px 16px;
    padding: 10px 16px 16px 16px !important;
    gap: 20px !important;
  
    .infoText {
      font-size: 14px;
    }
    
    .modeBox {
      padding: 16px;
  
      .modeBox-top {
        gap: 14px !important;
      
        .modeBox-top-inner {
          gap: 8px;
      
          .inner-name {
            font-size: 10px;
          }
      
          .inner-icon {
            width: 16px !important;
            height: 16px !important;
          }
        }
      
        .modeBox-top-infoText {
          font-size: 14px !important;
        }
      }
    }
  }

  .button-title {
    padding: 6px 14px !important;
  }
}