@import '../../_variables.scss';

.page-sponsors {
  display: flex;
  padding: 40px 0px 20px;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;

  .sponsors-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;

    .content-btn {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      padding-bottom: 16px;
      border-bottom: 1px solid var(--Neutral-N600, #363436);
    }

    .content-sponsorList {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;

      .no-ads {
        width: 100%;
        display: flex;
        justify-content: center;
      }

      .sponsorList-item {
        display: flex;
        width: 100%;
        height: 138px;
        padding: 16px;
        align-items: center;
        gap: 24px;
        border-radius: 4px;
        background: var(--neutral-n-700, #201D20);
        color: inherit;
        text-decoration: none;

        .item-img {
          display: flex;
          width: 240px;
          min-height: 106px;
          padding: 0px 16px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          // align-self: stretch;
          background: #2d2d2d;
          pointer-events: none;


          .img-wrapper {
            display: flex;
            flex: 1 0 0;
            // align-self: stretch;
            // background: url(<path-to-image>), lightgray 50% / contain no-repeat;
            box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12), 0px 1px 16px 0px rgba(0, 0, 0, 0.08);
            object-fit: cover;
            width: 100%;
            height: 100%;
           
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        .item-content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          flex: 1 0 0;
          align-self: stretch;

            .content {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              flex: 1 0 0;
              align-self: stretch;

              .content-createX {
                display: flex;
                padding-bottom: 0px;
                align-items: center;
                gap: 8px;
                align-self: stretch;

                .create-activeWrapper {
                  display: none;
                }

                .content-createL {
                  display: none;
                }
  
                .content-create {
                  color: var(--neutral-n-500, #525052);
                  /* Overline/O01 */
                  font-family: Nekst;
                  font-size: 10px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 14px; /* 140% */
                  letter-spacing: 0.3px;
                  text-transform: uppercase;
                }   
              }

              .content-name {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                align-self: stretch;
                overflow: hidden;
                color: var(--neutral-n-100, #F6F6F6);
                text-overflow: ellipsis;
                /* Paragraph/P-L - Medium */
                font-family: Nekst;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 25.2px; /* 140% */
              }

              .content-comment {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                align-self: stretch;
                overflow: hidden;
                color: var(--neutral-n-400, #999);
                text-overflow: ellipsis;
                /* Paragraph/P-S - Regular */
                font-family: Nekst;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 19.6px; /* 140% */
              }
            }
          

          .content-banner {
            display: flex;
            align-items: center;
            gap: 8px;

            .banner-containerText {
              display: flex;
              align-items: center;
              gap: 4px;

              .containerText-icon {
                display: flex;
                filter: invert(42%) sepia(0%) saturate(1352%) hue-rotate(87deg) brightness(119%) contrast(44%);
                width: 12px;
                height: 12px;
              }

              .containerText-text {
                color: var(--neutral-n-400, #999);
                font-family: Nekst;
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: 14px; /* 140% */
                letter-spacing: 0.3px;
                text-transform: uppercase;
              }
            }
          }

        }

        .item-action {
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoints-mobile) {
  .page-sponsors {
    padding: 18px 0px 20px;

    .sponsors-content {
      .content-btn {
        display: none;
      }

      .content-sponsorList {
        .sponsorList-item {
          flex-direction: column;
          height: unset;
          padding: 0;
          gap: 0;

          .item-img {
            display: flex;
            width: 100%;
            height: 106px;
            padding: 0px 16px;
            border-radius: 4px 4px 0 0;
            pointer-events: unset;

            .img-wrapper {
              box-shadow: unset;

              img {
                object-fit: contain;
              }
            } 
          }

          .item-content {
            padding: 16px;

            .content {
              .content-createX {
                display: flex;
                padding-bottom: 0px;
                align-items: center;
                gap: 8px;
                align-self: stretch;

                .create-activeWrapper {
                  display: unset;
                  display: flex;
                  align-items: center;
                  gap: 4px;

                  .activeWrapper-dot {
                    background: var(--neutral-n-400, #999);
                    width: 6px;
                    height: 6px;
                    border-radius: 100%;
                  }

                  .activeWrapper-text {
                    color: var(--neutral-n-400, #999);
                    font-family: Nekst;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 14px; /* 140% */
                    letter-spacing: 0.3px;
                    text-transform: uppercase;
                  }
                }

                .create-activeWrapperActive {
                  .activeWrapper-dot {
                    background: var(--green-g-400, #26BD83);
                  }

                  .activeWrapper-text {
                    color: var(--green-g-400, #26BD83);
                  }
                }

                .content-createL {
                  display: unset;
                }
              }
            }
    
              
            .content-banner {
              padding-top: 12px;
            }
          }

          .item-action {
            display: none;
          }
        }
      }
    }
  }
}
  

  

  
