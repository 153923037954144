@import '../../_variables.scss';

.tag-clubContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  margin: 10px 0 0;

  .clubContainer-club {
    display: flex;
    flex-direction: row; 
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 1 1 0px;
    
    .club-home {
      flex-direction: column;
      align-items: flex-end;
      text-align: left;
      overflow: hidden;

      .home-title {
        color: #dddddd;
      }

      .home-name {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: bold;
      }
    }

    .club-home-desk {
      font-size: 15px;
    }

    .club-guest {
      flex-direction: column;
      align-items: flex-end;
      text-align: right;
      overflow: hidden;

      .guest-title {
        color: #dddddd;
      }

      .guest-name {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: bold;
      }
    }

    .club-guest-desk {
      font-size: 15px;
    }

    .club-logo {
      display: flex;
      align-items: center;

      img {
        height: 26px;
        width: 26px;
      }

      .left {
        margin: 0 6px 0 0
      }

      .right {
        margin: 0 0 0 6px
      }
    }

    .club-logo-desk {
      img {
        height: 42px;
        width: 42px;
      }
    }
  }
}

.tag-btnContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-top: -6px;
  
  .mbsc-mobiscroll .mbsc-switch-handle {
    top: 0.65em !important;
  }
  .mbsc-mobiscroll.mbsc-ltr.mbsc-switch {
    padding: 0em 1em 0em 2em;
  }
}

.listIcon {
  img {
    height: 22px;
    margin: 8px 8px 0px  14px;
    cursor: pointer;
  }
}

.listIcon-desk {
  img {
    height: 30px;
    margin: 8px 8px 0px  18px;
  }
}

.tag-buttonContainer {
  height: calc(100vh - 388px);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  background: #dbdbdb;

  .buttonContainer-line {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1 1 0px;
    padding: 1px 0px;

    button {
      text-transform: uppercase;
      width: 100%;
      height: 100%;
      border: 0;
      background: white;
      margin: 1px;
      display: flex;
      align-items: center;
      font-weight: bold;
    }

    button:active {
      background: rgb(219, 219, 219);

      .line-number {
        color: #b1b1b1;
      }
    }

    .single-button {
      justify-content: center;
      font-size: 2.4vh;
      cursor: pointer;
    }

    .tow-button {
      justify-content: space-between;
      padding: 0 18px 0 4px;
      min-width: 50%;
      cursor: pointer;

      .line-text {
        // height: 100%;
        // display: flex;
        align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0 6px;
        font-size: 2vh;
      }

      
      .line-text-desk {
        font-size: 1.5vh;
      }

      .line-number {
        display: flex;
        align-items: flex-end;
        color: #dddddd;
        font-size: 4vh;
      }

      .line-number-desk {
        font-size: 3vh;
      }
    }

    .tow-button:nth-child(2) {
      flex-direction: row-reverse;
      padding: 0 14px 0 18px;

      
      .line-text {
        margin: 0 -4px 0 6px;
      }
    }

    .four-button {
      flex-direction: column;
      justify-content: space-between;
      padding: 0 24px 0 14px;
      font-size: 10px;
      padding: 0;
      // min-width: 25%;
      cursor: pointer;

      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;

      .line-text {
        height: 100%;
        display: flex;
        align-items: center;
        width: 90%;
        font-size: 1.6vh;
      }

      .line-number {
        font-size: 2vh;
        color: #dddddd;
        margin-bottom: -2px;
      }
    }

    .four-button:nth-child(3) {      
      .line-text {
        flex-direction: row-reverse;
      }
    }

    .four-button:nth-child(4) {      
      .line-text {
        flex-direction: row-reverse;
      }
    }
  }
}

.tag-overlay-desk {
  position: fixed !important;
  top: 0;
  left: 20%;
  right: 20%;
  bottom: 0;
  z-index: 2000;
  background: #121212f2;

  border-right: 1px solid #2e2e2e;
  border-left: 1px solid #2e2e2e;
  // height: calc(100% - 86px) !important;
}

.tag-overlay-desk > div {
  padding: 0 14px;
}

.tag-overlay-mobile {
  position: fixed !important;
  top: 0;
  left: 0%;
  right: 0%;
  bottom: 0;
  z-index: 2000;
  background: #121212f2;

  border-right: 1px solid #2e2e2e;
  border-left: 1px solid #2e2e2e;
  // height: calc(100% - 86px) !important;
}

.tag-overlay {
  width: 100%;

  .overlay-container {
    width: 100%;
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 4px;
    background: var(--neutral-n-600, #363436);

    /* Shadow-16 */
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.20), 0px 8px 16px 0px rgba(0, 0, 0, 0.28);

    .insert-timeslider {

      .mbsc-mobiscroll.mbsc-progress {
        padding: 1em 0 !important;
      }

      .slider {

        .title {
          display: flex;
          flex-direction: row;
          justify-content: space-between;


          flex: 1 0 0;
          color: var(--neutral-n-400, #999);
          /* Paragraph/P-XS - Regular */
          font-family: Nekst;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16.8px;
        }

        .mbsc-input-wrap {
          padding: 0px !important;
        }  
      }
    }

    .container-insert {
      width: 100%;
      gap: 12px;
      display: flex;
      flex-direction: column;

      .img-upload{
        margin-top: 0 !important;
        
        .mbsc-input {
          margin: 0 0 !important;
        }
      
        .mbsc-ic-upload {
          margin-top: 0 !important;
          margin-right: 8px !important;
        }

        .uplaod-title {
          display: flex;
          flex-direction: column;
          flex: 1 0 0;
          color: var(--neutral-n-400, #999);
          /* Paragraph/P-XS - Regular */
          font-family: Nekst;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16.8px;
        }
      }

      .insert-exampleBox {
        aspect-ratio: 16 / 9;
        background-color: rgb(209, 209, 209);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;

        .exampleBox-center {
          background: #7a95f5;
          height: 90%;
          aspect-ratio: 16 / 9;
        }

        .exampleBox-bottom {
          background: #7a95f5;
          width: 90%;
          height: 30%;
        }
      }

      .insert-title-btn {
        display: flex;
        margin-bottom: -12px;

        span {
          display: flex;
          flex-direction: column;
          flex: 1 0 0;
          color: var(--neutral-n-400, #999);
          /* Paragraph/P-XS - Regular */
          font-family: Nekst;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16.8px;
        }
      }

      .staige-button-large {
        width: 100%;
        height: auto;
        display: flex;
        padding: 4px !important;
        justify-content: center;
        border: none;
        outline: none;
      }

      @media screen and (min-width: $breakpoints-mobile) {
        .insert-button {
          position: absolute;
          border: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }

      .insert-exampleBtnContainer {
        .exampleBtnContainer {
          display: flex;
          gap: 4px;
          align-items: center;
          margin: 8px 0;

          .staige-button-large {
            width: 100%;
            height: auto;
            display: flex;
            padding: 4px !important;
            justify-content: center;
            border: none;
            outline: none;
          }

    
          .button-title {
            padding: 6px 14px !important;

            div {
              font-size: 10px !important;
            }
          }
        }

        .underTitle {
          display: flex;
          align-items: center;
          justify-content: space-around;
          font-size: 10px;
          margin-top: -6px;

          .disabled {
            color: #878787;
          }
        }

        .title {
          // margin-bottom: 8px;

          span {
            display: flex;
            flex-direction: column;
            flex: 1 0 0;
            color: var(--neutral-n-400, #999);
            /* Paragraph/P-XS - Regular */
            font-family: Nekst;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16.8px;
          }
        } 
      }
    }

    .container-tags {
      overflow: auto;
      height: calc(100% - 108px);

      .tags-noTags {
        text-align: center;
        height: calc(100vh - 160px);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .mbsc-lv-item.mbsc-lv-img-left {
        padding: 0
      }

      .mbsc-lv {
        background: transparent !important;
      }

      .mbsc-lv-stage-c-v {
        background-color: #df5c5c !important;
      }

      .mbsc-lv-img {
        position: inherit !important;
        max-height:  none !important;
        max-width:  none !important;
        top:  none !important;
        transform:  none !important;
    }

      .tags-tag {
        /*background: white;
        color: #000000;
        border-radius: 6px;
        margin: 10px 0;
        overflow: hidden;*/
        background: #2e2e2e;
        border-radius: 6px;
        // margin: 16px 0;
        overflow: hidden;

        .tag-cont {
          display: flex;
          justify-content: space-between;
          // flex-direction: row;
          flex-wrap: nowrap;
          width: 100%;
          flex-direction: inherit;

        
          .cont-tag {
            display: flex;
            // justify-content: space-between;
            flex-direction: row;
            min-height: 38px;
            font-size: 12px;

            .tag-teamColor {
              width: 10px;
              // opacity: 0.5;
              margin-right: 4px;

              display: flex;
              align-items: center;
              height: 100%;
              font-size: 8px;
              color: #ffffffc7;
              justify-content: center;
            }
    
            .tagCenter {
              align-self: center;
            }
          }
        }
      }
    }
  }

  .container-return {
    position: absolute;
    bottom: 6px;
    width: 100%;
    z-index: 1000;
  }
}

.tagHighlight-loading {
  .s-spinner {
    height: 10px;
    width: 10px;
    margin: 0px -10px 0 0;
  }
}

.highlight-icon {
  width: 54px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.streamname {
  display: none;
}




.crop-container {
  position: relative;
  height: 100%;
  width: 100%;
}


.lastTagContainer {
  position: relative;
  
  .lastTagContainer-box {
    position: absolute;
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #cdbd8a;
    color: #000;
    height: 38px;
    margin-top: 8px;
    justify-content: space-between;

    .box-box {
      display: flex;
      align-items: center;

      .box-timer {
        background: #00000026;
        height: 28px;
        width: 28px;
        margin: 10px;
        border-radius: 17px;
        text-align: center;
        padding-top: 3px;
        color: #e3e3e3;
      }
    }

    .box-removeBtn {
      margin-right: 10px;
      z-index: 100;
    }
  }
}


.timebar {
  .timebar-menu {
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .menu-timeIcon {
      font-size: 10px !important;
      margin: 0;
      padding: 3px 4px 1px;
    }
  }

  .timebar-menu-desk {
    font-size: 20px;

    .menu-timeIcon {
      font-size: 14px !important;
    }
  }

  .timebar-open {
    position: relative;
    z-index: 10000;

    .open-container {
      position: absolute;
      border-top: 1px solid;
      background: #121212;
      width: 100%;
      padding: 18px 8px;
      border-radius: 0 0 10px 10px;

      .container-title {
        font-size: 10px;
        margin-top: -10px;
        margin-bottom: 10px;
      }

      .container-buttons {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -8px;

        .buttons-list {
          padding: 6px 8px;
          flex: 50%;
          width: 100%;
        }
      }

      .container-buttons-desk {
        font-size: 19px;
      }
    }
  }
}

.timerBox {
  padding: 0;
  label {
    margin: 0 !important;
  }
}

.more-col {
  display: none !important
}

.desktopWrapper {
  display: flex;
  flex-direction: row-reverse;
  padding-top: 6px;
  height: calc(100% - 14px);
}

$variable: 800px;

.tagContainer {
  display: grid;
  grid-column-gap: 10px;
  // height: 100vh;
}

.waitContainer {
  display: grid;
  grid-column-gap: 10px;
  // height: 100vh;
}

@media screen and (min-width: $breakpoints-mobile) {
  .tagContainer {
    grid-template-columns: 3fr 360px;
    grid-template-rows: 1fr;
    padding: 10px;
  }

  .waitContainer {
    grid-template-columns: 3fr 375px;
    grid-template-rows: 1fr;
    padding: 10px;
  }
}

@media screen and (max-width: $breakpoints-mobile) {
  .tagContainer {
    grid-template-columns: 1fr;
    grid-template-rows: 240px 1fr;
  }

  .waitContainer {
    grid-template-columns: 1fr;
    grid-template-rows: 240px 1fr;
  }
}

.btn-container {
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: auto;
  // padding-bottom: 44px;
  
  .grid-double-btn {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 4px;
    grid-row-gap: 2px;
    margin: 4px 0;

    .timeout-btn {
      display: flex;
      height: 42px;
      padding: 0px 8px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      border-radius: 4px;
      background: var(--Orange-O400, #FF3517);
      border: 0;
      cursor: pointer;

      .tag-name {
        color: var(--Neutral-N100, #F6F6F6);
        text-align: center;
        /* Paragraph/P-L - Medium */
        font-family: Nekst;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 25.2px; /* 140% */
      }
    }

    .timeout-btn:hover {
      background: var(--Orange-O300, #FF8370);
    }

    .timeout-btn:focus {
      border: 1px solid var(--Orange-O200, #FFCAC2);
      background: var(--Orange-O400, #FF3517);
    }

    .timeout-btnDisabled {
      display: flex;
      height: 42px;
      padding: 0px 8px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 4px;
      border: 0;
      background: var(--Neutral-N500, #525052);
      cursor: default;

      .tag-name {
        color: var(--Neutral-N400, #999);
        text-align: center;
        /* Paragraph/P-L - Medium */
        font-family: Nekst;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 25.2px; /* 140% */
      }
    }

    .tag-btn {
      display: flex;
      padding: 4px 8px;
      flex-direction: column;
      align-items: center;
      align-self: stretch;
      border-radius: 4px;
      background: var(--neutral-n-600, #363436);
      // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      border: 0;
      cursor: pointer;

      .tag-name {
        display: flex;
        justify-content: space-between;
        flex: 1 0;
        color: var(--neutral-n-400, #999);
        font-size: 12px;
        font-family: Nekst;
        font-style: normal;
        font-weight: 400;
        line-height: 16.8px;
        flex-direction: row;
        width: 100%;
      }

      .tag-counter {
        color: var(--neutral-n-100, #F6F6F6);
        text-align: center;
        font-size: 54px;
        font-family: Nekst;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        text-transform: uppercase;
        margin-bottom: 12px;
      }
    }

    .tag-btn:hover {
      background: #4d4b4d;
    }

    .tag-btn:disabled {
      opacity: 0.4;
    }

    .tag-btn:hover:disabled{
      /*your styles*/
      background: var(--neutral-n-600, #363436) !important;
    }
    .tag-btn:active:disabled{
      /*your styles*/
      background: var(--neutral-n-600, #363436) !important;
    }
  }
}

.btn-container::-webkit-scrollbar {
  width: 0;
}


.event-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}


.container-switch {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  .switch-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
   
    height: 40px;
    text-transform: uppercase;
    cursor: pointer;
  }

  .inactive:hover {
    background: var(--neutral-n-50, rgba(246, 246, 246, 0.16));
  }

  .active {
    border-bottom: 2px solid var(--neutral-n-100, #F6F6F6);
    background: var(--neutral-n-700, #201D20);
    cursor: unset;
  }
}
.conatiner-content {
  position: relative;
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: stretch;
  flex: 1 0 0;
  align-self: stretch;

  background: var(--neutral-n-700, #201D20);
}

.content-top {
  gap: 8px;
  display: flex;
  flex-direction: column;
}


.content-bottom {
  position: relative;
  height: 100%;
}


// BUTTONS

.insert-button {
  cursor: pointer;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.insert-button:hover {
  background: var(--orange-o-500, #E01E00);
}

.insert-button:active {
  background: var(--orange-o-600, #A31600);
}

@media screen and (min-width: $breakpoints-mobile) {
  .insert-button {
    display: flex;
    padding: 10px 14px;
    justify-content: center;
    background: var(--orange-o-400, #FF3517);
    margin: 0px -8px -8px -8px;
    color: var(--neutral-n-100, #F6F6F6);
    font-size: 14px;
    font-family: Nekst;
    font-style: normal;
    font-weight: 900;
    line-height: 100%;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    z-index: 10;

    .icon {
      display: none;
    }
  }

  .mobile-eventlistBtn {
    display: none !important;
  }

  .insert-cropper {
    .reactEasyCrop_Container {
      position: absolute;
      z-index: 1000;
      bottom: 82px !important;
      max-width: 864px;
      margin-left: auto;
      margin-right: auto;
      top: 70% !important;
    }
  }
}

@media screen and (max-width: $breakpoints-mobile) {
  .insert-button {
    display: none !important;
    /*position: fixed;
    right:    0;
    bottom:   0;
    margin: 24px;
    border-radius: 100px;
    background: var(--orange-o-400, #FF3517);
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.20), 0px 4px 8px 0px rgba(0, 0, 0, 0.28);

    .icon {
      display: flex;
      padding: 16px;
    }

    .text {
      display: none;
    }*/
  }

  .container-switch {
    display: none !important;
  }

  .tagContainerOnlyBottom {
    grid-template-rows: 0 1fr;
  }

  .conatiner-content {
    background: transparent;
    padding: 4px !important;
  }

  .content-top {
    margin: -4px -4px 0px -4px;
    background: var(--neutral-n-700, #201D20);
    gap: 0;
  }

  .container-box {
    padding-bottom: 8px;
  }
  
  .insert-cropper {
    .reactEasyCrop_Container {
      background-color: tomato;
      position: absolute;
      z-index: 1000;
      bottom: 68px !important;
      max-width: 864px;
      margin-left: auto;
      margin-right: auto;
      top: 50% !important;
    }
  }
  
}

.content-container {
  display: grid;
  height: 100vh;
  grid-template-columns: 1fr;
  grid-template-rows: 50px 1fr;

  .topBar {
    display: flex;
    padding: 0px 16px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--neutral-n-600, #363436);
    background: var(--neutral-n-700, #201D20);

    .topBar-left {
      display: flex;
      align-items: center;
      gap: 4px;
      flex: 1 0 0;
      align-self: stretch;
      color: var(--neutral-n-100, #F6F6F6);
      /* Paragraph/P-M - Regular */
      font-size: 16px;
      font-family: Nekst;
      font-style: normal;
      font-weight: 400;

      a {
        color: var(--neutral-n-100, #F6F6F6);
        text-decoration: none;
      }
    }

    .topBar-right {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}


.smallIcon-button {
  display: flex;
  padding: 7px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--neutral-n-100, #F6F6F6);
  width: 32px;
  height: 32px;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.smallIcon-button:hover {
  background-color: #c7c7c730;
}

.smallIcon-buttonWarn:hover {
  background-color: #e92f2f30 !important;
}

.disabledOp {
  pointer-events: none;
}

.teamSiteMode {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  gap: 16px;

  .teamSiteMode-line {
    height: 8px;
    background: #121212;
    margin: 0 -8px;
  }

  .teamSiteMode-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 0 0;
    color: var(--neutral-n-400, #999);
    text-align: center;
    font-family: Nekst;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.8px;
  }

  .overlayTeamSite {
    position: relative !important;
    width: 100% !important;
    // height: auto;
    left: 0 !important;
    bottom: 0 !important;
    background: var(--neutral-n-600, #363436) !important;
  }
}


.insert-cropper-full {
  .reactEasyCrop_Container {
    position: absolute;
    z-index: 1000;
    bottom: 82px !important;
    max-width: 84% !important;
    margin-left: auto;
    margin-right: auto;
    top: 0 !important;
  }
}

.s-ai-warning {
  display: none !important;
}


