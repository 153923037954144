.staige-checkbox {
  display: flex;
  align-items: center;
  gap: 8px;

  input[type="checkbox"] {
    /* ...existing styles */
    display: grid;
    place-content: center;
    min-height: 16px;
    min-width: 16px;
    cursor: pointer;
    background: transparent;
    -webkit-appearance: none;
    border: 2px solid var(--neutral-n-100, #F6F6F6);
    border-radius: 4px;
  }
  
  input[type="checkbox"]::before {
    content: "";
    height: 8px;
    width: 8px;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  }
  
  input[type="checkbox"]:checked::before {
    transform: scale(1);
    background: var(--neutral-n-100, #F6F6F6);;
    // background-color: var(--orange-o-400, #FF3517);
  }
  
  label {
    color: var(--neutral-n-100, #F6F6F6);
    /* Controls/Text M */
    font-family: Nekst;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
  }
}