@import '../../_variables.scss';

.staige-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;

  .input-form {
    width: 100%;
    height: 32px;
  
    .input-icons {
      width: 100%;
  
      i {
        position: absolute;
      }
  
      .icon {
        height: 32px;
        width: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
  
        img {
          height: 18px;
          width: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
  
      .inputField {
        display: flex;
        padding: 6px 12px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 2px;
        background: var(--neutral-n-50, rgba(246, 246, 246, 0.16));
        border: 0;
        flex: 1 0 0;
        color: var(--neutral-n-100, #F6F6F6);
        /* Paragraph/P-S - Regular */
        font-family: Nekst;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 19.6px; /* 140% */
  
        padding-left: 38px;
        width: 100%;
      }
      .inputField:hover {
        outline: 1px solid;
      }
      .inputField:focus {
        outline: 1px solid;
      }
      .inputField:active {
        outline: 1px solid;
      }
      
      ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        color: var(--neutral-n-400, #999);
      }
      :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: var(--neutral-n-400, #999);
        opacity:  1;
      }
      ::-moz-placeholder { /* Mozilla Firefox 19+ */
        color: var(--neutral-n-400, #999);
        opacity:  1;
      }
      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: var(--neutral-n-400, #999);
      }
      ::-ms-input-placeholder { /* Microsoft Edge */
        color: var(--neutral-n-400, #999);
      }
      
      ::placeholder { /* Most modern browsers support this now. */
        color: var(--neutral-n-400, #999);
      }
    }
  }
  
  .input-label {
    color: var(--neutral-n-100, #F6F6F6);
    /* Paragraph/P-S - Medium */
    font-family: Nekst;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 19.6px; /* 140% */
    margin-bottom: 8px;
  }
  
  .input-helperText {
    align-self: stretch;
    color: var(--neutral-n-400, #999);
    /* Paragraph/P-XXS - Regular */
    font-family: Nekst;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 140% */
    margin-top: 4px;
  }

  .input-helperTextError {
    color: var(--red-r-400, #D14343);
  }
}


