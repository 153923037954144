
.blue {
  background: var(--blue-b-500, #2E6BCC) !important;
}

.blue:hover {
  background: #2c5ba7 !important;
}

.blueActive {
  background: #1D4380 !important;
  border: 0 !important;
}

.blueActive:hover {
  background: #1D4380 !important;
  border: 0 !important;
}

.container-eventTap {
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  background: var(--neutral-n-600, #363436);


  .eventTap-icon {
    display: flex;
    width: 32px;
    height: 32px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: var(--neutral-n-50, rgba(246, 246, 246, 0.16));

    img {
      flex: 1 0 0;
      align-self: stretch;
      width: 24px;
      height: 24px;
    }
  }

  .eventTap-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;

    overflow: hidden;

    .info-time {
      color: var(--neutral-n-400, #999);
      /* Paragraph/P-XXS - Regular */
      font-size: 10px;
      font-family: Nekst;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
    }

    .info-name {
      text-wrap: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;

      color: var(--neutral-n-100, #F6F6F6);
      /* Paragraph/P-M - Regular */
      font-size: 16px;
      font-family: Nekst;
      font-style: normal;
      font-weight: 400;
      line-height: 22.4px;
    }
  }

  .eventTap-quickBtn {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

.container-eventTap:hover {
  background: var(--neutral-n-500, #525052);
}

.container-eventTapActive {
  background: var(--neutral-n-500, #525052);
  border: 1px solid var(--neutral-n-100, #F6F6F6);
}