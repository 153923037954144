@import '../_variables.scss';

.comp-simpleTopbar {
  display: flex;
  padding: 16px 16px 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  .simpleTopbar-left {
    display: flex;
    padding-right: 0px;
    align-items: center;
    gap: 8px;
    flex-wrap: nowrap;

    .left-btn {

      .smallIcon-button {
        align-items: flex-start;
      }
    }

    .left-title {
      color: var(--neutral-n-100, #F6F6F6);
      font-family: Nekst;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 19.6px; /* 140% */
    }
  }
}

.comp-topbar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  padding: 16px 16px 0 16px;
  width: 100%;

  .topbar {
    display: flex;
    padding: 8px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 100px;
    border: 1px solid var(--neutral-n-600, #363436);
    background: var(--neutral-n-700, #201D20);
    /* Shadow-4 */
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.20), 0px 2px 4px 0px rgba(0, 0, 0, 0.28);
  
    .topbar-left {
      display: flex;
      padding-right: 0px;
      align-items: center;
      gap: 8px;

      .left-title {
        color: var(--neutral-n-100, #F6F6F6);
        padding: 0 8px;
        font-family: Nekst;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 19.6px; /* 140% */
      }
    }
    
    .topbar-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      
      .right-userInfo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;

        .userInfo-name {
          color: var(--neutral-n-100, #F6F6F6);
          text-align: right;
          /* Paragraph/P-XXS - Medium */
          font-family: Nekst;
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: 14px; /* 140% */
        }

        .userInfo-Role {
          color: var(--neutral-n-400, #999);
          /* Paragraph/P-XXS - Regular */
          font-family: Nekst;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px; /* 140% */
        }
      }

      .right-user {
        display: flex;
        width: 32px;
        height: 32px;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        background: var(--neutral-n-50, rgba(246, 246, 246, 0.16));

        .user-shortName {
          color: var(--neutral-n-100, #F6F6F6);
          font-family: Nekst;
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: 14px; /* 140% */
        }
      }
    }

    .container-logout {
      position: absolute;
      right: 28px;
      top: 62px;
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      border: 1px solid var(--neutral-n-600, #363436);
      background: var(--neutral-n-700, #201D20);
      z-index: 900;
      cursor: pointer;

      .logout {
        display: flex;
        padding: 6px 12px;
        align-items: center;
        gap: 8px;
        width: 160px;

        .logout-icon {
          width: 16px;
          height: 16px;
        }
  
        .logout-text {
          color: var(--red-r-400, #D14343);
          /* Paragraph/P-S - Regular */
          font-family: Nekst;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 19.6px; /* 140% */
        }

        .lang-icon {
          display: flex;
          width: 16px;
          height: 16px;
          padding: 0.667px;
          justify-content: center;
          align-items: center;

          img {
            border-radius: 100%;
            height: 100%;
            width: 100%;
          }
        }

        .lang-text {
          flex: 1 0 0;
          color: var(--neutral-n-100, #F6F6F6);
          /* Paragraph/P-S - Regular */
          font-family: Nekst;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 19.6px; /* 140% */
        }

        .lang-action {
          width: 20px;
          height: 20px;

          .svg {
            width: 16px;
          }
        }
      }

      .logout:hover {
        background: #414141;
      }
    }
  }
}

@media screen and (min-width: $breakpoints-mobile) {
  .comp-topbar {
    .topbar { 
      .topbar-left {
        .sidebar-logo {
          display: none;
        }
      }
      
      .topbar-right {
        .btn-wrapper {
          display: none;
        }
      }

      .topbar-mobileSecond {
        display: none !important;
      }
    }
  }
}

@media screen and (max-width: $breakpoints-mobile) {
  .comp-simpleTopbar {
    display: flex;
    padding: 8px 16px 8px 8px;
    align-items: flex-start;
    gap: 16px;
    border-bottom: 1px solid var(--neutral-n-600, #363436);
    background: var(--neutral-n-700, #201D20);
    width: 100%;
    height: 56px;
    justify-content: center;
  
    .simpleTopbar-left {  
      .left-btn {
        margin-left: 6px;
        margin-right: 2px;

        .smallIcon-button {
          align-items: center;
        }
    
        .mbsc-ic {
          font-size: 18px;
        }
      }

      .left-title {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        flex: 1 0 0;
        overflow: hidden;
        color: var(--neutral-n-100, #F6F6F6);
        text-overflow: ellipsis;
        font-family: Nekst;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 25.2px; /* 140% */
      }
    }
  }

  .comp-topbar {
    padding: 0;

    .topbar { 
      border-radius: 0;
      padding: 16px 16px 12px;
      flex-wrap: wrap;
      gap: 12px;
      border: none;
      border-bottom: 1px solid var(--neutral-n-600, #363436);

      .topbar-mobileSecond {
        flex-basis: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left-title {
          flex: 1 0 0;
          color: var(--neutral-n-100, #F6F6F6);
          font-family: Nekst;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 30px; /* 150% */
          letter-spacing: 0.4px;
          text-transform: uppercase;
          padding: 2px 0;

          display: flex;
          gap: 10px;
          flex-wrap: nowrap;
          flex-direction: row;
        }

        .right-quickButtons {
          display: flex;
          padding-right: 0px;
          align-items: center;
          gap: 8px;
          align-self: stretch;
        }
      }

      .topbar-left {
        .sidebar-logo {
          display: flex;
          align-items: center;
          align-self: stretch;
          gap: 4px
        }

        .smallIcon-button {
          display: none;
        }

        .left-title {
          display: none;
        }
      }

      .topbar-right {
        gap: 6px;
        cursor: unset;

        .btn-wrapper {
          display: flex;
          gap: 6px;
          flex-direction: row;
        }

        .right-user {
          width: 40px;
          height: 40px;
  
          .user-shortName {
            font-size: 12px;
          }
        }

        .right-userInfo {
          display: none;
        }
      }
      
      .container-logout {
        right: 16px;
        top: 62px;
      }
    }
  }

  .item-wrap {
    display: flex;
    padding: 10px;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    width: 100%;
    justify-content: center;
    background-color: transparent;
    border: 0;
    cursor: pointer;
  
    img {
      width: 24px;
      height: 24px;
      filter: invert(0.4);
    }
  
    .imgActive {
      filter: invert(0);
    }
  
    .wrap-text {
      color: var(--neutral-n-100, #F6F6F6);
      font-family: Nekst;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 19.6px; /* 140% */
    }
  }
  
  .item-wrap:hover {
    background: var(--neutral-n-600, rgb(91, 89, 91));
  }
  
  .item-wrap:disabled {
    cursor: unset;
    img {
      filter: invert(0.8);;
    }
    .wrap-text {
      color: var(--neutral-n-50, rgba(246, 246, 246, 0.16));
    }
  }
  
  .item-wrap:disabled:hover {
    background: transparent
  }
  
  .itme-wrap-active {
    background: var(--neutral-n-600, #363436);
  }
}