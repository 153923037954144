@import '../../_variables.scss';

.page-cameraErr {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--neutral-n-500, #525052);
  /* Overline/O01 */
  font-family: Nekst;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px; /* 140% */
  letter-spacing: 0.3px;
  text-transform: uppercase;
  text-align: center;
}

.page-camera {
  display: flex;
  padding: 24px 0px 20px;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;

  .camera-container {
    display: flex;
    // max-width: 1280px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;

    .container-action {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
    }

    .container-content {
      display: flex;
      align-items: flex-start;
      gap: 16px;
      flex: 1 0 0;
      align-self: stretch;

      .content-column01 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        flex: 1 0 0;
        align-self: stretch;

        .column01-top {
          display: flex;
          align-items: center;
          gap: 8px;
          align-self: stretch;

          .top-title {
            flex: 1 0 0;
            color: var(--neutral-n-400, #999);
            /* Paragraph/P-S - Medium */
            font-family: Nekst;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 19.6px; /* 140% */
          }
        }

        .column01-list {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 24px;
          align-self: stretch;

          .list-list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
          }

          .list-fieldmaskItemDefault {
            background: var(--neutral-n-600, #363436) !important;
          }

          .list-fieldmaskItem {
            display: flex;
            padding: 16px;
            align-items: center;
            gap: 16px;
            align-self: stretch;
            border-radius: 4px;
            background: var(--neutral-n-700, #201D20);
            position: relative;

            .fieldmaskItem-delete {
              background: #00000042;
              margin: -16px;
              position: absolute;
              width: 100%;
              height: 100%;
            }

            .fieldmaskItem-content {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              flex: 1 0 0;

              .content-header {
                display: flex;
                width: 132px;
                height: 12px;
                align-items: center;
                gap: 4px;

                .header-defaultText {
                  color: var(--orange-o-400, #FF3517);
                  font-family: Nekst;
                  font-size: 10px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 14px; /* 140% */
                  letter-spacing: 0.3px;
                  text-transform: uppercase;
                }

                .header-normalText {
                  color: var(--neutral-n-500, #525052);
                  /* Overline/O01 */
                  font-family: Nekst;
                  font-size: 10px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 14px; /* 140% */
                  letter-spacing: 0.3px;
                  text-transform: uppercase;
                }
              }

              .content-title {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                align-self: stretch;
                overflow: hidden;
                color: var(--neutral-n-100, #F6F6F6);
                text-overflow: ellipsis;
                /* Paragraph/P-L - Medium */
                font-family: Nekst;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 25.2px; /* 140% */
              }
            }

            .fieldmaskItem-action {
              display: flex;
              align-items: center;
              gap: 8px;
            }
          }
        }
      }

      .content-column02 {
        display: flex;
        width: 380px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;

        .column02-camera {
          display: flex;
          width: 380px;
          padding: 16px;
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
          background: var(--neutral-n-700, #201D20);

          .camera-topInfo {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            align-self: stretch;

            .topInfo-text {
              color: var(--neutral-n-400, #999);
              /* Overline/O01 */
              font-family: Nekst;
              font-size: 10px;
              font-style: normal;
              font-weight: 600;
              line-height: 14px; /* 140% */
              letter-spacing: 0.3px;
              text-transform: uppercase;
            }
          }

          .camera-field {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            align-self: stretch;
            overflow: hidden;
            color: var(--neutral-n-100, #F6F6F6);
            text-align: center;
            text-overflow: ellipsis;
            /* Paragraph/P-S - Regular */
            font-family: Nekst;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 19.6px; /* 140% */
          }

          .camera-img {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            height: 140px;
            position: relative;
            justify-content: center;

            .img-background {
              width: 100%;
              background: var(--neutral-n-600, #363436);
              display: flex;
              align-items: center;
              justify-content: center;

              svg {
                opacity: 0.25;
              }
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              position: absolute;
            }
          }

          .camera-info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;

            .info-item {
              display: flex;
              justify-content: space-between;
              align-items: center;
              align-self: stretch;

              .item-title {
                flex: 1 0 0;
                color: var(--neutral-n-100, #F6F6F6);
                /* Paragraph/P-XS - Medium */
                font-family: Nekst;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 16.8px; /* 140% */
              }
            }
          }

          .camera-quest {
            align-self: stretch;
            color: var(--neutral-n-400, #999);
            /* Paragraph/P-XXS - Regular */
            font-family: Nekst;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px; /* 140% */
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoints-mobile) {
  .page-camera {
    .camera-container {
      .container-content {
        flex-direction: column-reverse;

        .content-column02 {
          width: 100%;

          .column02-camera {
            width: 100%;
          }
        }
      }
    }
  }
}