@import '../../../_variables.scss';

.stepper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  left: 16px;
  top: 110px;

  .item {
    display: flex;
    width: 160px;
    padding: 8px;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: var(--neutral-n-700, #201D20);
    cursor: pointer;

    .item-icon {
      display: flex;
      align-items: center;
      filter: invert(48%) sepia(79%) saturate(0%) hue-rotate(86deg) brightness(118%) contrast(50%);

      img {
        width: 20px;
        height: 20px;
        flex-shrink: 0;
      }
    }

    .item-icon-check {
      filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(50%);
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1 0 0;
      text-wrap: nowrap;

      .step {
        color: var(--neutral-n-400, #999);
        /* Overline/O01 */
        font-family: Nekst;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px; /* 140% */
        letter-spacing: 0.3px;
        text-transform: uppercase;
      }

      .infoText {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        align-self: stretch;
        overflow: hidden;
        color: var(--neutral-n-100, #F6F6F6);
        text-overflow: ellipsis;
        font-family: Nekst;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 19.6px; /* 140% */
      }
    }
  }

  .item-active {
    border: 1px solid var(--neutral-n-100, #F6F6F6);
    background: var(--neutral-n-600, #363436);
  }
}

.stepper-edit {
  position: unset;
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
  margin-top: 12px;
  margin-bottom: 12px;

  .item {
    display: flex;
    width: 160px;
    padding: 8px;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: var(--neutral-n-700, #201D20);
    cursor: pointer;
    justify-content: center;

    .item-icon {
      display: flex;
      align-items: center;
      filter: invert(48%) sepia(79%) saturate(0%) hue-rotate(86deg) brightness(118%) contrast(50%);

      img {
        width: 16px;
        height: 16px;
        flex-shrink: 0;
      }
    }

    .item-icon-check {
      filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(50%);
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: unset;
      text-wrap: nowrap;

      .step {
        display: none;
      }

      .infoText {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        align-self: stretch;
        overflow: hidden;
        color: #8b8b8b;
        text-overflow: ellipsis;
        font-family: Nekst;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 19.6px; /* 140% */
      }
    }
  }

  .item-active {
    border: 1px solid var(--neutral-n-100, #F6F6F6);
    background: var(--neutral-n-600, #363436);

    .item-icon {
      filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(1000%) contrast(96%);
    }

    .infoText {
      color: var(--neutral-n-100, #F6F6F6) !important;
    }
  }
}


@media screen and (max-width: $breakpoints-desktop-s) {
  .stepper {
    position: unset;
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    flex-direction: row;
    justify-content: center;
    flex-wrap: nowrap;
    margin-top: 12px;

    .item {
      .item-icon {
        img {
          width: 16px;
          height: 16px;
        }
      }

      .content {
        .infoText {
          display: none;
        }
      }
    }
  }  
}