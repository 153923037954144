@import '../../../_variables.scss';

.pw-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  
  .container-paywall {
    .paywall-agbBox {
      display: flex;
      padding-bottom: 20px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
  
      .agbBox-a {
        color: var(--neutral-n-100, #F6F6F6) !important;
        font-family: Nekst;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        text-decoration-line: underline;
        cursor: pointer !important;
      }
    }
  
    .paywall-cardWrapper {
      display: flex;
      // align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      align-items: stretch;
  
      .cardWrapper-card {
        display: flex;
        height: 200px;
        padding: 16px;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        flex: 1 0 0;
        background: var(--neutral-n-600, #363436);
  
        .card-header {
          display: flex;
          flex-direction: column;
          gap: 8px;
          width: 100%;
          align-items: flex-start;
  
          .header-tags {
            display: flex;
            gap: 4px;
        
          }
        }  
        
        .card-vat {
          color: var(--neutral-n-400, #999);
          text-align: center;
          /* Paragraph/P-XXS - Regular */
          font-family: Nekst;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px; /* 140% */
          margin-top: 22px;
        }
  
        .mbsc-label {
          left: 0 !important;
        }
  
        .mbsc-input-ic {
          filter: invert(97%) sepia(0) saturate(0%) hue-rotate(0) brightness(20) contrast(100%) !important;
          top: 23px !important;
        }
      }
    }
  }  
}


@media screen and (max-width: $breakpoints-mobile) {
  .pw-container {

    .paywall-cardWrapper {
      flex-direction: column;
    }
  } 
}