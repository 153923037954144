@import '../../../_variables.scss';

.page-eventEdit {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  height: calc(100% - 68px);
  overflow: auto;
  padding-bottom: 20px;
  margin-right: -1em;
  margin-left: -1em;

  .eventEdit-step {
    display: flex;
    max-width: 674px;
    width: 100%;
    padding-top: 24px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;

    .step-contractWarning {
      display: flex;
      padding: 8px;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      border-radius: 2px;
      border: 1px solid var(--blue-b-400, #3382FF);
      background: var(--blue-b-100, rgba(51, 130, 255, 0.32));

      .contractWarning-text {
        align-self: stretch;
        color: var(--blue-b-50, #F2F7FF);
        font-family: Nekst;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 19.6px; /* 140% */
      }
    }


    .step-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 32px;
      flex: 1 0 0;
      align-self: stretch;

      .content-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        align-self: stretch;
  
        .header-title {
          align-self: stretch;
          color: var(--neutral-n-400, #999);
          text-align: center;
          /* Paragraph/P-S - Medium */
          font-family: Nekst;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 19.6px; /* 140% */
        }
    
        .header-subTitle {
          align-self: stretch;
          color: var(--neutral-n-100, #F6F6F6);
          text-align: center;
          font-family: Nekst;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 30px; /* 125% */
          letter-spacing: 0.4px;
          text-transform: uppercase;
        }
      }

      .content-mainEdit {
        padding: 10px;
        margin-top: 24px;
        background: var(--neutral-n-700, #201D20);

        .main-tab {
          display: flex;
          padding: 8px 16px;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          border-radius: 2px;
          border: 1px solid var(--neutral-n-600, #363436);

          .tab-textBlock {
            display: flex;
            align-items: center;
            gap: 4px;

            .textBlock-icon {
              display: flex;

              img {
                width: 12px;
                height: 12px;
              }
            }
         
            .textBlock-title {
              color: var(--neutral-n-400, #999);
              font-family: Nekst;
              font-size: 10px;
              font-style: normal;
              font-weight: 500;
              line-height: 14px; /* 140% */
              letter-spacing: 0.3px;
              text-transform: uppercase;
              max-width: 140px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }

        .main-camera {
          background: var(--neutral-n-600, #363436);
        }

        .eventStep-form {
          background: var(--neutral-n-600, #363436);
        }

        .eventStep-activeBox {
          background: var(--neutral-n-600, #363436);
        }

        .teams-preview {
          background: var(--neutral-n-600, #363436);
        }

        .input-team {
          background: var(--neutral-n-600, #363436);
        }

        .teams-competition {
          background: var(--neutral-n-600, #363436);
        }

        .list-item {
          background: var(--neutral-n-600, #363436);
        }
      }

      .content-main {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
      }
    }
  }
}

.bottom-quickBar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  padding: 16px 24px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-top: 1px solid var(--neutral-n-600, #363436);
  background: var(--neutral-n-700, #201D20);
}

.box-headerAl {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  justify-content: space-between;
  margin-bottom: 16px;

  .headerAl-title {
    flex: 1 0 0;
    color: var(--Neutral-N400, #999);
    /* Paragraph/P-S - Regular */
    font-family: Nekst;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.6px; /* 140% */
  }

  .top-quickInfo {
    display: flex;
    align-items: center;
    gap: 12px;

    .quickInfo-recIcon {
      opacity: 0.2;
      margin-right: -8px;
      width: 16px;
    }

    .quickInfo-box {
      display: flex;
      align-items: center;
      gap: 4px;
      justify-content: flex-end;

      img {
        width: 14px;
        height: 14px;
      }

      .box-title {
        color: var(--neutral-n-100, #F6F6F6);
        font-family: Nekst;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px; /* 140% */
        letter-spacing: 0.3px;
        text-transform: uppercase;
      }
    }
  }
}

.box-headerAf {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;

  .teams-top {
    display: flex;
    align-items: center;
    gap: 16px;
    align-self: stretch;

    .top-logos {
      display: flex;
      height: 64px;
      align-items: center;
      gap: -16px;
      flex: 1 0 0;

      .club-logo {
        display: flex;
        width: 64px;
        height: 64px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        // background: #C1C1FE;
        position: relative;

        img {
          height: 80%;
          width: 80%;
          z-index: 2;
        }

        .bg {
          height: 100%;
          width: 100%;
          position: absolute;
          border-radius: 100px;
          z-index: 1;
          opacity: 0.2;
          border: 2px solid var(--neutral-n-800, #151315);
        }

        .bg2 {
          background: #121212;
          height: 100%;
          width: 100%;
          position: absolute;
          border-radius: 100px;
          z-index: 2;
        }
      }
    }

    .top-quickInfo {
      display: flex;
      align-items: center;
      gap: 12px;
      // flex-direction: column;

      .quickInfo-recIcon {
        opacity: 0.2;
        margin-right: -8px;
        width: 16px;
      }

      .quickInfo-box {
        display: flex;
        align-items: center;
        gap: 4px;
        justify-content: flex-end;

        img {
          width: 14px;
          height: 14px;
        }

        .box-title {
          color: var(--neutral-n-100, #F6F6F6);
          font-family: Nekst;
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: 14px; /* 140% */
          letter-spacing: 0.3px;
          text-transform: uppercase;
        }
      }
    }

    .top-quickInfoDisable {
      .switch-chckbox {
        display: none !important;
      }
    }
  }

  .teams-title {
    align-self: stretch;
    color: var(--neutral-n-100, #F6F6F6);
    font-family: Nekst;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 125% */
    letter-spacing: 0.4px;
    text-transform: uppercase;
    text-wrap: nowrap;

  }

  .teams-label {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    padding-top: 4px;
  }
}

.content-shareXX {
  display: flex;
  padding: 12px 12px 12px 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--neutral-n-600, #363436);
  background: var(--neutral-n-700, #201D20);

  .share-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;

    .left-freeCard {
      color: var(--neutral-n-400, #999);
      /* Link/L-S */
      font-family: Nekst;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 14px */
      text-decoration-line: underline;
      cursor: pointer;
    }
  }

  .share-quickBtn {
    display: flex;
    align-items: center;
    gap: 8px;
    overflow-y: auto;
    padding: 0 0 0 4px;
  }

  .share-quickBtn::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .share-quickBtn {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
}

@media screen and (max-width: $breakpoints-mobile) {
  .page-eventEdit {
    height: unset;
    padding-bottom: 20px;
    overflow: unset;
    margin-right: 0em;
    margin-left: 0em;
  } 

  .bottom-quickBar {
    .staige-button {
      flex: 1 0 0 !important;
    }
  }

  .content-mainEdit {
    .main-tab {
      .mobile {
        display: none !important;
      }
    }
  }
}
