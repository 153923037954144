@import '../../../_variables.scss';

.main-camera {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  background: var(--neutral-n-700, #201D20);

  .camera-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    .wrapper-titel {
      color: var(--neutral-n-100, #F6F6F6);
      /* Paragraph/P-S - Medium */
      font-family: Nekst;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 19.6px; /* 140% */
    }

    .wrapper-cams {
      display: flex;
      padding: 10px 0px 8px 0px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      max-height: 396px;
      overflow: auto;  

      .cam-tagActive {
        border: 1px solid var(--neutral-n-100, #F6F6F6) !important;
        background: var(--neutral-n-50, rgba(246, 246, 246, 0.16));
      }

      .cam-tag {
        display: flex;
        padding: 12px 16px;
        align-items: center;
        gap: 24px;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid var(--neutral-n-50, rgba(246, 246, 246, 0.16));
        cursor: pointer;

        .tag-img {
          display: flex;
          align-items: center;

          img {
            width: 40px;
            height: 70.222px;
          }

          .imgIp {
            width: 40px;
            height: 100%;
          }

          .imgK1 {
            width: 100%;
            height: 58px;
          }

          .imgK2m {
            width: 100%;
            height: 15px;
          }

          .imgK2x {
            width: 100%;
            height: 18px;
          }
        }

        .tag-content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          flex: 1 0 0;
          align-self: stretch;

          .content-titel {
            color: var(--neutral-n-400, #999);
            /* Overline/O01 */
            font-family: Nekst;
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 14px; /* 140% */
            letter-spacing: 0.3px;
            text-transform: uppercase;
          }

          .content-location {
            display: flex;
            align-items: center;
            gap: 8px;
            flex: 1 0 0;
            align-self: stretch;

            .location-icon {
              width: 16px;
              height: 16px;

              img {
                height: 100%;
                width: 100%;
              }
            }

            .location-text {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              flex: 1 0 0;
              overflow: hidden;
              color: var(--neutral-n-100, #F6F6F6);
              text-overflow: ellipsis;
              /* Paragraph/P-S - Regular */
              font-family: Nekst;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 19.6px; /* 140% */
            }
          }
        }

        .tag-btn {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-blend-mode: multiply;
          width: 20px;
          height: 20px;
          border-radius: 100%;
          border: 1px solid var(--transparent-transparent, #FFF);

          .btn-radio {
            background: var(--transparent-transparent, #FFF);
            height: 10px;
            width: 10px;
            border-radius: 100%;
          }
        }
      }
    }
  }

  .camera-fieldmask {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    .fieldmask-titel {
      color: var(--neutral-n-100, #F6F6F6);
      /* Paragraph/P-S - Medium */
      font-family: Nekst;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 19.6px; /* 140% */
    }

    .fieldmask-tags {
      display: flex;
      padding-top: 10px;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      flex-wrap: wrap;

      .tags-tag {
        display: flex;
        padding: 0px 10px;
        align-items: center;
        gap: 4px;
        border-radius: 100px;
        background: var(--neutral-n-50, rgba(246, 246, 246, 0.16));
        cursor: pointer;
        text-wrap: nowrap;
        min-width: 54px;
        justify-content: center;

        .tag-text {
          color: var(--neutral-n-100, #F6F6F6);
          text-align: center;
          /* Tags & Badges/Tag L */
          font-family: Nekst;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%; /* 14px */
          display: flex;
          height: 32px;
          justify-content: center;
          align-items: center;
        }

        .staige-button {
          height: 32px;
        }
      }

      .tags-tagActive {
        background: var(--neutral-n-100, #F6F6F6);

        .tag-text {
          color: var(--neutral-n-800, #151315);
        }
      }
    }
  }
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}
::-webkit-scrollbar:vertical {
  width: 8px;
}
::-webkit-scrollbar:horizontal {
  height: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  border: 2px solid #525252;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #525252;
}


@media screen and (max-width: $breakpoints-mobile) {
  .main-camera {
    padding: 12px;
  } 
}