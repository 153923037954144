@import '../../../_variables.scss';

.embedCode-container {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0;
  align-self: stretch;
  background: var(--neutral-n-700, #201D20);
  overflow: auto;
  margin: 8px 0 0;

  .embedCode-title {
    color: var(--neutral-n-400, #999);
    font-family: Nekst;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
  }

  .option-copy {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  textarea {
    width: 100%;
  }
}

@media screen and (max-width: $breakpoints-mobile) {

}