.login-content {
  background: transparent !important;
  padding: 1rem 0 !important;

  .info {
    width: 100% !important;
    padding-bottom: 40px !important;
  }

  .login-form {
    width: 100% !important;
    font-size: 16px;

    .logout-button {
      width: 100% !important;
      border-radius: 60px !important;
      // border: 1px solid #FF3517 !important;
      background: #FF3517 !important;
      color: #fff !important;
      font-family: "DegularText-Bold" !important;
      line-height: 14px;
      font-size: 20px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}

.md-login-form {
  .social-media-login-buttons {
    display: flex;
    flex-direction: column;
    gap: 0.25em;

    button {
      text-transform: none;
      height: 40px;
      width: 240px;
      padding: 0;
      font-size: 14px;
      border: none;
      display: flex;
      margin: 0 auto 3px;
      font-weight: 400;
      font-family: Roboto, sans-serif;

      .icon {
        height: 36px;
        width: 36px;
        margin: 2px;

        img {
          margin-left: 9px;
          margin-top: 9px;
          width: 18px;
          height: 18px;
          margin-right: auto;
          display: block;
        }
      }

      span {
        margin-top: 11px;
        margin-left: 13px;
      }

      &.microsoft {
        background-color: #2f2f2f;
        color: #fff;
      }

      &.google {
        background-color: #4285f4;
        color: #fff;

        .icon {
          background-color: #fff;
        }
      }

      &.apple {
        background-color: #000;
        color: #fff;

        img {
          width: 15px;
        }
      }

      &.facebook {
        background-color: #1877f2;
        color: #fff;
      }

      &.twitter {
        background-color: #fff;
        color: #000;

        img {
          margin-left: 10.5px;
          width: 22px;
        }
      }
    }
  }
}
