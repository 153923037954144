@import '../../../../_variables.scss';

.top-container {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  height: 36px;

  .container-left {
    width: 360px;
  }

  .container-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
  }
}


@media screen and (max-width: $breakpoints-mobile) {
  .top-container {
    .container-left {
      width: 100%;
    }

    .container-right {
      display: none;
    }
  }
}


.list-user {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
  align-self: stretch;

  .user-containerAdmin {
    cursor: pointer;
  }

  .user-container {
    display: flex;
    padding: 12px 24px 12px 12px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 4px;
    background: var(--neutral-n-700, #201D20);
    max-width: 516px;

    .container-userInfo {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
      flex: 1 0 0;

      .userInfo-img {
        display: flex;
        width: 40px;
        height: 40px;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        background: var(--neutral-n-50, rgba(246, 246, 246, 0.16));
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }

        .shortName {
          color: var(--neutral-n-100, #F6F6F6);
          font-family: Nekst;
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: 14px; /* 140% */
        }
      }

      .userInfo-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        flex: 1 0 0;

        .info-name {
          align-self: stretch;
          color: var(--neutral-n-100, #F6F6F6);
          /* Paragraph/P-M - Medium */
          font-family: Nekst;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 22.4px; /* 140% */
        }

        .info-role {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          align-self: stretch;
          overflow: hidden;
          color: var(--neutral-n-400, #999);
          text-overflow: ellipsis;
          /* Paragraph/P-S - Regular */
          font-family: Nekst;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 19.6px; /* 140% */
        }
      }
    }

    .container-icon {
      display: flex;
    }
  }

  .user-containerAdmin:hover {
    background: var(--neutral-n-600, #363436);
  }
}

