@import './_variables.scss';

html,
body {
  /*height: 100vh;*/
  margin: 0;
  padding: 0;
  background-color: #121212;
}

.mbsc-page-ctx {
  margin: 0;
  padding: 0;
  //height: 100vh !important;
}

#root {
  height: 100%;
}

.app-page {
  height: 100vh !important;
  // padding-bottom: 0 !important;
  background: transparent !important;

  .menu {
    background-color: azure !important;
  }
}

.page-grid-dis {
  .topbar {
    display: none;
  }
}

.page-grid-dis-sidebar {
  grid-template-columns: 1fr !important;
}

.page-grid {
  height: 100vh;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  .grid-content {
    height: 100vh;
    overflow: hidden;
  }
}

@media screen and (min-width: $breakpoints-mobile) {
  .navbar {
    display: none;
  }
}

@media screen and (max-width: $breakpoints-mobile) {
  .page-grid {
    grid-template-columns: none;

    .grid-sidebar {
      display: none;
    }

    .grid-content {
      height: calc(100vh - 66px);
      overflow: hidden;

      .bottom-bar {
        position: absolute;
      }
    }
  }
}

.app-header-r {
  margin-top: -1px;
  margin-right: 4px;
  position: absolute;
  right: 0;
}

.mbsc-page .app-header h3 {
  margin: 0;
  padding: 0 12px;
  font-size: 20px;
}

.app-tab {
  padding-top: 52px;
  padding-bottom: 64px;
}

.mbsc-grid-base {
  height: 100% !important;
}

.mbsc-grid-base-contract {
  padding-bottom: 90px !important;
}

.mbsc-grid-base-withoutContract {
  padding-bottom: 58px !important;
}

/* Mobiscroll Theme */

/* Material Theme */

.loading-spinner {
  text-align: center;

  svg {
    animation: 2s linear infinite svg-animation;
    max-width: 50px;
  }
}

/*svg {
  animation: 2s linear infinite svg-animation;
  max-width: 50px;
}*/

@keyframes svg-animation {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

circle {
  animation: 1.4s ease-in-out infinite both circle-animation;
  display: block;
  fill: transparent;
  stroke: #44525d;
  stroke-linecap: round;
  stroke-dasharray: 283;
  stroke-dashoffset: 280;
  stroke-width: 10px;
  transform-origin: 50% 50%;
}

@keyframes circle-animation {
  0%,
  25% {
    stroke-dashoffset: 280;
    transform: rotate(0);
  }

  50%,
  75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: 280;
    transform: rotate(360deg);
  }
}

.app-page-menu {
  background: #151315 !important;
}

.cam-state {
  display: flex;
  position: absolute;
}

.state-dot {
  height: 16px;
  width: 16px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid #ababab;
}

.state-title {
  margin-left: 6px;
}

@media (max-width: 992px) {
  .mbsc-card-title {
    font-size: 18px !important;
  }

  h3 {
    font-size: 18px !important;
  }
}


.navbar {
  .mbsc-mobiscroll-dark.mbsc-ms-a.mbsc-ms-bottom {
    border-top: 0px !important;

    .mbsc-scv-sc {
      padding: 8px;
      
    margin: 0px -26px;

    }
  }

  .navbar-contract {
    position: fixed;
    bottom: 52px;
    display: flex;
    align-items: center;
    background: #414141;
    text-transform: uppercase;
    width: 100%;
    z-index: 1000;

    .contract-title {
      font-size: 12px;
      padding: 0 1em;
      color: #cfcfcf;
      flex: none;
    }

    input {
      padding: 6px 0px !important;
      margin: 0px !important;
      outline: none;
      // cursor: pointer;
    }

    .s-spinner {
      z-index: 10000;
      position: absolute;
      left: calc(100% - 47px);
      height: 26px;
      width: 26px;
      top: 3px;
    }
  }

  .navbar-contract-pointer {
    input {
      cursor: pointer;
    }
  }
}

// Slider
.mbsc-mobiscroll .mbsc-slider-step {
  background: transparent !important;;
}

.mbsc-mobiscroll-dark .mbsc-progress-track {
  background: #595959;
}

.mbsc-ios-dark.mbsc-schedule-event {
  color: #ff3517 !important;
}

.mbsc-material.mbsc-segmented-button.mbsc-button.mbsc-selected {
  background: #ff3517 !important;
  color: #fff;
}

.mbsc-material.mbsc-segmented-button.mbsc-button {
  border-color: #ff3517 !important;
  color: white !important;
}

/*
██████╗ ██╗   ██╗████████╗████████╗ ██████╗ ███╗   ██╗
██╔══██╗██║   ██║╚══██╔══╝╚══██╔══╝██╔═══██╗████╗  ██║
██████╔╝██║   ██║   ██║      ██║   ██║   ██║██╔██╗ ██║
██╔══██╗██║   ██║   ██║      ██║   ██║   ██║██║╚██╗██║
██████╔╝╚██████╔╝   ██║      ██║   ╚██████╔╝██║ ╚████║
╚═════╝  ╚═════╝    ╚═╝      ╚═╝    ╚═════╝ ╚═╝  ╚═══╝
//SECTION Button
*/

button.mbsc-mobiscroll-dark.mbsc-btn.grey{
  background-color: #151315;
  color: #898989;
  margin-left: 0;
  font-weight: normal;
  text-transform: inherit;
}

button.mbsc-mobiscroll-dark.mbsc-btn.round {
  border-radius: 20px;
  background-color: transparent !important;
  border: 1px solid white;
  color: white;
  font-family: "DegularText-Bold";
  margin: 0;
}
button.mbsc-mobiscroll-dark.mbsc-btn.addedMargin {
  margin: 5px;
}
button.mbsc-mobiscroll-dark.mbsc-btn.ensureZIndex {
  z-index: 99;
}
button.mbsc-mobiscroll-dark.mbsc-btn.round.flat {
  line-height: 0;
}
button.mbsc-mobiscroll-dark.mbsc-btn.round:disabled {
  color: grey;
  border-color: grey;
}

button.mbsc-mobiscroll-dark.mbsc-btn.round.blue {
  border-color: #FF3517;
}

button.mbsc-mobiscroll-dark.mbsc-btn.round.blue.full {
  background-color: #FF3517 !important;
}

button.mbsc-mobiscroll-dark.mbsc-btn.round.full:disabled {
  background-color: grey !important;
  border-color: grey !important;
}


button.mbsc-mobiscroll-dark.mbsc-btn.round.full {
  color: black !important;
  background-color: white !important;
  mix-blend-mode: screen;
}

button.mbsc-mobiscroll-dark.mbsc-btn.round.invert {
  color: black;
  background-color: white !important;
  mix-blend-mode: screen;
}

button.mbsc-mobiscroll-dark.mbsc-btn.small {
  font-size: 0.7em;
  padding: 4px 12px;
}

button.mbsc-mobiscroll-dark.mbsc-btn.middle {
  // font-size: 1em;
  padding: 6px 12px;
}

button.mbsc-mobiscroll-dark.mbsc-btn.fullWidth {
  width: 100%;
}

button.mbsc-mobiscroll-dark.mbsc-btn.right {
  float: right;
}
// !SECTION

/*
███████╗███████╗██╗     ███████╗ ██████╗████████╗
██╔════╝██╔════╝██║     ██╔════╝██╔════╝╚══██╔══╝
███████╗█████╗  ██║     █████╗  ██║        ██║
╚════██║██╔══╝  ██║     ██╔══╝  ██║        ██║
███████║███████╗███████╗███████╗╚██████╗   ██║
╚══════╝╚══════╝╚══════╝╚══════╝ ╚═════╝   ╚═╝
//SECTION Select
*/

div.mbsc-fr-btn-e.round{
  border-radius: 20px;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  font-family: "DegularText-Bold";
  margin: 0;
  padding: 11px;
  margin: 10px 10px;
}


div.mbsc-fr-btn-e.round:disabled {
  color: grey;
  border-color: grey;
}

div.mbsc-fr-btn-e.round.blue {
  border-color: #FF3517;
}

div.mbsc-fr-btn-e.round.blue.full {
  background-color: #FF3517;
}

div.mbsc-fr-btn-e.round.full:disabled {
  background-color: grey !important;
  border-color: grey !important;
}

div.mbsc-fr-btn-e.round.full {
  color: black;
  background-color: white;
  mix-blend-mode: screen;
}

div.mbsc-fr-btn-e.round.invert {
  color: black;
  background-color: white;
  mix-blend-mode: screen;
}

div.mbsc-fr-btn-e.round.small {
  font-size: 0.7em;
  padding: 6px 12px;
}
// Lets hope this class is not used by mobiscroll outside of
// Custom Buttons on select Elements. We need to overwrite it,
// because it is the wrapper to our custom classed button elements
// and dictates the buttons width
.mbsc-fr-btn-w{
  width: 50%;
  text-align: center;
}

input.mbsc-select-input.mbsc-control{
  width: 100%;
  background-color: #414141;
  color: #898989;
  font-family: "DegularText-Regular";
  font-size: 1em;
  // padding: 10px;
  border-style:solid;
  border: none;
  margin: .5em 0;
  padding: .6875em;
}
// .fullWidth input.mbsc-select-input.mbsc-control {
//   width: 100%;
// }
//!SECTION

/*
██╗   ██╗████████╗██╗██╗
██║   ██║╚══██╔══╝██║██║
██║   ██║   ██║   ██║██║
██║   ██║   ██║   ██║██║
╚██████╔╝   ██║   ██║███████╗
 ╚═════╝    ╚═╝   ╚═╝╚══════╝
//SECTION Util
*/

.clear {
  clear:both;
}

.hidden {
  visibility: hidden;
  z-index: -20;
}

//!SECTION

/*
███████╗ ██████╗ ███╗   ██╗████████╗███████╗
██╔════╝██╔═══██╗████╗  ██║╚══██╔══╝██╔════╝
█████╗  ██║   ██║██╔██╗ ██║   ██║   ███████╗
██╔══╝  ██║   ██║██║╚██╗██║   ██║   ╚════██║
██║     ╚██████╔╝██║ ╚████║   ██║   ███████║
╚═╝      ╚═════╝ ╚═╝  ╚═══╝   ╚═╝   ╚══════╝
// SECTION FONTS
*/

@font-face {
  font-family: 'Nekst-Bold';
  src: local('Nekst-Bold'), url(./fonts/Nekst-Bold.otf) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Nekst-Regular';
  src: local('Nekst-Regular'), url(./fonts/Nekst-Regular.otf) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'DegularText-Bold';
  src: local('DegularText-Bol'), url(./fonts/Nekst-Bold.otf) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'DegularText-Regular';
  src: local('DegularText-Regular'), url(./fonts/Nekst-Regular.otf) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'IBMPlexMono-Bold';
  src: local('IBMPlexMono-Bold'), url(./fonts/Nekst-Bold.otf) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'IBMPlexMono-Regular';
  src: local('IBMPlexMono-Regular'), url(./fonts/Nekst-Regular.otf) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
// !SECTION


// SELECT
.mbsc-windows.mbsc-page {
  background: transparent !important;
  padding-bottom: 0 !important;
}

.mbsc-ios.mbsc-page {
  background: transparent !important;
  padding-bottom: 0 !important;
}

.mbsc-ios-dark.mbsc-form-control-wrapper {
  margin: 3em 1em 1.5em !important;
  color: #dfdfdf !important;
  background: #414141 !important;
}

.mbsc-ios.mbsc-label-inline.mbsc-ltr {
  text-transform: uppercase;
  color: #fff;
  position: absolute;
}

.mbsc-ios.mbsc-textfield {
  height: 2.2em !important;
}

.mbsc-ios.mbsc-select-icon {
  top: 0.425em !important;
}

.mbsc-ios.mbsc-label-inline.mbsc-ltr  {
  padding-left: 0em !important;
  margin-top: -30px;
  font-size: 11px;
}

.mbsc-ios-dark.mbsc-select {
  background: #414141 !important;
  color: #dfdfdf !important;
}

.mbsc-ios-dark.mbsc-select-icon {
  color: #dfdfdf !important;
}

.mbsc-ios-dark.mbsc-form-control-wrapper:before, .mbsc-ios-dark.mbsc-form-control-wrapper:after {
  border-color: transparent !important;
}

// CALENDAR

.mbsc-ios-dark.mbsc-schedule-header-day.mbsc-selected {
  background: #ff3517 !important;
  color: #ffffff !important;
}

.mbsc-ios-dark.mbsc-calendar-button.mbsc-button {
  color: #e5e5e5 !important;
}



// toast
.mbsc-wdg .mbsc-wdg-c.mbsc-w-p {
  padding: 0 !important;
}

.mbsc-fr-c .mbsc-wdg-c .mbsc-w-p {
  padding: 0 !important;
}



.mbsc-toast-msg {
  border-radius: 0 !important;
  max-width: 100% !important;
  min-width: 100% !important;
  background: var(--neutral-n-500, #525052) !important;
  padding: 16px 0 !important;
}

.mbsc-warning {
  .mbsc-toast-msg {
    background: rgba(221, 204, 106, 1) !important;
  }
}

.mbsc-error {
  .mbsc-toast-msg {
    background: rgba(221, 106, 106, 1) !important;
  }
}