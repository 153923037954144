@import '../../../_variables.scss';

.subscriptionManagement {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: 40px 0 30px;

  .content-wrap {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;


    .wrap-main {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1 0 0;
      align-self: stretch;
      gap: 16px;


      .main-aboBox {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1 0 0;
        align-self: stretch;

        .aboBox-main {
          display: flex;
          padding: 16px;
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
          flex: 1 0 0;
          align-self: stretch;
          background: var(--neutral-n-700, #201D20);

          .main-header {
            display: flex;
            height: 16px;
            align-items: center;
            gap: 8px;
            align-self: stretch;

            .header-left {
              flex: 1 0 0;
              color: var(--neutral-n-400, #999);
              /* Overline/O01 */
              font-family: Nekst;
              font-size: 10px;
              font-style: normal;
              font-weight: 600;
              line-height: 14px; /* 140% */
              letter-spacing: 0.3px;
              text-transform: uppercase;
            }

            .header-right {
              flex: 1 0 0;
              color: var(--neutral-n-400, #999);
              /* Overline/O01 */
              font-family: Nekst;
              font-size: 10px;
              font-style: normal;
              font-weight: 600;
              line-height: 14px; /* 140% */
              letter-spacing: 0.3px;
              text-transform: uppercase;
              text-align: right;
            }
          }

          .main-info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;

            .info-title {
              color: var(--neutral-n-100, #F6F6F6);
              /* Heading/H3 - Uppercase */
              font-family: Nekst;
              font-size: 24px;
              font-style: normal;
              font-weight: 900;
              line-height: 30px; /* 125% */
              letter-spacing: 0.4px;
              text-transform: uppercase;
            }

            .info-infoText {
              // width: 540px;
              color: var(--neutral-n-200, #D6D6D6);
              /* Paragraph/P-XS - Regular */
              font-family: Nekst;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 16.8px; /* 140% */
            }
          }

          .main-counter {
            display: flex;
            padding: 8px;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
            align-self: stretch;
            border-radius: 4px;
            background: var(--neutral-n-50, rgba(246, 246, 246, 0.16));


            .counter-infoBar {
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              align-self: stretch;

              .infoBar-title {
                color: var(--neutral-n-100, #F6F6F6);
                /* Paragraph/P-XS - Regular */
                font-family: Nekst;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16.8px; /* 140% */
              }

              .infoBar-timer {
                display: flex;
                align-items: flex-start;
                gap: 4px;

                .timer-a {
                  color: var(--neutral-n-100, #F6F6F6);
                   /* Paragraph/P-XS - Regular */
                  font-family: Nekst;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 16.8px; /* 140% */
                }

                .timer-b {
                  color: var(--neutral-n-400, #999);
                  /* Paragraph/P-XS - Regular */
                  font-family: Nekst;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 16.8px; /* 140% */
                }
              }
            }

            .counter-bar {
              height: 16px;
              align-self: stretch;
              border-radius: 4px;
              background: var(--neutral-n-500, #525052);
              position: relative;

              .bar {
                width: 80%;
                height: 16px;
                position: absolute;
                background: var(--green-g-400, #26BD83);
              }
            }
          }

          .main-quickBtn {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 16px;
            align-self: stretch;

            .staige-button-ghost {
              .label {
                color: #999 !important;
                text-decoration: underline;
              }
            }
          }
        }

        .aboBox-trigger {
          display: flex;
          padding: 8px 16px;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          border-top: 1px solid var(--neutral-n-600, #363436);
          background: var(--neutral-n-700, #201D20);
          cursor: pointer;

          .trigger-title {
            color: var(--neutral-n-100, #F6F6F6);
            /* Overline/O01 */
            font-family: Nekst;
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 14px; /* 140% */
            letter-spacing: 0.3px;
            text-transform: uppercase;
          }

          .trigger-icon {
            font-size: 24px;
          }
        }

        .aboBox-trigger:hover {
          border-top: 1px solid var(--neutral-n-600, #363436);
          background: var(--neutral-n-600, #363436);
        }

        .aboBox-videoList {
          display: flex;
          padding: 16px;
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
          align-self: stretch;
          background: var(--neutral-n-700, #201D20);
          border-top: 1px solid var(--neutral-n-600, #363436);

          .videoList-header {
            display: flex;
            height: 32px;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            justify-content: space-between;
            margin-bottom: -16px;

            .header-left {
              color: var(--neutral-n-100, #F6F6F6);
              /* Overline/O01 */
              font-family: Nekst;
              font-size: 10px;
              font-style: normal;
              font-weight: 600;
              line-height: 14px; /* 140% */
              letter-spacing: 0.3px;
              text-transform: uppercase;
            }
          }

          .videoList-day {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            align-self: stretch;
            gap: 8px;

            .day-date {
              color: var(--neutral-n-400, #999);
              /* Overline/O01 */
              font-family: Nekst;
              font-size: 10px;
              font-style: normal;
              font-weight: 600;
              line-height: 14px; /* 140% */
              letter-spacing: 0.3px;
              text-transform: uppercase;
              // margin-bottom: 8px;
            }
          }

          .videoList-videoBox:hover {
            background: var(--neutral-n-50, rgba(246, 246, 246, 0.16));
          }

          .videoList-videoBox {
            display: flex;
            padding: 12px 24px 12px 12px;
            align-items: center;
            gap: 16px;
            align-self: stretch;
            border-radius: 4px;
            background: var(--neutral-n-600, #363436);
            cursor: pointer;
            text-decoration: none;


            .videobox-info {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 12px;
              flex: 1 0 0;

              .info-top {
                display: flex;
                align-items: center;
                gap: 8px;
                align-self: stretch;

                .top-badges {
                  display: flex;
                  align-items: center;
                  gap: 4px;
                }

                .box-textBox{
                  display: flex;
                  align-items: center;
                  gap: 4px;
    
                  .textBox-icon {
                    opacity: 0.7;
                    width: 12px;
                    height: 12px;
                    line-height: 0px;
                  }
    
                  .textBox-text {
                    color: var(--neutral-n-400, #999);
                    font-family: Nekst;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 14px; /* 140% */
                    letter-spacing: 0.3px;
                    text-transform: uppercase;
                  }
                }
              }

              .into-bottom {
                display: flex;
                align-items: center;
                gap: 16px;
                align-self: stretch;

                .bottom-team {
                  display: flex;
                  align-items: center;
                  gap: 4px;

                  .team-logo {
                    display: flex;
                    width: 32px;
                    height: 32px;
                    padding: 4px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 4px;
                    background: var(--neutral-n-50, rgba(246, 246, 246, 0.16));

                    img {
                      width: 100%;
                      height: 100%;
                    }
                  }

                  .team-name {
                    color: var(--neutral-n-100, #F6F6F6);
                    /* Paragraph/P-M - Medium */
                    font-family: Nekst;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 22.4px; /* 140% */
                  }
                }

                .bottom-vs {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  white-space: nowrap;
                  color: var(--neutral-n-100, #F6F6F6);
                  text-align: center;
                  font-family: Nekst;
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 24px; /* 120% */
                }
              }
            }

            .videobox-icon {
              display: flex;
            }
          }
        }
      }

    }

    .wrap-second {
      display: flex;
      width: 380px;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      
      a {
        width: 100%;
        text-decoration: none;
      }

      .second-helpContainer:hover {
        background: var(--neutral-n-600, #363436);

        .helpContainer-headerInfo {
          .headerInfo-icon {
            transform: rotate(-34deg);
          }
        }
      }

      .second-helpContainer {
        display: flex;
        padding: 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        background: var(--neutral-n-700, #201D20);

        .helpContainer-headerInfo {
          display: flex;
          height: 16px;
          align-items: center;
          gap: 10px;
          align-self: stretch;

          .headerInfo-text {
            flex: 1 0 0;
            color: var(--neutral-n-300, #B8B8B8);
            /* Overline/O01 */
            font-family: Nekst;
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 14px; /* 140% */
            letter-spacing: 0.3px;
            text-transform: uppercase;
          }

          .headerInfo-icon {
            width: 16px;
            height: 16px;
          }
        }

        .helpContainer-textInfo {
          align-self: stretch;
          color: var(--neutral-n-100, #F6F6F6);
          /* Paragraph/P-S - Regular */
          font-family: Nekst;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 19.6px; /* 140% */
        }
      }

      .offer-container {
        display: flex;
        padding: 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        border: 1px solid var(--aqua-a-400, #60FCE4);
        background: var(--aqua-a-100, rgba(96, 252, 228, 0.32));


        .container-headerInfo {
          display: flex;
          height: 16px;
          align-items: center;
          gap: 8px;
          align-self: stretch;

          .headerInfo-title {
            flex: 1 0 0;
            color: var(--aqua-a-200, #CAFCF4);
            /* Overline/O01 */
            font-family: Nekst;
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 14px; /* 140% */
            letter-spacing: 0.3px;
            text-transform: uppercase;
          }

          .headerInfo-subTitle {
            color: var(--aqua-a-200, #CAFCF4);
            /* Overline/O01 */
            font-family: Nekst;
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 14px; /* 140% */
            letter-spacing: 0.3px;
            text-transform: uppercase;
          }

          .headerInfo-icon {
            height: 16px;
            color: #d0fbe3;
          }
        }

        .container-info {
          display: flex;
          padding-bottom: 0px;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          align-self: stretch;

          .info-title {
            align-self: stretch;
            color: var(--neutral-n-100, #F6F6F6);
            /* Heading/H3 - Uppercase */
            font-family: Nekst;
            font-size: 24px;
            font-style: normal;
            font-weight: 900;
            line-height: 30px; /* 125% */
            letter-spacing: 0.4px;
            text-transform: uppercase;
          }

          .info-text {
            align-self: stretch;
            color: var(--aqua-a-200, #CAFCF4);
            /* Paragraph/P-XS - Regular */
            font-family: Nekst;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16.8px; /* 140% */
          }
        }

        .container-btn {
          width: 100%;
          /*display: flex;
          justify-content: center;
          align-items: center;
          flex: 1 0 0;*/
          // background: var(--neutral-n-100, #F6F6F6);
          /* Shadow-2 */
          // box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.20), 0px 1px 2px 0px rgba(0, 0, 0, 0.28);
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoints-mobile) {
  .subscriptionManagement {
    margin: 16px 0;

    .content-wrap {
      flex-direction: column;

      .wrap-main {
        .main-aboBox {
          .aboBox-videoList {
            .videoList-videoBox {
              .videobox-info {
                .info-top {
                  .box-textBox {
                    display: none;                
                  }
                }
              }

              .videobox-icon {
                display: none;
              }
            }
          }
        }
      }

      .wrap-second {
        width: 100%;
      }
    }
  }
}