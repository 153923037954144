@import '../../_variables.scss';

.staige-modal {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.8);

  .modal-container {
    display: flex;
    max-width: 640px;
    width: 100%;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    border: 1px solid var(--Neutral-N600, #363436);
    background: var(--Neutral-N700, #201D20);
    /* Shadow-16 */
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.20), 0px 8px 16px 0px rgba(0, 0, 0, 0.28);

    .container-inner {
      display: flex;
      padding-bottom: 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;

      .inner-title {
        align-self: stretch;
        color: var(--Neutral-N100, #F6F6F6);
        font-family: Nekst;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px; /* 125% */
      }

      .inner-text {
        align-self: stretch;
        color: var(--Neutral-N400, #999);
        /* Paragraph/P-S - Regular */
        font-family: Nekst;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 19.6px; /* 140% */
      }
    }

    .container-action {
      display: flex;
      padding-top: 16px;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
      align-self: stretch;
    }
  }
}

@media screen and (max-width: $breakpoints-mobile) {
  .staige-modal {
    .modal-container {
      .container-action {
        flex-direction: column-reverse;

        .staige-button {
          width: 100%;
        }
      }
    }
  }
}