@import '../../_variables.scss';

.page-fieldMask {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;

  .fieldMask-container {
    display: flex;
    width: 832px;
    padding-top: 24px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;

    .container-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      flex: 1 0 0;
      align-self: stretch;

      .content-header {
        display: flex;
        padding-bottom: 8px;
        align-items: center;
        gap: 16px;
        align-self: stretch;

        .header-title {
          flex: 1 0 0;
          color: var(--neutral-n-400, #999);
          /* Paragraph/P-S - Regular */
          font-family: Nekst;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 19.6px; /* 140% */


        }
      }

      .content-bottom {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;

        .bottom-input {
          display: flex;
          width: 400px;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;

          .mbsc-input {
            width: 100% !important;
            margin: 0 !important;
          }

          .mbsc-label {
            font-family: Nekst;
            font-size: 14px;
            text-transform: unset;
          }

          .mbsc-input-wrap {
            padding-top: 22px !important;
          }
        }

        .bottom-fieldmaskContainer {
          display: flex;
          padding: 16px;
          flex-direction: column;
          align-items: flex-start;
          align-self: stretch;
          background: var(--neutral-n-700, #201D20);
          gap: 16px;

          .fieldmaskContainer-header {
            display: flex;
            align-items: center;
            align-self: stretch;

            .header-title {
              flex: 1 0 0;
              color: var(--neutral-n-100, #F6F6F6);
              /* Paragraph/P-S - Medium */
              font-family: Nekst;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 19.6px; /* 140% */
              .subtext{
                align-self: stretch;
                color: var(--neutral-n-400, #999);
                /* Paragraph/P-XS - Regular */
                font-family: Nekst;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16.8px; /* 140% */
              }
            }
          }

          .fieldmaskContainer-content {
            display: flex;
            align-items: flex-start;
            gap: 16px;
            align-self: stretch;
           
            .content-interactiv {
              display: flex;
              width: 266.667px;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;

              .interactiv-action {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 8px;
                align-self: stretch;

                .staige-button {
                  width: 50%;
                }
              }

              .interactiv-field {
                width: 266.67px;
                height: 164.17px;
                position: relative;

                img {
                  position: absolute;
                }

                .corner {
                  opacity: 0.2;
                  height: 22px;
                  filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(87deg) brightness(119%) contrast(50%);
                }

                .cornerActive {
                  opacity: 1;
                }

                .corner-a {
                  top: 4px;
                  left: 4px;
                }

                .corner-b {
                  top: 4px;
                  right: 4px;
                }

                .corner-c {
                  bottom: 4px;
                  right: 4px;
                }

                .corner-d {
                  bottom: 4px;
                  left: 4px;
                }

                .currentCorner {
                  height: 28px;
                  width: 28px;
                  border-radius: 100%;
                  border: 4px solid red;
                  position: absolute;
                  margin: 1px 0;
                }

                .currentCorner-a {
                  top: 0;
                  left: 1px;
                }

                .currentCorner-b {
                  top: 0;
                  right: 1px;
                }

                .currentCorner-c {
                  bottom: 0;
                  right: 1px;
                }

                .currentCorner-d {
                  bottom: 0;
                  left: 1px;
                }
              }

              .interactiv-text{
                align-self: stretch;
                color: var(--neutral-n-400, #999);
                text-align: center;
                /* Paragraph/P-XS - Regular */
                font-family: Nekst;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16.8px; /* 140% */
              }
            }

            .content-prev {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              flex: 1 0 0;
              // padding: 0px 17px;
              // overflow: hidden;
              // padding: 0 16px;

              width: 200px;
              //height: 300px;

              height: 100%;
              aspect-ratio: 16 / 9;

              .prev-loading {
                width: 100%;
                height: 100%;
                display: flex;  
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoints-mobile) {
  
  .page-fieldMask {
    .fieldMask-container {
      width: 100%;

      .content-bottom {  
        .bottom-fieldmaskContainer {
          gap: 8px !important;

          /*.fieldmaskContainer-header {
            .header-title {
              display: none;
            }
          }*/

          .fieldmaskContainer-content {
            flex-direction: column-reverse;
            align-items: stretch !important;

            .content-interactiv {
              width: 100% !important;
              align-items: center !important;
              padding-top: 24px !important;
            }
          }
          .content-prev {
            width: 100% !important;
          }
        }

        .bottom-input {
          width: 100% !important;
        }
      }
    }
  }

  .aab {
    .aa {
      width: unset !important
    }
  }

}



.aab {
  position: absolute;
  // height: 100%;
  top: 15px;
  bottom: 0;
  left: 10px;
  right: 10px;

  .btn {
    position: absolute;
    left: 0;
    right: 0;
    width: 400px;
    z-index: 10000;
    top: 18px;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .turn90Left{
    transform: rotate(90deg);
  }
  .turn90Right{
    transform: rotate(-90deg);
  }

  .aa {
    position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
    width: 841px;
    top: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 948;

    .aacild {
      border: 1px solid;
      // margin: 73px;
    
      .staige-button {
        position: absolute !important;
        right: 14px !important;
        top: -14px !important;
        z-index: 10000;
      }
    }
  }
  
}

.title-lenses {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  color: var(--neutral-n-100, #F6F6F6);
  font-family: Nekst;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;

  .lenses {
    border: 2px solid;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 8px;
    cursor: pointer;
    user-select: none;
  
    color: #f6f6f69e;
    font-family: Nekst;
    font-style: normal;
    font-weight: 500;
  }
  
  .lenses:hover {
    color: #f6f6f6;
  }
  
  .lensesActive {
    color: #4ec36f;
  }
  
  .lensesActive:hover {
    color: #4ec36f;
    cursor: default;
  }
  
  .lesnses-loading {
      top: unset !important;
      left:  unset  !important;
      width: 12px !important;
      height: 12px !important;
  }
}

//////////////////////////



.imageContainer{
  margin-bottom: -5px;
}
.gridSnippet{
  width: 100%;
  height: auto;
  /* padding: 2px; */
  /* min-height: 100%; */
}

.imageContainer.turn90Left, .streamContainer.turn90Left{
  position: relative;
  transform: rotate(90deg);
  /* left: 11.5%; */
  top: 23%;
}
.imageContainer.turn90Right, .streamContainer.turn90Right{
  position: relative;
  transform: rotate(-90deg);
  /* right: 11.5%; */
  top: 23%;
}

.streamContainer.turn90Left, .streamContainer.turn90Right{
  top: 15%;
}

.streamContainer.turn90Left, .streamContainer.turn90Right {
  // margin-bottom: calc(100% - (((16/9) - 1) * 100%) + 20%);
}

span.red{
  color: red;
}
span.green{
  color: green;
}
span.yellow{
  color: yellow;
}
span.blue{
  color: blue;
}

.loaderContainer {
  /* width: max-content; 
  height: max-content; 
  display: inline-block;
  position: relative;
  top: 10px; */
}

.markerX {
  position: relative;
  top: -10px;
  left: -5px;
  cursor: default;
  pointer-events: none;
}

.turn90Left .markerX {
  top : 7px;
}

.turn90Right .markerX{
  left: 5px;
  top: -10px;
}

.marker {
  position: absolute;
  z-index: 100;
  pointer-events: none;
  /* width: 20px;
  height: 20px; */
}

.marker.red{
  color: red;
}
.marker.green{
  color: green;
}
.marker.yellow{
  color: yellow;
}
.marker.blue{
  color: blue;
}

.streamModalContainer{
  position: absolute;
  height: 80vh;
}

.streamModal {
  z-index: 10000;
  position: relative;
  background: black;
  width: 100%;
  height: 100%;
  /* background: transparent; */
}

.streamContainer {
  position: relative;
}

.streamContainer.fake {
  position: absolute;
  top: 0;
}

.streamContainer img {
  width: 100%;
  /* height: 100%; */
}


.streamPlayer {
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
}

.cameraView {
  padding-bottom: 12%;
}

.upper_controls {
  width: 100%;
}

.linkButton{
  float: right;
}

.mbsc-page h2.lessMargin{
  margin: 0;
}

.mbsc-page h3.lessMargin{
  margin: 0;
}
.mbsc-page span.slightlyBigger{
  font-size: 1.4em !important;
  font-weight: bold;
}


button.mbsc-mobiscroll-dark.mbsc-btn.modalCloseButton{
  background-color: transparent;
  color: black;
  position: absolute;
  z-index: 10;
}

button.mbsc-mobiscroll-dark.mbsc-btn.inline {
  background-color: transparent;
  color: white;
  border: none;
}

.mbsc-row .cornerSelectContainer{
  /* display: grid; */
  text-align: center;
}
.mbsc-col.mbsc-col-12.minHeigth {
  min-height: 45px;
}
.mbsc-col mbsc-col-12.mbsc-justify-content-center.flex{
  display: flex;
  align-items: center;
  justify-items: center;
}

img.fullWidth{
  width:100%;
}

div.fullSize{
  width: 100%;
  height: 100%;
}
div.center{
  display: flex;
  align-items: center;
  justify-content: center;
}

div.fieldView{
  width: 100%;
  max-width: 600px;
  max-height: 600px;
}

div.CornerHighlight {
  border-radius: 50px;
  border: 4px solid blue;
  width: 30px;
  height: 30px;
  position: relative;
}

div.CornerHighlight.TL.BW {
  top: 15px;
  left: 18%;
}
div.CornerHighlight.TR.BW {
  top: 15px;
  left: 76%;
}
div.CornerHighlight.BL.BW {
  top: 77%;
  left: -2%;
}
div.CornerHighlight.BR.BW {
  top: 77%;
  left: 96%;
}

div.CornerHighlight.TL.C {
  top: 15px;
  left: 16%;
}
div.CornerHighlight.TR.C {
  top: 15px;
  left: 76%;
}
div.CornerHighlight.BL.C {
  top: 79%;
  left: -2%;
}
div.CornerHighlight.BR.C {
  top: 77%;
  left: 96%;
}

img.padded{
  padding: 0 4px;
}

div.inline{
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -36px;
  margin-left: -16px;
}

div.shadowBG{
  width:100%;
  height: 100%;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  background-color: rgba(40, 40, 40, 0.6);
}

button.mbsc-mobiscroll-dark.mbsc-btn.closeButton{
  display: inline;
  position: absolute;
  right: 0;
  background-color: black;
  top: -30px;
}

button.mbsc-mobiscroll-dark.mbsc-btn.restoreButton{
  display: inline;
  position: absolute;
  right: 120px;
  background-color: black;
  top: -30px;
}

button.mbsc-mobiscroll.mbsc-no-touch.mbsc-btn:not(:disabled):not(.mbsc-active):hover.inImageButton{
  opacity: 0;
}

button.mbsc-mobiscroll-dark.mbsc-btn.inImageButton{
  display: inline;
  position: absolute;
  padding: 15px 50px;
  opacity: 0.0;
}
button.mbsc-mobiscroll-dark.mbsc-btn.inImageButton.TL{
  left:7%;
  top: 0%;
}
button.mbsc-mobiscroll-dark.mbsc-btn.inImageButton.TR{
  right:7%;
  top:0%;
}
button.mbsc-mobiscroll-dark.mbsc-btn.inImageButton.DL{
  bottom:0%;
  left:0%;
}
button.mbsc-mobiscroll-dark.mbsc-btn.inImageButton.DR{
  bottom:0%;
  right:0%;
}


.mbsc-col-4 {
  padding: 0 !important;
}

.dev-ccc {
  display: flex;
  align-items: flex-start;
  align-self: stretch;

  flex: 1 0 0;
  color: var(--Neutral-N400, #999);
  /* Paragraph/P-XS - Regular */
  font-family: Nekst;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px; /* 140% */
}
