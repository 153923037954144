@import '../../_variables.scss';

.page-administration {
  display: flex;
  padding: 40px 0px 20px;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;

  .main-container {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    .column-box {
      display: flex;
      padding: 16px 24px;
      align-items: center;
      gap: 24px;
      align-self: stretch;
      border-radius: 4px;
      background: var(--neutral-n-700, #201D20);
      width: 100%;
      text-decoration: none !important;

      .box-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        flex: 1 0 0;

        .content-title {
          color: var(--neutral-n-100, #F6F6F6);
          /* Paragraph/P-L - Medium */
          font-family: Nekst;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 25.2px; /* 140% */
        }

        .content-supTitle {
          align-self: stretch;
          color: var(--neutral-n-400, #999);
          /* Paragraph/P-S - Regular */
          font-family: Nekst;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 19.6px; /* 140% */
        }
      }

      .box-icon {
        display: flex;
        align-items: center;
      }
    }

    .column-box:hover {
      background: var(--neutral-n-600, #363436);
    }
  }
}

@media screen and (max-width: $breakpoints-mobile) {
  .page-administration {
    padding: 16px 0;

    .main-container {
      flex-direction: column;
     
      .column-box {
        align-items: stretch;
        min-height: 145px;
      }
    }
  }
}