.staige-tag {
  display: flex;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  border-radius: 100px;
  background: var(--neutral-n-50, rgba(246, 246, 246, 0.16));
  cursor: pointer;
  text-wrap: nowrap;

  .tag-lable {
    display: flex;
    height: 32px;
    padding: 5px 0px 7px 0px;
    justify-content: center;
    align-items: center;
    color: var(--neutral-n-100, #F6F6F6);
    text-align: center;
    /* Tags & Badges/Tag L */
    font-family: Nekst;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
  }


}

.staige-tagActive { 
  background: var(--neutral-n-100, #F6F6F6);

  .tag-lable {
    color: var(--neutral-n-800, #151315);
    text-align: center;
    /* Tags & Badges/Tag L */
    font-family: Nekst;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
  }
}