@keyframes fadein {
  from { opacity: 0 }
  to   { opacity: 1 }
}

.tag-overlayBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  gap: 16px;
  position: absolute;
  width: 336px;
  height: auto;
  left: calc(50% - 336px/2);
  bottom: 12px;
  background: #2E6BCC;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.28), 0px 0px 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 10000;

  -webkit-animation: fadein 0.2s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 0.2s; /* Firefox < 16 */
        -ms-animation: fadein 0.2s; /* Internet Explorer */
         -o-animation: fadein 0.2s; /* Opera < 12.1 */
            animation: fadein 0.2s;  


  .deactivated {
    position: absolute;
    right: 4px;
    top: 4px;
  }

  .icon-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    width: 40px;
    height: 40px;
    background: rgba(246, 246, 246, 0.16);
    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;

    img {
      width: 24px;
      height: 24px;
      /* Inside auto layout */
      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 1;
    }
  }
  
  .overlay-content {
    font-family: 'Nekst';
    text-align: center;

    .content-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }

    .content-infoText {
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
      padding-top: 8px;
    }

  }
  .overlay-btn {
    font-size: 12px;
    width: 100%;
    display: flex;
    margin-bottom: -4px;

    .btn {
      border: 1px solid #fff !important;
      color: #fff !important;
      padding: 8px 16px !important;
      width: 50%;
    }

    .btn:active {
      background: #fff !important;
      color: black !important;
    }

    .btn:hover {
      background: #fff !important;
      color: black !important;
      opacity: 1;
    }
  }
}