.app-header {
  display: flex;
  // justify-content: center;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  line-height: 24px;
  text-align: center;
  align-items: center;
  z-index: 100000;

  .header-container {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    margin: 18px;
  }
  img {
    margin: 16px;
    height: 16px;
  }

  h2 {
    flex: auto;
    font-size: 20px !important;
  }

  .header-btnList {
    .icon {
      padding: 9px 12px;
      margin: 0;
      font-size: 20px;
    }
  }

  .header-btnMenu {
    font-size: 26px;

    .icon {
      padding: 6px 14px;
      margin: 0;
    }
  }

  .header-btnRight {
    font-size: 26px;

    .icon {
      padding: 6px 14px;
      margin: 0;
    }
  }
}

@media (max-width: 767px) {
  .s-grid-lines {
    position: absolute !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    margin: 0 1em !important;
    //
    display: grid;
    align-items: start;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: calc((.34375rem - .01vw) * 4) calc((.34375rem - .01vw) * 1.5);
    align-items: stretch;
    position: fixed;
    top: 0;
    right: calc((.34375rem - .14vw) * 3);
    bottom: 0;
    left: calc((.34375rem - .14vw) * 3);
    pointer-events: none;
  
    .s-grid-line {
      border-right: 1px solid rgb(39, 39, 39); // 1px solid rgba(156, 96, 96, 0.15);
      border-left: 1px solid rgb(39, 39, 39); // 1px solid rgba(158,158,158,0.15);
    }

    .s-grid-line-second {
      border-right: 1px solid rgba(255, 255, 255, 0.1);
      border-left: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .s-grid-lines {
    position: absolute !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    margin: 0 1em !important;
    //
    display: grid;
    align-items: start;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: calc((.34375rem - .01vw) * 4) calc((.34375rem - .01vw) * 1.5);
    align-items: stretch;
    position: fixed;
    top: 0;
    right: calc((.34375rem - -2.75vw) * 3);
    bottom: 0;
    left: calc((.34375rem - -2.75vw) * 3);
    pointer-events: none;
  
    .s-grid-line {
      border-right: 1px solid rgb(39, 39, 39); // 1px solid rgba(156, 96, 96, 0.15);
      border-left: 1px solid rgb(39, 39, 39); // 1px solid rgba(158,158,158,0.15);
    }

    .s-grid-line-second {
      border-right: 1px solid rgba(255, 255, 255, 0.1);
      border-left: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
}

@media (min-width: 1200px) {
  .s-grid-lines {
    position: absolute !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    margin: 0 1em !important;
    //
    display: grid;
    align-items: start;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: calc((.34375rem - .01vw) * 4) calc((.34375rem - .01vw) * 1.5);
    align-items: stretch;
    position: fixed;
    top: 0;
    right: calc((.34375rem - -5.55vw) * 3);
    bottom: 0;
    left: calc((.34375rem - -5.55vw) * 3);
    pointer-events: none;
  
    .s-grid-line {
      border-right: 1px solid rgb(39, 39, 39); // 1px solid rgba(156, 96, 96, 0.15);
      border-left: 1px solid rgb(39, 39, 39); // 1px solid rgba(158,158,158,0.15);
    }

    .s-grid-line-second {
      border-right: 1px solid rgba(255, 255, 255, 0.1);
      border-left: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
}


.header-contracts {
  position: relative;

  .contracts-title {
    position: absolute;
    top: -12px;
    left: 10px;
    font-size: 10px;
    color: #c1c1c1;
    background: #121212;
    height: 16px;
    width: 43px;
    border-radius: 4px;
  }

  input {
    height: 10px;
    border-radius: 20px;
    cursor: pointer;
    margin-top: 2px !important;
    outline: none;
  }

  .s-spinner {
    position: absolute;
    left: calc(100% - 18px);
    top: 8px;
    width: 10px;
    height: 10px;
  }
}

.pageWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .bottom-bar {
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    padding: 16px 24px;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-top: 1px solid var(--neutral-n-600, #363436);
    background: var(--neutral-n-700, #201D20);
  }
}
