.clubScoreInfo-container {
  display: flex;
  padding: 16px 8px;
  // height: 120px;
  // flex: 120px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  flex-direction: row;
  justify-content: space-around;
  background: var(--neutral-n-600, #363436);
    
  .container-side {
    display: flex;
    width: 125.5px;
    padding: 0px 8px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    position: relative;

    .dot-container {
      position: absolute;
  
      display: flex;
      gap: 4px;
      flex-direction: column;

      .dot {
        width: 8px;
        height: 8px;
        background: var(--Neutral-N50, rgba(246, 246, 246, 0.16));
        border-radius: 100%;
      }

      .dot-active {
        background: var(--Orange-O400, #FF3517);
      }
    }

    .dot-containerLeft {
      left: 12px;
    }
    
    .dot-containerRight {
      right: 12px;
    }


    .logo {
      display: flex;
      width: 64px;
      height: 64px;
      padding: 9px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 4px;
      background: var(--neutral-n-50, rgba(246, 246, 246, 0.16));


      img {
        height: 100%;
        width: 100%;
      }
    }

    .name {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-shrink: 0;
      align-self: stretch;

      color: var(--neutral-n-100, #F6F6F6);
      text-align: center;
      /* Paragraph/P-XS - Regular */
      font-size: 12px;
      font-family: Nekst;
      font-style: normal;
      font-weight: 400;
      line-height: 16.8px;
    }
  }
  
  .container-mid {
    display: flex;
    // height: 112px;
    flex-direction: column;
    align-items: center;
    gap: 4px;

    .dateTimestamp {
      color: var(--neutral-n-400, #999);
      text-align: center;
      /* Paragraph/P-XXS - Regular */
      font-size: 10px;
      font-family: Nekst;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
    }

    .score {
      color: var(--neutral-n-100, #F6F6F6);
      text-align: center;
      font-size: 42px;
      font-family: Nekst;
      font-style: normal;
      font-weight: 500;
      line-height: 46px;
      text-transform: uppercase;
      white-space: nowrap;
    }

    .state {
      color: var(--neutral-n-100, #F6F6F6);
      text-align: center;
      /* Overline/O01 */
      font-size: 10px;
      font-family: Nekst;
      font-style: normal;
      font-weight: 600;
      line-height: 14px;
      letter-spacing: 0.3px;
      text-transform: uppercase;
    }
  }
}

.clubScoreInfo-containerXXX {
  padding: 2px 8px !important;
  // height: 70px !important;

  transition: flex 0.2s;
  flex: 70px;
  
  .container-side {
    width: 125.5px;
    padding: 0px 8px;
    gap: 0 !important;

    .logo {
      width: 54px;
      height: 54px;
      padding: 7px;
    }

    .name {
      font-size: 10px !important;
    }
  }
  
  .container-mid {
    height: 80px !important;
    gap: 0px;

    .dateTimestamp {
      font-size: 10px;
    }

    .score {
      font-size: 32px;
      font-weight: 500;
      line-height: 46px;
    }

    .state {
      font-size: 10px;
      line-height: 14px;
    }
  }
}


.cxcxcx {
  transition: flex, padding 0.2s;
  flex: 110px;

  .container-side {
    transition: gap 0.2s;
    gap: 0 !important;

    .logo {
      width: 64px;
      height: 64px;
      padding: 7px;

      transition: width 0.2s;
      transition: height 0.2s;
      transition: padding 0.2s;
    }
  }

  .container-mid {
    height: 80px !important;
    gap: 0px;
    transition: height 0.2s;

  }
}