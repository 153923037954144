.quickTime-container {
  display: flex;
  padding: 8px;
  align-items: flex-end;
  justify-content: space-between;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  border-bottom: 1px solid var(--neutral-n-600, #363436);
  background: var(--neutral-n-700, #201D20);
  cursor: pointer;

  .container-timerArt {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    gap: 8px;


    .timerArt-title {
      color: var(--neutral-n-400, #999);
      /* Paragraph/P-XXS - Regular */
      font-size: 10px;
      font-family: Nekst;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
    }

    .timerArt-button {

    }
  }
}

.quickTime-containerWarn {
  background: var(--Orange-O100, rgba(255, 53, 23, 0.32));

  .timerArt-title {
    color: var(--Orange-O300, #FF8370) !important;
  }
}

.container-box {
  padding: 8px 8px 0;
  display: flex;
  gap: 4px;
  flex-direction: column;

  .box-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 0 0;
  
    color: var(--neutral-n-100, #F6F6F6);
    /* Paragraph/P-XS - Regular */
    font-size: 12px;
    font-family: Nekst;
    font-style: normal;
    font-weight: 400;
    line-height: 16.8px;
  }

  .box-time {
    display: flex;
    gap: 4px;
  
    .time-setting {
      padding: 8px 8px 0px 8px;
      border-radius: 4px;
      background: var(--neutral-n-600, #363436);
      width: 92px;

      .setting-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: var(--neutral-n-400, #999);

        .header-title {
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex: 1 0 0;
          /* Paragraph/P-XXS - Regular */
          font-size: 10px;
          font-family: Nekst;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
        }

        span {
          width: 12px;
          height: 12px;
          font-size: 12px;
        }
      }

      .setting-timeInput {
        display: flex;
        padding: 8px 0px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        align-self: stretch;
        justify-content: space-between;

        .timeInput-input {
          display: flex;
          align-items: flex-end;
          justify-content: flex-start;

          input {
            color: var(--neutral-n-100, #F6F6F6);
            font-size: 16px;
            font-family: Nekst;
            font-style: normal;
            font-weight: 400;
            line-height: 22.4px;
            background-color: transparent;
            border: none;
            width: 34px;
            text-align: end;
          }

          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
          }
          input[type=number]{
            -moz-appearance: textfield;
          }
  
          input:focus-visible {
            border: none;
            outline: none;
          }

          .timeInput-info {
            color: var(--neutral-n-100, #F6F6F6);
            font-size: 8px;
            font-family: Nekst;
            padding: 4px 0;
          }
        }

        a {
          cursor: pointer;
          color: var(--neutral-n-100, #F6F6F6);
          text-decoration: none;

          span {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 2px;
          }

          .active {
            color: #26BD83;
          }
        }
      }
    }

    .time-settingWarn {
      background: var(--Orange-O100, rgba(255, 53, 23, 0.32));

      .setting-header {
        color: var(--Orange-O300, #FF8370) !important;
      }
    }

    .time-container {
      display: flex;
      padding: 8px 8px 0px 8px;
      flex-direction: column;
      align-items: flex-start;
      flex: 1 0 0;
      border-radius: 4px;
      background: var(--neutral-n-600, #363436);
      
      .title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1 0 0;
        color: var(--neutral-n-400, #999);
        /* Paragraph/P-XXS - Regular */
        font-size: 10px;
        font-family: Nekst;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
      }
    
      .buttons {
        display: flex;
        padding: 8px 4px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
    
        .midline {
          width: 1px;
          height: 22px;
          background: #525052;
        }
      }
    }
  }
}

.time-setting {
  padding: 8px 8px 0px 8px;
  border-radius: 4px;
  background: var(--neutral-n-600, #363436);
  width: 92px;

  .setting-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: var(--neutral-n-400, #999);

    .header-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1 0 0;
      /* Paragraph/P-XXS - Regular */
      font-size: 10px;
      font-family: Nekst;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
    }

    span {
      width: 12px;
      height: 12px;
      font-size: 12px;
    }
  }

  .setting-timeInput {
    display: flex;
    padding: 8px 0px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    justify-content: space-between;

    .timeInput-input {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;

      .input-time {
        color: var(--neutral-n-100, #F6F6F6);
        font-size: 16px;
        font-family: Nekst;
        font-style: normal;
        font-weight: 400;
        line-height: 22.4px;
        background-color: transparent;
        border: none;
        width: 34px;
        text-align: end;
      }

      input {
        color: var(--neutral-n-100, #F6F6F6);
        font-size: 16px;
        font-family: Nekst;
        font-style: normal;
        font-weight: 400;
        line-height: 22.4px;
        background-color: transparent;
        border: none;
        width: 34px;
        text-align: end;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
      }
      input[type=number]{
        -moz-appearance: textfield;
      }

      input:focus-visible {
        border: none;
        outline: none;
      }

      .timeInput-info {
        color: var(--neutral-n-100, #F6F6F6);
        font-size: 8px;
        font-family: Nekst;
        padding: 4px 0;
      }
    }

    a {
      cursor: pointer;
      color: var(--neutral-n-100, #F6F6F6);
      text-decoration: none !important;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
      }

      .active {
        color: #26BD83;
      }
    }
  }
}

.time-settingWarn {
  background: var(--Orange-O100, rgba(255, 53, 23, 0.32));

  .setting-header {
    color: var(--Orange-O300, #FF8370) !important;
  }
}

.wrapper-quickTime {
  display: flex;
  flex-direction: row;
  gap: 6px;
}

.quick-startStop {
  position: fixed;
  bottom: 22px;
  z-index: 22;
  left: 50%;
  transform: translateX(-50%);
}

.quick-startStop-play {
  .staige-button {
    border: 2px solid var(--Orange-O400, #FF3517);
    background: #601E15;

    /* Shadow-8 */
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.28), 0px 1px 2px 0px rgba(0, 0, 0, 0.20);
  }
}
