@import '../../../_variables.scss';

.waitScreen {
  height: 100%;
  display: flex;
  padding: 64px;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;

  .headerText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
    color: var(--neutral-n-100, #F6F6F6);
    text-align: center;
    font-family: Nekst;
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: 38px;
    text-transform: uppercase;
  }

  .content {
    display: flex;
    padding: 96px 8px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;

    .content-inner {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 32px;
      align-self: stretch;

      .clubInfo {
        display: flex;
        padding: 0px 8px;
        flex-direction: column;
        align-items: center;
        gap: 16px;

        .logo {
          display: flex;
          width: 140px;
          height: 140px;
          padding: 16px;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          background: var(--neutral-n-50, rgba(246, 246, 246, 0.16));

          img {
            height: 100%;
          }
        }

        .name {
          color: var(--neutral-n-100, #F6F6F6);
          text-align: center;
          font-family: Nekst;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 30px;          
        }
      }

      .videoInfo {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;
        align-self: stretch;
        min-width: 142px;

        .startVideoInfo {
          color: var(--neutral-n-100, #F6F6F6);
          text-align: center;
          /* Paragraph/P-S - Regular */
          font-family: Nekst;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 19.6px;
        }

        .videoTimer {
          color: var(--neutral-n-100, #F6F6F6);
          text-align: center;
          font-family: Nekst;
          font-size: 32px;
          font-style: normal;
          font-weight: 500;
          line-height: 38px;
        }

        .scoreInfo {
          color: var(--neutral-n-100, #F6F6F6);
          text-align: center;
          font-family: Nekst;
          font-size: 54px;
          font-style: normal;
          font-weight: 500;
          line-height: 58px;
          text-transform: uppercase;
        }

        .tags {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
          align-self: stretch;
          cursor: unset;
          margin: -20px;

          .ai {
            display: flex;
            padding: 1px 6px;
            align-items: center;
            gap: 4px;
            align-self: stretch;
            border-radius: 2px;
            background: var(--blue-b-500, #2E6BCC);

            .circle {
              display: flex;
              width: 8px;
              height: 8px;
              padding: 2px;
              justify-content: center;
              align-items: center;
              border-radius: 100px;
              background: var(--aqua-a-100, rgba(96, 252, 228, 0.32));

              div {
                width: 4px;
                height: 4px;
                background: var(--aqua-a-400, #60FCE4);
                border-radius: 100%;
              }
            }

            .text {
              color: var(--neutral-n-100, #F6F6F6);
              font-family: Nekst;
              font-size: 10px;
              font-style: normal;
              font-weight: 600;
              line-height: 100%;
              letter-spacing: 0.4px;
              text-transform: uppercase;
            }
          }

          .live {
            display: flex;
            padding: 1px 6px;
            align-items: center;
            border-radius: 2px;
            background: var(--orange-o-500, #E01E00);

            .text {
              color: var(--neutral-n-100, #F6F6F6);
              font-family: Nekst;
              font-size: 10px;
              font-style: normal;
              font-weight: 600;
              line-height: 100%;
              letter-spacing: 0.4px;
              text-transform: uppercase;
            }
          }
        }

        .videoTimerDays {
          margin: -10px 0px -28px;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoints-mobile) {
  .waitScreen {
    padding: 0 !important;
  
    .headerText {
      font-size: 24px !important;
      line-height: 16px !important;
      padding: 32px 16px;
    }
  
    .content {
      padding: 0 !important;
      gap: 16px;
  
      .content-inner {
        gap: 16px !important;
  
        .clubInfo {
          .logo {
            width: 64px !important;
            height: 64px !important;
            padding: 16px;
          }
  
          .name {
            font-size: 14px !important;
            line-height: 12px !important;          
          }
        }
  
        .videoInfo {
          gap: 0 !important;
          min-width: 108px !important;
  
          .startVideoInfo {
            font-size: 10px !important;
          }
  
          .videoTimer {
            font-size: 24px !important;
          
          }

          .videoTimerDays {
            margin:  0 0 -8px !important;
          }

          .tags { 
            margin: 0;
          }
        }
      }
    }
  }
}