@import '../../_variables.scss';

.page-editSponsors {
  display: flex;
  padding: 24px 0px 20px;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;

  .editSponsors-content {
    display: flex;
    max-width: 832px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    flex: 1 0 0;
    gap: 24px;
  }

  .content-header {
    display: flex;
    padding-bottom: 0px;
    align-items: center;
    gap: 16px;
    align-self: stretch;

    .header-titel {
      flex: 1 0 0;
      color: var(--neutral-n-400, #999);
      /* Paragraph/P-S - Regular */
      font-family: Nekst;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 19.6px; /* 140% */
    }

    .header-action {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
    }
  }

  .content-bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    .bottom-container {
      display: flex;
      padding: 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      align-self: stretch;
      background: var(--neutral-n-700, #201D20);

      .container-adImg {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        gap: 4px;

        .adImg-imgContainer {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          align-self: stretch;

          .imgContainer-text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            align-self: stretch;
            color: var(--neutral-n-100, #F6F6F6);
            /* Paragraph/P-S - Medium */
            font-family: Nekst;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 19.6px; /* 140% */
          }

          .imgContainer-img {
            display: flex;
            min-height: 90px;
            max-height: 240px;
            padding: 8px 16px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            align-self: stretch;
            border-radius: 4px;
            background: var(--neutral-n-600, #363436);

            .App {
              min-height: 90px;
              max-height: 240px;

              .ReactCrop {
                min-height: 90px;
                max-height: 240px;
              }
            }


            .img-prevImg {
              height: 180px;
              width: 100%;
              position: relative;
              display: flex;
              align-items: center;
              
              img {
                min-height: 90px;
                max-height: 240px;
                padding: 12px;
                object-fit: contain;
                width: 100%;
                height: 100%;
                position: absolute;
              }

              .img-prev {
                opacity: 0;
                width: 100%;
                height: 100%;

                .img-uploadIcon {
                  top: 14px;

                  img {
                    height: 24px;
                    width: 50px;
                    top: 14px;
                  }
                }

                .img-upload {  
                  .mbsc-input {
                    .mbsc-input-wrap {  
                      .mbsc-control {
                        min-height: 180px;
                      }
                    }
                  }
                }
              }

              .img-prev:hover {
                opacity: 0.8;
              }
            }

            .img-prev {
              position: relative;
              height: 100%;
              width: 100%;
              cursor: pointer;

              .img-uploadIcon {
                display: flex;
                flex-direction: column;
                position: absolute;
                gap: 4px;
                z-index: 10;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                pointer-events: none;

                color: #FFF;
                /* Paragraph/P-S - Medium */
                font-family: Nekst;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 19.6px; /* 140% */

                img {
                  height: 24px;
                  width: 24px;
                }
              }

              .img-upload {
                height: 100%;
                padding: 0 !important;

                .mbsc-input {
                  margin: 0 !important;
                  height: 100%;

                  .mbsc-input-wrap {
                    height: 100%;

                    .mbsc-control {
                      min-height: 90px;
                      // height: 100%;
                      margin: 0 !important;
                      cursor: pointer;
                    }
                  }
                }
              }

              .mbsc-ic-upload {
                display: none !important;
              }
            }

          }

          .imgContainer-infoText {
            align-self: stretch;
            color: var(--neutral-n-400, #999);
            /* Paragraph/P-XXS - Regular */
            font-family: Nekst;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px; /* 140% */
          }
        }
      }

      .container-adTextConfig {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;

        .adTextConfig-nameLink {
          display: flex;
          align-items: flex-start;
          gap: 16px;
          align-self: stretch;

          .mbsc-input {
            width: 100%;
            margin: 0;
          }

          .mbsc-label {
            font-family: Nekst;
            font-size: 14px;
            text-transform: unset;
          }

          .mbsc-input-wrap {
            padding-top: 22px;
          }
        }

        .adTextConfig-description {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
        }

        .adTextConfig-descriptionOpen {
          width: 100%;

          .mbsc-input {
            width: 100% !important;
            margin: 0 !important;
          }

          .mbsc-label {
            display: none !important;
          }

          .mbsc-input-wrap {
            padding-top: 0 !important;
          }
        }
      }

      .container-title {
        color: var(--neutral-n-100, #F6F6F6);
        font-family: Nekst;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 19.6px; /* 140% */
      }

      .container-previewBox {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;

        .previewBox-mobile {
          display: none;
        }

        .previewBox-option {
          display: flex;
          width: 200px;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          align-self: stretch;

          .option-item {
            display: flex;
            padding: 12px 16px 16px 16px;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
            flex: 1 0 0;
            align-self: stretch;
            border-radius: 4px;
            border: 1px solid var(--neutral-n-50, rgba(246, 246, 246, 0.16));
            // background: var(--neutral-n-50, rgba(246, 246, 246, 0.16));
            cursor: pointer;
            user-select: none; 

            .item-inner {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: flex-start;
              flex: 1 0 0;
              align-self: stretch;

              .inner-top {
                display: flex;
                align-items: center;
                gap: 16px;
                align-self: stretch;
                justify-content: space-between;

                .top-info {
                  display: flex;
                  align-items: center;
                  gap: 4px;
                  flex: 1 0 0;

                  img {
                    filter: invert(42%) sepia(93%) saturate(0%) hue-rotate(87deg) brightness(119%) contrast(34%);
                  }

                  .info-text {
                    color: var(--neutral-n-400, #999);
                    font-family: Nekst;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 14px; /* 140% */
                    letter-spacing: 0.3px;
                    text-transform: uppercase;
                  }
                }

                .top-check {
                  display: flex;
                  height: 20px;
                  width: 20px;
                  flex-direction: column;
                  border: 1px solid var(--transparent-transparent, #FFF);
                  border-radius: 100%;
                  background-blend-mode: multiply;
                  justify-content: center;
                  align-items: center;

                  .check-active {
                    // width: 8px;
                    //height: 8px;
                    // border-radius: 100%;
                    // background: var(--transparent-transparent, #FFF);
                  }
                }
              }

              .inner-text {
                align-self: stretch;
                color: var(--neutral-n-100, #F6F6F6);
                /* Paragraph/P-S - Regular */
                font-family: Nekst;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 19.6px; /* 140% */
              }
            }
          }
          
          .option-itemActive {
            background: var(--neutral-n-50, rgba(246, 246, 246, 0.16));
            border: 1px solid var(--neutral-n-100, #F6F6F6);

            .item-inner {
              .inner-top {
                .top-check {      
                  .check-active {
                    width: 8px;
                    height: 8px;
                    border-radius: 100%;
                    background: var(--transparent-transparent, #FFF);
                  }
                }
              }
            }
          }
        }

        .previewBox-prev {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          flex: 1 0 0;

          .prev-video {
            display: flex;
            height: 333px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            align-self: stretch;
            background: lightgray -144.17px -51.755px / 130.699% 129.508% no-repeat;
          }
        }
      }

      .container-basedItemActive {
        border: 1px solid var(--neutral-n-100, #F6F6F6);
        background: var(--neutral-n-50, rgba(246, 246, 246, 0.16));
      
        .basedItem-main {
          .main-checkbox {
            .checkbox-active {
              background: var(--transparent-transparent, #FFF);
              height: 8px;
              width: 8px;
              border-radius: 100%;
            }
          }
        }

        .basedItem-bottom {
          .mbsc-textarea {
            background: var(--neutral-n-50, rgba(246, 246, 246, 0.16)) !important;
          }
        }
      }

      .container-based {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
      }

      .container-basedItem {
        display: flex;
        padding: 12px 16px 16px 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid var(--neutral-n-50, rgba(246, 246, 246, 0.16));
        // background: var(--neutral-n-50, rgba(246, 246, 246, 0.16));
        cursor: pointer;

        .basedItem-main {
          display: flex;
          align-items: flex-start;
          gap: 24px;
          align-self: stretch;

          .main-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            flex: 1 0 0;
            align-self: stretch;

            .content-a {
              color: var(--neutral-n-400, #999);
              /* Overline/O01 */
              font-family: Nekst;
              font-size: 10px;
              font-style: normal;
              font-weight: 600;
              line-height: 14px; /* 140% */
              letter-spacing: 0.3px;
              text-transform: uppercase;
            }

            .content-b {
              align-self: stretch;
              color: var(--neutral-n-100, #F6F6F6);
              /* Paragraph/P-S - Regular */
              font-family: Nekst;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 19.6px; /* 140% */
            }
          }

          .main-checkbox {
            width: 20px;
            height: 20px;
            border: 1px solid var(--transparent-transparent, #FFF);
            border-radius: 100%;
            background-blend-mode: multiply;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .basedItem-bottom {
          display: flex;
          align-items: flex-start;
          align-self: stretch;

          .mbsc-textarea-wrapper {
            width: 100%;
            margin: 24px 0 0 !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoints-mobile) {
  .page-editSponsors {
    .content-bottom {
      .bottom-container {
        .container-adTextConfig {
          .adTextConfig-nameLink {
           flex-direction: column;
          }
        }

        .container-previewBox {
          display: flex;
          flex-direction: column;
          align-items: unset;

          .previewBox-option {
            display: none;
          }

          .previewBox-mobile {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
          }
        }
      }
    }
  }
}
