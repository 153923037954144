.staige-switch {
  display: flex;
  align-items: center;
  gap: 8px;

  .switch-label {
    color: var(--neutral-n-100, #F6F6F6);
    /* Controls/Text M */
    font-family: Nekst;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
  }

  .switch-chckbox {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  
    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--neutral-n-50, rgba(246, 246, 246, 0.16));;
      -webkit-transition: .4s;
      transition: .4s;
    }

    .checkbox:disabled {
      cursor: default;
    }

    .sliderDisabled {
      cursor: default;
    }
  
    .slider:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: var(--neutral-n-100, #F6F6F6);
      -webkit-transition: .4s;
      transition: .4s;
    }
  
    input:checked + .slider {
      background-color: var(--green-g-400, #26BD83);;
    }
  
    input:focus + .slider {
      box-shadow: 0 0 1px #2196F3;
    }
  
    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
  
    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }
  
    .slider.round:before {
      border-radius: 50%;
    }
  }
}

.staige-switchDisabled {
  opacity: 0.2;
  cursor: unset;
}

.staige-switch-small {
  .switch-chckbox {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 16px;
  
    .slider:before {
      height: 10px;
      width: 10px;
      left: 4px;
      bottom: 3px;
    }
  
    input:checked + .slider:before {
      -webkit-transform: translateX(14px);
      -ms-transform: translateX(14px);
      transform: translateX(14px);
    }
  }
}


.staige-switch-medium {
  .switch-chckbox {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 24px;
  
    .slider:before {
      height: 18px;
      width: 18px;
      left: 4px;
      bottom: 3px;
    }
  
    input:checked + .slider:before {
      -webkit-transform: translateX(22px);
      -ms-transform: translateX(22px);
      transform: translateX(22px);
    }
  }
}

