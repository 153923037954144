.wrapper-qt {
  .qt-quick {
    position: absolute;
    bottom: 18px;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 1000;

    button {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      padding: 6px 8px;

      border-radius: 100px;
      border: 2px solid var(--Orange-O400, #FF3517);
      background: #601E15;

      /* Shadow-8 */
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.28), 0px 1px 2px 0px rgba(0, 0, 0, 0.20);

      span {
        color: var(--Neutral-N100, #F6F6F6);
        font-family: Nekst;
        font-size: 14px;
        font-style: normal;
        font-weight: 900;
        line-height: 100%; /* 14px */
        letter-spacing: 0.6px;
        text-transform: uppercase;
      }
    }
  }
}