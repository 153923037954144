
.fieldmaskContainer-tipps {
  display: flex;
  padding: 8px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 4px;
  background: var(--Neutral-N600, #363436);

  .tipps-header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    .header-title {
      flex: 1 0 0;
      color: #FFF;
      /* Overline/O01 */
      font-family: Nekst;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: 14px; /* 140% */
      letter-spacing: 0.3px;
      text-transform: uppercase;
    }

    .header-actions {
      display: flex;
      align-items: center;
      gap: 4px;

      a {
        outline: none !important;
      }
    }
  }

  .tipps-scroll::-webkit-scrollbar {
    display: none;
  }

  .tipps-scroll {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    overflow: overlay;
    width: 100%;

    .scroll-wrapper {
      display: flex;
      align-items: flex-start;
      gap: 8px;

      .wrapper-item {
        display: flex;
        width: 314px;
        padding: 8px 12px;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 8px;
        background: var(--Neutral-N50, rgba(246, 246, 246, 0.16));
  
        .item-text {
          align-self: stretch;
          color: var(--Neutral-N100, #F6F6F6);
          /* Paragraph/P-S - Regular */
          font-family: Nekst;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 19.6px; /* 140% */
        }
      }
    }
  }
}