.staige-radioButton {
  display: flex;
  align-items: center;
  gap: 8px;

  input[type="radio"] {
    /* ...existing styles */
    display: grid;
    place-content: center;
    min-height: 20px;
    min-width: 20px;
    cursor: pointer;
    background: transparent;
    -webkit-appearance: none;
    appearance: none;
    border: 2px solid var(--neutral-n-100, #F6F6F6);
    border-radius: 100%;
  }
  
  input[type="radio"]::before {
    content: "";
    height: 8px;
    width: 8px;
    border-radius: 100%;
  }
  
  input[type="radio"]:checked::before {
    transform: scale(1);
    background: var(--neutral-n-100, #F6F6F6);
  }
  
  label {
    color: var(--neutral-n-100, #F6F6F6);
    /* Controls/Text M */
    font-family: Nekst, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
  }
}