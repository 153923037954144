.wrapper {
  padding-top: 1em;
}

.inputOptions {
  display: flex;
  flex-direction: column;
  gap: 1em;

  & > * {
    flex-grow: 1;
    flex-shrink: 1;
  }
}

.inputSeparator {
  display: grid;
  place-content: center;
  font-size: 1.3em;
  font-weight: bold;
}

.manualTokenInputWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  label {
    flex-grow: 1;
  }

  :global input.mbsc-control {
    font-family: 'Courier New', Courier, monospace;
  }
}

.qrReader {
  // max-width is enough because height mirrors width
  max-width: 70vh;
  margin: 0 auto;
}

.scannedToken {
  font-size: 3em;
  display: block;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 0.2em;
  font-family: 'Courier New', Courier, monospace;
}

.invalidToken {
  color: lighten(red, 15%);
  outline: 1px solid lighten(red, 15%);
}

.buttons {
  margin-top: 1em;

  .mbsc-btn:first-child {
    margin-left: 0;
  }
}

.agb-checkbox {
  .mbsc-checkbox-box {
    left: 1em !important;
    right: none !important;
  }
}

.agbCheckboxContainer {
  margin-left: -16px;
  display: flex;
  margin: 40px 0 30px -10px;

  .agbCheckbox {
    width: 66px;
    margin-top: -6px;
  }
}
