.navbar .mbsc-mobiscroll-dark.mbsc-ms-a.mbsc-ms-bottom {
  border-top: 1px solid var(--neutral-n-600, #363436) !important;
  background: var(--neutral-n-700, #201D20);
  height: 50px !important;
}

.mbsc-scv-sc {
  padding: 0 !important;
}

.navbar-contractInfo {
  position: fixed;
  bottom: 50px;
  width: 100%;
  z-index: 980;

  display: flex;
  background: var(--neutral-n-50, #363636);
  padding: 0px 16px;

  color: var(--neutral-n-400, #999);
  /* Overline/O01 */
  font-family: Nekst;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 140% */
  letter-spacing: 0.3px;
  text-transform: uppercase;
  align-items: center;

  .mbsc-select-input.mbsc-control  {
    background: var(--neutral-n-50, #363636);
    backdrop-filter: blur(16px);
    height: 16px !important;
    padding: 0px 12px !important;
    margin: 0 !important;
    overflow: hidden;
    color: var(--neutral-n-100, #F6F6F6);
    text-overflow: ellipsis;
    font-family: Nekst;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
  }

  input:focus{
    outline: none !important;
  }
}

.contractSelect {
  .mbsc-sc-whl-gr {
    padding: 20px !important;
  }
  .mbsc-sc-whl-gr {
    text-align: left;
    text-decoration: none;
  }

  .mbsc-sc-lbl {
    text-transform: none !important;
    color: var(--neutral-n-100, #F6F6F6) !important;
    /* Paragraph/P-M - Medium */
    font-family: Nekst !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 22.4px !important; /* 140% */
  }

  .mbsc-sc-itm {
    color: var(--neutral-n-100, #F6F6F6) !important;
    /* Paragraph/P-S - Regular */
    font-family: Nekst !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    // line-height: 19.6px !important; /* 140% */
  }
}