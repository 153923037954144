@import '../../../_variables.scss';

.main-teamsStep {
  display: flex;
  padding-bottom: 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  .teamForm {
    display: none;
  }

  .clubForm {
    display: none;
  }  

  .teams-preview {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    background: var(--neutral-n-700, #201D20);


    .main-tab {
      display: flex;
      padding: 8px 16px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      border-radius: 2px;
      border: 1px solid var(--Neutral-N600, #363436);

      .tab-textBlock {
        display: flex;
        align-items: center;
        gap: 4px;

        .textBlock-icon {
          filter: invert(42%) sepia(93%) saturate(0%) hue-rotate(0deg) brightness(80%) contrast(119%);
        }

        .textBlock-title {
          color: var(--Neutral-N400, #999);
          font-family: Nekst, sans-serif;
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: 14px; /* 140% */
          letter-spacing: 0.3px;
        }
      }
    }

    .preview-teams {
      display: flex;
      justify-content: center;
      align-items: flex-start;


      .teams-team {
        display: flex;
        width: 152px;
        padding: 0px 8px;
        flex-direction: column;
        align-items: center;
        gap: 8px;

        .team-logo {
          display: flex;
          width: 64px;
          height: 64px;
          padding: 9px;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          background: var(--neutral-n-600, #363436);

          img {
            width: 100%;
            height: 100%;
          }
        }

        .team-text {
          align-self: stretch;
          color: var(--neutral-n-100, #F6F6F6);
          text-align: center;
          /* Paragraph/P-M - Medium */
          font-family: Nekst, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 22.4px; /* 140% */
        }
      }

      .team-vs {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;
        align-self: stretch;
      }
    }
  }

  .teams-input {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    .input-team {
      display: flex;
      padding: 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      flex: 1 0 0;
      background: var(--neutral-n-700, #201D20);
      width: 100%;

      .filteredSelect  {
        width: 100%;

        .mbsc-input {
          margin: 0;
        }
      }
    }
  }

  .teams-competition {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0;
    background: var(--neutral-n-700, #201D20);
    width: 100%;

    .filteredSelect  {
      width: 100%;

      .mbsc-input {
        margin: 0;
      }
    }
  }

  .mbsc-label {
    position: relative !important;
    margin-bottom: -10px !important;
    align-self: stretch;
    color: var(--neutral-n-100, #F6F6F6) !important;
    /* Paragraph/P-S - Medium */
    font-family: Nekst !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 19.6px !important; /* 140% */
    text-transform: none !important;
  }
}


@media screen and (max-width: $breakpoints-mobile) {
  .main-teamsStep {
    margin-bottom: 28px;

    .teams-input {
      display: flex;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      flex-direction: column;

      .input-team {
        padding: 12px;
      }
    }
  } 
}
