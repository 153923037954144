.staige-badge {
  display: flex;
  padding: 2px 6px;
  align-items: center;
  border-radius: 2px;
  background: var(--neutral-n-500, #525052);
  text-wrap: nowrap;

  .badge-title {
    color: var(--neutral-n-100, #F6F6F6);
    /* Tags & Badges/badge */
    font-family: Nekst;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 10px */
    letter-spacing: 0.4px;
    text-transform: uppercase;
  }
}

.color-white {
  background: var(--neutral-n-100, #F6F6F6);

  .badge-title {
    color: var(--neutral-n-800, #151315);
  }
}

.color-violet {
  background: var(--violet-v-500, #672CBA);
}

.color-blue {
  background: var(--blue-b-500, #2E6BCC);
}

.color-red {
  background: var(--orange-o-500, #E01E00);
}

.color-rosa {
  background: var(--rosa-ro-500, #B828AC);
}

.color-aqua {
  background: var(--aqua-a-500, #04C8AB);
  .badge-title {
    color: var(--neutral-n-800, #151315);
  }
}