@import '../../_variables.scss';


.staige-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background: var(--orange-o-400, #FF3517);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.20), 0px 1px 2px 0px rgba(0, 0, 0, 0.28);
  width: 34px;
  height: 34px;
  text-decoration: none !important;
  flex-shrink: 0;
  user-select: none;

  .medium {
    display: flex;
    padding: 7px;
    justify-content: center;
    align-items: center;
    gap: 4px;

    img {
      width: 18px;
      height: 18px;
    }

    .label {
      color: var(--neutral-n-100, #F6F6F6);
      /* Button/Button M */
      font-family: Nekst;
      font-size: 12px;
      font-style: normal;
      font-weight: 900;
      line-height: 100%; /* 12px */
      letter-spacing: 0.4px;
      text-transform: uppercase;
      text-wrap: nowrap;
    }
  }

  .small {
    img {
      width: 14px;
      height: 14px;
    }

    .label {
      font-family: Nekst;
      font-size: 10px;
      font-style: normal;
      font-weight: 900;
      line-height: 100%; /* 10px */
      letter-spacing: 0.4px;
      text-transform: uppercase;
    }
  }
}

.staige-button-small {
  width: 24px;
  height: 24px;
}

.staige-button-large {
  width: 40px;
  height: 40px;
  padding: 10px 14px !important;

  .label {
    font-size: 14px !important;
  }
}

.staige-button-withLabel {
  padding: 8px 12px;
  gap: 4px;
  width: auto;
  min-width: 0;
}

.staige-button-withLabel-small {
  padding: 5px 2px;
  gap: 4px;
  width: auto;
  min-width: 0;
}

.staige-buttonRound {
  border-radius: 100px;
}

.staige-button:hover {
  background: var(--orange-o-500, #E01E00);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.20), 0px 1px 2px 0px rgba(0, 0, 0, 0.28);
}

.staige-button:active  {
  background: var(--orange-o-600, #A31600);
}

.staige-button:disabled {
  background: var(--neutral-n-50, rgba(246, 246, 246, 0.16));
  cursor: unset;
}

.staige-buttonDisabled {
  background: var(--neutral-n-50, rgba(246, 246, 246, 0.16)) !important;
  cursor: default !important;

  img {
    opacity: 0.2;
  }

  .medium {
    .label {
      color: var(--neutral-n-50, rgba(246, 246, 246, 0.16)) !important;
    }
  }
}

// secondary
.staige-button-secondary {
  background: transparent;
  border: 1px solid var(--neutral-n-100, #F6F6F6);
}

.staige-button-secondary:hover {
  border: 1px solid var(--neutral-n-100, #F6F6F6);
  background: var(--neutral-n-50, rgba(246, 246, 246, 0.16));
}

.staige-button-secondary:active  {
  border: 1px solid var(--neutral-n-200, #D6D6D6);
  background: var(--neutral-n-50, rgba(246, 246, 246, 0.16));
}

.staige-secondary-buttonDisabled {
  border: 1px solid var(--neutral-n-50, rgba(246, 246, 246, 0.16)) !important;
  background: transparent !important;
  cursor: default !important;

  img {
    opacity: 0.2;
  }

  .medium {
    .label {
      color: var(--neutral-n-50, rgba(246, 246, 246, 0.16)) !important;
    }
  }
}

// danger-secondary
.staige-button-danger-secondary {
  background: transparent;
  border: 1px solid var(--Red-R400, #D14343);

  .medium {
    .label {
      color: var(--Red-R400, #D14343) !important;
    }
  }
}

.staige-button-danger-secondary:hover {
  border-radius: 2px;
  border: 1px solid var(--Red-R400, #D14343);
  background: var(--Red-R100, rgba(209, 67, 67, 0.32));

  .medium {
    .label {
      color:  var(--Neutral-N100, #F6F6F6) !important;
    }
  }
}

.staige-button-danger-secondary:active  {
  border: 1px solid var(--Red-R500, #A73636);
  background: var(--Red-R100, rgba(209, 67, 67, 0.32));

  .medium {
    .label {
      color: var(--Neutral-N200, #D6D6D6) !important;
    }
  }
}

.staige-danger-secondary-buttonDisabled {
  border: 1px solid var(--Red-R100, rgba(209, 67, 67, 0.32)) !important;
  background: transparent !important;
  cursor: default !important;

  img {
    opacity: 0.2;
  }

  .medium {
    .label {
      color: var(--Red-R100, rgba(209, 67, 67, 0.32)) !important;
    }
  }
}

// ghost
.staige-button-ghost {
  background: transparent;
  border: none;
  box-shadow: none;
}

.staige-button-ghost:hover {
  border: none;
  background: var(--neutral-n-50, rgba(246, 246, 246, 0.16));
}

.staige-button-ghost:active  {
  border: none;
  background: var(--neutral-n-50, rgba(246, 246, 246, 0.16));

  .medium {
    .label {
      color: var(--neutral-n-200, #D6D6D6);
    }
  }
}

.staige-ghost-buttonDisabled {
  // background: var(--neutral-n-50, rgba(246, 246, 246, 0.16)) !important;
  cursor: default !important;

  img {
    opacity: 0.2;
  }

  .medium {
    .label {
      color: var(--neutral-n-50, rgba(246, 246, 246, 0.16)) !important;
    }
  }
}

.staige-ghost-buttonDisabled:hover {
  background: transparent !important;
}

// white
.staige-button-white {
  background: var(--neutral-n-100, #F6F6F6);
  border: none;
  box-shadow: none;

  .medium {
    .label {
      color: var(--neutral-n-800, #151315);
    }

    img {
      filter: invert(0) sepia(0) saturate(0%) hue-rotate(0) brightness(0%) contrast(91%) !important;
    }
  }
}

.staige-button-white:hover {
  border: none;
  background: var(--neutral-n-200, #D6D6D6);
  /* Shadow-2 */
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.20), 0px 1px 2px 0px rgba(0, 0, 0, 0.28);
}

.staige-button-white:active  {
  border: none;
  background: var(--neutral-n-100, #F6F6F6);
  /* Shadow-2 */
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.20), 0px 1px 2px 0px rgba(0, 0, 0, 0.28);

  .medium {
    .label {
      color: var(--neutral-n-800, #151315);
    }
  }
}

.staige-white-buttonDisabled {
  background: var(--neutral-n-50, rgba(246, 246, 246, 0.16)) !important;
  cursor: default !important;

  img {
    opacity: 0.2;
  }

  .medium {
    .label {
      color: var(--neutral-n-50, rgba(246, 246, 246, 0.16)) !important;
    }

    img {
      filter: invert(42%) sepia(93%) saturate(0%) hue-rotate(87deg) brightness(119%) contrast(119%) !important;
    }
  }
}