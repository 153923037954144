@import '../../../../_variables.scss';

.page-aboUpgrade {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  height: 100%;

 .content-wrap {
    display: flex;
    width: 674px;
    padding: 24px 0px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;

    .wrap-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 32px;
      flex: 1 0 0;
      align-self: stretch;

      .content-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        align-self: stretch;

        .header-title {
          align-self: stretch;
          color: var(--Neutral-N400, #999);
          text-align: center;
          /* Paragraph/P-S - Medium */
          font-family: Nekst;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 19.6px; /* 140% */
        }

        .header-subTitle {
          align-self: stretch;
          color: var(--Neutral-N100, #F6F6F6);
          text-align: center;
          font-family: Nekst;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 30px; /* 125% */
          letter-spacing: 0.4px;
          text-transform: uppercase;
        }
      }

      .content-body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        flex: 1 0 0;
        align-self: stretch;

        .body-benefitBar {
          display: flex;
          height: 44px;
          padding: 12px 24px;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          align-self: stretch;
          border: 1px solid var(--Aqua-A400, #60FCE4);
          background: var(--Aqua-A100, rgba(96, 252, 228, 0.32));

          .benefitBar-list {
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;

            .list-item {
              display: flex;
              align-items: center;
              gap: 4px;
              text-wrap: nowrap;

              .item-icon {
                width: 16px;
                height: 16px;
              }

              .item-text {
                color: var(--Aqua-A200, #CAFCF4);
                /* Paragraph/P-S - Medium */
                font-family: Nekst;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 19.6px; /* 140% */
              }
            }
          }
        }

        .body-stripe {
          display: flex;
          padding: 24px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 24px;
          flex: 1 0 0;
          align-self: stretch;
          background: var(--Neutral-N700, #201D20);

          .stripe-title {
            color: #FFF;
            /* Paragraph/P-S - Medium */
            font-family: Nekst;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 19.6px; /* 140% */
          }
        }

        .body-form {
          display: flex;
          padding: 24px;
          flex-direction: column;
          align-items: flex-start;
          gap: 24px;
          align-self: stretch;
          background: var(--Neutral-N700, #201D20);

          .form-title {
            align-self: stretch;
            color: var(--Neutral-N400, #999);
            /* Overline/O01 */
            font-family: Nekst;
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 14px; /* 140% */
            letter-spacing: 0.3px;
            text-transform: uppercase;
          }

          .form-list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
            align-self: stretch;
          }

          .form-agb {
            display: flex;
            padding: 8px;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            border-radius: 2px;
            background: var(--Neutral-N600, #363436);

            .agbBox-a {
              color: #F6F6F6;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoints-mobile) {
  .page-aboUpgrade {
    .content-wrap {
      width: 100%;
     
      .wrap-content {
        gap: 24px;

        .content-body {
          .body-benefitBar {
            display: flex;
            padding: 12px;
            flex-direction: row;
            align-items: flex-start;
            gap: 12px;
            align-self: stretch;
            overflow: overlay;            

            .benefitBar-list {
              gap: 16px;
            }
          }

          .body-benefitBar::-webkit-scrollbar {
            display: none;
          }

          .body-form {
            padding: 12px;
          }
        }
      }
    }
  }
}