.main-sponsor {
  display: flex;
  padding-bottom: 0px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;

  .sponsor-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    .container-textInfo {
      display: flex;
      gap: 4px;

      .textInfo-t {
        color: var(--neutral-n-400, #999);
        /* Overline/O01 */
        font-family: Nekst;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px; /* 140% */
        letter-spacing: 0.3px;
        text-transform: uppercase;
      }
      
      img {
        cursor: pointer;
      }
    }

    .sponsor-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      align-self: stretch;
  
      .list-item {
        display: flex;
        padding: 16px;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        background: var(--neutral-n-700, #201D20);
        user-select: none;
  
        .item-img {
          display: flex;
          width: 48px;
          height: 48px;
          align-items: center;
          border-radius: 4px;
          background: var(--neutral-n-600, #363436);
  
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 4px;
          }
        }
  
        .item-content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex: 1 0 0;
          align-self: stretch;
          align-items: center;
          gap: 8px;
  
          .content-title {
            align-self: stretch;
            color: var(--neutral-n-100, #F6F6F6);
            /* Paragraph/P-M - Medium */
            font-family: Nekst;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 22.4px; /* 140% */
          }
  
          .content-subTitle {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 1 0 0;
            align-self: stretch;
            justify-content: flex-end;
  
            .subTitle-container {
              display: flex;
              align-items: center;
              gap: 4px;
  
              .container-text {
                display: flex;
                color: var(--neutral-n-100, #F6F6F6);
                font-family: Nekst;
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: 14px; /* 140% */
                letter-spacing: 0.3px;
                text-transform: uppercase;
                display: flex;
                gap: 4px;
              }
            }
          }
        }
  
        .item-toggle {
          display: flex;
          height: 24px;
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }

  .sponsor-addLink {
    display: flex;
    height: 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    a {
      color: var(--neutral-n-400, #999);
      /* Link/L-S */
      font-family: Nekst;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 14px */
      text-decoration-line: underline;
      user-select: none;
    }

    a:hover {
      color: var(--neutral-n-100, #F6F6F6);
    }
  }
}