p.option{
    padding: 5px 0px;
    margin: 5px 0px;
}
p.option:hover{
    background-color: gray;
}
span.headline{
    
}
span.subLabel{
    font-size: 0.75em;
    color: #777777;
}
.hidden{
    visibility: hidden;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: -1;
}

.filterSelect-content {
    .mbsc-mobiscroll.mbsc-control-w {
        margin: 1em 0em !important;
    }
}
