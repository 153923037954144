
$mbsc-font-path: '' !default;

@font-face {
    font-family: 'Mobiscroll';
    src: 
        url($mbsc-font-path + 'icons_mobiscroll.woff?ntghy') format('woff'),
        url($mbsc-font-path + 'icons_mobiscroll.woff') format('woff'),
        url($mbsc-font-path + 'icons_mobiscroll.ttf?ntghy') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.mbsc-font-icon:before {
    font-family: 'Mobiscroll';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* Icons */

.mbsc-icon-IE::before {
    content: "\ea01";
}
.mbsc-icon-IcoMoon::before {
    content: "\ea02";
}
.mbsc-icon-accessibility::before {
    content: "\ea03";
}
.mbsc-icon-address-book::before {
    content: "\ea04";
}
.mbsc-icon-aid::before {
    content: "\ea05";
}
.mbsc-icon-airplane::before {
    content: "\ea06";
}
.mbsc-icon-alarm::before {
    content: "\ea07";
}
.mbsc-icon-alarm2::before {
    content: "\ea08";
}
.mbsc-icon-android::before {
    content: "\ea09";
}
.mbsc-icon-angry::before {
    content: "\ea0a";
}
.mbsc-icon-angry2::before {
    content: "\ea0b";
}
.mbsc-icon-apple::before {
    content: "\ea0c";
}
.mbsc-icon-arrow-down-left::before {
    content: "\ea0d";
}
.mbsc-icon-arrow-down-left2::before {
    content: "\ea0e";
}
.mbsc-icon-arrow-down-left3::before {
    content: "\ea0f";
}
.mbsc-icon-arrow-down-right::before {
    content: "\ea10";
}
.mbsc-icon-arrow-down-right2::before {
    content: "\ea11";
}
.mbsc-icon-arrow-down-right3::before {
    content: "\ea12";
}
.mbsc-icon-arrow-down::before {
    content: "\ea13";
}
.mbsc-icon-arrow-down2::before {
    content: "\ea14";
}
.mbsc-icon-arrow-down3::before {
    content: "\ea15";
}
.mbsc-icon-arrow-down5::before {
    content: "\ea16";
}
.mbsc-icon-arrow-left::before {
    content: "\ea17";
}
.mbsc-icon-arrow-left2::before {
    content: "\ea18";
}
.mbsc-icon-arrow-left3::before {
    content: "\ea19";
}
.mbsc-icon-arrow-left5::before {
    content: "\ea1a";
}
.mbsc-icon-arrow-left6::before {
    content: "\ea1b";
}
.mbsc-icon-arrow-right::before {
    content: "\ea1c";
}
.mbsc-icon-arrow-right2::before {
    content: "\ea1d";
}
.mbsc-icon-arrow-right3::before {
    content: "\ea1e";
}
.mbsc-icon-arrow-right5::before {
    content: "\ea1f";
}
.mbsc-icon-arrow-right6::before {
    content: "\ea20";
}
.mbsc-icon-arrow-up-left::before {
    content: "\ea21";
}
.mbsc-icon-arrow-up-left2::before {
    content: "\ea22";
}
.mbsc-icon-arrow-up-left3::before {
    content: "\ea23";
}
.mbsc-icon-arrow-up-right::before {
    content: "\ea24";
}
.mbsc-icon-arrow-up-right2::before {
    content: "\ea25";
}
.mbsc-icon-arrow-up-right3::before {
    content: "\ea26";
}
.mbsc-icon-arrow-up::before {
    content: "\ea27";
}
.mbsc-icon-arrow-up2::before {
    content: "\ea28";
}
.mbsc-icon-arrow-up3::before {
    content: "\ea29";
}
.mbsc-icon-arrow-up5::before {
    content: "\ea2a";
}
.mbsc-icon-attachment::before {
    content: "\ea2b";
}
.mbsc-icon-backward::before {
    content: "\ea2c";
}
.mbsc-icon-backward2::before {
    content: "\ea2d";
}
.mbsc-icon-barcode::before {
    content: "\ea2e";
}
.mbsc-icon-bars::before {
    content: "\ea2f";
}
.mbsc-icon-bars2::before {
    content: "\ea30";
}
.mbsc-icon-bell::before {
    content: "\ea31";
}
.mbsc-icon-binoculars::before {
    content: "\ea32";
}
.mbsc-icon-blocked::before {
    content: "\ea33";
}
.mbsc-icon-blog::before {
    content: "\ea34";
}
.mbsc-icon-blogger::before {
    content: "\ea35";
}
.mbsc-icon-blogger2::before {
    content: "\ea36";
}
.mbsc-icon-bold::before {
    content: "\ea37";
}
.mbsc-icon-book::before {
    content: "\ea38";
}
.mbsc-icon-bookmark::before {
    content: "\ea39";
}
.mbsc-icon-bookmarks::before {
    content: "\ea3a";
}
.mbsc-icon-books::before {
    content: "\ea3b";
}
.mbsc-icon-box-add::before {
    content: "\ea3c";
}
.mbsc-icon-box-remove::before {
    content: "\ea3d";
}
.mbsc-icon-briefcase::before {
    content: "\ea3e";
}
.mbsc-icon-brightness-contrast::before {
    content: "\ea3f";
}
.mbsc-icon-brightness-medium::before {
    content: "\ea40";
}
.mbsc-icon-bubble::before {
    content: "\ea41";
}
.mbsc-icon-bubble2::before {
    content: "\ea42";
}
.mbsc-icon-bubbles::before {
    content: "\ea43";
}
.mbsc-icon-bubbles2::before {
    content: "\ea44";
}
.mbsc-icon-bubbles3::before {
    content: "\ea45";
}
.mbsc-icon-bubbles4::before {
    content: "\ea46";
}
.mbsc-icon-bug::before {
    content: "\ea47";
}
.mbsc-icon-bullhorn::before {
    content: "\ea48";
}
.mbsc-icon-busy::before {
    content: "\ea49";
}
.mbsc-icon-cabinet::before {
    content: "\ea4a";
}
.mbsc-icon-calculate::before {
    content: "\ea4b";
}
.mbsc-icon-calendar::before {
    content: "\ea4c";
}
.mbsc-icon-calendar2::before {
    content: "\ea4d";
}
.mbsc-icon-camera::before {
    content: "\ea4e";
}
.mbsc-icon-camera2::before {
    content: "\ea4f";
}
.mbsc-icon-cancel-circle::before {
    content: "\ea50";
}
.mbsc-icon-cart::before {
    content: "\ea51";
}
.mbsc-icon-cart2::before {
    content: "\ea52";
}
.mbsc-icon-cart3::before {
    content: "\ea53";
}
.mbsc-icon-checkbox-checked::before {
    content: "\ea54";
}
.mbsc-icon-checkbox-partial::before {
    content: "\ea55";
}
.mbsc-icon-checkbox-unchecked::before {
    content: "\ea56";
}
.mbsc-icon-checkmark-circle::before {
    content: "\ea57";
}
.mbsc-icon-checkmark::before {
    content: "\ea58";
}
.mbsc-icon-checkmark2::before {
    content: "\ea59";
}
.mbsc-icon-chrome::before {
    content: "\ea5a";
}
.mbsc-icon-clock::before {
    content: "\ea5b";
}
.mbsc-icon-clock2::before {
    content: "\ea5d";
}
.mbsc-icon-close::before {
    content: "\ea5c";
}
.mbsc-icon-cloud-download::before {
    content: "\ea5e";
}
.mbsc-icon-cloud-upload::before {
    content: "\ea5f";
}
.mbsc-icon-cloud::before {
    content: "\ea60";
}
.mbsc-icon-clubs::before {
    content: "\ea61";
}
.mbsc-icon-code::before {
    content: "\ea62";
}
.mbsc-icon-cog::before {
    content: "\ea63";
}
.mbsc-icon-cog2::before {
    content: "\ea64";
}
.mbsc-icon-cogs::before {
    content: "\ea65";
}
.mbsc-icon-coin::before {
    content: "\ea66";
}
.mbsc-icon-compass::before {
    content: "\ea67";
}
.mbsc-icon-confused::before {
    content: "\ea68";
}
.mbsc-icon-confused2::before {
    content: "\ea69";
}
.mbsc-icon-connection::before {
    content: "\ea6a";
}
.mbsc-icon-console::before {
    content: "\ea6b";
}
.mbsc-icon-contract::before {
    content: "\ea6c";
}
.mbsc-icon-contract2::before {
    content: "\ea6d";
}
.mbsc-icon-contrast::before {
    content: "\ea6e";
}
.mbsc-icon-cool::before {
    content: "\ea6f";
}
.mbsc-icon-cool2::before {
    content: "\ea70";
}
.mbsc-icon-copy::before {
    content: "\ea71";
}
.mbsc-icon-copy2::before {
    content: "\ea72";
}
.mbsc-icon-copy3::before {
    content: "\ea73";
}
.mbsc-icon-credit::before {
    content: "\ea74";
}
.mbsc-icon-crop::before {
    content: "\ea75";
}
.mbsc-icon-css3::before {
    content: "\ea76";
}
.mbsc-icon-dashboard::before {
    content: "\ea77";
}
.mbsc-icon-delicious::before {
    content: "\ea78";
}
.mbsc-icon-deviantart::before {
    content: "\ea79";
}
.mbsc-icon-deviantart2::before {
    content: "\ea7a";
}
.mbsc-icon-diamonds::before {
    content: "\ea7b";
}
.mbsc-icon-dice::before {
    content: "\ea7c";
}
.mbsc-icon-disk::before {
    content: "\ea7d";
}
.mbsc-icon-download::before {
    content: "\ea7e";
}
.mbsc-icon-download2::before {
    content: "\ea7f";
}
.mbsc-icon-download3::before {
    content: "\ea80";
}
.mbsc-icon-drawer::before {
    content: "\ea81";
}
.mbsc-icon-drawer2::before {
    content: "\ea82";
}
.mbsc-icon-drawer3::before {
    content: "\ea83";
}
.mbsc-icon-dribbble::before {
    content: "\ea84";
}
.mbsc-icon-dribbble2::before {
    content: "\ea85";
}
.mbsc-icon-dribbble3::before {
    content: "\ea86";
}
.mbsc-icon-droplet::before {
    content: "\ea87";
}
.mbsc-icon-earth::before {
    content: "\ea88";
}
.mbsc-icon-eject::before {
    content: "\ea89";
}
.mbsc-icon-embed::before {
    content: "\ea8a";
}
.mbsc-icon-enter::before {
    content: "\ea8b";
}
.mbsc-icon-envelope::before {
    content: "\ea8c";
}
.mbsc-icon-equalizer::before {
    content: "\ea8d";
}
.mbsc-icon-evil::before {
    content: "\ea8e";
}
.mbsc-icon-evil2::before {
    content: "\ea8f";
}
.mbsc-icon-exit::before {
    content: "\ea90";
}
.mbsc-icon-expand::before {
    content: "\ea91";
}
.mbsc-icon-expand2::before {
    content: "\ea92";
}
.mbsc-icon-eye-blocked::before {
    content: "\ea93";
}
.mbsc-icon-eye::before {
    content: "\ea94";
}
.mbsc-icon-eye2::before {
    content: "\ea95";
}
.mbsc-icon-fa-500px::before {
    content: "\ea96";
}
.mbsc-icon-fa-address-book-o::before {
    content: "\ea97";
}
.mbsc-icon-fa-address-book::before {
    content: "\ea98";
}
.mbsc-icon-fa-address-card-o::before {
    content: "\ea99";
}
.mbsc-icon-fa-address-card::before {
    content: "\ea9a";
}
.mbsc-icon-fa-adjust::before {
    content: "\ea9b";
}
.mbsc-icon-fa-adn::before {
    content: "\ea9c";
}
.mbsc-icon-fa-align-center::before {
    content: "\ea9d";
}
.mbsc-icon-fa-align-justify::before {
    content: "\ea9e";
}
.mbsc-icon-fa-align-left::before {
    content: "\ea9f";
}
.mbsc-icon-fa-align-right::before {
    content: "\eaa0";
}
.mbsc-icon-fa-amazon::before {
    content: "\eaa1";
}
.mbsc-icon-fa-ambulance::before {
    content: "\eaa2";
}
.mbsc-icon-fa-american-sign-language-interpreting::before {
    content: "\eaa3";
}
.mbsc-icon-fa-anchor::before {
    content: "\eaa4";
}
.mbsc-icon-fa-android::before {
    content: "\eaa5";
}
.mbsc-icon-fa-angellist::before {
    content: "\eaa6";
}
.mbsc-icon-fa-angle-double-down::before {
    content: "\eaa7";
}
.mbsc-icon-fa-angle-double-left::before {
    content: "\eaa8";
}
.mbsc-icon-fa-angle-double-right::before {
    content: "\eaa9";
}
.mbsc-icon-fa-angle-double-up::before {
    content: "\eaaa";
}
.mbsc-icon-fa-angle-down::before {
    content: "\eaab";
}
.mbsc-icon-fa-angle-left::before {
    content: "\eaac";
}
.mbsc-icon-fa-angle-right::before {
    content: "\eaad";
}
.mbsc-icon-fa-angle-up::before {
    content: "\eaae";
}
.mbsc-icon-fa-apple::before {
    content: "\eaaf";
}
.mbsc-icon-fa-archive::before {
    content: "\eab0";
}
.mbsc-icon-fa-area-chart::before {
    content: "\eab1";
}
.mbsc-icon-fa-arrow-circle-down::before {
    content: "\eab2";
}
.mbsc-icon-fa-arrow-circle-left::before {
    content: "\eab3";
}
.mbsc-icon-fa-arrow-circle-o-down::before {
    content: "\eab4";
}
.mbsc-icon-fa-arrow-circle-o-left::before {
    content: "\eab5";
}
.mbsc-icon-fa-arrow-circle-o-right::before {
    content: "\eab6";
}
.mbsc-icon-fa-arrow-circle-o-up::before {
    content: "\eab7";
}
.mbsc-icon-fa-arrow-circle-right::before {
    content: "\eab8";
}
.mbsc-icon-fa-arrow-circle-up::before {
    content: "\eab9";
}
.mbsc-icon-fa-arrow-down::before {
    content: "\eaba";
}
.mbsc-icon-fa-arrow-left::before {
    content: "\eabb";
}
.mbsc-icon-fa-arrow-right::before {
    content: "\eabc";
}
.mbsc-icon-fa-arrow-up::before {
    content: "\eabd";
}
.mbsc-icon-fa-arrows-alt::before {
    content: "\eabe";
}
.mbsc-icon-fa-arrows-h::before {
    content: "\eabf";
}
.mbsc-icon-fa-arrows-v::before {
    content: "\eac0";
}
.mbsc-icon-fa-arrows::before {
    content: "\eac1";
}
.mbsc-icon-fa-assistive-listening-systems::before {
    content: "\eac2";
}
.mbsc-icon-fa-asterisk::before {
    content: "\eac3";
}
.mbsc-icon-fa-at::before {
    content: "\eac4";
}
.mbsc-icon-fa-audio-description::before {
    content: "\eac5";
}
.mbsc-icon-fa-automobile::before {
    content: "\eac6";
}
.mbsc-icon-fa-backward::before {
    content: "\eac7";
}
.mbsc-icon-fa-balance-scale::before {
    content: "\eac8";
}
.mbsc-icon-fa-ban::before {
    content: "\eac9";
}
.mbsc-icon-fa-bandcamp::before {
    content: "\eaca";
}
.mbsc-icon-fa-bank::before {
    content: "\eacb";
}
.mbsc-icon-fa-bar-chart-o::before {
    content: "\eacc";
}
.mbsc-icon-fa-bar-chart::before {
    content: "\eacd";
}
.mbsc-icon-fa-barcode::before {
    content: "\eace";
}
.mbsc-icon-fa-bars::before {
    content: "\eacf";
}
.mbsc-icon-fa-bath::before {
    content: "\ead0";
}
.mbsc-icon-fa-battery-0::before {
    content: "\ead1";
}
.mbsc-icon-fa-battery-1::before {
    content: "\ead2";
}
.mbsc-icon-fa-battery-2::before {
    content: "\ead3";
}
.mbsc-icon-fa-battery-3::before {
    content: "\ead4";
}
.mbsc-icon-fa-battery-4::before {
    content: "\ead5";
}
.mbsc-icon-fa-battery::before {
    content: "\ead6";
}
.mbsc-icon-fa-bed::before {
    content: "\ead7";
}
.mbsc-icon-fa-beer::before {
    content: "\ead8";
}
.mbsc-icon-fa-behance-square::before {
    content: "\ead9";
}
.mbsc-icon-fa-behance::before {
    content: "\eada";
}
.mbsc-icon-fa-bell-o::before {
    content: "\eadb";
}
.mbsc-icon-fa-bell-slash-o::before {
    content: "\eadc";
}
.mbsc-icon-fa-bell-slash::before {
    content: "\eadd";
}
.mbsc-icon-fa-bell::before {
    content: "\eade";
}
.mbsc-icon-fa-bicycle::before {
    content: "\eadf";
}
.mbsc-icon-fa-binoculars::before {
    content: "\eae0";
}
.mbsc-icon-fa-birthday-cake::before {
    content: "\eae1";
}
.mbsc-icon-fa-bitbucket-square::before {
    content: "\eae2";
}
.mbsc-icon-fa-bitbucket::before {
    content: "\eae3";
}
.mbsc-icon-fa-bitcoin::before {
    content: "\eae4";
}
.mbsc-icon-fa-black-tie::before {
    content: "\eae5";
}
.mbsc-icon-fa-blind::before {
    content: "\eae6";
}
.mbsc-icon-fa-bluetooth-b::before {
    content: "\eae7";
}
.mbsc-icon-fa-bluetooth::before {
    content: "\eae8";
}
.mbsc-icon-fa-bold::before {
    content: "\eae9";
}
.mbsc-icon-fa-bolt::before {
    content: "\eaea";
}
.mbsc-icon-fa-bomb::before {
    content: "\eaeb";
}
.mbsc-icon-fa-book::before {
    content: "\eaec";
}
.mbsc-icon-fa-bookmark-o::before {
    content: "\eaed";
}
.mbsc-icon-fa-bookmark::before {
    content: "\eaee";
}
.mbsc-icon-fa-braille::before {
    content: "\eaef";
}
.mbsc-icon-fa-briefcase::before {
    content: "\eaf0";
}
.mbsc-icon-fa-bug::before {
    content: "\eaf1";
}
.mbsc-icon-fa-building-o::before {
    content: "\eaf2";
}
.mbsc-icon-fa-building::before {
    content: "\eaf3";
}
.mbsc-icon-fa-bullhorn::before {
    content: "\eaf4";
}
.mbsc-icon-fa-bullseye::before {
    content: "\eaf5";
}
.mbsc-icon-fa-bus::before {
    content: "\eaf6";
}
.mbsc-icon-fa-buysellads::before {
    content: "\eaf7";
}
.mbsc-icon-fa-cab::before {
    content: "\eaf8";
}
.mbsc-icon-fa-calculator::before {
    content: "\eaf9";
}
.mbsc-icon-fa-calendar-check-o::before {
    content: "\eafa";
}
.mbsc-icon-fa-calendar-minus-o::before {
    content: "\eafb";
}
.mbsc-icon-fa-calendar-o::before {
    content: "\eafc";
}
.mbsc-icon-fa-calendar-plus-o::before {
    content: "\eafd";
}
.mbsc-icon-fa-calendar-times-o::before {
    content: "\eafe";
}
.mbsc-icon-fa-calendar::before {
    content: "\eaff";
}
.mbsc-icon-fa-camera-retro::before {
    content: "\eb00";
}
.mbsc-icon-fa-camera::before {
    content: "\eb01";
}
.mbsc-icon-fa-caret-down::before {
    content: "\eb02";
}
.mbsc-icon-fa-caret-left::before {
    content: "\eb03";
}
.mbsc-icon-fa-caret-right::before {
    content: "\eb04";
}
.mbsc-icon-fa-caret-square-o-down::before {
    content: "\eb05";
}
.mbsc-icon-fa-caret-square-o-left::before {
    content: "\eb06";
}
.mbsc-icon-fa-caret-square-o-right::before {
    content: "\eb07";
}
.mbsc-icon-fa-caret-square-o-up::before {
    content: "\eb08";
}
.mbsc-icon-fa-caret-up::before {
    content: "\eb09";
}
.mbsc-icon-fa-cart-arrow-down::before {
    content: "\eb0a";
}
.mbsc-icon-fa-cart-plus::before {
    content: "\eb0b";
}
.mbsc-icon-fa-cc-amex::before {
    content: "\eb0c";
}
.mbsc-icon-fa-cc-diners-club::before {
    content: "\eb0d";
}
.mbsc-icon-fa-cc-discover::before {
    content: "\eb0e";
}
.mbsc-icon-fa-cc-jcb::before {
    content: "\eb0f";
}
.mbsc-icon-fa-cc-mastercard::before {
    content: "\eb10";
}
.mbsc-icon-fa-cc-paypal::before {
    content: "\eb11";
}
.mbsc-icon-fa-cc-stripe::before {
    content: "\eb12";
}
.mbsc-icon-fa-cc-visa::before {
    content: "\eb13";
}
.mbsc-icon-fa-cc::before {
    content: "\eb14";
}
.mbsc-icon-fa-certificate::before {
    content: "\eb15";
}
.mbsc-icon-fa-chain-broken::before {
    content: "\eb16";
}
.mbsc-icon-fa-chain::before {
    content: "\eb17";
}
.mbsc-icon-fa-check-circle-o::before {
    content: "\eb18";
}
.mbsc-icon-fa-check-circle::before {
    content: "\eb19";
}
.mbsc-icon-fa-check-square-o::before {
    content: "\eb1a";
}
.mbsc-icon-fa-check-square::before {
    content: "\eb1b";
}
.mbsc-icon-fa-check::before {
    content: "\eb1c";
}
.mbsc-icon-fa-chevron-circle-down::before {
    content: "\eb1d";
}
.mbsc-icon-fa-chevron-circle-left::before {
    content: "\eb1e";
}
.mbsc-icon-fa-chevron-circle-right::before {
    content: "\eb1f";
}
.mbsc-icon-fa-chevron-circle-up::before {
    content: "\eb20";
}
.mbsc-icon-fa-chevron-down::before {
    content: "\eb21";
}
.mbsc-icon-fa-chevron-left::before {
    content: "\eb22";
}
.mbsc-icon-fa-chevron-right::before {
    content: "\eb23";
}
.mbsc-icon-fa-chevron-up::before {
    content: "\eb24";
}
.mbsc-icon-fa-child::before {
    content: "\eb25";
}
.mbsc-icon-fa-chrome::before {
    content: "\eb26";
}
.mbsc-icon-fa-circle-o-notch::before {
    content: "\eb27";
}
.mbsc-icon-fa-circle-o::before {
    content: "\eb28";
}
.mbsc-icon-fa-circle-thin::before {
    content: "\eb29";
}
.mbsc-icon-fa-circle::before {
    content: "\eb2a";
}
.mbsc-icon-fa-clipboard::before {
    content: "\eb2b";
}
.mbsc-icon-fa-clock-o::before {
    content: "\eb2c";
}
.mbsc-icon-fa-clone::before {
    content: "\eb2d";
}
.mbsc-icon-fa-close::before {
    content: "\eb2e";
}
.mbsc-icon-fa-cloud-download::before {
    content: "\eb2f";
}
.mbsc-icon-fa-cloud-upload::before {
    content: "\eb30";
}
.mbsc-icon-fa-cloud::before {
    content: "\eb31";
}
.mbsc-icon-fa-cny::before {
    content: "\eb32";
}
.mbsc-icon-fa-code-fork::before {
    content: "\eb33";
}
.mbsc-icon-fa-code::before {
    content: "\eb34";
}
.mbsc-icon-fa-codepen::before {
    content: "\eb35";
}
.mbsc-icon-fa-codiepie::before {
    content: "\eb36";
}
.mbsc-icon-fa-coffee::before {
    content: "\eb37";
}
.mbsc-icon-fa-cog::before {
    content: "\eb38";
}
.mbsc-icon-fa-cogs::before {
    content: "\eb39";
}
.mbsc-icon-fa-columns::before {
    content: "\eb3a";
}
.mbsc-icon-fa-comment-o::before {
    content: "\eb3b";
}
.mbsc-icon-fa-comment::before {
    content: "\eb3c";
}
.mbsc-icon-fa-commenting-o::before {
    content: "\eb3d";
}
.mbsc-icon-fa-commenting::before {
    content: "\eb3e";
}
.mbsc-icon-fa-comments-o::before {
    content: "\eb3f";
}
.mbsc-icon-fa-comments::before {
    content: "\eb40";
}
.mbsc-icon-fa-compass::before {
    content: "\eb41";
}
.mbsc-icon-fa-compress::before {
    content: "\eb42";
}
.mbsc-icon-fa-connectdevelop::before {
    content: "\eb43";
}
.mbsc-icon-fa-contao::before {
    content: "\eb44";
}
.mbsc-icon-fa-copy::before {
    content: "\eb45";
}
.mbsc-icon-fa-copyright::before {
    content: "\eb46";
}
.mbsc-icon-fa-creative-commons::before {
    content: "\eb47";
}
.mbsc-icon-fa-credit-card-alt::before {
    content: "\eb48";
}
.mbsc-icon-fa-credit-card::before {
    content: "\eb49";
}
.mbsc-icon-fa-crop::before {
    content: "\eb4a";
}
.mbsc-icon-fa-crosshairs::before {
    content: "\eb4b";
}
.mbsc-icon-fa-css3::before {
    content: "\eb4c";
}
.mbsc-icon-fa-cube::before {
    content: "\eb4d";
}
.mbsc-icon-fa-cubes::before {
    content: "\eb4e";
}
.mbsc-icon-fa-cut::before {
    content: "\eb4f";
}
.mbsc-icon-fa-cutlery::before {
    content: "\eb50";
}
.mbsc-icon-fa-dashboard::before {
    content: "\eb51";
}
.mbsc-icon-fa-dashcube::before {
    content: "\eb52";
}
.mbsc-icon-fa-database::before {
    content: "\eb53";
}
.mbsc-icon-fa-deaf::before {
    content: "\eb54";
}
.mbsc-icon-fa-dedent::before {
    content: "\eb55";
}
.mbsc-icon-fa-delicious::before {
    content: "\eb56";
}
.mbsc-icon-fa-desktop::before {
    content: "\eb57";
}
.mbsc-icon-fa-deviantart::before {
    content: "\eb58";
}
.mbsc-icon-fa-diamond::before {
    content: "\eb59";
}
.mbsc-icon-fa-digg::before {
    content: "\eb5a";
}
.mbsc-icon-fa-dollar::before {
    content: "\eb5b";
}
.mbsc-icon-fa-dot-circle-o::before {
    content: "\eb5c";
}
.mbsc-icon-fa-download::before {
    content: "\eb5d";
}
.mbsc-icon-fa-dribbble::before {
    content: "\eb5e";
}
.mbsc-icon-fa-drivers-license-o::before {
    content: "\eb5f";
}
.mbsc-icon-fa-drivers-license::before {
    content: "\eb60";
}
.mbsc-icon-fa-dropbox::before {
    content: "\eb61";
}
.mbsc-icon-fa-drupal::before {
    content: "\eb62";
}
.mbsc-icon-fa-edge::before {
    content: "\eb63";
}
.mbsc-icon-fa-edit::before {
    content: "\eb64";
}
.mbsc-icon-fa-eercast::before {
    content: "\eb65";
}
.mbsc-icon-fa-eject::before {
    content: "\eb66";
}
.mbsc-icon-fa-ellipsis-h::before {
    content: "\eb67";
}
.mbsc-icon-fa-ellipsis-v::before {
    content: "\eb68";
}
.mbsc-icon-fa-empire::before {
    content: "\eb69";
}
.mbsc-icon-fa-envelope-o::before {
    content: "\eb6a";
}
.mbsc-icon-fa-envelope-open-o::before {
    content: "\eb6b";
}
.mbsc-icon-fa-envelope-open::before {
    content: "\eb6c";
}
.mbsc-icon-fa-envelope-square::before {
    content: "\eb6d";
}
.mbsc-icon-fa-envelope::before {
    content: "\eb6e";
}
.mbsc-icon-fa-envira::before {
    content: "\eb6f";
}
.mbsc-icon-fa-eraser::before {
    content: "\eb70";
}
.mbsc-icon-fa-etsy::before {
    content: "\eb71";
}
.mbsc-icon-fa-eur::before {
    content: "\eb72";
}
.mbsc-icon-fa-euro::before {
    content: "\eb73";
}
.mbsc-icon-fa-exchange::before {
    content: "\eb74";
}
.mbsc-icon-fa-exclamation-circle::before {
    content: "\eb75";
}
.mbsc-icon-fa-exclamation-triangle::before {
    content: "\eb76";
}
.mbsc-icon-fa-exclamation::before {
    content: "\eb77";
}
.mbsc-icon-fa-expand::before {
    content: "\eb78";
}
.mbsc-icon-fa-expeditedssl::before {
    content: "\eb79";
}
.mbsc-icon-fa-external-link-square::before {
    content: "\eb7a";
}
.mbsc-icon-fa-external-link::before {
    content: "\eb7b";
}
.mbsc-icon-fa-eye-slash::before {
    content: "\eb7c";
}
.mbsc-icon-fa-eye::before {
    content: "\eb7d";
}
.mbsc-icon-fa-eyedropper::before {
    content: "\eb7e";
}
.mbsc-icon-fa-fa::before {
    content: "\eb7f";
}
.mbsc-icon-fa-facebook-official::before {
    content: "\eb80";
}
.mbsc-icon-fa-facebook-square::before {
    content: "\eb81";
}
.mbsc-icon-fa-facebook::before {
    content: "\eb82";
}
.mbsc-icon-fa-fast-backward::before {
    content: "\eb83";
}
.mbsc-icon-fa-fast-forward::before {
    content: "\eb84";
}
.mbsc-icon-fa-fax::before {
    content: "\eb85";
}
.mbsc-icon-fa-feed::before {
    content: "\eb86";
}
.mbsc-icon-fa-female::before {
    content: "\eb87";
}
.mbsc-icon-fa-fighter-jet::before {
    content: "\eb88";
}
.mbsc-icon-fa-file-archive-o::before {
    content: "\eb89";
}
.mbsc-icon-fa-file-audio-o::before {
    content: "\eb8a";
}
.mbsc-icon-fa-file-code-o::before {
    content: "\eb8b";
}
.mbsc-icon-fa-file-excel-o::before {
    content: "\eb8c";
}
.mbsc-icon-fa-file-image-o::before {
    content: "\eb8d";
}
.mbsc-icon-fa-file-movie-o::before {
    content: "\eb8e";
}
.mbsc-icon-fa-file-o::before {
    content: "\eb8f";
}
.mbsc-icon-fa-file-pdf-o::before {
    content: "\eb90";
}
.mbsc-icon-fa-file-powerpoint-o::before {
    content: "\eb91";
}
.mbsc-icon-fa-file-text-o::before {
    content: "\eb92";
}
.mbsc-icon-fa-file-text::before {
    content: "\eb93";
}
.mbsc-icon-fa-file-word-o::before {
    content: "\eb94";
}
.mbsc-icon-fa-file::before {
    content: "\eb95";
}
.mbsc-icon-fa-film::before {
    content: "\eb96";
}
.mbsc-icon-fa-filter::before {
    content: "\eb97";
}
.mbsc-icon-fa-fire-extinguisher::before {
    content: "\eb98";
}
.mbsc-icon-fa-fire::before {
    content: "\eb99";
}
.mbsc-icon-fa-firefox::before {
    content: "\eb9a";
}
.mbsc-icon-fa-first-order::before {
    content: "\eb9b";
}
.mbsc-icon-fa-flag-checkered::before {
    content: "\eb9c";
}
.mbsc-icon-fa-flag-o::before {
    content: "\eb9d";
}
.mbsc-icon-fa-flag::before {
    content: "\eb9e";
}
.mbsc-icon-fa-flash::before {
    content: "\eb9f";
}
.mbsc-icon-fa-flask::before {
    content: "\eba0";
}
.mbsc-icon-fa-flickr::before {
    content: "\eba1";
}
.mbsc-icon-fa-floppy-o::before {
    content: "\eba2";
}
.mbsc-icon-fa-folder-o::before {
    content: "\eba3";
}
.mbsc-icon-fa-folder-open-o::before {
    content: "\eba4";
}
.mbsc-icon-fa-folder-open::before {
    content: "\eba5";
}
.mbsc-icon-fa-folder::before {
    content: "\eba6";
}
.mbsc-icon-fa-font::before {
    content: "\eba7";
}
.mbsc-icon-fa-fonticons::before {
    content: "\eba8";
}
.mbsc-icon-fa-fort-awesome::before {
    content: "\eba9";
}
.mbsc-icon-fa-forumbee::before {
    content: "\ebaa";
}
.mbsc-icon-fa-forward::before {
    content: "\ebab";
}
.mbsc-icon-fa-foursquare::before {
    content: "\ebac";
}
.mbsc-icon-fa-free-code-camp::before {
    content: "\ebad";
}
.mbsc-icon-fa-frown-o::before {
    content: "\ebae";
}
.mbsc-icon-fa-futbol-o::before {
    content: "\ebaf";
}
.mbsc-icon-fa-gamepad::before {
    content: "\ebb0";
}
.mbsc-icon-fa-gavel::before {
    content: "\ebb1";
}
.mbsc-icon-fa-gbp::before {
    content: "\ebb2";
}
.mbsc-icon-fa-gear::before {
    content: "\ebb3";
}
.mbsc-icon-fa-gears::before {
    content: "\ebb4";
}
.mbsc-icon-fa-genderless::before {
    content: "\ebb5";
}
.mbsc-icon-fa-get-pocket::before {
    content: "\ebb6";
}
.mbsc-icon-fa-gg-circle::before {
    content: "\ebb7";
}
.mbsc-icon-fa-gg::before {
    content: "\ebb8";
}
.mbsc-icon-fa-gift::before {
    content: "\ebb9";
}
.mbsc-icon-fa-git-square::before {
    content: "\ebba";
}
.mbsc-icon-fa-git::before {
    content: "\ebbb";
}
.mbsc-icon-fa-github-alt::before {
    content: "\ebbc";
}
.mbsc-icon-fa-github-square::before {
    content: "\ebbd";
}
.mbsc-icon-fa-github::before {
    content: "\ebbe";
}
.mbsc-icon-fa-gitlab::before {
    content: "\ebbf";
}
.mbsc-icon-fa-gittip::before {
    content: "\ebc0";
}
.mbsc-icon-fa-glass::before {
    content: "\ebc1";
}
.mbsc-icon-fa-glide-g::before {
    content: "\ebc2";
}
.mbsc-icon-fa-glide::before {
    content: "\ebc3";
}
.mbsc-icon-fa-globe::before {
    content: "\ebc4";
}
.mbsc-icon-fa-google-plus-circle::before {
    content: "\ebc5";
}
.mbsc-icon-fa-google-plus-square::before {
    content: "\ebc6";
}
.mbsc-icon-fa-google-plus::before {
    content: "\ebc7";
}
.mbsc-icon-fa-google-wallet::before {
    content: "\ebc8";
}
.mbsc-icon-fa-google::before {
    content: "\ebc9";
}
.mbsc-icon-fa-graduation-cap::before {
    content: "\ebca";
}
.mbsc-icon-fa-grav::before {
    content: "\ebcb";
}
.mbsc-icon-fa-group::before {
    content: "\ebcc";
}
.mbsc-icon-fa-h-square::before {
    content: "\ebcd";
}
.mbsc-icon-fa-hacker-news::before {
    content: "\ebce";
}
.mbsc-icon-fa-hand-grab-o::before {
    content: "\ebcf";
}
.mbsc-icon-fa-hand-lizard-o::before {
    content: "\ebd0";
}
.mbsc-icon-fa-hand-o-down::before {
    content: "\ebd1";
}
.mbsc-icon-fa-hand-o-left::before {
    content: "\ebd2";
}
.mbsc-icon-fa-hand-o-right::before {
    content: "\ebd3";
}
.mbsc-icon-fa-hand-o-up::before {
    content: "\ebd4";
}
.mbsc-icon-fa-hand-paper-o::before {
    content: "\ebd5";
}
.mbsc-icon-fa-hand-peace-o::before {
    content: "\ebd6";
}
.mbsc-icon-fa-hand-pointer-o::before {
    content: "\ebd7";
}
.mbsc-icon-fa-hand-scissors-o::before {
    content: "\ebd8";
}
.mbsc-icon-fa-hand-spock-o::before {
    content: "\ebd9";
}
.mbsc-icon-fa-handshake-o::before {
    content: "\ebda";
}
.mbsc-icon-fa-hashtag::before {
    content: "\ebdb";
}
.mbsc-icon-fa-hdd-o::before {
    content: "\ebdc";
}
.mbsc-icon-fa-header::before {
    content: "\ebdd";
}
.mbsc-icon-fa-headphones::before {
    content: "\ebde";
}
.mbsc-icon-fa-heart-o::before {
    content: "\ebdf";
}
.mbsc-icon-fa-heart::before {
    content: "\ebe0";
}
.mbsc-icon-fa-heartbeat::before {
    content: "\ebe1";
}
.mbsc-icon-fa-history::before {
    content: "\ebe2";
}
.mbsc-icon-fa-home::before {
    content: "\ebe3";
}
.mbsc-icon-fa-hospital-o::before {
    content: "\ebe8";
}
.mbsc-icon-fa-hourglass-1::before {
    content: "\ebe4";
}
.mbsc-icon-fa-hourglass-2::before {
    content: "\ebe5";
}
.mbsc-icon-fa-hourglass-3::before {
    content: "\ebe6";
}
.mbsc-icon-fa-hourglass-o::before {
    content: "\ebe7";
}
.mbsc-icon-fa-hourglass::before {
    content: "\ebe9";
}
.mbsc-icon-fa-houzz::before {
    content: "\ebea";
}
.mbsc-icon-fa-html5::before {
    content: "\ebeb";
}
.mbsc-icon-fa-i-cursor::before {
    content: "\ebec";
}
.mbsc-icon-fa-id-badge::before {
    content: "\ebed";
}
.mbsc-icon-fa-ils::before {
    content: "\ebee";
}
.mbsc-icon-fa-image::before {
    content: "\ebef";
}
.mbsc-icon-fa-imdb::before {
    content: "\ebf0";
}
.mbsc-icon-fa-inbox::before {
    content: "\ebf1";
}
.mbsc-icon-fa-indent::before {
    content: "\ebf2";
}
.mbsc-icon-fa-industry::before {
    content: "\ebf3";
}
.mbsc-icon-fa-info-circle::before {
    content: "\ebf4";
}
.mbsc-icon-fa-info::before {
    content: "\ebf5";
}
.mbsc-icon-fa-inr::before {
    content: "\ebf6";
}
.mbsc-icon-fa-instagram::before {
    content: "\ebf7";
}
.mbsc-icon-fa-internet-explorer::before {
    content: "\ebf8";
}
.mbsc-icon-fa-intersex::before {
    content: "\ebf9";
}
.mbsc-icon-fa-ioxhost::before {
    content: "\ebfe";
}
.mbsc-icon-fa-italic::before {
    content: "\ebfa";
}
.mbsc-icon-fa-joomla::before {
    content: "\ec00";
}
.mbsc-icon-fa-jsfiddle::before {
    content: "\ebfb";
}
.mbsc-icon-fa-key::before {
    content: "\ebfc";
}
.mbsc-icon-fa-keyboard-o::before {
    content: "\ebfd";
}
.mbsc-icon-fa-krw::before {
    content: "\ebff";
}
.mbsc-icon-fa-language::before {
    content: "\ec01";
}
.mbsc-icon-fa-laptop::before {
    content: "\ec06";
}
.mbsc-icon-fa-lastfm-square::before {
    content: "\ec02";
}
.mbsc-icon-fa-lastfm::before {
    content: "\ec03";
}
.mbsc-icon-fa-leaf::before {
    content: "\ec04";
}
.mbsc-icon-fa-leanpub::before {
    content: "\ec05";
}
.mbsc-icon-fa-legal::before {
    content: "\ec07";
}
.mbsc-icon-fa-lemon-o::before {
    content: "\ec08";
}
.mbsc-icon-fa-level-down::before {
    content: "\ec09";
}
.mbsc-icon-fa-level-up::before {
    content: "\ec0a";
}
.mbsc-icon-fa-life-bouy::before {
    content: "\ec0b";
}
.mbsc-icon-fa-lightbulb-o::before {
    content: "\ec0c";
}
.mbsc-icon-fa-line-chart::before {
    content: "\ec0d";
}
.mbsc-icon-fa-linkedin-square::before {
    content: "\ec0e";
}
.mbsc-icon-fa-linkedin::before {
    content: "\ec0f";
}
.mbsc-icon-fa-linode::before {
    content: "\ec10";
}
.mbsc-icon-fa-linux::before {
    content: "\ec11";
}
.mbsc-icon-fa-list-alt::before {
    content: "\ec12";
}
.mbsc-icon-fa-list-ol::before {
    content: "\ec13";
}
.mbsc-icon-fa-list-ul::before {
    content: "\ec14";
}
.mbsc-icon-fa-list::before {
    content: "\ec18";
}
.mbsc-icon-fa-location-arrow::before {
    content: "\ec15";
}
.mbsc-icon-fa-lock::before {
    content: "\ec16";
}
.mbsc-icon-fa-long-arrow-down::before {
    content: "\ec17";
}
.mbsc-icon-fa-long-arrow-left::before {
    content: "\ec19";
}
.mbsc-icon-fa-long-arrow-right::before {
    content: "\ec1a";
}
.mbsc-icon-fa-long-arrow-up::before {
    content: "\ec1b";
}
.mbsc-icon-fa-low-vision::before {
    content: "\ec1c";
}
.mbsc-icon-fa-magic::before {
    content: "\ec1d";
}
.mbsc-icon-fa-magnet::before {
    content: "\ec1e";
}
.mbsc-icon-fa-mail-forward::before {
    content: "\ec1f";
}
.mbsc-icon-fa-mail-reply-all::before {
    content: "\ec20";
}
.mbsc-icon-fa-mail-reply::before {
    content: "\ec21";
}
.mbsc-icon-fa-male::before {
    content: "\ec22";
}
.mbsc-icon-fa-map-marker::before {
    content: "\ec23";
}
.mbsc-icon-fa-map-o::before {
    content: "\ec24";
}
.mbsc-icon-fa-map-pin::before {
    content: "\ec25";
}
.mbsc-icon-fa-map-signs::before {
    content: "\ec26";
}
.mbsc-icon-fa-map::before {
    content: "\ec27";
}
.mbsc-icon-fa-mars-double::before {
    content: "\ec29";
}
.mbsc-icon-fa-mars-stroke-h::before {
    content: "\ec28";
}
.mbsc-icon-fa-mars-stroke-v::before {
    content: "\ec2a";
}
.mbsc-icon-fa-mars-stroke::before {
    content: "\ec2b";
}
.mbsc-icon-fa-mars::before {
    content: "\ec2c";
}
.mbsc-icon-fa-maxcdn::before {
    content: "\ec2d";
}
.mbsc-icon-fa-meanpath::before {
    content: "\ec2e";
}
.mbsc-icon-fa-medium::before {
    content: "\ec2f";
}
.mbsc-icon-fa-medkit::before {
    content: "\ec30";
}
.mbsc-icon-fa-meetup::before {
    content: "\ec31";
}
.mbsc-icon-fa-meh-o::before {
    content: "\ec32";
}
.mbsc-icon-fa-mercury::before {
    content: "\ec33";
}
.mbsc-icon-fa-microchip::before {
    content: "\ec34";
}
.mbsc-icon-fa-microphone-slash::before {
    content: "\ec35";
}
.mbsc-icon-fa-microphone::before {
    content: "\ec36";
}
.mbsc-icon-fa-minus-circle::before {
    content: "\ec37";
}
.mbsc-icon-fa-minus-square-o::before {
    content: "\ec38";
}
.mbsc-icon-fa-minus-square::before {
    content: "\ec39";
}
.mbsc-icon-fa-minus::before {
    content: "\ec3a";
}
.mbsc-icon-fa-mixcloud::before {
    content: "\ec3b";
}
.mbsc-icon-fa-mobile-phone::before {
    content: "\ec3c";
}
.mbsc-icon-fa-mobile::before {
    content: "\ec3d";
}
.mbsc-icon-fa-modx::before {
    content: "\ec3e";
}
.mbsc-icon-fa-money::before {
    content: "\ec3f";
}
.mbsc-icon-fa-moon-o::before {
    content: "\ec40";
}
.mbsc-icon-fa-motorcycle::before {
    content: "\ec41";
}
.mbsc-icon-fa-mouse-pointer::before {
    content: "\ec42";
}
.mbsc-icon-fa-music::before {
    content: "\ec43";
}
.mbsc-icon-fa-neuter::before {
    content: "\ec44";
}
.mbsc-icon-fa-newspaper-o::before {
    content: "\ec46";
}
.mbsc-icon-fa-object-group::before {
    content: "\ec45";
}
.mbsc-icon-fa-object-ungroup::before {
    content: "\ec47";
}
.mbsc-icon-fa-odnoklassniki-square::before {
    content: "\ec48";
}
.mbsc-icon-fa-odnoklassniki::before {
    content: "\ec49";
}
.mbsc-icon-fa-opencart::before {
    content: "\ec4a";
}
.mbsc-icon-fa-openid::before {
    content: "\ec4b";
}
.mbsc-icon-fa-opera::before {
    content: "\ec4c";
}
.mbsc-icon-fa-optin-monster::before {
    content: "\ec4d";
}
.mbsc-icon-fa-pagelines::before {
    content: "\ec4e";
}
.mbsc-icon-fa-paint-brush::before {
    content: "\ec4f";
}
.mbsc-icon-fa-paper-plane-o::before {
    content: "\ec50";
}
.mbsc-icon-fa-paper-plane::before {
    content: "\ec51";
}
.mbsc-icon-fa-paperclip::before {
    content: "\ec52";
}
.mbsc-icon-fa-paragraph::before {
    content: "\ec53";
}
.mbsc-icon-fa-paste::before {
    content: "\ec54";
}
.mbsc-icon-fa-pause-circle-o::before {
    content: "\ec55";
}
.mbsc-icon-fa-pause-circle::before {
    content: "\ec56";
}
.mbsc-icon-fa-pause::before {
    content: "\ec57";
}
.mbsc-icon-fa-paw::before {
    content: "\ec58";
}
.mbsc-icon-fa-paypal::before {
    content: "\ec59";
}
.mbsc-icon-fa-pencil-square::before {
    content: "\ec5a";
}
.mbsc-icon-fa-pencil::before {
    content: "\ec5b";
}
.mbsc-icon-fa-percent::before {
    content: "\ec5c";
}
.mbsc-icon-fa-phone-square::before {
    content: "\ec5d";
}
.mbsc-icon-fa-phone::before {
    content: "\ec5e";
}
.mbsc-icon-fa-picture-o::before {
    content: "\ec5f";
}
.mbsc-icon-fa-pie-chart::before {
    content: "\ec60";
}
.mbsc-icon-fa-pied-piper-alt::before {
    content: "\ec61";
}
.mbsc-icon-fa-pied-piper-pp::before {
    content: "\ec62";
}
.mbsc-icon-fa-pied-piper::before {
    content: "\ec63";
}
.mbsc-icon-fa-pinterest-p::before {
    content: "\ec64";
}
.mbsc-icon-fa-pinterest-square::before {
    content: "\ec65";
}
.mbsc-icon-fa-pinterest::before {
    content: "\ec66";
}
.mbsc-icon-fa-plane::before {
    content: "\ec67";
}
.mbsc-icon-fa-play-circle-o::before {
    content: "\ec68";
}
.mbsc-icon-fa-play-circle::before {
    content: "\ec69";
}
.mbsc-icon-fa-play::before {
    content: "\ec6a";
}
.mbsc-icon-fa-plug::before {
    content: "\ec6b";
}
.mbsc-icon-fa-plus-circle::before {
    content: "\ec6c";
}
.mbsc-icon-fa-plus-square-o::before {
    content: "\ec6d";
}
.mbsc-icon-fa-plus-square::before {
    content: "\ec6e";
}
.mbsc-icon-fa-plus::before {
    content: "\ec6f";
}
.mbsc-icon-fa-podcast::before {
    content: "\ec70";
}
.mbsc-icon-fa-power-off::before {
    content: "\ec71";
}
.mbsc-icon-fa-print::before {
    content: "\ec72";
}
.mbsc-icon-fa-product-hunt::before {
    content: "\ec73";
}
.mbsc-icon-fa-puzzle-piece::before {
    content: "\ec74";
}
.mbsc-icon-fa-qq::before {
    content: "\ec75";
}
.mbsc-icon-fa-qrcode::before {
    content: "\ec76";
}
.mbsc-icon-fa-question-circle-o::before {
    content: "\ec77";
}
.mbsc-icon-fa-question-circle::before {
    content: "\ec78";
}
.mbsc-icon-fa-question::before {
    content: "\ec79";
}
.mbsc-icon-fa-quora::before {
    content: "\ec7a";
}
.mbsc-icon-fa-quote-left::before {
    content: "\ec7b";
}
.mbsc-icon-fa-quote-right::before {
    content: "\ec7c";
}
.mbsc-icon-fa-ra::before {
    content: "\ec7d";
}
.mbsc-icon-fa-random::before {
    content: "\ec7e";
}
.mbsc-icon-fa-ravelry::before {
    content: "\ec7f";
}
.mbsc-icon-fa-recycle::before {
    content: "\ec80";
}
.mbsc-icon-fa-reddit-alien::before {
    content: "\ec81";
}
.mbsc-icon-fa-reddit-square::before {
    content: "\ec82";
}
.mbsc-icon-fa-reddit::before {
    content: "\ec83";
}
.mbsc-icon-fa-refresh::before {
    content: "\ec84";
}
.mbsc-icon-fa-registered::before {
    content: "\ec85";
}
.mbsc-icon-fa-renren::before {
    content: "\ec86";
}
.mbsc-icon-fa-repeat::before {
    content: "\ec87";
}
.mbsc-icon-fa-reply-all::before {
    content: "\ec88";
}
.mbsc-icon-fa-retweet::before {
    content: "\ec89";
}
.mbsc-icon-fa-road::before {
    content: "\ec8a";
}
.mbsc-icon-fa-rocket::before {
    content: "\ec8b";
}
.mbsc-icon-fa-rotate-left::before {
    content: "\ec8c";
}
.mbsc-icon-fa-rotate-right::before {
    content: "\ec8d";
}
.mbsc-icon-fa-rouble::before {
    content: "\ec8e";
}
.mbsc-icon-fa-rss-square::before {
    content: "\ec8f";
}
.mbsc-icon-fa-rss::before {
    content: "\ec90";
}
.mbsc-icon-fa-ruble::before {
    content: "\ec91";
}
.mbsc-icon-fa-rupee::before {
    content: "\ec92";
}
.mbsc-icon-fa-safari::before {
    content: "\ec93";
}
.mbsc-icon-fa-save::before {
    content: "\ec94";
}
.mbsc-icon-fa-scribd::before {
    content: "\ec95";
}
.mbsc-icon-fa-search-minus::before {
    content: "\ec96";
}
.mbsc-icon-fa-search-plus::before {
    content: "\ec97";
}
.mbsc-icon-fa-search::before {
    content: "\ec98";
}
.mbsc-icon-fa-sellsy::before {
    content: "\ec99";
}
.mbsc-icon-fa-server::before {
    content: "\ec9a";
}
.mbsc-icon-fa-share-alt-square::before {
    content: "\ec9b";
}
.mbsc-icon-fa-share-alt::before {
    content: "\ec9c";
}
.mbsc-icon-fa-share-square-o::before {
    content: "\ec9d";
}
.mbsc-icon-fa-share-square::before {
    content: "\ec9e";
}
.mbsc-icon-fa-shield::before {
    content: "\ec9f";
}
.mbsc-icon-fa-ship::before {
    content: "\eca0";
}
.mbsc-icon-fa-shirtsinbulk::before {
    content: "\eca1";
}
.mbsc-icon-fa-shopping-bag::before {
    content: "\eca2";
}
.mbsc-icon-fa-shopping-basket::before {
    content: "\eca3";
}
.mbsc-icon-fa-shopping-cart::before {
    content: "\eca4";
}
.mbsc-icon-fa-shower::before {
    content: "\eca5";
}
.mbsc-icon-fa-sign-in::before {
    content: "\eca6";
}
.mbsc-icon-fa-sign-language::before {
    content: "\eca7";
}
.mbsc-icon-fa-sign-out::before {
    content: "\eca8";
}
.mbsc-icon-fa-signal::before {
    content: "\eca9";
}
.mbsc-icon-fa-simplybuilt::before {
    content: "\ecaa";
}
.mbsc-icon-fa-sitemap::before {
    content: "\ecab";
}
.mbsc-icon-fa-skyatlas::before {
    content: "\ecac";
}
.mbsc-icon-fa-skype::before {
    content: "\ecad";
}
.mbsc-icon-fa-slack::before {
    content: "\ecae";
}
.mbsc-icon-fa-sliders::before {
    content: "\ecaf";
}
.mbsc-icon-fa-slideshare::before {
    content: "\ecb0";
}
.mbsc-icon-fa-smile-o::before {
    content: "\ecb1";
}
.mbsc-icon-fa-snapchat-ghost::before {
    content: "\ecb2";
}
.mbsc-icon-fa-snapchat-square::before {
    content: "\ecb3";
}
.mbsc-icon-fa-snapchat::before {
    content: "\ecb4";
}
.mbsc-icon-fa-snowflake-o::before {
    content: "\ecb5";
}
.mbsc-icon-fa-sort-alpha-asc::before {
    content: "\ecb6";
}
.mbsc-icon-fa-sort-alpha-desc::before {
    content: "\ecb7";
}
.mbsc-icon-fa-sort-amount-asc::before {
    content: "\ecb8";
}
.mbsc-icon-fa-sort-amount-desc::before {
    content: "\ecb9";
}
.mbsc-icon-fa-sort-asc::before {
    content: "\ecba";
}
.mbsc-icon-fa-sort-desc::before {
    content: "\ecbb";
}
.mbsc-icon-fa-sort-down::before {
    content: "\ecbc";
}
.mbsc-icon-fa-sort-numeric-asc::before {
    content: "\ecbd";
}
.mbsc-icon-fa-sort-numeric-desc::before {
    content: "\ecbe";
}
.mbsc-icon-fa-sort-up::before {
    content: "\ecbf";
}
.mbsc-icon-fa-sort::before {
    content: "\ecc0";
}
.mbsc-icon-fa-soundcloud::before {
    content: "\ecc1";
}
.mbsc-icon-fa-space-shuttle::before {
    content: "\ecc2";
}
.mbsc-icon-fa-spinner::before {
    content: "\ecc3";
}
.mbsc-icon-fa-spoon::before {
    content: "\ecc4";
}
.mbsc-icon-fa-spotify::before {
    content: "\ecc5";
}
.mbsc-icon-fa-square-o::before {
    content: "\ecc6";
}
.mbsc-icon-fa-square::before {
    content: "\ecc7";
}
.mbsc-icon-fa-stack-exchange::before {
    content: "\ecc8";
}
.mbsc-icon-fa-stack-overflow::before {
    content: "\ecc9";
}
.mbsc-icon-fa-star-half-empty::before {
    content: "\ecca";
}
.mbsc-icon-fa-star-half::before {
    content: "\eccb";
}
.mbsc-icon-fa-star-o::before {
    content: "\eccc";
}
.mbsc-icon-fa-star::before {
    content: "\eccd";
}
.mbsc-icon-fa-steam-square::before {
    content: "\ecce";
}
.mbsc-icon-fa-steam::before {
    content: "\eccf";
}
.mbsc-icon-fa-step-backward::before {
    content: "\ecd0";
}
.mbsc-icon-fa-step-forward::before {
    content: "\ecd1";
}
.mbsc-icon-fa-stethoscope::before {
    content: "\ecd2";
}
.mbsc-icon-fa-sticky-note-o::before {
    content: "\ecd3";
}
.mbsc-icon-fa-sticky-note::before {
    content: "\ecd4";
}
.mbsc-icon-fa-stop-circle-o::before {
    content: "\ecd5";
}
.mbsc-icon-fa-stop-circle::before {
    content: "\ecd6";
}
.mbsc-icon-fa-stop::before {
    content: "\ecd7";
}
.mbsc-icon-fa-street-view::before {
    content: "\ecd8";
}
.mbsc-icon-fa-strikethrough::before {
    content: "\ecd9";
}
.mbsc-icon-fa-stumbleupon-circle::before {
    content: "\ecda";
}
.mbsc-icon-fa-stumbleupon::before {
    content: "\ecdb";
}
.mbsc-icon-fa-subscript::before {
    content: "\ecdc";
}
.mbsc-icon-fa-subway::before {
    content: "\ecdd";
}
.mbsc-icon-fa-suitcase::before {
    content: "\ecde";
}
.mbsc-icon-fa-sun-o::before {
    content: "\ecdf";
}
.mbsc-icon-fa-superpowers::before {
    content: "\ece0";
}
.mbsc-icon-fa-superscript::before {
    content: "\ece1";
}
.mbsc-icon-fa-table::before {
    content: "\ece2";
}
.mbsc-icon-fa-tablet::before {
    content: "\ece3";
}
.mbsc-icon-fa-tag::before {
    content: "\ece4";
}
.mbsc-icon-fa-tags::before {
    content: "\ece5";
}
.mbsc-icon-fa-tasks::before {
    content: "\ece6";
}
.mbsc-icon-fa-telegram::before {
    content: "\ece7";
}
.mbsc-icon-fa-television::before {
    content: "\ece8";
}
.mbsc-icon-fa-tencent-weibo::before {
    content: "\ece9";
}
.mbsc-icon-fa-terminal::before {
    content: "\ecea";
}
.mbsc-icon-fa-text-height::before {
    content: "\eceb";
}
.mbsc-icon-fa-text-width::before {
    content: "\ecec";
}
.mbsc-icon-fa-th-large::before {
    content: "\eced";
}
.mbsc-icon-fa-th-list::before {
    content: "\ecee";
}
.mbsc-icon-fa-th::before {
    content: "\ecef";
}
.mbsc-icon-fa-themeisle::before {
    content: "\ecf0";
}
.mbsc-icon-fa-thermometer-0::before {
    content: "\ecf1";
}
.mbsc-icon-fa-thermometer-1::before {
    content: "\ecf2";
}
.mbsc-icon-fa-thermometer-2::before {
    content: "\ecf3";
}
.mbsc-icon-fa-thermometer-3::before {
    content: "\ecf5";
}
.mbsc-icon-fa-thermometer::before {
    content: "\ecf4";
}
.mbsc-icon-fa-thumb-tack::before {
    content: "\ecf6";
}
.mbsc-icon-fa-thumbs-down::before {
    content: "\ecf7";
}
.mbsc-icon-fa-thumbs-o-down::before {
    content: "\ecf8";
}
.mbsc-icon-fa-thumbs-o-up::before {
    content: "\ecf9";
}
.mbsc-icon-fa-thumbs-up::before {
    content: "\ecfa";
}
.mbsc-icon-fa-ticket::before {
    content: "\ecfb";
}
.mbsc-icon-fa-times-circle-o::before {
    content: "\ecfc";
}
.mbsc-icon-fa-times-circle::before {
    content: "\ecfd";
}
.mbsc-icon-fa-times-rectangle-o::before {
    content: "\ecfe";
}
.mbsc-icon-fa-times-rectangle::before {
    content: "\ecff";
}
.mbsc-icon-fa-times::before {
    content: "\ed00";
}
.mbsc-icon-fa-tint::before {
    content: "\ed01";
}
.mbsc-icon-fa-toggle-down::before {
    content: "\ed02";
}
.mbsc-icon-fa-toggle-left::before {
    content: "\ed03";
}
.mbsc-icon-fa-toggle-off::before {
    content: "\ed04";
}
.mbsc-icon-fa-toggle-on::before {
    content: "\ed05";
}
.mbsc-icon-fa-toggle-right::before {
    content: "\ed06";
}
.mbsc-icon-fa-toggle-up::before {
    content: "\ed07";
}
.mbsc-icon-fa-trademark::before {
    content: "\ed08";
}
.mbsc-icon-fa-train::before {
    content: "\ed09";
}
.mbsc-icon-fa-transgender-alt::before {
    content: "\ed0a";
}
.mbsc-icon-fa-trash-o::before {
    content: "\ed0b";
}
.mbsc-icon-fa-trash::before {
    content: "\ed0c";
}
.mbsc-icon-fa-tree::before {
    content: "\ed0d";
}
.mbsc-icon-fa-trello::before {
    content: "\ed0e";
}
.mbsc-icon-fa-tripadvisor::before {
    content: "\ed0f";
}
.mbsc-icon-fa-trophy::before {
    content: "\ed10";
}
.mbsc-icon-fa-truck::before {
    content: "\ed11";
}
.mbsc-icon-fa-try::before {
    content: "\ed12";
}
.mbsc-icon-fa-tty::before {
    content: "\ed13";
}
.mbsc-icon-fa-tumblr-square::before {
    content: "\ed14";
}
.mbsc-icon-fa-tumblr::before {
    content: "\ed15";
}
.mbsc-icon-fa-turkish-lira::before {
    content: "\ed16";
}
.mbsc-icon-fa-twitch::before {
    content: "\ed17";
}
.mbsc-icon-fa-twitter-square::before {
    content: "\ed18";
}
.mbsc-icon-fa-twitter::before {
    content: "\ed19";
}
.mbsc-icon-fa-umbrella::before {
    content: "\ed1a";
}
.mbsc-icon-fa-underline::before {
    content: "\ed1b";
}
.mbsc-icon-fa-universal-access::before {
    content: "\ed1c";
}
.mbsc-icon-fa-unlink::before {
    content: "\ed1d";
}
.mbsc-icon-fa-unlock-alt::before {
    content: "\ed1e";
}
.mbsc-icon-fa-unlock::before {
    content: "\ed1f";
}
.mbsc-icon-fa-unsorted::before {
    content: "\ed20";
}
.mbsc-icon-fa-upload::before {
    content: "\ed21";
}
.mbsc-icon-fa-usb::before {
    content: "\ed22";
}
.mbsc-icon-fa-user-circle-o::before {
    content: "\ed23";
}
.mbsc-icon-fa-user-circle::before {
    content: "\ed24";
}
.mbsc-icon-fa-user-md::before {
    content: "\ed25";
}
.mbsc-icon-fa-user-o::before {
    content: "\ed26";
}
.mbsc-icon-fa-user-plus::before {
    content: "\ed27";
}
.mbsc-icon-fa-user-secret::before {
    content: "\ed28";
}
.mbsc-icon-fa-user-times::before {
    content: "\ed29";
}
.mbsc-icon-fa-user::before {
    content: "\ed2a";
}
.mbsc-icon-fa-venus-double::before {
    content: "\ed2b";
}
.mbsc-icon-fa-venus-mars::before {
    content: "\ed2c";
}
.mbsc-icon-fa-venus::before {
    content: "\ed2d";
}
.mbsc-icon-fa-viacoin::before {
    content: "\ed2e";
}
.mbsc-icon-fa-viadeo-square::before {
    content: "\ed2f";
}
.mbsc-icon-fa-viadeo::before {
    content: "\ed30";
}
.mbsc-icon-fa-video-camera::before {
    content: "\ed31";
}
.mbsc-icon-fa-vimeo-square::before {
    content: "\ed32";
}
.mbsc-icon-fa-vimeo::before {
    content: "\ed33";
}
.mbsc-icon-fa-vine::before {
    content: "\ed34";
}
.mbsc-icon-fa-vk::before {
    content: "\ed35";
}
.mbsc-icon-fa-volume-control-phone::before {
    content: "\ed36";
}
.mbsc-icon-fa-volume-down::before {
    content: "\ed37";
}
.mbsc-icon-fa-volume-off::before {
    content: "\ed38";
}
.mbsc-icon-fa-volume-up::before {
    content: "\ed39";
}
.mbsc-icon-fa-warning::before {
    content: "\ed3a";
}
.mbsc-icon-fa-wechat::before {
    content: "\ed3b";
}
.mbsc-icon-fa-weibo::before {
    content: "\ed3c";
}
.mbsc-icon-fa-whatsapp::before {
    content: "\ed3d";
}
.mbsc-icon-fa-wheelchair-alt::before {
    content: "\ed3e";
}
.mbsc-icon-fa-wheelchair::before {
    content: "\ed3f";
}
.mbsc-icon-fa-wifi::before {
    content: "\ed40";
}
.mbsc-icon-fa-wikipedia-w::before {
    content: "\ed41";
}
.mbsc-icon-fa-window-maximize::before {
    content: "\ed42";
}
.mbsc-icon-fa-window-minimize::before {
    content: "\ed43";
}
.mbsc-icon-fa-window-restore::before {
    content: "\ed44";
}
.mbsc-icon-fa-windows::before {
    content: "\ed45";
}
.mbsc-icon-fa-won::before {
    content: "\ed46";
}
.mbsc-icon-fa-wordpress::before {
    content: "\ed47";
}
.mbsc-icon-fa-wpbeginner::before {
    content: "\ed48";
}
.mbsc-icon-fa-wpexplorer::before {
    content: "\ed49";
}
.mbsc-icon-fa-wpforms::before {
    content: "\ed4a";
}
.mbsc-icon-fa-wrench::before {
    content: "\ed4b";
}
.mbsc-icon-fa-xing-square::before {
    content: "\ed4c";
}
.mbsc-icon-fa-xing::before {
    content: "\ed4d";
}
.mbsc-icon-fa-y-combinator::before {
    content: "\ed4e";
}
.mbsc-icon-fa-yahoo::before {
    content: "\ed4f";
}
.mbsc-icon-fa-yelp::before {
    content: "\ed50";
}
.mbsc-icon-fa-yoast::before {
    content: "\ed51";
}
.mbsc-icon-fa-youtube-play::before {
    content: "\ed52";
}
.mbsc-icon-fa-youtube-square::before {
    content: "\ed53";
}
.mbsc-icon-fa-youtube::before {
    content: "\ed54";
}
.mbsc-icon-facebook::before {
    content: "\ed55";
}
.mbsc-icon-facebook2::before {
    content: "\ed56";
}
.mbsc-icon-facebook3::before {
    content: "\ed57";
}
.mbsc-icon-feed::before {
    content: "\ed58";
}
.mbsc-icon-feed2::before {
    content: "\ed59";
}
.mbsc-icon-feed3::before {
    content: "\ed5a";
}
.mbsc-icon-feed4::before {
    content: "\ed5b";
}
.mbsc-icon-file-css::before {
    content: "\ed5c";
}
.mbsc-icon-file-excel::before {
    content: "\ed5d";
}
.mbsc-icon-file-openoffice::before {
    content: "\ed5e";
}
.mbsc-icon-file-pdf::before {
    content: "\ed5f";
}
.mbsc-icon-file-powerpoint::before {
    content: "\ed60";
}
.mbsc-icon-file-word::before {
    content: "\ed61";
}
.mbsc-icon-file-xml::before {
    content: "\ed62";
}
.mbsc-icon-file-zip::before {
    content: "\ed63";
}
.mbsc-icon-file::before {
    content: "\ed64";
}
.mbsc-icon-file2::before {
    content: "\ed66";
}
.mbsc-icon-file3::before {
    content: "\ed65";
}
.mbsc-icon-file4::before {
    content: "\ed67";
}
.mbsc-icon-film::before {
    content: "\ed68";
}
.mbsc-icon-filter::before {
    content: "\ed69";
}
.mbsc-icon-filter2::before {
    content: "\ed6a";
}
.mbsc-icon-finder::before {
    content: "\ed6b";
}
.mbsc-icon-fire::before {
    content: "\ed6c";
}
.mbsc-icon-firefox::before {
    content: "\ed6d";
}
.mbsc-icon-first::before {
    content: "\ed6e";
}
.mbsc-icon-flag::before {
    content: "\ed6f";
}
.mbsc-icon-flattr::before {
    content: "\ed70";
}
.mbsc-icon-flickr::before {
    content: "\ed71";
}
.mbsc-icon-flickr2::before {
    content: "\ed72";
}
.mbsc-icon-flickr3::before {
    content: "\ed73";
}
.mbsc-icon-flickr4::before {
    content: "\ed74";
}
.mbsc-icon-flip::before {
    content: "\ed75";
}
.mbsc-icon-flip2::before {
    content: "\ed76";
}
.mbsc-icon-folder-open::before {
    content: "\ed77";
}
.mbsc-icon-folder::before {
    content: "\ed78";
}
.mbsc-icon-font::before {
    content: "\ed79";
}
.mbsc-icon-food::before {
    content: "\ed7a";
}
.mbsc-icon-forrst::before {
    content: "\ed7b";
}
.mbsc-icon-forrst2::before {
    content: "\ed7c";
}
.mbsc-icon-forward::before {
    content: "\ed7d";
}
.mbsc-icon-forward2::before {
    content: "\ed7e";
}
.mbsc-icon-forward3::before {
    content: "\ed7f";
}
.mbsc-icon-foundation-address-book::before {
    content: "\ed81";
}
.mbsc-icon-foundation-alert::before {
    content: "\ed80";
}
.mbsc-icon-foundation-align-center::before {
    content: "\ed82";
}
.mbsc-icon-foundation-align-justify::before {
    content: "\ed83";
}
.mbsc-icon-foundation-align-left::before {
    content: "\ed84";
}
.mbsc-icon-foundation-align-right::before {
    content: "\ed85";
}
.mbsc-icon-foundation-anchor::before {
    content: "\ed86";
}
.mbsc-icon-foundation-annotate::before {
    content: "\ed87";
}
.mbsc-icon-foundation-archive::before {
    content: "\ed88";
}
.mbsc-icon-foundation-arrow-down::before {
    content: "\ed89";
}
.mbsc-icon-foundation-arrow-left::before {
    content: "\ed8a";
}
.mbsc-icon-foundation-arrow-right::before {
    content: "\ed8b";
}
.mbsc-icon-foundation-arrow-up::before {
    content: "\ed8c";
}
.mbsc-icon-foundation-arrows-compress::before {
    content: "\ed8e";
}
.mbsc-icon-foundation-arrows-expand::before {
    content: "\ed8d";
}
.mbsc-icon-foundation-arrows-in::before {
    content: "\ed8f";
}
.mbsc-icon-foundation-arrows-out::before {
    content: "\ed90";
}
.mbsc-icon-foundation-asl::before {
    content: "\ed91";
}
.mbsc-icon-foundation-asterisk::before {
    content: "\ed92";
}
.mbsc-icon-foundation-at-sign::before {
    content: "\ed93";
}
.mbsc-icon-foundation-background-color::before {
    content: "\ed94";
}
.mbsc-icon-foundation-battery-empty::before {
    content: "\ed95";
}
.mbsc-icon-foundation-battery-full::before {
    content: "\ed96";
}
.mbsc-icon-foundation-battery-half::before {
    content: "\ed97";
}
.mbsc-icon-foundation-bitcoin-circle::before {
    content: "\ed98";
}
.mbsc-icon-foundation-bitcoin::before {
    content: "\ed99";
}
.mbsc-icon-foundation-blind::before {
    content: "\ed9a";
}
.mbsc-icon-foundation-bluetooth::before {
    content: "\ed9c";
}
.mbsc-icon-foundation-bold::before {
    content: "\ed9b";
}
.mbsc-icon-foundation-book-bookmark::before {
    content: "\ed9d";
}
.mbsc-icon-foundation-book::before {
    content: "\ed9e";
}
.mbsc-icon-foundation-bookmark::before {
    content: "\ed9f";
}
.mbsc-icon-foundation-braille::before {
    content: "\eda0";
}
.mbsc-icon-foundation-burst-new::before {
    content: "\eda1";
}
.mbsc-icon-foundation-burst-sale::before {
    content: "\eda7";
}
.mbsc-icon-foundation-burst::before {
    content: "\eda3";
}
.mbsc-icon-foundation-calendar::before {
    content: "\eda2";
}
.mbsc-icon-foundation-camera::before {
    content: "\eda4";
}
.mbsc-icon-foundation-check::before {
    content: "\eda5";
}
.mbsc-icon-foundation-checkbox::before {
    content: "\eda6";
}
.mbsc-icon-foundation-clipboard-notes::before {
    content: "\eda8";
}
.mbsc-icon-foundation-clipboard-pencil::before {
    content: "\eda9";
}
.mbsc-icon-foundation-clipboard::before {
    content: "\edaa";
}
.mbsc-icon-foundation-clock::before {
    content: "\edab";
}
.mbsc-icon-foundation-closed-caption::before {
    content: "\edac";
}
.mbsc-icon-foundation-cloud::before {
    content: "\edad";
}
.mbsc-icon-foundation-comment-minus::before {
    content: "\edae";
}
.mbsc-icon-foundation-comment-quotes::before {
    content: "\edaf";
}
.mbsc-icon-foundation-comment-video::before {
    content: "\edb0";
}
.mbsc-icon-foundation-comment::before {
    content: "\edb1";
}
.mbsc-icon-foundation-comments::before {
    content: "\edb2";
}
.mbsc-icon-foundation-compass::before {
    content: "\edb3";
}
.mbsc-icon-foundation-contrast::before {
    content: "\edb4";
}
.mbsc-icon-foundation-credit-card::before {
    content: "\edb5";
}
.mbsc-icon-foundation-crop::before {
    content: "\edb6";
}
.mbsc-icon-foundation-crown::before {
    content: "\edb7";
}
.mbsc-icon-foundation-css3::before {
    content: "\edb8";
}
.mbsc-icon-foundation-database::before {
    content: "\edb9";
}
.mbsc-icon-foundation-die-five::before {
    content: "\edba";
}
.mbsc-icon-foundation-die-four::before {
    content: "\edbb";
}
.mbsc-icon-foundation-die-one::before {
    content: "\edbc";
}
.mbsc-icon-foundation-die-six::before {
    content: "\edbd";
}
.mbsc-icon-foundation-die-three::before {
    content: "\edbe";
}
.mbsc-icon-foundation-die-two::before {
    content: "\edbf";
}
.mbsc-icon-foundation-dislike::before {
    content: "\edc0";
}
.mbsc-icon-foundation-dollar-bill::before {
    content: "\edc1";
}
.mbsc-icon-foundation-dollar::before {
    content: "\edc2";
}
.mbsc-icon-foundation-download::before {
    content: "\edc3";
}
.mbsc-icon-foundation-eject::before {
    content: "\edc4";
}
.mbsc-icon-foundation-elevator::before {
    content: "\edc5";
}
.mbsc-icon-foundation-euro::before {
    content: "\edc6";
}
.mbsc-icon-foundation-eye::before {
    content: "\edc7";
}
.mbsc-icon-foundation-fast-forward::before {
    content: "\edc8";
}
.mbsc-icon-foundation-female-symbol::before {
    content: "\edc9";
}
.mbsc-icon-foundation-female::before {
    content: "\edca";
}
.mbsc-icon-foundation-filter::before {
    content: "\edcb";
}
.mbsc-icon-foundation-first-aid::before {
    content: "\edcc";
}
.mbsc-icon-foundation-flag::before {
    content: "\edcd";
}
.mbsc-icon-foundation-folder-add::before {
    content: "\edce";
}
.mbsc-icon-foundation-folder-lock::before {
    content: "\edcf";
}
.mbsc-icon-foundation-folder::before {
    content: "\edd0";
}
.mbsc-icon-foundation-foot::before {
    content: "\edd4";
}
.mbsc-icon-foundation-foundation::before {
    content: "\edd5";
}
.mbsc-icon-foundation-graph-bar::before {
    content: "\edd1";
}
.mbsc-icon-foundation-graph-horizontal::before {
    content: "\edd2";
}
.mbsc-icon-foundation-graph-pie::before {
    content: "\edd3";
}
.mbsc-icon-foundation-graph-trend::before {
    content: "\edd6";
}
.mbsc-icon-foundation-guide-dog::before {
    content: "\edd7";
}
.mbsc-icon-foundation-hearing-aid::before {
    content: "\edd8";
}
.mbsc-icon-foundation-heart::before {
    content: "\edd9";
}
.mbsc-icon-foundation-home::before {
    content: "\edda";
}
.mbsc-icon-foundation-html5::before {
    content: "\eddb";
}
.mbsc-icon-foundation-indent-less::before {
    content: "\eddc";
}
.mbsc-icon-foundation-indent-more::before {
    content: "\eddd";
}
.mbsc-icon-foundation-info::before {
    content: "\edde";
}
.mbsc-icon-foundation-italic::before {
    content: "\eddf";
}
.mbsc-icon-foundation-key::before {
    content: "\ede0";
}
.mbsc-icon-foundation-laptop::before {
    content: "\ede1";
}
.mbsc-icon-foundation-layout::before {
    content: "\ede2";
}
.mbsc-icon-foundation-lightbulb::before {
    content: "\ede3";
}
.mbsc-icon-foundation-like::before {
    content: "\ede4";
}
.mbsc-icon-foundation-link::before {
    content: "\ede5";
}
.mbsc-icon-foundation-list-bullet::before {
    content: "\ede6";
}
.mbsc-icon-foundation-list-number::before {
    content: "\ede7";
}
.mbsc-icon-foundation-list-thumbnails::before {
    content: "\ede8";
}
.mbsc-icon-foundation-list::before {
    content: "\ede9";
}
.mbsc-icon-foundation-lock::before {
    content: "\edea";
}
.mbsc-icon-foundation-loop::before {
    content: "\edeb";
}
.mbsc-icon-foundation-magnifying-glass::before {
    content: "\edec";
}
.mbsc-icon-foundation-mail::before {
    content: "\eded";
}
.mbsc-icon-foundation-male-female::before {
    content: "\edee";
}
.mbsc-icon-foundation-male-symbol::before {
    content: "\edef";
}
.mbsc-icon-foundation-male::before {
    content: "\edf0";
}
.mbsc-icon-foundation-map::before {
    content: "\edf1";
}
.mbsc-icon-foundation-marker::before {
    content: "\edf2";
}
.mbsc-icon-foundation-megaphone::before {
    content: "\edf3";
}
.mbsc-icon-foundation-microphone::before {
    content: "\edf4";
}
.mbsc-icon-foundation-minus-circle::before {
    content: "\edf5";
}
.mbsc-icon-foundation-minus::before {
    content: "\edf6";
}
.mbsc-icon-foundation-mobile-signal::before {
    content: "\edf8";
}
.mbsc-icon-foundation-mobile::before {
    content: "\edf7";
}
.mbsc-icon-foundation-monitor::before {
    content: "\edf9";
}
.mbsc-icon-foundation-mountains::before {
    content: "\edfa";
}
.mbsc-icon-foundation-music::before {
    content: "\edfb";
}
.mbsc-icon-foundation-next::before {
    content: "\edff";
}
.mbsc-icon-foundation-no-dogs::before {
    content: "\edfc";
}
.mbsc-icon-foundation-no-smoking::before {
    content: "\edfd";
}
.mbsc-icon-foundation-page-add::before {
    content: "\edfe";
}
.mbsc-icon-foundation-page-copy::before {
    content: "\ee00";
}
.mbsc-icon-foundation-page-csv::before {
    content: "\ee01";
}
.mbsc-icon-foundation-page-delete::before {
    content: "\ee02";
}
.mbsc-icon-foundation-page-doc::before {
    content: "\ee03";
}
.mbsc-icon-foundation-page-edit::before {
    content: "\ee04";
}
.mbsc-icon-foundation-page-export-csv::before {
    content: "\ee05";
}
.mbsc-icon-foundation-page-export-doc::before {
    content: "\ee06";
}
.mbsc-icon-foundation-page-export-pdf::before {
    content: "\ee07";
}
.mbsc-icon-foundation-page-export::before {
    content: "\ee08";
}
.mbsc-icon-foundation-page-filled::before {
    content: "\ee09";
}
.mbsc-icon-foundation-page-multiple::before {
    content: "\ee0a";
}
.mbsc-icon-foundation-page-pdf::before {
    content: "\ee0b";
}
.mbsc-icon-foundation-page-remove::before {
    content: "\ee0c";
}
.mbsc-icon-foundation-page-search::before {
    content: "\ee0d";
}
.mbsc-icon-foundation-page::before {
    content: "\ee0e";
}
.mbsc-icon-foundation-paint-bucket::before {
    content: "\ee0f";
}
.mbsc-icon-foundation-paperclip::before {
    content: "\ee10";
}
.mbsc-icon-foundation-pause::before {
    content: "\ee11";
}
.mbsc-icon-foundation-paw::before {
    content: "\ee12";
}
.mbsc-icon-foundation-paypal::before {
    content: "\ee13";
}
.mbsc-icon-foundation-pencil::before {
    content: "\ee14";
}
.mbsc-icon-foundation-photo::before {
    content: "\ee15";
}
.mbsc-icon-foundation-play-circle::before {
    content: "\ee16";
}
.mbsc-icon-foundation-play-video::before {
    content: "\ee17";
}
.mbsc-icon-foundation-play::before {
    content: "\ee18";
}
.mbsc-icon-foundation-plus::before {
    content: "\ee19";
}
.mbsc-icon-foundation-pound::before {
    content: "\ee1a";
}
.mbsc-icon-foundation-power::before {
    content: "\ee1b";
}
.mbsc-icon-foundation-previous::before {
    content: "\ee1c";
}
.mbsc-icon-foundation-price-tag::before {
    content: "\ee1d";
}
.mbsc-icon-foundation-pricetag-multiple::before {
    content: "\ee1e";
}
.mbsc-icon-foundation-print::before {
    content: "\ee1f";
}
.mbsc-icon-foundation-prohibited::before {
    content: "\ee20";
}
.mbsc-icon-foundation-projection-screen::before {
    content: "\ee21";
}
.mbsc-icon-foundation-puzzle::before {
    content: "\ee22";
}
.mbsc-icon-foundation-quote::before {
    content: "\ee23";
}
.mbsc-icon-foundation-record::before {
    content: "\ee24";
}
.mbsc-icon-foundation-refresh::before {
    content: "\ee25";
}
.mbsc-icon-foundation-results-demographics::before {
    content: "\ee26";
}
.mbsc-icon-foundation-results::before {
    content: "\ee27";
}
.mbsc-icon-foundation-rewind-ten::before {
    content: "\ee28";
}
.mbsc-icon-foundation-rewind::before {
    content: "\ee29";
}
.mbsc-icon-foundation-rss::before {
    content: "\ee2a";
}
.mbsc-icon-foundation-safety-cone::before {
    content: "\ee2b";
}
.mbsc-icon-foundation-save::before {
    content: "\ee2c";
}
.mbsc-icon-foundation-share::before {
    content: "\ee2d";
}
.mbsc-icon-foundation-sheriff-badge::before {
    content: "\ee2e";
}
.mbsc-icon-foundation-shield::before {
    content: "\ee2f";
}
.mbsc-icon-foundation-shopping-bag::before {
    content: "\ee30";
}
.mbsc-icon-foundation-shopping-cart::before {
    content: "\ee31";
}
.mbsc-icon-foundation-shuffle::before {
    content: "\ee32";
}
.mbsc-icon-foundation-skull::before {
    content: "\ee33";
}
.mbsc-icon-foundation-social-500px::before {
    content: "\ee34";
}
.mbsc-icon-foundation-social-adobe::before {
    content: "\ee35";
}
.mbsc-icon-foundation-social-amazon::before {
    content: "\ee36";
}
.mbsc-icon-foundation-social-android::before {
    content: "\ee37";
}
.mbsc-icon-foundation-social-apple::before {
    content: "\ee38";
}
.mbsc-icon-foundation-social-behance::before {
    content: "\ee39";
}
.mbsc-icon-foundation-social-bing::before {
    content: "\ee3a";
}
.mbsc-icon-foundation-social-blogger::before {
    content: "\ee3b";
}
.mbsc-icon-foundation-social-delicious::before {
    content: "\ee3c";
}
.mbsc-icon-foundation-social-designer-news::before {
    content: "\ee3d";
}
.mbsc-icon-foundation-social-deviant-art::before {
    content: "\ee3e";
}
.mbsc-icon-foundation-social-digg::before {
    content: "\ee3f";
}
.mbsc-icon-foundation-social-dribbble::before {
    content: "\ee40";
}
.mbsc-icon-foundation-social-drive::before {
    content: "\ee41";
}
.mbsc-icon-foundation-social-dropbox::before {
    content: "\ee42";
}
.mbsc-icon-foundation-social-evernote::before {
    content: "\ee43";
}
.mbsc-icon-foundation-social-facebook::before {
    content: "\ee44";
}
.mbsc-icon-foundation-social-flickr::before {
    content: "\ee45";
}
.mbsc-icon-foundation-social-forrst::before {
    content: "\ee46";
}
.mbsc-icon-foundation-social-foursquare::before {
    content: "\ee47";
}
.mbsc-icon-foundation-social-game-center::before {
    content: "\ee48";
}
.mbsc-icon-foundation-social-github::before {
    content: "\ee49";
}
.mbsc-icon-foundation-social-google-plus::before {
    content: "\ee4a";
}
.mbsc-icon-foundation-social-hacker-news::before {
    content: "\ee4b";
}
.mbsc-icon-foundation-social-hi5::before {
    content: "\ee4c";
}
.mbsc-icon-foundation-social-instagram::before {
    content: "\ee4d";
}
.mbsc-icon-foundation-social-joomla::before {
    content: "\ee4e";
}
.mbsc-icon-foundation-social-lastfm::before {
    content: "\ee4f";
}
.mbsc-icon-foundation-social-linkedin::before {
    content: "\ee50";
}
.mbsc-icon-foundation-social-medium::before {
    content: "\ee51";
}
.mbsc-icon-foundation-social-myspace::before {
    content: "\ee53";
}
.mbsc-icon-foundation-social-orkut::before {
    content: "\ee52";
}
.mbsc-icon-foundation-social-path::before {
    content: "\ee54";
}
.mbsc-icon-foundation-social-picasa::before {
    content: "\ee55";
}
.mbsc-icon-foundation-social-pinterest::before {
    content: "\ee56";
}
.mbsc-icon-foundation-social-rdio::before {
    content: "\ee57";
}
.mbsc-icon-foundation-social-reddit::before {
    content: "\ee58";
}
.mbsc-icon-foundation-social-skillshare::before {
    content: "\ee59";
}
.mbsc-icon-foundation-social-skype::before {
    content: "\ee5a";
}
.mbsc-icon-foundation-social-smashing-mag::before {
    content: "\ee5b";
}
.mbsc-icon-foundation-social-snapchat::before {
    content: "\ee5c";
}
.mbsc-icon-foundation-social-spotify::before {
    content: "\ee5d";
}
.mbsc-icon-foundation-social-squidoo::before {
    content: "\ee5e";
}
.mbsc-icon-foundation-social-stack-overflow::before {
    content: "\ee5f";
}
.mbsc-icon-foundation-social-steam::before {
    content: "\ee60";
}
.mbsc-icon-foundation-social-stumbleupon::before {
    content: "\ee62";
}
.mbsc-icon-foundation-social-treehouse::before {
    content: "\ee61";
}
.mbsc-icon-foundation-social-tumblr::before {
    content: "\ee63";
}
.mbsc-icon-foundation-social-twitter::before {
    content: "\ee64";
}
.mbsc-icon-foundation-social-vimeo::before {
    content: "\ee65";
}
.mbsc-icon-foundation-social-windows::before {
    content: "\ee66";
}
.mbsc-icon-foundation-social-xbox::before {
    content: "\ee67";
}
.mbsc-icon-foundation-social-yahoo::before {
    content: "\ee68";
}
.mbsc-icon-foundation-social-yelp::before {
    content: "\ee6a";
}
.mbsc-icon-foundation-social-youtube::before {
    content: "\ee69";
}
.mbsc-icon-foundation-social-zerply::before {
    content: "\ee6b";
}
.mbsc-icon-foundation-social-zurb::before {
    content: "\ee6c";
}
.mbsc-icon-foundation-sound::before {
    content: "\ee6d";
}
.mbsc-icon-foundation-star::before {
    content: "\ee6e";
}
.mbsc-icon-foundation-stop::before {
    content: "\ee6f";
}
.mbsc-icon-foundation-strikethrough::before {
    content: "\ee70";
}
.mbsc-icon-foundation-subscript::before {
    content: "\ee72";
}
.mbsc-icon-foundation-superscript::before {
    content: "\ee71";
}
.mbsc-icon-foundation-tablet-landscape::before {
    content: "\ee73";
}
.mbsc-icon-foundation-tablet-portrait::before {
    content: "\ee74";
}
.mbsc-icon-foundation-target-two::before {
    content: "\ee76";
}
.mbsc-icon-foundation-target::before {
    content: "\ee75";
}
.mbsc-icon-foundation-telephone-accessible::before {
    content: "\ee77";
}
.mbsc-icon-foundation-telephone::before {
    content: "\ee78";
}
.mbsc-icon-foundation-text-color::before {
    content: "\ee7a";
}
.mbsc-icon-foundation-thumbnails::before {
    content: "\ee79";
}
.mbsc-icon-foundation-ticket::before {
    content: "\ee7b";
}
.mbsc-icon-foundation-torso-business::before {
    content: "\ee7c";
}
.mbsc-icon-foundation-torso-female::before {
    content: "\ee7d";
}
.mbsc-icon-foundation-torso::before {
    content: "\ee7e";
}
.mbsc-icon-foundation-torsos-all-female::before {
    content: "\ee7f";
}
.mbsc-icon-foundation-torsos-all::before {
    content: "\ee80";
}
.mbsc-icon-foundation-torsos-female-male::before {
    content: "\ee82";
}
.mbsc-icon-foundation-torsos-male-female::before {
    content: "\ee81";
}
.mbsc-icon-foundation-torsos::before {
    content: "\ee83";
}
.mbsc-icon-foundation-trash::before {
    content: "\ee84";
}
.mbsc-icon-foundation-trees::before {
    content: "\ee85";
}
.mbsc-icon-foundation-trophy::before {
    content: "\ee86";
}
.mbsc-icon-foundation-underline::before {
    content: "\ee87";
}
.mbsc-icon-foundation-universal-access::before {
    content: "\ee88";
}
.mbsc-icon-foundation-unlink::before {
    content: "\ee8a";
}
.mbsc-icon-foundation-unlock::before {
    content: "\ee89";
}
.mbsc-icon-foundation-upload-cloud::before {
    content: "\ee8b";
}
.mbsc-icon-foundation-upload::before {
    content: "\ee8c";
}
.mbsc-icon-foundation-usb::before {
    content: "\ee8d";
}
.mbsc-icon-foundation-video::before {
    content: "\ee8e";
}
.mbsc-icon-foundation-volume-none::before {
    content: "\ee8f";
}
.mbsc-icon-foundation-volume-strike::before {
    content: "\ee90";
}
.mbsc-icon-foundation-volume::before {
    content: "\ee91";
}
.mbsc-icon-foundation-web::before {
    content: "\ee92";
}
.mbsc-icon-foundation-wheelchair::before {
    content: "\ee93";
}
.mbsc-icon-foundation-widget::before {
    content: "\ee94";
}
.mbsc-icon-foundation-wrench::before {
    content: "\ee95";
}
.mbsc-icon-foundation-x-circle::before {
    content: "\ee97";
}
.mbsc-icon-foundation-x::before {
    content: "\ee96";
}
.mbsc-icon-foundation-yen::before {
    content: "\ee98";
}
.mbsc-icon-foundation-zoom-in::before {
    content: "\ee9a";
}
.mbsc-icon-foundation-zoom-out::before {
    content: "\ee99";
}
.mbsc-icon-foursquare::before {
    content: "\ee9b";
}
.mbsc-icon-foursquare2::before {
    content: "\ee9c";
}
.mbsc-icon-gift::before {
    content: "\ee9e";
}
.mbsc-icon-github::before {
    content: "\ee9d";
}
.mbsc-icon-github2::before {
    content: "\ee9f";
}
.mbsc-icon-github3::before {
    content: "\eea0";
}
.mbsc-icon-github4::before {
    content: "\eea2";
}
.mbsc-icon-github5::before {
    content: "\eea1";
}
.mbsc-icon-glass::before {
    content: "\eea3";
}
.mbsc-icon-globe::before {
    content: "\eea4";
}
.mbsc-icon-google-drive::before {
    content: "\eea5";
}
.mbsc-icon-google-plus::before {
    content: "\eea6";
}
.mbsc-icon-google-plus2::before {
    content: "\eea7";
}
.mbsc-icon-google-plus3::before {
    content: "\eea8";
}
.mbsc-icon-google-plus4::before {
    content: "\eeaa";
}
.mbsc-icon-google::before {
    content: "\eea9";
}
.mbsc-icon-grin::before {
    content: "\eeab";
}
.mbsc-icon-grin2::before {
    content: "\eeac";
}
.mbsc-icon-hammer::before {
    content: "\eeae";
}
.mbsc-icon-hammer2::before {
    content: "\eead";
}
.mbsc-icon-happy::before {
    content: "\eeaf";
}
.mbsc-icon-happy2::before {
    content: "\eeb0";
}
.mbsc-icon-headphones::before {
    content: "\eeb2";
}
.mbsc-icon-heart-broken::before {
    content: "\eeb1";
}
.mbsc-icon-heart::before {
    content: "\eeb3";
}
.mbsc-icon-heart2::before {
    content: "\eeb4";
}
.mbsc-icon-history::before {
    content: "\eeb6";
}
.mbsc-icon-home::before {
    content: "\eeb5";
}
.mbsc-icon-home2::before {
    content: "\eeb7";
}
.mbsc-icon-home3::before {
    content: "\eeb8";
}
.mbsc-icon-html5::before {
    content: "\eeba";
}
.mbsc-icon-html52::before {
    content: "\eeb9";
}
.mbsc-icon-image::before {
    content: "\eebb";
}
.mbsc-icon-image2::before {
    content: "\eebc";
}
.mbsc-icon-images::before {
    content: "\eebe";
}
.mbsc-icon-indent-decrease::before {
    content: "\eebd";
}
.mbsc-icon-indent-increase::before {
    content: "\eebf";
}
.mbsc-icon-info::before {
    content: "\eec0";
}
.mbsc-icon-info2::before {
    content: "\eec2";
}
.mbsc-icon-insert-template::before {
    content: "\eec1";
}
.mbsc-icon-instagram::before {
    content: "\eec3";
}
.mbsc-icon-ion-alert-circled::before {
    content: "\eec4";
}
.mbsc-icon-ion-alert::before {
    content: "\eec6";
}
.mbsc-icon-ion-android-add-contact::before {
    content: "\eec5";
}
.mbsc-icon-ion-android-add::before {
    content: "\eec7";
}
.mbsc-icon-ion-android-alarm::before {
    content: "\eec8";
}
.mbsc-icon-ion-android-archive::before {
    content: "\eec9";
}
.mbsc-icon-ion-android-arrow-back::before {
    content: "\eeca";
}
.mbsc-icon-ion-android-arrow-down-left::before {
    content: "\eecb";
}
.mbsc-icon-ion-android-arrow-down-right::before {
    content: "\eecd";
}
.mbsc-icon-ion-android-arrow-up-left::before {
    content: "\eecc";
}
.mbsc-icon-ion-android-arrow-up-right::before {
    content: "\eece";
}
.mbsc-icon-ion-android-battery::before {
    content: "\eecf";
}
.mbsc-icon-ion-android-book::before {
    content: "\eed0";
}
.mbsc-icon-ion-android-calendar::before {
    content: "\eed1";
}
.mbsc-icon-ion-android-call::before {
    content: "\eed2";
}
.mbsc-icon-ion-android-camera::before {
    content: "\eed3";
}
.mbsc-icon-ion-android-chat::before {
    content: "\eed4";
}
.mbsc-icon-ion-android-checkmark::before {
    content: "\eed5";
}
.mbsc-icon-ion-android-clock::before {
    content: "\eed6";
}
.mbsc-icon-ion-android-close::before {
    content: "\eed7";
}
.mbsc-icon-ion-android-contact::before {
    content: "\eed8";
}
.mbsc-icon-ion-android-contacts::before {
    content: "\eed9";
}
.mbsc-icon-ion-android-data::before {
    content: "\eeda";
}
.mbsc-icon-ion-android-developer::before {
    content: "\eedb";
}
.mbsc-icon-ion-android-display::before {
    content: "\eedc";
}
.mbsc-icon-ion-android-download::before {
    content: "\eedd";
}
.mbsc-icon-ion-android-dropdown::before {
    content: "\eede";
}
.mbsc-icon-ion-android-earth::before {
    content: "\eedf";
}
.mbsc-icon-ion-android-folder::before {
    content: "\eee0";
}
.mbsc-icon-ion-android-forums::before {
    content: "\eee1";
}
.mbsc-icon-ion-android-friends::before {
    content: "\eee2";
}
.mbsc-icon-ion-android-hand::before {
    content: "\eee3";
}
.mbsc-icon-ion-android-image::before {
    content: "\eee4";
}
.mbsc-icon-ion-android-inbox::before {
    content: "\eee5";
}
.mbsc-icon-ion-android-information::before {
    content: "\eee6";
}
.mbsc-icon-ion-android-keypad::before {
    content: "\eee7";
}
.mbsc-icon-ion-android-lightbulb::before {
    content: "\eee8";
}
.mbsc-icon-ion-android-locate::before {
    content: "\eee9";
}
.mbsc-icon-ion-android-location::before {
    content: "\eeea";
}
.mbsc-icon-ion-android-mail::before {
    content: "\eeeb";
}
.mbsc-icon-ion-android-microphone::before {
    content: "\eeec";
}
.mbsc-icon-ion-android-mixer::before {
    content: "\eeed";
}
.mbsc-icon-ion-android-more::before {
    content: "\eeee";
}
.mbsc-icon-ion-android-note::before {
    content: "\eeef";
}
.mbsc-icon-ion-android-playstore::before {
    content: "\eef0";
}
.mbsc-icon-ion-android-printer::before {
    content: "\eef2";
}
.mbsc-icon-ion-android-promotion::before {
    content: "\eef1";
}
.mbsc-icon-ion-android-reminder::before {
    content: "\eef3";
}
.mbsc-icon-ion-android-remove::before {
    content: "\eef4";
}
.mbsc-icon-ion-android-search::before {
    content: "\eef6";
}
.mbsc-icon-ion-android-send::before {
    content: "\eef5";
}
.mbsc-icon-ion-android-settings::before {
    content: "\eef7";
}
.mbsc-icon-ion-android-share::before {
    content: "\eef9";
}
.mbsc-icon-ion-android-social-user::before {
    content: "\eef8";
}
.mbsc-icon-ion-android-social::before {
    content: "\eefa";
}
.mbsc-icon-ion-android-sort::before {
    content: "\eefb";
}
.mbsc-icon-ion-android-star::before {
    content: "\eefc";
}
.mbsc-icon-ion-android-stopwatch::before {
    content: "\eefe";
}
.mbsc-icon-ion-android-storage::before {
    content: "\eefd";
}
.mbsc-icon-ion-android-system-back::before {
    content: "\eeff";
}
.mbsc-icon-ion-android-system-home::before {
    content: "\ef00";
}
.mbsc-icon-ion-android-system-windows::before {
    content: "\ef01";
}
.mbsc-icon-ion-android-timer::before {
    content: "\ef02";
}
.mbsc-icon-ion-android-trash::before {
    content: "\ef03";
}
.mbsc-icon-ion-android-volume::before {
    content: "\ef04";
}
.mbsc-icon-ion-android-wifi::before {
    content: "\ef05";
}
.mbsc-icon-ion-archive::before {
    content: "\ef06";
}
.mbsc-icon-ion-arrow-down-a::before {
    content: "\ef07";
}
.mbsc-icon-ion-arrow-down-b::before {
    content: "\ef08";
}
.mbsc-icon-ion-arrow-down-c::before {
    content: "\ef09";
}
.mbsc-icon-ion-arrow-expand::before {
    content: "\ef0a";
}
.mbsc-icon-ion-arrow-graph-down-left::before {
    content: "\ef0b";
}
.mbsc-icon-ion-arrow-graph-down-right::before {
    content: "\ef0c";
}
.mbsc-icon-ion-arrow-graph-up-left::before {
    content: "\ef0e";
}
.mbsc-icon-ion-arrow-graph-up-right::before {
    content: "\ef0d";
}
.mbsc-icon-ion-arrow-left-a::before {
    content: "\ef0f";
}
.mbsc-icon-ion-arrow-left-b::before {
    content: "\ef10";
}
.mbsc-icon-ion-arrow-left-c::before {
    content: "\ef11";
}
.mbsc-icon-ion-arrow-move::before {
    content: "\ef12";
}
.mbsc-icon-ion-arrow-resize::before {
    content: "\ef13";
}
.mbsc-icon-ion-arrow-return-left::before {
    content: "\ef14";
}
.mbsc-icon-ion-arrow-return-right::before {
    content: "\ef15";
}
.mbsc-icon-ion-arrow-right-a::before {
    content: "\ef16";
}
.mbsc-icon-ion-arrow-right-b::before {
    content: "\ef17";
}
.mbsc-icon-ion-arrow-right-c::before {
    content: "\ef18";
}
.mbsc-icon-ion-arrow-shrink::before {
    content: "\ef19";
}
.mbsc-icon-ion-arrow-swap::before {
    content: "\ef1a";
}
.mbsc-icon-ion-arrow-up-a::before {
    content: "\ef1b";
}
.mbsc-icon-ion-arrow-up-b::before {
    content: "\ef1c";
}
.mbsc-icon-ion-arrow-up-c::before {
    content: "\ef1d";
}
.mbsc-icon-ion-at::before {
    content: "\ef1e";
}
.mbsc-icon-ion-bag::before {
    content: "\ef1f";
}
.mbsc-icon-ion-battery-charging::before {
    content: "\ef20";
}
.mbsc-icon-ion-battery-empty::before {
    content: "\ef21";
}
.mbsc-icon-ion-battery-full::before {
    content: "\ef22";
}
.mbsc-icon-ion-battery-half::before {
    content: "\ef23";
}
.mbsc-icon-ion-battery-low::before {
    content: "\ef24";
}
.mbsc-icon-ion-beaker::before {
    content: "\ef25";
}
.mbsc-icon-ion-beer::before {
    content: "\ef26";
}
.mbsc-icon-ion-bluetooth::before {
    content: "\ef27";
}
.mbsc-icon-ion-bookmark::before {
    content: "\ef28";
}
.mbsc-icon-ion-briefcase::before {
    content: "\ef29";
}
.mbsc-icon-ion-bug::before {
    content: "\ef2a";
}
.mbsc-icon-ion-calculator::before {
    content: "\ef2b";
}
.mbsc-icon-ion-calendar::before {
    content: "\ef2c";
}
.mbsc-icon-ion-camera::before {
    content: "\ef2d";
}
.mbsc-icon-ion-card::before {
    content: "\ef2e";
}
.mbsc-icon-ion-chatbox-working::before {
    content: "\ef2f";
}
.mbsc-icon-ion-chatbox::before {
    content: "\ef30";
}
.mbsc-icon-ion-chatboxes::before {
    content: "\ef31";
}
.mbsc-icon-ion-chatbubble-working::before {
    content: "\ef32";
}
.mbsc-icon-ion-chatbubble::before {
    content: "\ef33";
}
.mbsc-icon-ion-chatbubbles::before {
    content: "\ef36";
}
.mbsc-icon-ion-checkmark-circled::before {
    content: "\ef34";
}
.mbsc-icon-ion-checkmark-round::before {
    content: "\ef35";
}
.mbsc-icon-ion-checkmark::before {
    content: "\ef37";
}
.mbsc-icon-ion-chevron-down::before {
    content: "\ef39";
}
.mbsc-icon-ion-chevron-left::before {
    content: "\ef38";
}
.mbsc-icon-ion-chevron-right::before {
    content: "\ef3a";
}
.mbsc-icon-ion-chevron-up::before {
    content: "\ef3b";
}
.mbsc-icon-ion-clipboard::before {
    content: "\ef3c";
}
.mbsc-icon-ion-clock::before {
    content: "\ef3d";
}
.mbsc-icon-ion-close-circled::before {
    content: "\ef3e";
}
.mbsc-icon-ion-close-round::before {
    content: "\ef3f";
}
.mbsc-icon-ion-close::before {
    content: "\ef41";
}
.mbsc-icon-ion-cloud::before {
    content: "\ef40";
}
.mbsc-icon-ion-code-download::before {
    content: "\ef42";
}
.mbsc-icon-ion-code-working::before {
    content: "\ef45";
}
.mbsc-icon-ion-code::before {
    content: "\ef43";
}
.mbsc-icon-ion-coffee::before {
    content: "\ef44";
}
.mbsc-icon-ion-compass::before {
    content: "\ef46";
}
.mbsc-icon-ion-compose::before {
    content: "\ef47";
}
.mbsc-icon-ion-connection-bars::before {
    content: "\ef49";
}
.mbsc-icon-ion-contrast::before {
    content: "\ef48";
}
.mbsc-icon-ion-disc::before {
    content: "\ef4a";
}
.mbsc-icon-ion-document-text::before {
    content: "\ef4b";
}
.mbsc-icon-ion-document::before {
    content: "\ef4c";
}
.mbsc-icon-ion-drag::before {
    content: "\ef4d";
}
.mbsc-icon-ion-earth::before {
    content: "\ef52";
}
.mbsc-icon-ion-edit::before {
    content: "\ef4e";
}
.mbsc-icon-ion-egg::before {
    content: "\ef4f";
}
.mbsc-icon-ion-eject::before {
    content: "\ef50";
}
.mbsc-icon-ion-email::before {
    content: "\ef51";
}
.mbsc-icon-ion-eye-disabled::before {
    content: "\ef53";
}
.mbsc-icon-ion-eye::before {
    content: "\ef55";
}
.mbsc-icon-ion-female::before {
    content: "\ef54";
}
.mbsc-icon-ion-filing::before {
    content: "\ef57";
}
.mbsc-icon-ion-film-marker::before {
    content: "\ef56";
}
.mbsc-icon-ion-flag::before {
    content: "\ef58";
}
.mbsc-icon-ion-flash-off::before {
    content: "\ef59";
}
.mbsc-icon-ion-flash::before {
    content: "\ef5a";
}
.mbsc-icon-ion-flask::before {
    content: "\ef5b";
}
.mbsc-icon-ion-folder::before {
    content: "\ef5c";
}
.mbsc-icon-ion-fork-repo::before {
    content: "\ef5d";
}
.mbsc-icon-ion-fork::before {
    content: "\ef5f";
}
.mbsc-icon-ion-forward::before {
    content: "\ef5e";
}
.mbsc-icon-ion-game-controller-a::before {
    content: "\ef60";
}
.mbsc-icon-ion-game-controller-b::before {
    content: "\ef62";
}
.mbsc-icon-ion-gear-a::before {
    content: "\ef61";
}
.mbsc-icon-ion-gear-b::before {
    content: "\ef63";
}
.mbsc-icon-ion-grid::before {
    content: "\ef64";
}
.mbsc-icon-ion-hammer::before {
    content: "\ef65";
}
.mbsc-icon-ion-headphone::before {
    content: "\ef66";
}
.mbsc-icon-ion-heart::before {
    content: "\ef67";
}
.mbsc-icon-ion-help-buoy::before {
    content: "\ef68";
}
.mbsc-icon-ion-help-circled::before {
    content: "\ef69";
}
.mbsc-icon-ion-help::before {
    content: "\ef6a";
}
.mbsc-icon-ion-home::before {
    content: "\ef6b";
}
.mbsc-icon-ion-icecream::before {
    content: "\ef6c";
}
.mbsc-icon-ion-icon-social-google-plus-outline::before {
    content: "\ef6d";
}
.mbsc-icon-ion-icon-social-google-plus::before {
    content: "\ef6f";
}
.mbsc-icon-ion-image::before {
    content: "\ef6e";
}
.mbsc-icon-ion-images::before {
    content: "\ef70";
}
.mbsc-icon-ion-information-circled::before {
    content: "\ef71";
}
.mbsc-icon-ion-information::before {
    content: "\ef72";
}
.mbsc-icon-ion-ionic::before {
    content: "\ef73";
}
.mbsc-icon-ion-ios7-alarm-outline::before {
    content: "\ef74";
}
.mbsc-icon-ion-ios7-alarm::before {
    content: "\ef75";
}
.mbsc-icon-ion-ios7-albums-outline::before {
    content: "\ef78";
}
.mbsc-icon-ion-ios7-albums::before {
    content: "\ef76";
}
.mbsc-icon-ion-ios7-arrow-back::before {
    content: "\ef77";
}
.mbsc-icon-ion-ios7-arrow-down::before {
    content: "\ef79";
}
.mbsc-icon-ion-ios7-arrow-forward::before {
    content: "\ef7a";
}
.mbsc-icon-ion-ios7-arrow-left::before {
    content: "\ef7b";
}
.mbsc-icon-ion-ios7-arrow-right::before {
    content: "\ef7c";
}
.mbsc-icon-ion-ios7-arrow-thin-down::before {
    content: "\ef7d";
}
.mbsc-icon-ion-ios7-arrow-thin-left::before {
    content: "\ef7f";
}
.mbsc-icon-ion-ios7-arrow-thin-right::before {
    content: "\ef7e";
}
.mbsc-icon-ion-ios7-arrow-thin-up::before {
    content: "\ef80";
}
.mbsc-icon-ion-ios7-arrow-up::before {
    content: "\ef81";
}
.mbsc-icon-ion-ios7-at-outline::before {
    content: "\ef82";
}
.mbsc-icon-ion-ios7-at::before {
    content: "\ef83";
}
.mbsc-icon-ion-ios7-bell-outline::before {
    content: "\ef84";
}
.mbsc-icon-ion-ios7-bell::before {
    content: "\ef85";
}
.mbsc-icon-ion-ios7-bolt-outline::before {
    content: "\ef87";
}
.mbsc-icon-ion-ios7-bolt::before {
    content: "\ef86";
}
.mbsc-icon-ion-ios7-bookmarks-outline::before {
    content: "\ef88";
}
.mbsc-icon-ion-ios7-bookmarks::before {
    content: "\ef8a";
}
.mbsc-icon-ion-ios7-box-outline::before {
    content: "\ef89";
}
.mbsc-icon-ion-ios7-box::before {
    content: "\ef8b";
}
.mbsc-icon-ion-ios7-briefcase-outline::before {
    content: "\ef8c";
}
.mbsc-icon-ion-ios7-briefcase::before {
    content: "\ef8d";
}
.mbsc-icon-ion-ios7-browsers-outline::before {
    content: "\ef8f";
}
.mbsc-icon-ion-ios7-browsers::before {
    content: "\ef8e";
}
.mbsc-icon-ion-ios7-calculator-outline::before {
    content: "\ef90";
}
.mbsc-icon-ion-ios7-calculator::before {
    content: "\ef91";
}
.mbsc-icon-ion-ios7-calendar-outline::before {
    content: "\ef92";
}
.mbsc-icon-ion-ios7-calendar::before {
    content: "\ef93";
}
.mbsc-icon-ion-ios7-camera-outline::before {
    content: "\ef94";
}
.mbsc-icon-ion-ios7-camera::before {
    content: "\ef95";
}
.mbsc-icon-ion-ios7-cart-outline::before {
    content: "\ef96";
}
.mbsc-icon-ion-ios7-cart::before {
    content: "\ef97";
}
.mbsc-icon-ion-ios7-chatboxes-outline::before {
    content: "\ef98";
}
.mbsc-icon-ion-ios7-chatboxes::before {
    content: "\ef99";
}
.mbsc-icon-ion-ios7-chatbubble-outline::before {
    content: "\ef9a";
}
.mbsc-icon-ion-ios7-chatbubble::before {
    content: "\ef9b";
}
.mbsc-icon-ion-ios7-checkmark-empty::before {
    content: "\ef9c";
}
.mbsc-icon-ion-ios7-checkmark-outline::before {
    content: "\ef9d";
}
.mbsc-icon-ion-ios7-checkmark::before {
    content: "\ef9f";
}
.mbsc-icon-ion-ios7-circle-filled::before {
    content: "\ef9e";
}
.mbsc-icon-ion-ios7-circle-outline::before {
    content: "\efa0";
}
.mbsc-icon-ion-ios7-clock-outline::before {
    content: "\efa1";
}
.mbsc-icon-ion-ios7-clock::before {
    content: "\efa2";
}
.mbsc-icon-ion-ios7-close-empty::before {
    content: "\efa3";
}
.mbsc-icon-ion-ios7-close-outline::before {
    content: "\efa4";
}
.mbsc-icon-ion-ios7-close::before {
    content: "\efa5";
}
.mbsc-icon-ion-ios7-cloud-download-outline::before {
    content: "\efa6";
}
.mbsc-icon-ion-ios7-cloud-download::before {
    content: "\efaa";
}
.mbsc-icon-ion-ios7-cloud-outline::before {
    content: "\efa7";
}
.mbsc-icon-ion-ios7-cloud-upload-outline::before {
    content: "\efa8";
}
.mbsc-icon-ion-ios7-cloud-upload::before {
    content: "\efa9";
}
.mbsc-icon-ion-ios7-cloud::before {
    content: "\efab";
}
.mbsc-icon-ion-ios7-cloudy-night-outline::before {
    content: "\efac";
}
.mbsc-icon-ion-ios7-cloudy-night::before {
    content: "\efad";
}
.mbsc-icon-ion-ios7-cloudy-outline::before {
    content: "\efae";
}
.mbsc-icon-ion-ios7-cloudy::before {
    content: "\efaf";
}
.mbsc-icon-ion-ios7-cog-outline::before {
    content: "\efb0";
}
.mbsc-icon-ion-ios7-cog::before {
    content: "\efb1";
}
.mbsc-icon-ion-ios7-compose-outline::before {
    content: "\efb2";
}
.mbsc-icon-ion-ios7-compose::before {
    content: "\efb3";
}
.mbsc-icon-ion-ios7-contact-outline::before {
    content: "\efb4";
}
.mbsc-icon-ion-ios7-contact::before {
    content: "\efb5";
}
.mbsc-icon-ion-ios7-copy-outline::before {
    content: "\efb6";
}
.mbsc-icon-ion-ios7-copy::before {
    content: "\efb7";
}
.mbsc-icon-ion-ios7-download-outline::before {
    content: "\efb8";
}
.mbsc-icon-ion-ios7-download::before {
    content: "\efb9";
}
.mbsc-icon-ion-ios7-drag::before {
    content: "\efba";
}
.mbsc-icon-ion-ios7-email-outline::before {
    content: "\efbb";
}
.mbsc-icon-ion-ios7-email::before {
    content: "\efbc";
}
.mbsc-icon-ion-ios7-eye-outline::before {
    content: "\efbd";
}
.mbsc-icon-ion-ios7-eye::before {
    content: "\efbe";
}
.mbsc-icon-ion-ios7-fastforward-outline::before {
    content: "\efbf";
}
.mbsc-icon-ion-ios7-fastforward::before {
    content: "\efc0";
}
.mbsc-icon-ion-ios7-filing-outline::before {
    content: "\efc1";
}
.mbsc-icon-ion-ios7-filing::before {
    content: "\efc2";
}
.mbsc-icon-ion-ios7-film-outline::before {
    content: "\efc3";
}
.mbsc-icon-ion-ios7-film::before {
    content: "\efc4";
}
.mbsc-icon-ion-ios7-flag-outline::before {
    content: "\efc5";
}
.mbsc-icon-ion-ios7-flag::before {
    content: "\efc6";
}
.mbsc-icon-ion-ios7-folder-outline::before {
    content: "\efc7";
}
.mbsc-icon-ion-ios7-folder::before {
    content: "\efc8";
}
.mbsc-icon-ion-ios7-gear-outline::before {
    content: "\efc9";
}
.mbsc-icon-ion-ios7-gear::before {
    content: "\efca";
}
.mbsc-icon-ion-ios7-glasses-outline::before {
    content: "\efcb";
}
.mbsc-icon-ion-ios7-glasses::before {
    content: "\efcc";
}
.mbsc-icon-ion-ios7-heart-outline::before {
    content: "\efcd";
}
.mbsc-icon-ion-ios7-heart::before {
    content: "\efce";
}
.mbsc-icon-ion-ios7-help-empty::before {
    content: "\efcf";
}
.mbsc-icon-ion-ios7-help-outline::before {
    content: "\efd0";
}
.mbsc-icon-ion-ios7-help::before {
    content: "\efd1";
}
.mbsc-icon-ion-ios7-infinite-outline::before {
    content: "\efd2";
}
.mbsc-icon-ion-ios7-infinite::before {
    content: "\efd3";
}
.mbsc-icon-ion-ios7-information-empty::before {
    content: "\efd4";
}
.mbsc-icon-ion-ios7-information-outline::before {
    content: "\efd5";
}
.mbsc-icon-ion-ios7-information::before {
    content: "\efd6";
}
.mbsc-icon-ion-ios7-ionic-outline::before {
    content: "\efd7";
}
.mbsc-icon-ion-ios7-keypad-outline::before {
    content: "\efd8";
}
.mbsc-icon-ion-ios7-keypad::before {
    content: "\efda";
}
.mbsc-icon-ion-ios7-lightbulb-outline::before {
    content: "\efd9";
}
.mbsc-icon-ion-ios7-lightbulb::before {
    content: "\efdb";
}
.mbsc-icon-ion-ios7-location-outline::before {
    content: "\efdc";
}
.mbsc-icon-ion-ios7-location::before {
    content: "\efdd";
}
.mbsc-icon-ion-ios7-locked-outline::before {
    content: "\efde";
}
.mbsc-icon-ion-ios7-locked::before {
    content: "\efdf";
}
.mbsc-icon-ion-ios7-medkit-outline::before {
    content: "\efe0";
}
.mbsc-icon-ion-ios7-medkit::before {
    content: "\efe1";
}
.mbsc-icon-ion-ios7-mic-off::before {
    content: "\efe2";
}
.mbsc-icon-ion-ios7-mic-outline::before {
    content: "\efe3";
}
.mbsc-icon-ion-ios7-mic::before {
    content: "\efe4";
}
.mbsc-icon-ion-ios7-minus-empty::before {
    content: "\efe5";
}
.mbsc-icon-ion-ios7-minus-outline::before {
    content: "\efe6";
}
.mbsc-icon-ion-ios7-minus::before {
    content: "\efe7";
}
.mbsc-icon-ion-ios7-monitor-outline::before {
    content: "\efe8";
}
.mbsc-icon-ion-ios7-monitor::before {
    content: "\efe9";
}
.mbsc-icon-ion-ios7-moon-outline::before {
    content: "\efea";
}
.mbsc-icon-ion-ios7-moon::before {
    content: "\efeb";
}
.mbsc-icon-ion-ios7-more-outline::before {
    content: "\efec";
}
.mbsc-icon-ion-ios7-more::before {
    content: "\efed";
}
.mbsc-icon-ion-ios7-musical-note::before {
    content: "\efee";
}
.mbsc-icon-ion-ios7-musical-notes::before {
    content: "\efef";
}
.mbsc-icon-ion-ios7-navigate-outline::before {
    content: "\eff0";
}
.mbsc-icon-ion-ios7-navigate::before {
    content: "\eff1";
}
.mbsc-icon-ion-ios7-paperplane-outline::before {
    content: "\eff2";
}
.mbsc-icon-ion-ios7-paperplane::before {
    content: "\eff3";
}
.mbsc-icon-ion-ios7-partlysunny-outline::before {
    content: "\eff4";
}
.mbsc-icon-ion-ios7-partlysunny::before {
    content: "\eff5";
}
.mbsc-icon-ion-ios7-pause-outline::before {
    content: "\eff6";
}
.mbsc-icon-ion-ios7-pause::before {
    content: "\eff7";
}
.mbsc-icon-ion-ios7-people-outline::before {
    content: "\eff8";
}
.mbsc-icon-ion-ios7-people::before {
    content: "\eff9";
}
.mbsc-icon-ion-ios7-person-outline::before {
    content: "\effa";
}
.mbsc-icon-ion-ios7-person::before {
    content: "\effb";
}
.mbsc-icon-ion-ios7-personadd-outline::before {
    content: "\effc";
}
.mbsc-icon-ion-ios7-personadd::before {
    content: "\effe";
}
.mbsc-icon-ion-ios7-photos-outline::before {
    content: "\effd";
}
.mbsc-icon-ion-ios7-photos::before {
    content: "\efff";
}
.mbsc-icon-ion-ios7-pie-outline::before {
    content: "\f000";
}
.mbsc-icon-ion-ios7-pie::before {
    content: "\f001";
}
.mbsc-icon-ion-ios7-play-outline::before {
    content: "\f002";
}
.mbsc-icon-ion-ios7-play::before {
    content: "\f003";
}
.mbsc-icon-ion-ios7-plus-empty::before {
    content: "\f004";
}
.mbsc-icon-ion-ios7-plus-outline::before {
    content: "\f005";
}
.mbsc-icon-ion-ios7-plus::before {
    content: "\f006";
}
.mbsc-icon-ion-ios7-pricetag-outline::before {
    content: "\f007";
}
.mbsc-icon-ion-ios7-pricetag::before {
    content: "\f008";
}
.mbsc-icon-ion-ios7-printer-outline::before {
    content: "\f009";
}
.mbsc-icon-ion-ios7-printer::before {
    content: "\f00a";
}
.mbsc-icon-ion-ios7-rainy-outline::before {
    content: "\f00b";
}
.mbsc-icon-ion-ios7-rainy::before {
    content: "\f00c";
}
.mbsc-icon-ion-ios7-recording-outline::before {
    content: "\f00d";
}
.mbsc-icon-ion-ios7-recording::before {
    content: "\f00e";
}
.mbsc-icon-ion-ios7-redo-outline::before {
    content: "\f00f";
}
.mbsc-icon-ion-ios7-redo::before {
    content: "\f010";
}
.mbsc-icon-ion-ios7-refresh-empty::before {
    content: "\f011";
}
.mbsc-icon-ion-ios7-refresh-outline::before {
    content: "\f012";
}
.mbsc-icon-ion-ios7-refresh::before {
    content: "\f013";
}
.mbsc-icon-ion-ios7-reload::before {
    content: "\f014";
}
.mbsc-icon-ion-ios7-rewind-outline::before {
    content: "\f015";
}
.mbsc-icon-ion-ios7-rewind::before {
    content: "\f016";
}
.mbsc-icon-ion-ios7-search-strong::before {
    content: "\f017";
}
.mbsc-icon-ion-ios7-search::before {
    content: "\f018";
}
.mbsc-icon-ion-ios7-skipbackward-outline::before {
    content: "\f019";
}
.mbsc-icon-ion-ios7-skipbackward::before {
    content: "\f01a";
}
.mbsc-icon-ion-ios7-skipforward-outline::before {
    content: "\f01b";
}
.mbsc-icon-ion-ios7-skipforward::before {
    content: "\f01c";
}
.mbsc-icon-ion-ios7-snowy::before {
    content: "\f01d";
}
.mbsc-icon-ion-ios7-speedometer-outline::before {
    content: "\f01e";
}
.mbsc-icon-ion-ios7-speedometer::before {
    content: "\f01f";
}
.mbsc-icon-ion-ios7-star-outline::before {
    content: "\f020";
}
.mbsc-icon-ion-ios7-star::before {
    content: "\f021";
}
.mbsc-icon-ion-ios7-stopwatch-outline::before {
    content: "\f022";
}
.mbsc-icon-ion-ios7-stopwatch::before {
    content: "\f023";
}
.mbsc-icon-ion-ios7-sunny-outline::before {
    content: "\f024";
}
.mbsc-icon-ion-ios7-sunny::before {
    content: "\f025";
}
.mbsc-icon-ion-ios7-telephone-outline::before {
    content: "\f026";
}
.mbsc-icon-ion-ios7-telephone::before {
    content: "\f027";
}
.mbsc-icon-ion-ios7-thunderstorm-outline::before {
    content: "\f028";
}
.mbsc-icon-ion-ios7-thunderstorm::before {
    content: "\f029";
}
.mbsc-icon-ion-ios7-time-outline::before {
    content: "\f02a";
}
.mbsc-icon-ion-ios7-time::before {
    content: "\f02b";
}
.mbsc-icon-ion-ios7-timer-outline::before {
    content: "\f02c";
}
.mbsc-icon-ion-ios7-timer::before {
    content: "\f02d";
}
.mbsc-icon-ion-ios7-trash-outline::before {
    content: "\f02e";
}
.mbsc-icon-ion-ios7-trash::before {
    content: "\f02f";
}
.mbsc-icon-ion-ios7-undo-outline::before {
    content: "\f030";
}
.mbsc-icon-ion-ios7-undo::before {
    content: "\f031";
}
.mbsc-icon-ion-ios7-unlocked-outline::before {
    content: "\f032";
}
.mbsc-icon-ion-ios7-unlocked::before {
    content: "\f033";
}
.mbsc-icon-ion-ios7-upload-outline::before {
    content: "\f034";
}
.mbsc-icon-ion-ios7-upload::before {
    content: "\f035";
}
.mbsc-icon-ion-ios7-videocam-outline::before {
    content: "\f036";
}
.mbsc-icon-ion-ios7-videocam::before {
    content: "\f037";
}
.mbsc-icon-ion-ios7-volume-high::before {
    content: "\f038";
}
.mbsc-icon-ion-ios7-volume-low::before {
    content: "\f039";
}
.mbsc-icon-ion-ios7-wineglass-outline::before {
    content: "\f03a";
}
.mbsc-icon-ion-ios7-wineglass::before {
    content: "\f03b";
}
.mbsc-icon-ion-ios7-world-outline::before {
    content: "\f03c";
}
.mbsc-icon-ion-ios7-world::before {
    content: "\f03d";
}
.mbsc-icon-ion-ipad::before {
    content: "\f03e";
}
.mbsc-icon-ion-iphone::before {
    content: "\f03f";
}
.mbsc-icon-ion-ipod::before {
    content: "\f040";
}
.mbsc-icon-ion-jet::before {
    content: "\f041";
}
.mbsc-icon-ion-key::before {
    content: "\f042";
}
.mbsc-icon-ion-knife::before {
    content: "\f043";
}
.mbsc-icon-ion-laptop::before {
    content: "\f044";
}
.mbsc-icon-ion-leaf::before {
    content: "\f045";
}
.mbsc-icon-ion-levels::before {
    content: "\f046";
}
.mbsc-icon-ion-lightbulb::before {
    content: "\f047";
}
.mbsc-icon-ion-link::before {
    content: "\f048";
}
.mbsc-icon-ion-load-a::before {
    content: "\f049";
}
.mbsc-icon-ion-load-b::before {
    content: "\f04a";
}
.mbsc-icon-ion-load-c::before {
    content: "\f04b";
}
.mbsc-icon-ion-load-d::before {
    content: "\f04c";
}
.mbsc-icon-ion-location::before {
    content: "\f04d";
}
.mbsc-icon-ion-locked::before {
    content: "\f04e";
}
.mbsc-icon-ion-log-in::before {
    content: "\f04f";
}
.mbsc-icon-ion-log-out::before {
    content: "\f050";
}
.mbsc-icon-ion-loop::before {
    content: "\f051";
}
.mbsc-icon-ion-magnet::before {
    content: "\f052";
}
.mbsc-icon-ion-male::before {
    content: "\f053";
}
.mbsc-icon-ion-man::before {
    content: "\f054";
}
.mbsc-icon-ion-map::before {
    content: "\f055";
}
.mbsc-icon-ion-medkit::before {
    content: "\f056";
}
.mbsc-icon-ion-mic-a::before {
    content: "\f057";
}
.mbsc-icon-ion-mic-b::before {
    content: "\f058";
}
.mbsc-icon-ion-mic-c::before {
    content: "\f059";
}
.mbsc-icon-ion-minus-circled::before {
    content: "\f05a";
}
.mbsc-icon-ion-minus-round::before {
    content: "\f05b";
}
.mbsc-icon-ion-minus::before {
    content: "\f05c";
}
.mbsc-icon-ion-model-s::before {
    content: "\f05d";
}
.mbsc-icon-ion-monitor::before {
    content: "\f05e";
}
.mbsc-icon-ion-more::before {
    content: "\f05f";
}
.mbsc-icon-ion-music-note::before {
    content: "\f060";
}
.mbsc-icon-ion-navicon-round::before {
    content: "\f061";
}
.mbsc-icon-ion-navicon::before {
    content: "\f062";
}
.mbsc-icon-ion-navigate::before {
    content: "\f063";
}
.mbsc-icon-ion-no-smoking::before {
    content: "\f064";
}
.mbsc-icon-ion-nuclear::before {
    content: "\f065";
}
.mbsc-icon-ion-paper-airplane::before {
    content: "\f066";
}
.mbsc-icon-ion-paperclip::before {
    content: "\f067";
}
.mbsc-icon-ion-pause::before {
    content: "\f068";
}
.mbsc-icon-ion-person-add::before {
    content: "\f069";
}
.mbsc-icon-ion-person-stalker::before {
    content: "\f06a";
}
.mbsc-icon-ion-person::before {
    content: "\f06b";
}
.mbsc-icon-ion-pie-graph::before {
    content: "\f06c";
}
.mbsc-icon-ion-pin::before {
    content: "\f06d";
}
.mbsc-icon-ion-pinpoint::before {
    content: "\f06e";
}
.mbsc-icon-ion-pizza::before {
    content: "\f06f";
}
.mbsc-icon-ion-plane::before {
    content: "\f070";
}
.mbsc-icon-ion-play::before {
    content: "\f071";
}
.mbsc-icon-ion-playstation::before {
    content: "\f072";
}
.mbsc-icon-ion-plus-circled::before {
    content: "\f073";
}
.mbsc-icon-ion-plus-round::before {
    content: "\f074";
}
.mbsc-icon-ion-plus::before {
    content: "\f075";
}
.mbsc-icon-ion-pound::before {
    content: "\f076";
}
.mbsc-icon-ion-power::before {
    content: "\f077";
}
.mbsc-icon-ion-pricetag::before {
    content: "\f078";
}
.mbsc-icon-ion-pricetags::before {
    content: "\f079";
}
.mbsc-icon-ion-printer::before {
    content: "\f07a";
}
.mbsc-icon-ion-radio-waves::before {
    content: "\f07b";
}
.mbsc-icon-ion-record::before {
    content: "\f07c";
}
.mbsc-icon-ion-refresh::before {
    content: "\f07d";
}
.mbsc-icon-ion-reply-all::before {
    content: "\f07e";
}
.mbsc-icon-ion-reply::before {
    content: "\f07f";
}
.mbsc-icon-ion-search::before {
    content: "\f080";
}
.mbsc-icon-ion-settings::before {
    content: "\f081";
}
.mbsc-icon-ion-share::before {
    content: "\f082";
}
.mbsc-icon-ion-shuffle::before {
    content: "\f083";
}
.mbsc-icon-ion-skip-backward::before {
    content: "\f084";
}
.mbsc-icon-ion-skip-forward::before {
    content: "\f085";
}
.mbsc-icon-ion-social-android-outline::before {
    content: "\f086";
}
.mbsc-icon-ion-social-android::before {
    content: "\f087";
}
.mbsc-icon-ion-social-apple-outline::before {
    content: "\f088";
}
.mbsc-icon-ion-social-apple::before {
    content: "\f089";
}
.mbsc-icon-ion-social-bitcoin-outline::before {
    content: "\f08a";
}
.mbsc-icon-ion-social-bitcoin::before {
    content: "\f08b";
}
.mbsc-icon-ion-social-buffer-outline::before {
    content: "\f08c";
}
.mbsc-icon-ion-social-buffer::before {
    content: "\f08d";
}
.mbsc-icon-ion-social-designernews-outline::before {
    content: "\f08e";
}
.mbsc-icon-ion-social-designernews::before {
    content: "\f08f";
}
.mbsc-icon-ion-social-dribbble-outline::before {
    content: "\f090";
}
.mbsc-icon-ion-social-dribbble::before {
    content: "\f091";
}
.mbsc-icon-ion-social-dropbox-outline::before {
    content: "\f092";
}
.mbsc-icon-ion-social-dropbox::before {
    content: "\f093";
}
.mbsc-icon-ion-social-facebook-outline::before {
    content: "\f094";
}
.mbsc-icon-ion-social-facebook::before {
    content: "\f095";
}
.mbsc-icon-ion-social-freebsd-devil::before {
    content: "\f096";
}
.mbsc-icon-ion-social-github-outline::before {
    content: "\f097";
}
.mbsc-icon-ion-social-github::before {
    content: "\f098";
}
.mbsc-icon-ion-social-googleplus-outline::before {
    content: "\f099";
}
.mbsc-icon-ion-social-googleplus::before {
    content: "\f09a";
}
.mbsc-icon-ion-social-hackernews-outline::before {
    content: "\f09b";
}
.mbsc-icon-ion-social-hackernews::before {
    content: "\f09c";
}
.mbsc-icon-ion-social-linkedin-outline::before {
    content: "\f09d";
}
.mbsc-icon-ion-social-linkedin::before {
    content: "\f09e";
}
.mbsc-icon-ion-social-pinterest-outline::before {
    content: "\f09f";
}
.mbsc-icon-ion-social-pinterest::before {
    content: "\f0a0";
}
.mbsc-icon-ion-social-reddit-outline::before {
    content: "\f0a1";
}
.mbsc-icon-ion-social-reddit::before {
    content: "\f0a2";
}
.mbsc-icon-ion-social-rss-outline::before {
    content: "\f0a3";
}
.mbsc-icon-ion-social-rss::before {
    content: "\f0a4";
}
.mbsc-icon-ion-social-skype-outline::before {
    content: "\f0a5";
}
.mbsc-icon-ion-social-skype::before {
    content: "\f0a6";
}
.mbsc-icon-ion-social-tumblr-outline::before {
    content: "\f0a7";
}
.mbsc-icon-ion-social-tumblr::before {
    content: "\f0a8";
}
.mbsc-icon-ion-social-tux::before {
    content: "\f0a9";
}
.mbsc-icon-ion-social-twitter-outline::before {
    content: "\f0aa";
}
.mbsc-icon-ion-social-twitter::before {
    content: "\f0ab";
}
.mbsc-icon-ion-social-vimeo-outline::before {
    content: "\f0ac";
}
.mbsc-icon-ion-social-vimeo::before {
    content: "\f0ad";
}
.mbsc-icon-ion-social-windows-outline::before {
    content: "\f0ae";
}
.mbsc-icon-ion-social-windows::before {
    content: "\f0af";
}
.mbsc-icon-ion-social-wordpress-outline::before {
    content: "\f0b0";
}
.mbsc-icon-ion-social-wordpress::before {
    content: "\f0b1";
}
.mbsc-icon-ion-social-yahoo-outline::before {
    content: "\f0b2";
}
.mbsc-icon-ion-social-yahoo::before {
    content: "\f0b3";
}
.mbsc-icon-ion-social-youtube-outline::before {
    content: "\f0b4";
}
.mbsc-icon-ion-social-youtube::before {
    content: "\f0b5";
}
.mbsc-icon-ion-speakerphone::before {
    content: "\f0b6";
}
.mbsc-icon-ion-speedometer::before {
    content: "\f0b9";
}
.mbsc-icon-ion-spoon::before {
    content: "\f0b7";
}
.mbsc-icon-ion-star::before {
    content: "\f0b8";
}
.mbsc-icon-ion-stats-bars::before {
    content: "\f0ba";
}
.mbsc-icon-ion-steam::before {
    content: "\f0bb";
}
.mbsc-icon-ion-stop::before {
    content: "\f0bc";
}
.mbsc-icon-ion-thermometer::before {
    content: "\f0bd";
}
.mbsc-icon-ion-thumbsdown::before {
    content: "\f0be";
}
.mbsc-icon-ion-thumbsup::before {
    content: "\f0bf";
}
.mbsc-icon-ion-trash-a::before {
    content: "\f0c0";
}
.mbsc-icon-ion-trash-b::before {
    content: "\f0c1";
}
.mbsc-icon-ion-umbrella::before {
    content: "\f0c2";
}
.mbsc-icon-ion-unlocked::before {
    content: "\f0c3";
}
.mbsc-icon-ion-upload::before {
    content: "\f0c4";
}
.mbsc-icon-ion-usb::before {
    content: "\f0c5";
}
.mbsc-icon-ion-videocamera::before {
    content: "\f0c6";
}
.mbsc-icon-ion-volume-high::before {
    content: "\f0c7";
}
.mbsc-icon-ion-volume-low::before {
    content: "\f0c8";
}
.mbsc-icon-ion-volume-medium::before {
    content: "\f0c9";
}
.mbsc-icon-ion-volume-mute::before {
    content: "\f0ca";
}
.mbsc-icon-ion-waterdrop::before {
    content: "\f0cb";
}
.mbsc-icon-ion-wifi::before {
    content: "\f0cc";
}
.mbsc-icon-ion-wineglass::before {
    content: "\f0cd";
}
.mbsc-icon-ion-woman::before {
    content: "\f0cf";
}
.mbsc-icon-ion-wrench::before {
    content: "\f0ce";
}
.mbsc-icon-ion-xbox::before {
    content: "\f0d0";
}
.mbsc-icon-ios-backspace::before {
    content: "\f0d1";
}
.mbsc-icon-italic::before {
    content: "\f0d3";
}
.mbsc-icon-joomla::before {
    content: "\f0d2";
}
.mbsc-icon-key::before {
    content: "\f0d4";
}
.mbsc-icon-key2::before {
    content: "\f0d5";
}
.mbsc-icon-keyboard::before {
    content: "\f0d6";
}
.mbsc-icon-lab::before {
    content: "\f0d7";
}
.mbsc-icon-lanyrd::before {
    content: "\f0d8";
}
.mbsc-icon-laptop::before {
    content: "\f0da";
}
.mbsc-icon-last::before {
    content: "\f0d9";
}
.mbsc-icon-lastfm::before {
    content: "\f0db";
}
.mbsc-icon-lastfm2::before {
    content: "\f0dc";
}
.mbsc-icon-leaf::before {
    content: "\f0dd";
}
.mbsc-icon-left-toright::before {
    content: "\f0de";
}
.mbsc-icon-library::before {
    content: "\f0df";
}
.mbsc-icon-libreoffice::before {
    content: "\f0e0";
}
.mbsc-icon-lightning::before {
    content: "\f0e1";
}
.mbsc-icon-line-banknote::before {
    content: "\f0e2";
}
.mbsc-icon-line-bubble::before {
    content: "\f0e3";
}
.mbsc-icon-line-bulb::before {
    content: "\f0e4";
}
.mbsc-icon-line-calendar::before {
    content: "\f0e5";
}
.mbsc-icon-line-camera::before {
    content: "\f0e7";
}
.mbsc-icon-line-clip::before {
    content: "\f0e6";
}
.mbsc-icon-line-clock::before {
    content: "\f0e8";
}
.mbsc-icon-line-cloud::before {
    content: "\f0e9";
}
.mbsc-icon-line-cup::before {
    content: "\f0ea";
}
.mbsc-icon-line-data::before {
    content: "\f0eb";
}
.mbsc-icon-line-diamond::before {
    content: "\f0ec";
}
.mbsc-icon-line-display::before {
    content: "\f0ed";
}
.mbsc-icon-line-eye::before {
    content: "\f0ee";
}
.mbsc-icon-line-fire::before {
    content: "\f0ef";
}
.mbsc-icon-line-food::before {
    content: "\f0f0";
}
.mbsc-icon-line-heart::before {
    content: "\f0f1";
}
.mbsc-icon-line-key::before {
    content: "\f0f4";
}
.mbsc-icon-line-lab::before {
    content: "\f0f2";
}
.mbsc-icon-line-like::before {
    content: "\f0f3";
}
.mbsc-icon-line-location::before {
    content: "\f0f5";
}
.mbsc-icon-line-lock::before {
    content: "\f0f6";
}
.mbsc-icon-line-mail::before {
    content: "\f0f7";
}
.mbsc-icon-line-megaphone::before {
    content: "\f0f8";
}
.mbsc-icon-line-music::before {
    content: "\f0f9";
}
.mbsc-icon-line-news::before {
    content: "\f0fa";
}
.mbsc-icon-line-note::before {
    content: "\f0fb";
}
.mbsc-icon-line-paperplane::before {
    content: "\f0fc";
}
.mbsc-icon-line-params::before {
    content: "\f0fd";
}
.mbsc-icon-line-pen::before {
    content: "\f0fe";
}
.mbsc-icon-line-phone::before {
    content: "\f0ff";
}
.mbsc-icon-line-photo::before {
    content: "\f100";
}
.mbsc-icon-line-search::before {
    content: "\f101";
}
.mbsc-icon-line-settings::before {
    content: "\f102";
}
.mbsc-icon-line-shop::before {
    content: "\f103";
}
.mbsc-icon-line-sound::before {
    content: "\f104";
}
.mbsc-icon-line-stack::before {
    content: "\f105";
}
.mbsc-icon-line-star::before {
    content: "\f106";
}
.mbsc-icon-line-study::before {
    content: "\f107";
}
.mbsc-icon-line-t-shirt::before {
    content: "\f108";
}
.mbsc-icon-line-tag::before {
    content: "\f109";
}
.mbsc-icon-line-trash::before {
    content: "\f10a";
}
.mbsc-icon-line-truck::before {
    content: "\f10b";
}
.mbsc-icon-line-tv::before {
    content: "\f10c";
}
.mbsc-icon-line-user::before {
    content: "\f10d";
}
.mbsc-icon-line-video::before {
    content: "\f10e";
}
.mbsc-icon-line-vynil::before {
    content: "\f10f";
}
.mbsc-icon-line-wallet::before {
    content: "\f110";
}
.mbsc-icon-line-world::before {
    content: "\f111";
}
.mbsc-icon-link::before {
    content: "\f112";
}
.mbsc-icon-linkedin::before {
    content: "\f113";
}
.mbsc-icon-list::before {
    content: "\f114";
}
.mbsc-icon-list2::before {
    content: "\f115";
}
.mbsc-icon-location::before {
    content: "\f116";
}
.mbsc-icon-location2::before {
    content: "\f117";
}
.mbsc-icon-lock::before {
    content: "\f118";
}
.mbsc-icon-lock2::before {
    content: "\f119";
}
.mbsc-icon-loop::before {
    content: "\f11a";
}
.mbsc-icon-loop2::before {
    content: "\f11b";
}
.mbsc-icon-loop3::before {
    content: "\f11c";
}
.mbsc-icon-magnet::before {
    content: "\f11d";
}
.mbsc-icon-mail::before {
    content: "\f11e";
}
.mbsc-icon-mail2::before {
    content: "\f11f";
}
.mbsc-icon-mail3::before {
    content: "\f120";
}
.mbsc-icon-mail4::before {
    content: "\f121";
}
.mbsc-icon-map::before {
    content: "\f122";
}
.mbsc-icon-map2::before {
    content: "\f123";
}
.mbsc-icon-material-3d-rotation::before {
    content: "\f124";
}
.mbsc-icon-material-ac-unit::before {
    content: "\f125";
}
.mbsc-icon-material-access-alarm::before {
    content: "\f126";
}
.mbsc-icon-material-access-alarms::before {
    content: "\f127";
}
.mbsc-icon-material-access-time::before {
    content: "\f128";
}
.mbsc-icon-material-accessibility::before {
    content: "\f129";
}
.mbsc-icon-material-accessible::before {
    content: "\f12a";
}
.mbsc-icon-material-account-balance-wallet::before {
    content: "\f12b";
}
.mbsc-icon-material-account-balance::before {
    content: "\f12c";
}
.mbsc-icon-material-account-box::before {
    content: "\f12d";
}
.mbsc-icon-material-account-child::before {
    content: "\f12e";
}
.mbsc-icon-material-account-circle::before {
    content: "\f12f";
}
.mbsc-icon-material-adb::before {
    content: "\f130";
}
.mbsc-icon-material-add-a-photo::before {
    content: "\f131";
}
.mbsc-icon-material-add-alarm::before {
    content: "\f132";
}
.mbsc-icon-material-add-alert::before {
    content: "\f133";
}
.mbsc-icon-material-add-box::before {
    content: "\f134";
}
.mbsc-icon-material-add-circle-outline::before {
    content: "\f135";
}
.mbsc-icon-material-add-circle::before {
    content: "\f136";
}
.mbsc-icon-material-add-location::before {
    content: "\f137";
}
.mbsc-icon-material-add-shopping-cart::before {
    content: "\f138";
}
.mbsc-icon-material-add-to-photos::before {
    content: "\f139";
}
.mbsc-icon-material-add-to-queue::before {
    content: "\f13a";
}
.mbsc-icon-material-add::before {
    content: "\f13b";
}
.mbsc-icon-material-adjust::before {
    content: "\f13c";
}
.mbsc-icon-material-airplanemode-off::before {
    content: "\f13d";
}
.mbsc-icon-material-airplanemode-on::before {
    content: "\f13e";
}
.mbsc-icon-material-airplay::before {
    content: "\f13f";
}
.mbsc-icon-material-airport-shuttle::before {
    content: "\f140";
}
.mbsc-icon-material-alarm-add::before {
    content: "\f141";
}
.mbsc-icon-material-alarm-off::before {
    content: "\f142";
}
.mbsc-icon-material-alarm-on::before {
    content: "\f143";
}
.mbsc-icon-material-alarm::before {
    content: "\f144";
}
.mbsc-icon-material-album::before {
    content: "\f145";
}
.mbsc-icon-material-all-inclusive::before {
    content: "\f146";
}
.mbsc-icon-material-android::before {
    content: "\f147";
}
.mbsc-icon-material-announcement::before {
    content: "\f148";
}
.mbsc-icon-material-apps::before {
    content: "\f149";
}
.mbsc-icon-material-archive::before {
    content: "\f14a";
}
.mbsc-icon-material-arrow-back::before {
    content: "\f14b";
}
.mbsc-icon-material-arrow-downward::before {
    content: "\f14c";
}
.mbsc-icon-material-arrow-drop-down-circle::before {
    content: "\f14d";
}
.mbsc-icon-material-arrow-drop-down::before {
    content: "\f14e";
}
.mbsc-icon-material-arrow-drop-up::before {
    content: "\f14f";
}
.mbsc-icon-material-arrow-forward::before {
    content: "\f150";
}
.mbsc-icon-material-arrow-upward::before {
    content: "\f151";
}
.mbsc-icon-material-art-track::before {
    content: "\f152";
}
.mbsc-icon-material-aspect-ratio::before {
    content: "\f153";
}
.mbsc-icon-material-assessment::before {
    content: "\f154";
}
.mbsc-icon-material-assignment-ind::before {
    content: "\f155";
}
.mbsc-icon-material-assignment-late::before {
    content: "\f156";
}
.mbsc-icon-material-assignment-return::before {
    content: "\f157";
}
.mbsc-icon-material-assignment-returned::before {
    content: "\f158";
}
.mbsc-icon-material-assignment-turned-in::before {
    content: "\f159";
}
.mbsc-icon-material-assignment::before {
    content: "\f15a";
}
.mbsc-icon-material-assistant-photo::before {
    content: "\f15b";
}
.mbsc-icon-material-assistant::before {
    content: "\f15c";
}
.mbsc-icon-material-attach-file::before {
    content: "\f15d";
}
.mbsc-icon-material-attach-money::before {
    content: "\f15e";
}
.mbsc-icon-material-attachment::before {
    content: "\f15f";
}
.mbsc-icon-material-audiotrack::before {
    content: "\f161";
}
.mbsc-icon-material-autorenew::before {
    content: "\f160";
}
.mbsc-icon-material-av-timer::before {
    content: "\f162";
}
.mbsc-icon-material-backspace::before {
    content: "\f163";
}
.mbsc-icon-material-backup::before {
    content: "\f164";
}
.mbsc-icon-material-battery-alert::before {
    content: "\f165";
}
.mbsc-icon-material-battery-charging-full::before {
    content: "\f166";
}
.mbsc-icon-material-battery-full::before {
    content: "\f167";
}
.mbsc-icon-material-battery-unknown::before {
    content: "\f168";
}
.mbsc-icon-material-beach-access::before {
    content: "\f169";
}
.mbsc-icon-material-beenhere::before {
    content: "\f16a";
}
.mbsc-icon-material-block::before {
    content: "\f16b";
}
.mbsc-icon-material-bluetooth-audio::before {
    content: "\f16c";
}
.mbsc-icon-material-bluetooth-connected::before {
    content: "\f16d";
}
.mbsc-icon-material-bluetooth-disabled::before {
    content: "\f16e";
}
.mbsc-icon-material-bluetooth-searching::before {
    content: "\f16f";
}
.mbsc-icon-material-bluetooth::before {
    content: "\f170";
}
.mbsc-icon-material-blur-circular::before {
    content: "\f171";
}
.mbsc-icon-material-blur-linear::before {
    content: "\f172";
}
.mbsc-icon-material-blur-off::before {
    content: "\f173";
}
.mbsc-icon-material-blur-on::before {
    content: "\f174";
}
.mbsc-icon-material-book::before {
    content: "\f175";
}
.mbsc-icon-material-bookmark-border::before {
    content: "\f176";
}
.mbsc-icon-material-bookmark-outline::before {
    content: "\f177";
}
.mbsc-icon-material-bookmark::before {
    content: "\f178";
}
.mbsc-icon-material-border-all::before {
    content: "\f179";
}
.mbsc-icon-material-border-bottom::before {
    content: "\f17a";
}
.mbsc-icon-material-border-clear::before {
    content: "\f17b";
}
.mbsc-icon-material-border-color::before {
    content: "\f17c";
}
.mbsc-icon-material-border-horizontal::before {
    content: "\f17d";
}
.mbsc-icon-material-border-inner::before {
    content: "\f17e";
}
.mbsc-icon-material-border-left::before {
    content: "\f17f";
}
.mbsc-icon-material-border-outer::before {
    content: "\f180";
}
.mbsc-icon-material-border-right::before {
    content: "\f181";
}
.mbsc-icon-material-border-style::before {
    content: "\f182";
}
.mbsc-icon-material-border-top::before {
    content: "\f183";
}
.mbsc-icon-material-border-vertical::before {
    content: "\f184";
}
.mbsc-icon-material-brightness-1::before {
    content: "\f185";
}
.mbsc-icon-material-brightness-2::before {
    content: "\f186";
}
.mbsc-icon-material-brightness-3::before {
    content: "\f187";
}
.mbsc-icon-material-brightness-4::before {
    content: "\f188";
}
.mbsc-icon-material-brightness-5::before {
    content: "\f189";
}
.mbsc-icon-material-brightness-6::before {
    content: "\f18a";
}
.mbsc-icon-material-brightness-7::before {
    content: "\f18b";
}
.mbsc-icon-material-brightness-auto::before {
    content: "\f18c";
}
.mbsc-icon-material-brightness-high::before {
    content: "\f18d";
}
.mbsc-icon-material-brightness-low::before {
    content: "\f18e";
}
.mbsc-icon-material-brightness-medium::before {
    content: "\f18f";
}
.mbsc-icon-material-broken-image::before {
    content: "\f190";
}
.mbsc-icon-material-brush::before {
    content: "\f191";
}
.mbsc-icon-material-bug-report::before {
    content: "\f192";
}
.mbsc-icon-material-build::before {
    content: "\f193";
}
.mbsc-icon-material-business-center::before {
    content: "\f194";
}
.mbsc-icon-material-business::before {
    content: "\f195";
}
.mbsc-icon-material-cached::before {
    content: "\f19a";
}
.mbsc-icon-material-cake::before {
    content: "\f196";
}
.mbsc-icon-material-call-end::before {
    content: "\f197";
}
.mbsc-icon-material-call-made::before {
    content: "\f198";
}
.mbsc-icon-material-call-merge::before {
    content: "\f199";
}
.mbsc-icon-material-call-missed-outgoing::before {
    content: "\f19b";
}
.mbsc-icon-material-call-missed::before {
    content: "\f19c";
}
.mbsc-icon-material-call-received::before {
    content: "\f19d";
}
.mbsc-icon-material-call-split::before {
    content: "\f19e";
}
.mbsc-icon-material-call::before {
    content: "\f19f";
}
.mbsc-icon-material-camera-alt::before {
    content: "\f1a0";
}
.mbsc-icon-material-camera-enhance::before {
    content: "\f1a1";
}
.mbsc-icon-material-camera-front::before {
    content: "\f1a2";
}
.mbsc-icon-material-camera-rear::before {
    content: "\f1a3";
}
.mbsc-icon-material-camera-roll::before {
    content: "\f1a4";
}
.mbsc-icon-material-camera::before {
    content: "\f1a5";
}
.mbsc-icon-material-cancel::before {
    content: "\f1a6";
}
.mbsc-icon-material-card-giftcard::before {
    content: "\f1a7";
}
.mbsc-icon-material-card-membership::before {
    content: "\f1a9";
}
.mbsc-icon-material-card-travel::before {
    content: "\f1a8";
}
.mbsc-icon-material-casino::before {
    content: "\f1aa";
}
.mbsc-icon-material-cast-connected::before {
    content: "\f1ab";
}
.mbsc-icon-material-cast::before {
    content: "\f1ac";
}
.mbsc-icon-material-center-focus-strong::before {
    content: "\f1ad";
}
.mbsc-icon-material-center-focus-weak::before {
    content: "\f1ae";
}
.mbsc-icon-material-change-history::before {
    content: "\f1af";
}
.mbsc-icon-material-chat-bubble-outline::before {
    content: "\f1b0";
}
.mbsc-icon-material-chat-bubble::before {
    content: "\f1b1";
}
.mbsc-icon-material-chat::before {
    content: "\f1b2";
}
.mbsc-icon-material-check-box-outline-blank::before {
    content: "\f1b3";
}
.mbsc-icon-material-check-box::before {
    content: "\f1b4";
}
.mbsc-icon-material-check-circle::before {
    content: "\f1b5";
}
.mbsc-icon-material-check::before {
    content: "\f1b6";
}
.mbsc-icon-material-chevron-left::before {
    content: "\f1b7";
}
.mbsc-icon-material-chevron-right::before {
    content: "\f1b8";
}
.mbsc-icon-material-child-care::before {
    content: "\f1b9";
}
.mbsc-icon-material-child-friendly::before {
    content: "\f1ba";
}
.mbsc-icon-material-class::before {
    content: "\f1bb";
}
.mbsc-icon-material-clear-all::before {
    content: "\f1bc";
}
.mbsc-icon-material-clear::before {
    content: "\f1bd";
}
.mbsc-icon-material-close::before {
    content: "\f1be";
}
.mbsc-icon-material-closed-caption::before {
    content: "\f1bf";
}
.mbsc-icon-material-cloud-circle::before {
    content: "\f1c0";
}
.mbsc-icon-material-cloud-done::before {
    content: "\f1c1";
}
.mbsc-icon-material-cloud-download::before {
    content: "\f1c2";
}
.mbsc-icon-material-cloud-off::before {
    content: "\f1c3";
}
.mbsc-icon-material-cloud-queue::before {
    content: "\f1c4";
}
.mbsc-icon-material-cloud-upload::before {
    content: "\f1c5";
}
.mbsc-icon-material-cloud::before {
    content: "\f1c6";
}
.mbsc-icon-material-code::before {
    content: "\f1c7";
}
.mbsc-icon-material-collections-bookmark::before {
    content: "\f1c8";
}
.mbsc-icon-material-collections::before {
    content: "\f1c9";
}
.mbsc-icon-material-color-lens::before {
    content: "\f1ca";
}
.mbsc-icon-material-colorize::before {
    content: "\f1cb";
}
.mbsc-icon-material-comment::before {
    content: "\f1cc";
}
.mbsc-icon-material-compare-arrows::before {
    content: "\f1cd";
}
.mbsc-icon-material-compare::before {
    content: "\f1ce";
}
.mbsc-icon-material-computer::before {
    content: "\f1cf";
}
.mbsc-icon-material-contact-mail::before {
    content: "\f1d0";
}
.mbsc-icon-material-contact-phone::before {
    content: "\f1d1";
}
.mbsc-icon-material-contacts::before {
    content: "\f1d2";
}
.mbsc-icon-material-content-copy::before {
    content: "\f1d3";
}
.mbsc-icon-material-content-cut::before {
    content: "\f1d4";
}
.mbsc-icon-material-content-paste::before {
    content: "\f1d5";
}
.mbsc-icon-material-control-point-duplicate::before {
    content: "\f1d6";
}
.mbsc-icon-material-control-point::before {
    content: "\f1d7";
}
.mbsc-icon-material-copyright::before {
    content: "\f1d8";
}
.mbsc-icon-material-create-new-folder::before {
    content: "\f1d9";
}
.mbsc-icon-material-create::before {
    content: "\f1da";
}
.mbsc-icon-material-credit-card::before {
    content: "\f1db";
}
.mbsc-icon-material-crop-16-9::before {
    content: "\f1dc";
}
.mbsc-icon-material-crop-3-2::before {
    content: "\f1dd";
}
.mbsc-icon-material-crop-5-4::before {
    content: "\f1de";
}
.mbsc-icon-material-crop-7-5::before {
    content: "\f1df";
}
.mbsc-icon-material-crop-din::before {
    content: "\f1e0";
}
.mbsc-icon-material-crop-free::before {
    content: "\f1e1";
}
.mbsc-icon-material-crop-landscape::before {
    content: "\f1e2";
}
.mbsc-icon-material-crop-original::before {
    content: "\f1e3";
}
.mbsc-icon-material-crop-portrait::before {
    content: "\f1e4";
}
.mbsc-icon-material-crop-rotate::before {
    content: "\f1e5";
}
.mbsc-icon-material-crop-square::before {
    content: "\f1e6";
}
.mbsc-icon-material-crop::before {
    content: "\f1e7";
}
.mbsc-icon-material-d-rotation::before {
    content: "\f1e8";
}
.mbsc-icon-material-dashboard::before {
    content: "\f1e9";
}
.mbsc-icon-material-data-usage::before {
    content: "\f1ea";
}
.mbsc-icon-material-date-range::before {
    content: "\f1eb";
}
.mbsc-icon-material-dehaze::before {
    content: "\f1ec";
}
.mbsc-icon-material-delete::before {
    content: "\f1ed";
}
.mbsc-icon-material-description::before {
    content: "\f1ee";
}
.mbsc-icon-material-desktop-mac::before {
    content: "\f1ef";
}
.mbsc-icon-material-desktop-windows::before {
    content: "\f1f0";
}
.mbsc-icon-material-details::before {
    content: "\f1f1";
}
.mbsc-icon-material-developer-board::before {
    content: "\f1f2";
}
.mbsc-icon-material-developer-mode::before {
    content: "\f1f3";
}
.mbsc-icon-material-device-hub::before {
    content: "\f1f4";
}
.mbsc-icon-material-devices-other::before {
    content: "\f1f5";
}
.mbsc-icon-material-devices::before {
    content: "\f1f6";
}
.mbsc-icon-material-dialer-sip::before {
    content: "\f1f7";
}
.mbsc-icon-material-dialpad::before {
    content: "\f1f8";
}
.mbsc-icon-material-directions-bike::before {
    content: "\f1f9";
}
.mbsc-icon-material-directions-boat::before {
    content: "\f1fa";
}
.mbsc-icon-material-directions-bus::before {
    content: "\f1fb";
}
.mbsc-icon-material-directions-car::before {
    content: "\f1fc";
}
.mbsc-icon-material-directions-ferry::before {
    content: "\f1fd";
}
.mbsc-icon-material-directions-railway::before {
    content: "\f1fe";
}
.mbsc-icon-material-directions-run::before {
    content: "\f1ff";
}
.mbsc-icon-material-directions-subway::before {
    content: "\f200";
}
.mbsc-icon-material-directions-train::before {
    content: "\f201";
}
.mbsc-icon-material-directions-transit::before {
    content: "\f202";
}
.mbsc-icon-material-directions-walk::before {
    content: "\f203";
}
.mbsc-icon-material-directions::before {
    content: "\f205";
}
.mbsc-icon-material-disc-full::before {
    content: "\f204";
}
.mbsc-icon-material-dnd-forwardslash::before {
    content: "\f206";
}
.mbsc-icon-material-dnd-on::before {
    content: "\f208";
}
.mbsc-icon-material-dns::before {
    content: "\f207";
}
.mbsc-icon-material-do-not-disturb::before {
    content: "\f209";
}
.mbsc-icon-material-dock::before {
    content: "\f20a";
}
.mbsc-icon-material-domain::before {
    content: "\f20b";
}
.mbsc-icon-material-done-all::before {
    content: "\f20c";
}
.mbsc-icon-material-done::before {
    content: "\f20d";
}
.mbsc-icon-material-donut-large::before {
    content: "\f20e";
}
.mbsc-icon-material-donut-small::before {
    content: "\f20f";
}
.mbsc-icon-material-drafts::before {
    content: "\f211";
}
.mbsc-icon-material-drag-handle::before {
    content: "\f210";
}
.mbsc-icon-material-drive-eta::before {
    content: "\f212";
}
.mbsc-icon-material-dvr::before {
    content: "\f215";
}
.mbsc-icon-material-edit-location::before {
    content: "\f213";
}
.mbsc-icon-material-edit::before {
    content: "\f214";
}
.mbsc-icon-material-eject::before {
    content: "\f216";
}
.mbsc-icon-material-email::before {
    content: "\f218";
}
.mbsc-icon-material-equalizer::before {
    content: "\f217";
}
.mbsc-icon-material-error::before {
    content: "\f219";
}
.mbsc-icon-material-event-available::before {
    content: "\f21a";
}
.mbsc-icon-material-event-busy::before {
    content: "\f21b";
}
.mbsc-icon-material-event-note::before {
    content: "\f21c";
}
.mbsc-icon-material-event::before {
    content: "\f21d";
}
.mbsc-icon-material-exit-to-app::before {
    content: "\f21e";
}
.mbsc-icon-material-expand-less::before {
    content: "\f21f";
}
.mbsc-icon-material-expand-more::before {
    content: "\f221";
}
.mbsc-icon-material-explicit::before {
    content: "\f220";
}
.mbsc-icon-material-explore::before {
    content: "\f222";
}
.mbsc-icon-material-exposure-minus-1::before {
    content: "\f223";
}
.mbsc-icon-material-exposure-minus-2::before {
    content: "\f224";
}
.mbsc-icon-material-exposure-plus-1::before {
    content: "\f225";
}
.mbsc-icon-material-exposure-plus-2::before {
    content: "\f226";
}
.mbsc-icon-material-exposure-zero::before {
    content: "\f227";
}
.mbsc-icon-material-exposure::before {
    content: "\f228";
}
.mbsc-icon-material-extension::before {
    content: "\f229";
}
.mbsc-icon-material-face-unlock::before {
    content: "\f22a";
}
.mbsc-icon-material-face::before {
    content: "\f22b";
}
.mbsc-icon-material-fast-forward::before {
    content: "\f22c";
}
.mbsc-icon-material-fast-rewind::before {
    content: "\f22d";
}
.mbsc-icon-material-favorite-outline::before {
    content: "\f22e";
}
.mbsc-icon-material-favorite::before {
    content: "\f22f";
}
.mbsc-icon-material-feedback::before {
    content: "\f231";
}
.mbsc-icon-material-fiber-manual-record::before {
    content: "\f230";
}
.mbsc-icon-material-fiber-new::before {
    content: "\f232";
}
.mbsc-icon-material-fiber-pin::before {
    content: "\f233";
}
.mbsc-icon-material-fiber-smart-record::before {
    content: "\f235";
}
.mbsc-icon-material-file-download::before {
    content: "\f234";
}
.mbsc-icon-material-file-upload::before {
    content: "\f236";
}
.mbsc-icon-material-film::before {
    content: "\f237";
}
.mbsc-icon-material-filter-1::before {
    content: "\f238";
}
.mbsc-icon-material-filter-2::before {
    content: "\f239";
}
.mbsc-icon-material-filter-3::before {
    content: "\f23a";
}
.mbsc-icon-material-filter-4::before {
    content: "\f23c";
}
.mbsc-icon-material-filter-5::before {
    content: "\f23b";
}
.mbsc-icon-material-filter-6::before {
    content: "\f23d";
}
.mbsc-icon-material-filter-7::before {
    content: "\f23e";
}
.mbsc-icon-material-filter-8::before {
    content: "\f23f";
}
.mbsc-icon-material-filter-9-plus::before {
    content: "\f240";
}
.mbsc-icon-material-filter-9::before {
    content: "\f241";
}
.mbsc-icon-material-filter-b-and-w::before {
    content: "\f242";
}
.mbsc-icon-material-filter-center-focus::before {
    content: "\f243";
}
.mbsc-icon-material-filter-drama::before {
    content: "\f245";
}
.mbsc-icon-material-filter-frames::before {
    content: "\f244";
}
.mbsc-icon-material-filter-hdr::before {
    content: "\f246";
}
.mbsc-icon-material-filter-list::before {
    content: "\f247";
}
.mbsc-icon-material-filter-none::before {
    content: "\f248";
}
.mbsc-icon-material-filter-tilt-shift::before {
    content: "\f249";
}
.mbsc-icon-material-filter-vintage::before {
    content: "\f24a";
}
.mbsc-icon-material-filter::before {
    content: "\f24b";
}
.mbsc-icon-material-find-in-page::before {
    content: "\f24c";
}
.mbsc-icon-material-find-replace::before {
    content: "\f24d";
}
.mbsc-icon-material-fingerprint::before {
    content: "\f24e";
}
.mbsc-icon-material-fitness-center::before {
    content: "\f24f";
}
.mbsc-icon-material-flag::before {
    content: "\f250";
}
.mbsc-icon-material-flare::before {
    content: "\f251";
}
.mbsc-icon-material-flash-auto::before {
    content: "\f252";
}
.mbsc-icon-material-flash-off::before {
    content: "\f253";
}
.mbsc-icon-material-flash-on::before {
    content: "\f254";
}
.mbsc-icon-material-flight-land::before {
    content: "\f255";
}
.mbsc-icon-material-flight-takeoff::before {
    content: "\f256";
}
.mbsc-icon-material-flight::before {
    content: "\f257";
}
.mbsc-icon-material-flip-to-back::before {
    content: "\f258";
}
.mbsc-icon-material-flip-to-front::before {
    content: "\f259";
}
.mbsc-icon-material-flip::before {
    content: "\f25a";
}
.mbsc-icon-material-folder-open::before {
    content: "\f25b";
}
.mbsc-icon-material-folder-shared::before {
    content: "\f25c";
}
.mbsc-icon-material-folder-special::before {
    content: "\f25d";
}
.mbsc-icon-material-folder::before {
    content: "\f25e";
}
.mbsc-icon-material-font-download::before {
    content: "\f25f";
}
.mbsc-icon-material-format-align-center::before {
    content: "\f260";
}
.mbsc-icon-material-format-align-justify::before {
    content: "\f261";
}
.mbsc-icon-material-format-align-left::before {
    content: "\f262";
}
.mbsc-icon-material-format-align-right::before {
    content: "\f263";
}
.mbsc-icon-material-format-bold::before {
    content: "\f264";
}
.mbsc-icon-material-format-clear::before {
    content: "\f265";
}
.mbsc-icon-material-format-color-fill::before {
    content: "\f266";
}
.mbsc-icon-material-format-color-reset::before {
    content: "\f267";
}
.mbsc-icon-material-format-color-text::before {
    content: "\f269";
}
.mbsc-icon-material-format-indent-decrease::before {
    content: "\f268";
}
.mbsc-icon-material-format-indent-increase::before {
    content: "\f26a";
}
.mbsc-icon-material-format-ital::before {
    content: "\f26b";
}
.mbsc-icon-material-format-line-spacing::before {
    content: "\f26c";
}
.mbsc-icon-material-format-list-bulleted::before {
    content: "\f26d";
}
.mbsc-icon-material-format-list-numbered::before {
    content: "\f26e";
}
.mbsc-icon-material-format-paint::before {
    content: "\f26f";
}
.mbsc-icon-material-format-quote::before {
    content: "\f271";
}
.mbsc-icon-material-format-shapes::before {
    content: "\f270";
}
.mbsc-icon-material-format-size::before {
    content: "\f272";
}
.mbsc-icon-material-format-strikethrough::before {
    content: "\f273";
}
.mbsc-icon-material-format-textdirection-l-to-r::before {
    content: "\f274";
}
.mbsc-icon-material-format-textdirection-r-to-l::before {
    content: "\f275";
}
.mbsc-icon-material-format-underline::before {
    content: "\f276";
}
.mbsc-icon-material-forum::before {
    content: "\f277";
}
.mbsc-icon-material-forward-10::before {
    content: "\f278";
}
.mbsc-icon-material-forward-30::before {
    content: "\f279";
}
.mbsc-icon-material-forward-5::before {
    content: "\f27a";
}
.mbsc-icon-material-forward::before {
    content: "\f27b";
}
.mbsc-icon-material-free-breakfast::before {
    content: "\f27c";
}
.mbsc-icon-material-fullscreen-exit::before {
    content: "\f27d";
}
.mbsc-icon-material-fullscreen::before {
    content: "\f27e";
}
.mbsc-icon-material-functions::before {
    content: "\f27f";
}
.mbsc-icon-material-gamepad::before {
    content: "\f280";
}
.mbsc-icon-material-games::before {
    content: "\f281";
}
.mbsc-icon-material-gavel::before {
    content: "\f282";
}
.mbsc-icon-material-gesture::before {
    content: "\f283";
}
.mbsc-icon-material-get-app::before {
    content: "\f284";
}
.mbsc-icon-material-gif::before {
    content: "\f285";
}
.mbsc-icon-material-golf-course::before {
    content: "\f286";
}
.mbsc-icon-material-gps-fixed::before {
    content: "\f287";
}
.mbsc-icon-material-gps-not-fixed::before {
    content: "\f288";
}
.mbsc-icon-material-gps-off::before {
    content: "\f289";
}
.mbsc-icon-material-grade::before {
    content: "\f28a";
}
.mbsc-icon-material-gradient::before {
    content: "\f28b";
}
.mbsc-icon-material-grain::before {
    content: "\f28c";
}
.mbsc-icon-material-graphic-eq::before {
    content: "\f28d";
}
.mbsc-icon-material-grid-off::before {
    content: "\f28e";
}
.mbsc-icon-material-grid-on::before {
    content: "\f28f";
}
.mbsc-icon-material-group-add::before {
    content: "\f290";
}
.mbsc-icon-material-group-work::before {
    content: "\f291";
}
.mbsc-icon-material-group::before {
    content: "\f292";
}
.mbsc-icon-material-hd::before {
    content: "\f293";
}
.mbsc-icon-material-hdr-off::before {
    content: "\f294";
}
.mbsc-icon-material-hdr-on::before {
    content: "\f295";
}
.mbsc-icon-material-hdr-strong::before {
    content: "\f296";
}
.mbsc-icon-material-hdr-weak::before {
    content: "\f297";
}
.mbsc-icon-material-headset-m::before {
    content: "\f298";
}
.mbsc-icon-material-headset::before {
    content: "\f299";
}
.mbsc-icon-material-healing::before {
    content: "\f29a";
}
.mbsc-icon-material-hearing::before {
    content: "\f29b";
}
.mbsc-icon-material-help-outline::before {
    content: "\f29c";
}
.mbsc-icon-material-help::before {
    content: "\f29d";
}
.mbsc-icon-material-high-quality::before {
    content: "\f29e";
}
.mbsc-icon-material-highlight-remove::before {
    content: "\f29f";
}
.mbsc-icon-material-history::before {
    content: "\f2a0";
}
.mbsc-icon-material-home::before {
    content: "\f2a1";
}
.mbsc-icon-material-hot-tub::before {
    content: "\f2a4";
}
.mbsc-icon-material-hotel::before {
    content: "\f2a2";
}
.mbsc-icon-material-hourglass-empty::before {
    content: "\f2a3";
}
.mbsc-icon-material-hourglass-full::before {
    content: "\f2a5";
}
.mbsc-icon-material-http::before {
    content: "\f2a6";
}
.mbsc-icon-material-https::before {
    content: "\f2a7";
}
.mbsc-icon-material-image-aspect-ratio::before {
    content: "\f2a8";
}
.mbsc-icon-material-image::before {
    content: "\f2a9";
}
.mbsc-icon-material-import-contacts::before {
    content: "\f2aa";
}
.mbsc-icon-material-import-export::before {
    content: "\f2ab";
}
.mbsc-icon-material-important-devices::before {
    content: "\f2ac";
}
.mbsc-icon-material-inbox::before {
    content: "\f2ad";
}
.mbsc-icon-material-indeterminate-check-box::before {
    content: "\f2ae";
}
.mbsc-icon-material-info-outline::before {
    content: "\f2af";
}
.mbsc-icon-material-info::before {
    content: "\f2b0";
}
.mbsc-icon-material-input::before {
    content: "\f2b1";
}
.mbsc-icon-material-insert-chart::before {
    content: "\f2b2";
}
.mbsc-icon-material-insert-comment::before {
    content: "\f2b3";
}
.mbsc-icon-material-insert-drive-file::before {
    content: "\f2b4";
}
.mbsc-icon-material-insert-emoticon::before {
    content: "\f2b5";
}
.mbsc-icon-material-insert-invitation::before {
    content: "\f2b6";
}
.mbsc-icon-material-insert-link::before {
    content: "\f2b7";
}
.mbsc-icon-material-insert-photo::before {
    content: "\f2b9";
}
.mbsc-icon-material-invert-colors-off::before {
    content: "\f2b8";
}
.mbsc-icon-material-invert-colors-on::before {
    content: "\f2ba";
}
.mbsc-icon-material-invert-colors::before {
    content: "\f2bb";
}
.mbsc-icon-material-iso::before {
    content: "\f2bc";
}
.mbsc-icon-material-keyboard-alt::before {
    content: "\f2bd";
}
.mbsc-icon-material-keyboard-arrow-down::before {
    content: "\f2be";
}
.mbsc-icon-material-keyboard-arrow-left::before {
    content: "\f2bf";
}
.mbsc-icon-material-keyboard-arrow-right::before {
    content: "\f2c0";
}
.mbsc-icon-material-keyboard-arrow-up::before {
    content: "\f2c1";
}
.mbsc-icon-material-keyboard-backspace::before {
    content: "\f2c2";
}
.mbsc-icon-material-keyboard-capslock::before {
    content: "\f2c3";
}
.mbsc-icon-material-keyboard-control::before {
    content: "\f2c4";
}
.mbsc-icon-material-keyboard-hide::before {
    content: "\f2c5";
}
.mbsc-icon-material-keyboard-return::before {
    content: "\f2c6";
}
.mbsc-icon-material-keyboard-tab::before {
    content: "\f2c7";
}
.mbsc-icon-material-keyboard-voice::before {
    content: "\f2c8";
}
.mbsc-icon-material-keyboard::before {
    content: "\f2c9";
}
.mbsc-icon-material-kitchen::before {
    content: "\f2ca";
}
.mbsc-icon-material-label-outline::before {
    content: "\f2cb";
}
.mbsc-icon-material-label::before {
    content: "\f2cc";
}
.mbsc-icon-material-landscape::before {
    content: "\f2cd";
}
.mbsc-icon-material-language::before {
    content: "\f2ce";
}
.mbsc-icon-material-laptop-chromebook::before {
    content: "\f2cf";
}
.mbsc-icon-material-laptop-mac::before {
    content: "\f2d0";
}
.mbsc-icon-material-laptop-windows::before {
    content: "\f2d1";
}
.mbsc-icon-material-laptop::before {
    content: "\f2d2";
}
.mbsc-icon-material-launch::before {
    content: "\f2d3";
}
.mbsc-icon-material-layers-clear::before {
    content: "\f2d5";
}
.mbsc-icon-material-layers::before {
    content: "\f2d4";
}
.mbsc-icon-material-leak-add::before {
    content: "\f2d6";
}
.mbsc-icon-material-leak-remove::before {
    content: "\f2d7";
}
.mbsc-icon-material-lens::before {
    content: "\f2d8";
}
.mbsc-icon-material-lightbulb-outline::before {
    content: "\f2d9";
}
.mbsc-icon-material-line-style::before {
    content: "\f2da";
}
.mbsc-icon-material-line-weight::before {
    content: "\f2db";
}
.mbsc-icon-material-linear-scale::before {
    content: "\f2dd";
}
.mbsc-icon-material-link::before {
    content: "\f2dc";
}
.mbsc-icon-material-linked-camera::before {
    content: "\f2de";
}
.mbsc-icon-material-list::before {
    content: "\f2df";
}
.mbsc-icon-material-live-help::before {
    content: "\f2e0";
}
.mbsc-icon-material-local-activity::before {
    content: "\f2e1";
}
.mbsc-icon-material-local-airport::before {
    content: "\f2e2";
}
.mbsc-icon-material-local-atm::before {
    content: "\f2e3";
}
.mbsc-icon-material-local-attraction::before {
    content: "\f2e5";
}
.mbsc-icon-material-local-bar::before {
    content: "\f2e4";
}
.mbsc-icon-material-local-cafe::before {
    content: "\f2e6";
}
.mbsc-icon-material-local-car-wash::before {
    content: "\f2e7";
}
.mbsc-icon-material-local-convenience-store::before {
    content: "\f2e8";
}
.mbsc-icon-material-local-dining::before {
    content: "\f2e9";
}
.mbsc-icon-material-local-drink::before {
    content: "\f2ea";
}
.mbsc-icon-material-local-florist::before {
    content: "\f2eb";
}
.mbsc-icon-material-local-gas-station::before {
    content: "\f2ed";
}
.mbsc-icon-material-local-grocery-store::before {
    content: "\f2ec";
}
.mbsc-icon-material-local-hospital::before {
    content: "\f2ee";
}
.mbsc-icon-material-local-hotel::before {
    content: "\f2ef";
}
.mbsc-icon-material-local-laundry-service::before {
    content: "\f2f0";
}
.mbsc-icon-material-local-library::before {
    content: "\f2f1";
}
.mbsc-icon-material-local-mall::before {
    content: "\f2f2";
}
.mbsc-icon-material-local-movies::before {
    content: "\f2f3";
}
.mbsc-icon-material-local-offer::before {
    content: "\f2f4";
}
.mbsc-icon-material-local-parking::before {
    content: "\f2f5";
}
.mbsc-icon-material-local-pharmacy::before {
    content: "\f2f6";
}
.mbsc-icon-material-local-phone::before {
    content: "\f2f7";
}
.mbsc-icon-material-local-pizza::before {
    content: "\f2f8";
}
.mbsc-icon-material-local-play::before {
    content: "\f2f9";
}
.mbsc-icon-material-local-post-office::before {
    content: "\f2fa";
}
.mbsc-icon-material-local-print-shop::before {
    content: "\f2fb";
}
.mbsc-icon-material-local-restaurant::before {
    content: "\f2fc";
}
.mbsc-icon-material-local-see::before {
    content: "\f2fd";
}
.mbsc-icon-material-local-shipping::before {
    content: "\f2fe";
}
.mbsc-icon-material-local-taxi::before {
    content: "\f2ff";
}
.mbsc-icon-material-location-city::before {
    content: "\f300";
}
.mbsc-icon-material-location-disabled::before {
    content: "\f301";
}
.mbsc-icon-material-location-history::before {
    content: "\f302";
}
.mbsc-icon-material-location-off::before {
    content: "\f303";
}
.mbsc-icon-material-location-on::before {
    content: "\f304";
}
.mbsc-icon-material-location-searching::before {
    content: "\f305";
}
.mbsc-icon-material-lock-open::before {
    content: "\f306";
}
.mbsc-icon-material-lock-outline::before {
    content: "\f307";
}
.mbsc-icon-material-lock::before {
    content: "\f308";
}
.mbsc-icon-material-looks-3::before {
    content: "\f309";
}
.mbsc-icon-material-looks-4::before {
    content: "\f30a";
}
.mbsc-icon-material-looks-5::before {
    content: "\f30b";
}
.mbsc-icon-material-looks-6::before {
    content: "\f30c";
}
.mbsc-icon-material-looks-one::before {
    content: "\f30d";
}
.mbsc-icon-material-looks-two::before {
    content: "\f30e";
}
.mbsc-icon-material-looks::before {
    content: "\f30f";
}
.mbsc-icon-material-loop::before {
    content: "\f310";
}
.mbsc-icon-material-loupe::before {
    content: "\f311";
}
.mbsc-icon-material-loyalty::before {
    content: "\f312";
}
.mbsc-icon-material-mail-outline::before {
    content: "\f313";
}
.mbsc-icon-material-mail::before {
    content: "\f314";
}
.mbsc-icon-material-map::before {
    content: "\f315";
}
.mbsc-icon-material-markunread-mailbox::before {
    content: "\f316";
}
.mbsc-icon-material-markunread::before {
    content: "\f317";
}
.mbsc-icon-material-memory::before {
    content: "\f318";
}
.mbsc-icon-material-menu::before {
    content: "\f319";
}
.mbsc-icon-material-merge-type::before {
    content: "\f31a";
}
.mbsc-icon-material-message::before {
    content: "\f31b";
}
.mbsc-icon-material-messenger::before {
    content: "\f31d";
}
.mbsc-icon-material-mic-none::before {
    content: "\f31c";
}
.mbsc-icon-material-mic-off::before {
    content: "\f31f";
}
.mbsc-icon-material-mic::before {
    content: "\f31e";
}
.mbsc-icon-material-mms::before {
    content: "\f320";
}
.mbsc-icon-material-mnone::before {
    content: "\f321";
}
.mbsc-icon-material-mode-comment::before {
    content: "\f322";
}
.mbsc-icon-material-mode-edit::before {
    content: "\f323";
}
.mbsc-icon-material-moff::before {
    content: "\f325";
}
.mbsc-icon-material-money-off::before {
    content: "\f324";
}
.mbsc-icon-material-monochrome-photos::before {
    content: "\f326";
}
.mbsc-icon-material-mood-bad::before {
    content: "\f327";
}
.mbsc-icon-material-mood::before {
    content: "\f329";
}
.mbsc-icon-material-more-horiz::before {
    content: "\f328";
}
.mbsc-icon-material-more-vert::before {
    content: "\f32a";
}
.mbsc-icon-material-more::before {
    content: "\f32c";
}
.mbsc-icon-material-motorcycle::before {
    content: "\f32b";
}
.mbsc-icon-material-mouse::before {
    content: "\f32d";
}
.mbsc-icon-material-move-to-inbox::before {
    content: "\f32e";
}
.mbsc-icon-material-movie-creation::before {
    content: "\f32f";
}
.mbsc-icon-material-movie-filter::before {
    content: "\f330";
}
.mbsc-icon-material-movie::before {
    content: "\f331";
}
.mbsc-icon-material-multitrack-audio::before {
    content: "\f332";
}
.mbsc-icon-material-music-note::before {
    content: "\f333";
}
.mbsc-icon-material-music-video::before {
    content: "\f334";
}
.mbsc-icon-material-my-library-add::before {
    content: "\f335";
}
.mbsc-icon-material-my-library-books::before {
    content: "\f336";
}
.mbsc-icon-material-my-library-mus::before {
    content: "\f337";
}
.mbsc-icon-material-my-location::before {
    content: "\f338";
}
.mbsc-icon-material-nature-people::before {
    content: "\f339";
}
.mbsc-icon-material-nature::before {
    content: "\f33a";
}
.mbsc-icon-material-navigate-before::before {
    content: "\f33b";
}
.mbsc-icon-material-navigate-next::before {
    content: "\f33c";
}
.mbsc-icon-material-navigation::before {
    content: "\f33d";
}
.mbsc-icon-material-near-me::before {
    content: "\f33e";
}
.mbsc-icon-material-network-cell::before {
    content: "\f33f";
}
.mbsc-icon-material-network-locked::before {
    content: "\f340";
}
.mbsc-icon-material-network-wifi::before {
    content: "\f341";
}
.mbsc-icon-material-new-releases::before {
    content: "\f342";
}
.mbsc-icon-material-next-week::before {
    content: "\f343";
}
.mbsc-icon-material-nfc::before {
    content: "\f344";
}
.mbsc-icon-material-no-sim::before {
    content: "\f345";
}
.mbsc-icon-material-not-interested::before {
    content: "\f346";
}
.mbsc-icon-material-note-add::before {
    content: "\f347";
}
.mbsc-icon-material-notifications-none::before {
    content: "\f348";
}
.mbsc-icon-material-notifications-off::before {
    content: "\f349";
}
.mbsc-icon-material-notifications-on::before {
    content: "\f34a";
}
.mbsc-icon-material-notifications-paused::before {
    content: "\f34c";
}
.mbsc-icon-material-notifications::before {
    content: "\f34b";
}
.mbsc-icon-material-now-wallpaper::before {
    content: "\f34d";
}
.mbsc-icon-material-now-widgets::before {
    content: "\f34e";
}
.mbsc-icon-material-offline-pin::before {
    content: "\f350";
}
.mbsc-icon-material-opacity::before {
    content: "\f34f";
}
.mbsc-icon-material-open-in-browser::before {
    content: "\f351";
}
.mbsc-icon-material-open-in-new::before {
    content: "\f352";
}
.mbsc-icon-material-open-with::before {
    content: "\f353";
}
.mbsc-icon-material-pages::before {
    content: "\f354";
}
.mbsc-icon-material-pageview::before {
    content: "\f355";
}
.mbsc-icon-material-palette::before {
    content: "\f356";
}
.mbsc-icon-material-pan-tool::before {
    content: "\f357";
}
.mbsc-icon-material-panorama-fisheye::before {
    content: "\f358";
}
.mbsc-icon-material-panorama-horizontal::before {
    content: "\f359";
}
.mbsc-icon-material-panorama-vertical::before {
    content: "\f35a";
}
.mbsc-icon-material-panorama-wide-angle::before {
    content: "\f35b";
}
.mbsc-icon-material-panorama::before {
    content: "\f35c";
}
.mbsc-icon-material-party-mode::before {
    content: "\f35d";
}
.mbsc-icon-material-pause-circle-fill::before {
    content: "\f35e";
}
.mbsc-icon-material-pause-circle-outline::before {
    content: "\f35f";
}
.mbsc-icon-material-pause::before {
    content: "\f360";
}
.mbsc-icon-material-payment::before {
    content: "\f361";
}
.mbsc-icon-material-people-outline::before {
    content: "\f362";
}
.mbsc-icon-material-people::before {
    content: "\f363";
}
.mbsc-icon-material-perm-camera-m::before {
    content: "\f365";
}
.mbsc-icon-material-perm-contact-cal::before {
    content: "\f364";
}
.mbsc-icon-material-perm-data-setting::before {
    content: "\f366";
}
.mbsc-icon-material-perm-device-info::before {
    content: "\f367";
}
.mbsc-icon-material-perm-identity::before {
    content: "\f368";
}
.mbsc-icon-material-perm-media::before {
    content: "\f369";
}
.mbsc-icon-material-perm-phone-msg::before {
    content: "\f36a";
}
.mbsc-icon-material-perm-scan-wifi::before {
    content: "\f36b";
}
.mbsc-icon-material-person-add::before {
    content: "\f36d";
}
.mbsc-icon-material-person-outline::before {
    content: "\f36c";
}
.mbsc-icon-material-person-pin-circle::before {
    content: "\f36e";
}
.mbsc-icon-material-person-pin::before {
    content: "\f36f";
}
.mbsc-icon-material-person::before {
    content: "\f370";
}
.mbsc-icon-material-pets::before {
    content: "\f371";
}
.mbsc-icon-material-phone-android::before {
    content: "\f372";
}
.mbsc-icon-material-phone-bluetooth-speaker::before {
    content: "\f373";
}
.mbsc-icon-material-phone-forwarded::before {
    content: "\f374";
}
.mbsc-icon-material-phone-in-talk::before {
    content: "\f375";
}
.mbsc-icon-material-phone-iphone::before {
    content: "\f376";
}
.mbsc-icon-material-phone-locked::before {
    content: "\f377";
}
.mbsc-icon-material-phone-missed::before {
    content: "\f379";
}
.mbsc-icon-material-phone-paused::before {
    content: "\f378";
}
.mbsc-icon-material-phone::before {
    content: "\f37a";
}
.mbsc-icon-material-phonelink-erase::before {
    content: "\f37b";
}
.mbsc-icon-material-phonelink-lock::before {
    content: "\f37c";
}
.mbsc-icon-material-phonelink-off::before {
    content: "\f37d";
}
.mbsc-icon-material-phonelink-ring::before {
    content: "\f37e";
}
.mbsc-icon-material-phonelink-setup::before {
    content: "\f37f";
}
.mbsc-icon-material-phonelink::before {
    content: "\f381";
}
.mbsc-icon-material-photo-album::before {
    content: "\f380";
}
.mbsc-icon-material-photo-camera::before {
    content: "\f382";
}
.mbsc-icon-material-photo-filter::before {
    content: "\f383";
}
.mbsc-icon-material-photo-library::before {
    content: "\f384";
}
.mbsc-icon-material-photo-size-select-actual::before {
    content: "\f385";
}
.mbsc-icon-material-photo-size-select-large::before {
    content: "\f386";
}
.mbsc-icon-material-photo-size-select-small::before {
    content: "\f387";
}
.mbsc-icon-material-photo::before {
    content: "\f388";
}
.mbsc-icon-material-picture-as-pdf::before {
    content: "\f389";
}
.mbsc-icon-material-picture-in-picture-alt::before {
    content: "\f38a";
}
.mbsc-icon-material-picture-in-picture::before {
    content: "\f38b";
}
.mbsc-icon-material-pin-drop::before {
    content: "\f38c";
}
.mbsc-icon-material-place::before {
    content: "\f38d";
}
.mbsc-icon-material-play-arrow::before {
    content: "\f38e";
}
.mbsc-icon-material-play-circle-fill::before {
    content: "\f38f";
}
.mbsc-icon-material-play-circle-outline::before {
    content: "\f390";
}
.mbsc-icon-material-play-download::before {
    content: "\f391";
}
.mbsc-icon-material-play-for-work::before {
    content: "\f392";
}
.mbsc-icon-material-play-install::before {
    content: "\f393";
}
.mbsc-icon-material-play-shopping-bag::before {
    content: "\f395";
}
.mbsc-icon-material-playlist-add-check::before {
    content: "\f394";
}
.mbsc-icon-material-playlist-add::before {
    content: "\f396";
}
.mbsc-icon-material-playlist-play::before {
    content: "\f397";
}
.mbsc-icon-material-plus-one::before {
    content: "\f398";
}
.mbsc-icon-material-poll::before {
    content: "\f399";
}
.mbsc-icon-material-polymer::before {
    content: "\f39a";
}
.mbsc-icon-material-portable-wifi-off::before {
    content: "\f39b";
}
.mbsc-icon-material-portrait::before {
    content: "\f39c";
}
.mbsc-icon-material-power-input::before {
    content: "\f39d";
}
.mbsc-icon-material-power-settings-new::before {
    content: "\f39e";
}
.mbsc-icon-material-pregnant-woman::before {
    content: "\f3a0";
}
.mbsc-icon-material-present-to-all::before {
    content: "\f39f";
}
.mbsc-icon-material-print::before {
    content: "\f3a1";
}
.mbsc-icon-material-publ::before {
    content: "\f3a2";
}
.mbsc-icon-material-public::before {
    content: "\f3a3";
}
.mbsc-icon-material-publish::before {
    content: "\f3a4";
}
.mbsc-icon-material-query-builder::before {
    content: "\f3a5";
}
.mbsc-icon-material-question-answer::before {
    content: "\f3a6";
}
.mbsc-icon-material-queue-mus::before {
    content: "\f3a7";
}
.mbsc-icon-material-queue-play-next::before {
    content: "\f3a8";
}
.mbsc-icon-material-queue::before {
    content: "\f3a9";
}
.mbsc-icon-material-quick-contacts-dialer::before {
    content: "\f3aa";
}
.mbsc-icon-material-quick-contacts-mail::before {
    content: "\f3ab";
}
.mbsc-icon-material-radio-button-off::before {
    content: "\f3ac";
}
.mbsc-icon-material-radio-button-on::before {
    content: "\f3ad";
}
.mbsc-icon-material-radio::before {
    content: "\f3ae";
}
.mbsc-icon-material-rate-review::before {
    content: "\f3af";
}
.mbsc-icon-material-receipt::before {
    content: "\f3b0";
}
.mbsc-icon-material-recent-actors::before {
    content: "\f3b1";
}
.mbsc-icon-material-record-voice-over::before {
    content: "\f3b2";
}
.mbsc-icon-material-redeem::before {
    content: "\f3b3";
}
.mbsc-icon-material-redo::before {
    content: "\f3b4";
}
.mbsc-icon-material-refresh::before {
    content: "\f3b5";
}
.mbsc-icon-material-remove-circle-outline::before {
    content: "\f3b6";
}
.mbsc-icon-material-remove-circle::before {
    content: "\f3b7";
}
.mbsc-icon-material-remove-from-queue::before {
    content: "\f3b8";
}
.mbsc-icon-material-remove-red-eye::before {
    content: "\f3b9";
}
.mbsc-icon-material-remove::before {
    content: "\f3bb";
}
.mbsc-icon-material-reorder::before {
    content: "\f3ba";
}
.mbsc-icon-material-repeat-one::before {
    content: "\f3bc";
}
.mbsc-icon-material-repeat::before {
    content: "\f3bd";
}
.mbsc-icon-material-replay-10::before {
    content: "\f3be";
}
.mbsc-icon-material-replay-30::before {
    content: "\f3bf";
}
.mbsc-icon-material-replay-5::before {
    content: "\f3c0";
}
.mbsc-icon-material-replay::before {
    content: "\f3c1";
}
.mbsc-icon-material-reply-all::before {
    content: "\f3c2";
}
.mbsc-icon-material-reply::before {
    content: "\f3c4";
}
.mbsc-icon-material-report-problem::before {
    content: "\f3c3";
}
.mbsc-icon-material-report::before {
    content: "\f3c5";
}
.mbsc-icon-material-restaurant-menu::before {
    content: "\f3c6";
}
.mbsc-icon-material-restore::before {
    content: "\f3c7";
}
.mbsc-icon-material-ring-volume::before {
    content: "\f3c8";
}
.mbsc-icon-material-room-service::before {
    content: "\f3c9";
}
.mbsc-icon-material-room::before {
    content: "\f3ca";
}
.mbsc-icon-material-rotate-90-degrees-ccw::before {
    content: "\f3cb";
}
.mbsc-icon-material-rotate-left::before {
    content: "\f3cc";
}
.mbsc-icon-material-rotate-right::before {
    content: "\f3cd";
}
.mbsc-icon-material-rounded-corner::before {
    content: "\f3ce";
}
.mbsc-icon-material-router::before {
    content: "\f3cf";
}
.mbsc-icon-material-rowing::before {
    content: "\f3d0";
}
.mbsc-icon-material-satellite::before {
    content: "\f3d1";
}
.mbsc-icon-material-save::before {
    content: "\f3d2";
}
.mbsc-icon-material-scanner::before {
    content: "\f3d3";
}
.mbsc-icon-material-schedule::before {
    content: "\f3d4";
}
.mbsc-icon-material-school::before {
    content: "\f3d5";
}
.mbsc-icon-material-screen-lock-landscape::before {
    content: "\f3d6";
}
.mbsc-icon-material-screen-lock-portrait::before {
    content: "\f3d7";
}
.mbsc-icon-material-screen-lock-rotation::before {
    content: "\f3d8";
}
.mbsc-icon-material-screen-rotation::before {
    content: "\f3d9";
}
.mbsc-icon-material-screen-share::before {
    content: "\f3da";
}
.mbsc-icon-material-sd-card::before {
    content: "\f3db";
}
.mbsc-icon-material-search::before {
    content: "\f3dc";
}
.mbsc-icon-material-security::before {
    content: "\f3dd";
}
.mbsc-icon-material-select-all::before {
    content: "\f3de";
}
.mbsc-icon-material-send::before {
    content: "\f3df";
}
.mbsc-icon-material-settings-applications::before {
    content: "\f3e0";
}
.mbsc-icon-material-settings-backup-restore::before {
    content: "\f3e1";
}
.mbsc-icon-material-settings-bluetooth::before {
    content: "\f3e2";
}
.mbsc-icon-material-settings-brightness::before {
    content: "\f3e3";
}
.mbsc-icon-material-settings-cell::before {
    content: "\f3e4";
}
.mbsc-icon-material-settings-display::before {
    content: "\f3e5";
}
.mbsc-icon-material-settings-ethernet::before {
    content: "\f3e6";
}
.mbsc-icon-material-settings-input-antenna::before {
    content: "\f3e7";
}
.mbsc-icon-material-settings-input-component::before {
    content: "\f3e8";
}
.mbsc-icon-material-settings-input-composite::before {
    content: "\f3e9";
}
.mbsc-icon-material-settings-input-hdmi::before {
    content: "\f3ea";
}
.mbsc-icon-material-settings-input-svideo::before {
    content: "\f3ed";
}
.mbsc-icon-material-settings-overscan::before {
    content: "\f3ec";
}
.mbsc-icon-material-settings-phone::before {
    content: "\f3eb";
}
.mbsc-icon-material-settings-power::before {
    content: "\f3ee";
}
.mbsc-icon-material-settings-remote::before {
    content: "\f3ef";
}
.mbsc-icon-material-settings-system-daydream::before {
    content: "\f3f0";
}
.mbsc-icon-material-settings-voice::before {
    content: "\f3f1";
}
.mbsc-icon-material-settings::before {
    content: "\f3f2";
}
.mbsc-icon-material-share::before {
    content: "\f3f3";
}
.mbsc-icon-material-shop-two::before {
    content: "\f3f4";
}
.mbsc-icon-material-shop::before {
    content: "\f3f5";
}
.mbsc-icon-material-shopping-basket::before {
    content: "\f3f6";
}
.mbsc-icon-material-shopping-cart::before {
    content: "\f3f8";
}
.mbsc-icon-material-short-text::before {
    content: "\f3f7";
}
.mbsc-icon-material-shuffle::before {
    content: "\f3f9";
}
.mbsc-icon-material-signal-cellular-4-bar::before {
    content: "\f3fa";
}
.mbsc-icon-material-signal-cellular-connected-no-internet-4-bar::before {
    content: "\f3fb";
}
.mbsc-icon-material-signal-cellular-no-sim::before {
    content: "\f3ff";
}
.mbsc-icon-material-signal-cellular-null::before {
    content: "\f3fc";
}
.mbsc-icon-material-signal-cellular-off::before {
    content: "\f3fd";
}
.mbsc-icon-material-signal-wifi-4-bar-lock::before {
    content: "\f3fe";
}
.mbsc-icon-material-signal-wifi-4-bar::before {
    content: "\f400";
}
.mbsc-icon-material-signal-wifi-off::before {
    content: "\f401";
}
.mbsc-icon-material-signal-wifi-statusbar-connected-no-internet::before {
    content: "\f402";
}
.mbsc-icon-material-signal-wifi-statusbar-not-connected::before {
    content: "\f403";
}
.mbsc-icon-material-signal-wifi-statusbar-null::before {
    content: "\f404";
}
.mbsc-icon-material-sim-card-alert::before {
    content: "\f405";
}
.mbsc-icon-material-sim-card::before {
    content: "\f406";
}
.mbsc-icon-material-skip-next::before {
    content: "\f407";
}
.mbsc-icon-material-skip-previous::before {
    content: "\f408";
}
.mbsc-icon-material-slideshow::before {
    content: "\f409";
}
.mbsc-icon-material-slow-motion-video::before {
    content: "\f40a";
}
.mbsc-icon-material-smartphone::before {
    content: "\f40b";
}
.mbsc-icon-material-sms-failed::before {
    content: "\f40c";
}
.mbsc-icon-material-sms::before {
    content: "\f40d";
}
.mbsc-icon-material-snooze::before {
    content: "\f40e";
}
.mbsc-icon-material-sort::before {
    content: "\f40f";
}
.mbsc-icon-material-spa::before {
    content: "\f413";
}
.mbsc-icon-material-space-bar::before {
    content: "\f411";
}
.mbsc-icon-material-speaker-group::before {
    content: "\f410";
}
.mbsc-icon-material-speaker-notes::before {
    content: "\f412";
}
.mbsc-icon-material-speaker-phone::before {
    content: "\f414";
}
.mbsc-icon-material-speaker::before {
    content: "\f415";
}
.mbsc-icon-material-spellcheck::before {
    content: "\f416";
}
.mbsc-icon-material-star-border::before {
    content: "\f417";
}
.mbsc-icon-material-star-half::before {
    content: "\f418";
}
.mbsc-icon-material-star-outline::before {
    content: "\f41a";
}
.mbsc-icon-material-star-rate::before {
    content: "\f419";
}
.mbsc-icon-material-star::before {
    content: "\f41b";
}
.mbsc-icon-material-stars::before {
    content: "\f41c";
}
.mbsc-icon-material-stay-current-landscape::before {
    content: "\f41d";
}
.mbsc-icon-material-stay-current-portrait::before {
    content: "\f41e";
}
.mbsc-icon-material-stay-primary-landscape::before {
    content: "\f41f";
}
.mbsc-icon-material-stay-primary-portrait::before {
    content: "\f422";
}
.mbsc-icon-material-stop-screen-share::before {
    content: "\f420";
}
.mbsc-icon-material-stop::before {
    content: "\f421";
}
.mbsc-icon-material-storage::before {
    content: "\f423";
}
.mbsc-icon-material-store-mall-directory::before {
    content: "\f424";
}
.mbsc-icon-material-store::before {
    content: "\f425";
}
.mbsc-icon-material-straighten::before {
    content: "\f426";
}
.mbsc-icon-material-strikethrough-s::before {
    content: "\f427";
}
.mbsc-icon-material-style::before {
    content: "\f428";
}
.mbsc-icon-material-subdirectory-arrow-left::before {
    content: "\f429";
}
.mbsc-icon-material-subdirectory-arrow-right::before {
    content: "\f42a";
}
.mbsc-icon-material-subject::before {
    content: "\f42b";
}
.mbsc-icon-material-subscriptions::before {
    content: "\f42c";
}
.mbsc-icon-material-subtitles::before {
    content: "\f42d";
}
.mbsc-icon-material-supervisor-account::before {
    content: "\f42e";
}
.mbsc-icon-material-surround-sound::before {
    content: "\f42f";
}
.mbsc-icon-material-swap-calls::before {
    content: "\f430";
}
.mbsc-icon-material-swap-horiz::before {
    content: "\f431";
}
.mbsc-icon-material-swap-vert-circle::before {
    content: "\f432";
}
.mbsc-icon-material-swap-vert::before {
    content: "\f433";
}
.mbsc-icon-material-switch-camera::before {
    content: "\f434";
}
.mbsc-icon-material-switch-video::before {
    content: "\f435";
}
.mbsc-icon-material-sync-disabled::before {
    content: "\f436";
}
.mbsc-icon-material-sync-problem::before {
    content: "\f437";
}
.mbsc-icon-material-sync::before {
    content: "\f438";
}
.mbsc-icon-material-system-update-tv::before {
    content: "\f439";
}
.mbsc-icon-material-system-update::before {
    content: "\f43a";
}
.mbsc-icon-material-tab-unselected::before {
    content: "\f43b";
}
.mbsc-icon-material-tab::before {
    content: "\f43c";
}
.mbsc-icon-material-tablet-android::before {
    content: "\f43d";
}
.mbsc-icon-material-tablet-mac::before {
    content: "\f43e";
}
.mbsc-icon-material-tablet::before {
    content: "\f43f";
}
.mbsc-icon-material-tag-faces::before {
    content: "\f440";
}
.mbsc-icon-material-tap-and-play::before {
    content: "\f441";
}
.mbsc-icon-material-terrain::before {
    content: "\f442";
}
.mbsc-icon-material-text-fields::before {
    content: "\f443";
}
.mbsc-icon-material-text-format::before {
    content: "\f444";
}
.mbsc-icon-material-textsms::before {
    content: "\f445";
}
.mbsc-icon-material-texture::before {
    content: "\f446";
}
.mbsc-icon-material-theaters::before {
    content: "\f447";
}
.mbsc-icon-material-thumb-down::before {
    content: "\f448";
}
.mbsc-icon-material-thumb-up::before {
    content: "\f44b";
}
.mbsc-icon-material-thumbs-up-down::before {
    content: "\f449";
}
.mbsc-icon-material-time-to-leave::before {
    content: "\f44a";
}
.mbsc-icon-material-timelapse::before {
    content: "\f44c";
}
.mbsc-icon-material-timeline::before {
    content: "\f44d";
}
.mbsc-icon-material-timer-10::before {
    content: "\f44e";
}
.mbsc-icon-material-timer-3::before {
    content: "\f44f";
}
.mbsc-icon-material-timer-auto::before {
    content: "\f450";
}
.mbsc-icon-material-timer-off::before {
    content: "\f451";
}
.mbsc-icon-material-timer::before {
    content: "\f452";
}
.mbsc-icon-material-toc::before {
    content: "\f453";
}
.mbsc-icon-material-today::before {
    content: "\f454";
}
.mbsc-icon-material-toll::before {
    content: "\f455";
}
.mbsc-icon-material-tonality::before {
    content: "\f456";
}
.mbsc-icon-material-touch-app::before {
    content: "\f457";
}
.mbsc-icon-material-toys::before {
    content: "\f458";
}
.mbsc-icon-material-track-changes::before {
    content: "\f459";
}
.mbsc-icon-material-traff::before {
    content: "\f45a";
}
.mbsc-icon-material-traffic::before {
    content: "\f45b";
}
.mbsc-icon-material-transform::before {
    content: "\f45c";
}
.mbsc-icon-material-translate::before {
    content: "\f45d";
}
.mbsc-icon-material-trending-down::before {
    content: "\f45f";
}
.mbsc-icon-material-trending-neutral::before {
    content: "\f45e";
}
.mbsc-icon-material-trending-up::before {
    content: "\f460";
}
.mbsc-icon-material-tune::before {
    content: "\f461";
}
.mbsc-icon-material-turned-in-not::before {
    content: "\f462";
}
.mbsc-icon-material-turned-in::before {
    content: "\f463";
}
.mbsc-icon-material-tv::before {
    content: "\f464";
}
.mbsc-icon-material-unarchive::before {
    content: "\f465";
}
.mbsc-icon-material-undo::before {
    content: "\f466";
}
.mbsc-icon-material-unfold-less::before {
    content: "\f467";
}
.mbsc-icon-material-unfold-more::before {
    content: "\f468";
}
.mbsc-icon-material-update::before {
    content: "\f469";
}
.mbsc-icon-material-usb::before {
    content: "\f46a";
}
.mbsc-icon-material-verified-user::before {
    content: "\f46b";
}
.mbsc-icon-material-vertical-align-bottom::before {
    content: "\f46c";
}
.mbsc-icon-material-vertical-align-center::before {
    content: "\f46d";
}
.mbsc-icon-material-vertical-align-top::before {
    content: "\f46f";
}
.mbsc-icon-material-vibration::before {
    content: "\f46e";
}
.mbsc-icon-material-video-collection::before {
    content: "\f470";
}
.mbsc-icon-material-video-library::before {
    content: "\f471";
}
.mbsc-icon-material-videocam-off::before {
    content: "\f472";
}
.mbsc-icon-material-videocam::before {
    content: "\f473";
}
.mbsc-icon-material-videogame-asset::before {
    content: "\f474";
}
.mbsc-icon-material-view-agenda::before {
    content: "\f475";
}
.mbsc-icon-material-view-array::before {
    content: "\f476";
}
.mbsc-icon-material-view-carousel::before {
    content: "\f477";
}
.mbsc-icon-material-view-column::before {
    content: "\f478";
}
.mbsc-icon-material-view-comfy::before {
    content: "\f479";
}
.mbsc-icon-material-view-compact::before {
    content: "\f47a";
}
.mbsc-icon-material-view-day::before {
    content: "\f47b";
}
.mbsc-icon-material-view-headline::before {
    content: "\f47c";
}
.mbsc-icon-material-view-list::before {
    content: "\f47d";
}
.mbsc-icon-material-view-module::before {
    content: "\f47e";
}
.mbsc-icon-material-view-quilt::before {
    content: "\f47f";
}
.mbsc-icon-material-view-stream::before {
    content: "\f480";
}
.mbsc-icon-material-view-week::before {
    content: "\f481";
}
.mbsc-icon-material-vignette::before {
    content: "\f482";
}
.mbsc-icon-material-visibility-off::before {
    content: "\f483";
}
.mbsc-icon-material-visibility::before {
    content: "\f484";
}
.mbsc-icon-material-voice-chat::before {
    content: "\f485";
}
.mbsc-icon-material-voicemail::before {
    content: "\f486";
}
.mbsc-icon-material-volume-down::before {
    content: "\f487";
}
.mbsc-icon-material-volume-mute::before {
    content: "\f488";
}
.mbsc-icon-material-volume-off::before {
    content: "\f489";
}
.mbsc-icon-material-volume-up::before {
    content: "\f48a";
}
.mbsc-icon-material-vpn-key::before {
    content: "\f48b";
}
.mbsc-icon-material-vpn-lock::before {
    content: "\f48c";
}
.mbsc-icon-material-wallet-giftcard::before {
    content: "\f48d";
}
.mbsc-icon-material-wallet-membership::before {
    content: "\f48e";
}
.mbsc-icon-material-wallet-travel::before {
    content: "\f48f";
}
.mbsc-icon-material-warning::before {
    content: "\f490";
}
.mbsc-icon-material-watch-later::before {
    content: "\f491";
}
.mbsc-icon-material-watch::before {
    content: "\f492";
}
.mbsc-icon-material-wb-auto::before {
    content: "\f493";
}
.mbsc-icon-material-wb-cloudy::before {
    content: "\f494";
}
.mbsc-icon-material-wb-incandescent::before {
    content: "\f495";
}
.mbsc-icon-material-wb-irradescent::before {
    content: "\f496";
}
.mbsc-icon-material-wb-sunny::before {
    content: "\f497";
}
.mbsc-icon-material-web-asset::before {
    content: "\f498";
}
.mbsc-icon-material-web::before {
    content: "\f499";
}
.mbsc-icon-material-weekend::before {
    content: "\f49a";
}
.mbsc-icon-material-whatshot::before {
    content: "\f49b";
}
.mbsc-icon-material-wifi-lock::before {
    content: "\f49c";
}
.mbsc-icon-material-wifi-tethering::before {
    content: "\f49e";
}
.mbsc-icon-material-work::before {
    content: "\f49d";
}
.mbsc-icon-material-wrap-text::before {
    content: "\f49f";
}
.mbsc-icon-material-youtube-searched-for::before {
    content: "\f4a0";
}
.mbsc-icon-material-zoom-in::before {
    content: "\f4a1";
}
.mbsc-icon-material-zoom-out::before {
    content: "\f4a2";
}
.mbsc-icon-menu::before {
    content: "\f4a3";
}
.mbsc-icon-menu2::before {
    content: "\f4a4";
}
.mbsc-icon-meteo-Celsius::before {
    content: "\f4a5";
}
.mbsc-icon-meteo-Fahrenheit::before {
    content: "\f4a6";
}
.mbsc-icon-meteo-cloud::before {
    content: "\f4a7";
}
.mbsc-icon-meteo-cloud2::before {
    content: "\f4a8";
}
.mbsc-icon-meteo-cloud3::before {
    content: "\f4a9";
}
.mbsc-icon-meteo-cloud4::before {
    content: "\f4aa";
}
.mbsc-icon-meteo-cloud5::before {
    content: "\f4ab";
}
.mbsc-icon-meteo-cloudy::before {
    content: "\f4ac";
}
.mbsc-icon-meteo-cloudy2::before {
    content: "\f4ad";
}
.mbsc-icon-meteo-cloudy3::before {
    content: "\f4ae";
}
.mbsc-icon-meteo-cloudy4::before {
    content: "\f4af";
}
.mbsc-icon-meteo-compass::before {
    content: "\f4b0";
}
.mbsc-icon-meteo-lightning::before {
    content: "\f4b1";
}
.mbsc-icon-meteo-lightning2::before {
    content: "\f4b2";
}
.mbsc-icon-meteo-lightning3::before {
    content: "\f4b3";
}
.mbsc-icon-meteo-lightning4::before {
    content: "\f4b5";
}
.mbsc-icon-meteo-lightning5::before {
    content: "\f4b4";
}
.mbsc-icon-meteo-lines::before {
    content: "\f4b6";
}
.mbsc-icon-meteo-moon::before {
    content: "\f4b7";
}
.mbsc-icon-meteo-moon2::before {
    content: "\f4b8";
}
.mbsc-icon-meteo-none::before {
    content: "\f4b9";
}
.mbsc-icon-meteo-rainy::before {
    content: "\f4ba";
}
.mbsc-icon-meteo-rainy2::before {
    content: "\f4bb";
}
.mbsc-icon-meteo-rainy3::before {
    content: "\f4bd";
}
.mbsc-icon-meteo-rainy4::before {
    content: "\f4bc";
}
.mbsc-icon-meteo-snowflake::before {
    content: "\f4be";
}
.mbsc-icon-meteo-snowy::before {
    content: "\f4bf";
}
.mbsc-icon-meteo-snowy2::before {
    content: "\f4c0";
}
.mbsc-icon-meteo-snowy3::before {
    content: "\f4c2";
}
.mbsc-icon-meteo-snowy4::before {
    content: "\f4c1";
}
.mbsc-icon-meteo-snowy5::before {
    content: "\f4c3";
}
.mbsc-icon-meteo-sun::before {
    content: "\f4c5";
}
.mbsc-icon-meteo-sun2::before {
    content: "\f4c4";
}
.mbsc-icon-meteo-sun3::before {
    content: "\f4c6";
}
.mbsc-icon-meteo-sunrise::before {
    content: "\f4c7";
}
.mbsc-icon-meteo-thermometer::before {
    content: "\f4c8";
}
.mbsc-icon-meteo-weather::before {
    content: "\f4c9";
}
.mbsc-icon-meteo-weather2::before {
    content: "\f4ca";
}
.mbsc-icon-meteo-weather3::before {
    content: "\f4cb";
}
.mbsc-icon-meteo-weather4::before {
    content: "\f4cd";
}
.mbsc-icon-meteo-weather5::before {
    content: "\f4cc";
}
.mbsc-icon-meteo-wind::before {
    content: "\f4ce";
}
.mbsc-icon-meteo-windy::before {
    content: "\f4cf";
}
.mbsc-icon-meteo-windy2::before {
    content: "\f4d0";
}
.mbsc-icon-meteo-windy3::before {
    content: "\f4d1";
}
.mbsc-icon-meteo-windy4::before {
    content: "\f4d2";
}
.mbsc-icon-meteo-windy5::before {
    content: "\f4d3";
}
.mbsc-icon-meter::before {
    content: "\f4d4";
}
.mbsc-icon-meter2::before {
    content: "\f4d6";
}
.mbsc-icon-minus::before {
    content: "\f4d5";
}
.mbsc-icon-mobile::before {
    content: "\f4d7";
}
.mbsc-icon-mobile2::before {
    content: "\f4da";
}
.mbsc-icon-mug::before {
    content: "\f4d9";
}
.mbsc-icon-music::before {
    content: "\f4d8";
}
.mbsc-icon-neutral::before {
    content: "\f4db";
}
.mbsc-icon-neutral2::before {
    content: "\f4de";
}
.mbsc-icon-new-tab::before {
    content: "\f4dd";
}
.mbsc-icon-newspaper::before {
    content: "\f4dc";
}
.mbsc-icon-next::before {
    content: "\f4df";
}
.mbsc-icon-notebook::before {
    content: "\f4e0";
}
.mbsc-icon-notification::before {
    content: "\f4e1";
}
.mbsc-icon-numbered-list::before {
    content: "\f4e2";
}
.mbsc-icon-office::before {
    content: "\f4e3";
}
.mbsc-icon-omega::before {
    content: "\f4e5";
}
.mbsc-icon-opera::before {
    content: "\f4e4";
}
.mbsc-icon-pacman::before {
    content: "\f4e6";
}
.mbsc-icon-paint-format::before {
    content: "\f4e7";
}
.mbsc-icon-paragraph-center::before {
    content: "\f4e8";
}
.mbsc-icon-paragraph-center2::before {
    content: "\f4ea";
}
.mbsc-icon-paragraph-justify::before {
    content: "\f4e9";
}
.mbsc-icon-paragraph-justify2::before {
    content: "\f4ec";
}
.mbsc-icon-paragraph-left::before {
    content: "\f4eb";
}
.mbsc-icon-paragraph-left2::before {
    content: "\f4ee";
}
.mbsc-icon-paragraph-right::before {
    content: "\f4ed";
}
.mbsc-icon-paragraph-right2::before {
    content: "\f4ef";
}
.mbsc-icon-paste::before {
    content: "\f4f0";
}
.mbsc-icon-paste2::before {
    content: "\f4f1";
}
.mbsc-icon-paste3::before {
    content: "\f4f2";
}
.mbsc-icon-pause::before {
    content: "\f4f5";
}
.mbsc-icon-pause2::before {
    content: "\f4f4";
}
.mbsc-icon-pawn::before {
    content: "\f4f3";
}
.mbsc-icon-paypal::before {
    content: "\f4f7";
}
.mbsc-icon-paypal2::before {
    content: "\f4f6";
}
.mbsc-icon-paypal3::before {
    content: "\f4f8";
}
.mbsc-icon-pen::before {
    content: "\f4f9";
}
.mbsc-icon-pencil::before {
    content: "\f4fa";
}
.mbsc-icon-pencil2::before {
    content: "\f4fb";
}
.mbsc-icon-phone-hang-up::before {
    content: "\f4fc";
}
.mbsc-icon-phone::before {
    content: "\f4fd";
}
.mbsc-icon-picassa::before {
    content: "\f4fe";
}
.mbsc-icon-picassa2::before {
    content: "\f4ff";
}
.mbsc-icon-pie::before {
    content: "\f500";
}
.mbsc-icon-pilcrow::before {
    content: "\f502";
}
.mbsc-icon-pinterest::before {
    content: "\f501";
}
.mbsc-icon-pinterest2::before {
    content: "\f503";
}
.mbsc-icon-play::before {
    content: "\f504";
}
.mbsc-icon-play2::before {
    content: "\f505";
}
.mbsc-icon-play3::before {
    content: "\f506";
}
.mbsc-icon-plus::before {
    content: "\f507";
}
.mbsc-icon-podcast::before {
    content: "\f508";
}
.mbsc-icon-point-down::before {
    content: "\f509";
}
.mbsc-icon-point-left::before {
    content: "\f50a";
}
.mbsc-icon-point-right::before {
    content: "\f50b";
}
.mbsc-icon-point-up::before {
    content: "\f50c";
}
.mbsc-icon-power-cord::before {
    content: "\f50d";
}
.mbsc-icon-previous::before {
    content: "\f50e";
}
.mbsc-icon-print::before {
    content: "\f50f";
}
.mbsc-icon-profile::before {
    content: "\f510";
}
.mbsc-icon-pushpin::before {
    content: "\f511";
}
.mbsc-icon-qrcode::before {
    content: "\f512";
}
.mbsc-icon-question::before {
    content: "\f513";
}
.mbsc-icon-quill::before {
    content: "\f514";
}
.mbsc-icon-quotes-left::before {
    content: "\f515";
}
.mbsc-icon-radio-checked::before {
    content: "\f516";
}
.mbsc-icon-radio-unchecked::before {
    content: "\f517";
}
.mbsc-icon-reddit::before {
    content: "\f518";
}
.mbsc-icon-redo::before {
    content: "\f519";
}
.mbsc-icon-redo2::before {
    content: "\f51a";
}
.mbsc-icon-remove::before {
    content: "\f51b";
}
.mbsc-icon-remove2::before {
    content: "\f51c";
}
.mbsc-icon-reply::before {
    content: "\f51d";
}
.mbsc-icon-right-toleft::before {
    content: "\f51e";
}
.mbsc-icon-road::before {
    content: "\f51f";
}
.mbsc-icon-rocket::before {
    content: "\f520";
}
.mbsc-icon-sad::before {
    content: "\f521";
}
.mbsc-icon-sad2::before {
    content: "\f522";
}
.mbsc-icon-safari::before {
    content: "\f523";
}
.mbsc-icon-scissors::before {
    content: "\f524";
}
.mbsc-icon-screen::before {
    content: "\f525";
}
.mbsc-icon-search::before {
    content: "\f526";
}
.mbsc-icon-settings::before {
    content: "\f527";
}
.mbsc-icon-share::before {
    content: "\f528";
}
.mbsc-icon-shield::before {
    content: "\f529";
}
.mbsc-icon-shocked::before {
    content: "\f52a";
}
.mbsc-icon-shocked2::before {
    content: "\f52b";
}
.mbsc-icon-shuffle::before {
    content: "\f52c";
}
.mbsc-icon-sigma::before {
    content: "\f52e";
}
.mbsc-icon-signup::before {
    content: "\f52d";
}
.mbsc-icon-skype::before {
    content: "\f52f";
}
.mbsc-icon-smiley::before {
    content: "\f530";
}
.mbsc-icon-smiley2::before {
    content: "\f531";
}
.mbsc-icon-soundcloud::before {
    content: "\f532";
}
.mbsc-icon-soundcloud2::before {
    content: "\f533";
}
.mbsc-icon-spades::before {
    content: "\f534";
}
.mbsc-icon-spam::before {
    content: "\f535";
}
.mbsc-icon-spell-check::before {
    content: "\f536";
}
.mbsc-icon-spinner::before {
    content: "\f537";
}
.mbsc-icon-spinner2::before {
    content: "\f538";
}
.mbsc-icon-spinner3::before {
    content: "\f53a";
}
.mbsc-icon-spinner4::before {
    content: "\f539";
}
.mbsc-icon-spinner5::before {
    content: "\f53b";
}
.mbsc-icon-spinner6::before {
    content: "\f53c";
}
.mbsc-icon-stack::before {
    content: "\f53d";
}
.mbsc-icon-stackoverflow::before {
    content: "\f53e";
}
.mbsc-icon-star::before {
    content: "\f53f";
}
.mbsc-icon-star2::before {
    content: "\f540";
}
.mbsc-icon-star3::before {
    content: "\f542";
}
.mbsc-icon-stats::before {
    content: "\f541";
}
.mbsc-icon-steam::before {
    content: "\f543";
}
.mbsc-icon-steam2::before {
    content: "\f545";
}
.mbsc-icon-stop::before {
    content: "\f544";
}
.mbsc-icon-stop2::before {
    content: "\f546";
}
.mbsc-icon-stopwatch::before {
    content: "\f547";
}
.mbsc-icon-storage::before {
    content: "\f548";
}
.mbsc-icon-stream-armchair-chair::before {
    content: "\f549";
}
.mbsc-icon-stream-arrow-target::before {
    content: "\f54a";
}
.mbsc-icon-stream-backpack-trekking::before {
    content: "\f54b";
}
.mbsc-icon-stream-bag-shopping::before {
    content: "\f54c";
}
.mbsc-icon-stream-barbecue-eat-food::before {
    content: "\f54e";
}
.mbsc-icon-stream-barista-coffee-espresso::before {
    content: "\f54d";
}
.mbsc-icon-stream-bomb-bug::before {
    content: "\f54f";
}
.mbsc-icon-stream-book-dowload::before {
    content: "\f550";
}
.mbsc-icon-stream-book-read::before {
    content: "\f551";
}
.mbsc-icon-stream-browser-window::before {
    content: "\f552";
}
.mbsc-icon-stream-brush-paint::before {
    content: "\f553";
}
.mbsc-icon-stream-bubble-comment-talk::before {
    content: "\f554";
}
.mbsc-icon-stream-bubble-love-talk::before {
    content: "\f556";
}
.mbsc-icon-stream-caddie-shop-shopping::before {
    content: "\f555";
}
.mbsc-icon-stream-caddie-shopping::before {
    content: "\f557";
}
.mbsc-icon-stream-camera-photo-polaroid::before {
    content: "\f558";
}
.mbsc-icon-stream-camera-photo::before {
    content: "\f559";
}
.mbsc-icon-stream-camera-video::before {
    content: "\f55a";
}
.mbsc-icon-stream-chaplin-hat-movie::before {
    content: "\f55b";
}
.mbsc-icon-stream-chef-food-restaurant::before {
    content: "\f55d";
}
.mbsc-icon-stream-clock-time::before {
    content: "\f55c";
}
.mbsc-icon-stream-cocktail-mojito::before {
    content: "\f55e";
}
.mbsc-icon-stream-coffee::before {
    content: "\f55f";
}
.mbsc-icon-stream-computer-imac::before {
    content: "\f560";
}
.mbsc-icon-stream-computer-macintosh-vintage::before {
    content: "\f561";
}
.mbsc-icon-stream-computer-network::before {
    content: "\f562";
}
.mbsc-icon-stream-computer::before {
    content: "\f563";
}
.mbsc-icon-stream-cook-pan-pot::before {
    content: "\f565";
}
.mbsc-icon-stream-crop::before {
    content: "\f564";
}
.mbsc-icon-stream-crown-king::before {
    content: "\f566";
}
.mbsc-icon-stream-danger-death-delete-destroy-skull::before {
    content: "\f567";
}
.mbsc-icon-stream-dashboard-speed::before {
    content: "\f568";
}
.mbsc-icon-stream-database::before {
    content: "\f569";
}
.mbsc-icon-stream-delete-garbage::before {
    content: "\f56a";
}
.mbsc-icon-stream-design-graphic-tablet-tablet::before {
    content: "\f56b";
}
.mbsc-icon-stream-design-pencil-rule::before {
    content: "\f56d";
}
.mbsc-icon-stream-diving-leisure-sea-sport::before {
    content: "\f56c";
}
.mbsc-icon-stream-drug-medecine-syringue::before {
    content: "\f56e";
}
.mbsc-icon-stream-earth-globe::before {
    content: "\f56f";
}
.mbsc-icon-stream-eat-food-fork-knife::before {
    content: "\f571";
}
.mbsc-icon-stream-eat-food-hotdog::before {
    content: "\f570";
}
.mbsc-icon-stream-edit-modify::before {
    content: "\f572";
}
.mbsc-icon-stream-email-mail::before {
    content: "\f573";
}
.mbsc-icon-stream-envellope-mail::before {
    content: "\f574";
}
.mbsc-icon-stream-eye-dropper::before {
    content: "\f575";
}
.mbsc-icon-stream-factory-lift-warehouse::before {
    content: "\f576";
}
.mbsc-icon-stream-first-aid-medecine-shield::before {
    content: "\f577";
}
.mbsc-icon-stream-food-ice-cream::before {
    content: "\f578";
}
.mbsc-icon-stream-frame-picture::before {
    content: "\f579";
}
.mbsc-icon-stream-grid-lines::before {
    content: "\f57a";
}
.mbsc-icon-stream-handle-vector::before {
    content: "\f57b";
}
.mbsc-icon-stream-happy-smiley::before {
    content: "\f57c";
}
.mbsc-icon-stream-headset-sound::before {
    content: "\f57e";
}
.mbsc-icon-stream-home-house::before {
    content: "\f57d";
}
.mbsc-icon-stream-ibook-laptop::before {
    content: "\f57f";
}
.mbsc-icon-stream-ink-pen::before {
    content: "\f580";
}
.mbsc-icon-stream-ipad::before {
    content: "\f581";
}
.mbsc-icon-stream-iphone::before {
    content: "\f582";
}
.mbsc-icon-stream-ipod-mini-music::before {
    content: "\f583";
}
.mbsc-icon-stream-ipod-music::before {
    content: "\f584";
}
.mbsc-icon-stream-ipod::before {
    content: "\f585";
}
.mbsc-icon-stream-japan-tea::before {
    content: "\f586";
}
.mbsc-icon-stream-laptop-macbook::before {
    content: "\f587";
}
.mbsc-icon-stream-like-love::before {
    content: "\f588";
}
.mbsc-icon-stream-link::before {
    content: "\f589";
}
.mbsc-icon-stream-lock-locker::before {
    content: "\f58a";
}
.mbsc-icon-stream-locker-unlock::before {
    content: "\f58b";
}
.mbsc-icon-stream-macintosh::before {
    content: "\f58c";
}
.mbsc-icon-stream-magic-magic-wand::before {
    content: "\f58d";
}
.mbsc-icon-stream-magnet::before {
    content: "\f58e";
}
.mbsc-icon-stream-man-people-user::before {
    content: "\f58f";
}
.mbsc-icon-stream-map-pin::before {
    content: "\f591";
}
.mbsc-icon-stream-map-user::before {
    content: "\f590";
}
.mbsc-icon-stream-micro-record::before {
    content: "\f592";
}
.mbsc-icon-stream-monocle-mustache::before {
    content: "\f593";
}
.mbsc-icon-stream-music-note::before {
    content: "\f594";
}
.mbsc-icon-stream-music-speaker::before {
    content: "\f595";
}
.mbsc-icon-stream-notebook::before {
    content: "\f596";
}
.mbsc-icon-stream-paint-bucket::before {
    content: "\f597";
}
.mbsc-icon-stream-painting-pallet::before {
    content: "\f599";
}
.mbsc-icon-stream-painting-roll::before {
    content: "\f598";
}
.mbsc-icon-stream-pen::before {
    content: "\f59a";
}
.mbsc-icon-stream-photo-pictures::before {
    content: "\f59b";
}
.mbsc-icon-stream-picture::before {
    content: "\f59c";
}
.mbsc-icon-stream-receipt-shopping::before {
    content: "\f59d";
}
.mbsc-icon-stream-remote-control::before {
    content: "\f59e";
}
.mbsc-icon-stream-settings::before {
    content: "\f59f";
}
.mbsc-icon-stream-shoes-snickers::before {
    content: "\f5a0";
}
.mbsc-icon-stream-speech-talk-user::before {
    content: "\f5a1";
}
.mbsc-icon-stream-stamp::before {
    content: "\f5a2";
}
.mbsc-icon-stream-suitcase-travel::before {
    content: "\f5a3";
}
.mbsc-icon-stream-sync::before {
    content: "\f5a4";
}
.mbsc-icon-stream-umbrella-weather::before {
    content: "\f5a5";
}
.mbsc-icon-strikethrough::before {
    content: "\f5a6";
}
.mbsc-icon-stumbleupon::before {
    content: "\f5a7";
}
.mbsc-icon-stumbleupon2::before {
    content: "\f5a8";
}
.mbsc-icon-support::before {
    content: "\f5a9";
}
.mbsc-icon-switch::before {
    content: "\f5aa";
}
.mbsc-icon-tab::before {
    content: "\f5ab";
}
.mbsc-icon-table::before {
    content: "\f5ac";
}
.mbsc-icon-table2::before {
    content: "\f5ad";
}
.mbsc-icon-tablet::before {
    content: "\f5ae";
}
.mbsc-icon-tag::before {
    content: "\f5af";
}
.mbsc-icon-tags::before {
    content: "\f5b0";
}
.mbsc-icon-target::before {
    content: "\f5b2";
}
.mbsc-icon-text-height::before {
    content: "\f5b1";
}
.mbsc-icon-text-width::before {
    content: "\f5b3";
}
.mbsc-icon-thumbs-up::before {
    content: "\f5b4";
}
.mbsc-icon-thumbs-up2::before {
    content: "\f5b5";
}
.mbsc-icon-ticket::before {
    content: "\f5b6";
}
.mbsc-icon-tongue::before {
    content: "\f5b7";
}
.mbsc-icon-tongue2::before {
    content: "\f5b8";
}
.mbsc-icon-tree::before {
    content: "\f5b9";
}
.mbsc-icon-trophy::before {
    content: "\f5ba";
}
.mbsc-icon-truck::before {
    content: "\f5bb";
}
.mbsc-icon-tumblr::before {
    content: "\f5bc";
}
.mbsc-icon-tumblr2::before {
    content: "\f5bd";
}
.mbsc-icon-tux::before {
    content: "\f5be";
}
.mbsc-icon-tv::before {
    content: "\f5bf";
}
.mbsc-icon-twitter::before {
    content: "\f5c0";
}
.mbsc-icon-twitter2::before {
    content: "\f5c2";
}
.mbsc-icon-twitter3::before {
    content: "\f5c1";
}
.mbsc-icon-underline::before {
    content: "\f5c3";
}
.mbsc-icon-undo::before {
    content: "\f5c4";
}
.mbsc-icon-undo2::before {
    content: "\f5c5";
}
.mbsc-icon-unlocked::before {
    content: "\f5c6";
}
.mbsc-icon-upload::before {
    content: "\f5c7";
}
.mbsc-icon-upload2::before {
    content: "\f5c9";
}
.mbsc-icon-upload3::before {
    content: "\f5c8";
}
.mbsc-icon-user::before {
    content: "\f5ca";
}
.mbsc-icon-user2::before {
    content: "\f5cb";
}
.mbsc-icon-user3::before {
    content: "\f5cc";
}
.mbsc-icon-user4::before {
    content: "\f5cd";
}
.mbsc-icon-users::before {
    content: "\f5ce";
}
.mbsc-icon-users2::before {
    content: "\f5cf";
}
.mbsc-icon-vimeo::before {
    content: "\f5d0";
}
.mbsc-icon-vimeo2::before {
    content: "\f5d1";
}
.mbsc-icon-vimeo3::before {
    content: "\f5d2";
}
.mbsc-icon-volume-decrease::before {
    content: "\f5d3";
}
.mbsc-icon-volume-high::before {
    content: "\f5d4";
}
.mbsc-icon-volume-increase::before {
    content: "\f5d5";
}
.mbsc-icon-volume-low::before {
    content: "\f5d6";
}
.mbsc-icon-volume-medium::before {
    content: "\f5d7";
}
.mbsc-icon-volume-mute::before {
    content: "\f5d8";
}
.mbsc-icon-volume-mute2::before {
    content: "\f5d9";
}
.mbsc-icon-wand::before {
    content: "\f5da";
}
.mbsc-icon-warning::before {
    content: "\f5db";
}
.mbsc-icon-windows::before {
    content: "\f5dd";
}
.mbsc-icon-windows8::before {
    content: "\f5dc";
}
.mbsc-icon-wink::before {
    content: "\f5de";
}
.mbsc-icon-wink2::before {
    content: "\f5df";
}
.mbsc-icon-wondering::before {
    content: "\f5e0";
}
.mbsc-icon-wondering2::before {
    content: "\f5e2";
}
.mbsc-icon-wordpress::before {
    content: "\f5e1";
}
.mbsc-icon-wordpress2::before {
    content: "\f5e3";
}
.mbsc-icon-wrench::before {
    content: "\f5e4";
}
.mbsc-icon-xing::before {
    content: "\f5e5";
}
.mbsc-icon-xing2::before {
    content: "\f5e6";
}
.mbsc-icon-yahoo::before {
    content: "\f5e7";
}
.mbsc-icon-yelp::before {
    content: "\f5e9";
}
.mbsc-icon-youtube::before {
    content: "\f5ea";
}
.mbsc-icon-youtube2::before {
    content: "\f5e8";
}
.mbsc-icon-zoom-in::before {
    content: "\f5eb";
}
.mbsc-icon-zoom-out::before {
    content: "\f5ec";
}

// form-controls component
// Theme variables for download builder
$mbsc-ios-theme: 'true' !default;
$mbsc-material-theme: 'true' !default;
$mbsc-windows-theme: 'true' !default;
$mbsc-mobiscroll-theme: null !default;

// Page color variables

$mbsc-page-background-light: null !default;
$mbsc-page-background-dark: null !default;
$mbsc-page-text-light: null !default;
$mbsc-page-text-dark: null !default;

$mbsc-button-color-light: null !default;
$mbsc-button-color-dark: null !default;
$mbsc-button-text-light: null !default;
$mbsc-button-text-dark: null !default;

$mbsc-form-background-light: null !default;
$mbsc-form-background-dark: null !default;
$mbsc-form-accent-light: null !default;
$mbsc-form-accent-dark: null !default;
$mbsc-form-text-light: null !default;
$mbsc-form-text-dark: null !default;
$mbsc-form-error-light: null !default;
$mbsc-form-error-dark: null !default;

$mbsc-input-background-light: null !default;
$mbsc-input-background-dark: null !default;
$mbsc-input-text-light: null !default;
$mbsc-input-text-dark: null !default;
$mbsc-input-accent-light: null !default;
$mbsc-input-accent-dark: null !default;
$mbsc-input-border-light: null !default;
$mbsc-input-border-dark: null !default;

$mbsc-calendar-background-light: null !default;
$mbsc-calendar-background-dark: null !default;
$mbsc-calendar-text-light: null !default;
$mbsc-calendar-text-dark: null !default;
$mbsc-calendar-accent-light: null !default;
$mbsc-calendar-accent-dark: null !default;
$mbsc-calendar-border-light: null !default;
$mbsc-calendar-border-dark: null !default;
$mbsc-calendar-mark-light: null !default;
$mbsc-calendar-mark-dark: null !default;
$mbsc-calendar-event-light: null !default;
$mbsc-calendar-event-dark: null !default;

@function get-contrast-color($color) {
  @if (lightness($color) > 67%) {
    @return #000;
  }

  @else {
    @return #fff;
  }
}


$mbsc-ios-accent: #007aff !default;
$mbsc-ios-background: #f7f7f7 !default;
$mbsc-ios-text: #000000 !default;

$mbsc-ios-dark-accent: #FF3517 !default;
$mbsc-ios-dark-background: #121212 !default;
$mbsc-ios-dark-text: #ffffff !default;

/* Base colors */
$mbsc-ios-primary: #3f97f6 !default;
$mbsc-ios-secondary: #90979E !default;
$mbsc-ios-success: #43BE5F !default;
$mbsc-ios-danger: #f5504e !default;
$mbsc-ios-warning: #f8b042 !default;
$mbsc-ios-info: #5BB7C5 !default;
$mbsc-ios-light: #fff !default;
$mbsc-ios-dark: #47494A !default;

$mbsc-ios-error: #d8332a;

/* Form colors */

$mbsc-ios-form-background: $mbsc-form-background-light !default;
$mbsc-ios-dark-form-background: $mbsc-form-background-dark !default;
$mbsc-ios-form-text: $mbsc-form-text-light !default;
$mbsc-ios-dark-form-text: $mbsc-form-text-dark !default;
$mbsc-ios-form-accent: $mbsc-form-accent-light !default;
$mbsc-ios-dark-form-accent: $mbsc-form-accent-dark !default;
$mbsc-ios-form-error: $mbsc-form-error-light !default;
$mbsc-ios-dark-form-error: $mbsc-form-error-dark !default;

/* Calendar colors (will be used by eventcalendar, calendar, range) */

$mbsc-ios-calendar-background: $mbsc-calendar-background-light !default;
$mbsc-ios-calendar-text: $mbsc-calendar-text-light !default;
$mbsc-ios-calendar-accent: $mbsc-calendar-accent-light !default;
$mbsc-ios-calendar-border: $mbsc-calendar-border-light !default;
$mbsc-ios-calendar-mark: $mbsc-calendar-mark-light !default;
$mbsc-ios-calendar-event: $mbsc-calendar-event-light !default;

$mbsc-ios-dark-calendar-background: $mbsc-calendar-background-dark !default;
$mbsc-ios-dark-calendar-text: $mbsc-calendar-text-dark !default;
$mbsc-ios-dark-calendar-accent: $mbsc-calendar-accent-dark !default;
$mbsc-ios-dark-calendar-border: $mbsc-calendar-border-dark !default;
$mbsc-ios-dark-calendar-mark: $mbsc-calendar-mark-dark !default;
$mbsc-ios-dark-calendar-event: $mbsc-calendar-event-dark !default;

$mbsc-ios-colors: ( // Colors map
  'background': $mbsc-ios-background,
  'text': $mbsc-ios-text,
  'accent': $mbsc-ios-accent,

  'calendar-background': $mbsc-ios-calendar-background,
  'calendar-text': $mbsc-ios-calendar-text,
  'calendar-accent': $mbsc-ios-calendar-accent,
  'calendar-border': $mbsc-ios-calendar-border,
  'calendar-mark': $mbsc-ios-calendar-mark,
  'calendar-event': $mbsc-ios-calendar-event,

  'form-background': $mbsc-ios-form-background,
  'form-text': $mbsc-ios-form-text,
  'form-accent': $mbsc-ios-form-accent,
  'form-error': $mbsc-ios-form-error,
);

$mbsc-ios-dark-colors: ( // Colors map
  'background': $mbsc-ios-dark-background,
  'text': $mbsc-ios-dark-text,
  'accent': $mbsc-ios-dark-accent,

  'calendar-background': $mbsc-ios-dark-calendar-background,
  'calendar-text': $mbsc-ios-dark-calendar-text,
  'calendar-accent': $mbsc-ios-dark-calendar-accent,
  'calendar-border': $mbsc-ios-dark-calendar-border,
  'calendar-mark': $mbsc-ios-dark-calendar-mark,
  'calendar-event': $mbsc-ios-dark-calendar-event,

  'form-background': $mbsc-ios-dark-form-background,
  'form-text': $mbsc-ios-dark-form-text,
  'form-accent': $mbsc-ios-dark-form-accent,
  'form-error': $mbsc-ios-dark-form-error,
);

@function mbsc-ios-colors($params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $background-limited: hsl(hue($background), saturation($background), max(lightness($background), 3%));
  $background-alt: lighten($background, 6%);
  $border-color: '';

  @if (lightness($background) > 50%) {
    $border-color: darken($background-limited, 17%);
  }

  @else {
    $border-color: lighten($background, 20%);
  }

  @return ( // General colors
    'background-alt': $background-alt,
    'background-limited': $background-limited,
    'border-color': $border-color,
  );
}



@mixin mbsc-ios-form-controls($theme, $params) {

  $text: map-get($params, 'text');
  $background: map-get($params, 'background');

  $bg-param: map-get($params, 'form-background');
  $err-param: map-get($params, 'form-error');

  $background: if($bg-param, $bg-param, $background);

  $is-dark: false;
  $border-color: '';
  $wrapper-background: '';
  $wrapper-background-popup: '';

  @if (lightness($background) > 50%) {
    $border-color: darken($background, 17%);
    $wrapper-background: lighten($background, 6%);
    $wrapper-background-popup: $wrapper-background;
  }

  @else {
    $is-dark: true;
    $border-color: lighten($background, 20%);
    $wrapper-background: lighten($background, 11%);
    $wrapper-background-popup: lighten($background, 17%);
  }

  $error: if($err-param, $err-param, $mbsc-ios-error);

  .mbsc-#{$theme} {

    /* Wrapper */

    &.mbsc-form-control-wrapper {
      background: $wrapper-background;
    }

    &.mbsc-form-control-wrapper:before,
    &.mbsc-form-control-wrapper:after {
      border-color: $border-color;
    }

    &.mbsc-form-control-wrapper.mbsc-error:after,
    &.mbsc-form-control-wrapper.mbsc-error + .mbsc-form-control-wrapper:before {
      border-color: $error;
    }

    /* Inside popup */

    @if ($is-dark) {
      &.mbsc-popup &.mbsc-form-control-wrapper {
        background: $wrapper-background-popup;
      }
    }
  }
}



$mbsc-material-accent: #1a73e8 !default;
$mbsc-material-background: #fff !default;
$mbsc-material-text: #303030 !default;

$mbsc-material-dark-accent: #87b0f3 !default;
$mbsc-material-dark-background: #000 !default;
$mbsc-material-dark-text: #fff !default;

/* Base colors */
$mbsc-material-primary: #3f97f6 !default;
$mbsc-material-secondary: #90979E !default;
$mbsc-material-success: #43BE5F !default;
$mbsc-material-danger: #f5504e !default;
$mbsc-material-warning: #f8b042 !default;
$mbsc-material-info: #5BB7C5 !default;
$mbsc-material-light: #fff !default;
$mbsc-material-dark: #47494A !default;

$mbsc-material-error: #de3226;

/* Form colors */

$mbsc-material-form-background: $mbsc-form-background-light !default;
$mbsc-material-dark-form-background: $mbsc-form-background-dark !default;
$mbsc-material-form-text: $mbsc-form-text-light !default;
$mbsc-material-dark-form-text: $mbsc-form-text-dark !default;
$mbsc-material-form-accent: $mbsc-form-accent-light !default;
$mbsc-material-dark-form-accent: $mbsc-form-accent-dark !default;
$mbsc-material-form-error: $mbsc-form-error-light !default;
$mbsc-material-dark-form-error: $mbsc-form-error-dark !default;

/* Calendar colors (will be used by eventcalendar, calendar, range) */

$mbsc-material-calendar-background: $mbsc-calendar-background-light !default;
$mbsc-material-calendar-text: $mbsc-calendar-text-light !default;
$mbsc-material-calendar-accent: $mbsc-calendar-accent-light !default;
$mbsc-material-calendar-border: $mbsc-calendar-border-light !default;
$mbsc-material-calendar-mark: $mbsc-calendar-mark-light !default;
$mbsc-material-calendar-event: $mbsc-calendar-event-light !default;

$mbsc-material-dark-calendar-background: $mbsc-calendar-background-dark !default;
$mbsc-material-dark-calendar-text: $mbsc-calendar-text-dark !default;
$mbsc-material-dark-calendar-accent: $mbsc-calendar-accent-dark !default;
$mbsc-material-dark-calendar-border: $mbsc-calendar-border-dark !default;
$mbsc-material-dark-calendar-mark: $mbsc-calendar-mark-dark !default;
$mbsc-material-dark-calendar-event: $mbsc-calendar-event-dark !default;

$mbsc-material-colors: ( // Colors map
  'background': $mbsc-material-background,
  'text': $mbsc-material-text,
  'accent': $mbsc-material-accent,

  'calendar-background': $mbsc-material-calendar-background,
  'calendar-text': $mbsc-material-calendar-text,
  'calendar-accent': $mbsc-material-calendar-accent,
  'calendar-border': $mbsc-material-calendar-border,
  'calendar-mark': $mbsc-material-calendar-mark,
  'calendar-event': $mbsc-material-calendar-event,

  'form-background': $mbsc-material-form-background,
  'form-text': $mbsc-material-form-text,
  'form-accent': $mbsc-material-form-accent,
  'form-error': $mbsc-material-form-error,
);

$mbsc-material-dark-colors: ( // Colors map
  'background': $mbsc-material-dark-background,
  'text': $mbsc-material-dark-text,
  'accent': $mbsc-material-dark-accent,

  'calendar-background': $mbsc-material-dark-calendar-background,
  'calendar-text': $mbsc-material-dark-calendar-text,
  'calendar-accent': $mbsc-material-dark-calendar-accent,
  'calendar-border': $mbsc-material-dark-calendar-border,
  'calendar-mark': $mbsc-material-dark-calendar-mark,
  'calendar-event': $mbsc-material-dark-calendar-event,

  'form-background': $mbsc-material-dark-form-background,
  'form-text': $mbsc-material-dark-form-text,
  'form-accent': $mbsc-material-dark-form-accent,
  'form-error': $mbsc-material-dark-form-error,
);

@function mbsc-material-colors($params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  @return ();
}



@mixin mbsc-material-form-controls($theme, $params) {}



$mbsc-windows-accent: #0078d7 !default;
$mbsc-windows-background: #ffffff !default;
$mbsc-windows-text: #333333 !default;

$mbsc-windows-dark-accent: #0078d7 !default;
$mbsc-windows-dark-background: #1a1a1a !default;
$mbsc-windows-dark-text: #ffffff !default;

/* Base colors */
$mbsc-windows-primary: #3f97f6 !default;
$mbsc-windows-secondary: #90979E !default;
$mbsc-windows-success: #43BE5F !default;
$mbsc-windows-danger: #f5504e !default;
$mbsc-windows-warning: #f8b042 !default;
$mbsc-windows-info: #5BB7C5 !default;
$mbsc-windows-light: #fff !default;
$mbsc-windows-dark: #47494A !default;

$mbsc-windows-error: #a4262c;

/* Form colors */

$mbsc-windows-form-background: $mbsc-form-background-light !default;
$mbsc-windows-dark-form-background: $mbsc-form-background-dark !default;
$mbsc-windows-form-text: $mbsc-form-text-light !default;
$mbsc-windows-dark-form-text: $mbsc-form-text-dark !default;
$mbsc-windows-form-accent: $mbsc-form-accent-light !default;
$mbsc-windows-dark-form-accent: $mbsc-form-accent-dark !default;
$mbsc-windows-form-error: $mbsc-form-error-light !default;
$mbsc-windows-dark-form-error: $mbsc-form-error-dark !default;

/* Calendar colors (will be used by eventcalendar, calendar, range) */

$mbsc-windows-calendar-background: $mbsc-calendar-background-light !default;
$mbsc-windows-calendar-text: $mbsc-calendar-text-light !default;
$mbsc-windows-calendar-accent: $mbsc-calendar-accent-light !default;
$mbsc-windows-calendar-border: $mbsc-calendar-border-light !default;
$mbsc-windows-calendar-mark: $mbsc-calendar-mark-light !default;
$mbsc-windows-calendar-event: $mbsc-calendar-event-light !default;

$mbsc-windows-dark-calendar-background: $mbsc-calendar-background-dark !default;
$mbsc-windows-dark-calendar-text: $mbsc-calendar-text-dark !default;
$mbsc-windows-dark-calendar-accent: $mbsc-calendar-accent-dark !default;
$mbsc-windows-dark-calendar-border: $mbsc-calendar-border-dark !default;
$mbsc-windows-dark-calendar-mark: $mbsc-calendar-mark-dark !default;
$mbsc-windows-dark-calendar-event: $mbsc-calendar-event-dark !default;

$mbsc-windows-colors: ( // Colors map
  'background': $mbsc-windows-background,
  'text': $mbsc-windows-text,
  'accent': $mbsc-windows-accent,
  
  'calendar-background': $mbsc-windows-calendar-background,
  'calendar-text': $mbsc-windows-calendar-text,
  'calendar-accent': $mbsc-windows-calendar-accent,
  'calendar-border': $mbsc-windows-calendar-border,
  'calendar-mark': $mbsc-windows-calendar-mark,
  'calendar-event': $mbsc-windows-calendar-event,

  'form-background': $mbsc-windows-form-background,
  'form-text': $mbsc-windows-form-text,
  'form-accent': $mbsc-windows-form-accent,
  'form-error': $mbsc-windows-form-error,
);

$mbsc-windows-dark-colors: ( // Colors map
  'background': $mbsc-windows-dark-background,
  'text': $mbsc-windows-dark-text,
  'accent': $mbsc-windows-dark-accent,
  
  'calendar-background': $mbsc-windows-dark-calendar-background,
  'calendar-text': $mbsc-windows-dark-calendar-text,
  'calendar-accent': $mbsc-windows-dark-calendar-accent,
  'calendar-border': $mbsc-windows-dark-calendar-border,
  'calendar-mark': $mbsc-windows-dark-calendar-mark,
  'calendar-event': $mbsc-windows-dark-calendar-event,

  'form-background': $mbsc-windows-dark-form-background,
  'form-text': $mbsc-windows-dark-form-text,
  'form-accent': $mbsc-windows-dark-form-accent,
  'form-error': $mbsc-windows-dark-form-error,
);

@function mbsc-windows-colors($params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');
  
  @return ();
}



@mixin mbsc-windows-form-controls($theme, $params) {}

// button component


$mbsc-ios-button-color: $mbsc-button-color-light !default;
$mbsc-ios-button-text: $mbsc-button-text-light !default;

$mbsc-ios-dark-button-color: $mbsc-button-color-dark !default;
$mbsc-ios-dark-button-text: $mbsc-button-text-dark !default;

$mbsc-ios-colors: map-merge($mbsc-ios-colors, ('button-color': $mbsc-ios-button-color,
    'button-text': $mbsc-ios-button-text,
  ));

$mbsc-ios-dark-colors: map-merge($mbsc-ios-dark-colors, ('button-color': $mbsc-ios-dark-button-color,
    'button-text': $mbsc-ios-dark-button-text,
  ));

@mixin mbsc-ios-button($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $button-background-param: map-get($params, 'button-color');
  $button-text-param: map-get($params, 'button-text');

  $white: #fff;
  $black: #000;
  $button-background: '';
  $background-contrast: '';

  @if(lightness($background) > 50%) {
    $button-background: lighten($background, 10%);
    $background-contrast: #000;
  }

  @else {
    $button-background: lighten($background, 23%);
    $background-contrast: #fff;
  }

  $button-background: if($button-background-param, $button-background-param, $button-background);
  $button-text: if($button-text-param, $button-text-param, $accent);

  $form-background: $background;
  // The $form-background is used for the active text color of outlined buttons.
  // Until we'll have the form component specify this, we'll default to the $background.
  // @if($button-background-param) {
  //   @if(lightness($button-background-param) > 50%) {
  //     $form-background: adjust-hue(darken(saturate($button-background-param, 19%), 12%), 240deg);
  //   }
  //   @else {
  //     $form-background: adjust-hue(lighten(desaturate($button-background-param, 19%), 10%), 240deg);
  //   }
  // }
  // @else {
  //   @if(lightness($background) > 50%) {
  //     $form-background: adjust-hue(darken(saturate($background, 19%), 2%), 240deg);
  //   }
  //   @else {
  //     $form-background: adjust-hue(lighten(desaturate($background, 19%), 10%), 240deg);
  //   }
  // }

  $form-selection: '';

  // Light button
  @if (lightness($accent) > 50%) {
    $form-selection: lighten(saturate($accent, 15%), 3%);
  }

  // Dark button
  @else {
    $form-selection: darken(desaturate($accent, 15%), 3%);
  }

  $form-selection: if($button-background-param, $button-background-param, $form-selection);
  $flat-color: if($button-background-param, $button-background-param, $accent);

  .mbsc-#{$theme} {
    &.mbsc-button-standard {
      background: $button-background;
      color: $button-text;
    }

    // &.mbsc-button:disabled {
    //   background: $disabled-background;
    //   color: $disabled-color;
    // }

    /* Flat buttons */
    &.mbsc-button-flat {
      color: $flat-color;
    }

    /* Outline buttons */
    &.mbsc-button-outline {
      border: 1px solid $form-selection;
      color: $form-selection;

      &.mbsc-active {
        background: $form-selection;
        color: $background;
      }
    }

    &.mbsc-button.mbsc-focus {
      background: rgba($background-contrast, .05);
      // box-shadow: 0 0 0 2px rgba($text, .3);
    }

    /* Predefined colors */
    &.mbsc-button-primary.mbsc-button-standard {
      background: $mbsc-ios-primary;
      color: $white;
    }

    &.mbsc-button-secondary.mbsc-button-standard {
      background: $mbsc-ios-secondary;
      color: $white;
    }

    &.mbsc-button-success.mbsc-button-standard {
      background: $mbsc-ios-success;
      color: $white;
    }

    &.mbsc-button-danger.mbsc-button-standard {
      background: $mbsc-ios-danger;
      color: $white;
    }

    &.mbsc-button-warning.mbsc-button-standard {
      background: $mbsc-ios-warning;
      color: $white;
    }

    &.mbsc-button-info.mbsc-button-standard {
      background: $mbsc-ios-info;
      color: $white;
    }

    &.mbsc-button-dark.mbsc-button-standard {
      background: $mbsc-ios-dark;
      color: $white;
    }

    &.mbsc-button-light.mbsc-button-standard {
      background: $mbsc-ios-light;
      color: $black;
    }

    &.mbsc-button-primary.mbsc-button-flat {
      color: $mbsc-ios-primary;
    }

    &.mbsc-button-secondary.mbsc-button-flat {
      color: $mbsc-ios-secondary;
    }

    &.mbsc-button-success.mbsc-button-flat {
      color: $mbsc-ios-success;
    }

    &.mbsc-button-danger.mbsc-button-flat {
      color: $mbsc-ios-danger;
    }

    &.mbsc-button-warning.mbsc-button-flat {
      color: $mbsc-ios-warning;
    }

    &.mbsc-button-info.mbsc-button-flat {
      color: $mbsc-ios-info;
    }

    &.mbsc-button-dark.mbsc-button-flat {
      color: $mbsc-ios-dark;
    }

    &.mbsc-button-light.mbsc-button-flat {
      color: darken($mbsc-ios-light, 20%);
    }

    &.mbsc-button-primary.mbsc-button-outline {
      border-color: $mbsc-ios-primary;
      color: $mbsc-ios-primary;

      &.mbsc-active {
        background: $mbsc-ios-primary;
        color: $mbsc-ios-light;
      }
    }

    &.mbsc-button-secondary.mbsc-button-outline {
      border-color: $mbsc-ios-secondary;
      color: $mbsc-ios-secondary;

      &.mbsc-active {
        background: $mbsc-ios-secondary;
        color: $mbsc-ios-light;
      }
    }

    &.mbsc-button-success.mbsc-button-outline {
      border-color: $mbsc-ios-success;
      color: $mbsc-ios-success;

      &.mbsc-active {
        background: $mbsc-ios-success;
        color: $mbsc-ios-light;
      }
    }

    &.mbsc-button-danger.mbsc-button-outline {
      border-color: $mbsc-ios-danger;
      color: $mbsc-ios-danger;

      &.mbsc-active {
        background: $mbsc-ios-danger;
        color: $mbsc-ios-light;
      }
    }

    &.mbsc-button-warning.mbsc-button-outline {
      border-color: $mbsc-ios-warning;
      color: $mbsc-ios-warning;

      &.mbsc-active {
        background: $mbsc-ios-warning;
        color: $mbsc-ios-light;
      }
    }

    &.mbsc-button-info.mbsc-button-outline {
      border-color: $mbsc-ios-info;
      color: $mbsc-ios-info;

      &.mbsc-active {
        background: $mbsc-ios-info;
        color: $mbsc-ios-light;
      }
    }

    &.mbsc-button-dark.mbsc-button-outline {
      border-color: $mbsc-ios-dark;
      color: $mbsc-ios-dark;

      &.mbsc-active {
        background: $mbsc-ios-dark;
        color: $mbsc-ios-light;
      }
    }

    &.mbsc-button-light.mbsc-button-outline {
      border-color: darken($mbsc-ios-light, 25%);
      color: darken($mbsc-ios-light, 25%);

      &.mbsc-active {
        background: darken($mbsc-ios-light, 25%);
        color: $mbsc-ios-light;
      }
    }
  }
}



$mbsc-material-button-color: $mbsc-button-color-light !default;
$mbsc-material-button-text: $mbsc-button-text-light !default;

$mbsc-material-dark-button-color: $mbsc-button-color-dark !default;
$mbsc-material-dark-button-text: $mbsc-button-text-dark !default;

$mbsc-material-colors: map-merge($mbsc-material-colors, ('button-color': $mbsc-material-button-color,
    'button-text': $mbsc-material-button-text,
  ));

$mbsc-material-dark-colors: map-merge($mbsc-material-dark-colors, ('button-color': $mbsc-material-dark-button-color,
    'button-text': $mbsc-material-dark-button-text,
  ));

@mixin mbsc-material-button($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $button-background-param: map-get($params, 'button-color');
  $button-text-param: map-get($params, 'button-text');

  $button-active: darken($background, 13%);
  $button-text: '';
  $button-background: '';

  @if (lightness($background) > 50%) {
    $button-text: darken($text, 36%);
    $button-background: darken($background, 19%);
  }

  @else {
    $button-text: lighten($text, 24%);
    $button-background: lighten($background, 17%);
  }

  $button-background: if($button-background-param, $button-background-param, $button-background);
  $button-text: if($button-text-param, $button-text-param, $button-text);
  $flatout-color: if($button-background-param, $button-background, $button-text);

  .mbsc-#{$theme} {
    &.mbsc-button-standard {
      background: $button-background;
      color: $button-text;
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);

      &.mbsc-hover {
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
      }

      &.mbsc-focus,
      &.mbsc-active {
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
      }
    }

    /* Flat buttons */
    &.mbsc-button-flat,
    &.mbsc-button-outline {
      color: $flatout-color;

      &.mbsc-hover,
      &.mbsc-active {
        background: rgba($flatout-color, .2);
      }

      &.mbsc-focus {
        background: rgba($flatout-color, .3);
      }
    }

    /* Outline buttons */
    &.mbsc-button-outline {
      border-color: $flatout-color;
    }

    /* Predefined colors */
    &.mbsc-button-primary.mbsc-button-standard {
      background: $mbsc-material-primary;
      color: $background;
    }

    &.mbsc-button-secondary.mbsc-button-standard {
      background: $mbsc-material-secondary;
      color: $background;
    }

    &.mbsc-button-success.mbsc-button-standard {
      background: $mbsc-material-success;
      color: $background;
    }

    &.mbsc-button-danger.mbsc-button-standard {
      background: $mbsc-material-danger;
      color: $background;
    }

    &.mbsc-button-warning.mbsc-button-standard {
      background: $mbsc-material-warning;
      color: $background;
    }

    &.mbsc-button-info.mbsc-button-standard {
      background: $mbsc-material-info;
      color: $background;
    }

    &.mbsc-button-dark.mbsc-button-standard {
      background: $mbsc-material-dark;
      color: $background;
    }

    &.mbsc-button-light.mbsc-button-standard {
      background: $mbsc-material-light;
      color: $text;
    }

    &.mbsc-button-primary.mbsc-button-flat {
      color: $mbsc-material-primary;

      &.mbsc-hover,
      &.mbsc-active {
        background: rgba($mbsc-material-primary, .2);
      }

      &.mbsc-focus {
        background: rgba($mbsc-material-primary, .3);
      }
    }

    &.mbsc-button-secondary.mbsc-button-flat {
      color: $mbsc-material-secondary;

      &.mbsc-hover,
      &.mbsc-active {
        background: rgba($mbsc-material-secondary, .2);
      }

      &.mbsc-focus {
        background: rgba($mbsc-material-secondary, .3);
      }
    }

    &.mbsc-button-success.mbsc-button-flat {
      color: $mbsc-material-success;

      &.mbsc-hover,
      &.mbsc-active {
        background: rgba($mbsc-material-success, .2);
      }

      &.mbsc-focus {
        background: rgba($mbsc-material-success, .3);
      }
    }

    &.mbsc-button-danger.mbsc-button-flat {
      color: $mbsc-material-danger;

      &.mbsc-hover,
      &.mbsc-active {
        background: rgba($mbsc-material-danger, .2);
      }

      &.mbsc-focus {
        background: rgba($mbsc-material-danger, .3);
      }
    }

    &.mbsc-button-warning.mbsc-button-flat {
      color: $mbsc-material-warning;

      &.mbsc-hover,
      &.mbsc-active {
        background: rgba($mbsc-material-warning, .2);
      }

      &.mbsc-focus {
        background: rgba($mbsc-material-warning, .3);
      }
    }

    &.mbsc-button-info.mbsc-button-flat {
      color: $mbsc-material-info;

      &.mbsc-hover,
      &.mbsc-active {
        background: rgba($mbsc-material-info, .2);
      }

      &.mbsc-focus {
        background: rgba($mbsc-material-info, .3);
      }
    }

    &.mbsc-button-dark.mbsc-button-flat {
      color: $mbsc-material-dark;

      &.mbsc-hover,
      &.mbsc-active {
        background: rgba($mbsc-material-dark, .2);
      }

      &.mbsc-focus {
        background: rgba($mbsc-material-dark, .3);
      }
    }

    &.mbsc-button-light.mbsc-button-flat {
      color: darken($mbsc-material-light, 20%);

      &.mbsc-hover,
      &.mbsc-active {
        background: rgba($mbsc-material-light, .2);
      }

      &.mbsc-focus {
        background: rgba($mbsc-material-light, .3);
      }
    }

    &.mbsc-button-primary.mbsc-button-outline {
      border-color: $mbsc-material-primary;
      color: $mbsc-material-primary;

      &.mbsc-hover,
      &.mbsc-active {
        background: rgba($mbsc-material-primary, .2);
      }

      &.mbsc-focus {
        background: rgba($mbsc-material-primary, .3);
      }
    }

    &.mbsc-button-secondary.mbsc-button-outline {
      border-color: $mbsc-material-secondary;
      color: $mbsc-material-secondary;

      &.mbsc-hover,
      &.mbsc-active {
        background: rgba($mbsc-material-secondary, .2);
      }

      &.mbsc-focus {
        background: rgba($mbsc-material-secondary, .3);
      }
    }

    &.mbsc-button-success.mbsc-button-outline {
      border-color: $mbsc-material-success;
      color: $mbsc-material-success;

      &.mbsc-hover,
      &.mbsc-active {
        background: rgba($mbsc-material-success, .2);
      }

      &.mbsc-focus {
        background: rgba($mbsc-material-success, .3);
      }
    }

    &.mbsc-button-danger.mbsc-button-outline {
      border-color: $mbsc-material-danger;
      color: $mbsc-material-danger;

      &.mbsc-hover,
      &.mbsc-active {
        background: rgba($mbsc-material-danger, .2);
      }

      &.mbsc-focus {
        background: rgba($mbsc-material-danger, .3);
      }
    }

    &.mbsc-button-warning.mbsc-button-outline {
      border-color: $mbsc-material-warning;
      color: $mbsc-material-warning;

      &.mbsc-hover,
      &.mbsc-active {
        background: rgba($mbsc-material-warning, .2);
      }

      &.mbsc-focus {
        background: rgba($mbsc-material-warning, .3);
      }
    }

    &.mbsc-button-info.mbsc-button-outline {
      border-color: $mbsc-material-info;
      color: $mbsc-material-info;

      &.mbsc-hover,
      &.mbsc-active {
        background: rgba($mbsc-material-info, .2);
      }

      &.mbsc-focus {
        background: rgba($mbsc-material-info, .3);
      }
    }

    &.mbsc-button-dark.mbsc-button-outline {
      border-color: $mbsc-material-dark;
      color: $mbsc-material-dark;

      &.mbsc-hover,
      &.mbsc-active {
        background: rgba($mbsc-material-dark, .2);
      }

      &.mbsc-focus {
        background: rgba($mbsc-material-dark, .3);
      }
    }

    &.mbsc-button-light.mbsc-button-outline {
      border-color: darken($mbsc-material-light, 20%);
      color: darken($mbsc-material-light, 20%);

      &.mbsc-hover,
      &.mbsc-active {
        background: rgba($mbsc-material-light, .2);
      }

      &.mbsc-focus {
        background: rgba($mbsc-material-light, .3);
      }
    }
  }
}



$mbsc-windows-button-color: $mbsc-button-color-light !default;
$mbsc-windows-button-text: $mbsc-button-text-light !default;

$mbsc-windows-dark-button-color: $mbsc-button-color-dark !default;
$mbsc-windows-dark-button-text: $mbsc-button-text-dark !default;

$mbsc-windows-colors: map-merge($mbsc-windows-colors, ('button-color': $mbsc-windows-button-color,
    'button-text': $mbsc-windows-button-text,
  ));

$mbsc-windows-dark-colors: map-merge($mbsc-windows-dark-colors, ('button-color': $mbsc-windows-dark-button-color,
    'button-text': $mbsc-windows-dark-button-text,
  ));

@mixin mbsc-windows-button($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $button-background-param: map-get($params, 'button-color');
  $button-text-param: map-get($params, 'button-text');

  $button-text: if($button-text-param, $button-text-param, $text);

  $button-bg: '';
  $hover: '';
  $button-border: '';
  $button-border-hover: '';
  $dark-text: darken($mbsc-windows-dark, 30%);

  @if (lightness($background) > 50%) {
    $button-bg: darken($background, 20%);
    $hover: lighten($button-text, 70%);
    $button-border: darken($background, 40%);
    $button-border-hover: darken($background, 50%);
  }

  @else {
    $button-bg: lighten($background, 15%);
    $hover: darken($button-text, 70%);
    $button-border: lighten($background, 35%);
    $button-border-hover: lighten($background, 45%);
  }

  $button-bg: if($button-background-param, $button-background-param, $button-bg);

  @if($button-background-param) {
    $button-border: $button-background-param;

    @if (lightness($button-background-param) > 50%) {
      $button-border-hover: darken($button-border, 10%);
    }

    @else {
      $button-border-hover: lighten($button-border, 10%);
    }
  }


  $active-base: if($button-background-param, $button-background-param, $background);
  $button-active: '';

  @if (lightness($active-base) > 50%) {
    $button-active: lighten($button-text, 28%);
  }

  @else {
    $button-active: darken($button-text, 33%);
  }

  $flat-color: if($button-background-param, $button-bg, $button-text);
  $flat-active: if($button-background-param, $button-text, $button-bg);

  .mbsc-#{$theme} {
    &.mbsc-button-standard {
      background: $button-bg;
      border-color: $button-bg;
      color: $button-text;

      &.mbsc-hover {
        border-color: $button-active;
      }

      &.mbsc-active {
        border-color: $button-active;
        background: $button-active;
      }
    }

    /* Flat buttons */
    &.mbsc-button-flat {
      color: $flat-color;

      &.mbsc-active {
        background: lighten($button-bg, 15%);
        border-color: lighten($button-bg, 15%);
        color: $button-text;
      }
    }

    /* Outline buttons */
    &.mbsc-button-outline {
      border: 0.125em solid $button-border;
      color: $button-border;

      &.mbsc-hover {
        border-color: $button-border-hover;
      }

      &.mbsc-active {
        border-color: $button-border-hover;
        background: $button-border-hover;
        color: get-contrast-color($button-border-hover);
      }
    }

    &.mbsc-button.mbsc-focus {
      // background: rgba($accent, .3);
      box-shadow: 0 0 0 1px $button-text;
    }

    /* Predefined colors */
    &.mbsc-button-primary.mbsc-button-standard {
      background: $mbsc-windows-primary;
      border-color: $mbsc-windows-primary;
      color: get-contrast-color($mbsc-windows-primary);
    }

    &.mbsc-button-secondary.mbsc-button-standard {
      background: $mbsc-windows-secondary;
      border-color: $mbsc-windows-secondary;
      color: get-contrast-color($mbsc-windows-secondary);
    }

    &.mbsc-button-success.mbsc-button-standard {
      background: $mbsc-windows-success;
      border-color: $mbsc-windows-success;
      color: get-contrast-color($mbsc-windows-success);
    }

    &.mbsc-button-danger.mbsc-button-standard {
      background: $mbsc-windows-danger;
      border-color: $mbsc-windows-danger;
      color: get-contrast-color($mbsc-windows-danger);
    }

    &.mbsc-button-warning.mbsc-button-standard {
      background: $mbsc-windows-warning;
      border-color: $mbsc-windows-warning;
      color: get-contrast-color($mbsc-windows-warning);
    }

    &.mbsc-button-info.mbsc-button-standard {
      background: $mbsc-windows-info;
      border-color: $mbsc-windows-info;
      color: get-contrast-color($mbsc-windows-info);
    }

    &.mbsc-button-dark.mbsc-button-standard {
      background: $mbsc-windows-dark;
      border-color: $mbsc-windows-dark;
      color: get-contrast-color($mbsc-windows-dark);
    }

    &.mbsc-button-light.mbsc-button-standard {
      background: $mbsc-windows-light;
      border-color: $mbsc-windows-light;
      color: $dark-text;
    }

    &.mbsc-button-primary.mbsc-button-flat {
      color: $mbsc-windows-primary;

      &.mbsc-active {
        background: lighten($mbsc-windows-primary, 15%);
        border-color: lighten($mbsc-windows-primary, 15%);
      }
    }

    &.mbsc-button-secondary.mbsc-button-flat {
      color: $mbsc-windows-secondary;

      &.mbsc-active {
        background: lighten($mbsc-windows-secondary, 15%);
        border-color: lighten($mbsc-windows-secondary, 15%);
      }
    }

    &.mbsc-button-success.mbsc-button-flat {
      color: $mbsc-windows-success;

      &.mbsc-active {
        background: lighten($mbsc-windows-success, 15%);
        border-color: lighten($mbsc-windows-success, 15%);
      }
    }

    &.mbsc-button-danger.mbsc-button-flat {
      color: $mbsc-windows-danger;

      &.mbsc-active {
        background: lighten($mbsc-windows-danger, 15%);
        border-color: lighten($mbsc-windows-danger, 15%);
      }
    }

    &.mbsc-button-warning.mbsc-button-flat {
      color: $mbsc-windows-warning;

      &.mbsc-active {
        background: lighten($mbsc-windows-warning, 15%);
        border-color: lighten($mbsc-windows-warning, 15%);
      }
    }

    &.mbsc-button-info.mbsc-button-flat {
      color: $mbsc-windows-info;

      &.mbsc-active {
        background: lighten($mbsc-windows-info, 15%);
        border-color: lighten($mbsc-windows-info, 15%);
      }
    }

    &.mbsc-button-dark.mbsc-button-flat {
      color: $mbsc-windows-dark;

      &.mbsc-active {
        background: lighten($mbsc-windows-dark, 15%);
        border-color: lighten($mbsc-windows-dark, 15%);
      }
    }

    &.mbsc-button-light.mbsc-button-flat {
      color: darken($mbsc-windows-light, 20%);

      &.mbsc-active {
        background: lighten($mbsc-windows-light, 15%);
        border-color: lighten($mbsc-windows-light, 15%);
      }
    }

    &.mbsc-button-primary.mbsc-button-outline {
      border-color: $mbsc-windows-primary;
      color: $mbsc-windows-primary;
    }

    &.mbsc-button-secondary.mbsc-button-outline {
      border-color: $mbsc-windows-secondary;
      color: $mbsc-windows-secondary;
    }

    &.mbsc-button-success.mbsc-button-outline {
      border-color: $mbsc-windows-success;
      color: $mbsc-windows-success;
    }

    &.mbsc-button-danger.mbsc-button-outline {
      border-color: $mbsc-windows-danger;
      color: $mbsc-windows-danger;
    }

    &.mbsc-button-warning.mbsc-button-outline {
      border-color: $mbsc-windows-warning;
      color: $mbsc-windows-warning;
    }

    &.mbsc-button-info.mbsc-button-outline {
      border-color: $mbsc-windows-info;
      color: $mbsc-windows-info;
    }

    &.mbsc-button-dark.mbsc-button-outline {
      border-color: $mbsc-windows-dark;
      color: $mbsc-windows-dark;
    }

    &.mbsc-button-light.mbsc-button-outline {
      border-color: darken($mbsc-windows-light, 20%);
      color: darken($mbsc-windows-light, 20%);
    }

    /* Predefined colors - hover for standard and outline buttons */
    &.mbsc-button-primary.mbsc-button-standard,
    &.mbsc-button-primary.mbsc-button-outline {
      &.mbsc-hover {
        border-color: darken($mbsc-windows-primary, 20%);
      }

      &.mbsc-active {
        background: darken($mbsc-windows-primary, 20%);
        color: $mbsc-windows-light;
      }
    }

    &.mbsc-button-secondary.mbsc-button-standard,
    &.mbsc-button-secondary.mbsc-button-outline {
      &.mbsc-hover {
        border-color: darken($mbsc-windows-secondary, 20%);
      }

      &.mbsc-active {
        background: darken($mbsc-windows-secondary, 20%);
        color: $mbsc-windows-light;
      }
    }

    &.mbsc-button-success.mbsc-button-standard,
    &.mbsc-button-success.mbsc-button-outline {
      &.mbsc-hover {
        border-color: darken($mbsc-windows-success, 20%);
      }

      &.mbsc-active {
        background: darken($mbsc-windows-success, 20%);
        color: $mbsc-windows-light;
      }
    }

    &.mbsc-button-danger.mbsc-button-standard,
    &.mbsc-button-danger.mbsc-button-outline {
      &.mbsc-hover {
        border-color: darken($mbsc-windows-danger, 20%);
      }

      &.mbsc-active {
        background: darken($mbsc-windows-danger, 20%);
        color: $mbsc-windows-light;
      }
    }

    &.mbsc-button-warning.mbsc-button-standard,
    &.mbsc-button-warning.mbsc-button-outline {
      &.mbsc-hover {
        border-color: darken($mbsc-windows-warning, 20%);
      }

      &.mbsc-active {
        background: darken($mbsc-windows-warning, 20%);
        color: $mbsc-windows-light;
      }
    }

    &.mbsc-button-info.mbsc-button-standard,
    &.mbsc-button-info.mbsc-button-outline {
      &.mbsc-hover {
        border-color: darken($mbsc-windows-info, 20%);
      }

      &.mbsc-active {
        background: darken($mbsc-windows-info, 20%);
        color: $mbsc-windows-light;
      }
    }

    &.mbsc-button-dark.mbsc-button-standard,
    &.mbsc-button-dark.mbsc-button-outline {
      &.mbsc-hover {
        border-color: darken($mbsc-windows-dark, 30%);
      }

      &.mbsc-active {
        background: darken($mbsc-windows-dark, 30%);
        color: $mbsc-windows-light;
      }
    }

    &.mbsc-button-light.mbsc-button-standard,
    &.mbsc-button-light.mbsc-button-outline {
      &.mbsc-hover {
        border-color: darken($mbsc-windows-light, 40%);
      }

      &.mbsc-active {
        background: darken($mbsc-windows-light, 40%);
        color: $mbsc-windows-dark;
      }
    }
  }
}

// checkbox component


@mixin mbsc-ios-checkbox($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $bg-param: map-get($params, 'form-background');
  $acc-param: map-get($params, 'form-accent');

  $background: if($bg-param, $bg-param, $background);
  $accent: if($acc-param, $acc-param, $accent);

  $form-selection: '';

  @if (lightness($accent) > 50%) {
    $form-selection: lighten(saturate($accent, 15%), 3%);
  }

  @else {
    $form-selection: darken(desaturate($accent, 15%), 3%);
  }

  .mbsc-#{$theme} {
    &.mbsc-checkbox-box {
      color: $form-selection;
    }
  }
}



@mixin mbsc-material-checkbox($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $bg-param: map-get($params, 'form-background');
  $text-param: map-get($params, 'form-text');
  $acc-param: map-get($params, 'form-accent');

  $background: if($bg-param, $bg-param, $background);
  $text: if($text-param, $text-param, $text);
  $accent: if($acc-param, $acc-param, $accent);

  $checkbox: '';

  @if (lightness($background) > 50%) {
    $checkbox: lighten($background, 7%);
  }

  @else {
    $checkbox: $background;
  }

  .mbsc-#{$theme} {
    &.mbsc-checkbox-box {
      color: $accent;
      border-color: $text;
    }

    &.mbsc-checkbox-box:after {
      border-color: $checkbox;
    }
  }
}



@mixin mbsc-windows-checkbox($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $bg-param: map-get($params, 'form-background');
  $text-param: map-get($params, 'form-text');
  $acc-param: map-get($params, 'form-accent');

  $background: if($bg-param, $bg-param, $background);
  $text: if($text-param, $text-param, $text);
  $accent: if($acc-param, $acc-param, $accent);

  $checkbox-border: '';
  $input-hover: '';

  @if (lightness($background) > 50%) {
    $checkbox-border: desaturate(lighten($accent, 52%), 24%);
    $input-hover: darken($background, 55%);
  }

  @else {
    $checkbox-border: saturate(darken($accent, 52%), 24%);
    $input-hover: lighten($background, 55%);
  }

  .mbsc-#{$theme} {

    &.mbsc-checkbox-box {
      color: $accent;
      border-color: $text;
    }

    &.mbsc-checkbox-box:after {
      border-color: $checkbox-border;
    }

    &.mbsc-checkbox-box:before {
      background: $input-hover;
    }

    &.mbsc-checkbox-box.mbsc-active {
      border-color: $input-hover;
      background: $input-hover;
    }
  }
}

// input component


$mbsc-ios-input-background: $mbsc-input-background-light !default;
$mbsc-ios-input-text: $mbsc-input-text-light !default;
$mbsc-ios-input-accent: $mbsc-input-accent-light !default;
$mbsc-ios-input-border: $mbsc-input-border-light !default;

$mbsc-ios-dark-input-background: $mbsc-input-background-dark !default;
$mbsc-ios-dark-input-text: $mbsc-input-text-dark !default;
$mbsc-ios-dark-input-accent: $mbsc-input-accent-dark !default;
$mbsc-ios-dark-input-border: $mbsc-input-border-dark !default;

$mbsc-ios-colors: map-merge($mbsc-ios-colors, ( // Colors map
    'input-background': $mbsc-ios-input-background,
    'input-text': $mbsc-ios-input-text,
    'input-accent': $mbsc-ios-input-accent,
    'input-border': $mbsc-ios-input-border,
  ));

$mbsc-ios-dark-colors: map-merge($mbsc-ios-dark-colors, ( // Colors map
    'input-background': $mbsc-ios-dark-input-background,
    'input-text': $mbsc-ios-dark-input-text,
    'input-accent': $mbsc-ios-dark-input-accent,
    'input-border': $mbsc-ios-dark-input-border,
  ));

@mixin mbsc-ios-input($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');

  $bg-param: map-get($params, 'input-background');
  $text-param: map-get($params, 'input-text');
  $brd-param: map-get($params, 'input-border');
  $err-param: map-get($params, 'form-error');

  $background: if($bg-param, $bg-param, $background);
  $text: if($text-param, $text-param, $text);
  $error: if($err-param, $err-param, $mbsc-ios-error);

  $is-dark: false;
  $border-color: '';
  $input-background: '';
  $input-background-popup: '';
  $input-tag-background: '';
  $textfield-text: $text;
  $textfield-icon-color: '';

  // Light background
  @if (lightness($background) > 50%) {
    $input-background: lighten($background, 6%);
    $input-background-popup: $input-background;
    $input-tag-background: darken($background, 10%);
    $textfield-icon-color: lighten($text, 54.90);
    $border-color: darken($background, 17%);
  }

  // Dark background
  @else {
    $is-dark: true;
    $input-background: lighten($background, 11%);
    $input-background-popup: lighten($background, 17%);
    $input-tag-background: lighten($background, 23%);
    $textfield-icon-color: $mbsc-ios-dark-text;
    $border-color: lighten($background, 20%);
  }

  $border-color: if($brd-param, $brd-param, $border-color);

  .mbsc-#{$theme} {
    /* Wrapper */

    &.mbsc-textfield-wrapper {
      background: none;
    }

    &.mbsc-textfield-wrapper-underline {
      background: $input-background;
    }

    /* Form element */

    &.mbsc-label,
    &.mbsc-textfield {
      color: $textfield-text;
    }

    &.mbsc-textfield-box,
    &.mbsc-textfield-outline {
      background: $input-background;
    }

    &.mbsc-textfield-outline {
      border-color: $border-color;
    }

    /* Icon */

    &.mbsc-textfield-icon,
    &.mbsc-select-icon {
      color: $textfield-icon-color;
    }

    /* Error */

    &.mbsc-textfield-outline.mbsc-error,
    &.mbsc-textfield-box.mbsc-error {
      border-color: $error;
    }

    &.mbsc-error-message {
      color: $error;
    }

    /* Select */

    &.mbsc-select {
      background: $input-background;
    }

    /* Textarea */

    &.mbsc-textarea-inner.mbsc-textfield-inner-box,
    &.mbsc-textarea-inner.mbsc-textfield-inner-outline {
      background: $input-background;
    }

    &.mbsc-textarea-inner.mbsc-textfield-inner-outline {
      border-color: $border-color;
    }

    &.mbsc-textarea-inner.mbsc-error {
      border-color: $error;
    }

    /* Inside popup */

    @if ($is-dark) {
      &.mbsc-popup &.mbsc-textfield-wrapper {
        background: none;
      }

      &.mbsc-popup &.mbsc-select,
      &.mbsc-popup &.mbsc-textfield-wrapper-underline,
      &.mbsc-popup &.mbsc-textfield-box,
      &.mbsc-popup &.mbsc-textfield-outline,
      &.mbsc-popup &.mbsc-textarea-inner.mbsc-textfield-inner-box,
      &.mbsc-popup &.mbsc-textarea-inner.mbsc-textfield-inner-outline {
        background: $input-background-popup;
      }
    }

    /* Input tags */

    &.mbsc-textfield-tag {
      background: $input-tag-background;
    }

    &.mbsc-textfield-tag-clear {
      color: $textfield-icon-color;
    }

  }
}



$mbsc-material-input-background: $mbsc-input-background-light !default;
$mbsc-material-input-text: $mbsc-input-text-light !default;
$mbsc-material-input-accent: $mbsc-input-accent-light !default;
$mbsc-material-input-border: $mbsc-input-border-light !default;

$mbsc-material-dark-input-background: $mbsc-input-background-dark !default;
$mbsc-material-dark-input-text: $mbsc-input-text-dark !default;
$mbsc-material-dark-input-accent: $mbsc-input-accent-dark !default;
$mbsc-material-dark-input-border: $mbsc-input-border-dark !default;

$mbsc-material-colors: map-merge($mbsc-material-colors, ( // Colors map
    'input-background': $mbsc-material-input-background,
    'input-text': $mbsc-material-input-text,
    'input-accent': $mbsc-material-input-accent,
    'input-border': $mbsc-material-input-border,
  ));

$mbsc-material-dark-colors: map-merge($mbsc-material-dark-colors, ( // Colors map
    'input-background': $mbsc-material-dark-input-background,
    'input-text': $mbsc-material-dark-input-text,
    'input-accent': $mbsc-material-dark-input-accent,
    'input-border': $mbsc-material-dark-input-border,
  ));

@mixin mbsc-material-input($theme, $params) {
  $background: map-get($params, 'background');
  $accent: map-get($params, 'accent');
  $text: map-get($params, 'text');

  $bg-param: map-get($params, 'input-background');
  $text-param: map-get($params, 'input-text');
  $acc-param: map-get($params, 'input-accent');
  $err-param: map-get($params, 'form-error');
  $brd-param: map-get($params, 'input-border');

  $text: if($text-param, $text-param, $text);
  $accent: if($acc-param, $acc-param, $accent);
  $error: if($err-param, $err-param, $mbsc-material-error);

  $input-base-color: '';
  $input-text: '';
  $input-icon-color: '';
  $input-border-color: '';
  $input-label-color: '';
  $input-background: '';
  $input-box-background: '';
  $input-box-hover-background: '';
  $input-box-focus-background: '';
  $input-tag-background: '';

  // Light background
  @if (lightness($background) > 50%) {
    $input-base-color: darken($text, 19%);
    $input-text: rgba($input-base-color, .87);
    $input-border-color: rgba($input-base-color, .42);
    $input-icon-color: rgba($input-base-color, .54);
    $input-label-color: rgba($input-base-color, .6);
    $input-background: if($bg-param, $bg-param, $background);
    $input-box-background: if($bg-param, $bg-param, darken($background, 4%));
    $input-box-hover-background: darken($input-box-background, 3%);
    $input-box-focus-background: darken($input-box-background, 7%);
    $input-tag-background: darken($background, 17%);
  }

  // Dark background
  @else {
    $input-base-color: $text;
    $input-text: $input-base-color;
    $input-border-color: $input-base-color;
    $input-icon-color: $input-base-color;
    $input-label-color: rgba($input-base-color, .6);
    $input-background: if($bg-param, $bg-param, $background);
    $input-box-background: if($bg-param, $bg-param, lighten($background, 4%));
    $input-box-hover-background: lighten($input-box-background, 3%);
    $input-box-focus-background: lighten($input-box-background, 7%);
    $input-tag-background: lighten($background, 27%);
  }

  $input-text: if($text-param, $text-param, $input-text);
  $input-icon-color: if($text-param, $text-param, $input-icon-color);
  $input-label-color: if($text-param, $text-param, $input-label-color);
  $input-border-color: if($brd-param, $brd-param, $input-border-color);

  .mbsc-#{$theme} {

    /* Form element */

    &.mbsc-textfield {
      border-bottom-color: $input-border-color;
      color: $input-text;
    }

    &.mbsc-textfield.mbsc-hover {
      border-color: $input-text;
    }

    &.mbsc-textfield.mbsc-error {
      border-color: $error;
    }

    /* Icon */

    &.mbsc-textfield-icon,
    &.mbsc-select-icon {
      color: $input-icon-color;
    }

    /* Ripple */

    &.mbsc-textfield-ripple {
      background-color: $accent;
    }

    &.mbsc-textfield-ripple.mbsc-error {
      background-color: $error;
    }

    /* Label */

    &.mbsc-label {
      color: $input-label-color;
    }

    &.mbsc-label-stacked.mbsc-focus,
    &.mbsc-label-floating.mbsc-focus {
      color: $accent;
    }

    &.mbsc-label.mbsc-error {
      color: $error;
    }

    /* Error message */

    &.mbsc-error-message {
      color: $error;
    }

    /* Select */

    &.mbsc-select {
      background: $input-background;
    }

    /* Box input ------------------------------------------------------------------------------- */

    &.mbsc-textfield-box.mbsc-select,
    &.mbsc-textfield-inner-box {
      background: $input-box-background;
    }

    &.mbsc-textfield-box.mbsc-select.mbsc-hover,
    &.mbsc-textfield-inner-box.mbsc-hover {
      background: $input-box-hover-background;
    }

    &.mbsc-textfield-box.mbsc-select.mbsc-focus,
    &.mbsc-textfield-inner-box.mbsc-focus {
      background: $input-box-focus-background;
    }

    /* ----------------------------------------------------------------------------------------- */


    /* Outline input --------------------------------------------------------------------------- */

    &.mbsc-textfield-fieldset {
      border-color: $input-border-color;
    }

    &.mbsc-textfield-fieldset.mbsc-hover {
      border-color: $input-text;
    }

    &.mbsc-textfield-fieldset.mbsc-focus {
      border-color: $accent;
    }

    &.mbsc-textfield-fieldset.mbsc-error {
      border-color: $error;
    }

    &.mbsc-textfield-fieldset.disabled {
      border-color: $input-text;
    }

    /* Input tag */

    &.mbsc-textfield-tag {
      background: $input-tag-background;
    }

    &.mbsc-textfield-tag-clear {
      color: $input-icon-color;
    }

    /* ----------------------------------------------------------------------------------------- */
  }
}



$mbsc-windows-input-background: $mbsc-input-background-light !default;
$mbsc-windows-input-text: $mbsc-input-text-light !default;
$mbsc-windows-input-accent: $mbsc-input-accent-light !default;
$mbsc-windows-input-border: $mbsc-input-border-light !default;

$mbsc-windows-dark-input-background: $mbsc-input-background-dark !default;
$mbsc-windows-dark-input-text: $mbsc-input-text-dark !default;
$mbsc-windows-dark-input-accent: $mbsc-input-accent-dark !default;
$mbsc-windows-dark-input-border: $mbsc-input-border-dark !default;

$mbsc-windows-colors: map-merge($mbsc-windows-colors, ( // Colors map
    'input-background': $mbsc-windows-input-background,
    'input-text': $mbsc-windows-input-text,
    'input-accent': $mbsc-windows-input-accent,
    'input-border': $mbsc-windows-input-border,
  ));

$mbsc-windows-dark-colors: map-merge($mbsc-windows-dark-colors, ( // Colors map
    'input-background': $mbsc-windows-dark-input-background,
    'input-text': $mbsc-windows-dark-input-text,
    'input-accent': $mbsc-windows-dark-input-accent,
    'input-border': $mbsc-windows-dark-input-border,
  ));

@mixin mbsc-windows-input($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $bg-param: map-get($params, 'input-background');
  $text-param: map-get($params, 'input-text');
  $brd-param: map-get($params, 'input-border');
  $acc-param: map-get($params, 'input-accent');
  $err-param: map-get($params, 'form-error');

  $background: if($bg-param, $bg-param, $background);
  $text: if($text-param, $text-param, $text);
  $accent: if($acc-param, $acc-param, $accent);
  $error: if($err-param, $err-param, $mbsc-windows-error);

  $input-backgound: $background;
  $input-hover: $text;
  $input-border: '';
  $input-disabled-background: '';
  $input-disabled-color: '';
  $input-tag-background: '';

  // Light background
  @if (lightness($background) > 50%) {
    $input-border: lighten($text, 33%);
    $input-disabled-background: darken($background, 5%);
    $input-disabled-color: lighten($text, 42%);
    $input-tag-background: darken($background, 10%);
  }

  // Dark background
  @else {
    $input-border: darken($text, 33%);
    $input-disabled-background: lighten($background, 5%);
    $input-disabled-color: darken($text, 42%);
    $input-tag-background: lighten($background, 27%);
  }

  $input-border: if($brd-param, $brd-param, $input-border);

  .mbsc-#{$theme} {

    /* Wrapper */

    &.mbsc-textfield-wrapper {
      color: $text;
    }

    &.mbsc-textfield-wrapper.mbsc-disabled {
      color: $input-disabled-color;
    }

    /* Form element */

    &.mbsc-textfield {
      background: $input-backgound;
      color: $text;
    }

    &.mbsc-textfield-box,
    &.mbsc-textfield-outline {
      border-color: $input-border;
    }

    &.mbsc-textfield.mbsc-hover {
      border-color: $input-hover;
    }

    &.mbsc-textfield-box.mbsc-focus,
    &.mbsc-textfield-outline.mbsc-focus {
      border-color: $accent;
      box-shadow: 0 0 0 1px $accent inset;
    }

    &.mbsc-textfield.mbsc-disabled {
      background: $input-disabled-background;
      border-color: $input-disabled-background;
    }

    &.mbsc-textfield.mbsc-error {
      border-color: $error;
    }

    &.mbsc-textfield-box.mbsc-error.mbsc-focus,
    &.mbsc-textfield-outline.mbsc-error.mbsc-focus {
      box-shadow: 0 0 0 1px $error inset;
    }

    /* Error message */

    &.mbsc-error-message {
      color: $error;
    }

    /* Label */

    &.mbsc-label {
      color: $text;
    }

    /* Underline input --------------------------------------------------------------------------- */

    &.mbsc-textfield-wrapper-underline {
      border-color: $input-border;
    }

    &.mbsc-textfield-wrapper-underline.mbsc-hover {
      border-color: $input-hover;
    }

    &.mbsc-textfield-wrapper-underline.mbsc-focus {
      border-color: $accent;
      box-shadow: 0 1px 0 0 $accent;
    }

    &.mbsc-textfield-wrapper-underline.mbsc-disabled {
      border-color: $input-disabled-background;
    }

    &.mbsc-textfield-wrapper-underline.mbsc-error {
      border-color: $error;
    }

    &.mbsc-textfield-wrapper-underline.mbsc-error.mbsc-focus {
      box-shadow: 0 1px 0 0 $error;
    }

    /* ----------------------------------------------------------------------------------------- */

    /* Input tags */

    &.mbsc-textfield-tag {
      background: $input-tag-background;
    }

  }
}

// page component


// Theme specific variables - inherited from global variables

// background
$mbsc-ios-page-background: $mbsc-page-background-light !default;
$mbsc-ios-dark-page-background: $mbsc-page-background-dark !default;

// text
$mbsc-ios-page-text: $mbsc-page-text-light !default;
$mbsc-ios-dark-page-text: $mbsc-page-text-dark !default;

// add variables to color maps
$mbsc-ios-colors: map-merge($mbsc-ios-colors, ('page-background': $mbsc-ios-page-background,
    'page-text': $mbsc-ios-page-text,
  ));

$mbsc-ios-dark-colors: map-merge($mbsc-ios-dark-colors, ('page-background': $mbsc-ios-dark-page-background,
    'page-text': $mbsc-ios-dark-page-text,
  ));

@mixin mbsc-ios-page($theme, $params) {

  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $title-text: '';

  @if (lightness($background) > 50%) {
    // $background: darken($background, 2%);
    $background: adjust-hue(darken(saturate($background, 24%), 1%), 240deg);
    $title-text: lighten($text, 43%);
  }

  @else {
    // $background: adjust-hue(lighten(desaturate($background, 19%), 10%), 240deg);
    // $background: lighten($background, 2%);
    $title-text: darken($text, 43%);
  }

  // get custom params
  $text-param: map-get($params, 'page-text');
  $background-param: map-get($params, 'page-background');
  // overwrite params with custom variables
  $background: if($background-param, $background-param, $background);
  $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));

  .mbsc-#{$theme} {
    &.mbsc-page {
      background: $background;
      color: $text;
    }

    .mbsc-block-title,
    .mbsc-form-group-title {
      color: $title-text;
    }

    a {
      color: $accent;
    }
  }
}



// Theme specific variables - inherited from global variables

// background
$mbsc-material-page-background: $mbsc-page-background-light !default;
$mbsc-material-dark-page-background: $mbsc-page-background-dark !default;
// text
$mbsc-material-page-text: $mbsc-page-text-light !default;
$mbsc-material-dark-page-text: $mbsc-page-text-dark !default;

// add variablest to color maps
$mbsc-material-colors: map-merge($mbsc-material-colors, ('page-background': $mbsc-material-page-background,
    'page-text': $mbsc-material-page-text,
  ));

$mbsc-material-dark-colors: map-merge($mbsc-material-dark-colors, ('page-background': $mbsc-material-dark-page-background,
    'page-text': $mbsc-material-dark-page-text,
  ));

@mixin mbsc-material-page($theme, $params) {

  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  .mbsc-#{$theme} {
    &.mbsc-page {
      background-color: $background;
      color: $text;
    }

    .mbsc-block-title,
    .mbsc-form-group-title {
      color: $accent;
    }

    a {
      color: $accent;
    }
  }
}



// Theme specific variables - inherited from global variables

// background
$mbsc-windows-page-background: $mbsc-page-background-light !default;
$mbsc-windows-dark-page-background: $mbsc-page-background-dark !default;

// text
$mbsc-windows-page-text: $mbsc-page-text-light !default;
$mbsc-windows-dark-page-text: $mbsc-page-text-dark !default;

// add variables to color maps
$mbsc-windows-colors: map-merge($mbsc-windows-colors, ( //
    'page-background': $mbsc-windows-page-background,
    'page-text': $mbsc-windows-page-text,
  ));

$mbsc-windows-dark-colors: map-merge($mbsc-windows-dark-colors, ( //
    'page-background': $mbsc-windows-dark-page-background,
    'page-text': $mbsc-windows-dark-page-text,
  ));

@mixin mbsc-windows-page($theme, $params) {

  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  .mbsc-#{$theme} {
    &.mbsc-page {
      background-color: $background;
      color: $text;
    }

    .mbsc-block-title,
    .mbsc-form-group-title {
      color: $text;
    }

    a {
      color: $accent;
    }
  }
}

// radio component


@mixin mbsc-ios-radio($theme, $params) {
  $background: map-get($params, 'background');
  $accent: map-get($params, 'accent');

  $accent-param: map-get($params, 'form-accent');
  $bg-param: map-get($params, 'form-background');

  $accent: if($accent-param, $accent-param, $accent);
  $background: if($bg-param, $bg-param, $background);

  $disabled: '';

  @if (lightness($background) > 50%) {
    $disabled: darken($background, 20%);
  }

  @else {
    $disabled: lighten($background, 23%);
  }

  $form-selection: '';

  @if (lightness($accent) > 50%) {
    $form-selection: lighten(saturate($accent, 15%), 3%);
  }

  @else {
    $form-selection: darken(desaturate($accent, 15%), 3%);
  }

  .mbsc-#{$theme} {
    &.mbsc-radio-box:after {
      border-color: $form-selection;
    }

    &.mbsc-radio-label.mbsc-disabled {
      color: $disabled;
    }
  }
}



@mixin mbsc-material-radio($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $accent-param: map-get($params, 'form-accent');
  $text-param: map-get($params, 'form-text');

  $accent: if($accent-param, $accent-param, $accent);
  $text: if($text-param, $text-param, $text);

  .mbsc-#{$theme} {
    &.mbsc-radio-box {
      color: $accent;
      border-color: $text;
    }
  }
}



@mixin mbsc-windows-radio($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $text-param: map-get($params, 'form-text');
  $accent-param: map-get($params, 'form-accent');

  $accent: if($accent-param, $accent-param, $accent);
  $text: if($text-param, $text-param, $text);

  .mbsc-#{$theme} {
    &.mbsc-radio-box {
      border: .125em solid $text;
    }

    &.mbsc-radio-box:after {
      background: $text;
    }

    &.mbsc-radio-box.mbsc-checked {
      border-color: $accent;
    }

    &.mbsc-radio-box.mbsc-active {
      border-color: rgba($text, .6);
    }

    &.mbsc-radio-box.mbsc-active:after {
      background: rgba($text, .6);
    }
  }
}

// segmented component


@mixin mbsc-ios-segmented($theme, $params) {
  $background: map-get($params, 'background');
  $accent: map-get($params, 'accent');

  $bg-param: map-get($params, 'form-background');
  $acc-param: map-get($params, 'form-accent');

  $background: if($bg-param, $bg-param, $background);
  $accent: if($acc-param, $acc-param, $accent);

  $is-dark: false;
  $selectbox: '';
  $selectbox-contrast: '';
  $button-background: '';
  $shadow: '';

  @if (lightness($background) > 50%) {
    $button-background: darken($background, 10%);
    $selectbox: #fff;
    $selectbox-contrast: #000;
    $shadow: darken($background, 24%);
  }

  @else {
    $is-dark: true;
    $button-background: lighten($background, 11%);
    $selectbox: #5a5a5a;
    $selectbox-contrast: #fff;
    $shadow: $background;
  }

  .mbsc-#{$theme} {
    &.mbsc-segmented {
      background: $button-background;
    }

    &.mbsc-segmented-item:before {
      border-color: rgba($selectbox-contrast, .2);
    }

    &.mbsc-segmented-item.mbsc-focus .mbsc-segmented-selectbox {
      box-shadow: 0 0 0 .0625em rgba($selectbox-contrast, .5) inset;
    }

    &.mbsc-segmented-selectbox-inner {
      background: $selectbox;

      &.mbsc-selected {
        box-shadow: $shadow 3px 3px 8px -4px;
      }
    }

    &.mbsc-segmented-button.mbsc-button {
      background: transparent;
      color: $selectbox-contrast;
    }

    /* Inside popup and calendar header */
    @if ($is-dark) {

      &.mbsc-datepicker .mbsc-segmented,
      &.mbsc-popup &.mbsc-segmented,
      &.mbsc-calendar-header &.mbsc-segmented {
        background: lighten($background, 17%);
      }
    }

    /* Color presets */

    &.mbsc-segmented-primary {
      background: $mbsc-ios-primary;
    }

    &.mbsc-segmented-secondary {
      background: $mbsc-ios-secondary;
    }

    &.mbsc-segmented-success {
      background: $mbsc-ios-success;
    }

    &.mbsc-segmented-warning {
      background: $mbsc-ios-warning;
    }

    &.mbsc-segmented-danger {
      background: $mbsc-ios-danger;
    }

    &.mbsc-segmented-info {
      background: $mbsc-ios-info;
    }

    &.mbsc-segmented-light {
      background: $mbsc-ios-light;
    }

    &.mbsc-segmented-dark {
      background: $mbsc-ios-dark;
    }
  }
}



@mixin mbsc-material-segmented($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $bg-param: map-get($params, 'form-background');
  $acc-param: map-get($params, 'form-accent');
  $text-param: map-get($params, 'form-text');
  $background: if($bg-param, $bg-param, $background);
  $accent: if($acc-param, $acc-param, $accent);
  $text: if($text-param, $text-param, $text);

  $button-color: '';
  $button-background: '';
  $button-text: '';

  @if (lightness($background) > 50%) {
    $button-background: darken($background, 19%);
    $button-color: darken($text, 36%);
    $button-text: darken($text, 36%);
  }

  @else {
    $button-background: lighten($background, 17%);
    $button-color: lighten($text, 24%);
    $button-text: lighten($text, 24%);
  }

  .mbsc-#{$theme} {

    &.mbsc-segmented-button.mbsc-button {
      border-color: $accent;
      color: $button-text;

      &.mbsc-hover,
      &.mbsc-active {
        background: rgba($button-color, .2);
      }

      &.mbsc-selected {
        background: $accent;
        color: $background;
      }

      &.mbsc-focus::after {
        background: rgba($button-text, .2);
      }
    }
  }
}



@mixin mbsc-windows-segmented($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $bg-param: map-get($params, 'form-background');
  $text-param: map-get($params, 'form-text');
  $acc-param: map-get($params, 'form-accent');

  $background: if($bg-param, $bg-param, $background);
  $text: if($text-param, $text-param, $text);
  $accent: if($acc-param, $acc-param, $accent);

  $button-border: '';
  $button-hover: '';
  $button-active: '';
  $border: '';
  $form-background: '';
  $button-bg: '';

  @if (lightness($background) > 50%) {
    $button-border: darken($background, 40%);
    $button-hover: darken($background, 17%);
    $button-active: lighten($text, 28%);
    $button-bg: darken($background, 20%);
    $border: darken($background, 10%);
    $form-background: lighten($background, 13%);
  }

  @else {
    $button-border: lighten($background, 35%);
    $button-active: darken($text, 33%);
    $button-hover: lighten($background, 17%);
    $button-bg: lighten($background, 15%);
    $border: lighten($background, 10%);
    $form-background: darken($background, 12%);
  }

  .mbsc-#{$theme} {
    &.mbsc-segmented-button.mbsc-button {
      background: $button-bg;
      color: $text;

      &.mbsc-hover {
        background: $button-hover;
      }

      &.mbsc-active {
        background: $button-hover;
      }

      &.mbsc-selected {
        background: $button-active;
        color: $form-background;
      }
    }
  }
}

// switch component


@mixin mbsc-ios-switch($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');

  $bg-param: map-get($params, 'form-background');
  $ac-param: map-get($params, 'form-accent');
  $text-param: map-get($params, 'form-text');

  $background: if($bg-param, $bg-param, $background);
  $switch-track-checked: if($ac-param, $ac-param, #4cd764);
  $text: if($text-param, $text-param, $text);

  $switch-track: '';

  @if (lightness($background) > 50%) {
    $switch-track: darken($background, 7%);
  }

  @else {
    $switch-track: lighten($background, 22%);
  }

  .mbsc-#{$theme} {
    &.mbsc-switch-track {

      // when the switch is not checked
      &:after {
        background: $switch-track;
      }

      &.mbsc-focus:after {
        box-shadow: 0 0 0 .125em rgba($text, .5);
      }

      // when the switch is checked
      &.mbsc-checked:after {
        background: $switch-track-checked;
      }
    }
  }
}



@mixin mbsc-material-switch($theme, $params) {
  $background: map-get($params, 'background');
  $accent: map-get($params, 'accent');

  $bg-param: map-get($params, 'form-background');
  $ac-param: map-get($params, 'form-accent');

  $background: if($bg-param, $bg-param, $background);
  $accent: if($ac-param, $ac-param, $accent);

  $track: '';
  $track-disabled: '';
  $handle-disabled: '';
  $handle: '';
  $bg-contrast: '';

  @if (lightness($background) > 50%) {
    $track: darken($background, 23%);
    $track-disabled: darken($background, 9%);
    $handle-disabled: darken($background, 20%);
    $handle: lighten($background, 5%);
    $bg-contrast: #000;
  }

  @else {
    $track: lighten($background, 17%);
    $track-disabled: lighten($background, 17%);
    $handle-disabled: lighten($background, 16%);
    $handle: lighten($background, 53%);
    $bg-contrast: #fff;
  }

  .mbsc-#{$theme} {
    &.mbsc-switch-track {
      background: $track;

      &.mbsc-checked {
        background: rgba($accent, .3);
      }

      &.mbsc-disabled {
        background: $track-disabled;
      }
    }


    &.mbsc-switch-handle {
      background: $handle;
      box-shadow: 0 3px 1px -2px rgba($bg-contrast, 0.2), 0 1px 5px 0 rgba($bg-contrast, 0.12);

      &.mbsc-checked {
        background: $accent;
      }

      &.mbsc-disabled {
        background: $handle-disabled;
      }
    }

    &.mbsc-switch-handle:before {
      background: rgba($bg-contrast, 0.1);
    }
  }
}



@mixin mbsc-windows-switch($theme, $params) {
  $background: map-get($params, 'background');
  $accent: map-get($params, 'accent');
  $text: map-get($params, 'text');

  $bg-param: map-get($params, 'form-background');
  $ac-param: map-get($params, 'form-accent');
  $text-param: map-get($params, 'form-text');

  $background: if($bg-param, $bg-param, $background);
  $accent: if($ac-param, $ac-param, $accent);
  $text: if($text-param, $text-param, $text);

  $active: '';
  $focus: '';

  @if (lightness($background) > 50%) {
    $active: darken($background, 55%);
    $focus: lighten($text, 45%);
  }

  @else {
    $active: lighten($background, 55%);
    $focus: darken($text, 45%);
  }

  .mbsc-#{$theme} {

    // Track

    &.mbsc-switch-track {

      &.mbsc-checked:before {
        border-color: $accent;
        background: $accent;
      }

      &:before,
      &.mbsc-disabled:before {
        border-color: $text;
        background: none;
      }

      &.mbsc-active:before {
        border-color: $active;
        background: $active;
      }

      &.mbsc-focus:after {
        border-color: $focus;
      }
    }

    // Handle

    &.mbsc-switch-handle {
      background: $text;

      &.mbsc-checked {
        background: $background;
      }

      &.mbsc-disabled {
        background: $text;
      }
    }
  }
}

// eventcalendar component


@mixin mbsc-ios-eventcalendar($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $background-param: map-get($params, 'calendar-background');
  $text-param: map-get($params, 'calendar-text');
  $accent-param: map-get($params, 'calendar-accent');
  $border-param: map-get($params, 'calendar-border');
  $event-param: map-get($params, 'calendar-event');

  $background: if($background-param, $background-param, $background);
  $text: if($text-param, $text-param, if($background-param, get-contrast-color($background), $text));
  $accent: if($accent-param, $accent-param, $accent);
  $event: if($event-param, $event-param, #5AC8FA);

  $is-dark: false;
  $background-alt: '';
  $background-contrast: '';
  $background-header: '';
  $border-color: '';
  $title-color: '';
  $schedule-time-color: '';
  $cell-disabled-bg: '';
  $cell-disabled-color: '';

  // Light background 
  @if (lightness($background) > 50%) {
    $background-alt: lighten($background, 3%);
    $background-contrast: #000;
    $background-header: $background;
    $border-color: darken($background, 17%);
    $schedule-time-color: lighten($mbsc-ios-text, 54.90);
    $title-color: adjust-hue(lighten(saturate($text, 2%), 44%), 240deg);
    $cell-disabled-bg: darken($background-alt, 6.27);
    $cell-disabled-color: darken($cell-disabled-bg, 40.39);
  }

  // Dark background 
  @else {
    $is-dark: true;
    $background-alt: $background;
    $background-contrast: #fff;
    $background-header: lighten($background, 11%);
    $border-color: lighten($background, 20%);
    $schedule-time-color: $mbsc-ios-dark-text;
    $title-color: adjust-hue(darken(desaturate($text, 2%), 44%), 240deg);
    $cell-disabled-bg: lighten($background-alt, 10);
    $cell-disabled-color: lighten($cell-disabled-bg, 37.25);
  }

  $border-color: if($border-param, $border-param, $border-color);

  .mbsc-#{$theme} {

    /* Calendar view */
    &.mbsc-eventcalendar {

      .mbsc-calendar-header,
      .mbsc-calendar-week-days {
        background: $background-header;
      }

      .mbsc-calendar-day.mbsc-disabled {
        background: $cell-disabled-bg;
      }
    }

    /* Agenda view */

    &.mbsc-event-list-empty {
      color: $title-color;
    }

    &.mbsc-event-day.mbsc-list-header {
      background: $background-alt;
      border-color: $border-color;
      color: $text;
    }

    &.mbsc-event.mbsc-list-item {
      background: $background-alt;
      color: $text;
    }

    &.mbsc-event.mbsc-list-item:before,
    &.mbsc-event.mbsc-list-item:after {
      border-color: $border-color;
    }

    &.mbsc-event.mbsc-list-item.mbsc-focus:before,
    &.mbsc-event.mbsc-list-item.mbsc-hover:before {
      background: rgba($background-contrast, .05);
    }

    &.mbsc-event-color {
      background: $event;
    }

    /* Popover events */

    @if ($is-dark) {
      &.mbsc-popover-list &.mbsc-event {
        background: lighten($background, 17%);
      }
    }

    /* Schedule view */

    /* TODO: refactor this without cascade */
    &.mbsc-eventcalendar-schedule .mbsc-calendar-day {
      background: $background-header;
    }

    &.mbsc-schedule-wrapper {
      background: $background-header;
    }

    /* Header */

    &.mbsc-schedule-header,
    &.mbsc-schedule-header-item {
      border-color: $border-color;
    }

    &.mbsc-schedule-header-day-today {
      color: $accent;
    }

    &.mbsc-schedule-header-day.mbsc-hover {
      background: rgba($accent, .3);
    }

    &.mbsc-schedule-header-dayname-curr,
    &.mbsc-schedule-header-day.mbsc-selected {
      color: $accent;
    }

    &.mbsc-schedule-header-day.mbsc-selected {
      background: $accent;
      color: $background-alt;
    }

    &.mbsc-schedule-header-dayname-curr {
      color: $text;
    }

    &.mbsc-schedule-date-header {
      background: $background-header;
      border-color: $border-color;
    }

    /* Grid & All-day row */
    &.mbsc-schedule-grid-wrapper {
      background: $background-alt;
    }

    &.mbsc-schedule-all-day-wrapper {
      border-color: $border-color;
      background: $background-alt;
    }

    &.mbsc-schedule-all-day-text {
      color: $schedule-time-color;
    }

    &.mbsc-timeline-day::after,
    &.mbsc-timeline-slots,
    &.mbsc-timeline-slot-header,
    &.mbsc-timeline-header-month,
    &.mbsc-timeline-header-week,
    &.mbsc-timeline-header-date,
    &.mbsc-timeline-resource-empty,
    &.mbsc-timeline-header-column,
    &.mbsc-timeline-header,
    &.mbsc-timeline-resource,
    &.mbsc-timeline-column,
    &.mbsc-schedule-resource-group,
    &.mbsc-schedule-resource,
    &.mbsc-schedule-all-day-item::after,
    &.mbsc-schedule-column,
    &.mbsc-schedule-item {
      border-color: $border-color;
    }

    &.mbsc-cursor-indicator,
    &.mbsc-timeline-header-column,
    &.mbsc-schedule-time {
      color: $schedule-time-color;
    }

    /* Event style */

    &.mbsc-schedule-event {
      color: $event;
    }

    &.mbsc-schedule-event-title,
    &.mbsc-schedule-event-range {
      color: $background-contrast;
    }

    &.mbsc-schedule-event-active,
    &.mbsc-schedule-event-dragging {
      .mbsc-schedule-event-inner {
        color: get-contrast-color($event);
      }

      .mbsc-schedule-event-title,
      .mbsc-schedule-event-range {
        color: inherit;
      }
    }

    &.mbsc-schedule-time-indicator {
      border-color: $accent;
    }

    &.mbsc-schedule-time-indicator-day:before {
      background: $accent;
    }

    &.mbsc-cursor-indicator-time,
    &.mbsc-schedule-time-indicator-time {
      background: rgba($background-alt, .8);
    }

    &.mbsc-schedule-time-indicator-time {
      color: $accent;
    }

    /* Invalid */

    &.mbsc-schedule-invalid {
      background: rgba($cell-disabled-bg, 0.75);
      color: $cell-disabled-color;
    }

    /* Timeline */

    &.mbsc-timeline-resource-empty,
    &.mbsc-timeline-resource-bg {
      background: $background-alt;
    }

  }
}



@mixin mbsc-material-eventcalendar($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $background-param: map-get($params, 'calendar-background');
  $text-param: map-get($params, 'calendar-text');
  $accent-param: map-get($params, 'calendar-accent');
  $border-param: map-get($params, 'calendar-border');
  $event-param: map-get($params, 'calendar-event');

  $background: if($background-param, $background-param, $background);
  $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
  $accent: if($accent-param, $accent-param, $accent);
  $event: if($event-param, $event-param, $accent);

  $border: '';
  $schedule-text-color: '';
  $cell-disabled-bg: '';
  $cell-disabled-color: '';

  // Light background
  @if (lightness($background) > 50%) {
    $border: darken($background, 19%);
    $schedule-text-color: lighten(saturate(adjust-hue($text, 210deg), 4.27), 27.06);
    $cell-disabled-bg: darken($background, 6);
    $cell-disabled-color: darken($cell-disabled-bg, 40);
  }

  // Dark background
  @else {
    $border: lighten($background, 17%);
    $schedule-text-color: darken(saturate(adjust-hue($text, 210deg), 6.32), 13.33);
    $cell-disabled-bg: lighten($background, 10);
    $cell-disabled-color: lighten($cell-disabled-bg, 37);
  }

  $border: if($border-param, $border-param, $border);

  .mbsc-#{$theme} {
    &.mbsc-eventcalendar {
      background: $background;
      color: $text;

      .mbsc-calendar-day.mbsc-disabled {
        background: $cell-disabled-bg;
      }
    }

    &.mbsc-event-list-empty {
      color: rgba($text, .7);
    }

    &.mbsc-schedule-date-header,
    &.mbsc-event-day.mbsc-list-header {
      background: $background;
      color: rgba($text, .7);
    }

    &.mbsc-colored-event.mbsc-list-item {
      background: $accent;
      color: get-contrast-color($accent);
    }

    &.mbsc-event.mbsc-list-item.mbsc-focus {
      box-shadow: 0 .25em .5em 0 rgba(0, 0, 0, 0.4);
    }

    &.mbsc-event-color {
      background: $accent;
    }

    /* Schedule */

    /* Header */

    &.mbsc-schedule-header-dayname {
      color: $schedule-text-color;
    }

    &.mbsc-schedule-header-day {
      color: $text;
    }

    &.mbsc-schedule-header-dayname-curr {
      color: $accent;
    }

    &.mbsc-schedule-header-day-today {
      color: $accent;
    }

    &.mbsc-schedule-header-day.mbsc-hover {
      background: rgba($accent, .3);
    }

    &.mbsc-schedule-header-day.mbsc-selected {
      background-color: $accent;
      color: $background;
    }


    /* Grid & All-day cont */
    &.mbsc-timeline-day::after,
    &.mbsc-timeline-slots,
    &.mbsc-timeline-slot-header,
    &.mbsc-timeline-header-month,
    &.mbsc-timeline-header-week,
    &.mbsc-timeline-header-date,
    &.mbsc-timeline-resource-empty,
    &.mbsc-timeline-header-column,
    &.mbsc-timeline-header,
    &.mbsc-timeline-resource,
    &.mbsc-timeline-column,
    &.mbsc-schedule-resource-group,
    &.mbsc-schedule-resource,
    &.mbsc-schedule-time-col,
    &.mbsc-schedule-all-day-item::after,
    &.mbsc-schedule-column,
    &.mbsc-schedule-item {
      border-color: $border;
    }

    &.mbsc-schedule-time-wrapper-end:before,
    &.mbsc-schedule-time-wrapper:after {
      border-bottom: 1px solid $border;
    }

    &.mbsc-schedule-all-day-text,
    &.mbsc-schedule-time {
      color: $schedule-text-color;
    }

    &.mbsc-schedule-time-indicator {
      border-color: $accent;
    }

    &.mbsc-schedule-time-indicator-day:before {
      background: $accent;
    }

    &.mbsc-cursor-indicator-time,
    &.mbsc-schedule-time-indicator-time {
      background: rgba($background, .8);
    }

    &.mbsc-schedule-time-indicator-time {
      color: $accent;
    }

    /* Event style */

    &.mbsc-schedule-event {
      color: $event;
    }

    &.mbsc-schedule-event-inner {
      color: get-contrast-color($event);
    }

    /* Invalid */
    &.mbsc-schedule-invalid {
      background: rgba($cell-disabled-bg, 0.75);
      color: $cell-disabled-color;
    }

    /* Timeline */

    &.mbsc-timeline-resource-empty,
    &.mbsc-timeline-resource-bg {
      background: $background;
    }

  }
}



@mixin mbsc-windows-eventcalendar($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $background-param: map-get($params, 'calendar-background');
  $text-param: map-get($params, 'calendar-text');
  $accent-param: map-get($params, 'calendar-accent');
  $border-param: map-get($params, 'calendar-border');
  $event-param: map-get($params, 'calendar-event');

  $background: if($background-param, $background-param, $background);
  $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
  $accent: if($accent-param, $accent-param, $accent);
  $event: if($event-param, $event-param, $accent);

  $border: '';
  $active: '';
  $hover: '';
  $cell-disabled-bg: '';
  $cell-disabled-color: '';

  @if (lightness($background) > 50%) {
    $border: darken($background, 10%);
    $active: lighten($text, 60%);
    $hover: lighten($text, 70%);
    $cell-disabled-bg: darken($background, 6);
    $cell-disabled-color: darken($cell-disabled-bg, 40);

  }

  @else {
    $border: lighten($background, 10%);
    $active: darken($text, 60%);
    $hover: darken($text, 70%);
    $cell-disabled-bg: lighten($background, 10);
    $cell-disabled-color: lighten($cell-disabled-bg, 37);
  }

  $border: if($border-param, $border-param, $border);

  .mbsc-#{$theme} {
    &.mbsc-eventcalendar {
      background: $background;

      .mbsc-calendar-day.mbsc-disabled {
        background: $cell-disabled-bg;
      }
    }

    /* Event listing */

    &.mbsc-event-list-empty {
      color: rgba($text, .7);
    }

    &.mbsc-event-group {
      border-color: $border;
    }

    &.mbsc-event-day.mbsc-list-header,
    &.mbsc-event.mbsc-list-item {
      background: $background;
      color: $text;
    }

    &.mbsc-event.mbsc-list-item.mbsc-hover:before {
      background: $hover;
    }

    &.mbsc-event.mbsc-list-item.mbsc-active:before {
      background: $active;
    }

    &.mbsc-event-color {
      background: $accent;
    }

    /* Schedule view */

    &.mbsc-schedule-wrapper {
      background: $background;
    }

    /* Header */

    &.mbsc-schedule-header-day {
      border-color: $border;
    }

    &.mbsc-schedule-header-item {
      color: $text;
    }

    &.mbsc-schedule-header-day-today {
      color: $accent;
    }

    &.mbsc-schedule-header-dayname.mbsc-selected:after {
      background: $accent;
    }

    &.mbsc-schedule-date-header {
      border-color: $border;
    }

    /* Time indicator */

    &.mbsc-schedule-time-indicator,
    &.mbsc-schedule-time-indicator-day {
      border-color: $accent;
    }

    &.mbsc-cursor-indicator-time,
    &.mbsc-schedule-time-indicator-time {
      background: rgba($background, .8);
      color: $accent;
    }

    &.mbsc-schedule-time-indicator-time {
      color: $accent;
    }

    /* Grid & All-day row */
    &.mbsc-timeline-day::after,
    &.mbsc-timeline-slots,
    &.mbsc-timeline-slot-header,
    &.mbsc-timeline-header-month,
    &.mbsc-timeline-header-week,
    &.mbsc-timeline-header-date,
    &.mbsc-timeline-resource-empty,
    &.mbsc-timeline-header-column,
    &.mbsc-timeline-header,
    &.mbsc-timeline-resource,
    &.mbsc-timeline-column,
    &.mbsc-schedule-resource-group,
    &.mbsc-schedule-resource,
    &.mbsc-schedule-time-col,
    &.mbsc-schedule-all-day-item::after,
    &.mbsc-schedule-column,
    &.mbsc-schedule-item {
      border-color: $border;
    }

    &.mbsc-schedule-time-wrapper {
      border-top: 1px solid $border;
      color: $text;
    }

    &.mbsc-schedule-time-wrapper-end {
      border-bottom: 1px solid $border;
      color: $text;
    }

    &.mbsc-schedule-all-day-text,
    &.mbsc-schedule-time-cont {
      color: $text;
    }

    /* Event style */

    &.mbsc-schedule-event {
      color: $event;
    }

    &.mbsc-schedule-event-inner {
      color: get-contrast-color($event);
    }

    &.mbsc-schedule-event-dragging,
    &.mbsc-schedule-event-active {
      .mbsc-schedule-event-background {
        outline: 1px solid $text;
      }
    }

    /* Invalid */

    &.mbsc-schedule-invalid {
      background: rgba($cell-disabled-bg, 0.75);
      color: $cell-disabled-color;
    }

    /* Timeline */

    &.mbsc-timeline-resource-empty,
    &.mbsc-timeline-resource-bg {
      background: $background;
    }
  }
}

// calendar-view component


@mixin mbsc-ios-calendar-view($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $background-param: map-get($params, 'calendar-background');
  $text-param: map-get($params, 'calendar-text');
  $accent-param: map-get($params, 'calendar-accent');
  $border-param: map-get($params, 'calendar-border');
  $mark-param: map-get($params, 'calendar-mark');
  $event-param: map-get($params, 'calendar-event');

  $background: if($background-param, $background-param, $background);
  $text: if($text-param, $text-param, if($background-param, get-contrast-color($background), $text));
  $accent: if($accent-param, $accent-param, $accent);
  $event: if($event-param, $event-param, #5AC8FA);

  $background-alt: '';
  $border-color: '';
  $popup-background: '';
  $popup-cell: '';
  $hover: '';

  @if (lightness($background) > 50%) {
    $background-alt: lighten($background, 3%);
    $border-color: darken($background, 17%);
    $popup-background: $background-alt;
    $popup-cell: $background-alt;
    $hover: darken($background, 10%);
  }

  @else {
    $background-alt: $background;
    $border-color: lighten($background, 20%);
    $popup-background: lighten($background, 16%);
    $popup-cell: lighten($background, 11%);
    $hover: lighten($background, 17%);
  }

  $highlight: '';
  $highlight-contrast: '';

  @if (lightness($background) > 50%) {
    $highlight: lighten(desaturate($accent, 14%), 39%);
    $highlight-contrast: #000;
  }

  @else {
    $highlight: darken(desaturate($accent, 14%), 39%);
    $highlight-contrast: #fff;
  }

  $border-color: if($border-param, $border-param, $border-color);
  $mark: if($mark-param, $mark-param, $border-color);

  .mbsc-#{$theme} {
    &.mbsc-calendar {
      background: $background-alt;
      color: $text;
    }

    &.mbsc-calendar-wrapper {
      border-color: $border-color;
    }

    &.mbsc-calendar-header {
      border-color: $border-color;
    }

    &.mbsc-calendar-button.mbsc-button {
      color: $accent;
    }

    &.mbsc-calendar-cell {
      background: $background-alt;
      border-color: $border-color;
      color: $text;
    }

    &.mbsc-calendar-week-nr,
    &.mbsc-calendar-today {
      color: $accent;
    }

    &.mbsc-hover .mbsc-calendar-cell-text {
      background-color: rgba($accent, .3);
    }

    /* range highlight and selection */

    &.mbsc-range-day::after {
      background-color: $highlight;
    }

    &.mbsc-range-day .mbsc-calendar-cell-text {
      color: $highlight-contrast;
    }

    &.mbsc-range-hover::before {
      border-color: $hover;
    }

    &.mbsc-selected .mbsc-calendar-cell-text {
      border-color: $accent;
      background: $accent;
      color: get-contrast-color($accent);
    }

    &.mbsc-focus .mbsc-calendar-cell-text {
      box-shadow: 0 0 0 2px rgba($text, .5);
    }

    &.mbsc-focus .mbsc-calendar-day-text {
      box-shadow: none;
      border-color: rgba($text, .5);
    }

    &.mbsc-calendar-mark {
      background: $mark;
    }

    &.mbsc-calendar-label {
      color: $event;
    }

    &.mbsc-calendar-label-text {
      color: get-contrast-color($background);
    }

    &.mbsc-calendar-label-active,
    &.mbsc-calendar-label-dragging {
      .mbsc-calendar-label-inner {
        color: get-contrast-color($event);
      }

      .mbsc-calendar-label-text {
        color: inherit;
      }
    }

    &.mbsc-calendar-text-more .mbsc-calendar-label-text {
      color: $text;
    }

    /* Picker */
    &.mbsc-calendar-popup {

      .mbsc-popup-arrow,
      .mbsc-popup-body {
        background: $popup-background;
      }

      .mbsc-calendar-cell {
        background: $popup-cell;
      }
    }

    /* Multi month grid view */

    &.mbsc-calendar-grid {
      border-color: $border-color;
    }

    &.mbsc-calendar-month-title {
      color: $accent;
    }
  }
}



@mixin mbsc-material-calendar-view($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $background-param: map-get($params, 'calendar-background');
  $text-param: map-get($params, 'calendar-text');
  $accent-param: map-get($params, 'calendar-accent');
  $border-param: map-get($params, 'calendar-border');
  $mark-param: map-get($params, 'calendar-mark');
  $event-param: map-get($params, 'calendar-event');

  $background: if($background-param, $background-param, $background);
  $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
  $accent: if($accent-param, $accent-param, $accent);
  $mark: if($mark-param, $mark-param, $accent);
  $event: if($event-param, $event-param, $accent);

  $border: '';
  $cell-hover: '';
  $picker-background: '';
  $hover: '';

  // Light background
  @if (lightness($background) > 50%) {
    $border: darken($background, 19%);
    $cell-hover: #000;
    $picker-background: $background;
    $hover: darken($background, 10%);
  }

  // Dark background
  @else {
    $border: lighten($background, 17%);
    $cell-hover: #fff;
    $picker-background: lighten($background, 19%);
    $hover: lighten($background, 10%);
  }

  $border: if($border-param, $border-param, $border);

  .mbsc-#{$theme} {

    &.mbsc-calendar-wrapper:after {
      box-shadow: inset 0 .5em .25em -.5em rgba($text, 0.5);
    }

    &.mbsc-calendar-button.mbsc-button {
      color: $text;
    }

    &.mbsc-calendar-slide {
      background: $background;
    }

    &.mbsc-calendar-picker-slide {
      background: $picker-background;
    }

    &.mbsc-calendar-week-day {
      color: rgba($text, .7);
    }

    &.mbsc-calendar-cell-text {
      color: $text;
    }

    &.mbsc-calendar-week-nr,
    &.mbsc-calendar-today {
      color: $accent;
    }

    &.mbsc-focus .mbsc-calendar-cell-text {
      box-shadow: 0 0 0 2px rgba($text, .7);
      // border-color: rgba($text, .7);
    }

    &.mbsc-hover .mbsc-calendar-cell-text {
      background: rgba($cell-hover, .1);
    }

    /* range highlight and selection */

    &.mbsc-range-hover::before {
      border-color: $hover;
    }

    &.mbsc-range-day::after {
      background-color: rgba($accent, .25);
    }

    &.mbsc-selected .mbsc-calendar-cell-text {
      background: $accent;
      border-color: $accent;
      color: $background;
    }

    /* Marks */
    &.mbsc-calendar-mark {
      background: $mark;
    }

    &.mbsc-calendar-label {
      color: $event;
    }

    &.mbsc-calendar-label-inner {
      color: get-contrast-color($event);
    }

    &.mbsc-calendar-label.mbsc-calendar-label-active,
    &.mbsc-calendar-label.mbsc-calendar-label-dragging {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12), 0 1px 3px -1px rgba(0, 0, 0, 0.2);
    }

    &.mbsc-calendar-text-more {
      box-shadow: none;
    }

    &.mbsc-calendar-text-more .mbsc-calendar-label-text {
      color: $text;
    }

    /* Picker */
    &.mbsc-calendar-popup {

      .mbsc-popup-arrow,
      .mbsc-popup-body {
        background: $picker-background;
      }
    }

    /* Desktop style */
    &.mbsc-calendar-height-md {

      .mbsc-calendar-week-day,
      .mbsc-calendar-day {
        border-color: $border;
      }
    }

    /* Multi month grid view */

    &.mbsc-calendar-month-title {
      color: $accent;
    }
  }
}



@mixin mbsc-windows-calendar-view($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $background-param: map-get($params, 'calendar-background');
  $text-param: map-get($params, 'calendar-text');
  $accent-param: map-get($params, 'calendar-accent');
  $border-param: map-get($params, 'calendar-border');
  $mark-param: map-get($params, 'calendar-mark');
  $event-param: map-get($params, 'calendar-event');

  $background: if($background-param, $background-param, $background);
  $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
  $accent: if($accent-param, $accent-param, $accent);
  $mark: if($mark-param, $mark-param, rgba($text, .5));
  $event: if($event-param, $event-param, $accent);

  $alt-text: '';
  $border: '';
  $button-bg: '';
  $hover: '';

  @if (lightness($background) > 50%) {
    $alt-text: lighten($text, 20%);
    $border: darken($background, 10%);
    $button-bg: darken($background, 20%);
    $hover: rgba($text, .1);
  }

  @else {
    $alt-text: darken($text, 20%);
    $border: lighten($background, 10%);
    $button-bg: lighten($background, 15%);
    $hover: rgba($text, .22);
  }

  $border: if($border-param, $border-param, $border);

  .mbsc-#{$theme} {
    &.mbsc-calendar {
      color: $text;
    }

    &.mbsc-calendar-button.mbsc-button {
      color: $text;

      &.mbsc-active {
        background: lighten($button-bg, 15%);
        border-color: lighten($button-bg, 15%);
      }

      &.mbsc-focus {
        box-shadow: 0 0 0 1px $text;
      }
    }

    &.mbsc-calendar-wrapper-fixed {
      border-bottom: 1px solid $border;
    }

    &.mbsc-calendar-slide {
      background: $background;
      color: $text;
    }

    &.mbsc-calendar-week-day {
      color: $alt-text;
    }

    &.mbsc-calendar-week-nr,
    &.mbsc-calendar-today {
      color: $accent;
    }

    /* range highlight and selection */

    &.mbsc-range-day {
      border-left-color: rgba($accent, .25);
    }

    &.mbsc-range-day-start.mbsc-ltr,
    &.mbsc-range-day-end.mbsc-rtl {
      border-left-color: transparent;
    }

    &.mbsc-range-hover .mbsc-calendar-cell-inner {
      border-top-color: $hover;
      border-bottom-color: $hover;
      border-style: dashed;
    }

    // &.mbsc-range-hover-start .mbsc-calendar-cell-inner {
    //   border-left-color: $hover;
    // }

    // &.mbsc-range-hover-end .mbsc-calendar-cell-inner {
    //   border-right-color: $hover;
    // }

    // &.mbsc-selected .mbsc-calendar-cell-inner {
    //   border-color: transparent;
    // }

    // &.mbsc-range-day-start,
    // &.mbsc-range-day-end {
    //   border-left-color: $accent;
    //   border-right-color: $accent;
    // }

    // &.mbsc-range-day-start.mbsc-range-day-end {
    //   border-left-color: transparent;
    // }

    &.mbsc-selected,
    &.mbsc-range-day {
      .mbsc-calendar-cell-inner {
        background: rgba($accent, .25);
      }
    }

    &.mbsc-selected.mbsc-range-day {
      .mbsc-calendar-cell-inner {
        background-color: $accent;
      }

      .mbsc-calendar-cell-text {
        color: get-contrast-color($accent);
      }
    }

    &.mbsc-calendar-day-colors.mbsc-selected:after {
      border: 2px solid $accent;
    }

    &.mbsc-calendar-cell.mbsc-focus:after {
      border: 1px solid $text;
    }

    &.mbsc-calendar-cell.mbsc-hover:after {
      background: $hover;
    }

    /* Marks */
    &.mbsc-calendar-mark {
      background: $mark;
    }

    &.mbsc-calendar-label {
      color: $event;
    }

    &.mbsc-calendar-label-inner {
      color: get-contrast-color($event);
    }

    &.mbsc-calendar-label.mbsc-calendar-label-active {
      outline: 1px solid $text;
    }

    &.mbsc-calendar-text-more .mbsc-calendar-label-text {
      color: $text;
    }

    &.mbsc-calendar-height-md {
      .mbsc-calendar-week-day {
        border-color: $border;
      }
    }

    /* Picker */
    &.mbsc-calendar-popup {

      .mbsc-popup-arrow,
      .mbsc-popup {
        background: $background;
        border-color: $border;
      }
    }

    &.mbsc-calendar-width-md .mbsc-calendar-day {
      border-color: $border;
    }

    /* Multi month grid view */

    &.mbsc-calendar-month-title {
      color: $accent;
    }
  }
}

// list component


@mixin mbsc-ios-list($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');

  $colors: mbsc-ios-colors($params);

  $background-alt: '';
  $background-contrast: '';
  $border-color: '';
  $header-background: '';
  $header-text: '';

  @if (lightness($background) > 50%) {
    $background-alt: lighten($background, 3%);
    $background-contrast: #000;
    $border-color: darken($background, 17%);
    $header-background: adjust-hue(darken(saturate($background, 19%), 2%), 240deg);
    $header-text: adjust-hue(lighten(saturate($text, 2%), 44%), 240deg);
  }

  @else {
    $background-alt: $background;
    $background-contrast: #fff;
    $border-color: lighten($background, 20%);
    $header-background: adjust-hue(lighten(desaturate($background, 19%), 10%), 240deg);
    $header-text: adjust-hue(darken(desaturate($text, 2%), 44%), 240deg);
  }

  .mbsc-#{$theme} {
    &.mbsc-list-item {
      background: $background-alt;
      border-color: $border-color;
      color: $text;
    }

    &.mbsc-list-item:before,
    &.mbsc-list-item:after {
      border-top: 1px solid $border-color;
    }

    &.mbsc-list-header {
      background: $header-background;
      color: $header-text;
      border-color: $border-color;
    }

    &.mbsc-list-item.mbsc-focus:before,
    &.mbsc-list-item.mbsc-hover:before {
      background: rgba($background-contrast, .05);
    }

    &.mbsc-list-item.mbsc-active:before {
      background: rgba(0, 0, 0, .15);
    }
  }
}



@mixin mbsc-material-list($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  .mbsc-#{$theme} {
    &.mbsc-list-header {
      background: $background;
      color: $accent;
    }

    &.mbsc-list-item {
      background: $background;
      color: $text;
    }

    &.mbsc-list-item.mbsc-hover:before {
      background: rgba(0, 0, 0, .05);
    }

    &.mbsc-list-item.mbsc-active:before {
      background: rgba(0, 0, 0, .1);
    }
  }
}



@mixin mbsc-windows-list($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');

  $active: '';
  $hover: '';

  @if (lightness($background) > 50%) {
    $active: lighten($text, 60%);
    $hover: lighten($text, 70%);
  }

  @else {
    $active: darken($text, 60%);
    $hover: darken($text, 70%);
  }

  .mbsc-#{$theme} {

    &.mbsc-list-header,
    &.mbsc-list-item {
      background: $background;
      color: $text;
    }

    &.mbsc-list-item.mbsc-focus {
      // background: rgba($accent, .3);
      box-shadow: 0 0 0 1px $text inset;
    }

    &.mbsc-list-item.mbsc-hover:before {
      background: $hover;
    }

    &.mbsc-list-item.mbsc-active:before {
      background: $active;
    }
  }
}

// popup component


@mixin mbsc-ios-popup($theme, $params) {
  $background: map-get($params, "background");
  $text: map-get($params, "text");
  $accent: map-get($params, "accent");

  $is-dark: false;
  $button-hover: '';
  $buttons-background: '';
  $popup-border: '';
  $popup-background: '';

  @if (lightness($background) > 50%) {
    $button-hover: darken($background, 5%);
    $buttons-background: $background; // darken($background, 3%);
    $popup-border: darken($background, 17%);
    $popup-background: adjust-hue(darken(saturate($background, 24%), 1%), 240deg);
  }

  @else {
    $is-dark: true;
    $button-hover: lighten($background, 14%);
    $buttons-background: lighten($background, 16%);
    $popup-border: lighten($background, 20%);
    $popup-background: lighten($background, 11%);
  }

  .mbsc-#{$theme} {
    &.mbsc-popup-arrow {
      background: $popup-background;
      // border-color: $popup-border;
      box-shadow: 0 0 1em rgba(0, 0, 0, .2);
    }

    &.mbsc-popup-top,
    &.mbsc-popup-bottom {
      border-color: $popup-border;
    }

    &.mbsc-popup-body {
      background: $popup-background;
      // border-color: $popup-border;
      color: $text;
    }

    &.mbsc-popup-body-round,
    &.mbsc-popup-body-center,
    &.mbsc-popup-body-anchored {
      box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    }

    &.mbsc-popup-header-center {
      border-color: $popup-border;
    }

    &.mbsc-popup-buttons,
    &.mbsc-popup-header-no-buttons {
      border-color: $popup-border;
    }

    &.mbsc-popup-buttons-bottom,
    &.mbsc-popup-buttons-top {
      background: $buttons-background;
    }

    @if ($is-dark) {
      &.mbsc-popup-buttons-anchored {
        background: $buttons-background;
      }
    }

    &.mbsc-popup-button-flex.mbsc-button-flat.mbsc-font {
      border-color: $popup-border;
    }

    &.mbsc-popup-button-flex.mbsc-font.mbsc-hover,
    &.mbsc-popup-button-flex.mbsc-font.mbsc-focus {
      background: $button-hover;
    }

    &.mbsc-popup-button-flex.mbsc-font.mbsc-active {
      background: $popup-border;
    }

    &.mbsc-popup-button-flex.mbsc-button.mbsc-disabled {
      color: rgba($accent, .2);
    }
  }
}



@mixin mbsc-material-popup($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $popup-background: '';

  @if (lightness($background) > 50%) {
    $popup-background: $background;
  }

  @else {
    $popup-background: lighten($background, 19%);
  }

  .mbsc-#{$theme} {
    &.mbsc-popup-body {
      background: $popup-background;
      box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
      color: $text;
    }

    &.mbsc-popup-arrow {
      background: $popup-background;
      box-shadow: 0 0 1em rgba(0, 0, 0, .2);
    }

    &.mbsc-popup-button.mbsc-font {
      color: $accent;
    }
  }
}



@mixin mbsc-windows-popup($theme, $params) {
  $background: map-get($params, "background");
  $text: map-get($params, "text");
  $accent: map-get($params, "accent");

  $border: '';

  @if (lightness($background) > 50%) {
    $border: darken($background, 10%);
  }

  @else {
    $border: lighten($background, 10%);
  }

  .mbsc-#{$theme} {
    &.mbsc-popup {
      background: $background;
      border-color: $border;
      box-shadow: rgba(0, 0, 0, 0.133) 0 6px 14px 0, rgba(0, 0, 0, 0.11) 0 1px 4px 0;
      color: $text;
    }

    &.mbsc-popup-arrow {
      background: $background;
      border-color: $border;
    }

    &.mbsc-popup-header,
    &.mbsc-popup-buttons {
      border-color: $border;
    }
  }
}

// calendar component


@mixin mbsc-ios-calendar($theme, $params) {}



@mixin mbsc-material-calendar($theme, $params) {}



@mixin mbsc-windows-calendar($theme, $params) {}

// datepicker component


@mixin mbsc-ios-datepicker($theme, $params) {
  $background: map-get($params, 'background');
  $accent: map-get($params, 'accent');
  $text: map-get($params, 'text');

  $background-alt: '';
  $background-top-bottom: '';
  $border-color: '';
  $buttons-background: '';
  $clear-hover: '';

  @if (lightness($background) > 50%) {
    $background-alt: lighten($background, 3%);
    $background-top-bottom: adjust-hue(darken(saturate($background, 12%), 13%), 216deg);
    $border-color: darken($background, 17%);
    $clear-hover: darken($border-color, 10%);
    $buttons-background: darken($background, 3%);
  }

  @else {
    $background-alt: lighten($background, 11%);
    $background-top-bottom: lighten($background, 11%);
    $border-color: lighten($background, 20%);
    $clear-hover: lighten($border-color, 10%);
    $buttons-background: lighten($background, 8%);
  }

  .mbsc-#{$theme} {

    &.mbsc-picker {

      .mbsc-popup-arrow,
      .mbsc-popup-body {
        background: $background-alt;
      }
    }

    &.mbsc-picker-header {
      border-color: $border-color;
    }

    &.mbsc-datepicker {

      .mbsc-calendar,
      .mbsc-calendar-slide,
      .mbsc-calendar-cell {
        background: $background-alt;
      }
    }

    &.mbsc-datepicker-top.mbsc-datepicker-control-date,
    &.mbsc-datepicker-bottom.mbsc-datepicker-control-date {
      background: $background-top-bottom;
    }

    &.mbsc-datepicker-inline {
      background: $background-alt;
      border-color: $border-color;
      color: $text;
    }

    /* Range Control */

    &.mbsc-range-control-value.active {
      color: $accent;
    }

    &.mbsc-range-control-text-empty,
    &.mbsc-range-control-text-empty.active {
      color: rgba($text, .4);
    }

    &.mbsc-range-label-clear {
      color: $border-color;

      &:hover {
        color: $clear-hover;
      }
    }
  }

}



@mixin mbsc-material-datepicker($theme, $params) {

  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $ctrl-background: '';
  $clear-icon: '';
  $clear-icon-hover: '';

  @if (lightness($background) > 50%) {
    $ctrl-background: darken($text, 36%);
    $clear-icon: lighten($text, 50%);
    $clear-icon-hover: darken($clear-icon, 20%);
  }

  @else {
    $ctrl-background: lighten($text, 24%);
    $clear-icon: darken($text, 50%);
    $clear-icon-hover: lighten($clear-icon, 20%);
  }

  $background-alt: '';

  // Light background
  @if (lightness($background) > 50%) {
    $background-alt: $background;
  }

  // Dark background
  @else {
    $background-alt: lighten($background, 19%);
  }

  .mbsc-#{$theme} {
    &.mbsc-datepicker .mbsc-calendar-slide {
      background: $background-alt;
    }

    &.mbsc-datepicker-inline {
      background: $background;
      color: $text;
    }

    &.mbsc-datepicker-inline .mbsc-calendar-slide {
      background: $background;
    }

    /* Range Controls */

    &.mbsc-range-control-wrapper {
      border-bottom: 1px solid rgba($ctrl-background, .2);
    }

    &.mbsc-range-control-wrapper &.mbsc-segmented-button {
      &.mbsc-selected {
        border-bottom-color: $accent;
      }
    }

    &.mbsc-range-control-value,
    &.mbsc-range-control-label {
      &.active {
        color: $accent;
      }
    }

    &.mbsc-range-control-text-empty,
    &.mbsc-range-control-text-empty.active {
      color: rgba($text, .4);
    }

    &.mbsc-range-label-clear {
      color: $clear-icon;

      &:hover {
        color: $clear-icon-hover;
      }
    }

  }
}



@mixin mbsc-windows-datepicker($theme, $params) {

  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $button-bg: '';

  @if (lightness($background) > 50%) {
    $button-bg: darken($background, 20%);
  }

  @else {
    $button-bg: lighten($background, 15%);
  }

  $border: '';
  $hover: '';

  @if (lightness($background) > 50%) {
    $border: darken($background, 10%);
    $hover: lighten($text, 70%);
  }

  @else {
    $border: lighten($background, 10%);
    $hover: darken($text, 70%);
  }

  .mbsc-#{$theme} {
    &.mbsc-datepicker-inline {
      background: $background;
      color: $text;
    }

    &.mbsc-picker-header {
      border-color: $border;
    }

    &.mbsc-datepicker-tab {
      border-color: $border;
    }

    &.mbsc-range-control-wrapper {
      border-bottom: 1px solid $border;
    }

    &.mbsc-range-control-wrapper &.mbsc-segmented-button {
      &.mbsc-selected {
        border-bottom-color: $accent;
      }

      &.mbsc-focus {
        box-shadow: 0 0 0 1px $text inset;
      }
    }

    &.mbsc-range-control-value,
    &.mbsc-range-control-label {
      &.active {
        color: $accent;
      }
    }

    &.mbsc-range-control-text-empty,
    &.mbsc-range-control-text-empty.active {
      color: rgba($text, .4);
    }

    &.mbsc-range-label-clear {
      color: rgba($text, .6);

      &:hover {
        color: $text;
      }
    }
  }
}

// datetime component


@mixin mbsc-ios-datetime($theme, $params) {}



@mixin mbsc-material-datetime($theme, $params) {}



@mixin mbsc-windows-datetime($theme, $params) {}

// scroller component


@mixin mbsc-ios-scroller($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $item-color: hsl(hue($text), saturation($text), 62%);

  $background-alt: '';
  $background-top-bottom: '';
  $item-3d: '';
  $overlay: '';
  $scroller-background: '';
  $scroller-selected-line: '';

  @if (lightness($background) > 50%) {
    $background-alt: lighten($background, 3%);
    $background-top-bottom: adjust-hue(darken(saturate($background, 12%), 13%), 216deg);
    $item-3d: darken($background, 33%);
    $overlay: $background-alt;
    $scroller-background: $background-alt;
    $scroller-selected-line: #000;
  }

  @else {
    $background-alt: lighten($background, 11%);
    $background-top-bottom: lighten($background, 11%);
    $item-3d: lighten($background, 40%);
    $overlay: $background-alt;
    $scroller-background: $background-alt;
    $scroller-selected-line: #fff;
  }

  .mbsc-#{$theme} {
    &.mbsc-scroller {
      background: $scroller-background;
    }

    &.mbsc-scroller-top,
    &.mbsc-scroller-bottom {
      background: $background-top-bottom;
    }

    &.mbsc-scroller-wheel-overlay {
      background: linear-gradient($overlay, rgba($overlay, 0) 52%, rgba($overlay, 0) 48%, $overlay);
    }

    &.mbsc-scroller-wheel-overlay-top,
    &.mbsc-scroller-wheel-overlay-bottom {
      background: linear-gradient($background-top-bottom, rgba($background-top-bottom, 0) 52%, rgba($background-top-bottom, 0) 48%, $background-top-bottom);
    }

    &.mbsc-scroller-wheel-line {
      background: rgba($scroller-selected-line, .1);
    }

    &.mbsc-scroller-wheel-item {
      color: $item-color;
    }

    &.mbsc-scroller-wheel-item.mbsc-active,
    &.mbsc-scroller-wheel-item.mbsc-hover,
    &.mbsc-scroller-wheel-item.mbsc-focus,
    &.mbsc-scroller-wheel-header.mbsc-focus {
      background: rgba($accent, 0.15);
    }

    &.mbsc-scroller-wheel-item-2d,
    &.mbsc-scroller-wheel-item.mbsc-selected {
      color: $text;

    }

    /* 3D */

    &.mbsc-scroller-wheel-cont-3d {
      background: $scroller-background;
    }

    &.mbsc-scroller-wheel-item-3d {
      color: $item-3d;
    }

    &.mbsc-scroller-wheel-header,
    &.mbsc-scroller-wheel-item-3d.mbsc-wheel-item-multi {
      color: $text;
    }

    &.mbsc-scroller-wheel-item-3d.mbsc-wheel-item-multi.mbsc-selected-3d {
      color: $accent;
    }

    &.mbsc-scroller-wheel-cont-top.mbsc-scroller-wheel-cont-3d,
    &.mbsc-scroller-wheel-cont-bottom.mbsc-scroller-wheel-cont-3d {
      background: $background-top-bottom;
    }

    &.mbsc-scroller-wheel-cont-inline.mbsc-scroller-wheel-cont-3d {
      background: $background-alt;
    }

    /* Desktop style */

    &.mbsc-scroller-pointer {

      &.mbsc-scroller {
        background: $scroller-background;
      }

      &.mbsc-scroller-inline {
        background: $background-alt;
      }

      .mbsc-scroller-wheel-item {
        color: $text;
      }

      .mbsc-scroller-wheel-item.mbsc-selected {
        color: $accent;
      }
    }
  }
}



@mixin mbsc-material-scroller($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  .mbsc-#{$theme} {

    &.mbsc-scroller-wheel-cont::after,
    &.mbsc-scroller-wheel-cont::before {
      border-color: $accent;
    }

    &.mbsc-scroller-wheel-multi::after,
    &.mbsc-scroller-wheel-multi::before {
      border-color: transparent;
    }

    &.mbsc-scroller-wheel-item.mbsc-active,
    &.mbsc-scroller-wheel-item.mbsc-hover,
    &.mbsc-scroller-wheel-item.mbsc-focus,
    &.mbsc-scroller-wheel-header.mbsc-focus {
      background: rgba(0, 0, 0, .05);
    }

    &.mbsc-wheel-checkmark::after {
      border-color: $accent;
    }
  }
}



@mixin mbsc-windows-scroller($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $border: '';
  $wheel-button: '';

  @if (lightness($background) > 50%) {
    $border: darken($background, 10%);
  }

  @else {
    $border: lighten($background, 10%);
  }

  .mbsc-#{$theme} {
    &.mbsc-scroller-wheel-wrapper {
      color: $text;
      border-color: $border;
    }

    &.mbsc-scroller-wheel-line {
      background: rgba($accent, .4);
    }

    &.mbsc-scroller-wheel-item.mbsc-active,
    &.mbsc-scroller-wheel-item.mbsc-hover,
    &.mbsc-scroller-wheel-item.mbsc-focus,
    &.mbsc-scroller-wheel-header.mbsc-focus {
      background: rgba($text, .1);
    }

    &.mbsc-wheel-checkmark::after {
      border-color: $accent;
    }
  }
}

// timegrid component


@mixin mbsc-ios-timegrid($theme, $params) {
  $background: map-get($params, 'background');
  $accent: map-get($params, 'accent');
  $text: map-get($params, 'text');

  $background-hover: '';
  $selected-text: '';

  @if (lightness($background) > 50%) {
    $background-hover: lighten($accent, 35%);
    $selected-text: lighten($text, 100%);
  }

  @else {
    $background-hover: darken($accent, 25%);
    $selected-text: lighten($text, 100%);
  }

  .mbsc-#{$theme} {

    &.mbsc-timegrid-item:hover,
    &.mbsc-timegrid-item:focus {
      background: rgba($accent, .3);
    }

    &.mbsc-timegrid-item.mbsc-selected {
      background: $accent;
      color: $selected-text;
    }
  }
}



@mixin mbsc-material-timegrid($theme, $params) {
  $background: map-get($params, 'background');
  $accent: map-get($params, 'accent');
  $text: map-get($params, 'text');

  $background-hover: '';
  $selected-text: '';

  @if (lightness($background) > 50%) {
    $background-hover: lighten($accent, 35%);
    $selected-text: lighten($text, 100%);
  }

  @else {
    $background-hover: darken($accent, 25%);
    $selected-text: darken($text, 100%);
  }

  .mbsc-#{$theme} {

    &.mbsc-timegrid-item:hover,
    &.mbsc-timegrid-item:focus {
      background: rgba($text, .1);
    }

    &.mbsc-timegrid-item.mbsc-selected {
      background: $accent;
      color: $selected-text;
    }
  }
}



@mixin mbsc-windows-timegrid($theme, $params) {
  $background: map-get($params, 'background');
  $accent: map-get($params, 'accent');
  $text: map-get($params, 'text');

  .mbsc-#{$theme} {
    &.mbsc-timegrid-item.mbsc-selected {
      background: rgba($accent, .25);
      color: $text;
    }

    &.mbsc-timegrid-item:hover,
    &.mbsc-timegrid-item:focus {
      background: rgba($text, .1);
    }

    &.mbsc-timegrid-item.mbsc-selected:hover,
    &.mbsc-timegrid-item.mbsc-selected:focus {
      background: mix(rgba($accent, .5), rgba($text, .2));
    }
  }
}

// select component


@mixin mbsc-ios-select($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $item-color: hsl(hue($text), saturation($text), 62%);

  $border-color: '';
  $clear: '';
  $clear-hover: '';
  $popup-background: '';

  @if (lightness($background) > 50%) {
    $border-color: darken($background, 17%);
    $clear: $border-color;
    $clear-hover: darken($border-color, 10%);
    $popup-background: adjust-hue(darken(saturate($background, 24%), 1%), 240deg);
  }

  @else {
    $border-color: lighten($background, 20%);
    $clear: lighten($background, 25%);
    $clear-hover: lighten($border-color, 10%);
    $popup-background: lighten($background, 11%);
  }

  .mbsc-#{$theme} {
    &.mbsc-select-scroller-inline {
      border-color: $border-color;
    }

    &.mbsc-select-filter-cont {
      background-color: $popup-background;
    }

    &.mbsc-select-filter-clear {
      color: $clear;

      &:hover {
        color: $clear-hover;
      }
    }

    &.mbsc-select-empty-text {
      color: $item-color;
    }

    &.mbsc-select-group-wheel {
      border-color: $border-color;
    }

    &.mbsc-select-group-wheel-multi .mbsc-scroller-wheel-item-3d {
      color: $text;
    }

    &.mbsc-select-group-wheel-multi .mbsc-selected-3d {
      color: $accent;
    }

  }
}



@mixin mbsc-material-select($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $clear-icon: '';
  $clear-icon-hover: '';

  @if (lightness($background) > 50%) {
    $clear-icon: lighten($text, 50%);
    $clear-icon-hover: darken($clear-icon, 20%);
  }

  @else {
    $clear-icon: darken($text, 50%);
    $clear-icon-hover: lighten($clear-icon, 20%);
  }


  .mbsc-#{$theme} {
    &.mbsc-select-scroller-inline {
      background-color: $background;
      color: $text;
    }

    &.mbsc-select-filter-clear {
      color: $clear-icon;

      &:hover {
        color: $clear-icon-hover;
      }
    }

    &.mbsc-select-scroller.mbsc-scroller-pointer .mbsc-scroller-wheel-item.mbsc-selected {
       color: $accent;
       background: rgba(0, 0, 0, .15);
    }

    &.mbsc-select-group-wheel-multi .mbsc-selected {
      color: $accent;
    }
  }
}



@mixin mbsc-windows-select($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  .mbsc-#{$theme} {
    &.mbsc-select-scroller-inline {
      background-color: $background;
      color: $text;
    }

    &.mbsc-select-scroller.mbsc-scroller-pointer .mbsc-scroller-wheel-item.mbsc-selected,
    &.mbsc-select-group-wheel-multi .mbsc-selected {
      color: $accent;
    }

  }
}

// Theme builder function for ios theme

@mixin mbsc-ios-theme($theme, $colors) {
    @include mbsc-ios-form-controls($theme, $colors);
    @include mbsc-ios-button($theme, $colors);
    @include mbsc-ios-checkbox($theme, $colors);
    @include mbsc-ios-input($theme, $colors);
    @include mbsc-ios-page($theme, $colors);
    @include mbsc-ios-radio($theme, $colors);
    @include mbsc-ios-segmented($theme, $colors);
    @include mbsc-ios-switch($theme, $colors);
    @include mbsc-ios-eventcalendar($theme, $colors);
    @include mbsc-ios-calendar-view($theme, $colors);
    @include mbsc-ios-list($theme, $colors);
    @include mbsc-ios-popup($theme, $colors);
    @include mbsc-ios-calendar($theme, $colors);
    @include mbsc-ios-datepicker($theme, $colors);
    @include mbsc-ios-datetime($theme, $colors);
    @include mbsc-ios-scroller($theme, $colors);
    @include mbsc-ios-timegrid($theme, $colors);
    @include mbsc-ios-select($theme, $colors);
}

// Theme builder function for material theme

@mixin mbsc-material-theme($theme, $colors) {
    @include mbsc-material-form-controls($theme, $colors);
    @include mbsc-material-button($theme, $colors);
    @include mbsc-material-checkbox($theme, $colors);
    @include mbsc-material-input($theme, $colors);
    @include mbsc-material-page($theme, $colors);
    @include mbsc-material-radio($theme, $colors);
    @include mbsc-material-segmented($theme, $colors);
    @include mbsc-material-switch($theme, $colors);
    @include mbsc-material-eventcalendar($theme, $colors);
    @include mbsc-material-calendar-view($theme, $colors);
    @include mbsc-material-list($theme, $colors);
    @include mbsc-material-popup($theme, $colors);
    @include mbsc-material-calendar($theme, $colors);
    @include mbsc-material-datepicker($theme, $colors);
    @include mbsc-material-datetime($theme, $colors);
    @include mbsc-material-scroller($theme, $colors);
    @include mbsc-material-timegrid($theme, $colors);
    @include mbsc-material-select($theme, $colors);
}

// Theme builder function for windows theme

@mixin mbsc-windows-theme($theme, $colors) {
    @include mbsc-windows-form-controls($theme, $colors);
    @include mbsc-windows-button($theme, $colors);
    @include mbsc-windows-checkbox($theme, $colors);
    @include mbsc-windows-input($theme, $colors);
    @include mbsc-windows-page($theme, $colors);
    @include mbsc-windows-radio($theme, $colors);
    @include mbsc-windows-segmented($theme, $colors);
    @include mbsc-windows-switch($theme, $colors);
    @include mbsc-windows-eventcalendar($theme, $colors);
    @include mbsc-windows-calendar-view($theme, $colors);
    @include mbsc-windows-list($theme, $colors);
    @include mbsc-windows-popup($theme, $colors);
    @include mbsc-windows-calendar($theme, $colors);
    @include mbsc-windows-datepicker($theme, $colors);
    @include mbsc-windows-datetime($theme, $colors);
    @include mbsc-windows-scroller($theme, $colors);
    @include mbsc-windows-timegrid($theme, $colors);
    @include mbsc-windows-select($theme, $colors);
}

@mixin mbsc-custom-theme($theme, $base-theme, $colors) {
    @if $base-theme==ios {
        @include mbsc-ios-theme($theme, $colors);
    }
    @if $base-theme==material {
        @include mbsc-material-theme($theme, $colors);
    }
    @if $base-theme==windows {
        @include mbsc-windows-theme($theme, $colors);
    }
}


@mixin mbsc-grid-sm() {
  .mbsc-col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .mbsc-col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .mbsc-col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .mbsc-col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .mbsc-col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .mbsc-col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .mbsc-col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .mbsc-col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .mbsc-col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .mbsc-col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .mbsc-col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .mbsc-col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .mbsc-col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .mbsc-col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .mbsc-offset-sm-0 {
    margin-left: 0;
  }

  .mbsc-offset-sm-1 {
    margin-left: 8.333333%;
  }

  .mbsc-offset-sm-2 {
    margin-left: 16.666667%;
  }

  .mbsc-offset-sm-3 {
    margin-left: 25%;
  }

  .mbsc-offset-sm-4 {
    margin-left: 33.333333%;
  }

  .mbsc-offset-sm-5 {
    margin-left: 41.666667%;
  }

  .mbsc-offset-sm-6 {
    margin-left: 50%;
  }

  .mbsc-offset-sm-7 {
    margin-left: 58.333333%;
  }

  .mbsc-offset-sm-8 {
    margin-left: 66.666667%;
  }

  .mbsc-offset-sm-9 {
    margin-left: 75%;
  }

  .mbsc-offset-sm-10 {
    margin-left: 83.333333%;
  }

  .mbsc-offset-sm-11 {
    margin-left: 91.666667%;
  }

  .mbsc-push-sm-0 {
    left: auto;
  }

  .mbsc-push-sm-1 {
    left: 8.33333333%;
  }

  .mbsc-push-sm-2 {
    left: 16.66666667%;
  }

  .mbsc-push-sm-3 {
    left: 25%;
  }

  .mbsc-push-sm-4 {
    left: 33.33333333%;
  }

  .mbsc-push-sm-5 {
    left: 41.66666667%;
  }

  .mbsc-push-sm-6 {
    left: 50%;
  }

  .mbsc-push-sm-7 {
    left: 58.33333333%;
  }

  .mbsc-push-sm-8 {
    left: 66.66666667%;
  }

  .mbsc-push-sm-9 {
    left: 75%;
  }

  .mbsc-push-sm-10 {
    left: 83.33333333%;
  }

  .mbsc-push-sm-11 {
    left: 91.66666667%;
  }

  .mbsc-push-sm-12 {
    left: 100%;
  }

  .mbsc-pull-sm-0 {
    right: auto;
  }

  .mbsc-pull-sm-1 {
    right: 8.33333333%;
  }

  .mbsc-pull-sm-2 {
    right: 16.66666667%;
  }

  .mbsc-pull-sm-3 {
    right: 25%;
  }

  .mbsc-pull-sm-4 {
    right: 33.33333333%;
  }

  .mbsc-pull-sm-5 {
    right: 41.66666667%;
  }

  .mbsc-pull-sm-6 {
    right: 50%;
  }

  .mbsc-pull-sm-7 {
    right: 58.33333333%;
  }

  .mbsc-pull-sm-8 {
    right: 66.66666667%;
  }

  .mbsc-pull-sm-9 {
    right: 75%;
  }

  .mbsc-pull-sm-10 {
    right: 83.33333333%;
  }

  .mbsc-pull-sm-11 {
    right: 91.66666667%;
  }

  .mbsc-pull-sm-12 {
    right: 100%;
  }
}

@mixin mbsc-grid-md() {
  .mbsc-col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .mbsc-col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .mbsc-col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .mbsc-col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .mbsc-col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .mbsc-col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .mbsc-col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .mbsc-col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .mbsc-col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .mbsc-col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .mbsc-col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .mbsc-col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .mbsc-col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .mbsc-col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .mbsc-offset-md-0 {
    margin-left: 0;
  }

  .mbsc-offset-md-1 {
    margin-left: 8.333333%;
  }

  .mbsc-offset-md-2 {
    margin-left: 16.666667%;
  }

  .mbsc-offset-md-3 {
    margin-left: 25%;
  }

  .mbsc-offset-md-4 {
    margin-left: 33.333333%;
  }

  .mbsc-offset-md-5 {
    margin-left: 41.666667%;
  }

  .mbsc-offset-md-6 {
    margin-left: 50%;
  }

  .mbsc-offset-md-7 {
    margin-left: 58.333333%;
  }

  .mbsc-offset-md-8 {
    margin-left: 66.666667%;
  }

  .mbsc-offset-md-9 {
    margin-left: 75%;
  }

  .mbsc-offset-md-10 {
    margin-left: 83.333333%;
  }

  .mbsc-offset-md-11 {
    margin-left: 91.666667%;
  }

  .mbsc-push-md-0 {
    left: auto;
  }

  .mbsc-push-md-1 {
    left: 8.33333333%;
  }

  .mbsc-push-md-2 {
    left: 16.66666667%;
  }

  .mbsc-push-md-3 {
    left: 25%;
  }

  .mbsc-push-md-4 {
    left: 33.33333333%;
  }

  .mbsc-push-md-5 {
    left: 41.66666667%;
  }

  .mbsc-push-md-6 {
    left: 50%;
  }

  .mbsc-push-md-7 {
    left: 58.33333333%;
  }

  .mbsc-push-md-8 {
    left: 66.66666667%;
  }

  .mbsc-push-md-9 {
    left: 75%;
  }

  .mbsc-push-md-10 {
    left: 83.33333333%;
  }

  .mbsc-push-md-11 {
    left: 91.66666667%;
  }

  .mbsc-push-md-12 {
    left: 100%;
  }

  .mbsc-pull-md-0 {
    right: auto;
  }

  .mbsc-pull-md-1 {
    right: 8.33333333%;
  }

  .mbsc-pull-md-2 {
    right: 16.66666667%;
  }

  .mbsc-pull-md-3 {
    right: 25%;
  }

  .mbsc-pull-md-4 {
    right: 33.33333333%;
  }

  .mbsc-pull-md-5 {
    right: 41.66666667%;
  }

  .mbsc-pull-md-6 {
    right: 50%;
  }

  .mbsc-pull-md-7 {
    right: 58.33333333%;
  }

  .mbsc-pull-md-8 {
    right: 66.66666667%;
  }

  .mbsc-pull-md-9 {
    right: 75%;
  }

  .mbsc-pull-md-10 {
    right: 83.33333333%;
  }

  .mbsc-pull-md-11 {
    right: 91.66666667%;
  }

  .mbsc-pull-md-12 {
    right: 100%;
  }
}

@mixin mbsc-grid-lg() {
  .mbsc-col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .mbsc-col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .mbsc-col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .mbsc-col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .mbsc-col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .mbsc-col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .mbsc-col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .mbsc-col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .mbsc-col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .mbsc-col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .mbsc-col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .mbsc-col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .mbsc-col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .mbsc-col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .mbsc-offset-lg-0 {
    margin-left: 0;
  }

  .mbsc-offset-lg-1 {
    margin-left: 8.333333%;
  }

  .mbsc-offset-lg-2 {
    margin-left: 16.666667%;
  }

  .mbsc-offset-lg-3 {
    margin-left: 25%;
  }

  .mbsc-offset-lg-4 {
    margin-left: 33.333333%;
  }

  .mbsc-offset-lg-5 {
    margin-left: 41.666667%;
  }

  .mbsc-offset-lg-6 {
    margin-left: 50%;
  }

  .mbsc-offset-lg-7 {
    margin-left: 58.333333%;
  }

  .mbsc-offset-lg-8 {
    margin-left: 66.666667%;
  }

  .mbsc-offset-lg-9 {
    margin-left: 75%;
  }

  .mbsc-offset-lg-10 {
    margin-left: 83.333333%;
  }

  .mbsc-offset-lg-11 {
    margin-left: 91.666667%;
  }

  .mbsc-push-lg-0 {
    left: auto;
  }

  .mbsc-push-lg-1 {
    left: 8.33333333%;
  }

  .mbsc-push-lg-2 {
    left: 16.66666667%;
  }

  .mbsc-push-lg-3 {
    left: 25%;
  }

  .mbsc-push-lg-4 {
    left: 33.33333333%;
  }

  .mbsc-push-lg-5 {
    left: 41.66666667%;
  }

  .mbsc-push-lg-6 {
    left: 50%;
  }

  .mbsc-push-lg-7 {
    left: 58.33333333%;
  }

  .mbsc-push-lg-8 {
    left: 66.66666667%;
  }

  .mbsc-push-lg-9 {
    left: 75%;
  }

  .mbsc-push-lg-10 {
    left: 83.33333333%;
  }

  .mbsc-push-lg-11 {
    left: 91.66666667%;
  }

  .mbsc-push-lg-12 {
    left: 100%;
  }

  .mbsc-pull-lg-0 {
    right: auto;
  }

  .mbsc-pull-lg-1 {
    right: 8.33333333%;
  }

  .mbsc-pull-lg-2 {
    right: 16.66666667%;
  }

  .mbsc-pull-lg-3 {
    right: 25%;
  }

  .mbsc-pull-lg-4 {
    right: 33.33333333%;
  }

  .mbsc-pull-lg-5 {
    right: 41.66666667%;
  }

  .mbsc-pull-lg-6 {
    right: 50%;
  }

  .mbsc-pull-lg-7 {
    right: 58.33333333%;
  }

  .mbsc-pull-lg-8 {
    right: 66.66666667%;
  }

  .mbsc-pull-lg-9 {
    right: 75%;
  }

  .mbsc-pull-lg-10 {
    right: 83.33333333%;
  }

  .mbsc-pull-lg-11 {
    right: 91.66666667%;
  }

  .mbsc-pull-lg-12 {
    right: 100%;
  }
}

@mixin mbsc-grid-xl() {
  .mbsc-col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .mbsc-col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .mbsc-col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .mbsc-col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .mbsc-col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .mbsc-col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .mbsc-col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .mbsc-col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .mbsc-col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .mbsc-col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .mbsc-col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .mbsc-col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .mbsc-col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .mbsc-col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .mbsc-offset-xl-0 {
    margin-left: 0;
  }

  .mbsc-offset-xl-1 {
    margin-left: 8.333333%;
  }

  .mbsc-offset-xl-2 {
    margin-left: 16.666667%;
  }

  .mbsc-offset-xl-3 {
    margin-left: 25%;
  }

  .mbsc-offset-xl-4 {
    margin-left: 33.333333%;
  }

  .mbsc-offset-xl-5 {
    margin-left: 41.666667%;
  }

  .mbsc-offset-xl-6 {
    margin-left: 50%;
  }

  .mbsc-offset-xl-7 {
    margin-left: 58.333333%;
  }

  .mbsc-offset-xl-8 {
    margin-left: 66.666667%;
  }

  .mbsc-offset-xl-9 {
    margin-left: 75%;
  }

  .mbsc-offset-xl-10 {
    margin-left: 83.333333%;
  }

  .mbsc-offset-xl-11 {
    margin-left: 91.666667%;
  }

  .mbsc-push-xl-0 {
    left: auto;
  }

  .mbsc-push-xl-1 {
    left: 8.33333333%;
  }

  .mbsc-push-xl-2 {
    left: 16.66666667%;
  }

  .mbsc-push-xl-3 {
    left: 25%;
  }

  .mbsc-push-xl-4 {
    left: 33.33333333%;
  }

  .mbsc-push-xl-5 {
    left: 41.66666667%;
  }

  .mbsc-push-xl-6 {
    left: 50%;
  }

  .mbsc-push-xl-7 {
    left: 58.33333333%;
  }

  .mbsc-push-xl-8 {
    left: 66.66666667%;
  }

  .mbsc-push-xl-9 {
    left: 75%;
  }

  .mbsc-push-xl-10 {
    left: 83.33333333%;
  }

  .mbsc-push-xl-11 {
    left: 91.66666667%;
  }

  .mbsc-push-xl-12 {
    left: 100%;
  }

  .mbsc-pull-xl-0 {
    right: auto;
  }

  .mbsc-pull-xl-1 {
    right: 8.33333333%;
  }

  .mbsc-pull-xl-2 {
    right: 16.66666667%;
  }

  .mbsc-pull-xl-3 {
    right: 25%;
  }

  .mbsc-pull-xl-4 {
    right: 33.33333333%;
  }

  .mbsc-pull-xl-5 {
    right: 41.66666667%;
  }

  .mbsc-pull-xl-6 {
    right: 50%;
  }

  .mbsc-pull-xl-7 {
    right: 58.33333333%;
  }

  .mbsc-pull-xl-8 {
    right: 66.66666667%;
  }

  .mbsc-pull-xl-9 {
    right: 75%;
  }

  .mbsc-pull-xl-10 {
    right: 83.33333333%;
  }

  .mbsc-pull-xl-11 {
    right: 91.66666667%;
  }

  .mbsc-pull-xl-12 {
    right: 100%;
  }
}

.mbsc-grid,
.mbsc-grid-unresp,
.mbsc-grid-fixed {
  width: 100%;
  padding-right: 1em;
  padding-left: 1em;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
}

.mbsc-form-grid {
  margin-top: .75em;
  margin-bottom: .75em;
}

.mbsc-no-padding .mbsc-col,
.mbsc-no-padding [class*="mbsc-col-"],
.mbsc-form-grid .mbsc-col,
.mbsc-form-grid [class*="mbsc-col-"] {
  padding-right: 0;
  padding-left: 0;
}

.mbsc-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -1em;
  margin-left: -1em;
}

.mbsc-col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.mbsc-col-1,
.mbsc-col-2,
.mbsc-col-3,
.mbsc-col-4,
.mbsc-col-5,
.mbsc-col-6,
.mbsc-col-7,
.mbsc-col-8,
.mbsc-col-9,
.mbsc-col-10,
.mbsc-col-11,
.mbsc-col-12,
.mbsc-col,
.mbsc-col-auto,
.mbsc-col-sm-1,
.mbsc-col-sm-2,
.mbsc-col-sm-3,
.mbsc-col-sm-4,
.mbsc-col-sm-5,
.mbsc-col-sm-6,
.mbsc-col-sm-7,
.mbsc-col-sm-8,
.mbsc-col-sm-9,
.mbsc-col-sm-10,
.mbsc-col-sm-11,
.mbsc-col-sm-12,
.mbsc-col-sm,
.mbsc-col-sm-auto,
.mbsc-col-md-1,
.mbsc-col-md-2,
.mbsc-col-md-3,
.mbsc-col-md-4,
.mbsc-col-md-5,
.mbsc-col-md-6,
.mbsc-col-md-7,
.mbsc-col-md-8,
.mbsc-col-md-9,
.mbsc-col-md-10,
.mbsc-col-md-11,
.mbsc-col-md-12,
.mbsc-col-md,
.mbsc-col-md-auto,
.mbsc-col-lg-1,
.mbsc-col-lg-2,
.mbsc-col-lg-3,
.mbsc-col-lg-4,
.mbsc-col-lg-5,
.mbsc-col-lg-6,
.mbsc-col-lg-7,
.mbsc-col-lg-8,
.mbsc-col-lg-9,
.mbsc-col-lg-10,
.mbsc-col-lg-11,
.mbsc-col-lg-12,
.mbsc-col-lg,
.mbsc-col-lg-auto,
.mbsc-col-xl-1,
.mbsc-col-xl-2,
.mbsc-col-xl-3,
.mbsc-col-xl-4,
.mbsc-col-xl-5,
.mbsc-col-xl-6,
.mbsc-col-xl-7,
.mbsc-col-xl-8,
.mbsc-col-xl-9,
.mbsc-col-xl-10,
.mbsc-col-xl-11,
.mbsc-col-xl-12,
.mbsc-col-xl,
.mbsc-col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 1em;
  padding-left: 1em;
}

.mbsc-col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.mbsc-col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.mbsc-col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.mbsc-col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.mbsc-col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.mbsc-col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.mbsc-col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.mbsc-col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.mbsc-col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.mbsc-col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.mbsc-col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.mbsc-col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.mbsc-col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.mbsc-offset-1 {
  margin-left: 8.333333%;
}

.mbsc-offset-2 {
  margin-left: 16.666667%;
}

.mbsc-offset-3 {
  margin-left: 25%;
}

.mbsc-offset-4 {
  margin-left: 33.333333%;
}

.mbsc-offset-5 {
  margin-left: 41.666667%;
}

.mbsc-offset-6 {
  margin-left: 50%;
}

.mbsc-offset-7 {
  margin-left: 58.333333%;
}

.mbsc-offset-8 {
  margin-left: 66.666667%;
}

.mbsc-offset-9 {
  margin-left: 75%;
}

.mbsc-offset-10 {
  margin-left: 83.333333%;
}

.mbsc-offset-11 {
  margin-left: 91.666667%;
}

// .mbsc-grid-sm {
//   &.mbsc-grid-fixed {
//     max-width: 540px;
//   }

//   @include mbsc-grid-sm();
// }

@media (min-width: 576px) {
  .mbsc-grid {
    &.mbsc-grid-fixed {
      max-width: 540px;
    }

    @include mbsc-grid-sm();
  }
}

// .mbsc-grid-md {
//   &.mbsc-grid-fixed {
//     max-width: 720px;
//   }

//   @include mbsc-grid-md();
// }

@media (min-width: 768px) {
  .mbsc-grid {
    &.mbsc-grid-fixed {
      max-width: 720px;
    }

    @include mbsc-grid-md();
  }
}

// .mbsc-grid-lg {
//   &.mbsc-grid-fixed {
//     max-width: 960px;
//   }

//   @include mbsc-grid-lg();
// }

@media (min-width: 992px) {
  .mbsc-grid {
    &.mbsc-grid-fixed {
      max-width: 960px;
    }

    @include mbsc-grid-lg();
  }
}

// .mbsc-grid-xl {
//   &.mbsc-grid-fixed {
//     max-width: 1140px;
//   }

//   @include mbsc-grid-xl();
// }

@media (min-width: 1200px) {
  .mbsc-grid {
    &.mbsc-grid-fixed {
      max-width: 1140px;
    }

    @include mbsc-grid-xl();
  }
}

.mbsc-align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.mbsc-align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.mbsc-align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.mbsc-justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.mbsc-justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.mbsc-justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.mbsc-justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.mbsc-justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.mbsc-icon {
  display: inline-block;
  vertical-align: middle;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5em;
  text-align: center;
  flex: 0 0 auto;
}

.mbsc-icon > svg {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  fill: currentColor;
}

:root {
  --mbsc-safe-top: 0;
  --mbsc-safe-right: 0;
  --mbsc-safe-bottom: 0;
  --mbsc-safe-left: 0;

  @supports (top: constant(safe-area-inset-top)) {
    --mbsc-safe-top: constant(safe-area-inset-top);
    --mbsc-safe-right: constant(safe-area-inset-right);
    --mbsc-safe-bottom: constant(safe-area-inset-bottom);
    --mbsc-safe-left: constant(safe-area-inset-left);
  }

  @supports (top: env(safe-area-inset-top)) {
    --mbsc-safe-top: env(safe-area-inset-top);
    --mbsc-safe-right: env(safe-area-inset-right);
    --mbsc-safe-bottom: env(safe-area-inset-bottom);
    --mbsc-safe-left: env(safe-area-inset-left);
  }
}

.mbsc-font {
  font-family: -apple-system, 'Segoe UI', Roboto, sans-serif;
  font-size: 16px;
  font-weight: normal;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
}

.mbsc-reset {
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  // border-radius: 0;
  // font-family: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.mbsc-resize {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: -1;
}

.mbsc-resize-i {
  position: absolute;
  left: 0;
  top: 0;
}

.mbsc-resize-y {
  width: 200%;
  height: 200%;
}

.mbsc-hidden {
  visibility: hidden;
}

.mbsc-ltr {
  direction: ltr;
}

.mbsc-rtl {
  direction: rtl;
}

.mbsc-ripple {
  background: currentColor;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  border-radius: 1000em;
  pointer-events: none;
  transform: scale(0);
}

/* Flex util classes */

.mbsc-flex,
.mbsc-flex-col {
  display: flex;
}

.mbsc-flex-col {
  flex-direction: column;
}

.mbsc-flex-1-1 {
  flex: 1 1 auto;
}

.mbsc-flex-1-0 {
  flex: 1 0 auto;
}

.mbsc-flex-1-0-0 {
  flex: 1 0 0;
}

/* IE11 hack, where flex-basis auto does not work correctly */
@media all and (-ms-high-contrast:none) {
  .mbsc-flex-1-0-0 {
    flex: 1 0 auto;
  }
}

.mbsc-flex-none {
  flex: none;
}

@media (-webkit-min-device-pixel-ratio: 2) {

  .mbsc-hb,
  .mbsc-hb:before,
  .mbsc-hb:after {
    border-width: .5px !important;
  }
}



$mbsc-mobiscroll-accent: #2b93e6 !default;
$mbsc-mobiscroll-background: #ffffff !default;
$mbsc-mobiscroll-text: #454545 !default;

$mbsc-mobiscroll-dark-accent: #2b93e6 !default;
$mbsc-mobiscroll-dark-background: #000 !default;
$mbsc-mobiscroll-dark-text: #ffffff !default;

/* Base colors */
$mbsc-mobiscroll-primary: #3f97f6 !default;
$mbsc-mobiscroll-secondary: #90979E !default;
$mbsc-mobiscroll-success: #43BE5F !default;
$mbsc-mobiscroll-danger: #f5504e !default;
$mbsc-mobiscroll-warning: #f8b042 !default;
$mbsc-mobiscroll-info: #5BB7C5 !default;
$mbsc-mobiscroll-light: darken(#fff, 10%) !default;
$mbsc-mobiscroll-dark: #47494A !default;

$mbsc-mobiscroll-error: #de3226;

/* Form colors */

$mbsc-mobiscroll-form-background: $mbsc-form-background-light !default;
$mbsc-mobiscroll-dark-form-background: $mbsc-form-background-dark !default;
$mbsc-mobiscroll-form-accent: $mbsc-form-accent-light !default;
$mbsc-mobiscroll-dark-form-accent: $mbsc-form-accent-dark !default;
$mbsc-mobiscroll-form-text: $mbsc-form-text-light !default;
$mbsc-mobiscroll-dark-form-text: $mbsc-form-text-dark !default;
$mbsc-mobiscroll-form-error: $mbsc-form-error-light !default;
$mbsc-mobiscroll-dark-form-error: $mbsc-form-error-dark !default;

$mbsc-mobiscroll-colors: ( // Colors map
  'background': $mbsc-mobiscroll-background,
  'text': $mbsc-mobiscroll-text,
  'accent': $mbsc-mobiscroll-accent,
  'form-background': $mbsc-mobiscroll-form-background,
  'form-accent': $mbsc-mobiscroll-form-accent,
  'form-text': $mbsc-mobiscroll-form-text,
  'form-error': $mbsc-mobiscroll-form-error,
);

$mbsc-mobiscroll-dark-colors: ( // Colors map
  'background': $mbsc-mobiscroll-dark-background,
  'text': $mbsc-mobiscroll-dark-text,
  'accent': $mbsc-mobiscroll-dark-accent,
  'form-background': $mbsc-mobiscroll-dark-form-background,
  'form-accent': $mbsc-mobiscroll-dark-form-accent,
  'form-text': $mbsc-mobiscroll-dark-form-text,
  'form-error': $mbsc-mobiscroll-dark-form-error,
);

@function mbsc-mobiscroll-colors($params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  @return ();
}


$mbsc-mobiscroll-button-color: $mbsc-button-color-light !default;
$mbsc-mobiscroll-button-text: $mbsc-button-text-light !default;

$mbsc-mobiscroll-dark-button-color: $mbsc-button-color-dark !default;
$mbsc-mobiscroll-dark-button-text: $mbsc-button-text-dark !default;

$mbsc-mobiscroll-colors: map-merge($mbsc-mobiscroll-colors, ('button-color': $mbsc-mobiscroll-button-color,
    'button-text': $mbsc-mobiscroll-button-text,
  ));

$mbsc-mobiscroll-dark-colors: map-merge($mbsc-mobiscroll-dark-colors, ('button-color': $mbsc-mobiscroll-dark-button-color,
    'button-text': $mbsc-mobiscroll-dark-button-text,
  ));

@mixin mbsc-mobiscroll-button($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $button-background-param: map-get($params, 'button-color');
  $button-text-param: map-get($params, 'button-text');

  $btn-background: if($button-background-param, $button-background-param, $accent);
  $btn-text-base: if($button-background-param, $button-background-param, $background);
  $btn-text: '';

  @if (lightness($btn-text-base) > 50%) {
    $btn-text: darken($btn-text-base, 3%);
  }

  @else {
    $btn-text: $btn-text-base;
  }

  $btn-text: if($button-text-param, $button-text-param, $btn-text);

  .mbsc-#{$theme} {
    &.mbsc-button-standard {
      background: $btn-background;
      color: $btn-text;
    }

    /* Flat buttons */
    &.mbsc-button-flat {
      color: $btn-background;

      &.mbsc-hover {
        background: rgba($btn-background, .2);
      }

      &.mbsc-active {
        background: rgba($btn-background, .3);
      }
    }

    /* Outline buttons */
    &.mbsc-button-outline {
      border: 1px solid $btn-background;
      color: $btn-background;

      &.mbsc-active {
        background: $btn-background;
        color: $background;
      }
    }

    &.mbsc-button.mbsc-focus {
      // background: rgba($accent, .3);
      box-shadow: 0 0 0 2px rgba($text, .5);
    }

    /* Predefined colors */
    &.mbsc-button-primary.mbsc-button-standard {
      background: $mbsc-mobiscroll-primary;
      color: $background;
    }

    &.mbsc-button-secondary.mbsc-button-standard {
      background: $mbsc-mobiscroll-secondary;
      color: $background;
    }

    &.mbsc-button-success.mbsc-button-standard {
      background: $mbsc-mobiscroll-success;
      color: $background;
    }

    &.mbsc-button-danger.mbsc-button-standard {
      background: $mbsc-mobiscroll-danger;
      color: $background;
    }

    &.mbsc-button-warning.mbsc-button-standard {
      background: $mbsc-mobiscroll-warning;
      color: $background;
    }

    &.mbsc-button-info.mbsc-button-standard {
      background: $mbsc-mobiscroll-info;
      color: $background;
    }

    &.mbsc-button-dark.mbsc-button-standard {
      background: $mbsc-mobiscroll-dark;
      color: $background;
    }

    &.mbsc-button-light.mbsc-button-standard {
      background: $mbsc-mobiscroll-light;
      color: $text;
    }

    &.mbsc-button-primary.mbsc-button-flat {
      color: $mbsc-mobiscroll-primary;

      &.mbsc-hover {
        background: rgba($mbsc-mobiscroll-primary, .2);
      }

      &.mbsc-active {
        background: rgba($mbsc-mobiscroll-primary, .3);
      }
    }

    &.mbsc-button-secondary.mbsc-button-flat {
      color: $mbsc-mobiscroll-secondary;

      &.mbsc-hover {
        background: rgba($mbsc-mobiscroll-secondary, .2);
      }

      &.mbsc-active {
        background: rgba($mbsc-mobiscroll-secondary, .3);
      }
    }

    &.mbsc-button-success.mbsc-button-flat {
      color: $mbsc-mobiscroll-success;

      &.mbsc-hover {
        background: rgba($mbsc-mobiscroll-success, .2);
      }

      &.mbsc-active {
        background: rgba($mbsc-mobiscroll-success, .3);
      }
    }

    &.mbsc-button-danger.mbsc-button-flat {
      color: $mbsc-mobiscroll-danger;

      &.mbsc-hover {
        background: rgba($mbsc-mobiscroll-danger, .2);
      }

      &.mbsc-active {
        background: rgba($mbsc-mobiscroll-danger, .3);
      }
    }

    &.mbsc-button-warning.mbsc-button-flat {
      color: $mbsc-mobiscroll-warning;

      &.mbsc-hover {
        background: rgba($mbsc-mobiscroll-warning, .2);
      }

      &.mbsc-active {
        background: rgba($mbsc-mobiscroll-warning, .3);
      }
    }

    &.mbsc-button-info.mbsc-button-flat {
      color: $mbsc-mobiscroll-info;

      &.mbsc-hover {
        background: rgba($mbsc-mobiscroll-info, .2);
      }

      &.mbsc-active {
        background: rgba($mbsc-mobiscroll-info, .3);
      }
    }

    &.mbsc-button-dark.mbsc-button-flat {
      color: $mbsc-mobiscroll-dark;

      &.mbsc-hover {
        background: rgba($mbsc-mobiscroll-dark, .2);
      }

      &.mbsc-active {
        background: rgba($mbsc-mobiscroll-dark, .3);
      }
    }

    &.mbsc-button-light.mbsc-button-flat {
      color: darken($mbsc-mobiscroll-light, 20%);

      &.mbsc-hover {
        background: rgba($mbsc-mobiscroll-light, .2);
      }

      &.mbsc-active {
        background: rgba($mbsc-mobiscroll-light, .3);
      }
    }

    &.mbsc-button-primary.mbsc-button-outline {
      border-color: $mbsc-mobiscroll-primary;
      color: $mbsc-mobiscroll-primary;

      &.mbsc-active {
        background: $mbsc-mobiscroll-primary;
        color: $background;
      }
    }

    &.mbsc-button-secondary.mbsc-button-outline {
      border-color: $mbsc-mobiscroll-secondary;
      color: $mbsc-mobiscroll-secondary;

      &.mbsc-active {
        background: $mbsc-mobiscroll-secondary;
        color: $background;
      }
    }

    &.mbsc-button-success.mbsc-button-outline {
      border-color: $mbsc-mobiscroll-success;
      color: $mbsc-mobiscroll-success;

      &.mbsc-active {
        background: $mbsc-mobiscroll-success;
        color: $background;
      }
    }

    &.mbsc-button-danger.mbsc-button-outline {
      border-color: $mbsc-mobiscroll-danger;
      color: $mbsc-mobiscroll-danger;

      &.mbsc-active {
        background: $mbsc-mobiscroll-danger;
        color: $background;
      }
    }

    &.mbsc-button-warning.mbsc-button-outline {
      border-color: $mbsc-mobiscroll-warning;
      color: $mbsc-mobiscroll-warning;

      &.mbsc-active {
        background: $mbsc-mobiscroll-warning;
        color: $background;
      }
    }

    &.mbsc-button-info.mbsc-button-outline {
      border-color: $mbsc-mobiscroll-info;
      color: $mbsc-mobiscroll-info;

      &.mbsc-active {
        background: $mbsc-mobiscroll-info;
        color: $background;
      }
    }

    &.mbsc-button-dark.mbsc-button-outline {
      border-color: $mbsc-mobiscroll-dark;
      color: $mbsc-mobiscroll-dark;

      &.mbsc-active {
        background: $mbsc-mobiscroll-dark;
        color: $background;
      }
    }

    &.mbsc-button-light.mbsc-button-outline {
      border-color: darken($mbsc-mobiscroll-light, 20%);
      color: darken($mbsc-mobiscroll-light, 20%);

      &.mbsc-active {
        background: darken($mbsc-mobiscroll-light, 20%);
        color: $background;
      }
    }
  }
}


@if ($mbsc-mobiscroll-theme) {
  .mbsc-mobiscroll {
    &.mbsc-icon-button {
      border-radius: 4em;
    }

    &.mbsc-button {
      font-size: 1em;
      margin: .5em;
      padding: .25em .625em;
      line-height: 1.75em;
      border-radius: .25em;
      text-transform: uppercase;
      transition: background-color .2s ease-out;
    }

    &.mbsc-button-standard {
      &.mbsc-hover {
        opacity: .8;
      }

      &.mbsc-active {
        opacity: .6;
      }
    }

    &.mbsc-button-outline {
      &.mbsc-hover {
        opacity: .8;
      }

      &.mbsc-active {
        opacity: 1;
      }
    }

    &.mbsc-button:disabled,
    &.mbsc-button.mbsc-disabled {
      opacity: .3;
    }

    &.mbsc-ltr {
      &.mbsc-button-icon-start {
        padding-right: .625em;
      }

      &.mbsc-button-icon-end {
        padding-left: .625em;
      }
    }

    &.mbsc-rtl {
      &.mbsc-button-icon-start {
        padding-left: .625em;
      }

      &.mbsc-button-icon-end {
        padding-right: .625em;
      }
    }

    .mbsc-button-group,
    .mbsc-button-group-justified {
      margin: .5em;
    }

    .mbsc-button-group-block {
      margin: .5em 1em;
    }
  }

  @include mbsc-mobiscroll-button('mobiscroll', $mbsc-mobiscroll-colors);
  @include mbsc-mobiscroll-button('mobiscroll-dark', $mbsc-mobiscroll-dark-colors);
}



@if ($mbsc-ios-theme) {
  .mbsc-ios {
    &.mbsc-button {
      padding: 0 .5em;
      margin: .5em .25em;
      line-height: 2.25em;
      border-radius: .25em;
      transition: opacity .1s ease-out, background-color .1s ease-out;
    }

    &.mbsc-icon-button {
      padding: .5em;
      line-height: normal;
      border-radius: 4em;
    }

    &.mbsc-button.mbsc-hover {
      opacity: .7;
    }

    &.mbsc-button.mbsc-active {
      opacity: .5;
    }

    &.mbsc-button:disabled,
    &.mbsc-button.mbsc-disabled {
      opacity: .2;
    }

    &.mbsc-button-outline.mbsc-active {
      opacity: 1;
    }

    &.mbsc-ltr {
      &.mbsc-button-icon-start {
        padding-right: .375em;
      }

      &.mbsc-button-icon-end {
        padding-left: .375em;
      }
    }

    &.mbsc-rtl {
      &.mbsc-button-icon-start {
        padding-left: .375em;
      }

      &.mbsc-button-icon-end {
        padding-right: .375em;
      }
    }

    .mbsc-button-group,
    .mbsc-button-group-justified,
    .mbsc-button-group-block {
      margin: .5em .75em;
    }

    .mbsc-button-group-block {
      margin: .5em 1em;
    }
  }

  @include mbsc-ios-button('ios', $mbsc-ios-colors);
  @include mbsc-ios-button('ios-dark', $mbsc-ios-dark-colors);
}



@if ($mbsc-material-theme) {
  .mbsc-material {
    &.mbsc-button {
      min-width: 4.571429em;
      padding: .428572em 1.142858em;
      border-radius: .285715em;
      font-size: .875em;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 1.714286em;
      transition: box-shadow .2s ease-out, background-color .2s ease-out;
      margin: .5em;
    }

    &.mbsc-button-outline {
      border: 1px solid;
    }

    &.mbsc-button .mbsc-icon {
      font-size: 1.142858em;
    }

    &.mbsc-icon-button {
      min-width: 0;
      padding: .5em;
      border-radius: 2em;
      font-size: 1em;
      line-height: normal;
    }

    &.mbsc-icon-button .mbsc-icon {
      width: 1.5em;
      height: 1.5em;
      font-size: 1em;
    }

    &.mbsc-button:disabled,
    &.mbsc-button.mbsc-disabled {
      opacity: .3;
    }

    &.mbsc-ltr {
      &.mbsc-button-icon-start {
        padding-right: .5em;
        margin-left: -.25em;
      }

      &.mbsc-button-icon-end {
        padding-left: .5em;
        margin-right: -.25em;
      }
    }

    &.mbsc-rtl {
      &.mbsc-button-icon-start {
        padding-left: .5em;
        margin-right: -.25em;
      }

      &.mbsc-button-icon-end {
        padding-right: .5em;
        margin-left: -.25em;
      }
    }

    .mbsc-button-group,
    .mbsc-button-group-justified {
      margin: .5em;
    }

    .mbsc-button-group-block {
      margin: .5em 1em;
    }
  }

  @include mbsc-material-button('material', $mbsc-material-colors);
  @include mbsc-material-button('material-dark', $mbsc-material-dark-colors);
}



@if ($mbsc-windows-theme) {
  .mbsc-windows {
    &.mbsc-button {
      padding: 0 .5em;
      line-height: 1.75em;
      margin: .5em .25em;
      border: .125em solid transparent;
      transition: border-color .1s ease-out, background-color .1s ease-out;
    }

    &.mbsc-icon-button {
      width: 2.75em;
      height: 2em;
    }

    &.mbsc-button-flat {
      transition: background-color .1s ease-out;

      &.mbsc-hover {
        opacity: .7;
      }

      &.mbsc-active {
        opacity: 1;
      }
    }

    &.mbsc-button:disabled,
    &.mbsc-button.mbsc-disabled {
      opacity: .3;
    }

    &.mbsc-ltr {
      &.mbsc-button-icon-start {
        padding-right: .5em;
      }

      &.mbsc-button-icon-end {
        padding-left: .5em;
      }
    }

    &.mbsc-rtl {
      &.mbsc-button-icon-start {
        padding-left: .5em;
      }

      &.mbsc-button-icon-end {
        padding-right: .5em;
      }
    }

    .mbsc-button-group,
    .mbsc-button-group-justified {
      margin: .5em .75em;
    }

    .mbsc-button-group-block {
      margin: .5em 1em;
    }
  }

  @include mbsc-windows-button('windows', $mbsc-windows-colors);
  @include mbsc-windows-button('windows-dark', $mbsc-windows-dark-colors);
}


.mbsc-button {
  position: relative;
  z-index: 1;
  display: inline-block;
  vertical-align: middle;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  font-size: 1em;
  box-sizing: border-box;
  touch-action: manipulation;
  user-select: none;
  white-space: nowrap;
  cursor: pointer;
}

.mbsc-button:disabled,
.mbsc-button.mbsc-disabled {
  cursor: not-allowed;
}

.mbsc-button:focus {
  outline: 0;
}

.mbsc-button::-moz-focus-inner {
  border: 0;
}

.mbsc-icon-button {
  width: 2.5em;
  height: 2.5em;
  padding: .5em;
  flex: 0 0 auto;
}

.mbsc-button-icon > *,
.mbsc-button-icon > * > * {
  // For custom icon markup
  height: 100%;
}

.mbsc-button-icon-end {
  // Needed by javascript/jquery component, where end icon is before the button text in the markup
  order: 1;
}

.mbsc-button-group,
.mbsc-button-group-block,
.mbsc-button-group-justified {
  // Prevent margin collision
  border: 1px solid transparent;
}

.mbsc-button-group-block .mbsc-button,
.mbsc-button-block {
  display: block;
  width: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mbsc-button-group-justified,
.mbsc-button-group-justified mbsc-button {
  display: flex;
  flex-wrap: wrap;
}

.mbsc-button-group-justified .mbsc-button,
.mbsc-button-group-justified mbsc-button {
  flex: 1 auto;
}




@mixin mbsc-mobiscroll-form-controls($theme, $params) {}


@if ($mbsc-mobiscroll-theme) {
  .mbsc-mobiscroll {

    &.mbsc-form-control-label.mbsc-disabled,
    &.mbsc-description.mbsc-disabled {
      opacity: .4;
    }
  }

  @include mbsc-mobiscroll-form-controls('mobiscroll', $mbsc-mobiscroll-colors);
  @include mbsc-mobiscroll-form-controls('mobiscroll-dark', $mbsc-mobiscroll-dark-colors);
}



@if ($mbsc-ios-theme) {
  .mbsc-ios {

    &.mbsc-form-control-wrapper {
      margin-top: -1px;
    }

    &.mbsc-form-control-wrapper.mbsc-error {
      z-index: 2;
    }

    &.mbsc-form-control-wrapper:before,
    &.mbsc-form-control-wrapper:after {
      content: '';
      position: absolute;
      border-top: 1px solid transparent;
    }

    &.mbsc-form-control-wrapper.mbsc-ltr:after,
    &.mbsc-form-control-wrapper.mbsc-ltr:before {
      right: 0;
      left: 1em;
    }

    &.mbsc-form-control-wrapper.mbsc-rtl:after,
    &.mbsc-form-control-wrapper.mbsc-rtl:before {
      left: 0;
      right: 1em;
    }

    &.mbsc-form-control-wrapper:before {
      top: 0;
    }

    &.mbsc-form-control-wrapper:after {
      bottom: 0;
    }

    .mbsc-block-title + &.mbsc-form-control-wrapper.mbsc-ltr:before,
    .mbsc-form-group-title + &.mbsc-form-control-wrapper.mbsc-ltr:before,
    &.mbsc-form-control-wrapper.mbsc-ltr:first-child:before,
    &.mbsc-form-control-wrapper.mbsc-ltr:last-child:after {
      left: 0;
    }

    .mbsc-block-title + &.mbsc-form-control-wrapper.mbsc-rtl:before,
    .mbsc-form-group-title + &.mbsc-form-control-wrapper.mbsc-rtl:before,
    &.mbsc-form-control-wrapper.mbsc-rtl:first-child:before,
    &.mbsc-form-control-wrapper.mbsc-rtl:last-child:after {
      right: 0;
    }

    &.mbsc-form-control-label.mbsc-disabled,
    &.mbsc-description.mbsc-disabled {
      opacity: .3;
    }

    .mbsc-form-group-inset .mbsc-form-group-title + .mbsc-form-control-wrapper,
    .mbsc-form-group-inset .mbsc-form-control-wrapper:first-child {
      border-top-left-radius: .5em;
      border-top-right-radius: .5em;
    }

    .mbsc-form-group-inset .mbsc-form-control-wrapper:last-child {
      border-bottom-left-radius: .5em;
      border-bottom-right-radius: .5em;
    }

    .mbsc-form-group-inset .mbsc-form-group-title,
    .mbsc-form-group-inset .mbsc-form-group-title + .mbsc-form-control-wrapper:before,
    .mbsc-form-group-inset .mbsc-form-control-wrapper:first-child:before,
    .mbsc-form-group-inset .mbsc-form-control-wrapper:last-child:after {
      border-width: 0;
    }
  }

  @include mbsc-ios-form-controls('ios', $mbsc-ios-colors);
  @include mbsc-ios-form-controls('ios-dark', $mbsc-ios-dark-colors);
}



@if ($mbsc-material-theme) {
  .mbsc-material {

    &.mbsc-form-control-label.mbsc-disabled,
    &.mbsc-description.mbsc-disabled {
      opacity: .3;
    }
  }

  @include mbsc-material-form-controls('material', $mbsc-material-colors);
  @include mbsc-material-form-controls('material-dark', $mbsc-material-dark-colors);
}



@if ($mbsc-windows-theme) {
  .mbsc-windows {

    &.mbsc-form-control-label.mbsc-disabled,
    &.mbsc-description.mbsc-disabled {
      opacity: .3;
    }
  }

  @include mbsc-windows-form-controls('windows', $mbsc-windows-colors);
  @include mbsc-windows-form-controls('windows-dark', $mbsc-windows-dark-colors);
}


.mbsc-form-control-label {
  display: block;
}

.mbsc-form-control-input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 0;
  opacity: 0;
  margin: 0;
  z-index: 3;
}



@mixin mbsc-mobiscroll-checkbox($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $bg-param: map-get($params, 'form-background');
  $text-param: map-get($params, 'form-text');
  $acc-param: map-get($params, 'form-accent');

  $background: if($bg-param, $bg-param, $background);
  $text: if($text-param, $text-param, $text);
  $accent: if($acc-param, $acc-param, $accent);

  $checkbox: '';
  $input-disabled: '';

  @if (lightness($background) > 50%) {
    $checkbox: lighten($background, 3%);
    $input-disabled: darken($background, 13%);
  }

  @else {
    $checkbox: $background;
    $input-disabled: lighten($background, 17%);
  }

  .mbsc-#{$theme} {
    &.mbsc-checkbox-box {
      color: $accent;
      border-color: $text;
    }

    &.mbsc-checkbox-box:after {
      border-color: $checkbox;
    }

    &.mbsc-checkbox-box.mbsc-disabled {
      border-color: $input-disabled;
      background: $input-disabled;
    }
  }
}


@if ($mbsc-mobiscroll-theme) {
  .mbsc-mobiscroll {

    &.mbsc-checkbox-right {
      padding: 1em 3.125em 1em 1em;
    }

    &.mbsc-checkbox-left {
      padding: 1em 1em 1em 3.125em;
    }

    &.mbsc-checkbox-box {
      margin-top: -.5625em;
      width: 1.125em;
      height: 1.125em;
      border: .125em solid;
      border-radius: .125em;
    }

    &.mbsc-checkbox-box-right {
      right: 1em;
    }

    &.mbsc-checkbox-box-left {
      left: 1em;
    }

    &.mbsc-checkbox-box:after {
      top: .125em;
      left: .0625em;
      width: .8125em;
      height: .4375em;
      opacity: 1;
      border: .125em solid;
      border-top: 0;
      border-right: 0;
      transform: scale(0) rotate(-45deg);
      transition: transform .1s ease-out;
    }

    &.mbsc-checkbox-box.mbsc-checked {
      background: currentColor;
      border-color: currentColor;
    }

    &.mbsc-checkbox-box.mbsc-checked:after {
      transform: scale(1) rotate(-45deg);
    }

    /* Color presets */

    &.mbsc-checkbox-box.mbsc-checkbox-box-primary {
      color: $mbsc-mobiscroll-primary;
    }

    &.mbsc-checkbox-box.mbsc-checkbox-box-secondary {
      color: $mbsc-mobiscroll-secondary;
    }

    &.mbsc-checkbox-box.mbsc-checkbox-box-success {
      color: $mbsc-mobiscroll-success;
    }

    &.mbsc-checkbox-box.mbsc-checkbox-box-danger {
      color: $mbsc-mobiscroll-danger;
    }

    &.mbsc-checkbox-box.mbsc-checkbox-box-warning {
      color: $mbsc-mobiscroll-warning;
    }

    &.mbsc-checkbox-box.mbsc-checkbox-box-info {
      color: $mbsc-mobiscroll-info;
    }
  }

  @include mbsc-mobiscroll-checkbox('mobiscroll', $mbsc-mobiscroll-colors);
  @include mbsc-mobiscroll-checkbox('mobiscroll-dark', $mbsc-mobiscroll-dark-colors);
}



@if ($mbsc-ios-theme) {
  .mbsc-ios {

    &.mbsc-checkbox-right {
      padding: .875em 3.75em .875em 1em;
    }

    &.mbsc-checkbox-left {
      padding: .875em 1em .875em 3.75em;
    }

    &.mbsc-checkbox-box {
      width: 1.75em;
      height: 1.75em;
      margin-top: -.875em;
      border: .125em solid currentColor;
      border-radius: 2em;
    }

    &.mbsc-checkbox-box-right {
      right: 1em;
    }

    &.mbsc-checkbox-box-left {
      left: 1em;
    }

    &.mbsc-checkbox-box:after {
      top: 32%;
      left: 26%;
      width: .75em;
      height: .375em;
      border: .125em solid currentColor;
      border-top: 0;
      border-right: 0;
      transition: opacity .2s ease-in-out;
    }

    &.mbsc-checkbox-box:before {
      content: '';
      position: absolute;
      top: -.5em;
      left: -.5em;
      z-index: -1;
      width: 2.5em;
      height: 2.5em;
      opacity: 0;
      border-radius: 2.5em;
      background: #ccc;
      transition: opacity .2s ease-in-out;
    }

    &.mbsc-checkbox-box.mbsc-focus:before {
      opacity: .12;
    }

    &.mbsc-checkbox-box.mbsc-disabled {
      opacity: .3;
    }

    /* Color presets */

    &.mbsc-checkbox-box.mbsc-checkbox-box-primary {
      color: $mbsc-ios-primary;
    }

    &.mbsc-checkbox-box.mbsc-checkbox-box-secondary {
      color: $mbsc-ios-secondary;
    }

    &.mbsc-checkbox-box.mbsc-checkbox-box-success {
      color: $mbsc-ios-success;
    }

    &.mbsc-checkbox-box.mbsc-checkbox-box-danger {
      color: $mbsc-ios-danger;
    }

    &.mbsc-checkbox-box.mbsc-checkbox-box-warning {
      color: $mbsc-ios-warning;
    }

    &.mbsc-checkbox-box.mbsc-checkbox-box-info {
      color: $mbsc-ios-info;
    }

  }

  @include mbsc-ios-checkbox('ios', $mbsc-ios-colors);
  @include mbsc-ios-checkbox('ios-dark', $mbsc-ios-dark-colors);
}



@if ($mbsc-material-theme) {
  .mbsc-material {

    &.mbsc-checkbox-right {
      padding: .9375em 3.5em .9375em 1em;
    }

    &.mbsc-checkbox-left {
      padding: .9375em 1em .9375em 3.5em;
    }

    &.mbsc-checkbox-box {
      border-radius: .1875em;
      width: 1.125em;
      height: 1.125em;
      margin-top: -.5625em;
      border: .125em solid;
      transition: background-color .1s ease-out;
    }

    &.mbsc-checkbox-box-right {
      right: 1.25em;
    }

    &.mbsc-checkbox-box-left {
      left: 1.25em;
    }

    &.mbsc-checkbox-box:after {
      top: .125em;
      left: .0625em;
      width: .8125em;
      height: .4375em;
      opacity: 1;
      border: .125em solid;
      border-top: 0;
      border-right: 0;
      transform: scale(0) rotate(-45deg);
      transition: transform .1s ease-out;
    }

    &.mbsc-checkbox-box:before {
      content: '';
      position: absolute;
      top: -.8125em;
      left: -.8125em;
      z-index: -1;
      width: 2.5em;
      height: 2.5em;
      opacity: 0;
      border-radius: 2.5em;
      background: currentColor;
      transition: opacity .2s ease-in-out;
    }

    &.mbsc-checkbox-box.mbsc-focus:before {
      opacity: .12;
    }

    &.mbsc-checkbox-box.mbsc-active:before {
      opacity: .2;
    }

    &.mbsc-checkbox-box.mbsc-checked {
      background: currentColor;
      border-color: currentColor;
    }

    &.mbsc-checkbox-box.mbsc-checked:after {
      transform: scale(1) rotate(-45deg);
    }

    &.mbsc-checkbox-box.mbsc-disabled {
      opacity: .3;
    }

    /* Color presets */

    &.mbsc-checkbox-box.mbsc-checkbox-box-primary {
      color: $mbsc-material-primary;
    }

    &.mbsc-checkbox-box.mbsc-checkbox-box-secondary {
      color: $mbsc-material-secondary;
    }

    &.mbsc-checkbox-box.mbsc-checkbox-box-success {
      color: $mbsc-material-success;
    }

    &.mbsc-checkbox-box.mbsc-checkbox-box-danger {
      color: $mbsc-material-danger;
    }

    &.mbsc-checkbox-box.mbsc-checkbox-box-warning {
      color: $mbsc-material-warning;
    }

    &.mbsc-checkbox-box.mbsc-checkbox-box-info {
      color: $mbsc-material-info;
    }
  }

  @include mbsc-material-checkbox('material', $mbsc-material-colors);
  @include mbsc-material-checkbox('material-dark', $mbsc-material-dark-colors);
}



@if ($mbsc-windows-theme) {
  .mbsc-windows {

    &.mbsc-checkbox-left {
      padding: 1.125em 1em 1.125em 2.875em;
    }

    &.mbsc-checkbox-right {
      padding: 1.125em 2.875em 1.125em 1em;
    }

    &.mbsc-checkbox-box {
      margin-top: -.6875em;
      width: 1.3125em;
      height: 1.3125em;
      border: .125em solid;
    }

    &.mbsc-checkbox-box-left {
      left: 1em;
    }

    &.mbsc-checkbox-box-right {
      right: 1em;
    }

    &.mbsc-checkbox-box:after {
      top: 16%;
      left: 10%;
      width: .875em;
      height: .475em;
      border: .125em solid;
      border-top: 0;
      border-right: 0;
    }

    &.mbsc-checkbox-box:before {
      content: '';
      position: absolute;
      top: -.75em;
      left: -.75em;
      z-index: -1;
      width: 2.5em;
      height: 2.5em;
      opacity: 0;
      border-radius: 2.5em;
      transition: opacity .2s ease-in-out;
    }

    &.mbsc-checkbox-box.mbsc-focus:before {
      opacity: .12;
    }

    &.mbsc-checkbox-box.mbsc-checked {
      background: currentColor;
      border-color: currentColor;
    }

    &.mbsc-checkbox-box.mbsc-disabled {
      opacity: .3;
    }

    /* Color presets */

    &.mbsc-checkbox-box.mbsc-checkbox-box-primary {
      color: $mbsc-windows-primary;
    }

    &.mbsc-checkbox-box.mbsc-checkbox-box-secondary {
      color: $mbsc-windows-secondary;
    }

    &.mbsc-checkbox-box.mbsc-checkbox-box-success {
      color: $mbsc-windows-success;
    }

    &.mbsc-checkbox-box.mbsc-checkbox-box-danger {
      color: $mbsc-windows-danger;
    }

    &.mbsc-checkbox-box.mbsc-checkbox-box-warning {
      color: $mbsc-windows-warning;
    }

    &.mbsc-checkbox-box.mbsc-checkbox-box-info {
      color: $mbsc-windows-info;
    }
  }

  @include mbsc-windows-checkbox('windows', $mbsc-windows-colors);
  @include mbsc-windows-checkbox('windows-dark', $mbsc-windows-dark-colors);
}


.mbsc-checkbox {
  line-height: 1.25em;
  position: relative;
  display: block;
  margin: 0;
  z-index: 0;
  user-select: none;
}

.mbsc-checkbox-box {
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  display: block;
  width: 1.375em;
  height: 1.375em;
}

.mbsc-checkbox-box:after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  display: block;
  opacity: 0;
  transform: rotate(-45deg);
}

.mbsc-checkbox-box.mbsc-checked:after {
  opacity: 1;
}

.mbsc-description {
  display: block;
  font-size: .75em;
  opacity: .6;
}



@if ($mbsc-ios-theme) {
  .mbsc-ios {

    /* Wrapper */

    &.mbsc-textfield-wrapper-has-icon-right.mbsc-rtl,
    &.mbsc-textfield-wrapper-has-icon-left.mbsc-ltr {
      z-index: 1;
    }

    &.mbsc-textfield-wrapper-has-icon-left.mbsc-ltr:before,
    &.mbsc-textfield-wrapper-has-icon-left.mbsc-ltr:after {
      left: 3.375em;

    }

    &.mbsc-textfield-wrapper-has-icon-right.mbsc-rtl:after,
    &.mbsc-textfield-wrapper-has-icon-right.mbsc-rtl:before {
      right: 3.375em;
    }

    /* Inner element */

    &.mbsc-textfield-inner {
      position: static;
      flex: 1 auto;
      z-index: -1;
    }

    &.mbsc-textfield-inner.mbsc-disabled {
      opacity: .5;
    }

    /* Form element */

    &.mbsc-textfield {
      display: flex;
      height: 2.75em;
      padding: 0 1em;
      background: transparent;
      height: 2.75em;
    }

    &.mbsc-textfield-has-icon-left {
      padding-left: 3.375em;
    }

    &.mbsc-textfield-has-icon-right {
      padding-right: 3.375em;
    }

    &.mbsc-textfield-stacked,
    &.mbsc-textfield-floating {
      height: 3.5em;
      padding-top: 1.25em;
    }

    /* Icons */

    &.mbsc-textfield-icon {
      top: .625em;
      z-index: 1;
    }

    &.mbsc-textfield-icon-left {
      left: .9375em;
    }

    &.mbsc-textfield-icon-right {
      right: .9375em;
    }

    &.mbsc-textfield-icon-floating,
    &.mbsc-textfield-icon-stacked {
      top: 1em;
    }

    /* Label */

    &.mbsc-label-inline {
      line-height: 2.75em;
    }

    &.mbsc-label-inline.mbsc-ltr {
      padding-left: 1em;
    }

    &.mbsc-label-inline.mbsc-rtl {
      padding-right: 1em;
    }

    &.mbsc-label-stacked {
      top: .666667em;
      font-size: .75em;
      line-height: 1em;
    }

    &.mbsc-label-stacked.mbsc-ltr {
      left: 1.333334em;
    }

    &.mbsc-label-stacked.mbsc-rtl {
      right: 1.333334em;
    }

    &.mbsc-label-floating {
      top: .875em;
      line-height: 2em;
    }

    &.mbsc-label-floating.mbsc-ltr {
      left: 1em;
    }

    &.mbsc-label-floating.mbsc-rtl {
      right: 1em;
    }

    &.mbsc-label-floating-active.mbsc-label {
      transform: translateY(-.75em) scale(.75);
    }

    &.mbsc-label.mbsc-disabled {
      opacity: .5;
    }

    /* Error message */

    &.mbsc-error-message {
      display: block;
      padding: 0 1.333334em;
      line-height: 1.666667em;
    }

    &.mbsc-error-message-underline {
      position: static;
    }

    &.mbsc-error-message-has-icon-left.mbsc-ltr {
      padding-left: 4.5em;
    }

    &.mbsc-error-message-has-icon-right.mbsc-rtl {
      padding-right: 4.5em;
    }

    /* Underline input --------------------------------------------------------------------------- */

    .mbsc-textfield-wrapper-underline {
      // Cut corners of select dropdown in case of inset form group
      overflow: hidden;
    }

    /* Form element*/

    &.mbsc-textfield-underline-inline-has-icon-left.mbsc-ltr {
      padding-left: 1em;
    }

    &.mbsc-textfield-underline-inline-has-icon-right.mbsc-rtl {
      padding-right: 1em;
    }

    /* Label */

    &.mbsc-label-underline {
      margin: 0;
    }

    &.mbsc-label-underline-stacked-has-icon-left.mbsc-ltr {
      left: 4.5em;
    }

    &.mbsc-label-underline-stacked-has-icon-right.mbsc-rtl {
      right: 4.5em;
    }

    &.mbsc-label-underline-inline-has-icon-left.mbsc-ltr {
      padding-left: 3.375em;
    }

    &.mbsc-label-underline-inline-has-icon-right.mbsc-rtl {
      padding-right: 3.375em;
    }

    &.mbsc-label-underline-floating-has-icon-left.mbsc-ltr {
      left: 3.375em;
    }

    &.mbsc-label-underline-floating-has-icon-right.mbsc-rtl {
      right: 3.375em;
    }

    /* Error message */

    &.mbsc-error-message-underline.mbsc-error-message-inline.mbsc-ltr {
      padding-left: 1.333334em;
    }

    &.mbsc-error-message-underline.mbsc-error-message-inline.mbsc-rtl {
      padding-right: 1.333334em;
    }


    /* ------------------------------------------------------------------------------------------- */


    /* Box & outline input common styles --------------------------------------------------------- */

    /* Wrapper */

    &.mbsc-textfield-wrapper-outline,
    &.mbsc-textfield-wrapper-box {
      margin: 1.5em 1em;
    }

    &.mbsc-textfield-wrapper-outline.mbsc-font::after,
    &.mbsc-textfield-wrapper-outline.mbsc-font::before,
    &.mbsc-textfield-wrapper-box.mbsc-font::after,
    &.mbsc-textfield-wrapper-box.mbsc-font::before {
      border: none;
    }

    /* Form element */

    &.mbsc-textfield-box,
    &.mbsc-textfield-outline {
      border-radius: .5em;
      border: 1px solid transparent;
    }

    &.mbsc-textfield-inner-box,
    &.mbsc-textfield-inner-outline {
      position: relative;
    }

    /* Label */

    &.mbsc-label-box-stacked-has-icon-left.mbsc-ltr,
    &.mbsc-label-outline-stacked-has-icon-left.mbsc-ltr {
      left: 4.5em;
    }

    &.mbsc-label-outline-stacked-has-icon-right.mbsc-rtl,
    &.mbsc-label-box-stacked-has-icon-right.mbsc-rtl {
      right: 4.5em;
    }

    &.mbsc-label-box-stacked,
    &.mbsc-label-outline-stacked,
    &.mbsc-label-box-floating,
    &.mbsc-label-outline-floating {
      margin: 0 1px;
    }

    &.mbsc-label-outline-floating-has-icon-left.mbsc-ltr,
    &.mbsc-label-box-floating-has-icon-left.mbsc-ltr {
      left: 3.375em;
    }

    &.mbsc-label-outline-floating-has-icon-right.mbsc-rtl,
    &.mbsc-label-box-floating-has-icon-right.mbsc-rtl {
      right: 3.375em;
    }

    /* Error message */

    &.mbsc-error-message-outline,
    &.mbsc-error-message-box {
      margin: 0 1px;
    }

    /* ------------------------------------------------------------------------------------------- */

    /* Select */

    &.mbsc-select.mbsc-ltr {
      padding-right: 3.375em;
    }

    &.mbsc-select.mbsc-rtl {
      padding-left: 3.375em;
    }

    &.mbsc-select.mbsc-textfield-has-icon-right.mbsc-ltr {
      padding-right: 4.875em;
    }

    &.mbsc-select.mbsc-textfield-has-icon-left.mbsc-rtl {
      padding-left: 4.875em;
    }

    /* Select icon */

    &.mbsc-select-icon {
      top: .625em;
    }

    &.mbsc-select-icon-stacked,
    &.mbsc-select-icon-floating {
      top: 1em;
    }

    &.mbsc-select-icon.mbsc-ltr {
      right: .9375em;
    }

    &.mbsc-select-icon.mbsc-rtl {
      left: .9375em;
    }

    &.mbsc-select-icon-right.mbsc-ltr {
      right: 3.375em;
    }

    &.mbsc-select-icon-left.mbsc-rtl {
      left: 3.375em;
    }

    /* Textarea */

    &.mbsc-textarea {
      height: 3em;
      border: 0;
    }

    &.mbsc-textarea.mbsc-textfield-stacked,
    &.mbsc-textarea.mbsc-textfield-floating {
      padding-top: 0;
    }

    &.mbsc-textarea-inner {
      padding-top: .625em;
      padding-bottom: .625em;
    }

    &.mbsc-textarea-inner.mbsc-textfield-inner-box,
    &.mbsc-textarea-inner.mbsc-textfield-inner-outline {
      border: 1px solid transparent;
      border-radius: .5em;
    }

    &.mbsc-textarea-inner.mbsc-textfield-inner-stacked,
    &.mbsc-textarea-inner.mbsc-textfield-inner-floating {
      padding-top: 1.375em;
    }

    /* Input tags */

    &.mbsc-textfield-tags-inner {
      padding-top: 0;
      padding-bottom: 0;
    }

    &.mbsc-textfield.mbsc-textfield-tags {
      padding-top: .3125em;
      padding-bottom: .3125em;
      min-height: 2.75em;
    }

    &.mbsc-textfield-tags.mbsc-textfield-stacked,
    &.mbsc-textfield-tags.mbsc-textfield-floating {
      min-height: 2.125em;
      padding-top: 0;
      padding-bottom: 0;
    }

    &.mbsc-textfield-tag {
      border-radius: 1em;
    }

  }

  @include mbsc-ios-input('ios', $mbsc-ios-colors);
  @include mbsc-ios-input('ios-dark', $mbsc-ios-dark-colors);
}



@if ($mbsc-material-theme) {
  .mbsc-material {

    /* Wrapper */

    &.mbsc-textfield-wrapper {
      margin: 1.5em 1em;
      box-sizing: border-box;
    }

    /* Inner wrapper */

    &.mbsc-textfield-inner.mbsc-disabled {
      opacity: .5;
    }

    /* Form element */

    &.mbsc-textfield {
      display: block;
      width: 100%;
      height: 2.25em;
      background-color: transparent;
      border-bottom: 1px solid;
      font-size: 1em;
      transition: border-color .2s;
    }

    &.mbsc-textfield-has-icon-left {
      padding-left: 2em;
    }

    &.mbsc-textfield-has-icon-right {
      padding-right: 2em;
    }

    /* Icon */

    &.mbsc-textfield-icon {
      top: .375em;
    }

    &.mbsc-textfield-icon-floating,
    &.mbsc-textfield-icon-stacked,
    &.mbsc-textfield-icon-outline {
      top: 1em;
    }

    /* Ripple */

    .mbsc-textfield-ripple {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      transform: scaleX(0);
      transition: transform 180ms cubic-bezier(.4, 0, .2, 1);
      z-index: 2;
    }

    .mbsc-textfield-ripple-active {
      transform: scaleX(1);
    }

    /* Label */

    &.mbsc-label {
      font-size: .75em;
    }

    &.mbsc-label-inline {
      line-height: 2em;
      font-size: 1em;
    }

    &.mbsc-label-inline.mbsc-ltr {
      padding-right: .5em;
    }

    &.mbsc-label-inline.mbsc-rtl {
      padding-left: .5em;
    }

    &.mbsc-label-floating {
      font-size: 1em;
      line-height: 1.5em;
      top: 1.125em;
    }

    &.mbsc-label-floating-active {
      transform: translateY(-1.125em) scale(.75);
    }

    &.mbsc-label.mbsc-disabled {
      opacity: .5;
    }

    /* Error message */

    &.mbsc-error-message.mbsc-ltr {
      left: 0;
    }

    &.mbsc-error-message.mbsc-rtl {
      right: 0;
    }

    &.mbsc-error-message-has-icon-left.mbsc-ltr {
      left: 2.66667em;
    }

    &.mbsc-error-message-has-icon-right.mbsc-rtl {
      right: 2.66667em;
    }


    /* Underline input --------------------------------------------------------------------------- */

    /* Form element */

    &.mbsc-textfield-underline-stacked,
    &.mbsc-textfield-underline-floating {
      height: 3em;
      padding-top: .875em;
    }

    /* Icon */

    &.mbsc-textfield-icon-underline {
      top: .25em;
    }

    &.mbsc-textfield-icon-underline.mbsc-textfield-icon-floating,
    &.mbsc-textfield-icon-underline.mbsc-textfield-icon-stacked {
      top: 1.125em;
    }

    /* Label */

    &.mbsc-label-underline-inline {
      padding-top: 1px;
    }

    &.mbsc-label-underline-stacked-has-icon-left.mbsc-ltr {
      left: 2.66667em;
    }

    &.mbsc-label-underline-stacked-has-icon-right.mbsc-rtl {
      right: 2.66667em;
    }

    &.mbsc-label-underline-floating-has-icon-left.mbsc-ltr {
      left: 2em;
    }

    &.mbsc-label-underline-floating-has-icon-right.mbsc-rtl {
      right: 2em;
    }

    /* ------------------------------------------------------------------------------------------- */


    /* Box input --------------------------------------------------------------------------------- */

    /* Inner wrapper */

    &.mbsc-textfield-box,
    &.mbsc-textfield-inner-box {
      border-radius: .25em .25em 0 0;
    }

    /* Form element */

    &.mbsc-textfield-box {
      padding: 0 1em;
    }

    &.mbsc-textfield-box-stacked,
    &.mbsc-textfield-box-floating {
      height: 3.5em;
      padding-top: 1.25em;
    }

    &.mbsc-textfield-underline.mbsc-disabled {
      border-style: dotted;
    }

    /* Label */

    &.mbsc-label-box-inline {
      padding-top: 1px;
    }

    &.mbsc-label-box-stacked {
      top: .666667em;
    }

    &.mbsc-label-box-floating {
      top: 1em;
    }

    &.mbsc-label-box-floating.mbsc-label-floating-active {
      transform: translateY(-.625em) scale(.75);
    }

    /* ------------------------------------------------------------------------------------------- */


    /* Outline input ----------------------------------------------------------------------------- */

    /* Inner wrapper */

    &.mbsc-textfield-inner-outline {
      padding: 0 2px;
    }

    /* Form element */

    &.mbsc-textfield-outline {
      height: 3.5em;
      padding: 2px 1em;
      border: 0;
      border-radius: 4px;
    }

    &.mbsc-textfield-fieldset {
      top: -.5em;
      border: 1px solid;
      border-radius: 4px;
      transition: border-color .2s;
    }

    &.mbsc-textfield-fieldset-has-icon-left {
      padding-left: 3em;
    }

    &.mbsc-textfield-fieldset-has-icon-right {
      padding-right: 3em;
    }

    &.mbsc-textfield-fieldset.mbsc-focus {
      border-width: 2px;
    }

    &.mbsc-textfield-legend {
      padding: 0;
      margin: 0;
      font-size: .75em;
      color: transparent;
      width: 0.01px; // Fraction needed for IE11
      white-space: nowrap;
    }

    &.mbsc-textfield-legend-active {
      width: auto;
      padding: 0 .333334em;
      margin: 0 -.333334em;
    }

    /* Label */

    &.mbsc-label-outline-inline {
      line-height: 3.375em;
    }

    &.mbsc-label-outline-stacked {
      top: -.5em;
      margin: 0 2px; // Compensate borders
    }

    &.mbsc-label-outline-floating {
      top: 1em;
      margin: 0 2px; // Compensate borders
    }

    &.mbsc-label-outline-floating.mbsc-label-floating-active {
      margin-top: -1px;
      transform: translateY(-1.5em) scale(.75);
    }

    /* Error message */

    &.mbsc-error-message-outline {
      margin: 0 2px; // Compensate border
    }

    /* ------------------------------------------------------------------------------------------- */


    /* Box & outline input common styles --------------------------------------------------------- */

    /* Form element */

    &.mbsc-textfield-outline-has-icon-left,
    &.mbsc-textfield-box-has-icon-left {
      padding-left: 3em;
    }

    &.mbsc-textfield-outline-has-icon-right,
    &.mbsc-textfield-box-has-icon-right {
      padding-right: 3em;
    }

    /* Icon */

    &.mbsc-textfield-icon-outline-left,
    &.mbsc-textfield-icon-box-left {
      left: .75em;
    }

    &.mbsc-textfield-icon-outline-right,
    &.mbsc-textfield-icon-box-right {
      right: .75em;
    }

    /* Label */

    &.mbsc-label-box-stacked.mbsc-ltr,
    &.mbsc-label-outline-stacked.mbsc-ltr {
      left: 1.333334em;
    }

    &.mbsc-label-box-stacked.mbsc-rtl,
    &.mbsc-label-outline-stacked.mbsc-rtl {
      right: 1.333334em;
    }

    &.mbsc-label-outline-stacked-has-icon-left.mbsc-ltr,
    &.mbsc-label-box-stacked-has-icon-left.mbsc-ltr {
      left: 4em;
    }

    &.mbsc-label-box-stacked-has-icon-right.mbsc-rtl,
    &.mbsc-label-outline-stacked-has-icon-right.mbsc-rtl {
      right: 4em;
    }

    &.mbsc-label-box-floating.mbsc-ltr,
    &.mbsc-label-outline-floating.mbsc-ltr {
      left: 1em;
    }

    &.mbsc-label-box-floating.mbsc-rtl,
    &.mbsc-label-outline-floating.mbsc-rtl {
      right: 1em;
    }

    &.mbsc-label-outline-floating-has-icon-left.mbsc-ltr,
    &.mbsc-label-box-floating-has-icon-left.mbsc-ltr {
      left: 3em;
    }

    &.mbsc-label-outline-floating-has-icon-left.mbsc-rtl,
    &.mbsc-label-box-floating-has-icon-left.mbsc-rtl {
      right: 1em;
    }

    &.mbsc-label-outline-floating-has-icon-right.mbsc-rtl,
    &.mbsc-label-box-floating-has-icon-right.mbsc-rtl {
      right: 3em;
    }

    /* Error message */

    &.mbsc-error-message-box.mbsc-ltr,
    &.mbsc-error-message-outline.mbsc-ltr {
      left: 1.333334em;
    }

    &.mbsc-error-message-box.mbsc-rtl,
    &.mbsc-error-message-outline.mbsc-rtl {
      right: 1.333334em;
    }

    &.mbsc-error-message-box.mbsc-error-message-has-icon-left.mbsc-ltr,
    &.mbsc-error-message-outline.mbsc-error-message-has-icon-left.mbsc-ltr {
      left: 4em;
    }

    &.mbsc-error-message-box.mbsc-error-message-has-icon-right.mbsc-rtl,
    &.mbsc-error-message-outline.mbsc-error-message-has-icon-right.mbsc-rtl {
      right: 4em;
    }

    /* ------------------------------------------------------------------------------------------- */

    /* Select */

    &.mbsc-select.mbsc-ltr {
      padding-right: 3em;
    }

    &.mbsc-select.mbsc-rtl {
      padding-left: 3em;
    }

    &.mbsc-select.mbsc-textfield-has-icon-right.mbsc-ltr {
      padding-right: 4.5em;
    }

    &.mbsc-select.mbsc-textfield-has-icon-left.mbsc-rtl {
      padding-left: 4.5em;
    }

    &.mbsc-select.mbsc-textfield-underline-has-icon-right.mbsc-ltr {
      padding-right: 3.75em;
    }

    &.mbsc-select.mbsc-textfield-underline-has-icon-left.mbsc-rtl {
      padding-left: 3.75em;
    }

    /* Select icon */

    &.mbsc-select-icon {
      top: .375em;
    }

    &.mbsc-select-icon.mbsc-ltr {
      right: .75em;
    }

    &.mbsc-select-icon.mbsc-rtl {
      left: .75em;
    }

    &.mbsc-select-icon-right.mbsc-ltr {
      right: 3em;
    }

    &.mbsc-select-icon-left.mbsc-rtl {
      left: 3em;
    }

    &.mbsc-select-icon-stacked,
    &.mbsc-select-icon-floating,
    &.mbsc-select-icon-outline {
      top: 1em;
    }

    &.mbsc-select-icon-underline {
      top: .25em;
    }

    &.mbsc-select-icon-underline.mbsc-ltr {
      right: 0;
    }

    &.mbsc-select-icon-underline.mbsc-rtl {
      left: 0;
    }

    &.mbsc-select-icon-underline.mbsc-select-icon-right.mbsc-ltr {
      right: 2.25em;
    }

    &.mbsc-select-icon-underline.mbsc-select-icon-left.mbsc-rtl {
      left: 2.25em;
    }

    &.mbsc-select-icon-underline.mbsc-select-icon-floating,
    &.mbsc-select-icon-underline.mbsc-select-icon-stacked {
      top: 1.125em;
    }

    /* Textarea */

    &.mbsc-textarea {
      height: 1.875em;
      padding-bottom: .375em;
    }

    &.mbsc-textarea.mbsc-textfield-stacked,
    &.mbsc-textarea.mbsc-textfield-floating {
      padding-top: 0;
    }

    &.mbsc-textarea.mbsc-textfield-outline {
      height: 1.5em;
      padding-top: 0;
      padding-bottom: 0;
    }

    &.mbsc-textarea.mbsc-textfield-underline {
      padding-bottom: .3125em;
    }

    &.mbsc-textarea-inner.mbsc-textfield-inner-box {
      padding-top: .375em;
    }

    &.mbsc-textarea-inner.mbsc-textfield-inner-stacked,
    &.mbsc-textarea-inner.mbsc-textfield-inner-floating {
      padding-top: 1.625em;
    }

    &.mbsc-textarea-inner.mbsc-textfield-inner-outline {
      padding-top: 1em;
      padding-bottom: 1em;
    }

    &.mbsc-textarea-inner.mbsc-textfield-inner-underline {
      padding-top: .25em;
    }

    &.mbsc-textarea-inner.mbsc-textfield-inner-underline.mbsc-textfield-inner-stacked,
    &.mbsc-textarea-inner.mbsc-textfield-inner-underline.mbsc-textfield-inner-floating {
      padding-top: 1.125em;
    }

    /* Input tags */

    &.mbsc-textfield-tags-inner.mbsc-textfield-inner-underline,
    &.mbsc-textfield-tags-inner.mbsc-textfield-inner-inline {
      padding-top: 0;
    }

    &.mbsc-textfield-tags-inner.mbsc-textfield-inner-outline {
      padding-top: .625em;
      padding-bottom: .625em;
    }

    &.mbsc-textfield-tags.mbsc-textfield {
      padding-bottom: 0;
      min-height: 2.25em;
    }
    
    &.mbsc-textfield-tags.mbsc-textfield-outline {
      padding-top: 1px;
      padding-bottom: 1px;
    }

    &.mbsc-textfield-tag {
      border-radius: 1em;
    }

  }

  @include mbsc-material-input('material', $mbsc-material-colors);
  @include mbsc-material-input('material-dark', $mbsc-material-dark-colors);
}



$mbsc-mobiscroll-input-background: $mbsc-input-background-light !default;
$mbsc-mobiscroll-input-text: $mbsc-input-text-light !default;
$mbsc-mobiscroll-input-accent: $mbsc-input-accent-light !default;
$mbsc-mobiscroll-input-border: $mbsc-input-border-light !default;

$mbsc-mobiscroll-dark-input-background: $mbsc-input-background-dark !default;
$mbsc-mobiscroll-dark-input-text: $mbsc-input-text-dark !default;
$mbsc-mobiscroll-dark-input-accent: $mbsc-input-accent-dark !default;
$mbsc-mobiscroll-dark-input-border: $mbsc-input-border-dark !default;

$mbsc-mobiscroll-colors: map-merge($mbsc-mobiscroll-colors, ( // Colors map
    'input-background': $mbsc-mobiscroll-input-background,
    'input-text': $mbsc-mobiscroll-input-text,
    'input-accent': $mbsc-mobiscroll-input-accent,
    'input-border': $mbsc-mobiscroll-input-border,
  ));

$mbsc-mobiscroll-dark-colors: map-merge($mbsc-mobiscroll-dark-colors, ( // Colors map
    'input-background': $mbsc-mobiscroll-dark-input-background,
    'input-text': $mbsc-mobiscroll-dark-input-text,
    'input-accent': $mbsc-mobiscroll-dark-input-accent,
    'input-border': $mbsc-mobiscroll-dark-input-border,
  ));


@mixin mbsc-mobiscroll-input($theme, $params) {
  $background: map-get($params, 'background');
  $accent: map-get($params, 'accent');
  $text: map-get($params, 'text');

  $bg-param: map-get($params, 'input-background');
  $text-param: map-get($params, 'input-text');
  $brd-param: map-get($params, 'input-border');
  $acc-param: map-get($params, 'input-accent');
  $err-param: map-get($params, 'form-error');

  $text: if($text-param, $text-param, $text);
  $accent: if($acc-param, $acc-param, $accent);
  $error: if($err-param, $err-param, $mbsc-mobiscroll-error);

  $input-base-color: '';
  $input-text: '';
  $input-icon-color: '';
  $input-border-color: '';
  $input-label-color: '';
  $input-background: '';
  $input-box-background: '';
  $input-box-hover-background: '';
  $input-box-focus-background: '';

  // Light background
  @if (lightness($background) > 50%) {
    $input-base-color: darken($text, 27%);
    $input-text: rgba($input-base-color, .87);
    $input-border-color: rgba($input-base-color, .42);
    $input-icon-color: rgba($input-base-color, .54);
    $input-label-color: rgba($input-base-color, .6);
    $input-background: if($bg-param, $bg-param, $background);
    $input-box-background: if($bg-param, $bg-param, darken($background, 4%));
    $input-box-hover-background: darken($input-box-background, 3%);
    $input-box-focus-background: darken($input-box-background, 7%);
  }

  // Dark background
  @else {
    $input-base-color: $text;
    $input-text: $input-base-color;
    $input-border-color: $input-base-color;
    $input-icon-color: $input-base-color;
    $input-label-color: rgba($input-base-color, .6);
    $input-background: if($bg-param, $bg-param, $background);
    $input-box-background: if($bg-param, $bg-param, lighten($background, 4%));
    $input-box-hover-background: lighten($input-box-background, 3%);
    $input-box-focus-background: lighten($input-box-background, 7%);
  }

  $input-border-color: if($brd-param, $brd-param, $input-border-color);
  $input-text: if($text-param, $text-param, $input-text);
  $input-icon-color: if($text-param, $text-param, $input-icon-color);
  $input-label-color: if($text-param, $text-param, $input-label-color);

  .mbsc-#{$theme} {

    /* Form element */

    &.mbsc-textfield {
      border-bottom-color: $input-border-color;
      color: $input-text;
    }

    &.mbsc-textfield.mbsc-hover {
      border-color: $input-text;
    }

    &.mbsc-textfield.mbsc-error {
      border-color: $error;
    }

    /* Icon */

    &.mbsc-textfield-icon,
    &.mbsc-select-icon {
      color: $input-icon-color;
    }

    /* Ripple */

    &.mbsc-textfield-ripple {
      background-color: $accent;
    }

    &.mbsc-textfield-ripple.mbsc-error {
      background-color: $error;
    }

    /* Label */

    &.mbsc-label {
      color: $input-label-color;
    }

    &.mbsc-label-stacked.mbsc-focus,
    &.mbsc-label-floating.mbsc-focus {
      color: $accent;
    }

    &.mbsc-label.mbsc-error {
      color: $error;
    }

    /* Error message */

    &.mbsc-error-message {
      color: $error;
    }

    /* Select */

    &.mbsc-select {
      background: $input-background;
    }

    /* Box input ------------------------------------------------------------------------------- */

    &.mbsc-textfield-box.mbsc-select,
    &.mbsc-textfield-inner-box {
      background: $input-box-background;
    }

    &.mbsc-textfield-box.mbsc-select.mbsc-hover,
    &.mbsc-textfield-inner-box.mbsc-hover {
      background: $input-box-hover-background;
    }

    &.mbsc-textfield-box.mbsc-select.mbsc-focus,
    &.mbsc-textfield-inner-box.mbsc-focus {
      background: $input-box-focus-background;
    }

    /* ----------------------------------------------------------------------------------------- */


    /* Outline input --------------------------------------------------------------------------- */

    &.mbsc-textfield-fieldset {
      border-color: $input-border-color;
    }

    &.mbsc-textfield-fieldset.mbsc-hover {
      border-color: $input-text;
    }

    &.mbsc-textfield-fieldset.mbsc-focus {
      border-color: $accent;
    }

    &.mbsc-textfield-fieldset.mbsc-error {
      border-color: $error;
    }

    &.mbsc-textfield-fieldset.disabled {
      border-color: $input-text;
    }

    /* ----------------------------------------------------------------------------------------- */
  }
}


@if ($mbsc-mobiscroll-theme) {
  .mbsc-mobiscroll {

    /* Wrapper */

    &.mbsc-textfield-wrapper {
      margin: 1.5em 1em;
      box-sizing: border-box;
    }

    /* Inner wrapper */

    &.mbsc-textfield-inner.mbsc-disabled {
      opacity: .5;
    }

    /* Form element */

    &.mbsc-textfield {
      display: block;
      width: 100%;
      height: 2.125em;
      background-color: transparent;
      border-bottom: 1px solid;
      font-size: 1em;
      transition: border-color .2s;
    }

    &.mbsc-textfield-has-icon-left {
      padding-left: 2em;
    }

    &.mbsc-textfield-has-icon-right {
      padding-right: 2em;
    }

    /* Icon */

    &.mbsc-textfield-icon {
      top: .375em;
    }

    &.mbsc-textfield-icon-floating,
    &.mbsc-textfield-icon-stacked,
    &.mbsc-textfield-icon-outline {
      top: 1em;
    }

    /* Ripple */

    .mbsc-textfield-ripple {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      transform: scaleX(0);
      transition: transform 180ms cubic-bezier(.4, 0, .2, 1);
      z-index: 2;
    }

    .mbsc-textfield-ripple-active {
      transform: scaleX(1);
    }

    /* Label */

    &.mbsc-label {
      font-size: .75em;
    }

    &.mbsc-label-inline {
      line-height: 2em;
      font-size: 1em;
    }

    &.mbsc-label-inline.mbsc-ltr {
      padding-right: .5em;
    }

    &.mbsc-label-inline.mbsc-rtl {
      padding-left: .5em;
    }

    &.mbsc-label-floating {
      font-size: 1em;
      line-height: 1.5em;
      top: 1.125em;
    }

    &.mbsc-label-floating-active {
      transform: translateY(-1.125em) scale(.75);
    }

    &.mbsc-label.mbsc-disabled {
      opacity: .5;
    }

    /* Error message */

    &.mbsc-error-message.mbsc-ltr {
      left: 0;
    }

    &.mbsc-error-message.mbsc-rtl {
      right: 0;
    }

    &.mbsc-error-message-has-icon-left.mbsc-ltr {
      left: 2.66667em;
    }

    &.mbsc-error-message-has-icon-right.mbsc-rtl {
      right: 2.66667em;
    }


    /* Underline input --------------------------------------------------------------------------- */

    /* Form element */

    &.mbsc-textfield-underline-stacked,
    &.mbsc-textfield-underline-floating {
      height: 3em;
      padding-top: .875em;
    }

    /* Icon */

    &.mbsc-textfield-icon-underline {
      top: .25em;
    }

    &.mbsc-textfield-icon-underline.mbsc-textfield-icon-floating,
    &.mbsc-textfield-icon-underline.mbsc-textfield-icon-stacked {
      top: 1.125em;
    }

    /* Label */

    &.mbsc-label-underline-inline {
      padding-top: 1px;
    }

    &.mbsc-label-underline-stacked-has-icon-left.mbsc-ltr {
      left: 2.66667em;
    }

    &.mbsc-label-underline-stacked-has-icon-right.mbsc-rtl {
      right: 2.66667em;
    }

    &.mbsc-label-underline-floating-has-icon-left.mbsc-ltr {
      left: 2em;
    }

    &.mbsc-label-underline-floating-has-icon-right.mbsc-rtl {
      right: 2em;
    }

    /* ------------------------------------------------------------------------------------------- */


    /* Box input --------------------------------------------------------------------------------- */

    /* Inner wrapper */

    &.mbsc-textfield-box,
    &.mbsc-textfield-inner-box {
      border-radius: .25em .25em 0 0;
    }

    /* Form element */

    &.mbsc-textfield-box {
      height: 2.25em;
      padding: 0 1em;
    }

    &.mbsc-textfield-box-stacked,
    &.mbsc-textfield-box-floating {
      height: 3.5em;
      padding-top: 1.25em;
    }

    &.mbsc-textfield-underline.mbsc-disabled {
      border-style: dotted;
    }

    /* Label */

    &.mbsc-label-box-inline {
      padding-top: 1px;
    }

    &.mbsc-label-box-stacked {
      top: .666667em;
    }

    &.mbsc-label-box-floating {
      top: 1em;
    }

    &.mbsc-label-box-floating.mbsc-label-floating-active {
      transform: translateY(-.625em) scale(.75);
    }

    /* ------------------------------------------------------------------------------------------- */


    /* Outline input ----------------------------------------------------------------------------- */

    /* Inner wrapper */

    &.mbsc-textfield-inner-outline {
      padding: 0 2px;
    }

    /* Form element */

    &.mbsc-textfield-outline {
      height: 3.5em;
      padding: 2px 1em;
      border: 0;
      border-radius: 4px;
    }

    &.mbsc-textfield-fieldset {
      top: -.5em;
      border: 1px solid;
      border-radius: 4px;
      transition: border-color .2s;
    }

    &.mbsc-textfield-fieldset-has-icon-left {
      padding-left: 3em;
    }

    &.mbsc-textfield-fieldset-has-icon-right {
      padding-right: 3em;
    }

    &.mbsc-textfield-fieldset.mbsc-focus {
      border-width: 2px;
    }

    &.mbsc-textfield-legend {
      padding: 0;
      margin: 0;
      font-size: .75em;
      color: transparent;
      width: 0.01px; // Fraction needed for IE11
      white-space: nowrap;
    }

    &.mbsc-textfield-legend-active {
      width: auto;
      padding: 0 .333334em;
      margin: 0 -.333334em;
    }

    /* Label */

    &.mbsc-label-outline-inline {
      line-height: 3.375em;
    }

    &.mbsc-label-outline-stacked {
      top: -.5em;
      margin: 0 2px; // Compensate borders
    }

    &.mbsc-label-outline-floating {
      top: 1em;
      margin: 0 2px; // Compensate borders
    }

    &.mbsc-label-outline-floating.mbsc-label-floating-active {
      margin-top: -1px;
      transform: translateY(-1.5em) scale(.75);
    }

    /* Error message */

    &.mbsc-error-message-outline {
      margin: 0 2px; // Compensate border
    }

    /* ------------------------------------------------------------------------------------------- */


    /* Box & outline input common styles --------------------------------------------------------- */

    /* Form element */

    &.mbsc-textfield-outline-has-icon-left,
    &.mbsc-textfield-box-has-icon-left {
      padding-left: 3em;
    }

    &.mbsc-textfield-outline-has-icon-right,
    &.mbsc-textfield-box-has-icon-right {
      padding-right: 3em;
    }

    /* Icon */

    &.mbsc-textfield-icon-outline-left,
    &.mbsc-textfield-icon-box-left {
      left: .75em;
    }

    &.mbsc-textfield-icon-outline-right,
    &.mbsc-textfield-icon-box-right {
      right: .75em;
    }

    /* Label */

    &.mbsc-label-box-stacked.mbsc-ltr,
    &.mbsc-label-outline-stacked.mbsc-ltr {
      left: 1.333334em;
    }

    &.mbsc-label-box-stacked.mbsc-rtl,
    &.mbsc-label-outline-stacked.mbsc-rtl {
      right: 1.333334em;
    }

    &.mbsc-label-outline-stacked-has-icon-left.mbsc-ltr,
    &.mbsc-label-box-stacked-has-icon-left.mbsc-ltr {
      left: 4em;
    }

    &.mbsc-label-box-stacked-has-icon-right.mbsc-rtl,
    &.mbsc-label-outline-stacked-has-icon-right.mbsc-rtl {
      right: 4em;
    }

    &.mbsc-label-box-floating.mbsc-ltr,
    &.mbsc-label-outline-floating.mbsc-ltr {
      left: 1em;
    }

    &.mbsc-label-box-floating.mbsc-rtl,
    &.mbsc-label-outline-floating.mbsc-rtl {
      right: 1em;
    }

    &.mbsc-label-outline-floating-has-icon-left.mbsc-ltr,
    &.mbsc-label-box-floating-has-icon-left.mbsc-ltr {
      left: 3em;
    }

    &.mbsc-label-outline-floating-has-icon-left.mbsc-rtl,
    &.mbsc-label-box-floating-has-icon-left.mbsc-rtl {
      right: 1em;
    }

    &.mbsc-label-outline-floating-has-icon-right.mbsc-rtl,
    &.mbsc-label-box-floating-has-icon-right.mbsc-rtl {
      right: 3em;
    }

    /* Error message */

    &.mbsc-error-message-box.mbsc-ltr,
    &.mbsc-error-message-outline.mbsc-ltr {
      left: 1.333334em;
    }

    &.mbsc-error-message-box.mbsc-rtl,
    &.mbsc-error-message-outline.mbsc-rtl {
      right: 1.333334em;
    }

    &.mbsc-error-message-box.mbsc-error-message-has-icon-left.mbsc-ltr,
    &.mbsc-error-message-outline.mbsc-error-message-has-icon-left.mbsc-ltr {
      left: 4em;
    }

    &.mbsc-error-message-box.mbsc-error-message-has-icon-right.mbsc-rtl,
    &.mbsc-error-message-outline.mbsc-error-message-has-icon-right.mbsc-rtl {
      right: 4em;
    }

    /* ------------------------------------------------------------------------------------------- */

    /* Select */

    &.mbsc-select.mbsc-ltr {
      padding-right: 3em;
    }

    &.mbsc-select.mbsc-rtl {
      padding-left: 3em;
    }

    &.mbsc-select.mbsc-textfield-has-icon-right.mbsc-ltr {
      padding-right: 4.5em;
    }

    &.mbsc-select.mbsc-textfield-has-icon-left.mbsc-rtl {
      padding-left: 4.5em;
    }

    &.mbsc-select.mbsc-textfield-underline-has-icon-right.mbsc-ltr {
      padding-right: 3.75em;
    }

    &.mbsc-select.mbsc-textfield-underline-has-icon-left.mbsc-rtl {
      padding-left: 3.75em;
    }

    /* Select icon */

    &.mbsc-select-icon {
      top: .375em;
    }

    &.mbsc-select-icon.mbsc-ltr {
      right: .75em;
    }

    &.mbsc-select-icon.mbsc-rtl {
      left: .75em;
    }

    &.mbsc-select-icon-right.mbsc-ltr {
      right: 3em;
    }

    &.mbsc-select-icon-left.mbsc-rtl {
      left: 3em;
    }

    &.mbsc-select-icon-stacked,
    &.mbsc-select-icon-floating,
    &.mbsc-select-icon-outline {
      top: 1em;
    }

    &.mbsc-select-icon-underline {
      top: .25em;
    }

    &.mbsc-select-icon-underline.mbsc-ltr {
      right: 0;
    }

    &.mbsc-select-icon-underline.mbsc-rtl {
      left: 0;
    }

    &.mbsc-select-icon-underline.mbsc-select-icon-right.mbsc-ltr {
      right: 2.25em;
    }

    &.mbsc-select-icon-underline.mbsc-select-icon-left.mbsc-rtl {
      left: 2.25em;
    }

    &.mbsc-select-icon-underline.mbsc-select-icon-floating,
    &.mbsc-select-icon-underline.mbsc-select-icon-stacked {
      top: 1.125em;
    }

    /* Textarea */

    &.mbsc-textarea {
      height: 1.875em;
      padding-bottom: .375em;
    }

    &.mbsc-textarea.mbsc-textfield-stacked,
    &.mbsc-textarea.mbsc-textfield-floating {
      padding-top: 0;
    }

    &.mbsc-textarea.mbsc-textfield-outline {
      height: 1.5em;
      padding-top: 0;
      padding-bottom: 0;
    }

    &.mbsc-textarea.mbsc-textfield-underline {
      padding-bottom: .3125em;
    }

    &.mbsc-textarea-inner.mbsc-textfield-inner-box {
      padding-top: .375em;
    }

    &.mbsc-textarea-inner.mbsc-textfield-inner-stacked,
    &.mbsc-textarea-inner.mbsc-textfield-inner-floating {
      padding-top: 1.625em;
    }

    &.mbsc-textarea-inner.mbsc-textfield-inner-outline {
      padding-top: 1em;
      padding-bottom: 1em;
    }

    &.mbsc-textarea-inner.mbsc-textfield-inner-underline {
      padding-top: .25em;
    }

    &.mbsc-textarea-inner.mbsc-textfield-inner-underline.mbsc-textfield-inner-stacked,
    &.mbsc-textarea-inner.mbsc-textfield-inner-underline.mbsc-textfield-inner-floating {
      padding-top: 1.125em;
    }

  }

  @include mbsc-mobiscroll-input('mobiscroll', $mbsc-mobiscroll-colors);
  @include mbsc-mobiscroll-input('mobiscroll-dark', $mbsc-mobiscroll-dark-colors);
}



@if ($mbsc-windows-theme) {
  .mbsc-windows {

    /* Wrapper */

    &.mbsc-textfield-wrapper {
      margin: 1em;
    }

    &.mbsc-textfield-wrapper-inline {
      margin: 1.75em 1em;
    }

    &.mbsc-textfield-wrapper-floating {
      padding-top: 1.75em;
    }

    /* Form element */

    &.mbsc-textfield {
      height: 2em;
      padding: 0 .5em;
      border: 1px solid;
      border-radius: 2px;
    }

    /* Icons */

    &.mbsc-textfield-icon {
      top: .4375em;
      width: 1.125em;
      height: 1.125em;
      line-height: 1.125em;
      margin: 0 1px; // For border
    }

    &.mbsc-textfield-has-icon-right {
      padding-right: 2em;
    }

    &.mbsc-textfield-has-icon-left {
      padding-left: 2em;
    }

    &.mbsc-textfield-icon-left {
      left: .4375em;
    }

    &.mbsc-textfield-icon-right {
      right: .4375em;
    }

    /* Error  */

    &.mbsc-error-message {
      font-size: .75em;
    }

    /* Label */

    &.mbsc-label {
      line-height: 2em;
      font-size: .875em;
      font-weight: 600;
    }

    &.mbsc-label-inline {
      line-height: 2.285715em;
    }

    &.mbsc-label-floating {
      top: 2.142858em;
    }

    &.mbsc-label-stacked {
      position: static;
    }

    &.mbsc-label-floating.mbsc-ltr {
      transform: translateX(.571429em);
    }

    &.mbsc-label-floating.mbsc-rtl {
      transform: translateX(-.5em);
    }

    // TODO: check if simplify elsewhere too if there is a separate mbsc-label-{labeltype}-has-icon-{align} class?
    &.mbsc-label-underline-floating-has-icon-left.mbsc-ltr,
    &.mbsc-label-outline-floating-has-icon-left.mbsc-ltr,
    &.mbsc-label-box-floating-has-icon-left.mbsc-ltr {
      transform: translateX(2.285715em);
    }

    &.mbsc-label-underline-floating-has-icon-right.mbsc-rtl,
    &.mbsc-label-outline-floating-has-icon-right.mbsc-rtl,
    &.mbsc-label-box-floating-has-icon-right.mbsc-rtl {
      transform: translateX(-2.285715em);
    }

    &.mbsc-label.mbsc-label-floating-active {
      transform: translate(0, -2.142858em);
    }

    /* Underline input --------------------------------------------------------------------------- */

    /* Wrapper */
    &.mbsc-textfield-wrapper-underline {
      border-bottom: 1px solid;
    }

    /* Form element */
    &.mbsc-textfield-underline {
      border: 0;
      border-radius: 0;
    }

    /* ------------------------------------------------------------------------------------------- */

    /* Select */

    &.mbsc-select.mbsc-ltr {
      padding-right: 2em;
    }

    &.mbsc-select.mbsc-rtl {
      padding-left: 2em;
    }

    &.mbsc-select.mbsc-textfield-has-icon-right.mbsc-ltr {
      padding-right: 3.125em;
    }

    &.mbsc-select.mbsc-textfield-has-icon-left.mbsc-rtl {
      padding-left: 3.125em;
    }

    /* Select icon */

    &.mbsc-select-icon {
      top: .4375em;
      width: 1.125em;
      height: 1.125em;
      line-height: 1.125em;
      margin: 0 1px; // For border
    }

    &.mbsc-select-icon.mbsc-ltr {
      right: .4375em;
    }

    &.mbsc-select-icon.mbsc-rtl {
      left: .4375em;
    }

    &.mbsc-select-icon-right.mbsc-ltr {
      right: 2em;
    }

    &.mbsc-select-icon-left.mbsc-rtl {
      left: 2em;
    }

    /* Textarea */

    &.mbsc-textarea {
      height: 3.5em;
      padding-top: .1875em;
      padding-bottom: .1875em;
    }

    /* Input tags */

    &.mbsc-textfield.mbsc-textfield-tags {
      padding: .125em;
      min-height: 2em;
    }

    &.mbsc-textfield-tag {
      margin: .125em;
    }

    &.mbsc-textfield-tag.mbsc-ltr {
      margin-right: .125em;
    }

    &.mbsc-textfield-tag.mbsc-rtl {
      margin-left: .125em;
    }

    &.mbsc-textfield-tag-text {
      line-height: 1.571429em; // 22px
    }

    &.mbsc-textfield-tag-clear.mbsc-icon {
      width: 1.375em;
      height: 1.375em;
      border-width: .125em;
    }

    &.mbsc-textfield-tags-placeholder {
      padding: 0 .375em;
      line-height: 1.625em;
    }

  }

  @include mbsc-windows-input('windows', $mbsc-windows-colors);
  @include mbsc-windows-input('windows-dark', $mbsc-windows-dark-colors);
}


// Dummy animation to detect initial autofill in webkit browsers
@keyframes autofill {
  from {
    opacity: 1;
  }

  to {
    opacity: 1;
  }
}

/* Wrapper */

.mbsc-textfield-wrapper {
  position: relative;
  display: block;
  margin: 0;
  z-index: 0;
  user-select: none;
}

.mbsc-textfield-wrapper-inline {
  display: flex;
  // align-items: center;
}

.mbsc-form-grid .mbsc-textfield-wrapper-box,
.mbsc-form-grid .mbsc-textfield-wrapper-outline {
  margin: .75em 1em;
}

/* Inner wrapper */

.mbsc-textfield-inner {
  box-sizing: border-box;
  position: relative;
  display: block;
}

.mbsc-textfield-inner-inline {
  flex: 1 auto;
}

/* Form element */

.mbsc-textfield {
  display: block;
  width: 100%;
  font-size: 1em;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0;
  outline: 0;
  font-family: inherit;
  box-sizing: border-box;
  appearance: none;
  -webkit-appearance: none;
}

.mbsc-textfield:-webkit-autofill {
  animation-name: autofill;
}

.mbsc-textfield::-webkit-inner-spin-button {
  height: 2em;
  align-self: center;
}

.mbsc-textfield::-moz-placeholder {
  opacity: .5;
  color: inherit;
}

.mbsc-textfield::-webkit-input-placeholder {
  opacity: .5;
  color: inherit;
}

/* Floating label */

.mbsc-textfield-floating:-ms-input-placeholder {
  color: transparent;
}

.mbsc-textfield-floating::-moz-placeholder {
  opacity: 0;
  transition: opacity .2s;
}

.mbsc-textfield-floating::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity .2s;
}

.mbsc-textfield-floating::-webkit-datetime-edit {
  color: transparent;
}

.mbsc-textfield-floating-active:-ms-input-placeholder {
  color: inherit;
}

.mbsc-textfield-floating-active::-moz-placeholder {
  opacity: .5;
}

.mbsc-textfield-floating-active::-webkit-input-placeholder {
  opacity: .5;
}

.mbsc-textfield-floating-active::-webkit-datetime-edit {
  color: inherit;
}

.mbsc-textfield-floating .mbsc-textfield-tags-placeholder {
  opacity: 0;
  transition: opacity .2s;
}

.mbsc-textfield-floating-active .mbsc-textfield-tags-placeholder {
  opacity: .5;
}


.mbsc-textfield-fieldset {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 0 1em;
  pointer-events: none;
}

/* Icon */

.mbsc-textfield-icon {
  position: absolute;
  top: 0;
  text-align: center;
}

.mbsc-textfield-icon-left {
  left: 0;
}

.mbsc-textfield-icon-right {
  right: 0;
}

/* Label */

.mbsc-label {
  position: absolute;
  z-index: 1;
  top: 0;
  line-height: 1em;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.mbsc-label-inline {
  position: static;
  overflow: hidden;
  flex: 0 0 auto;
  width: 30%;
  max-width: 12.5em;
  // box-sizing: content-box;
  box-sizing: border-box;
  pointer-events: auto;
}

.mbsc-label-floating-animate {
  transition: transform .2s;
}

.mbsc-label-floating.mbsc-ltr {
  transform-origin: top left;
}

.mbsc-label-floating.mbsc-rtl {
  transform-origin: top right;
}

/* Error message */

.mbsc-error-message {
  position: absolute;
  top: 100%;
  font-size: .75em;
  line-height: 1.5em;
}

/* File input */

.mbsc-textfield-file {
  position: absolute;
  left: 0;
  opacity: 0;
}

/* Select */

.mbsc-select {
  cursor: pointer;
}

.mbsc-select-icon {
  position: absolute;
  text-align: center;
  pointer-events: none;
}

/* Textarea */

.mbsc-textarea {
  resize: none;
  overflow: hidden;
  line-height: 1.5em;
}

/* Password toggle */

.mbsc-toggle-icon:hover {
  cursor: pointer;
}

/* Input tags */

.mbsc-textfield.mbsc-textfield-hidden.mbsc-textarea {
  padding: 0;
  width: 100%;
  height: 100%;
  border: 0;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0;
}

.mbsc-textfield.mbsc-textfield-tags.mbsc-textarea {
  display: block;
  overflow: auto;
  height: auto;
  max-height: 5.5em;
}

.mbsc-textfield-tag {
  display: inline-block;
  margin: .25em 0;
  line-height: normal;
}

.mbsc-textfield-tag.mbsc-ltr {
  margin-right: .5em;
  padding-left: .75em;
}

.mbsc-textfield-tag.mbsc-rtl {
  margin-left: .5em;
  padding-right: .75em;
}

.mbsc-textfield-tag-text {
  font-size: .875em;
  line-height: 1.857143em; // 26px
}

.mbsc-textfield-tag-clear.mbsc-icon {
  width: 1.625em;
  height: 1.625em;
  vertical-align: top;
  border: .25em solid transparent;
  box-sizing: border-box;
  cursor: pointer;
}

.mbsc-textfield-tags-placeholder {
  opacity: .5;
  line-height: 2.125em;
}



@mixin mbsc-mobiscroll-popup($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  .mbsc-#{$theme} {
    &.mbsc-popup {
      background: $background;
      box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
      color: $text;
    }

    &.mbsc-popup-arrow {
      background: $background;
      box-shadow: 0 0 1em rgba(0, 0, 0, .2);
    }
  }
}


@if ($mbsc-mobiscroll-theme) {
  @include mbsc-mobiscroll-popup('mobiscroll', $mbsc-mobiscroll-colors);
  @include mbsc-mobiscroll-popup('mobiscroll-dark', $mbsc-mobiscroll-dark-colors);
}



@if ($mbsc-ios-theme) {
  .mbsc-ios {

    &.mbsc-popup-wrapper-bottom-full {
      padding-top: 1em;
    }

    &.mbsc-popup-wrapper-top-full {
      padding-bottom: 1em;
    }

    // &.mbsc-popup-overlay {
    //   background: rgba(0, 0, 0, .2);
    // }

    &.mbsc-popup-top {
      border-bottom: 1px solid;
    }

    &.mbsc-popup-bottom {
      border-top: 1px solid;
    }

    &.mbsc-popup-body-center {
      border-radius: .9375em;
    }

    &.mbsc-popup-body-bottom-full {
      border-radius: .75em .75em 0 0;
    }

    &.mbsc-popup-body-top-full {
      border-radius: 0 0 .75em .75em;
    }

    &.mbsc-popup-body-anchored {
      border-radius: .9375em;
    }

    &.mbsc-popup-arrow {
      border-radius: 0 .375em;
    }

    &.mbsc-popup-header {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      left: 0;
      line-height: 1.25em;
      padding: .75em 4.375em;
      font-weight: bold;
      text-align: center;
    }

    &.mbsc-popup-header-no-buttons {
      position: relative;
      border-bottom: 1px solid;
    }

    &.mbsc-popup-header-center {
      position: relative;
      padding: .75em .5em;
      border-bottom: 1px solid;
    }

    &.mbsc-popup-top-full .mbsc-popup-header,
    &.mbsc-popup-bottom-full .mbsc-popup-header {
      padding: 1.125em 4.375em;
    }

    &.mbsc-popup-buttons {
      order: -1;
      border-bottom: 1px solid;
    }

    &.mbsc-popup-buttons.mbsc-ltr {
      text-align: right;
    }

    &.mbsc-popup-buttons.mbsc-rtl {
      text-align: left;
    }

    &.mbsc-popup-button.mbsc-font {
      // line-height: 2.75em;
      margin-top: .25em;
      margin-bottom: .25em;
      z-index: 2;
    }

    &.mbsc-popup-button-primary {
      font-weight: bold;
    }

    &.mbsc-popup-button-close.mbsc-ltr {
      float: left;
    }

    &.mbsc-popup-button-close.mbsc-rtl {
      float: right;
    }

    &.mbsc-popup-buttons-anchored,
    &.mbsc-popup-buttons-top,
    &.mbsc-popup-buttons-bottom {
      padding: 0 .25em;
    }

    &.mbsc-popup-buttons-top-full,
    &.mbsc-popup-buttons-bottom-full {
      padding: .375em .25em;
    }

    /* Button styling for centered popup and desktop styled top/bottom popup */

    &.mbsc-popup-buttons-flex {
      order: 0;
      border: 0;
      padding: 0;
      display: flex;
    }

    &.mbsc-popup-buttons-flex.mbsc-popup-buttons {
      background: none;
    }

    &.mbsc-popup-button-flex.mbsc-font {
      flex: 1 1 100%;
      display: block;
    }

    &.mbsc-popup-button-flex.mbsc-font.mbsc-button {
      opacity: 1;
      margin-top: .5em;
      margin-bottom: .5em;
    }

    &.mbsc-popup-button-flex.mbsc-font.mbsc-button-flat {
      background: none;
      border-top: 1px solid;
      border-radius: 0;
      margin: 0;
      line-height: 2.75em;
    }

    &.mbsc-popup-button-flex.mbsc-button-flat.mbsc-ltr {
      border-right-style: solid;
      border-right-width: 1px;
    }

    &.mbsc-popup-button-flex.mbsc-button-flat.mbsc-rtl {
      border-left-style: solid;
      border-left-width: 1px;
    }

    &.mbsc-popup-button-flex.mbsc-button-flat:last-child {
      border-left: 0;
      border-right: 0;
    }

    /* Desktop styling */

    &.mbsc-popup-body-round {
      border-radius: .9375em;
    }

    &.mbsc-popup-pointer {
      .mbsc-popup-body-round {
        border-radius: .5em;
      }
    }

    &.mbsc-popup-round {
      .mbsc-popup {
        border: 0;
      }

      // .mbsc-popup-body-bottom.mbsc-popup-short,
      .mbsc-popup-body-bottom-full {
        border-radius: .75em .75em 0 0;
      }

      // .mbsc-popup-body-top.mbsc-popup-short,
      .mbsc-popup-body-top-full {
        border-radius: 0 0 .75em .75em;
      }
    }

  }

  @include mbsc-ios-popup('ios', $mbsc-ios-colors);
  @include mbsc-ios-popup('ios-dark', $mbsc-ios-dark-colors);
}



@if ($mbsc-material-theme) {
  .mbsc-material {

    &.mbsc-popup-body-center,
    &.mbsc-popup-body-anchored,
    &.mbsc-popup-body-round {
      border-radius: .25em;
    }

    &.mbsc-popup-header {
      font-size: 1.25em;
      font-weight: 500;
      padding: .8em .8em 0 .8em;
      line-height: 1.4em;
    }

    &.mbsc-popup-buttons {
      padding: .5em;
    }

    &.mbsc-popup-buttons.mbsc-ltr {
      text-align: right;
    }

    &.mbsc-popup-buttons.mbsc-rtl {
      text-align: right;
    }

    &.mbsc-popup-button.mbsc-button-flat {
      margin-left: 0;
      margin-right: 0;
    }

    &.mbsc-popup-button.mbsc-font {
      margin-top: 0;
      margin-bottom: 0;
      font-weight: 500;
      font-size: .9375em;
    }

    &.mbsc-popup-round {

      .mbsc-popup-body-bottom-full {
        border-radius: .25em .25em 0 0;
      }

      .mbsc-popup-body-top-full {
        border-radius: 0 0 .25em .25em;
      }
    }
  }

  @include mbsc-material-popup('material', $mbsc-material-colors);
  @include mbsc-material-popup('material-dark', $mbsc-material-dark-colors);
}



@if ($mbsc-windows-theme) {
  .mbsc-windows {

    &.mbsc-popup {
      border: 1px solid;
    }

    &.mbsc-popup-anchored-top {
      margin-top: -.75em;
    }

    &.mbsc-popup-anchored-bottom {
      margin-top: .75em;
    }

    &.mbsc-popup-arrow {
      width: 1em;
      height: 1em;
      border: 1px solid;
    }

    &.mbsc-popup-arrow-bottom,
    &.mbsc-popup-arrow-top {
      margin-left: -.5em;
    }

    &.mbsc-popup-arrow-left,
    &.mbsc-popup-arrow-right {
      margin-top: -.5em;
    }

    &.mbsc-popup-arrow-bottom {
      top: 1.5em;
    }

    &.mbsc-popup-arrow-top {
      bottom: 1.5em;
    }

    &.mbsc-popup-arrow-left {
      right: 1.5em;
    }

    &.mbsc-popup-arrow-right {
      left: 1.5em;
    }

    &.mbsc-popup-header {
      padding: .5em;
      font-weight: bold;
      text-align: center;
      border-bottom: 1px solid;
    }

    &.mbsc-popup-buttons {
      display: flex;
      flex-direction: row-reverse;
      border-top: 1px solid;
    }

    &.mbsc-popup-button.mbsc-font {
      flex: 1 1;
      padding-top: .25em;
      padding-bottom: .25em;
    }

    &.mbsc-popup-button.mbsc-button-flat {
      margin: 0;
    }
  }

  @include mbsc-windows-popup('windows', $mbsc-windows-colors);
  @include mbsc-windows-popup('windows-dark', $mbsc-windows-dark-colors);
}


@keyframes mbsc-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes mbsc-fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes mbsc-pop-in {
  from {
    opacity: 0;
    transform: scale(0.8);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes mbsc-pop-out {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0.8);
  }
}

@keyframes mbsc-slide-up-in {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes mbsc-slide-up-out {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(100%);
  }
}

@keyframes mbsc-slide-down-in {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes mbsc-slide-down-out {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
}

.mbsc-popup-open-ios {
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
}

.mbsc-popup-ctx {
  position: relative;
  --mbsc-safe-top: 0;
  --mbsc-safe-right: 0;
  --mbsc-safe-bottom: 0;
  --mbsc-safe-left: 0;
}

.mbsc-popup-limits {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.mbsc-popup-limits-anchored,
.mbsc-popup-limits-center {
  margin: 1em;
}

.mbsc-popup-wrapper {
  z-index: 99998;
  user-select: none;
}

.mbsc-popup,
.mbsc-popup-wrapper {
  box-sizing: border-box;
}

.mbsc-popup-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  display: flex;
  justify-content: center;
  border: 0 solid transparent;
}

.mbsc-popup-wrapper-ctx {
  position: absolute;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.mbsc-popup-wrapper-center {
  padding: 1em;
  align-items: center;
  border-width: var(--mbsc-safe-top) var(--mbsc-safe-right) var(--mbsc-safe-bottom) var(--mbsc-safe-left);
}

.mbsc-popup-wrapper-top {
  align-items: flex-start;
  border-bottom: var(--mbsc-safe-bottom) solid transparent;
}

.mbsc-popup-wrapper-bottom {
  align-items: flex-end;
  border-top-width: var(--mbsc-safe-top);
}

.mbsc-popup-wrapper-anchored {
  border-width: 0 var(--mbsc-safe-right) var(--mbsc-safe-bottom) var(--mbsc-safe-left);
}

.mbsc-popup-hidden {
  opacity: 0;
}

.mbsc-popup-overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: -10em;
  pointer-events: auto;
  background: rgba(0, 0, 0, .5);
  transform: translateZ(0);
}

.mbsc-popup-overlay-in {
  opacity: 1;
  animation: mbsc-fade-in 150ms cubic-bezier(0.0, 0.0, 0.2, 1);
}

.mbsc-popup-overlay-out {
  opacity: 0;
  animation: mbsc-fade-out 75ms cubic-bezier(0.4, 0.0, 1, 1);
}

.mbsc-popup {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  min-width: 12em;
  position: relative;
  z-index: 2;
  pointer-events: auto;
  text-shadow: none;
  user-select: none;
}

.mbsc-popup-center {
  min-width: 16em;
}

.mbsc-popup-bottom {
  width: 100%;
  bottom: 0;
}

.mbsc-popup-top {
  width: 100%;
  top: 0;
}

.mbsc-popup-anchored {
  position: absolute;
  // max-width: calc(100% - 16px);
}

// .mbsc-popup-anchored-top {
//   margin-top: -1em;
// }

// .mbsc-popup-anchored-bottom {
//   margin-top: 1em;
// }

.mbsc-popup-anchored-left {
  margin-left: -1em;
}

.mbsc-popup-anchored-right {
  margin-left: 1em;
}

.mbsc-popup-arrow-wrapper {
  position: absolute;
  z-index: 1;
  overflow: hidden;
  pointer-events: none;
}

.mbsc-popup-arrow-wrapper-top,
.mbsc-popup-arrow-wrapper-bottom {
  left: 0;
  right: 0;
  height: 2em;
}

.mbsc-popup-arrow-wrapper-left,
.mbsc-popup-arrow-wrapper-right {
  top: 0;
  bottom: 0;
  width: 2em;
}

.mbsc-popup-arrow-wrapper-top {
  top: 100%;
}

.mbsc-popup-arrow-wrapper-bottom {
  bottom: 100%;
}

.mbsc-popup-arrow-wrapper-left {
  left: 100%;
}

.mbsc-popup-arrow-wrapper-right {
  right: 100%;
}

.mbsc-popup-arrow {
  position: absolute;
  box-sizing: border-box;
  width: 1.5em;
  height: 1.5em;
  pointer-events: auto;
}

.mbsc-popup-arrow-top {
  bottom: 1.25em;
}

.mbsc-popup-arrow-bottom {
  top: 1.25em;
}

.mbsc-popup-arrow-top,
.mbsc-popup-arrow-bottom {
  left: 50%;
  margin-left: -.75em;
  transform: rotate(-45deg);
}

.mbsc-popup-arrow-left {
  right: 1.25em;
}

.mbsc-popup-arrow-right {
  left: 1.25em;
}

.mbsc-popup-arrow-left,
.mbsc-popup-arrow-right {
  top: 50%;
  margin-top: -.75em;
  transform: rotate(45deg);
}

.mbsc-popup-focus {
  outline: 0;
}

.mbsc-popup-body {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transform: translateZ(0);
  flex: 1 1 auto;
}

.mbsc-popup-body-top {
  padding-top: var(--mbsc-safe-top);
}

.mbsc-popup-body-bottom {
  padding-bottom: var(--mbsc-safe-bottom);
}

.mbsc-popup-body-top,
.mbsc-popup-body-bottom {
  padding-left: var(--mbsc-safe-left);
  padding-right: var(--mbsc-safe-right);
}

.mbsc-popup-body-round {
  padding: 0;
}

.mbsc-popup-header {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: none;
}

.mbsc-popup-content {
  flex: 1 1 auto;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.mbsc-popup-padding {
  padding: 1em;
}

.mbsc-popup-buttons {
  flex: none;
}

/* Animations */

.mbsc-popup-pop-in {
  opacity: 1;
  animation: mbsc-pop-in 150ms cubic-bezier(0.0, 0.0, 0.2, 1);
}

.mbsc-popup-pop-out {
  opacity: 0;
  animation: mbsc-pop-out 75ms cubic-bezier(0.4, 0.0, 1, 1);
}

.mbsc-popup-slide-up-in {
  animation: mbsc-slide-up-in 250ms cubic-bezier(0.0, 0.0, 0.2, 1);
}

.mbsc-popup-slide-up-out {
  animation: mbsc-slide-up-out 200ms cubic-bezier(0.4, 0.0, 1, 1);
}

.mbsc-popup-slide-down-in {
  animation: mbsc-slide-down-in 250ms cubic-bezier(0.0, 0.0, 0.2, 1);
}

.mbsc-popup-slide-down-out {
  animation: mbsc-slide-down-out 200ms cubic-bezier(0.4, 0.0, 1, 1);
}

/* Desktop styling */

.mbsc-popup-pointer {
  &.mbsc-picker .mbsc-popup-overlay,
  .mbsc-popup-overlay-anchored {
    background: none;
  }
}

.mbsc-popup-round {

  .mbsc-popup-slide-up-in {
    animation-name: mbsc-slide-up-in, mbsc-fade-in;
  }

  .mbsc-popup-slide-up-out {
    animation-name: mbsc-slide-up-out, mbsc-fade-out;
  }

  .mbsc-popup-slide-down-in {
    animation-name: mbsc-slide-down-in, mbsc-fade-in;
  }

  .mbsc-popup-slide-down-out {
    animation-name: mbsc-slide-down-out, mbsc-fade-out;
  }

  .mbsc-popup-top,
  .mbsc-popup-bottom {
    width: auto;
    margin-top: 3em;
    margin-bottom: 3em;
  }

  // .mbsc-popup-short {
  //   margin-top: 0;
  //   margin-bottom: 0;
  // }

}



@mixin mbsc-ios-notifications($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');
  
  $border-color: '';

  // Light background
  @if (lightness($background) > 50%) {
    $border-color: darken($background, 17%);
  }

  // Dark background
  @else {
    $border-color: lighten($background, 20%);
  }

  .mbsc-#{$theme} {
    .mbsc-toast-background {
      background: rgba($text, .8);
      color: get-contrast-color($text);
    }

    &.mbsc-prompt-input {
      border-color: $border-color;
    }

    &.mbsc-color-none .mbsc-snackbar-button.mbsc-button {
      color: $accent;
    }

  }

}


@if ($mbsc-ios-theme) {
  .mbsc-ios {
    .mbsc-toast-message {
      line-height: 1.857143em; // 26px / 14px based
      border-radius: 1.785715em; // 25px / 14px based
    }

    .mbsc-alert-title {
      margin: 0.277778em 0;
      font-size: 1.125em;
      text-align: center;
    }

    .mbsc-alert-message {
      margin: 0.357143em 0;
      font-size: .875em;
      text-align: center;
    }

    &.mbsc-prompt-input.mbsc-font {
      margin-top: 1em;
      border-radius: .5em;
      border-width: 1px;
      border-style: solid;
    }

    &.mbsc-prompt-input::after,
    &.mbsc-prompt-input::before {
      display: none;
    }

    &.mbsc-prompt-input .mbsc-textfield {
      height: 1.75em;
      line-height: 1.75em;
      padding: 0 .25em;
    }

    &.mbsc-prompt-input .mbsc-label {
      height: 1.75em;
      line-height: 1.75em;
      padding-left: .25em;
    }

    &.mbsc-primary .mbsc-toast-background {
      background: $mbsc-ios-primary;
      color: get-contrast-color($mbsc-ios-primary);
    }

    &.mbsc-secondary .mbsc-toast-background {
      background: $mbsc-ios-secondary;
      color: get-contrast-color($mbsc-ios-secondary);
    }

    &.mbsc-success .mbsc-toast-background {
      background: $mbsc-ios-success;
      color: get-contrast-color($mbsc-ios-success);
    }

    &.mbsc-danger .mbsc-toast-background {
      background: $mbsc-ios-danger;
      color: get-contrast-color($mbsc-ios-danger);
    }

    &.mbsc-warning .mbsc-toast-background {
      background: $mbsc-ios-warning;
      color: get-contrast-color($mbsc-ios-warning);
    }

    &.mbsc-info .mbsc-toast-background {
      background: $mbsc-ios-info;
      color: get-contrast-color($mbsc-ios-info);
    }
  }

  @include mbsc-ios-notifications('ios', $mbsc-ios-colors);
  @include mbsc-ios-notifications('ios-dark', $mbsc-ios-dark-colors);
}



@mixin mbsc-material-notifications($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  .mbsc-#{$theme} {

    &.mbsc-color-none .mbsc-snackbar-button.mbsc-button {
      color: lighten($accent, 10%);
    }

  }
}


@if ($mbsc-material-theme) {
  .mbsc-material {

    .mbsc-toast-message {
      border-radius: 1.571429em;
    }

    .mbsc-toast-background {
      background: #444;
      color: #fff;
    }

    &.mbsc-prompt-input.mbsc-font {
      margin: 1.5em 0 0 0;
    }

    &.mbsc-primary .mbsc-toast-background {
      background: $mbsc-material-primary;
      color: get-contrast-color($mbsc-material-primary);
    }

    &.mbsc-secondary .mbsc-toast-background {
      background: $mbsc-material-secondary;
      color: get-contrast-color($mbsc-material-secondary);
    }

    &.mbsc-success .mbsc-toast-background {
      background: $mbsc-material-success;
      color: get-contrast-color($mbsc-material-success);
    }

    &.mbsc-danger .mbsc-toast-background {
      background: $mbsc-material-danger;
      color: get-contrast-color($mbsc-material-danger);
    }

    &.mbsc-warning .mbsc-toast-background {
      background: $mbsc-material-warning;
      color: get-contrast-color($mbsc-material-warning);
    }

    &.mbsc-info .mbsc-toast-background {
      background: $mbsc-material-info;
      color: get-contrast-color($mbsc-material-info);
    }

  }

  @include mbsc-material-notifications('material', $mbsc-material-colors);
  @include mbsc-material-notifications('material-dark', $mbsc-material-dark-colors);
}



@mixin mbsc-windows-notifications($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  .mbsc-#{$theme} {
    .mbsc-toast-background {
      background: $text;
      color: get-contrast-color($text);
    }

    &.mbsc-color-none .mbsc-snackbar-button.mbsc-button {
      color: lighten($accent, 10%);
    }
  }
}


@if ($mbsc-windows-theme) {
  .mbsc-windows {

    &.mbsc-prompt-input.mbsc-font {
      margin: 1em 0 0 0;
    }

    &.mbsc-primary .mbsc-toast-background {
      background: $mbsc-windows-primary;
      color: get-contrast-color($mbsc-windows-primary);
    }

    &.mbsc-secondary .mbsc-toast-background {
      background: $mbsc-windows-secondary;
      color: get-contrast-color($mbsc-windows-secondary);
    }

    &.mbsc-success .mbsc-toast-background {
      background: $mbsc-windows-success;
      color: get-contrast-color($mbsc-windows-success);
    }

    &.mbsc-danger .mbsc-toast-background {
      background: $mbsc-windows-danger;
      color: get-contrast-color($mbsc-windows-danger);
    }

    &.mbsc-warning .mbsc-toast-background {
      background: $mbsc-windows-warning;
      color: get-contrast-color($mbsc-windows-warning);
    }

    &.mbsc-info .mbsc-toast-background {
      background: $mbsc-windows-info;
      color: get-contrast-color($mbsc-windows-info);
    }

  }

  @include mbsc-windows-notifications('windows', $mbsc-windows-colors);
  @include mbsc-windows-notifications('windows-dark', $mbsc-windows-dark-colors);
}


/* Toast */

.mbsc-toast.mbsc-font .mbsc-popup-body,
.mbsc-toast.mbsc-font .mbsc-popup,
.mbsc-snackbar.mbsc-font .mbsc-popup-body,
.mbsc-snackbar.mbsc-font .mbsc-popup {
  background: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  margin: 0;
  pointer-events: none;
}

.mbsc-toast.mbsc-font .mbsc-popup-content {
  text-align: center;
  padding-bottom: 4em;
}

.mbsc-toast-message {
  display: inline-block;
  min-width: 10em;
  max-width: 50em;
  padding: .857143em 2em;
  font-size: .875em;
  line-height: 1.428572;
}

/* Snackbar */

.mbsc-snackbar-cont {
  display: flex;
  align-items: center;
  min-width: 18em;
  max-width: 36em;
  margin: 0 auto;
  padding: .5em;
  pointer-events: auto;
}

.mbsc-snackbar-message {
  flex: 1;
  padding: .5em 1em;
  font-size: .875em; // 14px
  line-height: 1.571429em; // 22px
}

.mbsc-snackbar-button.mbsc-button.mbsc-font {
  margin: 0;
  color: #fff;
}

/* Alert, Confirm, Prompt*/

.mbsc-alert-content {
  max-width: 20em;
}

.mbsc-alert-title {
  margin: 0 0 1em 0;
  padding: 0;
  font-size: 1.428572em;
  font-weight: bold;
}

.mbsc-alert-message {
  margin: 1em 0;
  padding: 0;
  font-size: 1em;
}



@if ($mbsc-ios-theme) {
  .mbsc-ios {

    .mbsc-block-title,
    .mbsc-form-group-title {
      padding: .5em 1.333334em;
      font-size: .75em;
      line-height: 1.5em;
      text-transform: uppercase;
    }
  }

  @include mbsc-ios-page('ios', $mbsc-ios-colors);
  @include mbsc-ios-page('ios-dark', $mbsc-ios-dark-colors);
}



@if ($mbsc-material-theme) {
  .mbsc-material {

    .mbsc-block-title,
    .mbsc-form-group-title {
      padding: .666667em 1.333334em;
      font-size: .75em;
      text-transform: uppercase;
    }
  }

  @include mbsc-material-page('material', $mbsc-material-colors);
  @include mbsc-material-page('material-dark', $mbsc-material-dark-colors);
}



// Theme specific variables - inherited from global variables

// background
$mbsc-mobiscroll-page-background: $mbsc-page-background-light !default;
$mbsc-mobiscroll-dark-page-background: $mbsc-page-background-dark !default;

// text
$mbsc-mobiscroll-page-text: $mbsc-page-text-light !default;
$mbsc-mobiscroll-dark-page-text: $mbsc-page-text-dark !default;

// add variables to color maps
$mbsc-mobiscroll-colors: map-merge($mbsc-mobiscroll-colors, ('page-background': $mbsc-mobiscroll-page-background,
    'page-text': $mbsc-mobiscroll-page-text,
  ));

$mbsc-mobiscroll-dark-colors: map-merge($mbsc-mobiscroll-dark-colors, ('page-background': $mbsc-mobiscroll-dark-page-background,
    'page-text': $mbsc-mobiscroll-dark-page-text,
  ));

@mixin mbsc-mobiscroll-page($theme, $params) {

  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  .mbsc-#{$theme} {
    &.mbsc-page {
      background-color: $background;
      color: $text;
    }

    .mbsc-block-title,
    .mbsc-form-group-title {
      color: $accent;
    }

    a {
      color: $accent;
    }
  }
}


@if ($mbsc-mobiscroll-theme) {
  .mbsc-mobiscroll {

    .mbsc-block-title,
    .mbsc-form-group-title {
      padding: .666667em 1.333334em;
      font-size: .75em;
      text-transform: uppercase;
    }
  }

  @include mbsc-mobiscroll-page('mobiscroll', $mbsc-mobiscroll-colors);
  @include mbsc-mobiscroll-page('mobiscroll-dark', $mbsc-mobiscroll-dark-colors);
}



@if ($mbsc-windows-theme) {
  .mbsc-windows {

    .mbsc-block-title,
    .mbsc-form-group-title {
      font-size: 1.5em;
      padding: 0 .666667em;
      line-height: 2em;
    }
  }

  @include mbsc-windows-page('windows', $mbsc-windows-colors);
  @include mbsc-windows-page('windows-dark', $mbsc-windows-dark-colors);
}


.mbsc-page {
  display: block;
  min-height: 100%;
  padding: var(--mbsc-safe-top) var(--mbsc-safe-right) var(--mbsc-safe-bottom) var(--mbsc-safe-left);
}

.mbsc-page:before,
.mbsc-page:after {
  content: '';
  display: table;
}

/* Block */

.mbsc-block,
.mbsc-form-group {
  margin: 1.5em 0;
}

.mbsc-form-group-inset {
  margin: 2em 1.5em;
}

.mbsc-form-grid .mbsc-form-group-title {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

/* Typography */

.mbsc-page h1,
.mbsc-page h2,
.mbsc-page h3,
.mbsc-page h4,
.mbsc-page h5,
.mbsc-page h6,
.mbsc-h1,
.mbsc-h2,
.mbsc-h3,
.mbsc-h4,
.mbsc-h5,
.mbsc-h6 {
  margin: 0;
  padding: 0;
  color: inherit;
  font-weight: normal;
  font-family: inherit;
}

.mbsc-page p,
.mbsc-p {
  margin: 1em 0;
  padding: 0;
  line-height: 1.5;
}

.mbsc-page a,
.mbsc-a {
  text-decoration: none;
}

.mbsc-page a:hover,
.mbsc-a:hover {
  text-decoration: underline;
}

.mbsc-page a.mbsc-btn:hover,
.mbsc-a.mbsc-btn:hover {
  text-decoration: none;
}

.mbsc-page h1,
.mbsc-h1 {
  margin: .347826em 0;
  font-size: 2.875em;
}

.mbsc-page h2,
.mbsc-h2 {
  margin: .470588em 0;
  font-size: 2.125em;
}

.mbsc-page h3,
.mbsc-h3 {
  margin: .666666em 0;
  font-size: 1.5em;
}

.mbsc-page h4,
.mbsc-h4 {
  margin: .8em 0;
  font-size: 1.25em;
}

.mbsc-page h5,
.mbsc-h5 {
  margin: 1.066666em 0;
  font-size: .9375em;
}

.mbsc-page h6,
.mbsc-h6 {
  margin: 1.333333em 0;
  font-size: .75em;
}

/* Padding, margin */

.mbsc-padding {
  padding: 1em;
}

.mbsc-padding > p:first-child {
  margin-top: 0;
}

.mbsc-padding > p:last-child {
  margin-bottom: 0;
}

.mbsc-margin {
  margin: 1em 0;
}

.mbsc-margin:first-child {
  margin-top: 0;
}

.mbsc-margin:last-child {
  margin-bottom: 0;
}

/* Lists */

.mbsc-page ul,
.mbsc-page ol,
.mbsc-ul,
.mbsc-ol {
  padding: 0;
  margin: 1em 0 1em 1.25em;
  line-height: 1.5;
}

.mbsc-page ul ul,
.mbsc-page ol ol,
.mbsc-ul .mbsc-ul,
.mbsc-ol .mbsc-ol {
  margin: 0 0 0 1.25em;
}

/* Font sizes */

.mbsc-txt-xs {
  font-size: .625em
}

.mbsc-txt-s {
  font-size: .75em;
}

.mbsc-txt-m {
  font-size: 1.25em;
}

.mbsc-txt-l {
  font-size: 1.5em;
}

.mbsc-txt-xl {
  font-size: 2em;
}

.mbsc-txt-muted {
  opacity: .6;
}

/* Line heights */
.mbsc-line-height-xs {
  line-height: 1;
}

.mbsc-line-height-s {
  line-height: 1.25;
}

.mbsc-line-height-m {
  line-height: 1.5;
}

.mbsc-line-height-l {
  line-height: 1.75;
}

.mbsc-line-height-xl {
  line-height: 2;
}

/* Font weights */

.mbsc-ultra-bold {
  font-weight: 900;
}

.mbsc-bold {
  font-weight: bold;
}

.mbsc-medium {
  font-weight: 500;
}

.mbsc-light {
  font-weight: 300;
}

.mbsc-thin {
  font-weight: 100;
}

.mbsc-italic {
  font-style: italic;
}

/* Text align */

.mbsc-align-left {
  text-align: left;
}

.mbsc-align-right {
  text-align: right;
}

.mbsc-align-center {
  text-align: center;
}

.mbsc-align-justify {
  text-align: justify;
}

/* Float */

.mbsc-pull-right {
  float: right;
}

.mbsc-pull-left {
  float: left;
}

/* Image section */

.mbsc-media-fluid {
  display: block;
  width: 100%;
}

.mbsc-img-thumbnail {
  width: 6em;
  height: 6em;
  margin: 1em;
}

/* Avatar image */

.mbsc-avatar {
  width: 2.5em;
  height: 2.5em;
  padding: 0;
  border-radius: 1.25em;
}

/* Note */

.mbsc-note {
  position: relative;
  padding: .75em 1.25em;
  margin: 1em;
  border: 1px solid transparent;
  font-size: .875em;
}

@media (max-width: 600px) {
  .mbsc-note {
    text-align: center;
  }
}



@mixin mbsc-mobiscroll-radio($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $bg-param: map-get($params, 'form-background');
  $ac-param: map-get($params, 'form-accent');
  $background: if($bg-param, $bg-param, $background);
  $accent: if($ac-param, $ac-param, $accent);

  $input-disabled: '';

  @if (lightness($background) > 50%) {
    $input-disabled: darken($background, 13%);
  }

  @else {
    $input-disabled: lighten($background, 17%);
  }

  .mbsc-#{$theme} {

    &.mbsc-radio-box {
      color: $accent;
    }

    &.mbsc-radio-box.mbsc-disabled {
      color: $input-disabled;
    }

  }
}


@if ($mbsc-mobiscroll-theme) {
  .mbsc-mobiscroll {

    &.mbsc-radio-left {
      padding: 1em 1em 1em 3.125em;
    }

    &.mbsc-radio-right {
      padding: 1em 3.125em 1em 1em;
    }

    &.mbsc-radio-box {
      border: .125em solid currentColor;
    }

    &.mbsc-radio-box-left {
      left: 1em;
    }

    &.mbsc-radio-box-right {
      right: 1em;
    }

    &.mbsc-radio-box:after {
      width: .5em;
      height: .5em;
      margin-top: -.25em;
      margin-left: -.25em;
      border-radius: .625em;
      transform: scale(0);
      transition: transform .1s ease-out;
      background: currentColor;
    }

    &.mbsc-radio-box.mbsc-checked:after {
      transform: scale(1);
    }

    /* Color presets */

    &.mbsc-radio-box.mbsc-radio-box-primary {
      color: $mbsc-mobiscroll-primary;
    }

    &.mbsc-radio-box.mbsc-radio-box-secondary {
      color: $mbsc-mobiscroll-secondary;
    }

    &.mbsc-radio-box.mbsc-radio-box-success {
      color: $mbsc-mobiscroll-success;
    }

    &.mbsc-radio-box.mbsc-radio-box-danger {
      color: $mbsc-mobiscroll-danger;
    }

    &.mbsc-radio-box.mbsc-radio-box-warning {
      color: $mbsc-mobiscroll-warning;
    }

    &.mbsc-radio-box.mbsc-radio-box-info {
      color: $mbsc-mobiscroll-info;
    }
  }

  @include mbsc-mobiscroll-radio('mobiscroll', $mbsc-mobiscroll-colors);
  @include mbsc-mobiscroll-radio('mobiscroll-dark', $mbsc-mobiscroll-dark-colors);
}



@if ($mbsc-ios-theme) {
  .mbsc-ios {

    &.mbsc-radio-left {
      padding: .875em 1em .875em 3.75em;
    }

    &.mbsc-radio-right {
      padding: .875em 3.75em .875em 1em;
    }

    &.mbsc-radio-box:after {
      top: 44%;
      left: 23%;
      width: .875em;
      height: .375em;
      border: .125em solid;
      border-top: 0;
      border-right: 0;
      border-radius: 0;
      transform: rotate(-45deg);
      transition: opacity .2s ease-in-out;
    }

    &.mbsc-radio-box:before {
      content: '';
      position: absolute;
      top: -.75em;
      left: -.75em;
      z-index: -1;
      width: 2.5em;
      height: 2.5em;
      opacity: 0;
      border-radius: 2.5em;
      transition: opacity .2s ease-in-out;
      background: #ccc;
    }

    &.mbsc-radio-box.mbsc-focus:before {
      opacity: .12;
    }

    &.mbsc-radio-box-left {
      left: 1.125em;
    }

    &.mbsc-radio-box-right {
      right: 1.125em;
    }

    &.mbsc-radio-box.mbsc-disabled {
      opacity: .3;
    }

    /* Color presets */

    &.mbsc-radio-box.mbsc-radio-box-primary:after {
      border-color: $mbsc-ios-primary;
    }

    &.mbsc-radio-box.mbsc-radio-box-secondary:after {
      border-color: $mbsc-ios-secondary;
    }

    &.mbsc-radio-box.mbsc-radio-box-success:after {
      border-color: $mbsc-ios-success;
    }

    &.mbsc-radio-box.mbsc-radio-box-danger:after {
      border-color: $mbsc-ios-danger;
    }

    &.mbsc-radio-box.mbsc-radio-box-warning:after {
      border-color: $mbsc-ios-warning;
    }

    &.mbsc-radio-box.mbsc-radio-box-info:after {
      border-color: $mbsc-ios-info;
    }
  }

  @include mbsc-ios-radio('ios', $mbsc-ios-colors);
  @include mbsc-ios-radio('ios-dark', $mbsc-ios-dark-colors);
}



@if ($mbsc-material-theme) {
  .mbsc-material {

    &.mbsc-radio-left {
      padding: .9375em 1em .9375em 3.5em;
    }

    &.mbsc-radio-right {
      padding: .9375em 3.5em .9375em 1em;
    }

    &.mbsc-radio-box {
      border: .125em solid;
      transition: background-color .1s ease-out;
    }

    &.mbsc-radio-box-left {
      left: 1.125em;
    }

    &.mbsc-radio-box-right {
      right: 1.125em;
    }

    &.mbsc-radio-box:before {
      content: '';
      position: absolute;
      top: -.75em;
      left: -.75em;
      z-index: -1;
      width: 2.5em;
      height: 2.5em;
      opacity: 0;
      border-radius: 2.5em;
      transition: opacity .2s ease-in-out;
      background: currentColor;
    }

    &.mbsc-radio-box.mbsc-focus:before {
      opacity: .12;
    }

    &.mbsc-radio-box.mbsc-active:before {
      opacity: .2;
    }

    &.mbsc-radio-box:after {
      background: currentColor;
      transform: scale(0);
      transition: transform .1s ease-out;
    }

    &.mbsc-radio-box.mbsc-checked {
      border-color: currentColor;
    }

    &.mbsc-radio-box.mbsc-checked:after {
      transform: scale(1);
    }

    &.mbsc-radio-box.mbsc-disabled {
      opacity: .3;
    }

    /* Color presets */

    &.mbsc-radio-box.mbsc-radio-box-primary {
      color: $mbsc-material-primary;
    }

    &.mbsc-radio-box.mbsc-radio-box-secondary {
      color: $mbsc-material-secondary;
    }

    &.mbsc-radio-box.mbsc-radio-box-success {
      color: $mbsc-material-success;
    }

    &.mbsc-radio-box.mbsc-radio-box-danger {
      color: $mbsc-material-danger;
    }

    &.mbsc-radio-box.mbsc-radio-box-warning {
      color: $mbsc-material-warning;
    }

    &.mbsc-radio-box.mbsc-radio-box-info {
      color: $mbsc-material-info;
    }
  }

  @include mbsc-material-radio('material', $mbsc-material-colors);
  @include mbsc-material-radio('material-dark', $mbsc-material-dark-colors);
}



@if ($mbsc-windows-theme) {
  .mbsc-windows {

    &.mbsc-radio-left {
      padding: 1.125em 1em 1.125em 3.25em;
    }

    &.mbsc-radio-right {
      padding: 1.125em 3.25em 1.125em 1em;
    }

    &.mbsc-radio-box-left {
      left: 1em;
    }

    &.mbsc-radio-box-right {
      right: 1em;
    }

    &.mbsc-radio-box:before {
      content: '';
      position: absolute;
      top: -.75em;
      left: -.75em;
      z-index: -1;
      width: 2.5em;
      height: 2.5em;
      opacity: 0;
      border-radius: 2.5em;
      transition: opacity .2s ease-in-out;
      background: #ccc;
    }

    &.mbsc-radio-box.mbsc-focus:before {
      opacity: .12;
    }

    /* Color presets */

    &.mbsc-radio-box.mbsc-radio-box-primary.mbsc-checked {
      border-color: $mbsc-windows-primary;
    }

    &.mbsc-radio-box.mbsc-radio-box-secondary.mbsc-checked {
      border-color: $mbsc-windows-secondary;
    }

    &.mbsc-radio-box.mbsc-radio-box-success.mbsc-checked {
      border-color: $mbsc-windows-success;
    }

    &.mbsc-radio-box.mbsc-radio-box-danger.mbsc-checked {
      border-color: $mbsc-windows-danger;
    }

    &.mbsc-radio-box.mbsc-radio-box-warning.mbsc-checked {
      border-color: $mbsc-windows-warning;
    }

    &.mbsc-radio-box.mbsc-radio-box-info.mbsc-checked {
      border-color: $mbsc-windows-info;
    }
  }

  @include mbsc-windows-radio('windows', $mbsc-windows-colors);
  @include mbsc-windows-radio('windows-dark', $mbsc-windows-dark-colors);
}


.mbsc-radio {
  position: relative;
  display: block;
  margin: 0;
  z-index: 0;
  line-height: 1.25em;
  user-select: none;
}

.mbsc-radio-box {
  position: absolute;
  top: 50%;
  display: block;
  width: 1.25em;
  height: 1.25em;
  margin-top: -.625em;
  border-radius: 1.25em;
  box-sizing: border-box;
}

.mbsc-radio-box:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: .625em;
  height: .625em;
  margin-top: -.3125em;
  margin-left: -.3125em;
  border-radius: .625em;
  opacity: 0;
}

.mbsc-radio-box.mbsc-checked:after {
  opacity: 1;
}



@mixin mbsc-mobiscroll-segmented($theme, $params) {
  $background: map-get($params, 'background');
  $accent: map-get($params, 'accent');

  $bg-param: map-get($params, 'form-background');
  $acc-param: map-get($params, 'form-accent');

  $background: if($bg-param, $bg-param, $background);
  $accent: if($acc-param, $acc-param, $accent);

  $btn-light: '';

  @if (lightness($background) > 50%) {
    $btn-light: darken($background, 3%);
  }

  @else {
    $btn-light: $background;
  }


  .mbsc-#{$theme} {
    &.mbsc-segmented-button.mbsc-button {
      color: $accent;
      border-color: $accent;

      &.mbsc-hover {
        background: rgba($accent, .1);
      }

      &.mbsc-active {
        background: rgba($accent, .3);
      }

      &.mbsc-selected {
        background: $accent;
        color: $btn-light;
      }
    }
  }
}


@if ($mbsc-mobiscroll-theme) {
  .mbsc-mobiscroll {
    &.mbsc-segmented {
      padding: .5em 1em;
    }

    &.mbsc-segmented-item:first-child {
      .mbsc-segmented-button {
        border-top-left-radius: .25em;
        border-bottom-left-radius: .25em;
      }
    }

    &.mbsc-segmented-item:last-child {
      .mbsc-segmented-button {
        border-top-right-radius: .25em;
        border-bottom-right-radius: .25em;
        border-right-width: .142858em;
      }
    }

    &.mbsc-segmented-button.mbsc-button {
      margin: 0;
      padding: .428572em 1.142858em;
      background: none;
      border: .142858em solid transparent;
      border-right-width: 0;
      border-radius: 0;
      font-size: .875em;
      line-height: 1.428572em;

      &.mbsc-active,
      &.mbsc-hover {
        opacity: 1;
      }

      /* Color presets */

      &.mbsc-button-primary {
        color: $mbsc-mobiscroll-primary;
        border-color: $mbsc-mobiscroll-primary;

        &.mbsc-hover,
        &.mbsc-active {
          background: rgba($mbsc-mobiscroll-primary, .2);
        }

        &.mbsc-focus {
          background: rgba($mbsc-mobiscroll-primary, .3);
        }

        &.mbsc-selected {
          background: $mbsc-mobiscroll-primary;
        }
      }

      &.mbsc-button-secondary {
        color: $mbsc-mobiscroll-secondary;
        border-color: $mbsc-mobiscroll-secondary;

        &.mbsc-hover,
        &.mbsc-active {
          background: rgba($mbsc-mobiscroll-secondary, .2);
        }

        &.mbsc-focus {
          background: rgba($mbsc-mobiscroll-secondary, .3);
        }

        &.mbsc-selected {
          background: $mbsc-mobiscroll-secondary;
        }
      }

      &.mbsc-button-success {
        color: $mbsc-mobiscroll-success;
        border-color: $mbsc-mobiscroll-success;

        &.mbsc-hover,
        &.mbsc-active {
          background: rgba($mbsc-mobiscroll-success, .2);
        }

        &.mbsc-focus {
          background: rgba($mbsc-mobiscroll-success, .3);
        }

        &.mbsc-selected {
          background: $mbsc-mobiscroll-success;
        }
      }

      &.mbsc-button-danger {
        color: $mbsc-mobiscroll-danger;
        border-color: $mbsc-mobiscroll-danger;

        &.mbsc-hover,
        &.mbsc-active {
          background: rgba($mbsc-mobiscroll-danger, .2);
        }

        &.mbsc-focus {
          background: rgba($mbsc-mobiscroll-danger, .3);
        }

        &.mbsc-selected {
          background: $mbsc-mobiscroll-danger;
        }
      }

      &.mbsc-button-warning {
        color: $mbsc-mobiscroll-warning;
        border-color: $mbsc-mobiscroll-warning;

        &.mbsc-hover,
        &.mbsc-active {
          background: rgba($mbsc-mobiscroll-warning, .2);
        }

        &.mbsc-focus {
          background: rgba($mbsc-mobiscroll-warning, .3);
        }

        &.mbsc-selected {
          background: $mbsc-mobiscroll-warning;
        }
      }

      &.mbsc-button-info {
        color: $mbsc-mobiscroll-info;
        border-color: $mbsc-mobiscroll-info;

        &.mbsc-hover,
        &.mbsc-active {
          background: rgba($mbsc-mobiscroll-info, .2);
        }

        &.mbsc-focus {
          background: rgba($mbsc-mobiscroll-info, .3);
        }

        &.mbsc-selected {
          background: $mbsc-mobiscroll-info;
        }
      }
    }
  }

  @include mbsc-mobiscroll-segmented('mobiscroll', $mbsc-mobiscroll-colors);
  @include mbsc-mobiscroll-segmented('mobiscroll-dark', $mbsc-mobiscroll-dark-colors);
}



@if ($mbsc-ios-theme) {
  .mbsc-ios {
    &.mbsc-segmented {
      margin: .75em;
      padding: .0625em;
      position: relative;
      border-radius: .5625em;
    }

    &.mbsc-segmented-item:before {
      content: '';
      position: absolute;
      border-left: 1px solid;
      top: .3125em;
      bottom: .3125em;
      opacity: 1;
      transition: opacity .2s ease-in-out;
    }

    &.mbsc-segmented-item.mbsc-ltr:before {
      left: 0;
    }

    &.mbsc-segmented-item.mbsc-rtl:before {
      right: 0;
    }

    &.mbsc-segmented-item:first-child:before,
    &.mbsc-segmented-item-selected:before,
    &.mbsc-segmented-item-selected + .mbsc-segmented-item:before {
      opacity: 0;
    }

    &.mbsc-segmented-selectbox {
      position: absolute;
      padding: .0625em;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      user-select: none;
      border-radius: .4375em;
    }

    &.mbsc-segmented-selectbox-animate {
      transition: transform .2s ease-in-out;
    }

    &.mbsc-segmented-selectbox-inner {
      height: 100%;
      border-radius: .4375em;
      transition: transform .1s ease-in-out;
      visibility: hidden;
    }

    &.mbsc-segmented-selectbox-inner-visible {
      visibility: visible;
    }

    &.mbsc-segmented-item:first-child .mbsc-segmented-selectbox-inner {
      transform-origin: left;
    }

    &.mbsc-segmented-item:last-child .mbsc-segmented-selectbox-inner {
      transform-origin: right;
    }

    &.mbsc-segmented-dragging {
      .mbsc-segmented-selectbox-inner {
        transform: scale(.97, .95);
      }

      .mbsc-segmented-item:first-child .mbsc-segmented-selectbox-inner {
        transform: scale(.97, .95) translateX(.0625em);
      }

      .mbsc-segmented-item:last-child .mbsc-segmented-selectbox-inner {
        transform: scale(.97, .95) translateX(-.0625em);
      }
    }

    &.mbsc-segmented-button.mbsc-icon-button {
      height: 2.307693em; // 30px / 13px
    }

    &.mbsc-segmented-button.mbsc-button {
      margin: 0;
      padding: 0 .615385em; // 0 8px
      border-radius: 0;
      font-size: .8125em; // 13px
      line-height: 2.307693em; // 30px / 13px
      transition: opacity .1s ease-out, background-color .1s ease-out, transform .1s ease-in-out;
      // for the scaling to look better
      -webkit-font-smoothing: subpixel-antialiased;

      &.mbsc-active,
      &.mbsc-hover {
        opacity: 1;
      }
    }

    &.mbsc-segmented-dragging .mbsc-segmented-item-selected .mbsc-segmented-button {
      transform: scale(.97, .95);
    }

    &.mbsc-segmented-item-selected:first-child .mbsc-segmented-button {
      transform-origin: left;
    }

    &.mbsc-segmented-item-selected:last-child .mbsc-segmented-button {
      transform-origin: right;
    }

  }

  @include mbsc-ios-segmented('ios', $mbsc-ios-colors);
  @include mbsc-ios-segmented('ios-dark', $mbsc-ios-dark-colors);
}



@if ($mbsc-material-theme) {
  .mbsc-material {
    &.mbsc-segmented {
      padding: .75em;
    }

    &.mbsc-segmented-selectbox {
      display: none;
    }

    &.mbsc-segmented-item:first-child {
      .mbsc-segmented-button {
        border-top-left-radius: .25em;
        border-bottom-left-radius: .25em;
      }

      .mbsc-segmented-button.mbsc-rtl {
        border-radius: 0 .25em .25em 0;
        border-right-width: .142858em;
      }
    }

    &.mbsc-segmented-item:last-child {
      .mbsc-segmented-button {
        border-top-right-radius: .25em;
        border-bottom-right-radius: .25em;
        border-right-width: .142858em;
      }

      .mbsc-segmented-button.mbsc-rtl {
        border-radius: .25em 0 0 .25em;
        border-right-width: 0;
      }
    }

    &.mbsc-segmented-button.mbsc-button {
      margin: 0;
      padding: .285715em 1.142858em;
      border: .142858em solid transparent;
      border-right-width: 0;
      border-radius: 0;
      background: none;
      box-shadow: none;
      min-width: auto;
      font-size: .875em;

      &.mbsc-hover,
      &.mbsc-active {
        box-shadow: none;
      }

      &.mbsc-focus:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }

      /* Color presets */

      &.mbsc-button-primary {
        color: $mbsc-material-primary;
        border-color: $mbsc-material-primary;

        &.mbsc-hover,
        &.mbsc-active {
          background: rgba($mbsc-material-primary, .2);
        }

        &.mbsc-selected {
          background: $mbsc-material-primary;
        }
      }

      &.mbsc-button-secondary {
        color: $mbsc-material-secondary;
        border-color: $mbsc-material-secondary;

        &.mbsc-hover,
        &.mbsc-active {
          background: rgba($mbsc-material-secondary, .2);
        }

        &.mbsc-selected {
          background: $mbsc-material-secondary;
        }
      }

      &.mbsc-button-success {
        color: $mbsc-material-success;
        border-color: $mbsc-material-success;

        &.mbsc-hover,
        &.mbsc-active {
          background: rgba($mbsc-material-success, .2);
        }

        &.mbsc-selected {
          background: $mbsc-material-success;
        }
      }

      &.mbsc-button-danger {
        color: $mbsc-material-danger;
        border-color: $mbsc-material-danger;

        &.mbsc-hover,
        &.mbsc-active {
          background: rgba($mbsc-material-danger, .2);
        }

        &.mbsc-selected {
          background: $mbsc-material-danger;
        }
      }

      &.mbsc-button-warning {
        color: $mbsc-material-warning;
        border-color: $mbsc-material-warning;

        &.mbsc-hover,
        &.mbsc-active {
          background: rgba($mbsc-material-warning, .2);
        }

        &.mbsc-selected {
          background: $mbsc-material-warning;
        }
      }

      &.mbsc-button-info {
        color: $mbsc-material-info;
        border-color: $mbsc-material-info;

        &.mbsc-hover,
        &.mbsc-active {
          background: rgba($mbsc-material-info, .2);
        }

        &.mbsc-selected {
          background: $mbsc-material-info;
        }
      }
    }

  }

  @include mbsc-material-segmented('material', $mbsc-material-colors);
  @include mbsc-material-segmented('material-dark', $mbsc-material-dark-colors);
}



@if ($mbsc-windows-theme) {
  .mbsc-windows {
    &.mbsc-segmented {
      padding: .75em;
    }

    &.mbsc-segmented-selectbox {
      display: none;
    }

    &.mbsc-segmented-button.mbsc-button {
      margin: 0;
      border-color: transparent;

      &.mbsc-active,
      &.mbsc-hover {
        border-color: transparent;
      }

      &.mbsc-focus {
        z-index: 2;
      }

      /* Color presets */
      &.mbsc-button-primary {

        &.mbsc-active,
        &.mbsc-selected {
          background: $mbsc-windows-primary;
        }
      }

      &.mbsc-button-secondary {

        &.mbsc-active,
        &.mbsc-selected {
          background: $mbsc-windows-secondary;
        }
      }

      &.mbsc-button-success {

        &.mbsc-active,
        &.mbsc-selected {
          background: $mbsc-windows-success;
        }
      }

      &.mbsc-button-danger {

        &.mbsc-active,
        &.mbsc-selected {
          background: $mbsc-windows-danger;
        }
      }

      &.mbsc-button-warning {

        &.mbsc-active,
        &.mbsc-selected {
          background: $mbsc-windows-warning;
        }
      }

      &.mbsc-button-info {

        &.mbsc-active,
        &.mbsc-selected {
          background: $mbsc-windows-info;
        }
      }
    }
  }

  @include mbsc-windows-segmented('windows', $mbsc-windows-colors);
  @include mbsc-windows-segmented('windows-dark', $mbsc-windows-dark-colors);
}


.mbsc-segmented {
  // display: table;
  // table-layout: fixed;
  // width: 100%;
  display: flex;
  overflow: hidden;
  // box-sizing: border-box;
}

.mbsc-segmented-item {
  margin: 0;
  // display: table-cell;
  position: relative;
  vertical-align: top;
  text-align: center;
  font-size: 1em;
  flex: 1 1 0;
  user-select: none;
  min-width: 0; // needed for the items to have equal widths
}

.mbsc-segmented-label {
  display: block;
  margin: 0;
  padding: 0;
}

.mbsc-segmented-input {
  width: 1px;
  height: 0;
  opacity: 0;
  position: absolute;
}

.mbsc-segmented-button.mbsc-button {
  width: 100%;
}



@mixin mbsc-ios-stepper($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');

  $bg-param: map-get($params, 'form-background');
  $text-param: map-get($params, 'form-text');

  $background: if($bg-param, $bg-param, $background);
  $text: if($text-param, $text-param, $text);

  $button-background: '';
  $border: '';

  // Light background
  @if (lightness($background) > 50%) {
    $button-background: darken($background, 10%);
    $border: #000;
  }

  // Dark background
  @else {
    $button-background: lighten($background, 17%);
    $border: #fff;
  }

  .mbsc-#{$theme} {
    &.mbsc-stepper-input {
      border-color: $button-background;
      color: $text;
    }

    &.mbsc-stepper-input.mbsc-disabled {
      color: rgba($text, .2);
    }

    &.mbsc-stepper-button {
      background: $button-background;
      color: $text;
    }

    &.mbsc-stepper-plus:before {
      border-color: rgba($border, .2);
    }

  }
}


@if ($mbsc-ios-theme) {
  .mbsc-ios {
    &.mbsc-stepper-input {
      margin-left: -1px;
      margin-right: -1px;
      z-index: 1;
      border: 1px solid;
    }

    &.mbsc-stepper-button.mbsc-button {
      line-height: 1em;
    }

    &.mbsc-stepper-button.mbsc-disabled {
      opacity: 1;
    }

    &.mbsc-stepper-inner {
      font-weight: normal;
    }

    &.mbsc-disabled .mbsc-stepper-inner {
      opacity: .2;
    }

    &.mbsc-stepper-plus:before {
      content: '';
      position: absolute;
      border-left: 1px solid;
      top: .4125em;
      bottom: .4125em;
    }

    &.mbsc-stepper-plus.mbsc-ltr:before {
      left: 0;
    }

    &.mbsc-stepper-plus.mbsc-rtl:before {
      right: 0;
    }

    &.mbsc-stepper-center .mbsc-stepper-plus:before {
      display: none;
    }

    // button colors

    &.mbsc-primary .mbsc-stepper-button {
      color: $mbsc-ios-primary;
    }

    &.mbsc-secondary .mbsc-stepper-button {
      color: $mbsc-ios-secondary;
    }

    &.mbsc-success .mbsc-stepper-button {
      color: $mbsc-ios-success;
    }

    &.mbsc-danger .mbsc-stepper-button {
      color: $mbsc-ios-danger;
    }

    &.mbsc-warning .mbsc-stepper-button {
      color: $mbsc-ios-warning;
    }

    &.mbsc-info .mbsc-stepper-button {
      color: $mbsc-ios-info;
    }
  }
}

@include mbsc-ios-stepper('ios', $mbsc-ios-colors);
@include mbsc-ios-stepper('ios-dark', $mbsc-ios-dark-colors);



@mixin mbsc-material-stepper($theme, $params) {
  $text: map-get($params, 'text');
  $background: map-get($params, 'background');

  $bg-param: map-get($params, 'form-background');
  $text-param: map-get($params, 'form-text');
  
  $background: if($bg-param, $bg-param, $background);
  $text: if($text-param, $text-param, $text);

  $button-color: '';
  $text-color: '';
  $text-color-disabled: '';

  // Light background
  @if (lightness($background) > 50%) {
    $button-color: darken($background, 16%);
    $text-color: lighten($text, 17%);
    $text-color-disabled: darken($background, 30%);
  }

  // Dark background
  @else {
    $button-color: lighten($background, 35%);
    $text-color: darken($text, 24%);
    $text-color-disabled: lighten($background, 45%);
  }

  .mbsc-#{$theme} {
    &.mbsc-stepper-input {
      border-color: $button-color;
      color: $text-color;
    }

    &.mbsc-stepper-input.mbsc-disabled {
      color: $text-color-disabled;
    }

    &.mbsc-stepper-button {
      background: $button-color;
      color: $button-color;
    }

    &.mbsc-stepper-inner {
      color: $background;
    }

    &.mbsc-color-none .mbsc-stepper-inner {
      color: $text;
    }

    &.mbsc-stepper-button.mbsc-disabled {
      background: $button-color;
    }

    &.mbsc-stepper-button.mbsc-disabled .mbsc-stepper-inner {
      color: $text-color-disabled;
    }

  }
}


@if ($mbsc-material-theme) {
  .mbsc-material {
    &.mbsc-stepper-control {
      height: 2.25em;
    }

    &.mbsc-stepper-inner {
      font-size: 2.142857em;
      font-weight: 750;
    }

    &.mbsc-stepper-button.mbsc-button {
      line-height: 1em;
      box-shadow: none;
      border: 2px solid currentColor;
    }

    &.mbsc-stepper-minus.mbsc-ltr,
    &.mbsc-stepper-plus.mbsc-rtl {
      border-right: 0;
    }

    &.mbsc-stepper-minus.mbsc-rtl,
    &.mbsc-stepper-plus.mbsc-ltr {
      border-left: 0;
    }

    &.mbsc-stepper-button.mbsc-disabled {
      opacity: 1;
    }

    &.mbsc-stepper-input {
      z-index: 1;
      border-top: 2px solid;
      border-bottom: 2px solid;
      border-left: 0;
      border-right: 0;
      width: 4em;
    }

    // button colors

    &.mbsc-primary .mbsc-stepper-button {
      background: $mbsc-material-primary;
      border-color: $mbsc-material-primary;
    }

    &.mbsc-secondary .mbsc-stepper-button {
      background: $mbsc-material-secondary;
      border-color: $mbsc-material-secondary;
    }

    &.mbsc-success .mbsc-stepper-button {
      background: $mbsc-material-success;
      border-color: $mbsc-material-success;
    }

    &.mbsc-danger .mbsc-stepper-button {
      background: $mbsc-material-danger;
      border-color: $mbsc-material-danger;
    }

    &.mbsc-warning .mbsc-stepper-button {
      background: $mbsc-material-warning;
      border-color: $mbsc-material-warning;
    }

    &.mbsc-info .mbsc-stepper-button {
      background: $mbsc-material-info;
      border-color: $mbsc-material-info;
    }

    // input border colors

    &.mbsc-primary .mbsc-stepper-input {
      border-color: $mbsc-material-primary;
    }

    &.mbsc-secondary .mbsc-stepper-input {
      border-color: $mbsc-material-secondary;
    }

    &.mbsc-success .mbsc-stepper-input {
      border-color: $mbsc-material-success;
    }

    &.mbsc-danger .mbsc-stepper-input {
      border-color: $mbsc-material-danger;
    }

    &.mbsc-warning .mbsc-stepper-input {
      border-color: $mbsc-material-warning;
    }

    &.mbsc-info .mbsc-stepper-input {
      border-color: $mbsc-material-info;
    }

  }
}

@include mbsc-material-stepper('material', $mbsc-material-colors);
@include mbsc-material-stepper('material-dark', $mbsc-material-dark-colors);



@mixin mbsc-windows-stepper($theme, $params) {
  $text: map-get($params, 'text');
  $background: map-get($params, 'background');

  $bg-param: map-get($params, 'form-background');
  $text-param: map-get($params, 'form-text');
  
  $background: if($bg-param, $bg-param, $background);
  $text: if($text-param, $text-param, $text);

  $border-color: '';
  $disabled-text-color: '';
  $disabled-button-color: '';
  $hover: '';

  // Light background
  @if (lightness($background) > 50%) {
    $border-color: darken($background, 20%);
    $disabled-text-color: darken($background, 30%);
    $disabled-button-color: darken($background, 20%);;
    $hover: #000;
  }

  // Dark background
  @else {
    $border-color: lighten($background, 15%);
    $disabled-text-color: lighten($background, 40%);
    $disabled-button-color: lighten($background, 15%);
    $hover: #fff;
  }

  .mbsc-#{$theme} {
    &.mbsc-stepper-input {
      border-color: $border-color;
      color: $text;
    }

    &.mbsc-stepper-input.mbsc-disabled {
      color: $disabled-text-color;
    }

    &.mbsc-stepper-button {
      color: $border-color;
    }

    &.mbsc-stepper-button.mbsc-hover:before {
      background: rgba($hover, .2);
    }

    &.mbsc-stepper-button.mbsc-disabled {
      background: $disabled-button-color;
    }

    &.mbsc-stepper-inner {
      color: $text;
    }

    &.mbsc-stepper-button.mbsc-disabled .mbsc-stepper-inner {
      color: $disabled-text-color;
    }

  }
}


@if ($mbsc-windows-theme) {
  .mbsc-windows {

    &.mbsc-stepper-button.mbsc-button {
      width: 3.625em;
      line-height: 1.5em;
      border-color: currentColor;
    }

    &.mbsc-stepper-button.mbsc-hover:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &.mbsc-stepper-button.mbsc-disabled {
      opacity: 1;
    }

    &.mbsc-stepper-minus.mbsc-ltr,
    &.mbsc-stepper-plus.mbsc-rtl {
      border-right: 0;
    }

    &.mbsc-stepper-minus.mbsc-rtl,
    &.mbsc-stepper-plus.mbsc-ltr {
      border-left: 0;
    }

    &.mbsc-stepper-inner {
      font-size: 1.75em;
      font-weight: 800;
    }

    &.mbsc-stepper-input {
      z-index: 1;
      border-top: 2px solid;
      border-bottom: 2px solid;
      border-left: 0;
      border-right: 0;
      width: 4em;
    }

    // button colors

    &.mbsc-primary .mbsc-stepper-button {
      background: $mbsc-windows-primary;
      border-color: $mbsc-windows-primary;
    }

    &.mbsc-secondary .mbsc-stepper-button {
      background: $mbsc-windows-secondary;
      border-color: $mbsc-windows-secondary;
    }

    &.mbsc-success .mbsc-stepper-button {
      background: $mbsc-windows-success;
      border-color: $mbsc-windows-success;
    }

    &.mbsc-danger .mbsc-stepper-button {
      background: $mbsc-windows-danger;
      border-color: $mbsc-windows-danger;
    }

    &.mbsc-warning .mbsc-stepper-button {
      background: $mbsc-windows-warning;
      border-color: $mbsc-windows-warning;
    }

    &.mbsc-info .mbsc-stepper-button {
      background: $mbsc-windows-info;
      border-color: $mbsc-windows-info;
    }

    // input border colors

    &.mbsc-primary .mbsc-stepper-input {
      border-color: $mbsc-windows-primary;
    }

    &.mbsc-secondary .mbsc-stepper-input {
      border-color: $mbsc-windows-secondary;
    }

    &.mbsc-success .mbsc-stepper-input {
      border-color: $mbsc-windows-success;
      ;
    }

    &.mbsc-danger .mbsc-stepper-input {
      border-color: $mbsc-windows-danger;
    }

    &.mbsc-warning .mbsc-stepper-input {
      border-color: $mbsc-windows-warning;
    }

    &.mbsc-info .mbsc-stepper-input {
      border-color: $mbsc-windows-info;
    }
  }
}

@include mbsc-windows-stepper('windows', $mbsc-windows-colors);
@include mbsc-windows-stepper('windows-dark', $mbsc-windows-dark-colors);


.mbsc-stepper {
  line-height: 1.25em;
  position: relative;
  display: block;
  margin: 0;
  z-index: 0;
  user-select: none;
  box-sizing: border-box;
  padding: 1.5em 11.75em 1.5em 1em;
}

.mbsc-stepper.mbsc-rtl {
  padding: 1.5em 1em 1.5em 11.75em;
}

.mbsc-stepper-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.mbsc-stepper-input {
  width: 3.571429em;
  padding: 0;
  margin: 0;
  box-shadow: none;
  border-radius: 0;
  font-size: .875em;
  text-align: center;
  opacity: 1;
  z-index: 3;
  background: transparent;
  appearance: none;
}

.mbsc-stepper-input::-webkit-outer-spin-button,
.mbsc-stepper-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mbsc-stepper-input:focus {
  outline-width: 0;
}

.mbsc-stepper-input.mbsc-disabled {
  cursor: not-allowed;
}

.mbsc-stepper-control {
  position: absolute;
  display: flex;
  z-index: 2;
  margin-top: -1em;
  top: 50%;
  height: 2em;
}

.mbsc-stepper-control.mbsc-ltr {
  right: 1em;
}

.mbsc-stepper-control.mbsc-rtl {
  left: 1em;
}

.mbsc-stepper-start .mbsc-stepper-input {
  border: none;
  order: -1;
}

.mbsc-stepper-start.mbsc-ltr .mbsc-stepper-minus.mbsc-button {
  margin-right: -1px;
}

.mbsc-stepper-start.mbsc-rtl .mbsc-stepper-minus.mbsc-button {
  margin-left: -1px;
}

.mbsc-stepper-end .mbsc-stepper-input {
  border: none;
  order: 2;
}

.mbsc-stepper-end.mbsc-ltr .mbsc-stepper-minus.mbsc-button {
  margin-right: -1px;
}

.mbsc-stepper-end.mbsc-rtl .mbsc-stepper-minus.mbsc-button {
  margin-left: -1px;
}

.mbsc-stepper-button.mbsc-button.mbsc-font {
  width: 3.25em;
  margin: 0;
  display: inline-block;
}

.mbsc-stepper-inner {
  font-size: 2em;
  font-weight: bold;
}

.mbsc-stepper-minus.mbsc-button.mbsc-ltr {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.mbsc-stepper-plus.mbsc-button.mbsc-ltr {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.mbsc-stepper-minus.mbsc-button.mbsc-rtl {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.mbsc-stepper-plus.mbsc-button.mbsc-rtl {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}



@mixin mbsc-mobiscroll-switch($theme, $params) {

  .mbsc-#{$theme} {
    &.mbsc-switch-cont {
      color: red;
    }
  }
}


@if ($mbsc-mobiscroll-theme) {
  .mbsc-mobiscroll {

    &.mbsc-switch-right {
      padding: 1em 3.125em 1em 1em;
    }

    &.mbsc-switch-left {
      padding: 1em 1em 1em 3.125em;
    }

  }

  @include mbsc-mobiscroll-switch('mobiscroll', $mbsc-mobiscroll-colors);
  @include mbsc-mobiscroll-switch('mobiscroll-dark', $mbsc-mobiscroll-dark-colors);
}



@if ($mbsc-ios-theme) {
  .mbsc-ios {
    &.mbsc-switch-right {
      padding: .875em 5em .875em 1em;
    }

    &.mbsc-switch-left {
      padding: .875em 1em .875em 5em;
    }

    // Track

    &.mbsc-switch-track {
      width: 1.25em;
      height: 2em;
      padding: 0;
      margin: -.96875em .875em 0 .875em;
      border-radius: 1.25em;
    }

    &.mbsc-switch-track-right {
      right: 1em;
    }

    &.mbsc-switch-track-left {
      left: 1em;
    }

    &.mbsc-switch-track:after {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      right: -1em;
      bottom: 0;
      left: -1em;
      border-radius: 1.25em;
      transition: background-color .2s ease-out;
    }

    &.mbsc-switch-track.mbsc-disabled {
      opacity: .3;
    }

    // Handle

    &.mbsc-switch-handle {
      z-index: 2;
      top: 50%;
      left: 50%;
      width: 1.75em;
      height: 1.75em;
      margin: -.875em 0 0 -.875em;
      border-radius: 1.75em;
      box-shadow: 0 .1875em .75em rgba(0, 0, 0, .16), 0 .1875em .0625em rgba(0, 0, 0, .1);
      background: #fff;
    }

    // Color presets

    &.mbsc-switch-track.mbsc-switch-primary.mbsc-checked:after {
      background: $mbsc-ios-primary;
    }

    &.mbsc-switch-track.mbsc-switch-secondary.mbsc-checked:after {
      background: $mbsc-ios-secondary;
    }

    &.mbsc-switch-track.mbsc-switch-success.mbsc-checked:after {
      background: $mbsc-ios-success;
    }

    &.mbsc-switch-track.mbsc-switch-danger.mbsc-checked:after {
      background: $mbsc-ios-danger;
    }

    &.mbsc-switch-track.mbsc-switch-warning.mbsc-checked:after {
      background: $mbsc-ios-warning;
    }

    &.mbsc-switch-track.mbsc-switch-info.mbsc-checked:after {
      background: $mbsc-ios-info;
    }
  }

  @include mbsc-ios-switch('ios', $mbsc-ios-colors);
  @include mbsc-ios-switch('ios-dark', $mbsc-ios-dark-colors);
}



@if ($mbsc-material-theme) {
  .mbsc-material {

    &.mbsc-switch-right {
      padding: .9375em 4em .9375em 1em;
    }

    &.mbsc-switch-left {
      padding: .9375em 1em .9375em 4em;
    }

    // Track

    &.mbsc-switch-track {
      width: 1.75em;
      height: .875em;
      padding: 0 .25em;
      margin-top: -.4375em;
      border-radius: 1.25em;
    }

    &.mbsc-switch-track-left {
      left: 1.25em;
    }

    &.mbsc-switch-track-right {
      right: 1.25em;
    }

    // Handle

    &.mbsc-switch-handle {
      width: 1.25em;
      height: 1.25em;
      border-radius: 1.25em;
      top: 50%;
      margin-left: -.625em;
      margin-top: -.625em;
    }

    &.mbsc-switch-handle:before {
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      opacity: 0;
      transition: opacity .2s ease-in-out;
      border-radius: 2.875em;
      top: -.625em;
      left: -.625em;
      width: 2.5em;
      height: 2.5em;
    }

    &.mbsc-switch-handle.mbsc-focus:before {
      opacity: .5;
    }

    &.mbsc-switch-handle.mbsc-active:before {
      opacity: 1;
    }

    /* Color presets */


    &.mbsc-switch-handle.mbsc-switch-primary.mbsc-checked {
      background: $mbsc-material-primary;
    }

    &.mbsc-switch-track.mbsc-switch-primary.mbsc-checked {
      background: lighten($mbsc-material-primary, 20%);
    }

    &.mbsc-switch-handle.mbsc-switch-secondary.mbsc-checked {
      background: $mbsc-material-secondary;
    }

    &.mbsc-switch-track.mbsc-switch-secondary.mbsc-checked {
      background: lighten($mbsc-material-secondary, 20%);
    }

    &.mbsc-switch-handle.mbsc-switch-success.mbsc-checked {
      background: $mbsc-material-success;
    }

    &.mbsc-switch-track.mbsc-switch-success.mbsc-checked {
      background: lighten($mbsc-material-success, 20%);
    }

    &.mbsc-switch-handle.mbsc-switch-danger.mbsc-checked {
      background: $mbsc-material-danger;
    }

    &.mbsc-switch-track.mbsc-switch-danger.mbsc-checked {
      background: lighten($mbsc-material-danger, 20%);
    }

    &.mbsc-switch-handle.mbsc-switch-warning.mbsc-checked {
      background: $mbsc-material-warning;
    }

    &.mbsc-switch-track.mbsc-switch-warning.mbsc-checked {
      background: lighten($mbsc-material-warning, 20%);
    }

    &.mbsc-switch-handle.mbsc-switch-info.mbsc-checked {
      background: $mbsc-material-info;
    }

    &.mbsc-switch-track.mbsc-switch-info.mbsc-checked {
      background: lighten($mbsc-material-info, 20%);
    }


  }

  @include mbsc-material-switch('material', $mbsc-material-colors);
  @include mbsc-material-switch('material-dark', $mbsc-material-dark-colors);
}



@if ($mbsc-windows-theme) {
  .mbsc-windows {

    &.mbsc-switch-right {
      padding: 1.125em 5.25em 1.125em 1em;
    }

    &.mbsc-switch-left {
      padding: 1.125em 1em 1.125em 5.25em;
    }

    // Track

    &.mbsc-switch-track {
      width: 1.5em;
      height: 1em;
      margin-top: -.5em;
    }

    &.mbsc-switch-track-right {
      right: 1.75em; // .125 padding + .625 visible track relative position
    }

    &.mbsc-switch-track-left {
      left: 1.75em; // .125 padding + .625 visible track relative position
    }

    &.mbsc-switch-track:before,
    &.mbsc-switch-track:after {
      content: '';
      position: absolute;
      z-index: 1;
      border: .125em solid transparent;

    }

    &.mbsc-switch-track:before {
      top: -.125em; // .125em border
      bottom: -.125em; // .125em border
      left: -.625em; // .125em border + .5em space
      right: -.625em; // .125em border + .5em space
      border-radius: .625em;
      transition: background-color .2s ease-in-out, border .2s ease-in-out;
    }

    // used for the focus outline
    &.mbsc-switch-track:after {
      top: -.25em;
      bottom: -.25em;
      left: -.75em;
      right: -.75em;
      border-radius: .75em;
    }

    &.mbsc-switch-track.mbsc-disabled {
      opacity: .2;
    }

    // Handle

    &.mbsc-switch-handle {
      z-index: 2;
      top: 50%;
      left: 50%;
      right: auto;
      height: .625em;
      width: .625em;
      border-radius: 10px;
      margin: -.3125em 0 0 -.3125em;
    }

    // Color presets

    &.mbsc-switch-track.mbsc-switch-primary.mbsc-checked:before {
      border-color: $mbsc-windows-primary;
      background: $mbsc-windows-primary;
    }

    &.mbsc-switch-track.mbsc-switch-secondary.mbsc-checked:before {
      border-color: $mbsc-windows-secondary;
      background: $mbsc-windows-secondary;
    }

    &.mbsc-switch-track.mbsc-switch-success.mbsc-checked:before {
      border-color: $mbsc-windows-success;
      background: $mbsc-windows-success;
    }

    &.mbsc-switch-track.mbsc-switch-danger.mbsc-checked:before {
      border-color: $mbsc-windows-danger;
      background: $mbsc-windows-danger;
    }

    &.mbsc-switch-track.mbsc-switch-warning.mbsc-checked:before {
      border-color: $mbsc-windows-warning;
      background: $mbsc-windows-warning;
    }

    &.mbsc-switch-track.mbsc-switch-info.mbsc-checked:before {
      border-color: $mbsc-windows-info;
      background: $mbsc-windows-info;
    }

  }

  @include mbsc-windows-switch('windows', $mbsc-windows-colors);
  @include mbsc-windows-switch('windows-dark', $mbsc-windows-dark-colors);
}


.mbsc-switch {
  position: relative;
  display: block;
  margin: 0;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  line-height: 1.25em;
}

.mbsc-switch-track {
  position: absolute;
  top: 50%;
  z-index: 4;
  display: block;
  box-sizing: border-box;
}

.mbsc-switch-handle {
  display: block;
  position: absolute;
  cursor: pointer;

  &.mbsc-disabled {
    cursor: not-allowed;
  }
}

.mbsc-switch-handle-animate {
  transition: left .1s ease-in-out;
}

.mbsc-description {
  display: block;
  font-size: .75em;
  opacity: .6;
}



@mixin mbsc-mobiscroll-calendar-view($theme, $params) {
  $background: map-get($params, "background");
  $text: map-get($params, "text");
  $accent: map-get($params, "accent");

  .mbsc-#{$theme} {
    &.mbsc-calendar-wrapper-fixed {
      border-bottom: 1px solid $accent;
    }

    &.mbsc-calendar-week-day {
      border-bottom: 1px solid $accent;
      color: $accent;
    }

    &.mbsc-focus .mbsc-calendar-day-text {
      box-shadow: 0 0 0 2px rgba($text, 0.5);
    }

    &.mbsc-hover .mbsc-calendar-day-text {
      background: rgba($accent, 0.3);
    }

    &.mbsc-selected .mbsc-calendar-day-text {
      background: $accent;
      color: $background;
    }

    /* Week numbers */
    .mbsc-calendar-week-nr {
      color: $accent;
    }

    /* Marks */
    &.mbsc-calendar-mark {
      background: $text;
    }

    /* Labels */
    &.mbsc-calendar-label {
      color: $background;
      background: $text;
    }
  }
}


@if ($mbsc-mobiscroll-theme) {
  .mbsc-mobiscroll {
    &.mbsc-calendar-header {
      padding: 0.5em;
    }

    &.mbsc-calendar-slide {
      padding: 0 0.5em 0.5em 0.5em;
    }

    &.mbsc-calendar-day-text {
      width: 2.153847em;
      height: 2.153847em;
      margin: 0.307692em auto;
      line-height: 2.153847em;
      font-size: 0.8125em;
      border: 2px solid transparent;
      border-radius: 2em;
      box-sizing: content-box;
    }

    /* Week numbers */
    .mbsc-calendar-week-nr {
      width: 2.363637em;
      font-size: 0.6875em;
    }
  }

  @include mbsc-mobiscroll-calendar-view('mobiscroll', $mbsc-mobiscroll-colors);
  @include mbsc-mobiscroll-calendar-view('mobiscroll-dark', $mbsc-mobiscroll-dark-colors);
}



@if ($mbsc-ios-theme) {
  .mbsc-ios {
    &.mbsc-calendar-controls {
      /* IE11 needs px size here to avoid subpixel values */
      padding: 2px;
      /* padding: .125em; */
    }

    &.mbsc-calendar-cell {
      border-top-style: solid;
      border-top-width: 1px;
    }

    &.mbsc-calendar-cell-text {
      height: 2em;
      padding: 0 .5em;
      line-height: 2em;
      border-radius: 2em;
    }

    &.mbsc-calendar-week-day {
      height: 1.7em;
      line-height: 1.7em;
      font-size: 0.625em;
    }

    &.mbsc-calendar-week-nr {
      width: 2.363636em;
      height: auto;
      font-size: 0.75em;
      font-weight: bold;
      line-height: 3em;
    }

    &.mbsc-calendar-day-text {
      width: 1.625em;
      height: 1.625em;
      margin: 0.1875em;
      padding: 0;
      line-height: 1.625em;
      border: 2px solid transparent;
      border-radius: 2em;
    }

    /* Range Highlight */

    &.mbsc-range-day .mbsc-calendar-cell-inner {
      z-index: 1;
    }

    &.mbsc-range-day::after,
    &.mbsc-range-hover::before {
      content: '';
      height: 1.875em;
      position: absolute;
      left: -1px;
      right: -1px;
      top: .25em;
      z-index: 0;
      box-sizing: border-box;
    }

    &.mbsc-range-hover::before {
      border-top: 2px dashed;
      border-bottom: 2px dashed;
    }

    &.mbsc-range-day-start.mbsc-ltr::after,
    &.mbsc-range-day-end.mbsc-rtl::after,
    &.mbsc-range-hover-start.mbsc-ltr::before,
    &.mbsc-range-hover-end.mbsc-rtl::before {
      left: 50%;
      margin-left: -0.9375em;
      border-radius: 2em 0 0 2em;
    }

    &.mbsc-range-day-end.mbsc-ltr::after,
    &.mbsc-range-day-start.mbsc-rtl::after,
    &.mbsc-range-hover-end.mbsc-ltr::before,
    &.mbsc-range-hover-start.mbsc-rtl::before {
      right: 50%;
      margin-right: -0.9375em;
      border-radius: 0 2em 2em 0;
    }

    &.mbsc-range-day-start.mbsc-range-day-end::after,
    &.mbsc-range-hover-start.mbsc-range-hover-end::before {
      display: none;
    }

    /* Marks */

    &.mbsc-calendar-day-marked {
      padding-bottom: 0.4375em;
    }

    &.mbsc-calendar-marks {
      margin-top: -0.0625em;
    }

    &.mbsc-calendar-mark {
      width: 0.375em;
      height: 0.375em;
      border-radius: 0.375em;
      margin: 0 0.0625em;
    }

    /* Colors */

    &.mbsc-calendar-day-colors .mbsc-calendar-day-text {
      background-clip: padding-box;
    }

    &.mbsc-calendar-day-colors.mbsc-hover .mbsc-calendar-cell-text {
      background-clip: border-box;
    }

    /* Labels */

    &.mbsc-calendar-text:before {
      border-radius: .4em;
    }

    &.mbsc-calendar-label {
      font-weight: 600;
    }

    &.mbsc-calendar-label-background {
      margin: 0 -.1em;
      background: currentColor;
      opacity: .5;
      transition: opacity .15s ease-in-out;
    }

    &.mbsc-calendar-label-start.mbsc-ltr .mbsc-calendar-label-background,
    &.mbsc-calendar-label-end.mbsc-rtl .mbsc-calendar-label-background {
      margin-left: 0;
      border-top-left-radius: .4em;
      border-bottom-left-radius: .4em;
    }

    &.mbsc-calendar-label-end.mbsc-ltr .mbsc-calendar-label-background,
    &.mbsc-calendar-label-start.mbsc-rtl .mbsc-calendar-label-background {
      margin-right: 0;
      border-top-right-radius: .4em;
      border-bottom-right-radius: .4em;
    }

    &.mbsc-calendar-label-hover .mbsc-calendar-label-background {
      opacity: .6;
    }

    &.mbsc-calendar-label.mbsc-calendar-label-hover:before {
      background: none;
    }

    &.mbsc-calendar-label-dragging .mbsc-calendar-label-background,
    &.mbsc-calendar-label-active .mbsc-calendar-label-background {
      opacity: .9;
    }

    /* Desktop styling */

    &.mbsc-calendar-height-md {
      .mbsc-calendar-day {
        border-left-style: solid;
        border-left-width: 1px;
      }

      .mbsc-calendar-week-nr {
        font-size: 0.75em;
      }
    }

    &.mbsc-calendar-width-md {
      .mbsc-calendar-title {
        font-size: 1.5em;
        line-height: 1.666667em;
        padding: 0 0.166667em;
      }

      .mbsc-calendar-year {
        font-weight: 200;
      }

      .mbsc-calendar-week-day {
        height: 2.5em;
        padding: 0 0.5em;
        line-height: 2.5em;
        font-size: 1em;
        border-left: 1px solid transparent;
      }

      .mbsc-calendar-week-nr {
        font-size: .75em;
        padding: 0;
      }

      .mbsc-calendar-day-inner {
        min-height: 2.5em;
      }

      .mbsc-calendar-day-labels .mbsc-calendar-day-inner {
        min-height: 4.75em;
      }

      .mbsc-calendar-marks {
        padding: 0 0.75em;
      }

      .mbsc-calendar-day-text {
        width: 1.375em;
        height: 1.375em;
        line-height: 1.375em;
      }

      .mbsc-calendar-text {
        height: 1.8em;
        line-height: 1.8em;
        margin: 0 .4em .2em .4em;
      }

      .mbsc-calendar-label {
        padding: 0 .4em;
      }

      .mbsc-calendar-label-text {
        font-size: 1.2em;
      }

      .mbsc-calendar-label-background {
        margin: 0 -.4em;
      }
    }

    &.mbsc-calendar-height-md.mbsc-calendar-width-md {

      .mbsc-calendar-week-day.mbsc-ltr,
      .mbsc-calendar-day.mbsc-ltr,
      .mbsc-calendar-marks.mbsc-ltr {
        text-align: right;
      }

      .mbsc-calendar-week-day.mbsc-rtl,
      .mbsc-calendar-day.mbsc-rtl,
      .mbsc-calendar-marks.mbsc-rtl {
        text-align: left;
      }
    }

    /* Multi month grid view */

    &.mbsc-calendar-grid-view .mbsc-calendar-title {
      font-size: 1.5em;
      line-height: 1.666667em;
      padding: 0 0.166667em;
    }

    &.mbsc-calendar-grid {
      border-top: 1px solid;
    }

    &.mbsc-calendar-grid .mbsc-calendar-grid-item .mbsc-calendar-week-days {
      background: none;
    }

    &.mbsc-calendar-grid .mbsc-calendar-cell {
      border: 0;
    }
  }

  @include mbsc-ios-calendar-view('ios', $mbsc-ios-colors);
  @include mbsc-ios-calendar-view('ios-dark', $mbsc-ios-dark-colors);
}



@if ($mbsc-material-theme) {
  .mbsc-material {
    &.mbsc-calendar-wrapper-fixed:after {
      content: '';
      position: absolute;
      z-index: 6;
      bottom: -0.5em;
      left: 0;
      right: 0;
      height: 0.5em;
      pointer-events: none;
    }

    &.mbsc-calendar-controls {
      padding: 0.5em;
    }

    &.mbsc-calendar-week-day {
      height: 2.5em;
      line-height: 2.5em;
      font-size: 0.75em;
      font-weight: bold;
    }

    &.mbsc-calendar-week-nr {
      width: 2.363637em;
      height: auto;
      font-size: 0.6875em;
      line-height: 3em;
      font-weight: bold;
    }

    &.mbsc-calendar-button.mbsc-button {
      padding: .428572em;
    }

    &.mbsc-calendar-title {
      font-size: 1.428572em;
      font-weight: 400;
      text-transform: none;
      line-height: 1.4em;
    }

    &.mbsc-calendar-cell-text {
      height: 2em;
      padding: 0 .5em;
      line-height: 2em;
      border-radius: 2em;
    }

    &.mbsc-calendar-month-name {
      font-size: .8125em;
    }

    &.mbsc-calendar-month-name.mbsc-ltr {
      padding-left: .461539em;
      // margin-right: -1em; 
    }

    &.mbsc-calendar-month-name.mbsc-rtl {
      padding-right: .461539em;
      // margin-left: -1em; 
    }

    &.mbsc-calendar-day-text {
      height: 1.846154em;
      width: 1.846154em;
      line-height: 1.846154em;
      margin: .230769em auto;
      padding: 0;
      font-size: .8125em;
      border: 2px solid transparent;
    }

    /* Range Highlight */

    &.mbsc-range-day::after,
    &.mbsc-range-hover::before {
      content: '';
      height: 1.75em;
      position: absolute;
      left: 0;
      right: 0;
      top: .25em;
      z-index: -1;
    }

    &.mbsc-range-hover::before {
      box-sizing: content-box;
      height: 1.5em;
      border-top: 2px dashed;
      border-bottom: 2px dashed;
    }

    &.mbsc-range-day-start.mbsc-ltr::after,
    &.mbsc-range-day-end.mbsc-rtl::after {
      margin-left: -.875em;
      border-radius: 2em 0 0 2em;
      left: 50%;
      right: 0;
    }

    &.mbsc-range-day-end.mbsc-ltr::after,
    &.mbsc-range-day-start.mbsc-rtl::after {
      margin-right: -.875em;
      border-radius: 0 2em 2em 0;
      right: 50%;
      left: 0;
    }

    &.mbsc-range-hover-start.mbsc-ltr::before,
    &.mbsc-range-hover-end.mbsc-rtl::before {
      left: 50%;
      right: 0;
    }

    &.mbsc-range-hover-end.mbsc-ltr::before,
    &.mbsc-range-hover-start.mbsc-rtl::before {
      right: 50%;
      left: 0;
    }

    &.mbsc-range-day-start.mbsc-range-day-end::after,
    &.mbsc-range-hover-start.mbsc-range-hover-end::before {
      display: none;
    }

    /* Marks */

    &.mbsc-calendar-marks {
      margin-top: -.375em;
      transition: transform .1s ease-out;
    }

    &.mbsc-focus .mbsc-calendar-marks,
    &.mbsc-hover .mbsc-calendar-marks,
    &.mbsc-selected .mbsc-calendar-marks,
    &.mbsc-calendar-day-colors .mbsc-calendar-marks,
    &.mbsc-highlighted .mbsc-calendar-marks,
    &.mbsc-hover-highlighted .mbsc-calendar-marks {
      transform: translate3d(0, .25em, 0);
    }

    /* Labels */
    &.mbsc-calendar-label {
      font-weight: 600;
      border-radius: .4em;
    }

    &.mbsc-calendar-label-background {
      margin: 0 -.1em;
      background: currentColor;
    }

    &.mbsc-calendar-text:before {
      border-radius: .4em;
    }

    &.mbsc-calendar-label:before {
      margin: 0 -.1em;
      border-radius: 0;
    }

    &.mbsc-calendar-label-start.mbsc-ltr .mbsc-calendar-label-background,
    &.mbsc-calendar-label-end.mbsc-rtl .mbsc-calendar-label-background,
    &.mbsc-calendar-label-start.mbsc-ltr.mbsc-calendar-text:before,
    &.mbsc-calendar-label-end.mbsc-rtl.mbsc-calendar-text:before {
      margin-left: 0;
      border-top-left-radius: .4em;
      border-bottom-left-radius: .4em;
    }

    &.mbsc-calendar-label-end.mbsc-ltr .mbsc-calendar-label-background,
    &.mbsc-calendar-label-start.mbsc-rtl .mbsc-calendar-label-background,
    &.mbsc-calendar-label-end.mbsc-ltr.mbsc-calendar-text:before,
    &.mbsc-calendar-label-start.mbsc-rtl.mbsc-calendar-text:before {
      margin-right: 0;
      border-top-right-radius: .4em;
      border-bottom-right-radius: .4em;
    }

    /* Desktop style */
    &.mbsc-calendar-height-md {
      .mbsc-calendar-slide {
        padding: 0;
      }

      .mbsc-calendar-day {
        border-left: 1px solid;
        border-bottom: 1px solid;
      }

      .mbsc-calendar-week-nr {
        font-size: 0.6875em;
        line-height: 3.636363em;
      }
    }

    &.mbsc-calendar-width-md {

      .mbsc-calendar-week-day {
        padding: 0 1em;
      }

      .mbsc-calendar-week-nr {
        padding: 0;
      }

      .mbsc-calendar-day-inner {
        min-height: 4em;
      }

      .mbsc-calendar-day-labels .mbsc-calendar-day-inner {
        min-height: 5.5em;
      }

      .mbsc-calendar-day-text {
        margin: .461539em;
      }

      .mbsc-calendar-marks {
        padding: 0 1.125em;
        margin-left: -1px;
        margin-right: -1px;
      }

      .mbsc-calendar-text {
        height: 1.8em;
        line-height: 1.8em;
        margin: 0 .4em .2em .4em;
      }

      .mbsc-calendar-label {
        padding: 0 .4em;
      }

      .mbsc-calendar-label-text {
        font-size: 1.2em;
      }

      .mbsc-calendar-label:before,
      .mbsc-calendar-label-background {
        margin: 0 -.4em;
      }
    }

    &.mbsc-calendar-height-md.mbsc-calendar-width-md {

      .mbsc-calendar-week-day.mbsc-ltr,
      .mbsc-calendar-day.mbsc-ltr,
      .mbsc-calendar-marks.mbsc-ltr {
        text-align: left;
      }

      .mbsc-calendar-week-day.mbsc-rtl,
      .mbsc-calendar-day.mbsc-rtl,
      .mbsc-calendar-marks.mbsc-rtl {
        text-align: right;
      }
    }
  }

  @include mbsc-material-calendar-view('material', $mbsc-material-colors);
  @include mbsc-material-calendar-view('material-dark', $mbsc-material-dark-colors);
}



@if ($mbsc-windows-theme) {
  .mbsc-windows {
    &.mbsc-calendar-header {
      margin-bottom: -1px;
    }

    &.mbsc-calendar-button.mbsc-button.mbsc-font {
      margin: 1px;
    }

    &.mbsc-calendar-title-wrapper .mbsc-calendar-button {
      padding: 0;
    }

    &.mbsc-calendar-controls {
      padding: .5em;
      min-height: 2.125em;
    }

    &.mbsc-calendar-week-day {
      height: 2.5em;
      font-size: 0.75em;
      line-height: 2.5em;
    }

    &.mbsc-calendar-body .mbsc-calendar-week-day {
      border: 0;
    }

    &.mbsc-calendar-week-nr {
      width: 2.166667em;
      font-size: .75em;
      font-weight: bold;
      line-height: 3em;
    }

    &.mbsc-calendar-picker-slide {
      padding: .25em;
    }

    &.mbsc-calendar-cell {
      border-width: 0;
      border-style: solid;
      border-top-width: 1px;
      border-left-width: 1px;
      border-color: transparent;
      background-clip: padding-box;
      padding: 0;
    }

    &.mbsc-calendar-cell:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
    }

    &.mbsc-calendar-cell-inner {
      display: table;
      table-layout: fixed;
      position: relative;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border: 1px solid transparent;
      border-top-width: 2px;
      border-bottom-width: 2px;
    }

    &.mbsc-calendar-cell-text {
      display: table-cell;
      vertical-align: middle;
      min-width: 0;
    }

    &.mbsc-calendar-month-name {
      font-size: .875em;
      vertical-align: middle;

      &.mbsc-ltr {
        margin-left: .5em;
      }

      &.mbsc-rtl {
        margin-right: .5em;
      }
    }

    &.mbsc-calendar-day-empty:after {
      display: none;
    }

    &.mbsc-calendar-day-text {
      display: inline-block;
      font-size: .875em;
      line-height: 2.285715em;
      border-radius: 2em;
      // width: 2.285715em;
      margin: 0 .5em;
    }

    /* Marks */
    &.mbsc-calendar-marks {
      bottom: .25em;
      // margin-top: -0.5em;
    }

    &.mbsc-calendar-text {
      margin: 0 0 .2em 0;
    }

    &.mbsc-calendar-label-background {
      background: currentColor;
    }

    &.mbsc-calendar-width-md {

      .mbsc-calendar-title {
        font-size: 1.625em;
        font-weight: 300;
        line-height: 1.461538em;
        padding: 0 0.307693em;
      }

      .mbsc-calendar-week-day {
        height: 2.142858em;
        padding: 0 0.5em;
        font-size: 0.875em;
        line-height: 2.142858em;
      }

      .mbsc-calendar-week-nr {
        padding: 0;
        font-size: .75em;
      }

      .mbsc-calendar-day-inner {
        min-height: 4em;
      }

      .mbsc-calendar-day-text {
        text-align: center;
      }

      .mbsc-calendar-day-labels .mbsc-calendar-day-inner {
        min-height: 4.625em;
      }

      .mbsc-calendar-marks {
        // margin: 0;
        bottom: .5em;
        padding: 0 0.625em;
      }

      .mbsc-calendar-text {
        height: 1.8em;
        line-height: 1.8em;
      }

      .mbsc-calendar-label {
        padding: 0 .6em;
        margin: 0;
        margin-bottom: .2em;
      }

      .mbsc-calendar-label-text {
        font-size: 1.2em;
      }

      .mbsc-calendar-week-day.mbsc-ltr,
      .mbsc-calendar-day.mbsc-ltr,
      .mbsc-calendar-marks.mbsc-ltr {
        text-align: left;
      }

      .mbsc-calendar-week-day.mbsc-rtl,
      .mbsc-calendar-day.mbsc-rtl,
      .mbsc-calendar-marks.mbsc-rtl {
        text-align: right;
      }

      .mbsc-calendar-day.mbsc-calendar-day-labels {
        text-align: center;
      }
    }

    &.mbsc-calendar-grid-view .mbsc-calendar-title {
      font-size: 1.625em;
      font-weight: 300;
      line-height: 1.461538em;
      padding: 0 0.307693em;
    }
  }

  @include mbsc-windows-calendar-view('windows', $mbsc-windows-colors);
  @include mbsc-windows-calendar-view('windows-dark', $mbsc-windows-dark-colors);
}


@keyframes mbsc-zoom-in-up {
  from {
    opacity: 0;
    transform: scale(2);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes mbsc-zoom-in-down {
  from {
    opacity: 0;
    transform: scale(.5);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes mbsc-zoom-out-up {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(2);
  }
}

@keyframes mbsc-zoom-out-down {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(.5);
  }
}

.mbsc-calendar {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex-direction: column;
  touch-action: manipulation;
  position: relative;
}

.mbsc-calendar-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* Header */

.mbsc-calendar-header {
  position: relative;
  z-index: 1;
  will-change: opacity;

  .mbsc-calendar-week-days {
    margin-left: -1px;
  }
}

.mbsc-calendar,
.mbsc-calendar-title-wrapper,
.mbsc-calendar-controls {
  display: flex;
}

.mbsc-calendar-controls {
  align-items: center;
  box-sizing: content-box;
  min-height: 2.5em;
}

.mbsc-calendar-button.mbsc-button.mbsc-reset {
  margin: 0;
}

.mbsc-calendar-button-prev-multi {
  order: -1;
}

.mbsc-calendar-button-next-multi {
  order: 1;
}

.mbsc-calendar-body {
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.mbsc-calendar-body-inner {
  height: 100%;
  position: relative;
  overflow: hidden;
  margin-left: -1px;
}

.mbsc-calendar-wrapper-fixed {
  display: block;
  height: auto;
  overflow: visible;
  flex: 0 0 auto;

  .mbsc-calendar-header {
    display: block;
  }

  .mbsc-calendar-body {
    display: block;
    height: auto;
  }
}

.mbsc-calendar-hidden {
  visibility: hidden;
}

.mbsc-calendar-title-wrapper {
  flex: 1;
  align-items: center;
  overflow: hidden;
}

.mbsc-calendar-title-wrapper-multi .mbsc-calendar-button {
  flex: 1;
}

.mbsc-calendar-title {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.125em;
  // line-height: 2.222223em;
  padding: 0 0.25em;
  display: inline-block;
  vertical-align: middle;
}

/* Scrollview */

.mbsc-calendar-scroll-wrapper {
  display: block;
  overflow: hidden;
  position: relative;
  height: 100%;
}

.mbsc-calendar-picker-wrapper {
  position: relative;
  width: 15em;
  height: 15em;
  overflow: hidden;
}

.mbsc-calendar-picker {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.mbsc-calendar-picker-main {
  position: relative;
  z-index: 0;
}

.mbsc-calendar-picker-in-up {
  opacity: 0;
  animation: mbsc-zoom-in-up 200ms cubic-bezier(0.0, 0.0, 0.2, 1);
}

.mbsc-calendar-picker-in-down {
  opacity: 0;
  animation: mbsc-zoom-in-down 200ms cubic-bezier(0.0, 0.0, 0.2, 1);
}

.mbsc-calendar-picker-out-up {
  opacity: 0;
  animation: mbsc-zoom-out-up 200ms cubic-bezier(0.4, 0.0, 1, 1);
}

.mbsc-calendar-picker-out-down {
  opacity: 0;
  animation: mbsc-zoom-out-down 200ms cubic-bezier(0.0, 0.0, 0.2, 1);
}

.mbsc-calendar-scroll-wrapper > div {
  height: 100%;
}

.mbsc-calendar-scroll-wrapper > div > div {
  height: 100%;
  transform: translate3d(0, 0, 0);
}

.mbsc-calendar-slide {
  position: absolute;
  z-index: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.mbsc-calendar-slide.mbsc-ltr {
  left: 0;
}

.mbsc-calendar-slide.mbsc-rtl {
  right: 0;
}

.mbsc-calendar-slide:first-child {
  position: relative;
}

.mbsc-calendar-table {
  height: 100%;
  // Needed for iOS Safari to prevent animation flicker
  transform: translateZ(0);
}

.mbsc-calendar-cell {
  position: relative;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
}

.mbsc-calendar-cell:focus {
  outline: 0;
}

.mbsc-calendar-cell-inner {
  display: inline-block;
  padding: 0 .25em;
  vertical-align: middle;
}

.mbsc-calendar-cell.mbsc-disabled {
  cursor: not-allowed;
}

.mbsc-calendar-week-day {
  height: 2em;
  font-size: 0.6875em;
  line-height: 2em;
  text-align: center;
  border-bottom: 1px solid transparent;
}

.mbsc-calendar-year,
.mbsc-calendar-month {
  vertical-align: middle;
}

.mbsc-calendar-year-text,
.mbsc-calendar-month-text {
  margin: .875em 0;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 4.5em;
  box-sizing: border-box;
}

.mbsc-calendar-day {
  /* if only width is used, layout is broken with week numbers displayed */
  max-width: 14.285715%;
  text-align: center;
  vertical-align: top;
}

.mbsc-calendar-day-empty {
  cursor: default;
}

.mbsc-calendar-day-text {
  display: inline-block;
  text-align: center;
  box-sizing: content-box;
}

.mbsc-calendar-day-inner {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  border: 1px solid transparent;
  box-sizing: border-box;
}

.mbsc-calendar-day-hidden {
  visibility: hidden;
}

.mbsc-calendar-month-name {
  display: none;
}

.mbsc-calendar-width-md {

  .mbsc-calendar-month-name {
    display: inline-block;
    font-weight: bold;
  }

}

.mbsc-calendar-day-outer .mbsc-calendar-day-text,
.mbsc-calendar-day-outer .mbsc-calendar-month-name {
  opacity: .5;
}

.mbsc-calendar-day-outer.mbsc-selected .mbsc-calendar-day-text,
.mbsc-calendar-day-outer.mbsc-selected .mbsc-calendar-month-name {
  opacity: 1;
}

.mbsc-disabled .mbsc-calendar-cell-text,
.mbsc-disabled .mbsc-calendar-month-name {
  opacity: .2;
}

/* Day highlight */

.mbsc-calendar-day-highlight:before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .1);
}

/* Marks */

.mbsc-calendar-day-marked {
  padding-bottom: 0.25em;
}

.mbsc-calendar-marks {
  position: absolute;
  left: 0;
  right: 0;
  height: 0.375em;
  margin-top: -0.125em;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
}

.mbsc-calendar-mark {
  display: inline-block;
  vertical-align: top;
  width: 0.25em;
  height: 0.25em;
  margin: 0.0625em;
  border-radius: 0.25em;
}

/* Labels */

.mbsc-calendar-labels {
  position: absolute;
  left: 0;
  right: 0;
}

.mbsc-calendar-label-wrapper {
  position: absolute;
  z-index: 2;
  pointer-events: none;
  width: 100% !important;
}

.mbsc-calendar-label {
  padding: 0 .3em;
}

.mbsc-calendar-text:focus {
  outline: 0;
}

.mbsc-calendar-label-text {
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  z-index: 2;
}

.mbsc-calendar-text:before,
.mbsc-calendar-label-background {
  content: '';
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color .2s;
}

.mbsc-calendar-label.mbsc-calendar-label-hover:before {
  background-color: rgba(0, 0, 0, .2);
  z-index: 1;
}

.mbsc-calendar-text-more {
  padding: 0 .3em;
}

.mbsc-calendar-text-more.mbsc-calendar-label-active:before,
.mbsc-calendar-text-more.mbsc-calendar-label-hover:before {
  background-color: rgba(0, 0, 0, .1);
}

.mbsc-calendar-text {
  display: block;
  position: relative;
  height: 1.6em;
  line-height: 1.6em;
  // overflow: hidden;
  font-size: 0.625em;
  white-space: nowrap;
  margin: 0 0.1em 0.2em 0.1em;
}

.mbsc-calendar-text-placeholder {
  z-index: -1;
}

.mbsc-calendar-text.mbsc-ltr {
  text-align: left;
}

.mbsc-calendar-text.mbsc-rtl {
  text-align: right;
}

/* Label drag & drop */

.mbsc-calendar-label-resize {
  position: absolute;
  top: 0;
  height: 100%;
  width: .5em;
  z-index: 2;
  cursor: ew-resize;
}

.mbsc-calendar-label-resize:after {
  content: '';
  display: none;
  position: absolute;
  top: 50%;
  width: .8em;
  height: .8em;
  margin-top: -.4em;
  background: #fff;
  border-radius: .8em;
  border: 1px solid currentColor;
  box-sizing: border-box;
}

.mbsc-calendar-label-resize-start.mbsc-ltr,
.mbsc-calendar-label-resize-end.mbsc-rtl {
  left: 0;
}

.mbsc-calendar-label-resize-start.mbsc-rtl,
.mbsc-calendar-label-resize-end.mbsc-ltr {
  right: 0;
}

.mbsc-calendar-label-resize-start.mbsc-ltr:after,
.mbsc-calendar-label-resize-end.mbsc-rtl:after {
  left: -.4em;
}

.mbsc-calendar-label-resize-end.mbsc-ltr:after,
.mbsc-calendar-label-resize-start.mbsc-rtl:after {
  right: -.4em;
}

.mbsc-calendar-label-resize-start-touch:before,
.mbsc-calendar-label-resize-end-touch:before {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -1em;
  width: 2em;
  height: 2em;
}

.mbsc-calendar-label-resize-start-touch:before {
  left: -1em;
}

.mbsc-calendar-label-resize-end-touch:before {
  right: -1em;
}

.mbsc-calendar-label-hover,
.mbsc-calendar-label-dragging {
  .mbsc-calendar-label-resize:after {
    display: block;
  }
}

.mbsc-calendar-label-inactive {
  opacity: .4;
}

.mbsc-calendar-label-hidden {
  visibility: hidden;
}

.mbsc-calendar-labels-dragging {
  z-index: 3;
}

/* Multi month grid view */

// /* TODO: refactor */
// .mbsc-calendar-grid-view {

//   .mbsc-calendar-wrapper,
//   .mbsc-calendar-body,
//   .mbsc-calendar-body-inner {
//     overflow: hidden;
//     display: flex;
//     flex-direction: column;
//     flex: 1 1 auto;
//   }
// }

.mbsc-calendar-grid {
  overflow: auto;
  padding: 0 2%;

  .mbsc-calendar-table {
    margin: 0 5%;
    width: 90%;
  }
}

.mbsc-calendar-grid-item {
  margin: 1em 0;
  // Space for month title, needed for height 100% to work correctly for .mbsc-calendar-table in Safari
  padding-top: 2.5em;
}

.mbsc-calendar-month-title {
  padding-bottom: .8em;
  margin: 0 10%;
  margin-top: -2em;
  font-size: 1.25em;
  font-weight: bold;
  line-height: 1.2em;
}

/* Variable row */

.mbsc-calendar-body-inner-variable {
  overflow-y: auto;
}



@mixin mbsc-mobiscroll-list($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  .mbsc-#{$theme} {
    &.mbsc-list-header {
      background: $background;
      color: $accent;
      border-color: rgba($text, .125);
    }

    &.mbsc-list-item {
      background: $background;
      color: $text;
      border-color: rgba($text, .125);
    }
  }
}


@if ($mbsc-mobiscroll-theme) {
  .mbsc-mobiscroll {
    &.mbsc-list-header {
      padding: .5em 1em;
      font-size: .75em;
      text-transform: uppercase;
      border-bottom: 1px solid;
    }

    &.mbsc-list-item {
      border-bottom: 1px solid;
    }
  }

  @include mbsc-mobiscroll-list('mobiscroll', $mbsc-mobiscroll-colors);
  @include mbsc-mobiscroll-list('mobiscroll-dark', $mbsc-mobiscroll-dark-colors);
}



@if ($mbsc-ios-theme) {
  .mbsc-ios {
    &.mbsc-list-item {
      margin-top: -1px;
      padding: 1em;
    }

    &.mbsc-list-item:before,
    &.mbsc-list-item:after {
      content: '';
      position: absolute;
      right: 0;
      left: 1em;
    }

    &.mbsc-list-item.mbsc-rtl:before,
    &.mbsc-list-item.mbsc-rtl:after {
      left: 0;
      right: 1em;
    }

    &.mbsc-list-item:before {
      top: 0;
      transition: none;
    }

    &.mbsc-list-item:after {
      bottom: 0;
    }

    &.mbsc-list-item:first-child:before,
    &.mbsc-list-item:last-child:after {
      left: 0;
      right: 0;
    }

    &.mbsc-list-item.mbsc-hover,
    &.mbsc-list-item.mbsc-active {
      z-index: 3;
    }

    &.mbsc-list-item.mbsc-focus {
      z-index: 5;
    }

    &.mbsc-list-item.mbsc-focus:before,
    &.mbsc-list-item.mbsc-focus:after,
    &.mbsc-list-item.mbsc-hover:before,
    &.mbsc-list-item.mbsc-hover:after,
    &.mbsc-list-item.mbsc-active:before,
    &.mbsc-list-item.mbsc-active:after {
      left: 0;
      right: 0;
    }

    &.mbsc-list-header {
      z-index: 4;
      margin-top: -1px;
      padding: 2.333334em 1.333334em .5em 1.333334em;
      font-size: .75em;
      line-height: 1.5em;
      border-top: 1px solid;
      border-bottom: 1px solid;
      text-transform: uppercase;
    }

    &.mbsc-list-header:first-child {
      border-top: 0;
      margin-top: 0;
    }
  }

  @include mbsc-ios-list('ios', $mbsc-ios-colors);
  @include mbsc-ios-list('ios-dark', $mbsc-ios-dark-colors);
}



@if ($mbsc-material-theme) {
  .mbsc-material {
    &.mbsc-list-header {
      margin-top: -1px;
      font-size: .875em;
      font-weight: 600;
      padding: .5714em 1.14285em;
    }
  }

  @include mbsc-material-list('material', $mbsc-material-colors);
  @include mbsc-material-list('material-dark', $mbsc-material-dark-colors);
}



@if ($mbsc-windows-theme) {
  .mbsc-windows {
    &.mbsc-list-header {
      // font-size: .9375em;
      font-weight: 500;
      line-height: 1.25em;
    }

    &.mbsc-list-item {
      padding: .5em;
      line-height: 1.25em;
    }
  }

  @include mbsc-windows-list('windows', $mbsc-windows-colors);
  @include mbsc-windows-list('windows-dark', $mbsc-windows-dark-colors);
}


.mbsc-list {
  display: block;
}

.mbsc-list-header {
  display: flex;
}

.mbsc-list-item {
  position: relative;
  z-index: 2;
  list-style: none;
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
  line-height: 1.25em;
}

.mbsc-list-item-actionable {
  cursor: pointer;
}

.mbsc-list-item:before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  transition: background-color .2s ease-out;
}

.mbsc-list-item:focus {
  outline: 0;
}

.mbsc-list-item::-moz-focus-inner {
  border: 0;
}

.mbsc-schedule-grid-wrapper {
  overflow: hidden;
  position: relative;
}

.mbsc-timeline-resource-col,
.mbsc-schedule-time-col {
  flex: none;
  width: 4.25em;
  box-sizing: border-box;
}

/* Schedule grid */

.mbsc-timeline-grid-scroll,
.mbsc-schedule-grid-scroll {
  overflow-x: auto;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.mbsc-schedule-grid {
  flex: 1 0 auto;
}

/* Borders */

.mbsc-schedule-all-day-item::after,
.mbsc-timeline-day::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.mbsc-schedule-all-day-item.mbsc-ltr::after,
.mbsc-timeline-day.mbsc-ltr::after {
  left: 100%;
  border-right-style: solid;
  border-right-width: 1px;
}

.mbsc-schedule-all-day-item.mbsc-rtl::after,
.mbsc-timeline-day.mbsc-rtl::after {
  right: 100%;
  border-left-style: solid;
  border-left-width: 1px;
}

.mbsc-timeline-slot-header.mbsc-rtl,
// .mbsc-timeline-day.mbsc-rtl,
.mbsc-timeline-header-date.mbsc-rtl,
.mbsc-timeline-resource.mbsc-rtl,
.mbsc-timeline-resource-empty.mbsc-rtl,
.mbsc-timeline-header-column.mbsc-rtl,
.mbsc-timeline-column.mbsc-rtl,
// .mbsc-schedule-all-day-item.mbsc-ltr,
.mbsc-schedule-column.mbsc-ltr {
  border-left-style: solid;
  border-left-width: 1px;
}

.mbsc-timeline-slot-header.mbsc-ltr,
// .mbsc-timeline-day.mbsc-ltr,
.mbsc-timeline-header-date.mbsc-ltr,
.mbsc-timeline-resource.mbsc-ltr,
.mbsc-timeline-resource-empty.mbsc-ltr,
.mbsc-timeline-header-column.mbsc-ltr,
.mbsc-timeline-column.mbsc-ltr,
// .mbsc-schedule-all-day-item.mbsc-rtl,
.mbsc-schedule-column.mbsc-rtl {
  border-right-style: solid;
  border-right-width: 1px;
}

/* Schedule event */

.mbsc-schedule-event:focus {
  outline: none;
}

.mbsc-schedule-event {
  position: absolute;
  padding: 1px 0;
  box-sizing: border-box;
  min-height: .75em;
  z-index: 1;
}

.mbsc-schedule-event.mbsc-ltr {
  left: 0;
}

.mbsc-schedule-event.mbsc-rtl {
  right: 0;
}

.mbsc-schedule-event-hover {
  cursor: pointer;
}

.mbsc-schedule-event-inner {
  position: relative;
  max-height: 100%;
  margin: 0 .2em;
  line-height: 1.5em;
  font-size: .625em;
  padding: .5em;
  box-sizing: border-box;
  overflow: hidden;
}

.mbsc-schedule-event-small-height.mbsc-schedule-event-inner {
  padding-top: 0;
  padding-bottom: 0;
  line-height: 1.2em;
  margin-top: -1px;
}

.mbsc-schedule-event-small-height.mbsc-schedule-event-inner .mbsc-schedule-event-title {
  font-size: 1em;
}

.mbsc-schedule-event-background {
  position: absolute;
  top: -.5em;
  left: 1px;
  right: 1px;
  bottom: -.5em;
  background: currentColor;
}

.mbsc-schedule-event-start {
  .mbsc-schedule-event-background {
    top: 1px;
  }
}

.mbsc-schedule-event-end {
  .mbsc-schedule-event-background {
    bottom: 1px;
  }
}

.mbsc-schedule-event-range {
  font-size: .8em;
}

.mbsc-schedule-event-all-day {
  position: relative;
  display: block;
  padding: 0;
  margin: .125em 0;
  min-height: 0;
  box-sizing: content-box;
  z-index: 2;
}

.mbsc-schedule-event-all-day-placeholder {
  z-index: -1;
}

.mbsc-schedule-event-all-day-inner {
  position: relative;
  height: 1.6em;
  line-height: 1.6em;
  margin: 0 .2em;
  padding: 0 .3em;
  font-size: .625em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.mbsc-schedule-event-all-day-background.mbsc-schedule-event-background {
  top: 0;
  bottom: 0;
}

/* Timeline event */

.mbsc-timeline-event {
  z-index: 2;
  min-width: .75em;

  .mbsc-schedule-event-title,
  .mbsc-schedule-event-range {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

/* Event Drag & drop */

.mbsc-schedule-event-dragging {
  position: absolute;
  top: 0;
  z-index: 3;
}

.mbsc-schedule-event-inactive {
  opacity: .5;
}

.mbsc-schedule-event-hidden {
  visibility: hidden;
}

/* Event resize handles */

.mbsc-schedule-event-resize {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  height: .25em;
  z-index: 1;
  cursor: ns-resize;
}

.mbsc-schedule-event-resize-start {
  top: 0;
}

.mbsc-schedule-event-resize-end {
  bottom: 0;
}

.mbsc-schedule-event-resize-start:after {
  top: -.25em;
  right: .5em;
}

.mbsc-schedule-event-resize-end:after {
  bottom: -.25em;
  left: .5em;
}

.mbsc-schedule-event-resize:after {
  content: '';
  position: absolute;
  margin: 1px 0;
  width: .5em;
  height: .5em;
  background: #fff;
  border-radius: 50%;
  border: 1px solid currentColor;
  box-sizing: border-box;
}

.mbsc-schedule-event-resize-start-touch:before,
.mbsc-schedule-event-resize-end-touch:before {
  content: '';
  position: absolute;
  margin: 1px 0;
  width: 1.5em;
  height: 1em;
}

.mbsc-schedule-event-resize-start-touch:before {
  top: -.5em;
  right: 0;
}

.mbsc-schedule-event-resize-end-touch:before {
  bottom: -.5em;
  left: 0;
}

// .mbsc-schedule-event-active,
.mbsc-schedule-event-dragging,
.mbsc-schedule-event-hover {
  .mbsc-schedule-event-resize {
    display: block;
  }
}


/* Blocking and colored date styles */

.mbsc-schedule-invalid,
.mbsc-schedule-color {
  position: absolute;
  top: -.5em;
  bottom: -.5em;
  left: 0;
  right: 0;
  box-sizing: content-box;
  z-index: 0;
  overflow: hidden;
}

.mbsc-schedule-invalid-all-day,
.mbsc-schedule-color-all-day {
  top: 0;
  bottom: 0;
  z-index: -1;
}

.mbsc-schedule-invalid-start,
.mbsc-schedule-color-start {
  padding-top: .5em;
  margin-top: -.5em;
}

.mbsc-schedule-invalid-end,
.mbsc-schedule-color-end {
  padding-bottom: .5em;
}

.mbsc-schedule-invalid-text,
.mbsc-schedule-color-text {
  padding: .375em;
  font-size: .75em;
}

.mbsc-schedule-color-text {
  opacity: .8;
}


/* Time indicator */

.mbsc-schedule-time-indicator {
  position: absolute;
  z-index: 2;
  pointer-events: none;
}

.mbsc-schedule-time-indicator-x {
  border-bottom: 1px solid;
}

.mbsc-schedule-time-indicator-x.mbsc-ltr {
  left: 4.25em;
  right: 0;
}

.mbsc-schedule-time-indicator-x.mbsc-rtl {
  right: 4.25em;
  left: 0;
}

.mbsc-schedule-time-indicator-y {
  top: 0;
  bottom: 0;
  border-left: 1px solid;
}

.mbsc-schedule-time-indicator-day {
  position: absolute;
  width: 14.285714%;
}

.mbsc-schedule-time-indicator-day:before {
  content: '';
  position: absolute;
  width: .4375em;
  height: .4375em;
  margin-top: -.1875em;
  margin-left: -.1875em;
  margin-right: -.1875em;
  border-radius: 50%;
  z-index: 1;
}

.mbsc-schedule-time-indicator-time {
  position: absolute;
  top: -1em;
  font-size: .625em;
  line-height: 2em;
  margin-top: 1px;
  padding: 0 .8em;
  white-space: nowrap;
}

.mbsc-schedule-time-indicator-time-y {
  top: -1.8em;
  padding: 0 .5em;
  margin-left: -.5em;
  margin-right: -.5em;
  line-height: 1.6em;
}

.mbsc-schedule-time-indicator-time-x.mbsc-ltr,
.mbsc-schedule-time-indicator-time-y.mbsc-rtl {
  right: 100%;
}

.mbsc-schedule-time-indicator-time-x.mbsc-rtl,
.mbsc-schedule-time-indicator-time-y.mbsc-ltr {
  left: 100%;
}

/* Cursor indicator */

.mbsc-cursor-indicator {
  position: absolute;

  z-index: 3;
}

.mbsc-cursor-indicator-x {
  top: 1.875em;
}


.mbsc-cursor-indicator-y.mbsc-ltr {
  left: 4.25em;
}

.mbsc-cursor-indicator-y.mbsc-rtl {
  right: 4.25em;
}

.mbsc-cursor-indicator-time {
  line-height: 1.6em;
  font-size: .625em;
  line-height: 2em;
  padding: 0 .8em;
  white-space: nowrap;
}

.mbsc-cursor-indicator-time-y {
  position: absolute;
  margin-top: -.5em;
}

.mbsc-cursor-indicator-time-y.mbsc-ltr {
  right: 100%;
}

.mbsc-cursor-indicator-time-y.mbsc-rtl {
  left: 100%;
}

@supports (overflow:clip) {

  .mbsc-schedule-event-inner {
    position: sticky;
    top: 0;
  }

}

.mbsc-schedule-wrapper {
  overflow: auto;
}

/* Resource groups */

.mbsc-schedule-resource-group {
  // min-width: 16em;
  box-sizing: border-box;
  flex-basis: 16em;
}

.mbsc-schedule-resource-group:first-child:last-child {
  // min-width: 0;
  flex-basis: 0;
}

.mbsc-schedule-resource-group.mbsc-ltr {
  border-left-width: 2px;
  border-left-style: solid;
}

.mbsc-schedule-resource-group.mbsc-rtl {
  border-right-width: 2px;
  border-right-style: solid;
}

.mbsc-schedule-resource-group:first-child {
  border: 0;
}

/* Schedule header */

.mbsc-schedule-date-header-text {
  line-height: 1.5em;
  text-align: center;
}

.mbsc-schedule-header {
  overflow: hidden;
}

.mbsc-schedule-all-day-group-wrapper,
.mbsc-schedule-header-wrapper {
  min-width: 0;
  overflow: hidden;
}

.mbsc-schedule-header-item {
  display: block; // for angular component which displays in inline 
  position: relative;
  text-align: center;
  line-height: 1.25em;
}

.mbsc-schedule-header-item.mbsc-hover {
  cursor: pointer;
}

.mbsc-schedule-header-dayname {
  font-size: .75em;
}

.mbsc-schedule-header-day {
  font-size: 1.125em;
}

/* Schedule column width for: header item, header resource, all-day item, schedule column  */

.mbsc-schedule-col-width {
  box-sizing: border-box;
  // min-width: 0; // needed for the items equal width
  width: 2.5em;
}

.mbsc-schedule-wrapper-multi .mbsc-schedule-col-width {
  width: 6.25em;
}

/* Resource title */

.mbsc-schedule-resource {
  overflow: hidden;
}

.mbsc-schedule-resource-title {
  padding: .5em;
  font-size: .875em;
  font-weight: 600;
  text-align: center;
}

/* Schedule all day event container */

.mbsc-schedule-all-day-wrapper {
  position: relative;
  max-height: 7.8125em;
  overflow-x: hidden;
  overflow-y: scroll;
}

.mbsc-schedule-all-day-wrapper-shadow {
  position: relative;
}

.mbsc-schedule-all-day-wrapper-shadow:after {
  content: '';
  box-shadow: inset 0 0.5em 0.25em -0.5em rgba(48, 48, 48, 0.5);
  height: 4px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -4px;
  z-index: 4;
  pointer-events: none;
}

.mbsc-schedule-all-day-item {
  position: relative;
}

.mbsc-schedule-all-day-item:first-child,
.mbsc-schedule-column:first-child {
  border: 0;
}

/* Time section */

.mbsc-schedule-time-wrapper {
  position: relative;
  width: 4.25em;
  height: 3.125em;
  box-sizing: border-box;
}

.mbsc-schedule-time {
  position: relative;
  line-height: 2em;
  top: -1em;
  font-size: .75em;
}

.mbsc-schedule-time-end {
  position: absolute;
  top: auto;
  bottom: -1em;
  left: 0;
  right: 0;
}

.mbsc-schedule-time.mbsc-ltr {
  text-align: right;
}

.mbsc-schedule-time.mbsc-rtl {
  text-align: left;
}

.mbsc-schedule-time-cont-pos {
  position: relative;
}


/* Grid */

.mbsc-schedule-time-cont-inner {
  position: absolute;
  width: 100%;
  z-index: 2;
  pointer-events: none;
  height: 100%;
  box-sizing: border-box;
  padding: .5em 0;
}

.mbsc-schedule-column {
  padding: .5em 0;
}

.mbsc-schedule-column-inner {
  position: relative;
}

.mbsc-schedule-all-day-item,
.mbsc-schedule-item {
  box-sizing: border-box;
}

.mbsc-schedule-item {
  height: 3.125em;
  position: relative;
  border-top: 1px solid;
}

.mbsc-schedule-item-last {
  border-bottom: 1px solid;
}

/* Event spacing */

.mbsc-schedule-events {
  position: absolute;
  height: 100%;
}

.mbsc-schedule-events.mbsc-ltr {
  left: 0;
  right: .5em;
}

.mbsc-schedule-events.mbsc-rtl {
  right: 0;
  left: .5em;
}

/* All day events */

.mbsc-schedule-event-all-day {
  .mbsc-schedule-event-resize {
    width: .25em;
    height: 100%;
    cursor: ew-resize;
  }

  .mbsc-schedule-event-resize:after {
    top: 50%;
    right: auto;
    left: auto;
    margin-top: -.25em;
  }

  .mbsc-schedule-event-resize-start.mbsc-ltr,
  .mbsc-schedule-event-resize-end.mbsc-rtl {
    right: auto;
  }

  .mbsc-schedule-event-resize-end.mbsc-ltr,
  .mbsc-schedule-event-resize-start.mbsc-rtl {
    left: auto;
  }

  .mbsc-schedule-event-resize-start.mbsc-ltr:after,
  .mbsc-schedule-event-resize-end.mbsc-rtl:after {
    right: -.25em;
  }

  .mbsc-schedule-event-resize-end.mbsc-ltr:after,
  .mbsc-schedule-event-resize-start.mbsc-rtl:after {
    left: -.25em;
  }

  /* Increase touch area for resize dots */

  .mbsc-schedule-event-resize-start-touch:before {
    height: 1.5em;
    top: 50%;
    left: -.75em;
    right: auto;
    margin-top: -.75em;
  }

  .mbsc-schedule-event-resize-end-touch:before {
    height: 1.5em;
    top: 50%;
    left: auto;
    right: -.75em;
    margin-top: -.75em;
  }
}

/* Large screen */

.mbsc-calendar-width-md {

  .mbsc-schedule-event-title {
    font-size: 1.2em;
  }

  .mbsc-schedule-event-range {
    font-size: 1em;
  }

  .mbsc-schedule-event-all-day-title {
    font-size: 1em;
  }

  .mbsc-schedule-event-all-day-inner {
    font-size: .75em;
    height: 1.5em;
    line-height: 1.5em;
    padding: 0 .333334em;
    margin: 0 .333334em;
  }
}

.mbsc-timeline {
  overflow: hidden;
  position: relative;
  // padding-top: 3.5em;

  &.mbsc-ltr {
    margin-right: -2px;
  }

  &.mbsc-rtl {
    margin-left: -2px;
  }
}

.mbsc-timeline-header-row-1 {
  height: 1.625em;
}

.mbsc-timeline-header-row-2 {
  height: 3.25em;
}

.mbsc-timeline-header-row-3 {
  height: 4.875em;
}

.mbsc-timeline-header-row-4 {
  height: 6.5em;
}

/* Resource column */

.mbsc-timeline-resource-col {
  width: 12em;
}

.mbsc-timeline-resources {
  position: absolute;
  top: 0;
  bottom: 15px;
  z-index: 4;
  overflow: hidden;
  // padding-top: 3.5em;

  &.mbsc-ltr {
    left: 0;
  }

  &.mbsc-rtl {
    right: 0;
  }
}

.mbsc-timeline-resource-empty {
  position: relative;
  z-index: 5;
  // height: 3.5em;
  overflow: hidden;
  pointer-events: auto;
  align-self: flex-start;
}

.mbsc-timeline-resource {
  box-sizing: border-box;
  overflow: hidden;
}

/* Hierarchy */

.mbsc-timeline-parent {
  background: rgba(#e6e6e6, .25);
}

.mbsc-timeline-resource-icon {
  font-size: .875em;
  font-weight: 600;
  margin: .357143em 0;
  cursor: pointer;
  box-sizing: content-box;
}

.mbsc-timeline-resource-icon.mbsc-ltr {
  margin-left: .5em;
}

.mbsc-timeline-resource-icon.mbsc-rtl {
  margin-right: .5em;
}

.mbsc-timeline-resource-header,
.mbsc-timeline-resource-title {
  font-size: .875em;
  font-weight: 600;
  padding: .5em;
}

/* Header */

.mbsc-timeline-header {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  // height: 100%;
  overflow: hidden;
  z-index: 3;
  pointer-events: none;

  .mbsc-schedule-time-indicator {
    // top: 3.5em;
    top: auto;
    bottom: auto;
    height: 100%;
    margin-top: -.3125em;
  }

}

.mbsc-timeline-header-time-cont {
  position: relative;
}

.mbsc-timeline-day {
  position: relative;
  min-width: 9em;
  box-sizing: border-box;
  pointer-events: auto;
}

.mbsc-timeline-day-month {
  min-width: 0;
}

// .mbsc-timeline-day:last-child {

//   // border: 0;
//   // margin: 0;
//   &::after {
//     border: 0;
//   }

//   .mbsc-timeline-header-column:last-child,
//   .mbsc-timeline-header-date {
//     border-left: 0;
//     border-right: 0;
//   }
// }

.mbsc-timeline-header-column.mbsc-timeline-no-border,
.mbsc-timeline-column.mbsc-timeline-no-border {
  border-left: 0;
  border-right: 0;
}

.mbsc-timeline-month {
  min-width: 0;
  position: relative;
}

.mbsc-timeline-header-month,
.mbsc-timeline-header-date,
.mbsc-timeline-header-week {
  // height: 1.875em;
  height: 1.625em;
  box-sizing: border-box;
  pointer-events: auto;
}

.mbsc-timeline-header-month-last.mbsc-ltr,
.mbsc-timeline-header-week-last.mbsc-ltr {
  border-right-width: 1px;
  border-right-style: solid;
}

.mbsc-timeline-header-month-last.mbsc-rtl,
.mbsc-timeline-header-week-last.mbsc-rtl {
  border-left-width: 1px;
  border-left-style: solid;
}

.mbsc-timeline-header-month-text,
.mbsc-timeline-header-date-text,
.mbsc-timeline-header-week-text {
  font-size: .875em; // 14px
  padding: 0 .285714em;
  // line-height: 2.142858em;
  line-height: 1.785715em; // 25px
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.mbsc-timeline-header-week-text,
.mbsc-timeline-header-month-text {
  overflow: visible;
  max-width: none;
  position: absolute;
}

.mbsc-timeline-header-month-text {
  font-weight: bold;
}

.mbsc-timeline-header-month-text-last,
.mbsc-timeline-header-week-text-last {
  max-width: 100%;
  overflow: hidden;
}

.mbsc-timeline-header-column {
  border-bottom: 1px solid;
  height: 1.625em;
}

.mbsc-cursor-indicator-time-x,
.mbsc-timeline-header-time {
  padding: 0 .5em;
  font-size: .625em;
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 2.6em;
}

/* Grid */

.mbsc-timeline-column,
.mbsc-timeline-header-column {
  width: 4em;
  box-sizing: border-box;
  position: relative;
}

.mbsc-timeline-grid-scroll {
  overflow-y: auto;
  // Needed for iOS Safari to don't change the z-index stacking context
  -webkit-overflow-scrolling: auto;
}

.mbsc-timeline-grid {
  position: relative;
  overflow: hidden;
}

.mbsc-timeline-resource,
.mbsc-timeline-row {
  height: 3.25em; // 52px
  position: relative;
  flex: 1 0 auto;
}

.mbsc-timeline-resource-empty,
.mbsc-timeline-resource,
.mbsc-timeline-header-date,
.mbsc-timeline-header-month,
.mbsc-timeline-header-week,
.mbsc-timeline-column {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

/* Events */

.mbsc-timeline-events {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: .5em;
}

.mbsc-timeline-event-background {
  top: 1px;
  bottom: 1px;
}

.mbsc-timeline-event-resize {
  height: 100%;
  width: .25em;
  right: auto;
  left: auto;
  cursor: ew-resize;

  &.mbsc-schedule-event-resize-start.mbsc-ltr,
  &.mbsc-schedule-event-resize-end.mbsc-rtl {
    left: 0;
  }

  &.mbsc-schedule-event-resize-end.mbsc-ltr,
  &.mbsc-schedule-event-resize-start.mbsc-rtl {
    right: 0;
  }

  &.mbsc-schedule-event-resize-end:after,
  &.mbsc-schedule-event-resize-start:after {
    top: 50%;
    margin-top: -.25em;
  }

  &.mbsc-schedule-event-resize-end:after {
    left: -.125em;
  }

  &.mbsc-schedule-event-resize-start:after {
    right: -.125em;
  }

  &.mbsc-schedule-event-resize-start-touch:before,
  &.mbsc-schedule-event-resize-end-touch:before {
    height: 1.5em;
    top: 50%;
    left: 50%;
    margin: -.75em 0 0 -.75em;
  }
}

.mbsc-timeline-event-listing {
  position: relative;

  &.mbsc-timeline-event-dragging {
    position: absolute;
    top: 0;
  }

  &.mbsc-schedule-event-all-day-placeholder {
    padding: 0;
    margin: .125em 0;
  }
}

/* Invalid, color */

.mbsc-timeline-color,
.mbsc-timeline-invalid {
  top: 0;
  bottom: 0;
  padding: 0;
  margin: 0;
}

/* No time displayed */

.mbsc-timeline-header-column.mbsc-timeline-no-height {
  border: 0;
  height: 0;
}

// .mbsc-timeline-no-time {
//   // padding-top: 1.875em;

//   // .mbsc-timeline-resource-empty {
//   //   height: 1.875em;
//   // }

//   // .mbsc-schedule-time-indicator {
//   //   top: 1.875em;
//   // }

//   .mbsc-timeline-header-column {
//     border: 0;
//     height: 0;
//   }

//   // .mbsc-timeline-resources {
//   //   padding-top: 1.875em;
//   // }
// }

// .mbsc-timeline-has-slots {
//   .mbsc-timeline-header-column {
//     border: 0;
//     height: 0;
//   }
// }

/* Sticky positioning */

@supports (position:sticky) {

  .mbsc-timeline-resources.mbsc-ltr {
    position: sticky;
    top: auto;
    bottom: auto;
    overflow: visible;
    padding-top: 0;
  }

}

/* Slots */

.mbsc-timeline-slots {
  height: 1.625em;
  box-sizing: border-box;
  border-bottom: 1px solid;
}

.mbsc-timeline-slot {
  position: relative;
  width: 8em;
  box-sizing: border-box;
  flex: 1 0 auto;
}

// .mbsc-timeline-day:last-child .mbsc-timeline-slot-header:last-child {
//   border: 0;
// }

.mbsc-timeline-slot-title {
  font-size: .875em;
  font-weight: 600;
  line-height: 1.785715em; // 25px
  padding: 0 .285714em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@supports (overflow:clip) {

  .mbsc-timeline-grid {
    overflow: clip;
  }

  .mbsc-timeline .mbsc-schedule-event-inner {
    display: inline-block;
    max-width: 100%;
    vertical-align: top;
  }

  .mbsc-timeline.mbsc-ltr .mbsc-schedule-event-inner {
    left: 192px;
  }

  .mbsc-timeline.mbsc-rtl .mbsc-schedule-event-inner {
    right: 192px;
  }

  .mbsc-timeline-event-listing .mbsc-schedule-event-inner {
    position: relative;
    display: block;
  }

  .mbsc-timeline .mbsc-timeline-event-listing .mbsc-schedule-event-inner,
  .mbsc-timeline.mbsc-timeline-no-resource .mbsc-schedule-event-inner {
    left: 0;
    right: 0;
  }

}



@mixin mbsc-mobiscroll-eventcalendar($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  .mbsc-#{$theme} {
    &.mbsc-eventcalendar {
      background: $background;
      color: $text;
    }
  }
}


@if ($mbsc-mobiscroll-theme) {
  .mbsc-mobiscroll {

    /* Event list */
    &.mbsc-event.mbsc-list-item {
      padding: .5em .625em;
    }

    &.mbsc-event-text,
    &.mbsc-event-time {
      font-size: .875em;
      line-height: 1.285715em;
    }

    &.mbsc-event-time {
      width: 6em;
      font-size: .714286em;
      line-height: 1.6;
      min-height: 3.2em;
      opacity: .7;
    }

    &.mbsc-event-text {
      font-weight: 600;
    }

    &.mbsc-event-sep {
      display: none;
    }
  }

  @include mbsc-mobiscroll-eventcalendar('mobiscroll', $mbsc-mobiscroll-colors);
  @include mbsc-mobiscroll-eventcalendar('mobiscroll-dark', $mbsc-mobiscroll-dark-colors);
}



@if ($mbsc-ios-theme) {
  .mbsc-ios {

    &.mbsc-eventcalendar .mbsc-calendar-header-vertical {
      margin-bottom: -1px;
      border-bottom-width: 1px;
      border-bottom-style: solid;
    }

    &.mbsc-eventcalendar .mbsc-calendar-wrapper {
      border-bottom-width: 1px;
      border-bottom-style: solid;
    }

    &.mbsc-popover-list .mbsc-event:last-child:after {
      display: none;
    }

    &.mbsc-event-day.mbsc-list-header {
      font-weight: bold;
      padding: .5em 1em;
      line-height: 1.5em;
      text-transform: none;
    }

    &.mbsc-event-time {
      width: 6em;
      font-size: .875em; // 14px
      line-height: 1.428572em; // 20px;
    }

    &.mbsc-event-color {
      width: .25em;
      border-radius: .125em;
      margin: 0 .5em 0 -.5em;
    }

    &.mbsc-event-color.mbsc-rtl {
      margin: 0 -.5em 0 .5em;
    }

    &.mbsc-event-time.mbsc-ltr {
      text-align: right;
    }

    &.mbsc-event-time.mbsc-rtl {
      text-align: left;
    }

    &.mbsc-event-end {
      opacity: .7;
    }

    &.mbsc-event-text {
      font-weight: 500;
    }

    &.mbsc-event-sep {
      display: none;
    }

    &.mbsc-calendar-button-today {
      font-weight: 500;
      padding: 0;
    }

    /* Schedule view */

    /* TODO: refactor this without cascade */
    &.mbsc-eventcalendar-schedule .mbsc-calendar-day,
    &.mbsc-eventcalendar-schedule .mbsc-calendar-wrapper {
      border: 0;
    }

    &.mbsc-schedule-wrapper {
      position: relative;
      margin-top: -1px;
      z-index: 2;
    }

    /* Header */

    &.mbsc-schedule-header {
      border-bottom: 1px solid;
    }

    &.mbsc-schedule-header-item {
      padding: .4375em 0;
      line-height: 1.75em;
    }

    &.mbsc-schedule-header-item.mbsc-ltr {
      border-left-style: solid;
      border-left-width: 1px;
    }

    &.mbsc-schedule-header-item.mbsc-rtl {
      border-right-style: solid;
      border-right-width: 1px;
    }

    &.mbsc-schedule-header-item:first-child {
      border: 0;
    }

    &.mbsc-schedule-header-dayname {
      font-size: 1em;
      font-weight: 400;
    }

    &.mbsc-schedule-header-day {
      display: inline-block;
      height: 1.625em;
      width: 1.625em;
      line-height: 1.625em;
      border-radius: 50%;
      text-align: center;
      font-size: 1em;
      font-weight: 400;
    }

    &.mbsc-schedule-header-item-large {
      padding: .1875em 0;

      &.mbsc-schedule-header-item {
        border-color: transparent;
      }

      .mbsc-schedule-header-dayname {
        display: inline-block;
        padding: 0 .375em;
        font-size: 1em;
        font-weight: 400;
      }
    }

    &.mbsc-schedule-all-day-wrapper {
      max-height: 3.125em;
      border-bottom: 1px solid;
      // border-top: 1px solid;
    }

    &.mbsc-schedule-all-day-text {
      padding: .5em .666667em;
      font-size: .75em;
      text-align: right;
      text-transform: lowercase;
    }

    &.mbsc-schedule-date-header {
      position: relative;
      z-index: 3;
      will-change: opacity;
      border-bottom: 1px solid;
    }

    &.mbsc-schedule-date-header-text {
      padding: .5em;
      font-size: .75em;
      font-weight: bold;
    }

    &.mbsc-schedule-resource.mbsc-ltr {
      border-left-width: 1px;
      border-left-style: solid;
    }

    &.mbsc-schedule-resource.mbsc-rtl {
      border-right-width: 1px;
      border-right-style: solid;
    }

    &.mbsc-schedule-resource:first-child {
      border: 0;
    }

    /* Grid & All-day row */

    &.mbsc-schedule-all-day-item {
      padding: .25em 0;
      min-height: 1.875em;
    }

    &.mbsc-schedule-time {
      font-size: .625em;
      padding: 0 .8em;
    }

    /* Schedule event style */

    &.mbsc-schedule-event-background {
      opacity: .5;
      transition: opacity .15s ease-in-out;
    }

    &.mbsc-schedule-event-start {
      .mbsc-schedule-event-background {
        border-top-left-radius: .25em;
        border-top-right-radius: .25em;
      }
    }

    &.mbsc-schedule-event-end {
      .mbsc-schedule-event-background {
        border-bottom-left-radius: .25em;
        border-bottom-right-radius: .25em;
      }
    }

    &.mbsc-timeline-event-start.mbsc-ltr,
    &.mbsc-timeline-event-end.mbsc-rtl {
      .mbsc-schedule-event-background {
        border-bottom-left-radius: .25em;
        border-top-left-radius: .25em;
      }
    }

    &.mbsc-timeline-event-end.mbsc-ltr,
    &.mbsc-timeline-event-start.mbsc-rtl {
      .mbsc-schedule-event-background {
        border-bottom-right-radius: .25em;
        border-top-right-radius: .25em;
      }
    }

    &.mbsc-schedule-event-hover {
      .mbsc-schedule-event-background {
        opacity: .6;
      }
    }

    &.mbsc-schedule-event-active,
    &.mbsc-schedule-event-dragging {
      .mbsc-schedule-event-background {
        opacity: .9;
      }
    }

    &.mbsc-schedule-event-dragging {
      top: .25em;
    }

    &.mbsc-timeline-event-dragging {
      top: 0;
    }

    &.mbsc-schedule-event-bar {
      position: absolute;
      top: -.5em;
      bottom: -.5em;
      width: .1875em;
      background: currentColor;
    }

    &.mbsc-schedule-event-bar.mbsc-ltr {
      left: 1px;
    }

    &.mbsc-schedule-event-bar.mbsc-rtl {
      right: 1px;
    }

    &.mbsc-schedule-event-start {
      .mbsc-schedule-event-bar {
        top: 1px;

        &.mbsc-ltr {
          border-top-left-radius: .1875em;
        }

        &.mbsc-rtl {
          border-top-left-radius: .1875em;
        }
      }
    }

    &.mbsc-schedule-event-end {
      .mbsc-schedule-event-bar {
        bottom: 1px;

        &.mbsc-ltr {
          border-bottom-left-radius: .1875em;
        }

        &.mbsc-rtl {
          border-bottom-left-radius: .1875em;
        }
      }
    }

    &.mbsc-schedule-event-title {
      font-weight: 600;
    }

    /* Schedule event all-day style */

    &.mbsc-schedule-event-all-day-inner {
      margin: 0 .4em;
    }

    &.mbsc-schedule-event-all-day-background.mbsc-schedule-event-background {
      border-radius: 0;
    }

    &.mbsc-timeline-event-start.mbsc-ltr .mbsc-schedule-event-all-day-background,
    &.mbsc-timeline-event-end.mbsc-rtl .mbsc-schedule-event-all-day-background,
    &.mbsc-schedule-event-start.mbsc-ltr .mbsc-schedule-event-all-day-background,
    &.mbsc-schedule-event-end.mbsc-rtl .mbsc-schedule-event-all-day-background {
      left: .25em;
      border-top-left-radius: .25em;
      border-bottom-left-radius: .25em;
    }

    &.mbsc-timeline-event-end.mbsc-ltr .mbsc-schedule-event-all-day-background,
    &.mbsc-timeline-event-start.mbsc-rtl .mbsc-schedule-event-all-day-background,
    &.mbsc-schedule-event-end.mbsc-ltr .mbsc-schedule-event-all-day-background,
    &.mbsc-schedule-event-start.mbsc-rtl .mbsc-schedule-event-all-day-background {
      right: .25em;
      border-top-right-radius: .25em;
      border-bottom-right-radius: .25em;
    }

    /* Large screen styles */

    &.mbsc-calendar-width-md {
      .mbsc-schedule-all-day-wrapper {
        max-height: 3.5em;
      }

      .mbsc-cursor-indicator-time-y,
      .mbsc-schedule-time,
      .mbsc-schedule-time-indicator-time-x {
        font-size: .75em;
        padding: 0 .666667em;
      }

    }

    /* timeline resource icon */
    &.mbsc-timeline-resource-icon {
      width: 1.214286em;
      padding: 0 .142858em;
    }
  }

  @include mbsc-ios-eventcalendar('ios', $mbsc-ios-colors);
  @include mbsc-ios-eventcalendar('ios-dark', $mbsc-ios-dark-colors);
}



@if ($mbsc-material-theme) {
  .mbsc-material {

    /* Event list */

    &.mbsc-eventcalendar-schedule .mbsc-calendar-wrapper:after {
      display: none;
    }

    &.mbsc-event.mbsc-list-item {
      margin: .625em 1em;
      padding: .5em .625em;
      flex-direction: column;
      border-radius: .214286em;
      transition: box-shadow .2s ease-out;
    }

    &.mbsc-event-text,
    &.mbsc-event-time {
      font-size: .8125em;
      line-height: 1.384616em;
    }

    &.mbsc-event-time {
      display: flex;
      font-weight: 300;
    }

    &.mbsc-event-text {
      font-weight: 600;
    }

    &.mbsc-event-all-day {
      display: none;
    }

    &.mbsc-event-sep {
      padding: 0 .25em;
    }

    &.mbsc-event-until {
      padding-right: .25em;
    }

    /* Popover list */
    &.mbsc-popover-list {
      width: 13em;
      padding: .5em .125em;
    }

    &.mbsc-popover-list .mbsc-event {
      margin: .125em .5em;
      padding: .25em .5em;
    }

    &.mbsc-popover-list .mbsc-event-time,
    &.mbsc-popover-list .mbsc-event-text {
      font-size: .75em;
    }

    /* Schedule */

    /* Header */

    &.mbsc-schedule-header-item {
      padding-bottom: .25em;
    }

    &.mbsc-schedule-header-dayname {
      position: relative;
      // margin-top: .7em;
      line-height: 2.8em;
      font-size: .625em;
      font-weight: 500;
      text-transform: uppercase;
    }

    &.mbsc-schedule-header-day {
      height: 1.8em;
      width: 1.8em;
      margin-top: -.3em;
      margin-left: auto;
      margin-right: auto;
      line-height: 1.8em;
      font-size: 1.25em;
      border-radius: 50%;
    }

    &.mbsc-schedule-date-header-text {
      font-size: .875em;
      font-weight: 600;
      padding: .5714em 1.14285em;
    }

    &.mbsc-schedule-resource.mbsc-ltr {
      border-left-width: 1px;
      border-left-style: solid;
    }

    &.mbsc-schedule-resource.mbsc-rtl {
      border-right-width: 1px;
      border-right-style: solid;
    }

    &.mbsc-schedule-resource:first-child {
      border: 0;
    }

    /* Grid & All-day cont */

    &.mbsc-schedule-all-day-text {
      padding: .4em .8em;
      font-size: .625em;
      line-height: 1.6em;
      text-transform: uppercase;
    }

    &.mbsc-schedule-all-day-item {
      padding: 1px 0;
      min-height: 1.5em;
    }

    &.mbsc-schedule-time-col.mbsc-ltr {
      border-right-width: 1px;
      border-right-style: solid;
    }

    &.mbsc-schedule-time-col.mbsc-rtl {
      border-left-width: 1px;
      border-left-style: solid;
    }

    &.mbsc-schedule-time-col.mbsc-schedule-time-col-empty {
      border: 0;
    }

    &.mbsc-schedule-time-wrapper.mbsc-ltr {
      padding-right: 1em;
    }

    &.mbsc-schedule-time-wrapper.mbsc-rtl {
      padding-left: 1em;
    }

    &.mbsc-schedule-time-wrapper:after {
      content: '';
      position: absolute;
      top: 0;
      width: 9px;
    }

    &.mbsc-schedule-time-wrapper-end:before {
      content: '';
      position: absolute;
      bottom: 0;
      width: 9px;
    }

    &.mbsc-schedule-time-wrapper-end.mbsc-ltr:before,
    &.mbsc-schedule-time-wrapper.mbsc-ltr:after {
      right: 0;
    }

    &.mbsc-schedule-time-wrapper-end.mbsc-rtl:before,
    &.mbsc-schedule-time-wrapper.mbsc-rtl:after {
      left: 0;
    }

    &.mbsc-schedule-time {
      font-size: .625em;
    }

    &.mbsc-schedule-time-end.mbsc-ltr {
      right: 1.6em;
    }

    &.mbsc-schedule-time-end.mbsc-rtl {
      left: 1.6em;
    }

    /* Time & cursor indicator */

    &.mbsc-schedule-time-indicator-x:after {
      content: '';
      position: absolute;
      top: 0;
      width: .5625em;
      border-bottom: 1px solid;
      border-color: inherit;
    }

    &.mbsc-schedule-time-indicator.mbsc-ltr:after {
      left: -.5625em;
    }

    &.mbsc-schedule-time-indicator.mbsc-rtl:after {
      right: -.5625em;
    }

    &.mbsc-schedule-time-indicator-time-x {
      padding: 0 1.6em;
      margin: 0 1px;
    }

    &.mbsc-cursor-indicator-y.mbsc-ltr {
      left: 3.75em;
    }

    &.mbsc-cursor-indicator-y.mbsc-rtl {
      right: 3.75em;
    }

    /* Event style */

    &.mbsc-schedule-event-inner {
      border-radius: .4em;
    }

    &.mbsc-schedule-event-start {
      .mbsc-schedule-event-background {
        border-top-left-radius: .333334em;
        border-top-right-radius: .333334em;
      }
    }

    &.mbsc-schedule-event-end {
      .mbsc-schedule-event-background {
        border-bottom-left-radius: .333334em;
        border-bottom-right-radius: .333334em;
      }
    }

    &.mbsc-timeline-event-start.mbsc-ltr,
    &.mbsc-timeline-event-end.mbsc-rtl {
      .mbsc-schedule-event-background {
        border-bottom-left-radius: .333334em;
        border-top-left-radius: .333334em;

      }
    }

    &.mbsc-timeline-event-end.mbsc-ltr,
    &.mbsc-timeline-event-start.mbsc-rtl {
      .mbsc-schedule-event-background {
        border-bottom-right-radius: .333334em;
        border-top-right-radius: .333334em;
      }
    }

    &.mbsc-schedule-event-active,
    &.mbsc-schedule-event-dragging {
      .mbsc-schedule-event-background {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12), 0 1px 3px -1px rgba(0, 0, 0, 0.2);
      }
    }

    &.mbsc-schedule-event-hover {
      .mbsc-schedule-event-background:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .1);
      }
    }

    &.mbsc-schedule-event-title {
      font-weight: 500;
    }

    &.mbsc-schedule-event-all-day-background.mbsc-schedule-event-background {
      border-radius: 0;
    }

    &.mbsc-timeline-event-start.mbsc-ltr .mbsc-schedule-event-all-day-background,
    &.mbsc-timeline-event-end.mbsc-rtl .mbsc-schedule-event-all-day-background,
    &.mbsc-schedule-event-start.mbsc-ltr .mbsc-schedule-event-all-day-background,
    &.mbsc-schedule-event-end.mbsc-rtl .mbsc-schedule-event-all-day-background {
      border-top-left-radius: .333334em;
      border-bottom-left-radius: .333334em;
    }

    &.mbsc-timeline-event-end.mbsc-ltr .mbsc-schedule-event-all-day-background,
    &.mbsc-timeline-event-start.mbsc-rtl .mbsc-schedule-event-all-day-background,
    &.mbsc-schedule-event-end.mbsc-ltr .mbsc-schedule-event-all-day-background,
    &.mbsc-schedule-event-start.mbsc-rtl .mbsc-schedule-event-all-day-background {
      border-top-right-radius: .333334em;
      border-bottom-right-radius: .333334em;
    }

    &.mbsc-schedule-header-item-large {
      .mbsc-schedule-header-dayname {
        // margin-top: .727273em;
        line-height: 2.909091em;
        font-size: 0.6875em;
        text-transform: uppercase;
      }

      .mbsc-schedule-header-day {
        height: 1.769231em;
        width: 1.769231em;
        line-height: 1.769231em;
        font-size: 1.625em;
        margin-top: -.307693em;
      }
    }

  }

  @include mbsc-material-eventcalendar('material', $mbsc-material-colors);
  @include mbsc-material-eventcalendar('material-dark', $mbsc-material-dark-colors);
}



@if ($mbsc-windows-theme) {
  .mbsc-windows {
    &.mbsc-popover-list {
      margin: .25em 0;
    }

    &.mbsc-event-group {
      border-bottom: 1px solid;
    }

    &.mbsc-event-day.mbsc-list-header {
      padding: 1em 1.5em .25em 1.5em;
    }

    &.mbsc-event.mbsc-list-item {
      margin-bottom: .25em;
      align-items: center;
    }

    &.mbsc-event-content {
      order: 1;
    }

    &.mbsc-event-time {
      width: 7em;
      padding: 0 1.333334em;
      font-size: .75em;
      box-sizing: border-box;
    }

    &.mbsc-event-color {
      width: .5em;
      margin: -.5em 0 -.5em -.5em;
      align-self: stretch;
    }

    &.mbsc-event-sep {
      display: none;
    }

    /* Schedule view */

    &.mbsc-schedule-wrapper {
      position: relative;
      min-height: 1.5em;
      z-index: 2;
    }

    /* Header */

    &.mbsc-schedule-header-day {
      font-size: 1em;
      text-align: left;
      padding: .5em .5em 1.5em .5em;
      border-top: 1px solid;
    }

    &.mbsc-schedule-header-day.mbsc-ltr {
      border-left-style: solid;
      border-left-width: 1px;
      margin-left: -1px;
    }

    &.mbsc-schedule-header-day.mbsc-rtl {
      border-right-style: solid;
      border-right-width: 1px;
      margin-right: -1px;
    }

    &.mbsc-schedule-header-item.mbsc-ltr {
      border-left: 1px solid transparent;
    }

    &.mbsc-schedule-header-item.mbsc-rtl {
      border-right: 1px solid transparent;
    }

    &.mbsc-schedule-header-item:first-child {
      border: 0;

      .mbsc-schedule-header-day {
        border-left: 0;
        border-right: 0;
        margin: 0;
      }
    }

    &.mbsc-schedule-header-dayname {
      padding: .571429em; //.428571em .571429em;
      font-size: .875em;
      text-align: left;
    }

    &.mbsc-schedule-all-day-item {
      min-height: 1.375em;
    }

    &.mbsc-schedule-all-day-text {
      padding: 0 .333334em;
      font-size: .75em;
      text-align: center;
      line-height: 1.833334em;
    }

    &.mbsc-schedule-header-dayname {
      position: relative;
    }

    &.mbsc-schedule-header-dayname.mbsc-selected:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: .285715em;
    }

    &.mbsc-schedule-date-header {
      border-bottom: 1px solid;
    }

    &.mbsc-schedule-date-header-text {
      font-weight: 500;
      line-height: 1.25em;
      padding: .5em 0;
    }

    &.mbsc-schedule-header-day.mbsc-hover {
      background: rgba(0, 0, 0, .1);
    }

    &.mbsc-schedule-resource {
      border-top: 1px solid;
      margin-top: -1px;
    }

    &.mbsc-schedule-resource.mbsc-ltr {
      border-left-width: 1px;
      border-left-style: solid;
      text-align: left;
    }

    &.mbsc-schedule-resource.mbsc-rtl {
      border-right-width: 1px;
      border-right-style: solid;
      text-align: right;
    }

    &.mbsc-schedule-resource:first-child {
      border-left: 0;
      border-right: 0;
    }

    /* Time & cursor indicator */

    &.mbsc-schedule-time-indicator-x {
      border-bottom: 1px dashed;
    }

    &.mbsc-cursor-indicator-time-y,
    &.mbsc-schedule-time-indicator-time-x {
      padding: 0 .333334em;
      font-size: .75em;
      text-transform: lowercase;
    }

    &.mbsc-schedule-time-indicator-day {
      border-top: 1px solid;
      border-bottom: 1px solid;
    }

    &.mbsc-schedule-time-indicator-day:before {
      display: none;
    }


    &.mbsc-cursor-indicator-y.mbsc-ltr {
      left: 4.1875em;
    }

    &.mbsc-cursor-indicator-y.mbsc-rtl {
      right: 4.1875em;
    }

    /* Grid & All-day row */

    &.mbsc-schedule-all-day-wrapper {
      max-height: 4em;
    }

    &.mbsc-schedule-time-col.mbsc-ltr {
      border-right-width: 1px;
      border-right-style: solid;
    }

    &.mbsc-schedule-time-col.mbsc-rtl {
      border-left-width: 1px;
      border-left-style: solid;
    }

    &.mbsc-schedule-time {
      top: 0;
      padding: 0 .333334em;
      text-transform: lowercase;
    }

    &.mbsc-schedule-time-end {
      display: none;
    }

    /* Event style */

    &.mbsc-schedule-event-hover {
      .mbsc-schedule-event-background:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .1);
      }
    }

    /* timeline resource icon */
    &.mbsc-timeline-resource-icon {
      width: 1.071429em;
      padding: 0 .214286em;
    }

  }

  @include mbsc-windows-eventcalendar('windows', $mbsc-windows-colors);
  @include mbsc-windows-eventcalendar('windows-dark', $mbsc-windows-dark-colors);
}


.mbsc-eventcalendar {
  height: 100%;
  overflow: hidden;
}

/* Event list */

.mbsc-event-list {
  flex: 1 1 auto;
  position: relative;
}

.mbsc-event-list-scroll {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.mbsc-event-list-empty {
  text-align: center;
  margin: 3em;
  font-size: 1.5em;
}

.mbsc-event-day {
  position: sticky;
  top: 0;
  z-index: 3;
}

.mbsc-event-color,
.mbsc-event-time {
  flex: 0 0 auto;
}

.mbsc-event-content {
  flex: 1 1 auto;
  overflow: hidden;
}

.mbsc-event-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Popover list */

.mbsc-popover-list {
  width: 20em;
  max-width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.mbsc-popover-hidden {
  visibility: hidden;
}

// Covers the entire calendar during drag, so it show the move cursor everywhere,
// and will prevent hover on other elements
.mbsc-calendar-dragging {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  cursor: move;
}


/* External drag element */

.mbsc-draggable {
  user-select: none;
}

.mbsc-drag-clone {
  position: fixed;
  display: table;
  z-index: 99;
  margin: 15px 0 0 0 !important;
  box-shadow: 0 0 transparent, 0 0 transparent, 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -1px rgba(0, 0, 0, .06);
  cursor: move;

  &:after {
    content: '';
    position: absolute;
    top: -50px;
    bottom: -50px;
    left: -50px;
    right: -50px;
  }
}

/* Fake scroll rules */
.mbsc-schedule-fake-scroll-x,
.mbsc-schedule-fake-scroll-y {
  visibility: hidden;
  flex: none;
}

.mbsc-schedule-fake-scroll-x {
  overflow-x: scroll;
  width: 0;
}

.mbsc-schedule-fake-scroll-y {
  overflow-y: scroll;
  max-height: 20px;
}



@if ($mbsc-ios-theme) {
  @include mbsc-ios-calendar('ios', $mbsc-ios-colors);
  @include mbsc-ios-calendar('ios-dark', $mbsc-ios-dark-colors);
}



@if ($mbsc-material-theme) {
  @include mbsc-material-calendar('material', $mbsc-material-colors);
  @include mbsc-material-calendar('material-dark', $mbsc-material-dark-colors);
}



@if ($mbsc-windows-theme) {
  @include mbsc-windows-calendar('windows', $mbsc-windows-colors);
  @include mbsc-windows-calendar('windows-dark', $mbsc-windows-dark-colors);
}




@if ($mbsc-material-theme) {
  .mbsc-material {
    &.mbsc-scroller-wheel-wrapper {
      margin: 0 .25em;
      padding: .5em 0;
    }

    &.mbsc-scroller-wheel-cont::after,
    &.mbsc-scroller-wheel-cont::before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;

      border-width: 2px;
      border-top-style: solid;
      z-index: 1;
    }

    &.mbsc-scroller-wheel-cont::after {
      bottom: -2px;
    }

    &.mbsc-scroller-wheel-cont::before {
      top: -2px;
    }

    &.mbsc-scroller-wheel-group {
      padding: 2em .25em;
    }

    &.mbsc-scroller-wheel-item {
      padding: 0 .272727em;
      font-size: 1.375em;
      text-align: center;
    }

    &.mbsc-wheel-item-multi {
      padding: 0 2em; // .5em added for more space between checkmark and text
    }

    &.mbsc-scroller-wheel-header {
      font-weight: bold;
      font-size: 1.125em;
    }

    /* Checkmark styling */

    &.mbsc-wheel-checkmark {
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      display: none;
      border-radius: .1875em;
      width: 1.3em;
      height: 1.3em;
      margin-top: -.55em;

      &::after {
        content: '';
        box-sizing: border-box;
        position: absolute;
        display: block;

        top: 0.27273em;
        left: 0.27273em;
        width: .8125em;
        height: .4375em;
        opacity: 0;
        border: .125em solid;
        border-top: 0;
        border-right: 0;
        transform: scale(0) rotate(-45deg);
        transition: transform .1s ease-out;
      }

      &.mbsc-selected::after {
        opacity: 1;
        transform: scale(1) rotate(-45deg);
      }

      &.mbsc-ltr {
        left: .25em;
      }

      &.mbsc-rtl {
        right: .25em;
      }
    }

    &.mbsc-wheel-item-multi &.mbsc-wheel-checkmark {
      display: block;
    }

    /* Desktop styling */

    &.mbsc-scroller-pointer {

      .mbsc-scroller-wheel-group {
        padding: 0;
      }

      .mbsc-scroller-wheel-wrapper {
        margin: 0;
        padding: 0;
      }

      .mbsc-scroller-wheel-item {
        font-size: 1em;
        padding: 0 1.25em;
      }

      .mbsc-wheel-item-multi {
        padding: 0 2em;
      }

      .mbsc-wheel-item-multi {
        &.mbsc-ltr {
          text-align: left;
        }

        &.mbsc-rtl {
          text-align: right;
        }
      }
    }
  }

  @include mbsc-material-scroller('material', $mbsc-material-colors);
  @include mbsc-material-scroller('material-dark', $mbsc-material-dark-colors);
}



@if ($mbsc-ios-theme) {
  .mbsc-ios {

    &.mbsc-scroller-wheel-multi.mbsc-scroller-wheel-cont-3d {
      visibility: hidden;
    }

    &.mbsc-scroller-wheel-group-cont {
      padding: 0 .625em;
    }

    &.mbsc-scroller-wheel-group {
      padding: .625em;
    }

    &.mbsc-wheel-group-checkmark {
      padding: .625em 0;
    }

    &.mbsc-scroller-wheel-3d {
      overflow: visible;
    }

    &.mbsc-scroller-wheel-line {
      display: block;
      z-index: 1;
      border-radius: .5em;
      margin: 0 .625em;
    }

    &.mbsc-scroller-wheel-overlay {
      display: block;
    }

    &.mbsc-scroller-wheel-item {
      padding: 0 .5em;
      font-size: 1.25em;
      text-align: center;
      box-sizing: border-box;
      border-radius: .5em;
    }

    &.mbsc-scroller-wheel-item-3d {
      font-size: 1.125em;
    }

    &.mbsc-wheel-item-checkmark {
      padding-left: 1.75em;
      padding-right: 1.75em;
    }

    &.mbsc-scroller-wheel-header {
      font-size: .875em;
    }

    /* checkmark */

    &.mbsc-wheel-checkmark {
      display: none;
      position: absolute;
      width: 1.75em;
      height: 1.75em;
      top: 50%;
      margin-top: -0.875em;

      &::after {
        content: '';
        box-sizing: border-box;
        position: absolute;
        display: block;
        opacity: 0;
        transform: rotate(-45deg);

        top: 32%;
        left: 26%;
        width: .75em;
        height: .375em;
        border: .125em solid currentColor;
        border-top: 0;
        border-right: 0;
        transition: opacity .2s ease-in-out;
      }

      &.mbsc-selected::after {
        opacity: 1;
      }

      &.mbsc-ltr {
        left: 0;
      }
  
      &.mbsc-rtl {
        right: 0;
      }
    }

    &.mbsc-wheel-item-multi &.mbsc-wheel-checkmark,
    &.mbsc-scroller-pointer &.mbsc-wheel-item-checkmark .mbsc-wheel-checkmark {
      display: block;
    }

    /* Desktop styling */

    &.mbsc-scroller-pointer {

      .mbsc-scroller-wheel-group-cont,
      .mbsc-scroller-wheel-group {
        padding: 0;
      }

      .mbsc-scroller-wheel-wrapper {
        padding: .1875em 0;
      }

      .mbsc-scroller-wheel-overlay {
        display: none;
      }

      .mbsc-scroller-wheel-item {
        font-size: 1em;
        padding: 0 .75em;
        margin: 0 .1875em;
      }

      .mbsc-scroller-wheel-line {
        margin: 0 .1875em;
      }

      .mbsc-wheel-item-checkmark.mbsc-ltr {
        text-align: left;
        padding-left: 1.75em;
      }

      .mbsc-wheel-item-checkmark.mbsc-rtl {
        text-align: right;
        padding-right: 1.75em;
      }

      // Group styling

      .mbsc-scroller-wheel-header {
        font-size: .75em;
        font-weight: 700;
      }
    }
  }

  @include mbsc-ios-scroller('ios', $mbsc-ios-colors);
  @include mbsc-ios-scroller('ios-dark', $mbsc-ios-dark-colors);
}



@if ($mbsc-windows-theme) {
  .mbsc-windows {

    &.mbsc-scroller {
      position: relative;
    }

    &.mbsc-scroller-wheel-wrapper.mbsc-ltr {
      border-left-width: 1px;
      border-left-style: solid;
    }

    &.mbsc-scroller-wheel-wrapper-0.mbsc-ltr {
      border-left: 0;
    }

    &.mbsc-scroller-wheel-wrapper.mbsc-rtl {
      border-right-width: 1px;
      border-right-style: solid;
    }

    &.mbsc-scroller-wheel-wrapper-0.mbsc-rtl {
      border-right: 0;
    }

    &.mbsc-scroller-wheel-item {
      padding: 0 .5em;
      box-sizing: border-box;
      text-align: center;
    }

    &.mbsc-wheel-item-multi {
      padding: 0 2.0625em; // .5em is added for more space between checkmark and text

      &.mbsc-ltr {
        text-align: left;
      }

      &.mbsc-rtl {
        text-align: right;
      }
    }

    &.mbsc-scroller-wheel-line {
      display: block;
      z-index: 1;
    }

    &.mbsc-scroller-wheel-header {
      font-size: 1.375em;
    }

    /* Checkmark styling */

    &.mbsc-wheel-checkmark {
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      display: none;

      margin-top: -.8125em;
      width: 1.5625em;
      height: 1.5625em;

      &::after {
        content: '';
        box-sizing: border-box;
        position: absolute;
        display: block;
        opacity: 0;
        transform: rotate(-45deg);

        top: 28%;
        left: 21%;
        width: .875em;
        height: .475em;
        border: .125em solid;
        border-top: 0;
        border-right: 0;
      }

      &.mbsc-selected::after {
        opacity: 1;
      }

      &.mbsc-ltr {
        left: .25em;
      }

      &.mbsc-rtl {
        right: .25em;
      }
    }

    &.mbsc-wheel-item-multi &.mbsc-wheel-checkmark {
      display: block;
    }

    /* Desktop styling */

    &.mbsc-scroller-pointer {

      .mbsc-scroller-wheel-item {
        padding: 0 1.25em;
      }

      .mbsc-wheel-item-multi {
        padding: 0 2.0625em; // .5em is added for more space between checkmark and text
      }
    }

    &.mbsc-scroller-bar::after {
      border-radius: 0;
    }
  }

  @include mbsc-windows-scroller('windows', $mbsc-windows-colors);
  @include mbsc-windows-scroller('windows-dark', $mbsc-windows-dark-colors);
}


.mbsc-scroller {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  text-align: center;
  user-select: none;
}

.mbsc-scroller-wheel-group-cont {
  position: relative;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  overflow: hidden;
}

.mbsc-scroller-wheel-group-cont:first-child:last-child {
  display: block;
}

.mbsc-scroller-wheel-group {
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.mbsc-scroller-wheel-group-3d {
  perspective: 100em;
}

.mbsc-scroller-wheel-wrapper {
  display: block;
  max-width: 100%;
  position: relative;
  touch-action: none;
  /* Temp */
  min-width: 80px;
}

.mbsc-scroller-pointer .mbsc-scroller-wheel-wrapper-0:last-child {
  flex: 1 1 auto; // Expand single wheel in desktop mode
}

.mbsc-scroller-wheel-line {
  display: none;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  pointer-events: none;
  transform: translateY(-50%);
}

.mbsc-scroller-wheel-overlay {
  display: none;
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}

.mbsc-scroller-wheel {
  display: block;
  overflow: hidden;
  /* Fixes Firefox rendering issues */
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  margin: -1px 0;
}

.mbsc-scroller-wheel-cont {
  position: relative;
  z-index: 1;
  top: 50%;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  transform: translateY(-50%);
}

.mbsc-scroller-wheel-cont-3d {
  overflow: hidden;
  border: 0;
}

.mbsc-scroller-wheel-cont-3d > div {
  position: relative;
  top: -1px;
}

.mbsc-scroller-wheel-wrapper-3d,
.mbsc-scroller-wheel-3d {
  transform-style: preserve-3d;
}

.mbsc-scroller-items-3d {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform-style: preserve-3d;
  transform: translateY(-50%);
}

.mbsc-scroller .mbsc-scroller-wheel,
.mbsc-scroller .mbsc-scroller-wheel-cont {
  box-sizing: content-box;
}

.mbsc-scroller-wheel-item {
  display: block;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mbsc-scroller-wheel-item:focus,
.mbsc-scroller-wheel-header:focus {
  outline: 0;
}

.mbsc-scroller-wheel-item-3d {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  cursor: pointer;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.mbsc-scroller-wheel-item.mbsc-disabled {
  opacity: .3;
}

.mbsc-scroller-wheel-header {
  display: block; // Needed by Angular
  padding: 0 .5em;

  &.mbsc-ltr {
    text-align: left;
  }

  &.mbsc-rtl {
    text-align: right;
  }
}

.mbsc-scroller-bar-cont {
  position: absolute;
  z-index: 4;
  top: 0;
  bottom: 0;
  width: 10px;
  opacity: 0;
  background: rgba(0, 0, 0, 0.05);
  transform: translateZ(0);
  transition: opacity .2s;

  &.mbsc-ltr {
    right: 0;
  }

  &.mbsc-rtl {
    left: 0;
  }
}

.mbsc-scroller-bar-hidden {
  display: none;
}

.mbsc-scroller-wheel:hover .mbsc-scroller-bar-cont,
.mbsc-scroller-bar-started {
  opacity: 1;
}

.mbsc-scroller-bar {
  position: absolute;
  right: 0;
  top: 0;
  width: 10px;
  // height: 100%;

  &::after {
    content: '';
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 3px;
  }
}



@if ($mbsc-ios-theme) {
  .mbsc-ios {
    &.mbsc-datetime-year-wheel .mbsc-scroller-wheel-item {
      min-width: 3.8em;
    }

    &.mbsc-datetime-month-wheel .mbsc-scroller-wheel-item {
      text-align: left;
    }

    &.mbsc-datetime-day-wheel .mbsc-scroller-wheel-item,
    &.mbsc-datetime-hour-wheel .mbsc-scroller-wheel-item,
    &.mbsc-datetime-date-wheel .mbsc-scroller-wheel-item {
      text-align: right;
    }
  }
}





@if ($mbsc-windows-theme) {
  .mbsc-windows {
    &.mbsc-datetime-date-wheel.mbsc-scroller-wheel-wrapper {
      min-width: 120px;
    }

    &.mbsc-datetime-month-wheel .mbsc-scroller-wheel-item,
    &.mbsc-datetime-date-wheel .mbsc-scroller-wheel-item {
      text-align: left;
    }

    &.mbsc-datetime-month-wheel.mbsc-rtl .mbsc-scroller-wheel-item,
    &.mbsc-datetime-date-wheel.mbsc-rtl .mbsc-scroller-wheel-item {
      text-align: right;
    }
  }
}




@if ($mbsc-ios-theme) {
  .mbsc-ios {

    &.mbsc-timegrid-item {
      line-height: 2em;
      margin: .5em .625em;
      border-radius: 2em;
      font-size: .875em;
    }

    &.mbsc-timegrid-item.mbsc-selected,
    &.mbsc-timegrid-item:hover,
    &.mbsc-timegrid-item:focus {
      font-size: 1em;
      margin: 0 .25em;
      outline: none;
    }

  }
}

@include mbsc-ios-timegrid('ios', $mbsc-ios-colors);
@include mbsc-ios-timegrid('ios-dark', $mbsc-ios-dark-colors);



@if ($mbsc-material-theme) {
  .mbsc-material {

    &.mbsc-timegrid-item {
      line-height: 2em;
      margin: .5em .625em;
      border-radius: 2em;
      font-size: .875em;
    }

    &.mbsc-timegrid-item.mbsc-selected,
    &.mbsc-timegrid-item:hover,
    &.mbsc-timegrid-item:focus {
      margin: 0 .25em;
      outline: none;
    }
  }
}

@include mbsc-material-timegrid('material', $mbsc-material-colors);
@include mbsc-material-timegrid('material-dark', $mbsc-material-dark-colors);



@if ($mbsc-windows-theme) {
  .mbsc-windows {

    &.mbsc-timegrid-item {
      padding: 1.3125em 0;
      margin: .0625em;
      font-size: 14px;
    }

    &.mbsc-timegrid-item:focus {
      outline: none;
    }
  }
}

@include mbsc-windows-timegrid('windows', $mbsc-windows-colors);
@include mbsc-windows-timegrid('windows-dark', $mbsc-windows-dark-colors);


// When the calendar control is present, then the calendar specifies the height,
// but when the row is wrapped, we need a minimum height, so the grid won't collapse
// into 0
.mbsc-datepicker-control-calendar .mbsc-datepicker-tab-timegrid {
  min-height: 10em;
}

// when there is no other control there the grid needs a minimum height
.mbsc-datepicker-tab-timegrid {
  min-height: 19em;
}

.mbsc-timegrid-item.mbsc-disabled {
  pointer-events: none;
  opacity: 0.2;
}

.mbsc-datepicker-tab-timegrid.mbsc-datepicker-tab {
  position: relative;
  overflow: auto;
  align-items: flex-start;
  width: 18.5em;
}

.mbsc-timegrid-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: table;
  table-layout: fixed;
  width: 100%;
}

.mbsc-timegrid-row {
  display: table-row;
}

.mbsc-timegrid-cell {
  display: table-cell;
  position: relative; // do not remove!

  &.mbsc-disabled {
    cursor: not-allowed;
  }
}

.mbsc-timegrid-item {
  vertical-align: middle;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
}



@if ($mbsc-ios-theme) {

  .mbsc-ios {

    &.mbsc-picker {

      .mbsc-popup-overlay-top,
      .mbsc-popup-overlay-bottom {
        background: none;
      }
    }

    &.mbsc-datepicker-inline {
      border-top: 1px solid;
      border-bottom: 1px solid;
      margin-top: -1px;
      margin-bottom: -1px;
      z-index: 2;
      position: relative;
    }

    &.mbsc-datepicker .mbsc-calendar-grid,
    &.mbsc-datepicker .mbsc-calendar-cell,
    &.mbsc-datepicker .mbsc-calendar-cell-inner {
      border-color: transparent;
    }

    &.mbsc-datepicker .mbsc-selected .mbsc-calendar-day-text,
    &.mbsc-datepicker .mbsc-highlighted .mbsc-calendar-day-text,
    &.mbsc-datepicker .mbsc-range-hover-start .mbsc-calendar-day-text,
    &.mbsc-datepicker .mbsc-range-hover-end .mbsc-calendar-day-text,
    &.mbsc-datepicker .mbsc-hover .mbsc-calendar-day-text {
      width: 1.444445em;
      height: 1.444445em;
      margin: 0.16667em;
      font-size: 1.125em;
      line-height: 1.444445em;
    }

    &.mbsc-picker-header {
      line-height: 1.25em;
      padding: .75em 4.375em;
      font-weight: bold;
      text-align: center;
      border-bottom: 1px solid;
    }

    /* Range Controls */

    &.mbsc-range-control-wrapper {
      padding: 0 .75em;
      overflow: hidden;
    }

    &.mbsc-range-control-wrapper .mbsc-segmented {
      width: 17em; // 272px
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
      box-sizing: border-box;
    }

    // Segmented width based on controls

    &.mbsc-datepicker-control-calendar.mbsc-datepicker-control-timegrid .mbsc-range-control-wrapper .mbsc-segmented,
    &.mbsc-datepicker-control-calendar.mbsc-datepicker-control-time .mbsc-range-control-wrapper .mbsc-segmented,
    &.mbsc-datepicker-control-date.mbsc-datepicker-control-time .mbsc-range-control-wrapper .mbsc-segmented,
    &.mbsc-datepicker-control-datetime .mbsc-range-control-wrapper .mbsc-segmented {
      width: 25em; // 400px: default format can show -> "09/25/2020 12:30 AM"
    }

    &.mbsc-range-control-wrapper .mbsc-segmented-button {
      display: block;
      padding: 0 .5em;
    }

    &.mbsc-range-control-wrapper .mbsc-range-value-nonempty .mbsc-segmented-button {
      padding: 0 2.0625em 0 .5em;
    }

    &.mbsc-range-control-wrapper .mbsc-range-value-nonempty .mbsc-segmented-button.mbsc-rtl {
      padding: 0 .5em 0 2.0625em;
    }

    &.mbsc-range-control-label,
    &.mbsc-range-control-value {
      text-align: left;
      line-height: 1.538462em; // 20px

      &.active {
        font-weight: 600;
      }

      &.mbsc-rtl {
        text-align: right;
      }
    }

    &.mbsc-range-control-label {
      padding: .615385em .615385em 0 .615385em; // 8px
    }

    &.mbsc-range-control-value {
      padding: 0 .615385em .615385em .615385em; // 8px
      text-overflow: ellipsis;
      overflow: hidden;

      &.active {
        padding: 0 .571429em .571429em .571429em; // 8px
        font-size: 1.076923em; // 14px
        line-height: 1.428572em; // 20px
      }
    }

    &.mbsc-range-label-clear {
      margin-top: -.692308em; // 9px
      width: 1.307693em; // 17px
      height: 1.307693em;

      &.mbsc-ltr {
        right: 1em;
      }

      &.mbsc-rtl {
        left: 1em;
      }
    }

  }

  @include mbsc-ios-datepicker('ios', $mbsc-ios-colors);
  @include mbsc-ios-datepicker('ios-dark', $mbsc-ios-dark-colors);
}



@if ($mbsc-material-theme) {

  .mbsc-material {
    &.mbsc-picker-header {
      font-size: 1.25em;
      font-weight: 500;
      padding: .8em .8em 0 .8em;
      line-height: 1.4em;
      text-align: center;
    }

    /* Range Control */

    // Overrides for the segmented
    &.mbsc-range-control-wrapper {

      .mbsc-segmented {
        padding: 0;
        max-width: 100%;
        width: 18.5em; // 296px
      }

      .mbsc-segmented-button.mbsc-button.mbsc-font {
        background: none;
        border: 0;
        border-bottom: 2px solid transparent;
        border-radius: 0;
        padding: .8571422em 1.142857em; // 12px 16px (14px based)
        display: block;
      }

      .mbsc-segmented-button.mbsc-ltr {
        text-align: left;
      }

      .mbsc-segmented-button.mbsc-rtl {
        text-align: right;
      }

      .mbsc-range-value-nonempty .mbsc-segmented-button.mbsc-ltr {
        padding-right: 2.642857em; // 37px (14px based)
      }

      .mbsc-range-value-nonempty .mbsc-segmented-button.mbsc-rtl {
        padding-left: 2.642857em; // 37px (14px based)
      }
    }

    // Segmented width based on controls

    &.mbsc-datepicker-control-calendar.mbsc-datepicker-control-timegrid .mbsc-range-control-wrapper .mbsc-segmented,
    &.mbsc-datepicker-control-calendar.mbsc-datepicker-control-time .mbsc-range-control-wrapper .mbsc-segmented,
    &.mbsc-datepicker-control-date.mbsc-datepicker-control-time .mbsc-range-control-wrapper .mbsc-segmented,
    &.mbsc-datepicker-control-datetime .mbsc-range-control-wrapper .mbsc-segmented {
      width: 25em; // 400px: default format can show -> "09/25/2020 12:30 AM"
    }

    &.mbsc-range-control-label {
      text-transform: uppercase;
    }

    &.mbsc-range-control-value {
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.mbsc-range-label-clear {
      margin-top: -.625em; // 10px
      right: .75em; // 12px
      width: 1.3125em; // 21px
      height: 1.3125em;

      &.mbsc-ltr {
        right: .75em;
      }

      &.mbsc-rtl {
        left: .75em;
      }
    }

    // Reduce calendar title font size

    &.mbsc-datepicker .mbsc-calendar-title {
      font-size: 1.142858em;
      line-height: 1.75em;
    }

    &.mbsc-calendar-grid .mbsc-calendar-title {
      font-size: 1.428572em;
      line-height: 1.4em;
    }
  }

  @include mbsc-material-datepicker('material', $mbsc-material-colors);
  @include mbsc-material-datepicker('material-dark', $mbsc-material-dark-colors);
}



@if ($mbsc-windows-theme) {

  .mbsc-windows {
    &.mbsc-picker-header {
      padding: .5em;
      font-weight: bold;
      text-align: center;
      border-bottom: 1px solid;
    }

    &.mbsc-datepicker-tab {
      border-top: 1px solid;
      margin-top: -1px;
    }

    /* Range Control */

    // overwrites for the segmented
    &.mbsc-range-control-wrapper {

      .mbsc-segmented {
        padding: 0;
        max-width: 100%;
        width: 18.5em; // 296px
      }

      .mbsc-segmented-button.mbsc-button.mbsc-font {
        background: none;
        border: 0;
        border-bottom: 2px solid transparent;
        padding: .625em 1em; // 10px 8px;
        line-height: 1.25em; // 20px
        display: block;
        text-align: left;
      }

      .mbsc-segmented-button.mbsc-rtl {
        text-align: right;
      }

      .mbsc-range-value-nonempty .mbsc-segmented-button.mbsc-ltr {
        padding-right: 1.875em; // 10px 28px 10px 8px;
      }

      .mbsc-range-value-nonempty .mbsc-segmented-button.mbsc-rtl {
        padding-left: 1.875em;
      }
    }

    // Segmented width based on controls

    &.mbsc-datepicker-control-calendar.mbsc-datepicker-control-timegrid .mbsc-range-control-wrapper .mbsc-segmented,
    &.mbsc-datepicker-control-calendar.mbsc-datepicker-control-time .mbsc-range-control-wrapper .mbsc-segmented,
    &.mbsc-datepicker-control-date.mbsc-datepicker-control-time .mbsc-range-control-wrapper .mbsc-segmented,
    &.mbsc-datepicker-control-datetime .mbsc-range-control-wrapper .mbsc-segmented {
      width: 26.25em; // 420px: default format can show -> "09/25/2020 12:30 AM"
    }

    &.mbsc-range-control-label {
      font-size: 0.9375em; // 15px
      line-height: 1.6em; // 24px
    }

    &.mbsc-range-control-value {
      font-size: 1.0625em; // 17px
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.mbsc-range-label-clear {
      width: 1em; // 16px
      height: 1em;
      right: .4375em; // 7px;
      margin-top: -.5em;

      &.mbsc-ltr {
        right: .4375em; // 7px;
      }

      &.mbsc-rtl {
        left: .4375em; // 7px
      }
    }
  }

  @include mbsc-windows-datepicker('windows', $mbsc-windows-colors);
  @include mbsc-windows-datepicker('windows-dark', $mbsc-windows-dark-colors);
}


/* Needed for angular */
mbsc-datetime {
  display: block;
  width: 100%;
}

.mbsc-datepicker-inline {
  height: 100%;
}

.mbsc-datepicker .mbsc-calendar {
  height: 100%;
  max-width: 100%;
  padding-bottom: .5em;
  box-sizing: border-box;
  // For IE11
  display: block;
}

.mbsc-datepicker .mbsc-calendar-header .mbsc-calendar-week-days {
  padding: 0 .5em;
}

/* Start/end controls */

.mbsc-range-control-wrapper .mbsc-segmented-input {
  // v4 compatibility
  width: 1px;
}

.mbsc-range-label-clear {
  position: absolute;
  top: 50%;
}

/* Tabs */

.mbsc-datepicker-tab-wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow: hidden;
}

.mbsc-datepicker-tab {
  visibility: hidden;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mbsc-datepicker-tab-expand {
  flex: 1 1 auto;
  height: 100%;
}

.mbsc-datepicker-tab-active {
  visibility: visible;
}

.mbsc-datepicker-time-modal {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  max-width: none;
  height: 100%;
}

.mbsc-datepicker .mbsc-calendar-slide {
  padding: 0 .5em;
}



@if ($mbsc-ios-theme) {
  .mbsc-ios {
    &.mbsc-select-scroller-inline {
      border-top: 1px solid;
      border-bottom: 1px solid;
      margin-top: -1px;
      margin-bottom: -1px;
    }

    &.mbsc-select-group-wheel-multi .mbsc-scroller-wheel-cont-3d {
      visibility: hidden;
    }

    // select specific styling overwrites
    &.mbsc-select-scroller {

      // Desktop
      &.mbsc-scroller-pointer {

        .mbsc-select-group-wheel.mbsc-ltr {
          border-right-width: 1px;
          border-right-style: solid;
        }

        .mbsc-select-group-wheel.mbsc-rtl {
          border-left-width: 1px;
          border-left-style: solid;
        }

        // .mbsc-scroller-wheel-item.mbsc-ltr {
        //   margin: 0 .75em 0 .1875em;
        // }

        // .mbsc-scroller-wheel-item.mbsc-rtl {
        //   margin: 0 .1875em 0 .75em;
        // }

        // no scrollbar
        // .mbsc-scroller-bar-none {

        //   .mbsc-scroller-wheel-item.mbsc-ltr,
        //   .mbsc-scroller-wheel-item.mbsc-rtl {
        //     margin: 0 .1875em;
        //   }

        // }
      }
    }

    &.mbsc-select-filter-cont {
      padding: .5em;
    }

    &.mbsc-select-filter {
      margin: 0;
    }

    &.mbsc-select-filter-input {
      height: 2em;
    }

    &.mbsc-select-empty-text {
      font-size: 1.25em;
    }

  }

  @include mbsc-ios-select('ios', $mbsc-ios-colors);
  @include mbsc-ios-select('ios-dark', $mbsc-ios-dark-colors);
}



@if ($mbsc-material-theme) {
  .mbsc-material {

    // select specific overrides
    &.mbsc-select-scroller {

      // Desktop styling overrides
      &.mbsc-scroller-pointer {

        .mbsc-scroller-wheel-cont::after,
        .mbsc-scroller-wheel-cont::before {
          display: none;
        }
      }
    }

    &.mbsc-select-filter {
      margin: 0;
    }

    &.mbsc-select-empty-text {
      font-size: 1.375em;
      opacity: .5;
    }
  }

  @include mbsc-material-select('material', $mbsc-material-colors);
  @include mbsc-material-select('material-dark', $mbsc-material-dark-colors);
}



@if ($mbsc-windows-theme) {
  .mbsc-windows {

    &.mbsc-select-filter {
      margin: .5em;
    }

    &.mbsc-select-empty-text {
      font-size: 1.5em;
      opacity: .6;
    }

    //   // select specific overrides
    //   &.mbsc-select-scroller {

    //     // Desktop styling overrides
    //     &.mbsc-scroller-pointer {

    //       .mbsc-scroller-wheel-group {
    //         padding: 0;
    //       }
    //     }
    //   }
  }

  @include mbsc-windows-select('windows', $mbsc-windows-colors);
  @include mbsc-windows-select('windows-dark', $mbsc-windows-dark-colors);
}


.mbsc-select-scroller .mbsc-scroller-wheel-wrapper {
  flex: 1 1 auto;
}

.mbsc-select-filter-cont {
  position: relative;
}

.mbsc-select-filter-clear {
  position: absolute;
  width: 1em;
  height: 1em;
  top: 50%;
  margin-top: -1em;
  padding: .5em;
  cursor: pointer;
  box-sizing: content-box;

  &.mbsc-rtl {
    left: .5em;
    right: auto;
  }

  &.mbsc-ltr {
    right: .5em;
    left: auto;
  }
}

.mbsc-select-empty .mbsc-scroller-wheel-group-cont {
  visibility: hidden;
}

.mbsc-select-empty-text {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mbsc-select-scroller.mbsc-scroller-pointer {
  .mbsc-scroller-wheel-item.mbsc-ltr {
    text-align: left;
  }

  .mbsc-scroller-wheel-item.mbsc-rtl {
    text-align: right;
  }
}
