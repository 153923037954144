@import '../../_variables.scss';

.comp-panoPlayer {
  height: 100%;
  width: 100%;

  .player-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    
    .s-spinner {
      top: 0;
      left: 0;
      color: black;
    }
  
    .loading-text {
      color: #717171;
    }
  }

  .panoPlayer-b {
    height: 100%;
    position: relative;
    background: #141414;

    .video-controls {
      display: none;
    }

    .full-button {
      position: absolute;
      z-index: 4;
      margin-left: 12px;
      margin-top: 12px;
      background: transparent;
      border: 0;
    }

    .panoPlayer-range {
      position: absolute;
      z-index: 4;
      right: 19px;
      bottom: 8px;
      background: #027bff;
      padding: 6px;
      border-radius: 6px;
    }
  }

  .panoPlayer-fullscreen {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 60px;

    .full-button {
      margin-left: 26px;
    }
  }
}

.controls-video {
  display: none !important;
}

.player-actionSave { 
  bottom: 0 !important;
  padding: 32px 120px 32px 0 !important;
  z-index: 1 !important;

  .staige-button {
    height: 26px !important;
  }
}

.player-action {
  display: flex;
  position: absolute;
  z-index: 333;
  right: 0;
  padding: 32px;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 16px;
  pointer-events: auto;

  .action-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
    align-items: center;

    color: var(--Neutral-N400, #999);
    text-align: right;
    /* Paragraph/P-XXS - Medium */
    font-family: Nekst;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 140% */
  }

  .btn-fullscreen {
    display: flex;
    padding: 7px;
    justify-content: center;
    align-items: center;
    border: 0;
    background: transparent;
  
    svg {
      width: 18px;
      height: 18px;
    }
  }
  
  .btn-fullscreen:hover {
    background-color: #ffffff29;
  }
}


.dot-active {
  width: 6px !important;
  height: 6px !important;
}

.ui-controls {
  z-index: 2 !important;
}


@media screen and (max-width: 640px) {
  .player-action {
    padding: 8px !important;
  }
}