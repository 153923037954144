@import '../_variables.scss';

.comp-timeContainer {
  display: flex;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  background: var(--Neutral-N700, #201D20);
  
  .timeContainer-head {
    display: flex;
    align-items: center;
    align-self: stretch;
    
    .staige-button  {
      height: 16px;
    }

    .head-text {
      flex: 1 0 0;
      color: var(--Neutral-N100, #F6F6F6);
      /* Paragraph/P-XS - Medium */
      font-family: Nekst;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16.8px; /* 140% */
    }
  }

  .timeContainer-progress {
    display: flex;
    padding: 8px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;

    .progress-bar {
      height: 4px;
      align-self: stretch;
      border-radius: 10px;
      background: var(--Neutral-N50, rgba(246, 246, 246, 0.16));

      .bar-progress {
        //width: 40px;
        height: 4px;
        // position: absolute;
        top: 8px;
        border-radius: 10px;
        background: var(--Yellow-Caution-YC300, #FCF197);
      }
    }
  }

  .timeContainer-time {
    display: flex;
    justify-content: flex-end;
    flex-wrap: nowrap;

    align-self: stretch;

    text-align: right;
    

    .time-a {
      color: var(--Neutral-N100, #F6F6F6);
      font-family: Nekst;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16.8px; /* 140% */
      font-family: Nekst;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16.8px;
    }

    .time-b {
      color: var(--Neutral-N400, #999);
      font-family: Nekst;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16.8px; /* 140% */
      font-family: Nekst;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16.8px;
    }
  }
}

.comp-timeContainer-warn {
  border: 1px solid var(--Red-R400, #D14343);
  background: var(--Red-R100, rgba(209, 67, 67, 0.32));

  .timeContainer-progress {
    .progress-bar {
      background: var(--Neutral-N50, rgba(246, 246, 246, 0.16));

      .bar-progress {
        background: var(--Red-R400, #D14343);
      }
    }
  }

  .timeContainer-time {
    .time-a {
      color: var(--Red-R400, #D14343);
    }

    .time-b {
      color: var(--Neutral-N100, #F6F6F6);
    }
  }
}

/*@media screen and (min-width: $breakpoints-mobile) {
  
}

@media screen and (max-width: $breakpoints-mobile) {
  
}*/