.home {
  a[aria-disabled='true'] {
    li {
      color: #151315;
      cursor: not-allowed;
    }
  }
}

.squareWrapper {
  .square {
    margin: 4px 4px 4px 0px !important;
  }
}


.squareWrapper:nth-child(2n) {
  .square {
    margin: 4px 0px 4px 4px !important;
  }
}

.square {
  // z-index: 1000;
  position: relative;
  max-height: 170px;
  z-index: 0;

  .content {
    background-color: #2e2e2e;
    position: absolute;
    width: 100%;
    height: 100%;
  
    .innerContent {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
  
      img {
        padding-bottom: 12px;
        height: 3em;
      }
  
      .menuText {
        font-family: 'DegularText-Bold';
        white-space:nowrap;
        color: #f7f7f7;
        font-size: 18px;
      }
    }
  }

  .activeContent {
    background-color: #f7f7f7;
    // color: #FF3517 !important;

    .innerContent {
      color: #FF3517;
      // filter: invert(90%) sepia(76%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(102%);
      filter: invert(90%) sepia(76%) saturate(0%) hue-rotate(0deg) brightness(0%) contrast(102%);
    }
  }
}

.square:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}