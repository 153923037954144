
.eventList-container {
  display: flex;
  gap: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;

  .container-eventMenu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    div {
      width: 100%;
      position: relative;
    }
  
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1 0 0;
      text-decoration: none !important;
  
      display: flex;
      padding: 10px 16px;
      justify-content: center;
      align-items: center;

      color: var(--neutral-n-100, #F6F6F6);
      /* Tab/Tag M */
      font-size: 14px;
      font-family: Nekst;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      letter-spacing: 0.4px;
      text-transform: uppercase;
      text-decoration: none;
      cursor: pointer;

      span {
        position: absolute;
        right: 0;
        top: 0px;
        font-size: 10px;
        color: #676767;    
      }
    }
  
    .active {
      border-bottom: 2px solid var(--neutral-n-100, #F6F6F6);
      cursor: unset;
    }
  }

  .container-eventList {
    position: relative;
    height: 100%;

    .eventList-wrapper {
      height: 100%;
      width: 100%;
      position: absolute;
      overflow: auto; 

      .aa {
        display: flex;
        padding: 0 0 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;

        .ssss {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 8px;
          align-self: stretch;
        }
      }

      .eventList-box {
        display: flex;
        gap: 4px;
        flex-direction: column;
      }
    }
  }
}