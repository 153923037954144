.checkList-container {
  .container-quickBar {
    display: flex;
    justify-content: flex-end;
    padding: 8px 0;
    align-items: center;
    min-height: 60px;

    .quickBar-title {
      flex-grow: 1;
      font-family: 'Nekst';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      color: #999999;
    }

    .quickBar-btn {
      display: flex;
      gap: 10px;

      .btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        width: 24px;
        height: 24px;
        border-radius: 2px;
        cursor: pointer;
  
        img {
          // margin-top: -1px;
        }
      }
  
      .btn-cancle:hover {
        background-color: rgba(210, 32, 32, 0.253);
      }
  
      .btn-allow:hover {
        background-color: rgba(255, 255, 255, 0.253);
      }

      .circleLoader {
        height: 0;

        .circle-loader {
          height: 24px;
          width: 24px;
        }
      }
    }
  }

  .container-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: auto;

    .event-btnBox {
      display: flex;
      flex-direction: row;
      gap: 10px;
    
      .btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        width: 32px;
        height: 32px;
        border: 1px solid #F6F6F6;
        border-radius: 100px;
        cursor: pointer;
      }
    
      .btn:hover {
        background-color: #f6f6f643;        
      }
    }
  }
}

