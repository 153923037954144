
.teamSideChoise {
  display: flex;
  padding-top: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  .teamSideChoise-header {
    display: flex;
    align-items: center;
    gap: 8px;

    span {
      color: var(--Neutral-N100, #F6F6F6);
      /* Paragraph/P-S - Medium */
      font-family: Nekst, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 19.6px; /* 140% */
    }

    svg {
      fill: var(--Neutral-N100, #F6F6F6);
    }
  }

  .teamSideChoise-body {
    display: flex;
    width: 118px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
}