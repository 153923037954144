.staige-dropdown {
  .staige-button 
    .small {
      padding: 2px 8px;

      .label {
        color: var(--neutral-n-100, #F6F6F6);
        font-family: Nekst;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 19.6px;
        text-transform: unset;
      }

      img {
      width: 20px;
      height: 20px;
    }
  }
  
  .btn-tag {
    z-index: 100;
    display: flex;
    padding-left: 8px;
    align-items: center;
    gap: 4px;
    border-radius: 100px;
    background: var(--Neutral-N100, #F6F6F6);

    .tag-label {
      display: flex;
      padding: 2px 0px 4px 0px;
      justify-content: center;
      align-items: center;
      color: var(--Neutral-N800, #151315);
      text-align: center;
      /* Tags & Badges/Tag S */
      font-family: Nekst;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 10px */
    }

    .tag-close {
      display: flex;
      align-items: flex-start;
      display: flex;
      padding: 3px;
      justify-content: center;
      align-items: center;
      border-radius: 100px;   
      cursor: pointer;
    }
    
    .tag-close:hover {
      background-color: #cfcfcf;   
    }
  }

  .dropdown-container {
    position: absolute;
    background: #201d20;
    display: flex;
    // padding: 6px;
    // gap: 8px;
    flex-direction: column;
    border: 1px solid #363436;

    .container-tab {
      padding: 6px 16px;
      display: flex;
      flex-direction: row;
      // justify-content: space-between;
      cursor: pointer;
      align-items: center;
      min-width: 154px;
    
      input {
        accent-color: #fff;
        background-blend-mode: multiply;
        font-size: 2px;
      }

      .tab-active {
        width: 16px;
        height: 16px;
        margin: 0px 10px 0px 0px;

        border-radius: 100%;
        display: flex;
        justify-content: center;
      }

      .tap-text {
        flex: 1 0 0;
        color: var(--Neutral-N100, #F6F6F6);
        /* Paragraph/P-S - Regular */
        font-family: Nekst;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 19.6px; /* 140% */
      }
    }

    .container-tab:hover {
      background: #363436;
    }
  }
}