@import '../../_variables.scss';

.page-cameraList {
  display: flex;
  padding: 24px 0px 20px;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;

  .cameraList-noAllow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    flex: 1 0 0;
    align-self: stretch;

    .noAllow-text {
      color: var(--neutral-n-100, #F6F6F6);
      text-align: center;
      /* Paragraph/P-M - Regular */
      font-family: Nekst;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22.4px; /* 140% */
    }
  }

  .cameraList-cameraList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;

    .cameraList-action {
      display: flex;
      padding-bottom: 0px;
      justify-content: flex-end;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      border-bottom: 1px solid var(--neutral-n-600, #363436);
      padding-bottom: 16px;
    }

    .cameraList-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;

      .content-cameraItem:hover {
        background: var(--neutral-n-600, #363436);

        .cameraItem-img {
          background: var(--neutral-n-500, #525052);
        }
      }

      .content-cameraItem {
        display: flex;
        width: 100%;
        height: 136px;
        padding: 16px 32px 16px 16px;
        align-items: center;
        gap: 24px;
        border-radius: 4px;
        background: var(--neutral-n-700, #201D20);
        cursor: pointer;
        text-decoration: none;

        .cameraItem-img2 {
          width: 160px;
        }

        .cameraItem-img {
          display: flex;
          width: 160px;
          padding: 12px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          align-self: stretch;
          background: var(--neutral-n-600, #363436);

          position: absolute;

          .saa {
            overflow: hidden;
            display: flex;
            justify-content: center;
            margin: -12px;
            opacity: 0.2;
          }

          .xx {
            height: 100%;
            width: 100%;
            position: absolute;

            img {
              height: 100%;
              width: 100%;
              object-fit: contain;
              padding: 12px;
            }
          }
          
        }

        .cameraItem-content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          flex: 1 0 0;
          align-self: stretch;

          .content-id {
            display: flex;
            align-items: flex-start;
            gap: 8px;

            .id-text {
              color: var(--neutral-n-500, #525052);
              /* Overline/O01 */
              font-family: Nekst;
              font-size: 10px;
              font-style: normal;
              font-weight: 600;
              line-height: 14px; /* 140% */
              letter-spacing: 0.3px;
              text-transform: uppercase;
            }
          }

          .content-info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
            align-self: stretch;

            .info-version {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              align-self: stretch;
              overflow: hidden;
              color: var(--neutral-n-100, #F6F6F6);
              text-overflow: ellipsis;
              /* Paragraph/P-L - Medium */
              font-family: Nekst;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 25.2px; /* 140% */
            }

            .info-place {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              align-self: stretch;
              overflow: hidden;
              color: var(--neutral-n-400, #999);
              text-overflow: ellipsis;
              /* Paragraph/P-S - Regular */
              font-family: Nekst;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 19.6px; /* 140% */
            }
          }

          .content-field {
            display: flex;
            padding: 4px;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            background: var(--neutral-n-50, rgba(246, 246, 246, 0.16));
            
            .field-text {
              color: var(--neutral-n-100, #F6F6F6);
              font-family: Nekst;
              font-size: 10px;
              font-style: normal;
              font-weight: 600;
              line-height: 14px; /* 140% */
              letter-spacing: 0.3px;
              text-transform: uppercase;
            }
          }
        }

        .cameraItem-action {
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoints-mobile) {
  .page-cameraList {
    .cameraList-cameraList {
      .cameraList-content {
        .content-cameraItem {
          flex-direction: column;
          height: 100%;
          padding: 0;

          .cameraItem-img2 {
            display: none;
          }

          .cameraItem-img {
            width: unset;
            border-radius: 4px 4px 0 0;
            position: relative;
            padding: 0;

            .saa {
              margin: 0;
            }
          }

          .cameraItem-action {
            display: none;
          }
        }

        .content-cameraItem {
          .cameraItem-content {
            padding: 0px 12px 12px 12px;

            .content-info {
              .info-version {
                padding: 10px 0 0;
              }
              .info-place {
                -webkit-box-orient: unset;
                padding: 8px 0;
              }
            }
         }
        }
      }
    }
  }
}