// select component
$modules: () !default;

@mixin exports($name) {
  @if (not index($modules, $name)) {
    $modules: append($modules, $name) !global;
    @content;
  }
}




// Button color variables

$mbsc-button-color-light: null !default;
$mbsc-button-color-dark: null !default;
$mbsc-button-text-light: null !default;
$mbsc-button-text-dark: null !default;

// Forms color variables

$mbsc-form-background-light: null !default;
$mbsc-form-background-dark: null !default;
$mbsc-form-text-light: null !default;
$mbsc-form-text-dark: null !default;
$mbsc-form-accent-light: null !default;
$mbsc-form-accent-dark: null !default;

$mbsc-form-group-title-text-light: null !default;
$mbsc-form-group-title-text-dark: null !default;

// Input color variables

$mbsc-input-background-light: null !default;
$mbsc-input-background-dark: null !default;
$mbsc-input-border-light: null !default;
$mbsc-input-border-dark: null !default;
$mbsc-input-text-light: null !default;
$mbsc-input-text-dark: null !default;
$mbsc-input-accent-light: null !default;
$mbsc-input-accent-dark: null !default;
$mbsc-input-error-light: null !default;
$mbsc-input-error-dark: null !default;

// Navigation color variables

$mbsc-nav-background-light: null !default;
$mbsc-nav-background-dark: null !default;
$mbsc-nav-text-light: null !default;
$mbsc-nav-text-dark: null !default;
$mbsc-nav-accent-light: null !default;
$mbsc-nav-accent-dark: null !default;

// Card color variables

$mbsc-card-background-light: null !default;
$mbsc-card-background-dark: null !default;
$mbsc-card-text-light: null !default;
$mbsc-card-text-dark: null !default;

// Page color variables

$mbsc-page-background-light: null !default;
$mbsc-page-background-dark: null !default;
$mbsc-page-text-light: null !default;
$mbsc-page-text-dark: null !default;

// Listview color variables

$mbsc-listview-background-light: null !default;
$mbsc-listview-background-dark: null !default;
$mbsc-listview-text-light: null !default;
$mbsc-listview-text-dark: null !default;
$mbsc-listview-accent-light: null !default;
$mbsc-listview-accent-dark: null !default;
$mbsc-listview-header-background-light: null !default;
$mbsc-listview-header-background-dark: null !default;
$mbsc-listview-header-text-light: null !default;
$mbsc-listview-header-text-dark: null !default;

// Calendar/Range/Eventcalendar color variables

$mbsc-calendar-mark-light: null !default;
$mbsc-calendar-mark-dark: null !default;

// Frame color variables

$mbsc-frame-background-light: null !default;
$mbsc-frame-background-dark: null !default;
$mbsc-frame-text-light: null !default;
$mbsc-frame-text-dark: null !default;
$mbsc-frame-accent-light: null !default;
$mbsc-frame-accent-dark: null !default;
$mbsc-frame-overlay-light: null !default;
$mbsc-frame-overlay-dark: null !default;

@function get-contrast-color($color) {
  @if (lightness($color) > 65%) {
    @return #000;
  }

  @else {
    @return #fff;
  }
}


$mbsc-mobiscroll-accent: #4eccc4 !default;
$mbsc-mobiscroll-background: #f7f7f7 !default;
$mbsc-mobiscroll-text: #454545 !default;

$mbsc-mobiscroll-dark-accent: #FF3517 !default;
$mbsc-mobiscroll-dark-background: #121212 !default;
$mbsc-mobiscroll-dark-text: #f7f7f7 !default;

/* Base colors */
$mbsc-mobiscroll-primary: #3f97f6 !default;
$mbsc-mobiscroll-secondary: #90979E !default;
$mbsc-mobiscroll-success: #3f97f6 !default;
$mbsc-mobiscroll-danger: #f5504e !default;
$mbsc-mobiscroll-warning: #f8b042 !default;
$mbsc-mobiscroll-info: #5BB7C5 !default;
$mbsc-mobiscroll-light: darken(#fff, 10%) !default;
$mbsc-mobiscroll-dark: #47494A !default;

$mbsc-mobiscroll-colors: ( // Colors map
  'background': $mbsc-mobiscroll-background,
  'text': $mbsc-mobiscroll-text,
  'accent': $mbsc-mobiscroll-accent,
);
$mbsc-mobiscroll-dark-colors: ( // Colors map
  'background': $mbsc-mobiscroll-dark-background,
  'text': $mbsc-mobiscroll-dark-text,
  'accent': $mbsc-mobiscroll-dark-accent,
);

@function mbsc-mobiscroll-colors($params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $border: '';
  $empty-color: '';
  $input-disabled: '';
  $input-box: '';
  $tooltip: '';
  $empty-color: '';

  // Light background
  @if (lightness($background) > 50%) {
    $border: darken($background, 17%);
    $empty-color: lighten($text, 20%);
    $input-disabled: darken($background, 13%);
    $input-box: darken($background, 10%);
    $tooltip: darken($background, 4%);
  }

  // Dark background
  @else {
    $border: lighten($background, 17%);
    $empty-color: $text;
    $input-disabled: lighten($background, 17%);
    $input-box: lighten($background, 10%);
    $tooltip: $background;
  }

  @return ('border': $border,
  'empty-color': $empty-color,
  'input-disabled': $input-disabled,
  'input-box': $input-box,
  'tooltip': $tooltip,
  /* static colors */
  'textarea': #1f1f1f,
  'notification': #787878,
  'white-text': #ffffff,
  'error': #de3226,
  'dark-text': #000);
}

@mixin mbsc-mobiscroll-common($theme, $params) {
  @include exports("common.#{$theme}.colors") {
    $colors: mbsc-mobiscroll-colors($params);
    $empty-color: map-get($colors, empty-color);

    .mbsc-#{$theme} {
      .mbsc-empty {
        color: $empty-color;
      }
    }
  }
}


// Theme specific variables - inherited from global variables

// Background
$mbsc-mobiscroll-frame-background: $mbsc-frame-background-light !default;
$mbsc-mobiscroll-dark-frame-background: $mbsc-frame-background-dark !default;
// Text
$mbsc-mobiscroll-frame-text: $mbsc-frame-text-light !default;
$mbsc-mobiscroll-dark-frame-text: $mbsc-frame-text-dark !default;
// Accent
$mbsc-mobiscroll-frame-accent: $mbsc-frame-accent-light !default;
$mbsc-mobiscroll-dark-frame-accent: $mbsc-frame-accent-dark !default;
// Overlay
$mbsc-mobiscroll-frame-overlay: $mbsc-frame-overlay-light !default;
$mbsc-mobiscroll-dark-frame-overlay: $mbsc-frame-overlay-dark !default;

$mbsc-mobiscroll-colors: map-merge($mbsc-mobiscroll-colors, (
  'frame-background': $mbsc-mobiscroll-frame-background,
  'frame-text': $mbsc-mobiscroll-frame-text,
  'frame-accent': $mbsc-mobiscroll-frame-accent,
  'frame-overlay': $mbsc-mobiscroll-frame-overlay,
));

$mbsc-mobiscroll-dark-colors: map-merge($mbsc-mobiscroll-dark-colors, (
  'frame-background': $mbsc-mobiscroll-dark-frame-background,
  'frame-text': $mbsc-mobiscroll-dark-frame-text,
  'frame-accent': $mbsc-mobiscroll-dark-frame-accent,
  'frame-overlay': $mbsc-mobiscroll-dark-frame-overlay,
));

@mixin mbsc-mobiscroll-frame($theme, $params) {
  @include exports("frame.#{$theme}.colors") {

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    $accent: map-get($params, 'accent');

    $overlay-param: map-get($params, 'frame-overlay');
    $background-param: map-get($params, 'frame-background');
    $text-param: map-get($params, 'frame-text');
    $accent-param: map-get($params, 'frame-accent');

    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
    $accent: if($accent-param, $accent-param, $accent);
    $overlay: if($overlay-param, $overlay-param, rgba(0, 0, 0, .7));

    .mbsc-#{$theme} {
      .mbsc-fr-overlay {
        background: $overlay;
      }

      .mbsc-fr-w {
        background: $background;
        color: $text;
      }

      .mbsc-fr-hdr,
      .mbsc-fr-btn {
        color: $accent;
      }

      .mbsc-fr-btn.mbsc-active,
      &.mbsc-no-touch .mbsc-fr-btn-e:not(.mbsc-disabled):hover {
        background: rgba($accent, .3);
      }

      .mbsc-fr-arr {
        background: $background;
      }
    }
  }
}




@include exports("common") {
  .mbsc-cloak {
    visibility: hidden !important;
  }

  /* Empty view */

  .mbsc-empty {
    text-align: center;
    margin: 3em;
    color: inherit;
  }

  .mbsc-empty h3 {
    margin: .666666em 0;
    padding: 0;
    color: inherit;
    font-size: 1.5em;
    font-weight: normal;
    font-family: inherit;
  }

  .mbsc-empty p {
    margin: 1em 0;
    padding: 0;
    font-size: 1em;
    line-height: 1.5;
  }
}



@include exports("animation") {

  .mbsc-anim-trans .mbsc-fr-scroll {
    overflow: hidden;
  }

  .mbsc-anim-trans-flip .mbsc-fr-persp,
  .mbsc-anim-trans-swing .mbsc-fr-persp {
    -webkit-perspective: 1000px;
    perspective: 1000px;
  }

  .mbsc-anim-trans .mbsc-fr-popup,
  .mbsc-anim-trans .mbsc-fr-overlay {
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .mbsc-anim-in .mbsc-fr-popup,
  .mbsc-anim-in .mbsc-fr-overlay {
    -webkit-animation-timing-function: ease-out;
    -webkit-animation-duration: 225ms;
    animation-timing-function: ease-out;
    animation-duration: 225ms;
  }

  .mbsc-anim-out .mbsc-fr-popup,
  .mbsc-anim-out .mbsc-fr-overlay {
    -webkit-animation-timing-function: ease-in;
    -webkit-animation-duration: 195ms;
    animation-timing-function: ease-in;
    animation-duration: 195ms;
  }

  .mbsc-anim-in .mbsc-fr-overlay {
    -webkit-animation-name: mbsc-anim-f-in;
    animation-name: mbsc-anim-f-in;
  }

  .mbsc-anim-out .mbsc-fr-overlay {
    -webkit-animation-name: mbsc-anim-f-out;
    animation-name: mbsc-anim-f-out;
  }

  .mbsc-anim-flip,
  .mbsc-anim-swing,
  .mbsc-anim-slidehorizontal,
  .mbsc-anim-slidevertical,
  .mbsc-anim-slidedown,
  .mbsc-anim-slideup,
  .mbsc-anim-fade {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateX(0);
    backface-visibility: hidden;
    transform: translateX(0);
  }

  .mbsc-anim-swing,
  .mbsc-anim-slidehorizontal,
  .mbsc-anim-slidevertical,
  .mbsc-anim-slidedown,
  .mbsc-anim-slideup,
  .mbsc-anim-fade {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
  }

  .mbsc-anim-flip,
  .mbsc-anim-pop {
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }

  .mbsc-anim-in .mbsc-anim-pop {
    opacity: 1;
    -webkit-animation-name: mbsc-anim-p-in;
    -webkit-animation-duration: 100ms;
    -webkit-transform: scale(1);
    animation-name: mbsc-anim-p-in;
    animation-duration: 100ms;
    transform: scale(1);
  }

  .mbsc-anim-out .mbsc-anim-pop {
    opacity: 0;
    -webkit-animation-name: mbsc-anim-p-out;
    -webkit-animation-duration: 150ms;
    animation-name: mbsc-anim-p-out;
    animation-duration: 150ms;
  }

  .mbsc-anim-trans-pop .mbsc-fr-overlay {
    -webkit-animation-duration: 150ms;
    animation-duration: 150ms;
  }

  .mbsc-anim-in .mbsc-anim-flip {
    opacity: 1;
    -webkit-animation-name: mbsc-anim-fl-in;
    -webkit-transform: scale(1);
    animation-name: mbsc-anim-fl-in;
    transform: scale(1);
  }

  .mbsc-anim-out .mbsc-anim-flip {
    opacity: 0;
    -webkit-animation-name: mbsc-anim-fl-out;
    animation-name: mbsc-anim-fl-out;
  }

  .mbsc-anim-in .mbsc-anim-swing {
    opacity: 1;
    -webkit-animation-name: mbsc-anim-sw-in;
    -webkit-transform: scale(1);
    transform: scale(1);
    animation-name: mbsc-anim-sw-in;
  }

  .mbsc-anim-out .mbsc-anim-swing {
    opacity: 0;
    -webkit-animation-name: mbsc-anim-sw-out;
    animation-name: mbsc-anim-sw-out;
  }

  .mbsc-anim-in .mbsc-anim-slidehorizontal {
    opacity: 1;
    -webkit-animation-name: mbsc-anim-sh-in;
    -webkit-transform: scale(1);
    transform: scale(1);
    animation-name: mbsc-anim-sh-in;
  }

  .mbsc-anim-out .mbsc-anim-slidehorizontal {
    opacity: 0;
    -webkit-animation-name: mbsc-anim-sh-out;
    animation-name: mbsc-anim-sh-out;
  }

  .mbsc-anim-in .mbsc-anim-slidevertical {
    opacity: 1;
    -webkit-animation-name: mbsc-anim-sv-in;
    -webkit-transform: scale(1);
    animation-name: mbsc-anim-sv-in;
    transform: scale(1);
  }

  .mbsc-anim-out .mbsc-anim-slidevertical {
    opacity: 0;
    -webkit-animation-name: mbsc-anim-sv-out;
    animation-name: mbsc-anim-sv-out;
  }

  .mbsc-anim-in .mbsc-anim-slidedown {
    -webkit-animation-name: mbsc-anim-sd-in;
    -webkit-transform: scale(1);
    animation-name: mbsc-anim-sd-in;
    transform: scale(1);
  }

  .mbsc-anim-out .mbsc-anim-slidedown {
    -webkit-animation-name: mbsc-anim-sd-out;
    -webkit-transform: translateY(-100%);
    animation-name: mbsc-anim-sd-out;
  }

  .mbsc-anim-in .mbsc-anim-slideup {
    -webkit-animation-name: mbsc-anim-su-in;
    -webkit-transform: scale(1);
    transform: scale(1);
    animation-name: mbsc-anim-su-in;
  }

  .mbsc-anim-out .mbsc-anim-slideup {
    -webkit-animation-name: mbsc-anim-su-out;
    -webkit-transform: translateY(100%);
    animation-name: mbsc-anim-su-out;
  }

  .mbsc-anim-in .mbsc-anim-fade {
    opacity: 1;
    -webkit-animation-name: mbsc-anim-f-in;
    animation-name: mbsc-anim-f-in;
  }

  .mbsc-anim-out .mbsc-anim-fade {
    opacity: 0;
    -webkit-animation-name: mbsc-anim-f-out;
    animation-name: mbsc-anim-f-out;
  }

  .mbsc-fr-pointer {
    &.mbsc-anim-in .mbsc-anim-slidedown {
      -webkit-animation-name: mbsc-anim-sd-in, mbsc-anim-f-in;
      animation-name: mbsc-anim-sd-in, mbsc-anim-f-in;
    }

    &.mbsc-anim-out .mbsc-anim-slidedown {
      -webkit-animation-name: mbsc-anim-sd-out, mbsc-anim-f-out;
      animation-name: mbsc-anim-sd-out, mbsc-anim-f-out;
    }

    &.mbsc-anim-in .mbsc-anim-slideup {
      -webkit-animation-name: mbsc-anim-su-in, mbsc-anim-f-in;
      animation-name: mbsc-anim-su-in, mbsc-anim-f-in;
    }

    &.mbsc-anim-out .mbsc-anim-slideup {
      -webkit-animation-name: mbsc-anim-su-out, mbsc-anim-f-out;
      animation-name: mbsc-anim-su-out, mbsc-anim-f-out;
    }
  }

  /* Fade in */

  @keyframes mbsc-anim-f-in {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @-webkit-keyframes mbsc-anim-f-in {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }


  /* Fade out */

  @keyframes mbsc-anim-f-out {
    from {
      visibility: visible;
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

  @-webkit-keyframes mbsc-anim-f-out {
    from {
      visibility: visible;
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }


  /* Pop in */

  @keyframes mbsc-anim-p-in {
    from {
      opacity: 0;
      transform: scale(0.8);
    }

    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @-webkit-keyframes mbsc-anim-p-in {
    from {
      opacity: 0;
      -webkit-transform: scale(0.8);
    }

    to {
      opacity: 1;
      -webkit-transform: scale(1);
    }
  }


  /* Pop out */

  @keyframes mbsc-anim-p-out {
    from {
      opacity: 1;
      transform: scale(1);
    }

    to {
      opacity: 0;
      transform: scale(0.8);
    }
  }

  @-webkit-keyframes mbsc-anim-p-out {
    from {
      opacity: 1;
      -webkit-transform: scale(1);
    }

    to {
      opacity: 0;
      -webkit-transform: scale(0.8);
    }
  }


  /* Flip in */

  @keyframes mbsc-anim-fl-in {
    from {
      opacity: 0;
      transform: rotateY(90deg);
    }

    to {
      opacity: 1;
      transform: rotateY(0);
    }
  }

  @-webkit-keyframes mbsc-anim-fl-in {
    from {
      opacity: 0;
      -webkit-transform: rotateY(90deg);
    }

    to {
      opacity: 1;
      -webkit-transform: rotateY(0);
    }
  }


  /* Flip out */

  @keyframes mbsc-anim-fl-out {
    from {
      opacity: 1;
      transform: rotateY(0deg);
    }

    to {
      opacity: 0;
      transform: rotateY(-90deg);
    }
  }

  @-webkit-keyframes mbsc-anim-fl-out {
    from {
      opacity: 1;
      -webkit-transform: rotateY(0deg);
    }

    to {
      opacity: 0;
      -webkit-transform: rotateY(-90deg);
    }
  }


  /* Swing in */

  @keyframes mbsc-anim-sw-in {
    from {
      opacity: 0;
      transform: rotateY(-90deg);
    }

    to {
      opacity: 1;
      transform: rotateY(0deg);
    }
  }

  @-webkit-keyframes mbsc-anim-sw-in {
    from {
      opacity: 0;
      -webkit-transform: rotateY(-90deg);
    }

    to {
      opacity: 1;
      -webkit-transform: rotateY(0deg);
    }
  }


  /* Swing out */

  @keyframes mbsc-anim-sw-out {
    from {
      opacity: 1;
      transform: rotateY(0deg);
    }

    to {
      opacity: 0;
      transform: rotateY(-90deg);
    }
  }

  @-webkit-keyframes mbsc-anim-sw-out {
    from {
      opacity: 1;
      -webkit-transform: rotateY(0deg);
    }

    to {
      opacity: 0;
      -webkit-transform: rotateY(-90deg);
    }
  }


  /* Slide horizontal in */

  @keyframes mbsc-anim-sh-in {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @-webkit-keyframes mbsc-anim-sh-in {
    from {
      opacity: 0;
      -webkit-transform: translateX(-100%);
    }

    to {
      opacity: 1;
      -webkit-transform: translateX(0);
    }
  }


  /* Slide horizontal out */

  @keyframes mbsc-anim-sh-out {
    from {
      opacity: 1;
      transform: translateX(0);
    }

    to {
      opacity: 0;
      transform: translateX(100%);
    }
  }

  @-webkit-keyframes mbsc-anim-sh-out {
    from {
      opacity: 1;
      -webkit-transform: translateX(0);
    }

    to {
      opacity: 0;
      -webkit-transform: translateX(100%);
    }
  }


  /* Slide vertical in */

  @keyframes mbsc-anim-sv-in {
    from {
      opacity: 0;
      transform: translateY(-100%);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @-webkit-keyframes mbsc-anim-sv-in {
    from {
      opacity: 0;
      -webkit-transform: translateY(-100%);
    }

    to {
      opacity: 1;
      -webkit-transform: translateY(0);
    }
  }


  /* Slide vertical out */

  @keyframes mbsc-anim-sv-out {
    from {
      opacity: 1;
      transform: translateY(0);
    }

    to {
      opacity: 0;
      transform: translateY(100%);
    }
  }

  @-webkit-keyframes mbsc-anim-sv-out {
    from {
      opacity: 1;
      -webkit-transform: translateY(0);
    }

    to {
      opacity: 0;
      -webkit-transform: translateY(100%);
    }
  }


  /* Slide Down In */

  @keyframes mbsc-anim-sd-in {
    from {
      transform: translateY(-100%);
    }

    to {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes mbsc-anim-sd-in {
    from {
      opacity: 1;
      -webkit-transform: translateY(-100%);
    }

    to {
      opacity: 1;
      -webkit-transform: translateY(0);
    }
  }


  /* Slide down out */

  @keyframes mbsc-anim-sd-out {
    from {
      transform: translateY(0);
    }

    to {
      transform: translateY(-100%);
    }
  }

  @-webkit-keyframes mbsc-anim-sd-out {
    from {
      opacity: 1;
      -webkit-transform: translateY(0);
    }

    to {
      opacity: 1;
      -webkit-transform: translateY(-100%);
    }
  }


  /* Slide Up In */

  @keyframes mbsc-anim-su-in {
    from {
      transform: translateY(100%);
    }

    to {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes mbsc-anim-su-in {
    from {
      opacity: 1;
      -webkit-transform: translateY(100%);
    }

    to {
      opacity: 1;
      -webkit-transform: translateY(0);
    }
  }


  /* Slide up out */

  @keyframes mbsc-anim-su-out {
    from {
      transform: translateY(0);
    }

    to {
      transform: translateY(100%);
    }
  }

  @-webkit-keyframes mbsc-anim-su-out {
    from {
      opacity: 1;
      -webkit-transform: translateY(0);
    }

    to {
      opacity: 1;
      -webkit-transform: translateY(100%);
    }
  }
}



$mbsc-font-path: '' !default;

@include exports("icons") {

    @font-face {
        font-family: 'icons_mobiscroll';
        src:
        url($mbsc-font-path + 'icons_mobiscroll.woff?akmyhn') format('woff'),
        url($mbsc-font-path + 'icons_mobiscroll.woff') format('woff'),
        url($mbsc-font-path + 'icons_mobiscroll.ttf?akmyhn') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    .mbsc-ic:before {
        font-family: 'icons_mobiscroll';
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

/* Icons */

.mbsc-ic-IE::before {
  content: "\ea01";
}
.mbsc-ic-IcoMoon::before {
  content: "\ea02";
}
.mbsc-ic-accessibility::before {
  content: "\ea03";
}
.mbsc-ic-address-book::before {
  content: "\ea04";
}
.mbsc-ic-aid::before {
  content: "\ea05";
}
.mbsc-ic-airplane::before {
  content: "\ea06";
}
.mbsc-ic-alarm::before {
  content: "\ea07";
}
.mbsc-ic-alarm2::before {
  content: "\ea08";
}
.mbsc-ic-android::before {
  content: "\ea09";
}
.mbsc-ic-angry::before {
  content: "\ea0a";
}
.mbsc-ic-angry2::before {
  content: "\ea0b";
}
.mbsc-ic-apple::before {
  content: "\ea0c";
}
.mbsc-ic-arrow-down-left::before {
  content: "\ea0d";
}
.mbsc-ic-arrow-down-left2::before {
  content: "\ea0e";
}
.mbsc-ic-arrow-down-left3::before {
  content: "\ea0f";
}
.mbsc-ic-arrow-down-right::before {
  content: "\ea10";
}
.mbsc-ic-arrow-down-right2::before {
  content: "\ea11";
}
.mbsc-ic-arrow-down-right3::before {
  content: "\ea12";
}
.mbsc-ic-arrow-down::before {
  content: "\ea13";
}
.mbsc-ic-arrow-down2::before {
  content: "\ea14";
}
.mbsc-ic-arrow-down3::before {
  content: "\ea15";
}
.mbsc-ic-arrow-down5::before {
  content: "\ea16";
}
.mbsc-ic-arrow-left::before {
  content: "\ea17";
}
.mbsc-ic-arrow-left2::before {
  content: "\ea18";
}
.mbsc-ic-arrow-left3::before {
  content: "\ea19";
}
.mbsc-ic-arrow-left5::before {
  content: "\ea1a";
}
.mbsc-ic-arrow-left6::before {
  content: "\ea1b";
}
.mbsc-ic-arrow-right::before {
  content: "\ea1c";
}
.mbsc-ic-arrow-right2::before {
  content: "\ea1d";
}
.mbsc-ic-arrow-right3::before {
  content: "\ea1e";
}
.mbsc-ic-arrow-right5::before {
  content: "\ea1f";
}
.mbsc-ic-arrow-right6::before {
  content: "\ea20";
}
.mbsc-ic-arrow-up-left::before {
  content: "\ea21";
}
.mbsc-ic-arrow-up-left2::before {
  content: "\ea22";
}
.mbsc-ic-arrow-up-left3::before {
  content: "\ea23";
}
.mbsc-ic-arrow-up-right::before {
  content: "\ea24";
}
.mbsc-ic-arrow-up-right2::before {
  content: "\ea25";
}
.mbsc-ic-arrow-up-right3::before {
  content: "\ea26";
}
.mbsc-ic-arrow-up::before {
  content: "\ea27";
}
.mbsc-ic-arrow-up2::before {
  content: "\ea28";
}
.mbsc-ic-arrow-up3::before {
  content: "\ea29";
}
.mbsc-ic-arrow-up5::before {
  content: "\ea2a";
}
.mbsc-ic-attachment::before {
  content: "\ea2b";
}
.mbsc-ic-backward::before {
  content: "\ea2c";
}
.mbsc-ic-backward2::before {
  content: "\ea2d";
}
.mbsc-ic-barcode::before {
  content: "\ea2e";
}
.mbsc-ic-bars::before {
  content: "\ea2f";
}
.mbsc-ic-bars2::before {
  content: "\ea30";
}
.mbsc-ic-bell::before {
  content: "\ea31";
}
.mbsc-ic-binoculars::before {
  content: "\ea32";
}
.mbsc-ic-blocked::before {
  content: "\ea33";
}
.mbsc-ic-blog::before {
  content: "\ea34";
}
.mbsc-ic-blogger::before {
  content: "\ea35";
}
.mbsc-ic-blogger2::before {
  content: "\ea36";
}
.mbsc-ic-bold::before {
  content: "\ea37";
}
.mbsc-ic-book::before {
  content: "\ea38";
}
.mbsc-ic-bookmark::before {
  content: "\ea39";
}
.mbsc-ic-bookmarks::before {
  content: "\ea3a";
}
.mbsc-ic-books::before {
  content: "\ea3b";
}
.mbsc-ic-box-add::before {
  content: "\ea3c";
}
.mbsc-ic-box-remove::before {
  content: "\ea3d";
}
.mbsc-ic-briefcase::before {
  content: "\ea3e";
}
.mbsc-ic-brightness-contrast::before {
  content: "\ea3f";
}
.mbsc-ic-brightness-medium::before {
  content: "\ea40";
}
.mbsc-ic-bubble::before {
  content: "\ea41";
}
.mbsc-ic-bubble2::before {
  content: "\ea42";
}
.mbsc-ic-bubbles::before {
  content: "\ea43";
}
.mbsc-ic-bubbles2::before {
  content: "\ea44";
}
.mbsc-ic-bubbles3::before {
  content: "\ea45";
}
.mbsc-ic-bubbles4::before {
  content: "\ea46";
}
.mbsc-ic-bug::before {
  content: "\ea47";
}
.mbsc-ic-bullhorn::before {
  content: "\ea48";
}
.mbsc-ic-busy::before {
  content: "\ea49";
}
.mbsc-ic-cabinet::before {
  content: "\ea4a";
}
.mbsc-ic-calculate::before {
  content: "\ea4b";
}
.mbsc-ic-calendar::before {
  content: "\ea4c";
}
.mbsc-ic-calendar2::before {
  content: "\ea4d";
}
.mbsc-ic-camera::before {
  content: "\ea4e";
}
.mbsc-ic-camera2::before {
  content: "\ea4f";
}
.mbsc-ic-cancel-circle::before {
  content: "\ea50";
}
.mbsc-ic-cart::before {
  content: "\ea51";
}
.mbsc-ic-cart2::before {
  content: "\ea52";
}
.mbsc-ic-cart3::before {
  content: "\ea53";
}
.mbsc-ic-checkbox-checked::before {
  content: "\ea54";
}
.mbsc-ic-checkbox-partial::before {
  content: "\ea55";
}
.mbsc-ic-checkbox-unchecked::before {
  content: "\ea56";
}
.mbsc-ic-checkmark-circle::before {
  content: "\ea57";
}
.mbsc-ic-checkmark::before {
  content: "\ea58";
}
.mbsc-ic-checkmark2::before {
  content: "\ea59";
}
.mbsc-ic-chrome::before {
  content: "\ea5a";
}
.mbsc-ic-clock::before {
  content: "\ea5b";
}
.mbsc-ic-clock2::before {
  content: "\ea5c";
}
.mbsc-ic-close::before {
  content: "\ea5d";
}
.mbsc-ic-cloud-download::before {
  content: "\ea5e";
}
.mbsc-ic-cloud-upload::before {
  content: "\ea5f";
}
.mbsc-ic-cloud::before {
  content: "\ea60";
}
.mbsc-ic-clubs::before {
  content: "\ea61";
}
.mbsc-ic-code::before {
  content: "\ea62";
}
.mbsc-ic-cog::before {
  content: "\ea63";
}
.mbsc-ic-cog2::before {
  content: "\ea64";
}
.mbsc-ic-cogs::before {
  content: "\ea65";
}
.mbsc-ic-coin::before {
  content: "\ea66";
}
.mbsc-ic-compass::before {
  content: "\ea67";
}
.mbsc-ic-confused::before {
  content: "\ea68";
}
.mbsc-ic-confused2::before {
  content: "\ea69";
}
.mbsc-ic-connection::before {
  content: "\ea6a";
}
.mbsc-ic-console::before {
  content: "\ea6b";
}
.mbsc-ic-contract::before {
  content: "\ea6c";
}
.mbsc-ic-contract2::before {
  content: "\ea6d";
}
.mbsc-ic-contrast::before {
  content: "\ea6e";
}
.mbsc-ic-cool::before {
  content: "\ea6f";
}
.mbsc-ic-cool2::before {
  content: "\ea70";
}
.mbsc-ic-copy::before {
  content: "\ea71";
}
.mbsc-ic-copy2::before {
  content: "\ea72";
}
.mbsc-ic-copy3::before {
  content: "\ea73";
}
.mbsc-ic-credit::before {
  content: "\ea74";
}
.mbsc-ic-crop::before {
  content: "\ea75";
}
.mbsc-ic-css3::before {
  content: "\ea76";
}
.mbsc-ic-dashboard::before {
  content: "\ea77";
}
.mbsc-ic-delicious::before {
  content: "\ea78";
}
.mbsc-ic-deviantart::before {
  content: "\ea79";
}
.mbsc-ic-deviantart2::before {
  content: "\ea7a";
}
.mbsc-ic-diamonds::before {
  content: "\ea7b";
}
.mbsc-ic-dice::before {
  content: "\ea7c";
}
.mbsc-ic-disk::before {
  content: "\ea7d";
}
.mbsc-ic-download::before {
  content: "\ea7e";
}
.mbsc-ic-download2::before {
  content: "\ea7f";
}
.mbsc-ic-download3::before {
  content: "\ea80";
}
.mbsc-ic-drawer::before {
  content: "\ea81";
}
.mbsc-ic-drawer2::before {
  content: "\ea82";
}
.mbsc-ic-drawer3::before {
  content: "\ea83";
}
.mbsc-ic-dribbble::before {
  content: "\ea84";
}
.mbsc-ic-dribbble2::before {
  content: "\ea85";
}
.mbsc-ic-dribbble3::before {
  content: "\ea86";
}
.mbsc-ic-droplet::before {
  content: "\ea87";
}
.mbsc-ic-earth::before {
  content: "\ea88";
}
.mbsc-ic-eject::before {
  content: "\ea89";
}
.mbsc-ic-embed::before {
  content: "\ea8a";
}
.mbsc-ic-enter::before {
  content: "\ea8b";
}
.mbsc-ic-envelope::before {
  content: "\ea8c";
}
.mbsc-ic-equalizer::before {
  content: "\ea8d";
}
.mbsc-ic-evil::before {
  content: "\ea8e";
}
.mbsc-ic-evil2::before {
  content: "\ea8f";
}
.mbsc-ic-exit::before {
  content: "\ea90";
}
.mbsc-ic-expand::before {
  content: "\ea91";
}
.mbsc-ic-expand2::before {
  content: "\ea92";
}
.mbsc-ic-eye-blocked::before {
  content: "\ea93";
}
.mbsc-ic-eye::before {
  content: "\ea94";
}
.mbsc-ic-eye2::before {
  content: "\ea95";
}
.mbsc-ic-fa-500px::before {
  content: "\ea96";
}
.mbsc-ic-fa-address-book-o::before {
  content: "\ea97";
}
.mbsc-ic-fa-address-book::before {
  content: "\ea98";
}
.mbsc-ic-fa-address-card-o::before {
  content: "\ea99";
}
.mbsc-ic-fa-address-card::before {
  content: "\ea9a";
}
.mbsc-ic-fa-adjust::before {
  content: "\ea9b";
}
.mbsc-ic-fa-adn::before {
  content: "\ea9c";
}
.mbsc-ic-fa-align-center::before {
  content: "\ea9d";
}
.mbsc-ic-fa-align-justify::before {
  content: "\ea9e";
}
.mbsc-ic-fa-align-left::before {
  content: "\ea9f";
}
.mbsc-ic-fa-align-right::before {
  content: "\eaa0";
}
.mbsc-ic-fa-amazon::before {
  content: "\eaa1";
}
.mbsc-ic-fa-ambulance::before {
  content: "\eaa2";
}
.mbsc-ic-fa-american-sign-language-interpreting::before {
  content: "\eaa3";
}
.mbsc-ic-fa-anchor::before {
  content: "\eaa4";
}
.mbsc-ic-fa-android::before {
  content: "\eaa5";
}
.mbsc-ic-fa-angellist::before {
  content: "\eaa6";
}
.mbsc-ic-fa-angle-double-down::before {
  content: "\eaa7";
}
.mbsc-ic-fa-angle-double-left::before {
  content: "\eaa8";
}
.mbsc-ic-fa-angle-double-right::before {
  content: "\eaa9";
}
.mbsc-ic-fa-angle-double-up::before {
  content: "\eaaa";
}
.mbsc-ic-fa-angle-down::before {
  content: "\eaab";
}
.mbsc-ic-fa-angle-left::before {
  content: "\eaac";
}
.mbsc-ic-fa-angle-right::before {
  content: "\eaad";
}
.mbsc-ic-fa-angle-up::before {
  content: "\eaae";
}
.mbsc-ic-fa-apple::before {
  content: "\eaaf";
}
.mbsc-ic-fa-archive::before {
  content: "\eab0";
}
.mbsc-ic-fa-area-chart::before {
  content: "\eab1";
}
.mbsc-ic-fa-arrow-circle-down::before {
  content: "\eab2";
}
.mbsc-ic-fa-arrow-circle-left::before {
  content: "\eab3";
}
.mbsc-ic-fa-arrow-circle-o-down::before {
  content: "\eab4";
}
.mbsc-ic-fa-arrow-circle-o-left::before {
  content: "\eab5";
}
.mbsc-ic-fa-arrow-circle-o-right::before {
  content: "\eab6";
}
.mbsc-ic-fa-arrow-circle-o-up::before {
  content: "\eab7";
}
.mbsc-ic-fa-arrow-circle-right::before {
  content: "\eab8";
}
.mbsc-ic-fa-arrow-circle-up::before {
  content: "\eab9";
}
.mbsc-ic-fa-arrow-down::before {
  content: "\eaba";
}
.mbsc-ic-fa-arrow-left::before {
  content: "\eabb";
}
.mbsc-ic-fa-arrow-right::before {
  content: "\eabc";
}
.mbsc-ic-fa-arrow-up::before {
  content: "\eabd";
}
.mbsc-ic-fa-arrows-alt::before {
  content: "\eabe";
}
.mbsc-ic-fa-arrows-h::before {
  content: "\eabf";
}
.mbsc-ic-fa-arrows-v::before {
  content: "\eac0";
}
.mbsc-ic-fa-arrows::before {
  content: "\eac1";
}
.mbsc-ic-fa-assistive-listening-systems::before {
  content: "\eac2";
}
.mbsc-ic-fa-asterisk::before {
  content: "\eac3";
}
.mbsc-ic-fa-at::before {
  content: "\eac4";
}
.mbsc-ic-fa-audio-description::before {
  content: "\eac5";
}
.mbsc-ic-fa-automobile::before {
  content: "\eac6";
}
.mbsc-ic-fa-backward::before {
  content: "\eac7";
}
.mbsc-ic-fa-balance-scale::before {
  content: "\eac8";
}
.mbsc-ic-fa-ban::before {
  content: "\eac9";
}
.mbsc-ic-fa-bandcamp::before {
  content: "\eaca";
}
.mbsc-ic-fa-bank::before {
  content: "\eacb";
}
.mbsc-ic-fa-bar-chart-o::before {
  content: "\eacc";
}
.mbsc-ic-fa-bar-chart::before {
  content: "\eacd";
}
.mbsc-ic-fa-barcode::before {
  content: "\eace";
}
.mbsc-ic-fa-bars::before {
  content: "\eacf";
}
.mbsc-ic-fa-bath::before {
  content: "\ead0";
}
.mbsc-ic-fa-battery-0::before {
  content: "\ead1";
}
.mbsc-ic-fa-battery-1::before {
  content: "\ead2";
}
.mbsc-ic-fa-battery-2::before {
  content: "\ead3";
}
.mbsc-ic-fa-battery-3::before {
  content: "\ead4";
}
.mbsc-ic-fa-battery-4::before {
  content: "\ead5";
}
.mbsc-ic-fa-battery::before {
  content: "\ead6";
}
.mbsc-ic-fa-bed::before {
  content: "\ead7";
}
.mbsc-ic-fa-beer::before {
  content: "\ead8";
}
.mbsc-ic-fa-behance-square::before {
  content: "\ead9";
}
.mbsc-ic-fa-behance::before {
  content: "\eada";
}
.mbsc-ic-fa-bell-o::before {
  content: "\eadb";
}
.mbsc-ic-fa-bell-slash-o::before {
  content: "\eadc";
}
.mbsc-ic-fa-bell-slash::before {
  content: "\eadd";
}
.mbsc-ic-fa-bell::before {
  content: "\eade";
}
.mbsc-ic-fa-bicycle::before {
  content: "\eadf";
}
.mbsc-ic-fa-binoculars::before {
  content: "\eae0";
}
.mbsc-ic-fa-birthday-cake::before {
  content: "\eae1";
}
.mbsc-ic-fa-bitbucket-square::before {
  content: "\eae2";
}
.mbsc-ic-fa-bitbucket::before {
  content: "\eae3";
}
.mbsc-ic-fa-bitcoin::before {
  content: "\eae4";
}
.mbsc-ic-fa-black-tie::before {
  content: "\eae5";
}
.mbsc-ic-fa-blind::before {
  content: "\eae6";
}
.mbsc-ic-fa-bluetooth-b::before {
  content: "\eae7";
}
.mbsc-ic-fa-bluetooth::before {
  content: "\eae8";
}
.mbsc-ic-fa-bold::before {
  content: "\eae9";
}
.mbsc-ic-fa-bolt::before {
  content: "\eaea";
}
.mbsc-ic-fa-bomb::before {
  content: "\eaeb";
}
.mbsc-ic-fa-book::before {
  content: "\eaec";
}
.mbsc-ic-fa-bookmark-o::before {
  content: "\eaed";
}
.mbsc-ic-fa-bookmark::before {
  content: "\eaee";
}
.mbsc-ic-fa-braille::before {
  content: "\eaef";
}
.mbsc-ic-fa-briefcase::before {
  content: "\eaf0";
}
.mbsc-ic-fa-bug::before {
  content: "\eaf1";
}
.mbsc-ic-fa-building-o::before {
  content: "\eaf2";
}
.mbsc-ic-fa-building::before {
  content: "\eaf3";
}
.mbsc-ic-fa-bullhorn::before {
  content: "\eaf4";
}
.mbsc-ic-fa-bullseye::before {
  content: "\eaf5";
}
.mbsc-ic-fa-bus::before {
  content: "\eaf6";
}
.mbsc-ic-fa-buysellads::before {
  content: "\eaf7";
}
.mbsc-ic-fa-cab::before {
  content: "\eaf8";
}
.mbsc-ic-fa-calculator::before {
  content: "\eaf9";
}
.mbsc-ic-fa-calendar-check-o::before {
  content: "\eafa";
}
.mbsc-ic-fa-calendar-minus-o::before {
  content: "\eafb";
}
.mbsc-ic-fa-calendar-o::before {
  content: "\eafc";
}
.mbsc-ic-fa-calendar-plus-o::before {
  content: "\eafd";
}
.mbsc-ic-fa-calendar-times-o::before {
  content: "\eafe";
}
.mbsc-ic-fa-calendar::before {
  content: "\eaff";
}
.mbsc-ic-fa-camera-retro::before {
  content: "\eb00";
}
.mbsc-ic-fa-camera::before {
  content: "\eb01";
}
.mbsc-ic-fa-caret-down::before {
  content: "\eb02";
}
.mbsc-ic-fa-caret-left::before {
  content: "\eb03";
}
.mbsc-ic-fa-caret-right::before {
  content: "\eb04";
}
.mbsc-ic-fa-caret-square-o-down::before {
  content: "\eb05";
}
.mbsc-ic-fa-caret-square-o-left::before {
  content: "\eb06";
}
.mbsc-ic-fa-caret-square-o-right::before {
  content: "\eb07";
}
.mbsc-ic-fa-caret-square-o-up::before {
  content: "\eb08";
}
.mbsc-ic-fa-caret-up::before {
  content: "\eb09";
}
.mbsc-ic-fa-cart-arrow-down::before {
  content: "\eb0a";
}
.mbsc-ic-fa-cart-plus::before {
  content: "\eb0b";
}
.mbsc-ic-fa-cc-amex::before {
  content: "\eb0c";
}
.mbsc-ic-fa-cc-diners-club::before {
  content: "\eb0d";
}
.mbsc-ic-fa-cc-discover::before {
  content: "\eb0e";
}
.mbsc-ic-fa-cc-jcb::before {
  content: "\eb0f";
}
.mbsc-ic-fa-cc-mastercard::before {
  content: "\eb10";
}
.mbsc-ic-fa-cc-paypal::before {
  content: "\eb11";
}
.mbsc-ic-fa-cc-stripe::before {
  content: "\eb12";
}
.mbsc-ic-fa-cc-visa::before {
  content: "\eb13";
}
.mbsc-ic-fa-cc::before {
  content: "\eb14";
}
.mbsc-ic-fa-certificate::before {
  content: "\eb15";
}
.mbsc-ic-fa-chain-broken::before {
  content: "\eb16";
}
.mbsc-ic-fa-chain::before {
  content: "\eb17";
}
.mbsc-ic-fa-check-circle-o::before {
  content: "\eb18";
}
.mbsc-ic-fa-check-circle::before {
  content: "\eb19";
}
.mbsc-ic-fa-check-square-o::before {
  content: "\eb1a";
}
.mbsc-ic-fa-check-square::before {
  content: "\eb1b";
}
.mbsc-ic-fa-check::before {
  content: "\eb1c";
}
.mbsc-ic-fa-chevron-circle-down::before {
  content: "\eb1d";
}
.mbsc-ic-fa-chevron-circle-left::before {
  content: "\eb1e";
}
.mbsc-ic-fa-chevron-circle-right::before {
  content: "\eb1f";
}
.mbsc-ic-fa-chevron-circle-up::before {
  content: "\eb20";
}
.mbsc-ic-fa-chevron-down::before {
  content: "\eb21";
}
.mbsc-ic-fa-chevron-left::before {
  content: "\eb22";
}
.mbsc-ic-fa-chevron-right::before {
  content: "\eb23";
}
.mbsc-ic-fa-chevron-up::before {
  content: "\eb24";
}
.mbsc-ic-fa-child::before {
  content: "\eb25";
}
.mbsc-ic-fa-chrome::before {
  content: "\eb26";
}
.mbsc-ic-fa-circle-o-notch::before {
  content: "\eb27";
}
.mbsc-ic-fa-circle-o::before {
  content: "\eb28";
}
.mbsc-ic-fa-circle-thin::before {
  content: "\eb29";
}
.mbsc-ic-fa-circle::before {
  content: "\eb2a";
}
.mbsc-ic-fa-clipboard::before {
  content: "\eb2b";
}
.mbsc-ic-fa-clock-o::before {
  content: "\eb2c";
}
.mbsc-ic-fa-clone::before {
  content: "\eb2d";
}
.mbsc-ic-fa-close::before {
  content: "\eb2e";
}
.mbsc-ic-fa-cloud-download::before {
  content: "\eb2f";
}
.mbsc-ic-fa-cloud-upload::before {
  content: "\eb30";
}
.mbsc-ic-fa-cloud::before {
  content: "\eb31";
}
.mbsc-ic-fa-cny::before {
  content: "\eb32";
}
.mbsc-ic-fa-code-fork::before {
  content: "\eb33";
}
.mbsc-ic-fa-code::before {
  content: "\eb34";
}
.mbsc-ic-fa-codepen::before {
  content: "\eb35";
}
.mbsc-ic-fa-codiepie::before {
  content: "\eb36";
}
.mbsc-ic-fa-coffee::before {
  content: "\eb37";
}
.mbsc-ic-fa-cog::before {
  content: "\eb38";
}
.mbsc-ic-fa-cogs::before {
  content: "\eb39";
}
.mbsc-ic-fa-columns::before {
  content: "\eb3a";
}
.mbsc-ic-fa-comment-o::before {
  content: "\eb3b";
}
.mbsc-ic-fa-comment::before {
  content: "\eb3c";
}
.mbsc-ic-fa-commenting-o::before {
  content: "\eb3d";
}
.mbsc-ic-fa-commenting::before {
  content: "\eb3e";
}
.mbsc-ic-fa-comments-o::before {
  content: "\eb3f";
}
.mbsc-ic-fa-comments::before {
  content: "\eb40";
}
.mbsc-ic-fa-compass::before {
  content: "\eb41";
}
.mbsc-ic-fa-compress::before {
  content: "\eb42";
}
.mbsc-ic-fa-connectdevelop::before {
  content: "\eb43";
}
.mbsc-ic-fa-contao::before {
  content: "\eb44";
}
.mbsc-ic-fa-copy::before {
  content: "\eb45";
}
.mbsc-ic-fa-copyright::before {
  content: "\eb46";
}
.mbsc-ic-fa-creative-commons::before {
  content: "\eb47";
}
.mbsc-ic-fa-credit-card-alt::before {
  content: "\eb48";
}
.mbsc-ic-fa-credit-card::before {
  content: "\eb49";
}
.mbsc-ic-fa-crop::before {
  content: "\eb4a";
}
.mbsc-ic-fa-crosshairs::before {
  content: "\eb4b";
}
.mbsc-ic-fa-css3::before {
  content: "\eb4c";
}
.mbsc-ic-fa-cube::before {
  content: "\eb4d";
}
.mbsc-ic-fa-cubes::before {
  content: "\eb4e";
}
.mbsc-ic-fa-cut::before {
  content: "\eb4f";
}
.mbsc-ic-fa-cutlery::before {
  content: "\eb50";
}
.mbsc-ic-fa-dashboard::before {
  content: "\eb51";
}
.mbsc-ic-fa-dashcube::before {
  content: "\eb52";
}
.mbsc-ic-fa-database::before {
  content: "\eb53";
}
.mbsc-ic-fa-deaf::before {
  content: "\eb54";
}
.mbsc-ic-fa-dedent::before {
  content: "\eb55";
}
.mbsc-ic-fa-delicious::before {
  content: "\eb56";
}
.mbsc-ic-fa-desktop::before {
  content: "\eb57";
}
.mbsc-ic-fa-deviantart::before {
  content: "\eb58";
}
.mbsc-ic-fa-diamond::before {
  content: "\eb59";
}
.mbsc-ic-fa-digg::before {
  content: "\eb5a";
}
.mbsc-ic-fa-dollar::before {
  content: "\eb5b";
}
.mbsc-ic-fa-dot-circle-o::before {
  content: "\eb5c";
}
.mbsc-ic-fa-download::before {
  content: "\eb5d";
}
.mbsc-ic-fa-dribbble::before {
  content: "\eb5e";
}
.mbsc-ic-fa-drivers-license-o::before {
  content: "\eb5f";
}
.mbsc-ic-fa-drivers-license::before {
  content: "\eb60";
}
.mbsc-ic-fa-dropbox::before {
  content: "\eb61";
}
.mbsc-ic-fa-drupal::before {
  content: "\eb62";
}
.mbsc-ic-fa-edge::before {
  content: "\eb63";
}
.mbsc-ic-fa-edit::before {
  content: "\eb64";
}
.mbsc-ic-fa-eercast::before {
  content: "\eb65";
}
.mbsc-ic-fa-eject::before {
  content: "\eb66";
}
.mbsc-ic-fa-ellipsis-h::before {
  content: "\eb67";
}
.mbsc-ic-fa-ellipsis-v::before {
  content: "\eb68";
}
.mbsc-ic-fa-empire::before {
  content: "\eb69";
}
.mbsc-ic-fa-envelope-o::before {
  content: "\eb6a";
}
.mbsc-ic-fa-envelope-open-o::before {
  content: "\eb6b";
}
.mbsc-ic-fa-envelope-open::before {
  content: "\eb6c";
}
.mbsc-ic-fa-envelope-square::before {
  content: "\eb6d";
}
.mbsc-ic-fa-envelope::before {
  content: "\eb6e";
}
.mbsc-ic-fa-envira::before {
  content: "\eb6f";
}
.mbsc-ic-fa-eraser::before {
  content: "\eb70";
}
.mbsc-ic-fa-etsy::before {
  content: "\eb71";
}
.mbsc-ic-fa-eur::before {
  content: "\eb72";
}
.mbsc-ic-fa-euro::before {
  content: "\eb73";
}
.mbsc-ic-fa-exchange::before {
  content: "\eb74";
}
.mbsc-ic-fa-exclamation-circle::before {
  content: "\eb75";
}
.mbsc-ic-fa-exclamation-triangle::before {
  content: "\eb76";
}
.mbsc-ic-fa-exclamation::before {
  content: "\eb77";
}
.mbsc-ic-fa-expand::before {
  content: "\eb78";
}
.mbsc-ic-fa-expeditedssl::before {
  content: "\eb79";
}
.mbsc-ic-fa-external-link-square::before {
  content: "\eb7a";
}
.mbsc-ic-fa-external-link::before {
  content: "\eb7b";
}
.mbsc-ic-fa-eye-slash::before {
  content: "\eb7c";
}
.mbsc-ic-fa-eye::before {
  content: "\eb7d";
}
.mbsc-ic-fa-eyedropper::before {
  content: "\eb7e";
}
.mbsc-ic-fa-fa::before {
  content: "\eb7f";
}
.mbsc-ic-fa-facebook-official::before {
  content: "\eb80";
}
.mbsc-ic-fa-facebook-square::before {
  content: "\eb81";
}
.mbsc-ic-fa-facebook::before {
  content: "\eb82";
}
.mbsc-ic-fa-fast-backward::before {
  content: "\eb83";
}
.mbsc-ic-fa-fast-forward::before {
  content: "\eb84";
}
.mbsc-ic-fa-fax::before {
  content: "\eb85";
}
.mbsc-ic-fa-feed::before {
  content: "\eb86";
}
.mbsc-ic-fa-female::before {
  content: "\eb87";
}
.mbsc-ic-fa-fighter-jet::before {
  content: "\eb88";
}
.mbsc-ic-fa-file-archive-o::before {
  content: "\eb89";
}
.mbsc-ic-fa-file-audio-o::before {
  content: "\eb8a";
}
.mbsc-ic-fa-file-code-o::before {
  content: "\eb8b";
}
.mbsc-ic-fa-file-excel-o::before {
  content: "\eb8c";
}
.mbsc-ic-fa-file-image-o::before {
  content: "\eb8d";
}
.mbsc-ic-fa-file-movie-o::before {
  content: "\eb8e";
}
.mbsc-ic-fa-file-o::before {
  content: "\eb8f";
}
.mbsc-ic-fa-file-pdf-o::before {
  content: "\eb90";
}
.mbsc-ic-fa-file-powerpoint-o::before {
  content: "\eb91";
}
.mbsc-ic-fa-file-text-o::before {
  content: "\eb92";
}
.mbsc-ic-fa-file-text::before {
  content: "\eb93";
}
.mbsc-ic-fa-file-word-o::before {
  content: "\eb94";
}
.mbsc-ic-fa-file::before {
  content: "\eb95";
}
.mbsc-ic-fa-film::before {
  content: "\eb96";
}
.mbsc-ic-fa-filter::before {
  content: "\eb97";
}
.mbsc-ic-fa-fire-extinguisher::before {
  content: "\eb98";
}
.mbsc-ic-fa-fire::before {
  content: "\eb99";
}
.mbsc-ic-fa-firefox::before {
  content: "\eb9a";
}
.mbsc-ic-fa-first-order::before {
  content: "\eb9b";
}
.mbsc-ic-fa-flag-checkered::before {
  content: "\eb9c";
}
.mbsc-ic-fa-flag-o::before {
  content: "\eb9d";
}
.mbsc-ic-fa-flag::before {
  content: "\eb9e";
}
.mbsc-ic-fa-flash::before {
  content: "\eb9f";
}
.mbsc-ic-fa-flask::before {
  content: "\eba0";
}
.mbsc-ic-fa-flickr::before {
  content: "\eba1";
}
.mbsc-ic-fa-floppy-o::before {
  content: "\eba2";
}
.mbsc-ic-fa-folder-o::before {
  content: "\eba3";
}
.mbsc-ic-fa-folder-open-o::before {
  content: "\eba4";
}
.mbsc-ic-fa-folder-open::before {
  content: "\eba5";
}
.mbsc-ic-fa-folder::before {
  content: "\eba6";
}
.mbsc-ic-fa-font::before {
  content: "\eba7";
}
.mbsc-ic-fa-fonticons::before {
  content: "\eba8";
}
.mbsc-ic-fa-fort-awesome::before {
  content: "\eba9";
}
.mbsc-ic-fa-forumbee::before {
  content: "\ebaa";
}
.mbsc-ic-fa-forward::before {
  content: "\ebab";
}
.mbsc-ic-fa-foursquare::before {
  content: "\ebac";
}
.mbsc-ic-fa-free-code-camp::before {
  content: "\ebad";
}
.mbsc-ic-fa-frown-o::before {
  content: "\ebae";
}
.mbsc-ic-fa-futbol-o::before {
  content: "\ebaf";
}
.mbsc-ic-fa-gamepad::before {
  content: "\ebb0";
}
.mbsc-ic-fa-gavel::before {
  content: "\ebb1";
}
.mbsc-ic-fa-gbp::before {
  content: "\ebb2";
}
.mbsc-ic-fa-gear::before {
  content: "\ebb3";
}
.mbsc-ic-fa-gears::before {
  content: "\ebb4";
}
.mbsc-ic-fa-genderless::before {
  content: "\ebb5";
}
.mbsc-ic-fa-get-pocket::before {
  content: "\ebb6";
}
.mbsc-ic-fa-gg-circle::before {
  content: "\ebb7";
}
.mbsc-ic-fa-gg::before {
  content: "\ebb8";
}
.mbsc-ic-fa-gift::before {
  content: "\ebb9";
}
.mbsc-ic-fa-git-square::before {
  content: "\ebba";
}
.mbsc-ic-fa-git::before {
  content: "\ebbb";
}
.mbsc-ic-fa-github-alt::before {
  content: "\ebbc";
}
.mbsc-ic-fa-github-square::before {
  content: "\ebbd";
}
.mbsc-ic-fa-github::before {
  content: "\ebbe";
}
.mbsc-ic-fa-gitlab::before {
  content: "\ebbf";
}
.mbsc-ic-fa-gittip::before {
  content: "\ebc0";
}
.mbsc-ic-fa-glass::before {
  content: "\ebc1";
}
.mbsc-ic-fa-glide-g::before {
  content: "\ebc2";
}
.mbsc-ic-fa-glide::before {
  content: "\ebc3";
}
.mbsc-ic-fa-globe::before {
  content: "\ebc4";
}
.mbsc-ic-fa-google-plus-circle::before {
  content: "\ebc5";
}
.mbsc-ic-fa-google-plus-square::before {
  content: "\ebc6";
}
.mbsc-ic-fa-google-plus::before {
  content: "\ebc7";
}
.mbsc-ic-fa-google-wallet::before {
  content: "\ebc8";
}
.mbsc-ic-fa-google::before {
  content: "\ebc9";
}
.mbsc-ic-fa-graduation-cap::before {
  content: "\ebca";
}
.mbsc-ic-fa-grav::before {
  content: "\ebcb";
}
.mbsc-ic-fa-group::before {
  content: "\ebcc";
}
.mbsc-ic-fa-h-square::before {
  content: "\ebcd";
}
.mbsc-ic-fa-hacker-news::before {
  content: "\ebce";
}
.mbsc-ic-fa-hand-grab-o::before {
  content: "\ebcf";
}
.mbsc-ic-fa-hand-lizard-o::before {
  content: "\ebd0";
}
.mbsc-ic-fa-hand-o-down::before {
  content: "\ebd1";
}
.mbsc-ic-fa-hand-o-left::before {
  content: "\ebd2";
}
.mbsc-ic-fa-hand-o-right::before {
  content: "\ebd3";
}
.mbsc-ic-fa-hand-o-up::before {
  content: "\ebd4";
}
.mbsc-ic-fa-hand-paper-o::before {
  content: "\ebd5";
}
.mbsc-ic-fa-hand-peace-o::before {
  content: "\ebd6";
}
.mbsc-ic-fa-hand-pointer-o::before {
  content: "\ebd7";
}
.mbsc-ic-fa-hand-scissors-o::before {
  content: "\ebd8";
}
.mbsc-ic-fa-hand-spock-o::before {
  content: "\ebd9";
}
.mbsc-ic-fa-handshake-o::before {
  content: "\ebda";
}
.mbsc-ic-fa-hashtag::before {
  content: "\ebdb";
}
.mbsc-ic-fa-hdd-o::before {
  content: "\ebdc";
}
.mbsc-ic-fa-header::before {
  content: "\ebdd";
}
.mbsc-ic-fa-headphones::before {
  content: "\ebde";
}
.mbsc-ic-fa-heart-o::before {
  content: "\ebdf";
}
.mbsc-ic-fa-heart::before {
  content: "\ebe0";
}
.mbsc-ic-fa-heartbeat::before {
  content: "\ebe1";
}
.mbsc-ic-fa-history::before {
  content: "\ebe2";
}
.mbsc-ic-fa-home::before {
  content: "\ebe3";
}
.mbsc-ic-fa-hospital-o::before {
  content: "\ebe8";
}
.mbsc-ic-fa-hourglass-1::before {
  content: "\ebe4";
}
.mbsc-ic-fa-hourglass-2::before {
  content: "\ebe5";
}
.mbsc-ic-fa-hourglass-3::before {
  content: "\ebe6";
}
.mbsc-ic-fa-hourglass-o::before {
  content: "\ebe7";
}
.mbsc-ic-fa-hourglass::before {
  content: "\ebe9";
}
.mbsc-ic-fa-houzz::before {
  content: "\ebea";
}
.mbsc-ic-fa-html5::before {
  content: "\ebeb";
}
.mbsc-ic-fa-i-cursor::before {
  content: "\ebec";
}
.mbsc-ic-fa-id-badge::before {
  content: "\ebed";
}
.mbsc-ic-fa-ils::before {
  content: "\ebf0";
}
.mbsc-ic-fa-image::before {
  content: "\ebee";
}
.mbsc-ic-fa-imdb::before {
  content: "\ebef";
}
.mbsc-ic-fa-inbox::before {
  content: "\ebf1";
}
.mbsc-ic-fa-indent::before {
  content: "\ebf2";
}
.mbsc-ic-fa-industry::before {
  content: "\ebf3";
}
.mbsc-ic-fa-info-circle::before {
  content: "\ebf4";
}
.mbsc-ic-fa-info::before {
  content: "\ebf5";
}
.mbsc-ic-fa-inr::before {
  content: "\ebf6";
}
.mbsc-ic-fa-instagram::before {
  content: "\ebf8";
}
.mbsc-ic-fa-internet-explorer::before {
  content: "\ebf7";
}
.mbsc-ic-fa-intersex::before {
  content: "\ebf9";
}
.mbsc-ic-fa-ioxhost::before {
  content: "\ebfa";
}
.mbsc-ic-fa-italic::before {
  content: "\ebfb";
}
.mbsc-ic-fa-joomla::before {
  content: "\ebfc";
}
.mbsc-ic-fa-jsfiddle::before {
  content: "\ebfd";
}
.mbsc-ic-fa-key::before {
  content: "\ebfe";
}
.mbsc-ic-fa-keyboard-o::before {
  content: "\ebff";
}
.mbsc-ic-fa-krw::before {
  content: "\ec00";
}
.mbsc-ic-fa-language::before {
  content: "\ec01";
}
.mbsc-ic-fa-laptop::before {
  content: "\ec02";
}
.mbsc-ic-fa-lastfm-square::before {
  content: "\ec06";
}
.mbsc-ic-fa-lastfm::before {
  content: "\ec03";
}
.mbsc-ic-fa-leaf::before {
  content: "\ec04";
}
.mbsc-ic-fa-leanpub::before {
  content: "\ec05";
}
.mbsc-ic-fa-legal::before {
  content: "\ec07";
}
.mbsc-ic-fa-lemon-o::before {
  content: "\ec09";
}
.mbsc-ic-fa-level-down::before {
  content: "\ec0c";
}
.mbsc-ic-fa-level-up::before {
  content: "\ec08";
}
.mbsc-ic-fa-life-bouy::before {
  content: "\ec0a";
}
.mbsc-ic-fa-lightbulb-o::before {
  content: "\ec0b";
}
.mbsc-ic-fa-line-chart::before {
  content: "\ec0d";
}
.mbsc-ic-fa-linkedin-square::before {
  content: "\ec0e";
}
.mbsc-ic-fa-linkedin::before {
  content: "\ec0f";
}
.mbsc-ic-fa-linode::before {
  content: "\ec10";
}
.mbsc-ic-fa-linux::before {
  content: "\ec11";
}
.mbsc-ic-fa-list-alt::before {
  content: "\ec12";
}
.mbsc-ic-fa-list-ol::before {
  content: "\ec13";
}
.mbsc-ic-fa-list-ul::before {
  content: "\ec14";
}
.mbsc-ic-fa-list::before {
  content: "\ec15";
}
.mbsc-ic-fa-location-arrow::before {
  content: "\ec16";
}
.mbsc-ic-fa-lock::before {
  content: "\ec17";
}
.mbsc-ic-fa-long-arrow-down::before {
  content: "\ec18";
}
.mbsc-ic-fa-long-arrow-left::before {
  content: "\ec19";
}
.mbsc-ic-fa-long-arrow-right::before {
  content: "\ec1a";
}
.mbsc-ic-fa-long-arrow-up::before {
  content: "\ec1b";
}
.mbsc-ic-fa-low-vision::before {
  content: "\ec1c";
}
.mbsc-ic-fa-magic::before {
  content: "\ec1d";
}
.mbsc-ic-fa-magnet::before {
  content: "\ec1e";
}
.mbsc-ic-fa-mail-forward::before {
  content: "\ec1f";
}
.mbsc-ic-fa-mail-reply-all::before {
  content: "\ec20";
}
.mbsc-ic-fa-mail-reply::before {
  content: "\ec21";
}
.mbsc-ic-fa-male::before {
  content: "\ec22";
}
.mbsc-ic-fa-map-marker::before {
  content: "\ec23";
}
.mbsc-ic-fa-map-o::before {
  content: "\ec24";
}
.mbsc-ic-fa-map-pin::before {
  content: "\ec26";
}
.mbsc-ic-fa-map-signs::before {
  content: "\ec25";
}
.mbsc-ic-fa-map::before {
  content: "\ec27";
}
.mbsc-ic-fa-mars-double::before {
  content: "\ec28";
}
.mbsc-ic-fa-mars-stroke-h::before {
  content: "\ec29";
}
.mbsc-ic-fa-mars-stroke-v::before {
  content: "\ec2a";
}
.mbsc-ic-fa-mars-stroke::before {
  content: "\ec2b";
}
.mbsc-ic-fa-mars::before {
  content: "\ec2c";
}
.mbsc-ic-fa-maxcdn::before {
  content: "\ec2d";
}
.mbsc-ic-fa-meanpath::before {
  content: "\ec2e";
}
.mbsc-ic-fa-medium::before {
  content: "\ec2f";
}
.mbsc-ic-fa-medkit::before {
  content: "\ec30";
}
.mbsc-ic-fa-meetup::before {
  content: "\ec31";
}
.mbsc-ic-fa-meh-o::before {
  content: "\ec32";
}
.mbsc-ic-fa-mercury::before {
  content: "\ec33";
}
.mbsc-ic-fa-microchip::before {
  content: "\ec34";
}
.mbsc-ic-fa-microphone-slash::before {
  content: "\ec35";
}
.mbsc-ic-fa-microphone::before {
  content: "\ec36";
}
.mbsc-ic-fa-minus-circle::before {
  content: "\ec37";
}
.mbsc-ic-fa-minus-square-o::before {
  content: "\ec38";
}
.mbsc-ic-fa-minus-square::before {
  content: "\ec39";
}
.mbsc-ic-fa-minus::before {
  content: "\ec3a";
}
.mbsc-ic-fa-mixcloud::before {
  content: "\ec3b";
}
.mbsc-ic-fa-mobile-phone::before {
  content: "\ec3c";
}
.mbsc-ic-fa-mobile::before {
  content: "\ec3d";
}
.mbsc-ic-fa-modx::before {
  content: "\ec3e";
}
.mbsc-ic-fa-money::before {
  content: "\ec3f";
}
.mbsc-ic-fa-moon-o::before {
  content: "\ec40";
}
.mbsc-ic-fa-motorcycle::before {
  content: "\ec41";
}
.mbsc-ic-fa-mouse-pointer::before {
  content: "\ec42";
}
.mbsc-ic-fa-music::before {
  content: "\ec43";
}
.mbsc-ic-fa-neuter::before {
  content: "\ec44";
}
.mbsc-ic-fa-newspaper-o::before {
  content: "\ec45";
}
.mbsc-ic-fa-object-group::before {
  content: "\ec46";
}
.mbsc-ic-fa-object-ungroup::before {
  content: "\ec47";
}
.mbsc-ic-fa-odnoklassniki-square::before {
  content: "\ec48";
}
.mbsc-ic-fa-odnoklassniki::before {
  content: "\ec49";
}
.mbsc-ic-fa-opencart::before {
  content: "\ec4a";
}
.mbsc-ic-fa-openid::before {
  content: "\ec4b";
}
.mbsc-ic-fa-opera::before {
  content: "\ec4c";
}
.mbsc-ic-fa-optin-monster::before {
  content: "\ec4d";
}
.mbsc-ic-fa-pagelines::before {
  content: "\ec4e";
}
.mbsc-ic-fa-paint-brush::before {
  content: "\ec4f";
}
.mbsc-ic-fa-paper-plane-o::before {
  content: "\ec50";
}
.mbsc-ic-fa-paper-plane::before {
  content: "\ec51";
}
.mbsc-ic-fa-paperclip::before {
  content: "\ec52";
}
.mbsc-ic-fa-paragraph::before {
  content: "\ec53";
}
.mbsc-ic-fa-paste::before {
  content: "\ec54";
}
.mbsc-ic-fa-pause-circle-o::before {
  content: "\ec55";
}
.mbsc-ic-fa-pause-circle::before {
  content: "\ec56";
}
.mbsc-ic-fa-pause::before {
  content: "\ec57";
}
.mbsc-ic-fa-paw::before {
  content: "\ec58";
}
.mbsc-ic-fa-paypal::before {
  content: "\ec59";
}
.mbsc-ic-fa-pencil-square::before {
  content: "\ec5a";
}
.mbsc-ic-fa-pencil::before {
  content: "\ec5b";
}
.mbsc-ic-fa-percent::before {
  content: "\ec5c";
}
.mbsc-ic-fa-phone-square::before {
  content: "\ec5d";
}
.mbsc-ic-fa-phone::before {
  content: "\ec5e";
}
.mbsc-ic-fa-picture-o::before {
  content: "\ec5f";
}
.mbsc-ic-fa-pie-chart::before {
  content: "\ec60";
}
.mbsc-ic-fa-pied-piper-alt::before {
  content: "\ec61";
}
.mbsc-ic-fa-pied-piper-pp::before {
  content: "\ec62";
}
.mbsc-ic-fa-pied-piper::before {
  content: "\ec63";
}
.mbsc-ic-fa-pinterest-p::before {
  content: "\ec64";
}
.mbsc-ic-fa-pinterest-square::before {
  content: "\ec65";
}
.mbsc-ic-fa-pinterest::before {
  content: "\ec66";
}
.mbsc-ic-fa-plane::before {
  content: "\ec67";
}
.mbsc-ic-fa-play-circle-o::before {
  content: "\ec68";
}
.mbsc-ic-fa-play-circle::before {
  content: "\ec69";
}
.mbsc-ic-fa-play::before {
  content: "\ec6a";
}
.mbsc-ic-fa-plug::before {
  content: "\ec6b";
}
.mbsc-ic-fa-plus-circle::before {
  content: "\ec6c";
}
.mbsc-ic-fa-plus-square-o::before {
  content: "\ec6d";
}
.mbsc-ic-fa-plus-square::before {
  content: "\ec6e";
}
.mbsc-ic-fa-plus::before {
  content: "\ec6f";
}
.mbsc-ic-fa-podcast::before {
  content: "\ec70";
}
.mbsc-ic-fa-power-off::before {
  content: "\ec71";
}
.mbsc-ic-fa-print::before {
  content: "\ec72";
}
.mbsc-ic-fa-product-hunt::before {
  content: "\ec73";
}
.mbsc-ic-fa-puzzle-piece::before {
  content: "\ec74";
}
.mbsc-ic-fa-qq::before {
  content: "\ec75";
}
.mbsc-ic-fa-qrcode::before {
  content: "\ec76";
}
.mbsc-ic-fa-question-circle-o::before {
  content: "\ec77";
}
.mbsc-ic-fa-question-circle::before {
  content: "\ec78";
}
.mbsc-ic-fa-question::before {
  content: "\ec79";
}
.mbsc-ic-fa-quora::before {
  content: "\ec7a";
}
.mbsc-ic-fa-quote-left::before {
  content: "\ec7b";
}
.mbsc-ic-fa-quote-right::before {
  content: "\ec7c";
}
.mbsc-ic-fa-ra::before {
  content: "\ec7d";
}
.mbsc-ic-fa-random::before {
  content: "\ec7e";
}
.mbsc-ic-fa-ravelry::before {
  content: "\ec7f";
}
.mbsc-ic-fa-recycle::before {
  content: "\ec80";
}
.mbsc-ic-fa-reddit-alien::before {
  content: "\ec81";
}
.mbsc-ic-fa-reddit-square::before {
  content: "\ec82";
}
.mbsc-ic-fa-reddit::before {
  content: "\ec83";
}
.mbsc-ic-fa-refresh::before {
  content: "\ec84";
}
.mbsc-ic-fa-registered::before {
  content: "\ec85";
}
.mbsc-ic-fa-renren::before {
  content: "\ec86";
}
.mbsc-ic-fa-repeat::before {
  content: "\ec87";
}
.mbsc-ic-fa-reply-all::before {
  content: "\ec88";
}
.mbsc-ic-fa-retweet::before {
  content: "\ec89";
}
.mbsc-ic-fa-road::before {
  content: "\ec8a";
}
.mbsc-ic-fa-rocket::before {
  content: "\ec8b";
}
.mbsc-ic-fa-rotate-left::before {
  content: "\ec8c";
}
.mbsc-ic-fa-rotate-right::before {
  content: "\ec8d";
}
.mbsc-ic-fa-rouble::before {
  content: "\ec8e";
}
.mbsc-ic-fa-rss-square::before {
  content: "\ec8f";
}
.mbsc-ic-fa-rss::before {
  content: "\ec90";
}
.mbsc-ic-fa-ruble::before {
  content: "\ec91";
}
.mbsc-ic-fa-rupee::before {
  content: "\ec92";
}
.mbsc-ic-fa-safari::before {
  content: "\ec93";
}
.mbsc-ic-fa-save::before {
  content: "\ec94";
}
.mbsc-ic-fa-scribd::before {
  content: "\ec95";
}
.mbsc-ic-fa-search-minus::before {
  content: "\ec96";
}
.mbsc-ic-fa-search-plus::before {
  content: "\ec97";
}
.mbsc-ic-fa-search::before {
  content: "\ec98";
}
.mbsc-ic-fa-sellsy::before {
  content: "\ec99";
}
.mbsc-ic-fa-server::before {
  content: "\ec9a";
}
.mbsc-ic-fa-share-alt-square::before {
  content: "\ec9b";
}
.mbsc-ic-fa-share-alt::before {
  content: "\ec9c";
}
.mbsc-ic-fa-share-square-o::before {
  content: "\ec9d";
}
.mbsc-ic-fa-share-square::before {
  content: "\ec9e";
}
.mbsc-ic-fa-shield::before {
  content: "\ec9f";
}
.mbsc-ic-fa-ship::before {
  content: "\eca0";
}
.mbsc-ic-fa-shirtsinbulk::before {
  content: "\eca1";
}
.mbsc-ic-fa-shopping-bag::before {
  content: "\eca2";
}
.mbsc-ic-fa-shopping-basket::before {
  content: "\eca3";
}
.mbsc-ic-fa-shopping-cart::before {
  content: "\eca4";
}
.mbsc-ic-fa-shower::before {
  content: "\eca5";
}
.mbsc-ic-fa-sign-in::before {
  content: "\eca6";
}
.mbsc-ic-fa-sign-language::before {
  content: "\eca7";
}
.mbsc-ic-fa-sign-out::before {
  content: "\eca8";
}
.mbsc-ic-fa-signal::before {
  content: "\eca9";
}
.mbsc-ic-fa-simplybuilt::before {
  content: "\ecaa";
}
.mbsc-ic-fa-sitemap::before {
  content: "\ecab";
}
.mbsc-ic-fa-skyatlas::before {
  content: "\ecac";
}
.mbsc-ic-fa-skype::before {
  content: "\ecad";
}
.mbsc-ic-fa-slack::before {
  content: "\ecae";
}
.mbsc-ic-fa-sliders::before {
  content: "\ecaf";
}
.mbsc-ic-fa-slideshare::before {
  content: "\ecb0";
}
.mbsc-ic-fa-smile-o::before {
  content: "\ecb1";
}
.mbsc-ic-fa-snapchat-ghost::before {
  content: "\ecb2";
}
.mbsc-ic-fa-snapchat-square::before {
  content: "\ecb3";
}
.mbsc-ic-fa-snapchat::before {
  content: "\ecb4";
}
.mbsc-ic-fa-snowflake-o::before {
  content: "\ecb5";
}
.mbsc-ic-fa-sort-alpha-asc::before {
  content: "\ecb6";
}
.mbsc-ic-fa-sort-alpha-desc::before {
  content: "\ecb7";
}
.mbsc-ic-fa-sort-amount-asc::before {
  content: "\ecb8";
}
.mbsc-ic-fa-sort-amount-desc::before {
  content: "\ecb9";
}
.mbsc-ic-fa-sort-asc::before {
  content: "\ecba";
}
.mbsc-ic-fa-sort-desc::before {
  content: "\ecbb";
}
.mbsc-ic-fa-sort-down::before {
  content: "\ecbc";
}
.mbsc-ic-fa-sort-numeric-asc::before {
  content: "\ecbd";
}
.mbsc-ic-fa-sort-numeric-desc::before {
  content: "\ecbe";
}
.mbsc-ic-fa-sort-up::before {
  content: "\ecbf";
}
.mbsc-ic-fa-sort::before {
  content: "\ecc0";
}
.mbsc-ic-fa-soundcloud::before {
  content: "\ecc1";
}
.mbsc-ic-fa-space-shuttle::before {
  content: "\ecc2";
}
.mbsc-ic-fa-spinner::before {
  content: "\ecc3";
}
.mbsc-ic-fa-spoon::before {
  content: "\ecc4";
}
.mbsc-ic-fa-spotify::before {
  content: "\ecc5";
}
.mbsc-ic-fa-square-o::before {
  content: "\ecc6";
}
.mbsc-ic-fa-square::before {
  content: "\ecc7";
}
.mbsc-ic-fa-stack-exchange::before {
  content: "\ecc8";
}
.mbsc-ic-fa-stack-overflow::before {
  content: "\ecc9";
}
.mbsc-ic-fa-star-half-empty::before {
  content: "\ecca";
}
.mbsc-ic-fa-star-half::before {
  content: "\eccb";
}
.mbsc-ic-fa-star-o::before {
  content: "\eccc";
}
.mbsc-ic-fa-star::before {
  content: "\eccd";
}
.mbsc-ic-fa-steam-square::before {
  content: "\ecce";
}
.mbsc-ic-fa-steam::before {
  content: "\eccf";
}
.mbsc-ic-fa-step-backward::before {
  content: "\ecd0";
}
.mbsc-ic-fa-step-forward::before {
  content: "\ecd1";
}
.mbsc-ic-fa-stethoscope::before {
  content: "\ecd2";
}
.mbsc-ic-fa-sticky-note-o::before {
  content: "\ecd3";
}
.mbsc-ic-fa-sticky-note::before {
  content: "\ecd4";
}
.mbsc-ic-fa-stop-circle-o::before {
  content: "\ecd5";
}
.mbsc-ic-fa-stop-circle::before {
  content: "\ecd6";
}
.mbsc-ic-fa-stop::before {
  content: "\ecd7";
}
.mbsc-ic-fa-street-view::before {
  content: "\ecd8";
}
.mbsc-ic-fa-strikethrough::before {
  content: "\ecd9";
}
.mbsc-ic-fa-stumbleupon-circle::before {
  content: "\ecda";
}
.mbsc-ic-fa-stumbleupon::before {
  content: "\ecdb";
}
.mbsc-ic-fa-subscript::before {
  content: "\ecdc";
}
.mbsc-ic-fa-subway::before {
  content: "\ecdd";
}
.mbsc-ic-fa-suitcase::before {
  content: "\ecde";
}
.mbsc-ic-fa-sun-o::before {
  content: "\ecdf";
}
.mbsc-ic-fa-superpowers::before {
  content: "\ece0";
}
.mbsc-ic-fa-superscript::before {
  content: "\ece1";
}
.mbsc-ic-fa-table::before {
  content: "\ece2";
}
.mbsc-ic-fa-tablet::before {
  content: "\ece3";
}
.mbsc-ic-fa-tag::before {
  content: "\ece4";
}
.mbsc-ic-fa-tags::before {
  content: "\ece5";
}
.mbsc-ic-fa-tasks::before {
  content: "\ece6";
}
.mbsc-ic-fa-telegram::before {
  content: "\ece7";
}
.mbsc-ic-fa-television::before {
  content: "\ece8";
}
.mbsc-ic-fa-tencent-weibo::before {
  content: "\ece9";
}
.mbsc-ic-fa-terminal::before {
  content: "\ecea";
}
.mbsc-ic-fa-text-height::before {
  content: "\eceb";
}
.mbsc-ic-fa-text-width::before {
  content: "\ecec";
}
.mbsc-ic-fa-th-large::before {
  content: "\eced";
}
.mbsc-ic-fa-th-list::before {
  content: "\ecee";
}
.mbsc-ic-fa-th::before {
  content: "\ecef";
}
.mbsc-ic-fa-themeisle::before {
  content: "\ecf0";
}
.mbsc-ic-fa-thermometer-0::before {
  content: "\ecf1";
}
.mbsc-ic-fa-thermometer-1::before {
  content: "\ecf2";
}
.mbsc-ic-fa-thermometer-2::before {
  content: "\ecf3";
}
.mbsc-ic-fa-thermometer-3::before {
  content: "\ecf4";
}
.mbsc-ic-fa-thermometer::before {
  content: "\ecf5";
}
.mbsc-ic-fa-thumb-tack::before {
  content: "\ecf6";
}
.mbsc-ic-fa-thumbs-down::before {
  content: "\ecf7";
}
.mbsc-ic-fa-thumbs-o-down::before {
  content: "\ecf8";
}
.mbsc-ic-fa-thumbs-o-up::before {
  content: "\ecf9";
}
.mbsc-ic-fa-thumbs-up::before {
  content: "\ecfa";
}
.mbsc-ic-fa-ticket::before {
  content: "\ecfb";
}
.mbsc-ic-fa-times-circle-o::before {
  content: "\ecfc";
}
.mbsc-ic-fa-times-circle::before {
  content: "\ecfd";
}
.mbsc-ic-fa-times-rectangle-o::before {
  content: "\ecfe";
}
.mbsc-ic-fa-times-rectangle::before {
  content: "\ecff";
}
.mbsc-ic-fa-times::before {
  content: "\ed00";
}
.mbsc-ic-fa-tint::before {
  content: "\ed01";
}
.mbsc-ic-fa-toggle-down::before {
  content: "\ed02";
}
.mbsc-ic-fa-toggle-left::before {
  content: "\ed03";
}
.mbsc-ic-fa-toggle-off::before {
  content: "\ed04";
}
.mbsc-ic-fa-toggle-on::before {
  content: "\ed05";
}
.mbsc-ic-fa-toggle-right::before {
  content: "\ed06";
}
.mbsc-ic-fa-toggle-up::before {
  content: "\ed07";
}
.mbsc-ic-fa-trademark::before {
  content: "\ed08";
}
.mbsc-ic-fa-train::before {
  content: "\ed09";
}
.mbsc-ic-fa-transgender-alt::before {
  content: "\ed0a";
}
.mbsc-ic-fa-trash-o::before {
  content: "\ed0b";
}
.mbsc-ic-fa-trash::before {
  content: "\ed0c";
}
.mbsc-ic-fa-tree::before {
  content: "\ed0d";
}
.mbsc-ic-fa-trello::before {
  content: "\ed0e";
}
.mbsc-ic-fa-tripadvisor::before {
  content: "\ed0f";
}
.mbsc-ic-fa-trophy::before {
  content: "\ed10";
}
.mbsc-ic-fa-truck::before {
  content: "\ed11";
}
.mbsc-ic-fa-try::before {
  content: "\ed12";
}
.mbsc-ic-fa-tty::before {
  content: "\ed13";
}
.mbsc-ic-fa-tumblr-square::before {
  content: "\ed14";
}
.mbsc-ic-fa-tumblr::before {
  content: "\ed15";
}
.mbsc-ic-fa-turkish-lira::before {
  content: "\ed16";
}
.mbsc-ic-fa-twitch::before {
  content: "\ed17";
}
.mbsc-ic-fa-twitter-square::before {
  content: "\ed18";
}
.mbsc-ic-fa-twitter::before {
  content: "\ed19";
}
.mbsc-ic-fa-umbrella::before {
  content: "\ed1a";
}
.mbsc-ic-fa-underline::before {
  content: "\ed1b";
}
.mbsc-ic-fa-universal-access::before {
  content: "\ed1c";
}
.mbsc-ic-fa-unlink::before {
  content: "\ed1d";
}
.mbsc-ic-fa-unlock-alt::before {
  content: "\ed1e";
}
.mbsc-ic-fa-unlock::before {
  content: "\ed1f";
}
.mbsc-ic-fa-unsorted::before {
  content: "\ed20";
}
.mbsc-ic-fa-upload::before {
  content: "\ed21";
}
.mbsc-ic-fa-usb::before {
  content: "\ed22";
}
.mbsc-ic-fa-user-circle-o::before {
  content: "\ed23";
}
.mbsc-ic-fa-user-circle::before {
  content: "\ed24";
}
.mbsc-ic-fa-user-md::before {
  content: "\ed25";
}
.mbsc-ic-fa-user-o::before {
  content: "\ed26";
}
.mbsc-ic-fa-user-plus::before {
  content: "\ed27";
}
.mbsc-ic-fa-user-secret::before {
  content: "\ed28";
}
.mbsc-ic-fa-user-times::before {
  content: "\ed29";
}
.mbsc-ic-fa-user::before {
  content: "\ed2a";
}
.mbsc-ic-fa-venus-double::before {
  content: "\ed2b";
}
.mbsc-ic-fa-venus-mars::before {
  content: "\ed2c";
}
.mbsc-ic-fa-venus::before {
  content: "\ed2d";
}
.mbsc-ic-fa-viacoin::before {
  content: "\ed2e";
}
.mbsc-ic-fa-viadeo-square::before {
  content: "\ed2f";
}
.mbsc-ic-fa-viadeo::before {
  content: "\ed30";
}
.mbsc-ic-fa-video-camera::before {
  content: "\ed31";
}
.mbsc-ic-fa-vimeo-square::before {
  content: "\ed32";
}
.mbsc-ic-fa-vimeo::before {
  content: "\ed33";
}
.mbsc-ic-fa-vine::before {
  content: "\ed34";
}
.mbsc-ic-fa-vk::before {
  content: "\ed35";
}
.mbsc-ic-fa-volume-control-phone::before {
  content: "\ed36";
}
.mbsc-ic-fa-volume-down::before {
  content: "\ed37";
}
.mbsc-ic-fa-volume-off::before {
  content: "\ed38";
}
.mbsc-ic-fa-volume-up::before {
  content: "\ed39";
}
.mbsc-ic-fa-warning::before {
  content: "\ed3a";
}
.mbsc-ic-fa-wechat::before {
  content: "\ed3b";
}
.mbsc-ic-fa-weibo::before {
  content: "\ed3c";
}
.mbsc-ic-fa-whatsapp::before {
  content: "\ed3d";
}
.mbsc-ic-fa-wheelchair-alt::before {
  content: "\ed3e";
}
.mbsc-ic-fa-wheelchair::before {
  content: "\ed3f";
}
.mbsc-ic-fa-wifi::before {
  content: "\ed40";
}
.mbsc-ic-fa-wikipedia-w::before {
  content: "\ed41";
}
.mbsc-ic-fa-window-maximize::before {
  content: "\ed42";
}
.mbsc-ic-fa-window-minimize::before {
  content: "\ed43";
}
.mbsc-ic-fa-window-restore::before {
  content: "\ed44";
}
.mbsc-ic-fa-windows::before {
  content: "\ed45";
}
.mbsc-ic-fa-won::before {
  content: "\ed46";
}
.mbsc-ic-fa-wordpress::before {
  content: "\ed47";
}
.mbsc-ic-fa-wpbeginner::before {
  content: "\ed48";
}
.mbsc-ic-fa-wpexplorer::before {
  content: "\ed49";
}
.mbsc-ic-fa-wpforms::before {
  content: "\ed4a";
}
.mbsc-ic-fa-wrench::before {
  content: "\ed4b";
}
.mbsc-ic-fa-xing-square::before {
  content: "\ed4c";
}
.mbsc-ic-fa-xing::before {
  content: "\ed4d";
}
.mbsc-ic-fa-y-combinator::before {
  content: "\ed4e";
}
.mbsc-ic-fa-yahoo::before {
  content: "\ed4f";
}
.mbsc-ic-fa-yelp::before {
  content: "\ed50";
}
.mbsc-ic-fa-yoast::before {
  content: "\ed51";
}
.mbsc-ic-fa-youtube-play::before {
  content: "\ed52";
}
.mbsc-ic-fa-youtube-square::before {
  content: "\ed53";
}
.mbsc-ic-fa-youtube::before {
  content: "\ed54";
}
.mbsc-ic-facebook::before {
  content: "\ed55";
}
.mbsc-ic-facebook2::before {
  content: "\ed56";
}
.mbsc-ic-facebook3::before {
  content: "\ed57";
}
.mbsc-ic-feed::before {
  content: "\ed58";
}
.mbsc-ic-feed2::before {
  content: "\ed59";
}
.mbsc-ic-feed3::before {
  content: "\ed5a";
}
.mbsc-ic-feed4::before {
  content: "\ed5b";
}
.mbsc-ic-file-css::before {
  content: "\ed5c";
}
.mbsc-ic-file-excel::before {
  content: "\ed5d";
}
.mbsc-ic-file-openoffice::before {
  content: "\ed5e";
}
.mbsc-ic-file-pdf::before {
  content: "\ed5f";
}
.mbsc-ic-file-powerpoint::before {
  content: "\ed60";
}
.mbsc-ic-file-word::before {
  content: "\ed61";
}
.mbsc-ic-file-xml::before {
  content: "\ed62";
}
.mbsc-ic-file-zip::before {
  content: "\ed63";
}
.mbsc-ic-file::before {
  content: "\ed64";
}
.mbsc-ic-file2::before {
  content: "\ed65";
}
.mbsc-ic-file3::before {
  content: "\ed66";
}
.mbsc-ic-file4::before {
  content: "\ed67";
}
.mbsc-ic-film::before {
  content: "\ed68";
}
.mbsc-ic-filter::before {
  content: "\ed69";
}
.mbsc-ic-filter2::before {
  content: "\ed6a";
}
.mbsc-ic-finder::before {
  content: "\ed6b";
}
.mbsc-ic-fire::before {
  content: "\ed6c";
}
.mbsc-ic-firefox::before {
  content: "\ed6d";
}
.mbsc-ic-first::before {
  content: "\ed6e";
}
.mbsc-ic-flag::before {
  content: "\ed6f";
}
.mbsc-ic-flattr::before {
  content: "\ed70";
}
.mbsc-ic-flickr::before {
  content: "\ed71";
}
.mbsc-ic-flickr2::before {
  content: "\ed72";
}
.mbsc-ic-flickr3::before {
  content: "\ed73";
}
.mbsc-ic-flickr4::before {
  content: "\ed74";
}
.mbsc-ic-flip::before {
  content: "\ed75";
}
.mbsc-ic-flip2::before {
  content: "\ed76";
}
.mbsc-ic-folder-open::before {
  content: "\ed77";
}
.mbsc-ic-folder::before {
  content: "\ed78";
}
.mbsc-ic-font::before {
  content: "\ed79";
}
.mbsc-ic-food::before {
  content: "\ed7a";
}
.mbsc-ic-forrst::before {
  content: "\ed7b";
}
.mbsc-ic-forrst2::before {
  content: "\ed7c";
}
.mbsc-ic-forward::before {
  content: "\ed7d";
}
.mbsc-ic-forward2::before {
  content: "\ed7f";
}
.mbsc-ic-forward3::before {
  content: "\ed7e";
}
.mbsc-ic-foundation-address-book::before {
  content: "\ed80";
}
.mbsc-ic-foundation-alert::before {
  content: "\ed81";
}
.mbsc-ic-foundation-align-center::before {
  content: "\ed82";
}
.mbsc-ic-foundation-align-justify::before {
  content: "\ed83";
}
.mbsc-ic-foundation-align-left::before {
  content: "\ed84";
}
.mbsc-ic-foundation-align-right::before {
  content: "\ed85";
}
.mbsc-ic-foundation-anchor::before {
  content: "\ed86";
}
.mbsc-ic-foundation-annotate::before {
  content: "\ed87";
}
.mbsc-ic-foundation-archive::before {
  content: "\ed88";
}
.mbsc-ic-foundation-arrow-down::before {
  content: "\ed89";
}
.mbsc-ic-foundation-arrow-left::before {
  content: "\ed8a";
}
.mbsc-ic-foundation-arrow-right::before {
  content: "\ed8b";
}
.mbsc-ic-foundation-arrow-up::before {
  content: "\ed8c";
}
.mbsc-ic-foundation-arrows-compress::before {
  content: "\ed8d";
}
.mbsc-ic-foundation-arrows-expand::before {
  content: "\ed8e";
}
.mbsc-ic-foundation-arrows-in::before {
  content: "\ed8f";
}
.mbsc-ic-foundation-arrows-out::before {
  content: "\ed90";
}
.mbsc-ic-foundation-asl::before {
  content: "\ed93";
}
.mbsc-ic-foundation-asterisk::before {
  content: "\ed91";
}
.mbsc-ic-foundation-at-sign::before {
  content: "\ed94";
}
.mbsc-ic-foundation-background-color::before {
  content: "\ed92";
}
.mbsc-ic-foundation-battery-empty::before {
  content: "\ed95";
}
.mbsc-ic-foundation-battery-full::before {
  content: "\ed96";
}
.mbsc-ic-foundation-battery-half::before {
  content: "\ed97";
}
.mbsc-ic-foundation-bitcoin-circle::before {
  content: "\ed9a";
}
.mbsc-ic-foundation-bitcoin::before {
  content: "\ed98";
}
.mbsc-ic-foundation-blind::before {
  content: "\ed99";
}
.mbsc-ic-foundation-bluetooth::before {
  content: "\ed9b";
}
.mbsc-ic-foundation-bold::before {
  content: "\ed9c";
}
.mbsc-ic-foundation-book-bookmark::before {
  content: "\ed9d";
}
.mbsc-ic-foundation-book::before {
  content: "\ed9e";
}
.mbsc-ic-foundation-bookmark::before {
  content: "\ed9f";
}
.mbsc-ic-foundation-braille::before {
  content: "\eda0";
}
.mbsc-ic-foundation-burst-new::before {
  content: "\eda1";
}
.mbsc-ic-foundation-burst-sale::before {
  content: "\eda2";
}
.mbsc-ic-foundation-burst::before {
  content: "\eda3";
}
.mbsc-ic-foundation-calendar::before {
  content: "\eda4";
}
.mbsc-ic-foundation-camera::before {
  content: "\eda5";
}
.mbsc-ic-foundation-check::before {
  content: "\eda6";
}
.mbsc-ic-foundation-checkbox::before {
  content: "\eda7";
}
.mbsc-ic-foundation-clipboard-notes::before {
  content: "\eda8";
}
.mbsc-ic-foundation-clipboard-pencil::before {
  content: "\eda9";
}
.mbsc-ic-foundation-clipboard::before {
  content: "\edaa";
}
.mbsc-ic-foundation-clock::before {
  content: "\edab";
}
.mbsc-ic-foundation-closed-caption::before {
  content: "\edac";
}
.mbsc-ic-foundation-cloud::before {
  content: "\edad";
}
.mbsc-ic-foundation-comment-minus::before {
  content: "\edae";
}
.mbsc-ic-foundation-comment-quotes::before {
  content: "\edaf";
}
.mbsc-ic-foundation-comment-video::before {
  content: "\edb0";
}
.mbsc-ic-foundation-comment::before {
  content: "\edb1";
}
.mbsc-ic-foundation-comments::before {
  content: "\edb2";
}
.mbsc-ic-foundation-compass::before {
  content: "\edb3";
}
.mbsc-ic-foundation-contrast::before {
  content: "\edb4";
}
.mbsc-ic-foundation-credit-card::before {
  content: "\edb5";
}
.mbsc-ic-foundation-crop::before {
  content: "\edb6";
}
.mbsc-ic-foundation-crown::before {
  content: "\edb7";
}
.mbsc-ic-foundation-css3::before {
  content: "\edb8";
}
.mbsc-ic-foundation-database::before {
  content: "\edb9";
}
.mbsc-ic-foundation-die-five::before {
  content: "\edba";
}
.mbsc-ic-foundation-die-four::before {
  content: "\edbb";
}
.mbsc-ic-foundation-die-one::before {
  content: "\edbc";
}
.mbsc-ic-foundation-die-six::before {
  content: "\edbd";
}
.mbsc-ic-foundation-die-three::before {
  content: "\edbe";
}
.mbsc-ic-foundation-die-two::before {
  content: "\edbf";
}
.mbsc-ic-foundation-dislike::before {
  content: "\edc0";
}
.mbsc-ic-foundation-dollar-bill::before {
  content: "\edc1";
}
.mbsc-ic-foundation-dollar::before {
  content: "\edc2";
}
.mbsc-ic-foundation-download::before {
  content: "\edc3";
}
.mbsc-ic-foundation-eject::before {
  content: "\edc4";
}
.mbsc-ic-foundation-elevator::before {
  content: "\edc5";
}
.mbsc-ic-foundation-euro::before {
  content: "\edc6";
}
.mbsc-ic-foundation-eye::before {
  content: "\edc7";
}
.mbsc-ic-foundation-fast-forward::before {
  content: "\edc8";
}
.mbsc-ic-foundation-female-symbol::before {
  content: "\edc9";
}
.mbsc-ic-foundation-female::before {
  content: "\edca";
}
.mbsc-ic-foundation-filter::before {
  content: "\edcb";
}
.mbsc-ic-foundation-first-aid::before {
  content: "\edcc";
}
.mbsc-ic-foundation-flag::before {
  content: "\edcd";
}
.mbsc-ic-foundation-folder-add::before {
  content: "\edce";
}
.mbsc-ic-foundation-folder-lock::before {
  content: "\edcf";
}
.mbsc-ic-foundation-folder::before {
  content: "\edd0";
}
.mbsc-ic-foundation-foot::before {
  content: "\edd1";
}
.mbsc-ic-foundation-foundation::before {
  content: "\edd2";
}
.mbsc-ic-foundation-graph-bar::before {
  content: "\edd3";
}
.mbsc-ic-foundation-graph-horizontal::before {
  content: "\edd4";
}
.mbsc-ic-foundation-graph-pie::before {
  content: "\edd5";
}
.mbsc-ic-foundation-graph-trend::before {
  content: "\edd6";
}
.mbsc-ic-foundation-guide-dog::before {
  content: "\edd7";
}
.mbsc-ic-foundation-hearing-aid::before {
  content: "\eddb";
}
.mbsc-ic-foundation-heart::before {
  content: "\eddc";
}
.mbsc-ic-foundation-home::before {
  content: "\edd8";
}
.mbsc-ic-foundation-html5::before {
  content: "\edd9";
}
.mbsc-ic-foundation-indent-less::before {
  content: "\edda";
}
.mbsc-ic-foundation-indent-more::before {
  content: "\eddd";
}
.mbsc-ic-foundation-info::before {
  content: "\edde";
}
.mbsc-ic-foundation-italic::before {
  content: "\eddf";
}
.mbsc-ic-foundation-key::before {
  content: "\ede0";
}
.mbsc-ic-foundation-laptop::before {
  content: "\ede1";
}
.mbsc-ic-foundation-layout::before {
  content: "\ede2";
}
.mbsc-ic-foundation-lightbulb::before {
  content: "\ede3";
}
.mbsc-ic-foundation-like::before {
  content: "\ede4";
}
.mbsc-ic-foundation-link::before {
  content: "\ede5";
}
.mbsc-ic-foundation-list-bullet::before {
  content: "\ede6";
}
.mbsc-ic-foundation-list-number::before {
  content: "\ede7";
}
.mbsc-ic-foundation-list-thumbnails::before {
  content: "\ede8";
}
.mbsc-ic-foundation-list::before {
  content: "\ede9";
}
.mbsc-ic-foundation-lock::before {
  content: "\edea";
}
.mbsc-ic-foundation-loop::before {
  content: "\edeb";
}
.mbsc-ic-foundation-magnifying-glass::before {
  content: "\edec";
}
.mbsc-ic-foundation-mail::before {
  content: "\eded";
}
.mbsc-ic-foundation-male-female::before {
  content: "\edee";
}
.mbsc-ic-foundation-male-symbol::before {
  content: "\edef";
}
.mbsc-ic-foundation-male::before {
  content: "\edf0";
}
.mbsc-ic-foundation-map::before {
  content: "\edf1";
}
.mbsc-ic-foundation-marker::before {
  content: "\edf2";
}
.mbsc-ic-foundation-megaphone::before {
  content: "\edf3";
}
.mbsc-ic-foundation-microphone::before {
  content: "\edf4";
}
.mbsc-ic-foundation-minus-circle::before {
  content: "\edf5";
}
.mbsc-ic-foundation-minus::before {
  content: "\edf6";
}
.mbsc-ic-foundation-mobile-signal::before {
  content: "\edf7";
}
.mbsc-ic-foundation-mobile::before {
  content: "\edf8";
}
.mbsc-ic-foundation-monitor::before {
  content: "\edf9";
}
.mbsc-ic-foundation-mountains::before {
  content: "\edfa";
}
.mbsc-ic-foundation-music::before {
  content: "\edfb";
}
.mbsc-ic-foundation-next::before {
  content: "\edfc";
}
.mbsc-ic-foundation-no-dogs::before {
  content: "\edfd";
}
.mbsc-ic-foundation-no-smoking::before {
  content: "\edfe";
}
.mbsc-ic-foundation-page-add::before {
  content: "\edff";
}
.mbsc-ic-foundation-page-copy::before {
  content: "\ee00";
}
.mbsc-ic-foundation-page-csv::before {
  content: "\ee01";
}
.mbsc-ic-foundation-page-delete::before {
  content: "\ee02";
}
.mbsc-ic-foundation-page-doc::before {
  content: "\ee03";
}
.mbsc-ic-foundation-page-edit::before {
  content: "\ee04";
}
.mbsc-ic-foundation-page-export-csv::before {
  content: "\ee05";
}
.mbsc-ic-foundation-page-export-doc::before {
  content: "\ee06";
}
.mbsc-ic-foundation-page-export-pdf::before {
  content: "\ee07";
}
.mbsc-ic-foundation-page-export::before {
  content: "\ee08";
}
.mbsc-ic-foundation-page-filled::before {
  content: "\ee09";
}
.mbsc-ic-foundation-page-multiple::before {
  content: "\ee0a";
}
.mbsc-ic-foundation-page-pdf::before {
  content: "\ee0b";
}
.mbsc-ic-foundation-page-remove::before {
  content: "\ee0c";
}
.mbsc-ic-foundation-page-search::before {
  content: "\ee0d";
}
.mbsc-ic-foundation-page::before {
  content: "\ee0e";
}
.mbsc-ic-foundation-paint-bucket::before {
  content: "\ee0f";
}
.mbsc-ic-foundation-paperclip::before {
  content: "\ee10";
}
.mbsc-ic-foundation-pause::before {
  content: "\ee11";
}
.mbsc-ic-foundation-paw::before {
  content: "\ee12";
}
.mbsc-ic-foundation-paypal::before {
  content: "\ee13";
}
.mbsc-ic-foundation-pencil::before {
  content: "\ee14";
}
.mbsc-ic-foundation-photo::before {
  content: "\ee15";
}
.mbsc-ic-foundation-play-circle::before {
  content: "\ee16";
}
.mbsc-ic-foundation-play-video::before {
  content: "\ee17";
}
.mbsc-ic-foundation-play::before {
  content: "\ee18";
}
.mbsc-ic-foundation-plus::before {
  content: "\ee19";
}
.mbsc-ic-foundation-pound::before {
  content: "\ee1a";
}
.mbsc-ic-foundation-power::before {
  content: "\ee1b";
}
.mbsc-ic-foundation-previous::before {
  content: "\ee1c";
}
.mbsc-ic-foundation-price-tag::before {
  content: "\ee1d";
}
.mbsc-ic-foundation-pricetag-multiple::before {
  content: "\ee1e";
}
.mbsc-ic-foundation-print::before {
  content: "\ee1f";
}
.mbsc-ic-foundation-prohibited::before {
  content: "\ee20";
}
.mbsc-ic-foundation-projection-screen::before {
  content: "\ee21";
}
.mbsc-ic-foundation-puzzle::before {
  content: "\ee22";
}
.mbsc-ic-foundation-quote::before {
  content: "\ee23";
}
.mbsc-ic-foundation-record::before {
  content: "\ee24";
}
.mbsc-ic-foundation-refresh::before {
  content: "\ee25";
}
.mbsc-ic-foundation-results-demographics::before {
  content: "\ee26";
}
.mbsc-ic-foundation-results::before {
  content: "\ee27";
}
.mbsc-ic-foundation-rewind-ten::before {
  content: "\ee28";
}
.mbsc-ic-foundation-rewind::before {
  content: "\ee29";
}
.mbsc-ic-foundation-rss::before {
  content: "\ee2a";
}
.mbsc-ic-foundation-safety-cone::before {
  content: "\ee2b";
}
.mbsc-ic-foundation-save::before {
  content: "\ee2c";
}
.mbsc-ic-foundation-share::before {
  content: "\ee2d";
}
.mbsc-ic-foundation-sheriff-badge::before {
  content: "\ee2e";
}
.mbsc-ic-foundation-shield::before {
  content: "\ee2f";
}
.mbsc-ic-foundation-shopping-bag::before {
  content: "\ee30";
}
.mbsc-ic-foundation-shopping-cart::before {
  content: "\ee31";
}
.mbsc-ic-foundation-shuffle::before {
  content: "\ee32";
}
.mbsc-ic-foundation-skull::before {
  content: "\ee33";
}
.mbsc-ic-foundation-social-500px::before {
  content: "\ee34";
}
.mbsc-ic-foundation-social-adobe::before {
  content: "\ee35";
}
.mbsc-ic-foundation-social-amazon::before {
  content: "\ee36";
}
.mbsc-ic-foundation-social-android::before {
  content: "\ee37";
}
.mbsc-ic-foundation-social-apple::before {
  content: "\ee38";
}
.mbsc-ic-foundation-social-behance::before {
  content: "\ee39";
}
.mbsc-ic-foundation-social-bing::before {
  content: "\ee3a";
}
.mbsc-ic-foundation-social-blogger::before {
  content: "\ee3b";
}
.mbsc-ic-foundation-social-delicious::before {
  content: "\ee3c";
}
.mbsc-ic-foundation-social-designer-news::before {
  content: "\ee3d";
}
.mbsc-ic-foundation-social-deviant-art::before {
  content: "\ee3e";
}
.mbsc-ic-foundation-social-digg::before {
  content: "\ee3f";
}
.mbsc-ic-foundation-social-dribbble::before {
  content: "\ee40";
}
.mbsc-ic-foundation-social-drive::before {
  content: "\ee41";
}
.mbsc-ic-foundation-social-dropbox::before {
  content: "\ee42";
}
.mbsc-ic-foundation-social-evernote::before {
  content: "\ee43";
}
.mbsc-ic-foundation-social-facebook::before {
  content: "\ee44";
}
.mbsc-ic-foundation-social-flickr::before {
  content: "\ee45";
}
.mbsc-ic-foundation-social-forrst::before {
  content: "\ee46";
}
.mbsc-ic-foundation-social-foursquare::before {
  content: "\ee47";
}
.mbsc-ic-foundation-social-game-center::before {
  content: "\ee48";
}
.mbsc-ic-foundation-social-github::before {
  content: "\ee49";
}
.mbsc-ic-foundation-social-google-plus::before {
  content: "\ee4a";
}
.mbsc-ic-foundation-social-hacker-news::before {
  content: "\ee4b";
}
.mbsc-ic-foundation-social-hi5::before {
  content: "\ee4c";
}
.mbsc-ic-foundation-social-instagram::before {
  content: "\ee4d";
}
.mbsc-ic-foundation-social-joomla::before {
  content: "\ee4e";
}
.mbsc-ic-foundation-social-lastfm::before {
  content: "\ee4f";
}
.mbsc-ic-foundation-social-linkedin::before {
  content: "\ee50";
}
.mbsc-ic-foundation-social-medium::before {
  content: "\ee51";
}
.mbsc-ic-foundation-social-myspace::before {
  content: "\ee52";
}
.mbsc-ic-foundation-social-orkut::before {
  content: "\ee53";
}
.mbsc-ic-foundation-social-path::before {
  content: "\ee54";
}
.mbsc-ic-foundation-social-picasa::before {
  content: "\ee55";
}
.mbsc-ic-foundation-social-pinterest::before {
  content: "\ee56";
}
.mbsc-ic-foundation-social-rdio::before {
  content: "\ee57";
}
.mbsc-ic-foundation-social-reddit::before {
  content: "\ee58";
}
.mbsc-ic-foundation-social-skillshare::before {
  content: "\ee59";
}
.mbsc-ic-foundation-social-skype::before {
  content: "\ee5a";
}
.mbsc-ic-foundation-social-smashing-mag::before {
  content: "\ee5b";
}
.mbsc-ic-foundation-social-snapchat::before {
  content: "\ee5c";
}
.mbsc-ic-foundation-social-spotify::before {
  content: "\ee5d";
}
.mbsc-ic-foundation-social-squidoo::before {
  content: "\ee5e";
}
.mbsc-ic-foundation-social-stack-overflow::before {
  content: "\ee5f";
}
.mbsc-ic-foundation-social-steam::before {
  content: "\ee60";
}
.mbsc-ic-foundation-social-stumbleupon::before {
  content: "\ee61";
}
.mbsc-ic-foundation-social-treehouse::before {
  content: "\ee62";
}
.mbsc-ic-foundation-social-tumblr::before {
  content: "\ee63";
}
.mbsc-ic-foundation-social-twitter::before {
  content: "\ee64";
}
.mbsc-ic-foundation-social-vimeo::before {
  content: "\ee65";
}
.mbsc-ic-foundation-social-windows::before {
  content: "\ee66";
}
.mbsc-ic-foundation-social-xbox::before {
  content: "\ee67";
}
.mbsc-ic-foundation-social-yahoo::before {
  content: "\ee68";
}
.mbsc-ic-foundation-social-yelp::before {
  content: "\ee69";
}
.mbsc-ic-foundation-social-youtube::before {
  content: "\ee6a";
}
.mbsc-ic-foundation-social-zerply::before {
  content: "\ee6b";
}
.mbsc-ic-foundation-social-zurb::before {
  content: "\ee6c";
}
.mbsc-ic-foundation-sound::before {
  content: "\ee6d";
}
.mbsc-ic-foundation-star::before {
  content: "\ee6e";
}
.mbsc-ic-foundation-stop::before {
  content: "\ee6f";
}
.mbsc-ic-foundation-strikethrough::before {
  content: "\ee70";
}
.mbsc-ic-foundation-subscript::before {
  content: "\ee71";
}
.mbsc-ic-foundation-superscript::before {
  content: "\ee72";
}
.mbsc-ic-foundation-tablet-landscape::before {
  content: "\ee73";
}
.mbsc-ic-foundation-tablet-portrait::before {
  content: "\ee74";
}
.mbsc-ic-foundation-target-two::before {
  content: "\ee75";
}
.mbsc-ic-foundation-target::before {
  content: "\ee76";
}
.mbsc-ic-foundation-telephone-accessible::before {
  content: "\ee77";
}
.mbsc-ic-foundation-telephone::before {
  content: "\ee78";
}
.mbsc-ic-foundation-text-color::before {
  content: "\ee79";
}
.mbsc-ic-foundation-thumbnails::before {
  content: "\ee7a";
}
.mbsc-ic-foundation-ticket::before {
  content: "\ee7b";
}
.mbsc-ic-foundation-torso-business::before {
  content: "\ee7c";
}
.mbsc-ic-foundation-torso-female::before {
  content: "\ee7d";
}
.mbsc-ic-foundation-torso::before {
  content: "\ee7e";
}
.mbsc-ic-foundation-torsos-all-female::before {
  content: "\ee7f";
}
.mbsc-ic-foundation-torsos-all::before {
  content: "\ee80";
}
.mbsc-ic-foundation-torsos-female-male::before {
  content: "\ee81";
}
.mbsc-ic-foundation-torsos-male-female::before {
  content: "\ee82";
}
.mbsc-ic-foundation-torsos::before {
  content: "\ee83";
}
.mbsc-ic-foundation-trash::before {
  content: "\ee84";
}
.mbsc-ic-foundation-trees::before {
  content: "\ee85";
}
.mbsc-ic-foundation-trophy::before {
  content: "\ee86";
}
.mbsc-ic-foundation-underline::before {
  content: "\ee87";
}
.mbsc-ic-foundation-universal-access::before {
  content: "\ee88";
}
.mbsc-ic-foundation-unlink::before {
  content: "\ee89";
}
.mbsc-ic-foundation-unlock::before {
  content: "\ee8a";
}
.mbsc-ic-foundation-upload-cloud::before {
  content: "\ee8b";
}
.mbsc-ic-foundation-upload::before {
  content: "\ee8c";
}
.mbsc-ic-foundation-usb::before {
  content: "\ee8d";
}
.mbsc-ic-foundation-video::before {
  content: "\ee8e";
}
.mbsc-ic-foundation-volume-none::before {
  content: "\ee8f";
}
.mbsc-ic-foundation-volume-strike::before {
  content: "\ee90";
}
.mbsc-ic-foundation-volume::before {
  content: "\ee91";
}
.mbsc-ic-foundation-web::before {
  content: "\ee92";
}
.mbsc-ic-foundation-wheelchair::before {
  content: "\ee93";
}
.mbsc-ic-foundation-widget::before {
  content: "\ee94";
}
.mbsc-ic-foundation-wrench::before {
  content: "\ee95";
}
.mbsc-ic-foundation-x-circle::before {
  content: "\ee96";
}
.mbsc-ic-foundation-x::before {
  content: "\ee97";
}
.mbsc-ic-foundation-yen::before {
  content: "\ee98";
}
.mbsc-ic-foundation-zoom-in::before {
  content: "\ee99";
}
.mbsc-ic-foundation-zoom-out::before {
  content: "\ee9a";
}
.mbsc-ic-foursquare::before {
  content: "\ee9b";
}
.mbsc-ic-foursquare2::before {
  content: "\ee9c";
}
.mbsc-ic-gift::before {
  content: "\ee9d";
}
.mbsc-ic-github::before {
  content: "\ee9e";
}
.mbsc-ic-github2::before {
  content: "\ee9f";
}
.mbsc-ic-github3::before {
  content: "\eea0";
}
.mbsc-ic-github4::before {
  content: "\eea1";
}
.mbsc-ic-github5::before {
  content: "\eea2";
}
.mbsc-ic-glass::before {
  content: "\eea3";
}
.mbsc-ic-globe::before {
  content: "\eea4";
}
.mbsc-ic-google-drive::before {
  content: "\eea5";
}
.mbsc-ic-google-plus::before {
  content: "\eea6";
}
.mbsc-ic-google-plus2::before {
  content: "\eea7";
}
.mbsc-ic-google-plus3::before {
  content: "\eea8";
}
.mbsc-ic-google-plus4::before {
  content: "\eea9";
}
.mbsc-ic-google::before {
  content: "\eeaa";
}
.mbsc-ic-grin::before {
  content: "\eeab";
}
.mbsc-ic-grin2::before {
  content: "\eeac";
}
.mbsc-ic-hammer::before {
  content: "\eead";
}
.mbsc-ic-hammer2::before {
  content: "\eeae";
}
.mbsc-ic-happy::before {
  content: "\eeaf";
}
.mbsc-ic-happy2::before {
  content: "\eeb0";
}
.mbsc-ic-headphones::before {
  content: "\eeb1";
}
.mbsc-ic-heart-broken::before {
  content: "\eeb2";
}
.mbsc-ic-heart::before {
  content: "\eeb3";
}
.mbsc-ic-heart2::before {
  content: "\eeb4";
}
.mbsc-ic-history::before {
  content: "\eeb5";
}
.mbsc-ic-home::before {
  content: "\eeb6";
}
.mbsc-ic-home2::before {
  content: "\eeb7";
}
.mbsc-ic-home3::before {
  content: "\eeb8";
}
.mbsc-ic-html5::before {
  content: "\eeb9";
}
.mbsc-ic-html52::before {
  content: "\eeba";
}
.mbsc-ic-image::before {
  content: "\eebb";
}
.mbsc-ic-image2::before {
  content: "\eebc";
}
.mbsc-ic-images::before {
  content: "\eebd";
}
.mbsc-ic-indent-decrease::before {
  content: "\eebe";
}
.mbsc-ic-indent-increase::before {
  content: "\eebf";
}
.mbsc-ic-info::before {
  content: "\eec0";
}
.mbsc-ic-info2::before {
  content: "\eec1";
}
.mbsc-ic-insert-template::before {
  content: "\eec2";
}
.mbsc-ic-instagram::before {
  content: "\eec4";
}
.mbsc-ic-ion-alert-circled::before {
  content: "\eec3";
}
.mbsc-ic-ion-alert::before {
  content: "\eec5";
}
.mbsc-ic-ion-android-add-contact::before {
  content: "\eec6";
}
.mbsc-ic-ion-android-add::before {
  content: "\eec7";
}
.mbsc-ic-ion-android-alarm::before {
  content: "\eec8";
}
.mbsc-ic-ion-android-archive::before {
  content: "\eec9";
}
.mbsc-ic-ion-android-arrow-back::before {
  content: "\eeca";
}
.mbsc-ic-ion-android-arrow-down-left::before {
  content: "\eecb";
}
.mbsc-ic-ion-android-arrow-down-right::before {
  content: "\eecc";
}
.mbsc-ic-ion-android-arrow-up-left::before {
  content: "\eecd";
}
.mbsc-ic-ion-android-arrow-up-right::before {
  content: "\eece";
}
.mbsc-ic-ion-android-battery::before {
  content: "\eecf";
}
.mbsc-ic-ion-android-book::before {
  content: "\eed0";
}
.mbsc-ic-ion-android-calendar::before {
  content: "\eed1";
}
.mbsc-ic-ion-android-call::before {
  content: "\eed2";
}
.mbsc-ic-ion-android-camera::before {
  content: "\eed3";
}
.mbsc-ic-ion-android-chat::before {
  content: "\eed4";
}
.mbsc-ic-ion-android-checkmark::before {
  content: "\eed5";
}
.mbsc-ic-ion-android-clock::before {
  content: "\eed6";
}
.mbsc-ic-ion-android-close::before {
  content: "\eed7";
}
.mbsc-ic-ion-android-contact::before {
  content: "\eed8";
}
.mbsc-ic-ion-android-contacts::before {
  content: "\eed9";
}
.mbsc-ic-ion-android-data::before {
  content: "\eeda";
}
.mbsc-ic-ion-android-developer::before {
  content: "\eedb";
}
.mbsc-ic-ion-android-display::before {
  content: "\eedc";
}
.mbsc-ic-ion-android-download::before {
  content: "\eedd";
}
.mbsc-ic-ion-android-dropdown::before {
  content: "\eedf";
}
.mbsc-ic-ion-android-earth::before {
  content: "\eede";
}
.mbsc-ic-ion-android-folder::before {
  content: "\eee0";
}
.mbsc-ic-ion-android-forums::before {
  content: "\eee1";
}
.mbsc-ic-ion-android-friends::before {
  content: "\eee2";
}
.mbsc-ic-ion-android-hand::before {
  content: "\eee3";
}
.mbsc-ic-ion-android-image::before {
  content: "\eee4";
}
.mbsc-ic-ion-android-inbox::before {
  content: "\eee5";
}
.mbsc-ic-ion-android-information::before {
  content: "\eee8";
}
.mbsc-ic-ion-android-keypad::before {
  content: "\eee6";
}
.mbsc-ic-ion-android-lightbulb::before {
  content: "\eee7";
}
.mbsc-ic-ion-android-locate::before {
  content: "\eee9";
}
.mbsc-ic-ion-android-location::before {
  content: "\eeea";
}
.mbsc-ic-ion-android-mail::before {
  content: "\eeeb";
}
.mbsc-ic-ion-android-microphone::before {
  content: "\eeec";
}
.mbsc-ic-ion-android-mixer::before {
  content: "\eeed";
}
.mbsc-ic-ion-android-more::before {
  content: "\eeee";
}
.mbsc-ic-ion-android-note::before {
  content: "\eeef";
}
.mbsc-ic-ion-android-playstore::before {
  content: "\eef0";
}
.mbsc-ic-ion-android-printer::before {
  content: "\eef1";
}
.mbsc-ic-ion-android-promotion::before {
  content: "\eef2";
}
.mbsc-ic-ion-android-reminder::before {
  content: "\eef3";
}
.mbsc-ic-ion-android-remove::before {
  content: "\eef4";
}
.mbsc-ic-ion-android-search::before {
  content: "\eef5";
}
.mbsc-ic-ion-android-send::before {
  content: "\eef6";
}
.mbsc-ic-ion-android-settings::before {
  content: "\eef7";
}
.mbsc-ic-ion-android-share::before {
  content: "\eef8";
}
.mbsc-ic-ion-android-social-user::before {
  content: "\eef9";
}
.mbsc-ic-ion-android-social::before {
  content: "\eefa";
}
.mbsc-ic-ion-android-sort::before {
  content: "\eefb";
}
.mbsc-ic-ion-android-star::before {
  content: "\eefc";
}
.mbsc-ic-ion-android-stopwatch::before {
  content: "\eefd";
}
.mbsc-ic-ion-android-storage::before {
  content: "\eefe";
}
.mbsc-ic-ion-android-system-back::before {
  content: "\eeff";
}
.mbsc-ic-ion-android-system-home::before {
  content: "\ef00";
}
.mbsc-ic-ion-android-system-windows::before {
  content: "\ef01";
}
.mbsc-ic-ion-android-timer::before {
  content: "\ef02";
}
.mbsc-ic-ion-android-trash::before {
  content: "\ef03";
}
.mbsc-ic-ion-android-volume::before {
  content: "\ef04";
}
.mbsc-ic-ion-android-wifi::before {
  content: "\ef05";
}
.mbsc-ic-ion-archive::before {
  content: "\ef06";
}
.mbsc-ic-ion-arrow-down-a::before {
  content: "\ef07";
}
.mbsc-ic-ion-arrow-down-b::before {
  content: "\ef08";
}
.mbsc-ic-ion-arrow-down-c::before {
  content: "\ef09";
}
.mbsc-ic-ion-arrow-expand::before {
  content: "\ef0a";
}
.mbsc-ic-ion-arrow-graph-down-left::before {
  content: "\ef0b";
}
.mbsc-ic-ion-arrow-graph-down-right::before {
  content: "\ef0c";
}
.mbsc-ic-ion-arrow-graph-up-left::before {
  content: "\ef0d";
}
.mbsc-ic-ion-arrow-graph-up-right::before {
  content: "\ef0e";
}
.mbsc-ic-ion-arrow-left-a::before {
  content: "\ef0f";
}
.mbsc-ic-ion-arrow-left-b::before {
  content: "\ef10";
}
.mbsc-ic-ion-arrow-left-c::before {
  content: "\ef11";
}
.mbsc-ic-ion-arrow-move::before {
  content: "\ef12";
}
.mbsc-ic-ion-arrow-resize::before {
  content: "\ef13";
}
.mbsc-ic-ion-arrow-return-left::before {
  content: "\ef14";
}
.mbsc-ic-ion-arrow-return-right::before {
  content: "\ef15";
}
.mbsc-ic-ion-arrow-right-a::before {
  content: "\ef16";
}
.mbsc-ic-ion-arrow-right-b::before {
  content: "\ef1b";
}
.mbsc-ic-ion-arrow-right-c::before {
  content: "\ef17";
}
.mbsc-ic-ion-arrow-shrink::before {
  content: "\ef18";
}
.mbsc-ic-ion-arrow-swap::before {
  content: "\ef19";
}
.mbsc-ic-ion-arrow-up-a::before {
  content: "\ef1a";
}
.mbsc-ic-ion-arrow-up-b::before {
  content: "\ef1c";
}
.mbsc-ic-ion-arrow-up-c::before {
  content: "\ef1d";
}
.mbsc-ic-ion-at::before {
  content: "\ef1e";
}
.mbsc-ic-ion-bag::before {
  content: "\ef1f";
}
.mbsc-ic-ion-battery-charging::before {
  content: "\ef20";
}
.mbsc-ic-ion-battery-empty::before {
  content: "\ef21";
}
.mbsc-ic-ion-battery-full::before {
  content: "\ef22";
}
.mbsc-ic-ion-battery-half::before {
  content: "\ef23";
}
.mbsc-ic-ion-battery-low::before {
  content: "\ef24";
}
.mbsc-ic-ion-beaker::before {
  content: "\ef25";
}
.mbsc-ic-ion-beer::before {
  content: "\ef26";
}
.mbsc-ic-ion-bluetooth::before {
  content: "\ef27";
}
.mbsc-ic-ion-bookmark::before {
  content: "\ef28";
}
.mbsc-ic-ion-briefcase::before {
  content: "\ef29";
}
.mbsc-ic-ion-bug::before {
  content: "\ef2a";
}
.mbsc-ic-ion-calculator::before {
  content: "\ef2b";
}
.mbsc-ic-ion-calendar::before {
  content: "\ef2c";
}
.mbsc-ic-ion-camera::before {
  content: "\ef2d";
}
.mbsc-ic-ion-card::before {
  content: "\ef2e";
}
.mbsc-ic-ion-chatbox-working::before {
  content: "\ef2f";
}
.mbsc-ic-ion-chatbox::before {
  content: "\ef30";
}
.mbsc-ic-ion-chatboxes::before {
  content: "\ef31";
}
.mbsc-ic-ion-chatbubble-working::before {
  content: "\ef32";
}
.mbsc-ic-ion-chatbubble::before {
  content: "\ef33";
}
.mbsc-ic-ion-chatbubbles::before {
  content: "\ef34";
}
.mbsc-ic-ion-checkmark-circled::before {
  content: "\ef35";
}
.mbsc-ic-ion-checkmark-round::before {
  content: "\ef36";
}
.mbsc-ic-ion-checkmark::before {
  content: "\ef37";
}
.mbsc-ic-ion-chevron-down::before {
  content: "\ef38";
}
.mbsc-ic-ion-chevron-left::before {
  content: "\ef39";
}
.mbsc-ic-ion-chevron-right::before {
  content: "\ef3a";
}
.mbsc-ic-ion-chevron-up::before {
  content: "\ef3b";
}
.mbsc-ic-ion-clipboard::before {
  content: "\ef3c";
}
.mbsc-ic-ion-clock::before {
  content: "\ef3d";
}
.mbsc-ic-ion-close-circled::before {
  content: "\ef3e";
}
.mbsc-ic-ion-close-round::before {
  content: "\ef3f";
}
.mbsc-ic-ion-close::before {
  content: "\ef40";
}
.mbsc-ic-ion-cloud::before {
  content: "\ef41";
}
.mbsc-ic-ion-code-download::before {
  content: "\ef42";
}
.mbsc-ic-ion-code-working::before {
  content: "\ef43";
}
.mbsc-ic-ion-code::before {
  content: "\ef44";
}
.mbsc-ic-ion-coffee::before {
  content: "\ef45";
}
.mbsc-ic-ion-compass::before {
  content: "\ef46";
}
.mbsc-ic-ion-compose::before {
  content: "\ef47";
}
.mbsc-ic-ion-connection-bars::before {
  content: "\ef48";
}
.mbsc-ic-ion-contrast::before {
  content: "\ef4b";
}
.mbsc-ic-ion-disc::before {
  content: "\ef49";
}
.mbsc-ic-ion-document-text::before {
  content: "\ef4a";
}
.mbsc-ic-ion-document::before {
  content: "\ef4c";
}
.mbsc-ic-ion-drag::before {
  content: "\ef4d";
}
.mbsc-ic-ion-earth::before {
  content: "\ef51";
}
.mbsc-ic-ion-edit::before {
  content: "\ef4e";
}
.mbsc-ic-ion-egg::before {
  content: "\ef4f";
}
.mbsc-ic-ion-eject::before {
  content: "\ef50";
}
.mbsc-ic-ion-email::before {
  content: "\ef52";
}
.mbsc-ic-ion-eye-disabled::before {
  content: "\ef53";
}
.mbsc-ic-ion-eye::before {
  content: "\ef54";
}
.mbsc-ic-ion-female::before {
  content: "\ef55";
}
.mbsc-ic-ion-filing::before {
  content: "\ef56";
}
.mbsc-ic-ion-film-marker::before {
  content: "\ef57";
}
.mbsc-ic-ion-flag::before {
  content: "\ef58";
}
.mbsc-ic-ion-flash-off::before {
  content: "\ef59";
}
.mbsc-ic-ion-flash::before {
  content: "\ef5a";
}
.mbsc-ic-ion-flask::before {
  content: "\ef5b";
}
.mbsc-ic-ion-folder::before {
  content: "\ef5c";
}
.mbsc-ic-ion-fork-repo::before {
  content: "\ef5d";
}
.mbsc-ic-ion-fork::before {
  content: "\ef5e";
}
.mbsc-ic-ion-forward::before {
  content: "\ef5f";
}
.mbsc-ic-ion-game-controller-a::before {
  content: "\ef60";
}
.mbsc-ic-ion-game-controller-b::before {
  content: "\ef61";
}
.mbsc-ic-ion-gear-a::before {
  content: "\ef62";
}
.mbsc-ic-ion-gear-b::before {
  content: "\ef63";
}
.mbsc-ic-ion-grid::before {
  content: "\ef64";
}
.mbsc-ic-ion-hammer::before {
  content: "\ef65";
}
.mbsc-ic-ion-headphone::before {
  content: "\ef66";
}
.mbsc-ic-ion-heart::before {
  content: "\ef67";
}
.mbsc-ic-ion-help-buoy::before {
  content: "\ef68";
}
.mbsc-ic-ion-help-circled::before {
  content: "\ef69";
}
.mbsc-ic-ion-help::before {
  content: "\ef6a";
}
.mbsc-ic-ion-home::before {
  content: "\ef6b";
}
.mbsc-ic-ion-icecream::before {
  content: "\ef6c";
}
.mbsc-ic-ion-icon-social-google-plus-outline::before {
  content: "\ef6d";
}
.mbsc-ic-ion-icon-social-google-plus::before {
  content: "\ef6e";
}
.mbsc-ic-ion-image::before {
  content: "\ef6f";
}
.mbsc-ic-ion-images::before {
  content: "\ef70";
}
.mbsc-ic-ion-information-circled::before {
  content: "\ef71";
}
.mbsc-ic-ion-information::before {
  content: "\ef72";
}
.mbsc-ic-ion-ionic::before {
  content: "\ef73";
}
.mbsc-ic-ion-ios7-alarm-outline::before {
  content: "\ef74";
}
.mbsc-ic-ion-ios7-alarm::before {
  content: "\ef75";
}
.mbsc-ic-ion-ios7-albums-outline::before {
  content: "\ef76";
}
.mbsc-ic-ion-ios7-albums::before {
  content: "\ef77";
}
.mbsc-ic-ion-ios7-arrow-back::before {
  content: "\ef78";
}
.mbsc-ic-ion-ios7-arrow-down::before {
  content: "\ef79";
}
.mbsc-ic-ion-ios7-arrow-forward::before {
  content: "\ef7a";
}
.mbsc-ic-ion-ios7-arrow-left::before {
  content: "\ef7b";
}
.mbsc-ic-ion-ios7-arrow-right::before {
  content: "\ef7c";
}
.mbsc-ic-ion-ios7-arrow-thin-down::before {
  content: "\ef7d";
}
.mbsc-ic-ion-ios7-arrow-thin-left::before {
  content: "\ef7e";
}
.mbsc-ic-ion-ios7-arrow-thin-right::before {
  content: "\ef7f";
}
.mbsc-ic-ion-ios7-arrow-thin-up::before {
  content: "\ef80";
}
.mbsc-ic-ion-ios7-arrow-up::before {
  content: "\ef81";
}
.mbsc-ic-ion-ios7-at-outline::before {
  content: "\ef82";
}
.mbsc-ic-ion-ios7-at::before {
  content: "\ef83";
}
.mbsc-ic-ion-ios7-bell-outline::before {
  content: "\ef84";
}
.mbsc-ic-ion-ios7-bell::before {
  content: "\ef85";
}
.mbsc-ic-ion-ios7-bolt-outline::before {
  content: "\ef86";
}
.mbsc-ic-ion-ios7-bolt::before {
  content: "\ef87";
}
.mbsc-ic-ion-ios7-bookmarks-outline::before {
  content: "\ef88";
}
.mbsc-ic-ion-ios7-bookmarks::before {
  content: "\ef89";
}
.mbsc-ic-ion-ios7-box-outline::before {
  content: "\ef8a";
}
.mbsc-ic-ion-ios7-box::before {
  content: "\ef8b";
}
.mbsc-ic-ion-ios7-briefcase-outline::before {
  content: "\ef8c";
}
.mbsc-ic-ion-ios7-briefcase::before {
  content: "\ef8d";
}
.mbsc-ic-ion-ios7-browsers-outline::before {
  content: "\ef8e";
}
.mbsc-ic-ion-ios7-browsers::before {
  content: "\ef8f";
}
.mbsc-ic-ion-ios7-calculator-outline::before {
  content: "\ef90";
}
.mbsc-ic-ion-ios7-calculator::before {
  content: "\ef91";
}
.mbsc-ic-ion-ios7-calendar-outline::before {
  content: "\ef92";
}
.mbsc-ic-ion-ios7-calendar::before {
  content: "\ef93";
}
.mbsc-ic-ion-ios7-camera-outline::before {
  content: "\ef94";
}
.mbsc-ic-ion-ios7-camera::before {
  content: "\ef95";
}
.mbsc-ic-ion-ios7-cart-outline::before {
  content: "\ef96";
}
.mbsc-ic-ion-ios7-cart::before {
  content: "\ef97";
}
.mbsc-ic-ion-ios7-chatboxes-outline::before {
  content: "\ef98";
}
.mbsc-ic-ion-ios7-chatboxes::before {
  content: "\ef99";
}
.mbsc-ic-ion-ios7-chatbubble-outline::before {
  content: "\ef9d";
}
.mbsc-ic-ion-ios7-chatbubble::before {
  content: "\ef9a";
}
.mbsc-ic-ion-ios7-checkmark-empty::before {
  content: "\ef9b";
}
.mbsc-ic-ion-ios7-checkmark-outline::before {
  content: "\ef9c";
}
.mbsc-ic-ion-ios7-checkmark::before {
  content: "\ef9e";
}
.mbsc-ic-ion-ios7-circle-filled::before {
  content: "\ef9f";
}
.mbsc-ic-ion-ios7-circle-outline::before {
  content: "\efa0";
}
.mbsc-ic-ion-ios7-clock-outline::before {
  content: "\efa1";
}
.mbsc-ic-ion-ios7-clock::before {
  content: "\efa2";
}
.mbsc-ic-ion-ios7-close-empty::before {
  content: "\efa3";
}
.mbsc-ic-ion-ios7-close-outline::before {
  content: "\efa4";
}
.mbsc-ic-ion-ios7-close::before {
  content: "\efa5";
}
.mbsc-ic-ion-ios7-cloud-download-outline::before {
  content: "\efa6";
}
.mbsc-ic-ion-ios7-cloud-download::before {
  content: "\efa7";
}
.mbsc-ic-ion-ios7-cloud-outline::before {
  content: "\efa8";
}
.mbsc-ic-ion-ios7-cloud-upload-outline::before {
  content: "\efa9";
}
.mbsc-ic-ion-ios7-cloud-upload::before {
  content: "\efaa";
}
.mbsc-ic-ion-ios7-cloud::before {
  content: "\efab";
}
.mbsc-ic-ion-ios7-cloudy-night-outline::before {
  content: "\efac";
}
.mbsc-ic-ion-ios7-cloudy-night::before {
  content: "\efad";
}
.mbsc-ic-ion-ios7-cloudy-outline::before {
  content: "\efae";
}
.mbsc-ic-ion-ios7-cloudy::before {
  content: "\efaf";
}
.mbsc-ic-ion-ios7-cog-outline::before {
  content: "\efb0";
}
.mbsc-ic-ion-ios7-cog::before {
  content: "\efb1";
}
.mbsc-ic-ion-ios7-compose-outline::before {
  content: "\efb2";
}
.mbsc-ic-ion-ios7-compose::before {
  content: "\efb3";
}
.mbsc-ic-ion-ios7-contact-outline::before {
  content: "\efb4";
}
.mbsc-ic-ion-ios7-contact::before {
  content: "\efb5";
}
.mbsc-ic-ion-ios7-copy-outline::before {
  content: "\efb6";
}
.mbsc-ic-ion-ios7-copy::before {
  content: "\efb7";
}
.mbsc-ic-ion-ios7-download-outline::before {
  content: "\efb8";
}
.mbsc-ic-ion-ios7-download::before {
  content: "\efb9";
}
.mbsc-ic-ion-ios7-drag::before {
  content: "\efba";
}
.mbsc-ic-ion-ios7-email-outline::before {
  content: "\efbb";
}
.mbsc-ic-ion-ios7-email::before {
  content: "\efbc";
}
.mbsc-ic-ion-ios7-eye-outline::before {
  content: "\efbd";
}
.mbsc-ic-ion-ios7-eye::before {
  content: "\efbe";
}
.mbsc-ic-ion-ios7-fastforward-outline::before {
  content: "\efbf";
}
.mbsc-ic-ion-ios7-fastforward::before {
  content: "\efc0";
}
.mbsc-ic-ion-ios7-filing-outline::before {
  content: "\efc1";
}
.mbsc-ic-ion-ios7-filing::before {
  content: "\efc2";
}
.mbsc-ic-ion-ios7-film-outline::before {
  content: "\efc3";
}
.mbsc-ic-ion-ios7-film::before {
  content: "\efc4";
}
.mbsc-ic-ion-ios7-flag-outline::before {
  content: "\efc5";
}
.mbsc-ic-ion-ios7-flag::before {
  content: "\efc6";
}
.mbsc-ic-ion-ios7-folder-outline::before {
  content: "\efc7";
}
.mbsc-ic-ion-ios7-folder::before {
  content: "\efc8";
}
.mbsc-ic-ion-ios7-gear-outline::before {
  content: "\efc9";
}
.mbsc-ic-ion-ios7-gear::before {
  content: "\efca";
}
.mbsc-ic-ion-ios7-glasses-outline::before {
  content: "\efcb";
}
.mbsc-ic-ion-ios7-glasses::before {
  content: "\efcc";
}
.mbsc-ic-ion-ios7-heart-outline::before {
  content: "\efcd";
}
.mbsc-ic-ion-ios7-heart::before {
  content: "\efce";
}
.mbsc-ic-ion-ios7-help-empty::before {
  content: "\efcf";
}
.mbsc-ic-ion-ios7-help-outline::before {
  content: "\efd0";
}
.mbsc-ic-ion-ios7-help::before {
  content: "\efd1";
}
.mbsc-ic-ion-ios7-infinite-outline::before {
  content: "\efd2";
}
.mbsc-ic-ion-ios7-infinite::before {
  content: "\efd3";
}
.mbsc-ic-ion-ios7-information-empty::before {
  content: "\efd4";
}
.mbsc-ic-ion-ios7-information-outline::before {
  content: "\efd5";
}
.mbsc-ic-ion-ios7-information::before {
  content: "\efd6";
}
.mbsc-ic-ion-ios7-ionic-outline::before {
  content: "\efd7";
}
.mbsc-ic-ion-ios7-keypad-outline::before {
  content: "\efd8";
}
.mbsc-ic-ion-ios7-keypad::before {
  content: "\efd9";
}
.mbsc-ic-ion-ios7-lightbulb-outline::before {
  content: "\efda";
}
.mbsc-ic-ion-ios7-lightbulb::before {
  content: "\efdb";
}
.mbsc-ic-ion-ios7-location-outline::before {
  content: "\efdc";
}
.mbsc-ic-ion-ios7-location::before {
  content: "\efdd";
}
.mbsc-ic-ion-ios7-locked-outline::before {
  content: "\efde";
}
.mbsc-ic-ion-ios7-locked::before {
  content: "\efdf";
}
.mbsc-ic-ion-ios7-medkit-outline::before {
  content: "\efe0";
}
.mbsc-ic-ion-ios7-medkit::before {
  content: "\efe1";
}
.mbsc-ic-ion-ios7-mic-off::before {
  content: "\efe2";
}
.mbsc-ic-ion-ios7-mic-outline::before {
  content: "\efe3";
}
.mbsc-ic-ion-ios7-mic::before {
  content: "\efe4";
}
.mbsc-ic-ion-ios7-minus-empty::before {
  content: "\efe5";
}
.mbsc-ic-ion-ios7-minus-outline::before {
  content: "\efe6";
}
.mbsc-ic-ion-ios7-minus::before {
  content: "\efe7";
}
.mbsc-ic-ion-ios7-monitor-outline::before {
  content: "\efe8";
}
.mbsc-ic-ion-ios7-monitor::before {
  content: "\efe9";
}
.mbsc-ic-ion-ios7-moon-outline::before {
  content: "\efea";
}
.mbsc-ic-ion-ios7-moon::before {
  content: "\efeb";
}
.mbsc-ic-ion-ios7-more-outline::before {
  content: "\efec";
}
.mbsc-ic-ion-ios7-more::before {
  content: "\efed";
}
.mbsc-ic-ion-ios7-musical-note::before {
  content: "\efee";
}
.mbsc-ic-ion-ios7-musical-notes::before {
  content: "\efef";
}
.mbsc-ic-ion-ios7-navigate-outline::before {
  content: "\eff0";
}
.mbsc-ic-ion-ios7-navigate::before {
  content: "\eff1";
}
.mbsc-ic-ion-ios7-paperplane-outline::before {
  content: "\eff2";
}
.mbsc-ic-ion-ios7-paperplane::before {
  content: "\eff3";
}
.mbsc-ic-ion-ios7-partlysunny-outline::before {
  content: "\eff4";
}
.mbsc-ic-ion-ios7-partlysunny::before {
  content: "\eff5";
}
.mbsc-ic-ion-ios7-pause-outline::before {
  content: "\eff6";
}
.mbsc-ic-ion-ios7-pause::before {
  content: "\eff7";
}
.mbsc-ic-ion-ios7-people-outline::before {
  content: "\eff8";
}
.mbsc-ic-ion-ios7-people::before {
  content: "\eff9";
}
.mbsc-ic-ion-ios7-person-outline::before {
  content: "\effa";
}
.mbsc-ic-ion-ios7-person::before {
  content: "\effb";
}
.mbsc-ic-ion-ios7-personadd-outline::before {
  content: "\effc";
}
.mbsc-ic-ion-ios7-personadd::before {
  content: "\effd";
}
.mbsc-ic-ion-ios7-photos-outline::before {
  content: "\effe";
}
.mbsc-ic-ion-ios7-photos::before {
  content: "\efff";
}
.mbsc-ic-ion-ios7-pie-outline::before {
  content: "\f000";
}
.mbsc-ic-ion-ios7-pie::before {
  content: "\f001";
}
.mbsc-ic-ion-ios7-play-outline::before {
  content: "\f002";
}
.mbsc-ic-ion-ios7-play::before {
  content: "\f003";
}
.mbsc-ic-ion-ios7-plus-empty::before {
  content: "\f004";
}
.mbsc-ic-ion-ios7-plus-outline::before {
  content: "\f005";
}
.mbsc-ic-ion-ios7-plus::before {
  content: "\f006";
}
.mbsc-ic-ion-ios7-pricetag-outline::before {
  content: "\f007";
}
.mbsc-ic-ion-ios7-pricetag::before {
  content: "\f008";
}
.mbsc-ic-ion-ios7-printer-outline::before {
  content: "\f009";
}
.mbsc-ic-ion-ios7-printer::before {
  content: "\f00a";
}
.mbsc-ic-ion-ios7-rainy-outline::before {
  content: "\f00b";
}
.mbsc-ic-ion-ios7-rainy::before {
  content: "\f00c";
}
.mbsc-ic-ion-ios7-recording-outline::before {
  content: "\f00d";
}
.mbsc-ic-ion-ios7-recording::before {
  content: "\f00e";
}
.mbsc-ic-ion-ios7-redo-outline::before {
  content: "\f00f";
}
.mbsc-ic-ion-ios7-redo::before {
  content: "\f010";
}
.mbsc-ic-ion-ios7-refresh-empty::before {
  content: "\f011";
}
.mbsc-ic-ion-ios7-refresh-outline::before {
  content: "\f012";
}
.mbsc-ic-ion-ios7-refresh::before {
  content: "\f013";
}
.mbsc-ic-ion-ios7-reload::before {
  content: "\f014";
}
.mbsc-ic-ion-ios7-rewind-outline::before {
  content: "\f015";
}
.mbsc-ic-ion-ios7-rewind::before {
  content: "\f016";
}
.mbsc-ic-ion-ios7-search-strong::before {
  content: "\f017";
}
.mbsc-ic-ion-ios7-search::before {
  content: "\f018";
}
.mbsc-ic-ion-ios7-skipbackward-outline::before {
  content: "\f019";
}
.mbsc-ic-ion-ios7-skipbackward::before {
  content: "\f01a";
}
.mbsc-ic-ion-ios7-skipforward-outline::before {
  content: "\f01b";
}
.mbsc-ic-ion-ios7-skipforward::before {
  content: "\f01c";
}
.mbsc-ic-ion-ios7-snowy::before {
  content: "\f01d";
}
.mbsc-ic-ion-ios7-speedometer-outline::before {
  content: "\f01e";
}
.mbsc-ic-ion-ios7-speedometer::before {
  content: "\f01f";
}
.mbsc-ic-ion-ios7-star-outline::before {
  content: "\f020";
}
.mbsc-ic-ion-ios7-star::before {
  content: "\f021";
}
.mbsc-ic-ion-ios7-stopwatch-outline::before {
  content: "\f022";
}
.mbsc-ic-ion-ios7-stopwatch::before {
  content: "\f023";
}
.mbsc-ic-ion-ios7-sunny-outline::before {
  content: "\f024";
}
.mbsc-ic-ion-ios7-sunny::before {
  content: "\f025";
}
.mbsc-ic-ion-ios7-telephone-outline::before {
  content: "\f026";
}
.mbsc-ic-ion-ios7-telephone::before {
  content: "\f027";
}
.mbsc-ic-ion-ios7-thunderstorm-outline::before {
  content: "\f028";
}
.mbsc-ic-ion-ios7-thunderstorm::before {
  content: "\f029";
}
.mbsc-ic-ion-ios7-time-outline::before {
  content: "\f02a";
}
.mbsc-ic-ion-ios7-time::before {
  content: "\f02b";
}
.mbsc-ic-ion-ios7-timer-outline::before {
  content: "\f02c";
}
.mbsc-ic-ion-ios7-timer::before {
  content: "\f02d";
}
.mbsc-ic-ion-ios7-trash-outline::before {
  content: "\f02e";
}
.mbsc-ic-ion-ios7-trash::before {
  content: "\f02f";
}
.mbsc-ic-ion-ios7-undo-outline::before {
  content: "\f030";
}
.mbsc-ic-ion-ios7-undo::before {
  content: "\f031";
}
.mbsc-ic-ion-ios7-unlocked-outline::before {
  content: "\f032";
}
.mbsc-ic-ion-ios7-unlocked::before {
  content: "\f033";
}
.mbsc-ic-ion-ios7-upload-outline::before {
  content: "\f034";
}
.mbsc-ic-ion-ios7-upload::before {
  content: "\f035";
}
.mbsc-ic-ion-ios7-videocam-outline::before {
  content: "\f036";
}
.mbsc-ic-ion-ios7-videocam::before {
  content: "\f037";
}
.mbsc-ic-ion-ios7-volume-high::before {
  content: "\f038";
}
.mbsc-ic-ion-ios7-volume-low::before {
  content: "\f039";
}
.mbsc-ic-ion-ios7-wineglass-outline::before {
  content: "\f03a";
}
.mbsc-ic-ion-ios7-wineglass::before {
  content: "\f03b";
}
.mbsc-ic-ion-ios7-world-outline::before {
  content: "\f03c";
}
.mbsc-ic-ion-ios7-world::before {
  content: "\f03d";
}
.mbsc-ic-ion-ipad::before {
  content: "\f03e";
}
.mbsc-ic-ion-iphone::before {
  content: "\f03f";
}
.mbsc-ic-ion-ipod::before {
  content: "\f040";
}
.mbsc-ic-ion-jet::before {
  content: "\f041";
}
.mbsc-ic-ion-key::before {
  content: "\f042";
}
.mbsc-ic-ion-knife::before {
  content: "\f043";
}
.mbsc-ic-ion-laptop::before {
  content: "\f044";
}
.mbsc-ic-ion-leaf::before {
  content: "\f045";
}
.mbsc-ic-ion-levels::before {
  content: "\f046";
}
.mbsc-ic-ion-lightbulb::before {
  content: "\f047";
}
.mbsc-ic-ion-link::before {
  content: "\f048";
}
.mbsc-ic-ion-load-a::before {
  content: "\f049";
}
.mbsc-ic-ion-load-b::before {
  content: "\f04a";
}
.mbsc-ic-ion-load-c::before {
  content: "\f04b";
}
.mbsc-ic-ion-load-d::before {
  content: "\f04c";
}
.mbsc-ic-ion-location::before {
  content: "\f04d";
}
.mbsc-ic-ion-locked::before {
  content: "\f04e";
}
.mbsc-ic-ion-log-in::before {
  content: "\f04f";
}
.mbsc-ic-ion-log-out::before {
  content: "\f050";
}
.mbsc-ic-ion-loop::before {
  content: "\f051";
}
.mbsc-ic-ion-magnet::before {
  content: "\f052";
}
.mbsc-ic-ion-male::before {
  content: "\f053";
}
.mbsc-ic-ion-man::before {
  content: "\f054";
}
.mbsc-ic-ion-map::before {
  content: "\f055";
}
.mbsc-ic-ion-medkit::before {
  content: "\f056";
}
.mbsc-ic-ion-mic-a::before {
  content: "\f057";
}
.mbsc-ic-ion-mic-b::before {
  content: "\f058";
}
.mbsc-ic-ion-mic-c::before {
  content: "\f059";
}
.mbsc-ic-ion-minus-circled::before {
  content: "\f05a";
}
.mbsc-ic-ion-minus-round::before {
  content: "\f05b";
}
.mbsc-ic-ion-minus::before {
  content: "\f05c";
}
.mbsc-ic-ion-model-s::before {
  content: "\f05d";
}
.mbsc-ic-ion-monitor::before {
  content: "\f05e";
}
.mbsc-ic-ion-more::before {
  content: "\f05f";
}
.mbsc-ic-ion-music-note::before {
  content: "\f060";
}
.mbsc-ic-ion-navicon-round::before {
  content: "\f061";
}
.mbsc-ic-ion-navicon::before {
  content: "\f062";
}
.mbsc-ic-ion-navigate::before {
  content: "\f063";
}
.mbsc-ic-ion-no-smoking::before {
  content: "\f064";
}
.mbsc-ic-ion-nuclear::before {
  content: "\f065";
}
.mbsc-ic-ion-paper-airplane::before {
  content: "\f066";
}
.mbsc-ic-ion-paperclip::before {
  content: "\f067";
}
.mbsc-ic-ion-pause::before {
  content: "\f068";
}
.mbsc-ic-ion-person-add::before {
  content: "\f069";
}
.mbsc-ic-ion-person-stalker::before {
  content: "\f06b";
}
.mbsc-ic-ion-person::before {
  content: "\f06a";
}
.mbsc-ic-ion-pie-graph::before {
  content: "\f06c";
}
.mbsc-ic-ion-pin::before {
  content: "\f06d";
}
.mbsc-ic-ion-pinpoint::before {
  content: "\f06e";
}
.mbsc-ic-ion-pizza::before {
  content: "\f06f";
}
.mbsc-ic-ion-plane::before {
  content: "\f070";
}
.mbsc-ic-ion-play::before {
  content: "\f071";
}
.mbsc-ic-ion-playstation::before {
  content: "\f072";
}
.mbsc-ic-ion-plus-circled::before {
  content: "\f073";
}
.mbsc-ic-ion-plus-round::before {
  content: "\f074";
}
.mbsc-ic-ion-plus::before {
  content: "\f075";
}
.mbsc-ic-ion-pound::before {
  content: "\f076";
}
.mbsc-ic-ion-power::before {
  content: "\f077";
}
.mbsc-ic-ion-pricetag::before {
  content: "\f078";
}
.mbsc-ic-ion-pricetags::before {
  content: "\f07b";
}
.mbsc-ic-ion-printer::before {
  content: "\f079";
}
.mbsc-ic-ion-radio-waves::before {
  content: "\f07a";
}
.mbsc-ic-ion-record::before {
  content: "\f07c";
}
.mbsc-ic-ion-refresh::before {
  content: "\f07d";
}
.mbsc-ic-ion-reply-all::before {
  content: "\f07e";
}
.mbsc-ic-ion-reply::before {
  content: "\f07f";
}
.mbsc-ic-ion-search::before {
  content: "\f080";
}
.mbsc-ic-ion-settings::before {
  content: "\f081";
}
.mbsc-ic-ion-share::before {
  content: "\f082";
}
.mbsc-ic-ion-shuffle::before {
  content: "\f083";
}
.mbsc-ic-ion-skip-backward::before {
  content: "\f084";
}
.mbsc-ic-ion-skip-forward::before {
  content: "\f085";
}
.mbsc-ic-ion-social-android-outline::before {
  content: "\f086";
}
.mbsc-ic-ion-social-android::before {
  content: "\f087";
}
.mbsc-ic-ion-social-apple-outline::before {
  content: "\f088";
}
.mbsc-ic-ion-social-apple::before {
  content: "\f089";
}
.mbsc-ic-ion-social-bitcoin-outline::before {
  content: "\f08a";
}
.mbsc-ic-ion-social-bitcoin::before {
  content: "\f08b";
}
.mbsc-ic-ion-social-buffer-outline::before {
  content: "\f08c";
}
.mbsc-ic-ion-social-buffer::before {
  content: "\f08d";
}
.mbsc-ic-ion-social-designernews-outline::before {
  content: "\f08e";
}
.mbsc-ic-ion-social-designernews::before {
  content: "\f08f";
}
.mbsc-ic-ion-social-dribbble-outline::before {
  content: "\f090";
}
.mbsc-ic-ion-social-dribbble::before {
  content: "\f091";
}
.mbsc-ic-ion-social-dropbox-outline::before {
  content: "\f092";
}
.mbsc-ic-ion-social-dropbox::before {
  content: "\f093";
}
.mbsc-ic-ion-social-facebook-outline::before {
  content: "\f094";
}
.mbsc-ic-ion-social-facebook::before {
  content: "\f095";
}
.mbsc-ic-ion-social-freebsd-devil::before {
  content: "\f096";
}
.mbsc-ic-ion-social-github-outline::before {
  content: "\f097";
}
.mbsc-ic-ion-social-github::before {
  content: "\f098";
}
.mbsc-ic-ion-social-googleplus-outline::before {
  content: "\f099";
}
.mbsc-ic-ion-social-googleplus::before {
  content: "\f09a";
}
.mbsc-ic-ion-social-hackernews-outline::before {
  content: "\f09b";
}
.mbsc-ic-ion-social-hackernews::before {
  content: "\f09c";
}
.mbsc-ic-ion-social-linkedin-outline::before {
  content: "\f09d";
}
.mbsc-ic-ion-social-linkedin::before {
  content: "\f09e";
}
.mbsc-ic-ion-social-pinterest-outline::before {
  content: "\f09f";
}
.mbsc-ic-ion-social-pinterest::before {
  content: "\f0a0";
}
.mbsc-ic-ion-social-reddit-outline::before {
  content: "\f0a1";
}
.mbsc-ic-ion-social-reddit::before {
  content: "\f0a2";
}
.mbsc-ic-ion-social-rss-outline::before {
  content: "\f0a3";
}
.mbsc-ic-ion-social-rss::before {
  content: "\f0a4";
}
.mbsc-ic-ion-social-skype-outline::before {
  content: "\f0a5";
}
.mbsc-ic-ion-social-skype::before {
  content: "\f0a6";
}
.mbsc-ic-ion-social-tumblr-outline::before {
  content: "\f0a7";
}
.mbsc-ic-ion-social-tumblr::before {
  content: "\f0a8";
}
.mbsc-ic-ion-social-tux::before {
  content: "\f0a9";
}
.mbsc-ic-ion-social-twitter-outline::before {
  content: "\f0aa";
}
.mbsc-ic-ion-social-twitter::before {
  content: "\f0ab";
}
.mbsc-ic-ion-social-vimeo-outline::before {
  content: "\f0ac";
}
.mbsc-ic-ion-social-vimeo::before {
  content: "\f0ad";
}
.mbsc-ic-ion-social-windows-outline::before {
  content: "\f0ae";
}
.mbsc-ic-ion-social-windows::before {
  content: "\f0af";
}
.mbsc-ic-ion-social-wordpress-outline::before {
  content: "\f0b0";
}
.mbsc-ic-ion-social-wordpress::before {
  content: "\f0b1";
}
.mbsc-ic-ion-social-yahoo-outline::before {
  content: "\f0b2";
}
.mbsc-ic-ion-social-yahoo::before {
  content: "\f0b3";
}
.mbsc-ic-ion-social-youtube-outline::before {
  content: "\f0b4";
}
.mbsc-ic-ion-social-youtube::before {
  content: "\f0b5";
}
.mbsc-ic-ion-speakerphone::before {
  content: "\f0b8";
}
.mbsc-ic-ion-speedometer::before {
  content: "\f0b6";
}
.mbsc-ic-ion-spoon::before {
  content: "\f0b7";
}
.mbsc-ic-ion-star::before {
  content: "\f0b9";
}
.mbsc-ic-ion-stats-bars::before {
  content: "\f0ba";
}
.mbsc-ic-ion-steam::before {
  content: "\f0bb";
}
.mbsc-ic-ion-stop::before {
  content: "\f0bc";
}
.mbsc-ic-ion-thermometer::before {
  content: "\f0bd";
}
.mbsc-ic-ion-thumbsdown::before {
  content: "\f0be";
}
.mbsc-ic-ion-thumbsup::before {
  content: "\f0bf";
}
.mbsc-ic-ion-trash-a::before {
  content: "\f0c0";
}
.mbsc-ic-ion-trash-b::before {
  content: "\f0c1";
}
.mbsc-ic-ion-umbrella::before {
  content: "\f0c5";
}
.mbsc-ic-ion-unlocked::before {
  content: "\f0c2";
}
.mbsc-ic-ion-upload::before {
  content: "\f0c3";
}
.mbsc-ic-ion-usb::before {
  content: "\f0c4";
}
.mbsc-ic-ion-videocamera::before {
  content: "\f0c6";
}
.mbsc-ic-ion-volume-high::before {
  content: "\f0c7";
}
.mbsc-ic-ion-volume-low::before {
  content: "\f0c8";
}
.mbsc-ic-ion-volume-medium::before {
  content: "\f0c9";
}
.mbsc-ic-ion-volume-mute::before {
  content: "\f0ca";
}
.mbsc-ic-ion-waterdrop::before {
  content: "\f0cb";
}
.mbsc-ic-ion-wifi::before {
  content: "\f0cc";
}
.mbsc-ic-ion-wineglass::before {
  content: "\f0cd";
}
.mbsc-ic-ion-woman::before {
  content: "\f0ce";
}
.mbsc-ic-ion-wrench::before {
  content: "\f0cf";
}
.mbsc-ic-ion-xbox::before {
  content: "\f0d0";
}
.mbsc-ic-ios-backspace::before {
  content: "\f0d1";
}
.mbsc-ic-italic::before {
  content: "\f0d2";
}
.mbsc-ic-joomla::before {
  content: "\f0d3";
}
.mbsc-ic-key::before {
  content: "\f0d4";
}
.mbsc-ic-key2::before {
  content: "\f0d5";
}
.mbsc-ic-keyboard::before {
  content: "\f0d6";
}
.mbsc-ic-lab::before {
  content: "\f0d7";
}
.mbsc-ic-lanyrd::before {
  content: "\f0d8";
}
.mbsc-ic-laptop::before {
  content: "\f0d9";
}
.mbsc-ic-last::before {
  content: "\f0da";
}
.mbsc-ic-lastfm::before {
  content: "\f0db";
}
.mbsc-ic-lastfm2::before {
  content: "\f0dc";
}
.mbsc-ic-leaf::before {
  content: "\f0dd";
}
.mbsc-ic-left-toright::before {
  content: "\f0de";
}
.mbsc-ic-library::before {
  content: "\f0df";
}
.mbsc-ic-libreoffice::before {
  content: "\f0e0";
}
.mbsc-ic-lightning::before {
  content: "\f0e1";
}
.mbsc-ic-line-banknote::before {
  content: "\f0e4";
}
.mbsc-ic-line-bubble::before {
  content: "\f0e5";
}
.mbsc-ic-line-bulb::before {
  content: "\f0e2";
}
.mbsc-ic-line-calendar::before {
  content: "\f0e3";
}
.mbsc-ic-line-camera::before {
  content: "\f0e6";
}
.mbsc-ic-line-clip::before {
  content: "\f0e7";
}
.mbsc-ic-line-clock::before {
  content: "\f0e8";
}
.mbsc-ic-line-cloud::before {
  content: "\f0e9";
}
.mbsc-ic-line-cup::before {
  content: "\f0ea";
}
.mbsc-ic-line-data::before {
  content: "\f0eb";
}
.mbsc-ic-line-diamond::before {
  content: "\f0ec";
}
.mbsc-ic-line-display::before {
  content: "\f0ed";
}
.mbsc-ic-line-eye::before {
  content: "\f0ee";
}
.mbsc-ic-line-fire::before {
  content: "\f0ef";
}
.mbsc-ic-line-food::before {
  content: "\f0f0";
}
.mbsc-ic-line-heart::before {
  content: "\f0f1";
}
.mbsc-ic-line-key::before {
  content: "\f0f2";
}
.mbsc-ic-line-lab::before {
  content: "\f0f3";
}
.mbsc-ic-line-like::before {
  content: "\f0f4";
}
.mbsc-ic-line-location::before {
  content: "\f0f5";
}
.mbsc-ic-line-lock::before {
  content: "\f0f6";
}
.mbsc-ic-line-mail::before {
  content: "\f0f7";
}
.mbsc-ic-line-megaphone::before {
  content: "\f0fa";
}
.mbsc-ic-line-music::before {
  content: "\f0f8";
}
.mbsc-ic-line-news::before {
  content: "\f0f9";
}
.mbsc-ic-line-note::before {
  content: "\f0fb";
}
.mbsc-ic-line-paperplane::before {
  content: "\f0fc";
}
.mbsc-ic-line-params::before {
  content: "\f0fd";
}
.mbsc-ic-line-pen::before {
  content: "\f0fe";
}
.mbsc-ic-line-phone::before {
  content: "\f0ff";
}
.mbsc-ic-line-photo::before {
  content: "\f100";
}
.mbsc-ic-line-search::before {
  content: "\f101";
}
.mbsc-ic-line-settings::before {
  content: "\f102";
}
.mbsc-ic-line-shop::before {
  content: "\f103";
}
.mbsc-ic-line-sound::before {
  content: "\f104";
}
.mbsc-ic-line-stack::before {
  content: "\f105";
}
.mbsc-ic-line-star::before {
  content: "\f106";
}
.mbsc-ic-line-study::before {
  content: "\f107";
}
.mbsc-ic-line-t-shirt::before {
  content: "\f108";
}
.mbsc-ic-line-tag::before {
  content: "\f109";
}
.mbsc-ic-line-trash::before {
  content: "\f10a";
}
.mbsc-ic-line-truck::before {
  content: "\f10b";
}
.mbsc-ic-line-tv::before {
  content: "\f10c";
}
.mbsc-ic-line-user::before {
  content: "\f10d";
}
.mbsc-ic-line-video::before {
  content: "\f10f";
}
.mbsc-ic-line-vynil::before {
  content: "\f10e";
}
.mbsc-ic-line-wallet::before {
  content: "\f110";
}
.mbsc-ic-line-world::before {
  content: "\f111";
}
.mbsc-ic-link::before {
  content: "\f112";
}
.mbsc-ic-linkedin::before {
  content: "\f113";
}
.mbsc-ic-list::before {
  content: "\f114";
}
.mbsc-ic-list2::before {
  content: "\f115";
}
.mbsc-ic-location::before {
  content: "\f116";
}
.mbsc-ic-location2::before {
  content: "\f117";
}
.mbsc-ic-lock::before {
  content: "\f118";
}
.mbsc-ic-lock2::before {
  content: "\f119";
}
.mbsc-ic-loop::before {
  content: "\f11a";
}
.mbsc-ic-loop2::before {
  content: "\f11b";
}
.mbsc-ic-loop3::before {
  content: "\f11c";
}
.mbsc-ic-magnet::before {
  content: "\f11d";
}
.mbsc-ic-mail::before {
  content: "\f11e";
}
.mbsc-ic-mail2::before {
  content: "\f11f";
}
.mbsc-ic-mail3::before {
  content: "\f120";
}
.mbsc-ic-mail4::before {
  content: "\f121";
}
.mbsc-ic-map::before {
  content: "\f122";
}
.mbsc-ic-map2::before {
  content: "\f123";
}
.mbsc-ic-material-3d-rotation::before {
  content: "\f124";
}
.mbsc-ic-material-ac-unit::before {
  content: "\f125";
}
.mbsc-ic-material-access-alarm::before {
  content: "\f126";
}
.mbsc-ic-material-access-alarms::before {
  content: "\f127";
}
.mbsc-ic-material-access-time::before {
  content: "\f128";
}
.mbsc-ic-material-accessibility::before {
  content: "\f129";
}
.mbsc-ic-material-accessible::before {
  content: "\f12a";
}
.mbsc-ic-material-account-balance-wallet::before {
  content: "\f12b";
}
.mbsc-ic-material-account-balance::before {
  content: "\f12c";
}
.mbsc-ic-material-account-box::before {
  content: "\f12d";
}
.mbsc-ic-material-account-child::before {
  content: "\f12e";
}
.mbsc-ic-material-account-circle::before {
  content: "\f12f";
}
.mbsc-ic-material-adb::before {
  content: "\f130";
}
.mbsc-ic-material-add-a-photo::before {
  content: "\f131";
}
.mbsc-ic-material-add-alarm::before {
  content: "\f132";
}
.mbsc-ic-material-add-alert::before {
  content: "\f133";
}
.mbsc-ic-material-add-box::before {
  content: "\f134";
}
.mbsc-ic-material-add-circle-outline::before {
  content: "\f135";
}
.mbsc-ic-material-add-circle::before {
  content: "\f136";
}
.mbsc-ic-material-add-location::before {
  content: "\f137";
}
.mbsc-ic-material-add-shopping-cart::before {
  content: "\f138";
}
.mbsc-ic-material-add-to-photos::before {
  content: "\f139";
}
.mbsc-ic-material-add-to-queue::before {
  content: "\f13a";
}
.mbsc-ic-material-add::before {
  content: "\f13b";
}
.mbsc-ic-material-adjust::before {
  content: "\f13c";
}
.mbsc-ic-material-airplanemode-off::before {
  content: "\f13d";
}
.mbsc-ic-material-airplanemode-on::before {
  content: "\f13e";
}
.mbsc-ic-material-airplay::before {
  content: "\f13f";
}
.mbsc-ic-material-airport-shuttle::before {
  content: "\f140";
}
.mbsc-ic-material-alarm-add::before {
  content: "\f141";
}
.mbsc-ic-material-alarm-off::before {
  content: "\f142";
}
.mbsc-ic-material-alarm-on::before {
  content: "\f143";
}
.mbsc-ic-material-alarm::before {
  content: "\f144";
}
.mbsc-ic-material-album::before {
  content: "\f145";
}
.mbsc-ic-material-all-inclusive::before {
  content: "\f146";
}
.mbsc-ic-material-android::before {
  content: "\f147";
}
.mbsc-ic-material-announcement::before {
  content: "\f148";
}
.mbsc-ic-material-apps::before {
  content: "\f149";
}
.mbsc-ic-material-archive::before {
  content: "\f14a";
}
.mbsc-ic-material-arrow-back::before {
  content: "\f14b";
}
.mbsc-ic-material-arrow-downward::before {
  content: "\f14c";
}
.mbsc-ic-material-arrow-drop-down-circle::before {
  content: "\f14d";
}
.mbsc-ic-material-arrow-drop-down::before {
  content: "\f14e";
}
.mbsc-ic-material-arrow-drop-up::before {
  content: "\f14f";
}
.mbsc-ic-material-arrow-forward::before {
  content: "\f150";
}
.mbsc-ic-material-arrow-upward::before {
  content: "\f151";
}
.mbsc-ic-material-art-track::before {
  content: "\f152";
}
.mbsc-ic-material-aspect-ratio::before {
  content: "\f153";
}
.mbsc-ic-material-assessment::before {
  content: "\f154";
}
.mbsc-ic-material-assignment-ind::before {
  content: "\f155";
}
.mbsc-ic-material-assignment-late::before {
  content: "\f156";
}
.mbsc-ic-material-assignment-return::before {
  content: "\f157";
}
.mbsc-ic-material-assignment-returned::before {
  content: "\f158";
}
.mbsc-ic-material-assignment-turned-in::before {
  content: "\f159";
}
.mbsc-ic-material-assignment::before {
  content: "\f15a";
}
.mbsc-ic-material-assistant-photo::before {
  content: "\f15b";
}
.mbsc-ic-material-assistant::before {
  content: "\f15c";
}
.mbsc-ic-material-attach-file::before {
  content: "\f15d";
}
.mbsc-ic-material-attach-money::before {
  content: "\f15e";
}
.mbsc-ic-material-attachment::before {
  content: "\f15f";
}
.mbsc-ic-material-audiotrack::before {
  content: "\f160";
}
.mbsc-ic-material-autorenew::before {
  content: "\f161";
}
.mbsc-ic-material-av-timer::before {
  content: "\f162";
}
.mbsc-ic-material-backspace::before {
  content: "\f163";
}
.mbsc-ic-material-backup::before {
  content: "\f164";
}
.mbsc-ic-material-battery-alert::before {
  content: "\f165";
}
.mbsc-ic-material-battery-charging-full::before {
  content: "\f166";
}
.mbsc-ic-material-battery-full::before {
  content: "\f167";
}
.mbsc-ic-material-battery-unknown::before {
  content: "\f168";
}
.mbsc-ic-material-beach-access::before {
  content: "\f169";
}
.mbsc-ic-material-beenhere::before {
  content: "\f16a";
}
.mbsc-ic-material-block::before {
  content: "\f16b";
}
.mbsc-ic-material-bluetooth-audio::before {
  content: "\f16c";
}
.mbsc-ic-material-bluetooth-connected::before {
  content: "\f16d";
}
.mbsc-ic-material-bluetooth-disabled::before {
  content: "\f16e";
}
.mbsc-ic-material-bluetooth-searching::before {
  content: "\f16f";
}
.mbsc-ic-material-bluetooth::before {
  content: "\f170";
}
.mbsc-ic-material-blur-circular::before {
  content: "\f171";
}
.mbsc-ic-material-blur-linear::before {
  content: "\f172";
}
.mbsc-ic-material-blur-off::before {
  content: "\f173";
}
.mbsc-ic-material-blur-on::before {
  content: "\f174";
}
.mbsc-ic-material-book::before {
  content: "\f175";
}
.mbsc-ic-material-bookmark-border::before {
  content: "\f176";
}
.mbsc-ic-material-bookmark-outline::before {
  content: "\f177";
}
.mbsc-ic-material-bookmark::before {
  content: "\f178";
}
.mbsc-ic-material-border-all::before {
  content: "\f179";
}
.mbsc-ic-material-border-bottom::before {
  content: "\f17a";
}
.mbsc-ic-material-border-clear::before {
  content: "\f17b";
}
.mbsc-ic-material-border-color::before {
  content: "\f17c";
}
.mbsc-ic-material-border-horizontal::before {
  content: "\f17d";
}
.mbsc-ic-material-border-inner::before {
  content: "\f17e";
}
.mbsc-ic-material-border-left::before {
  content: "\f17f";
}
.mbsc-ic-material-border-outer::before {
  content: "\f180";
}
.mbsc-ic-material-border-right::before {
  content: "\f181";
}
.mbsc-ic-material-border-style::before {
  content: "\f182";
}
.mbsc-ic-material-border-top::before {
  content: "\f183";
}
.mbsc-ic-material-border-vertical::before {
  content: "\f184";
}
.mbsc-ic-material-brightness-1::before {
  content: "\f185";
}
.mbsc-ic-material-brightness-2::before {
  content: "\f186";
}
.mbsc-ic-material-brightness-3::before {
  content: "\f187";
}
.mbsc-ic-material-brightness-4::before {
  content: "\f188";
}
.mbsc-ic-material-brightness-5::before {
  content: "\f189";
}
.mbsc-ic-material-brightness-6::before {
  content: "\f18a";
}
.mbsc-ic-material-brightness-7::before {
  content: "\f18b";
}
.mbsc-ic-material-brightness-auto::before {
  content: "\f18c";
}
.mbsc-ic-material-brightness-high::before {
  content: "\f18d";
}
.mbsc-ic-material-brightness-low::before {
  content: "\f18e";
}
.mbsc-ic-material-brightness-medium::before {
  content: "\f18f";
}
.mbsc-ic-material-broken-image::before {
  content: "\f190";
}
.mbsc-ic-material-brush::before {
  content: "\f191";
}
.mbsc-ic-material-bug-report::before {
  content: "\f192";
}
.mbsc-ic-material-build::before {
  content: "\f193";
}
.mbsc-ic-material-business-center::before {
  content: "\f194";
}
.mbsc-ic-material-business::before {
  content: "\f195";
}
.mbsc-ic-material-cached::before {
  content: "\f196";
}
.mbsc-ic-material-cake::before {
  content: "\f197";
}
.mbsc-ic-material-call-end::before {
  content: "\f198";
}
.mbsc-ic-material-call-made::before {
  content: "\f199";
}
.mbsc-ic-material-call-merge::before {
  content: "\f19a";
}
.mbsc-ic-material-call-missed-outgoing::before {
  content: "\f19b";
}
.mbsc-ic-material-call-missed::before {
  content: "\f19c";
}
.mbsc-ic-material-call-received::before {
  content: "\f19d";
}
.mbsc-ic-material-call-split::before {
  content: "\f19e";
}
.mbsc-ic-material-call::before {
  content: "\f19f";
}
.mbsc-ic-material-camera-alt::before {
  content: "\f1a0";
}
.mbsc-ic-material-camera-enhance::before {
  content: "\f1a1";
}
.mbsc-ic-material-camera-front::before {
  content: "\f1a2";
}
.mbsc-ic-material-camera-rear::before {
  content: "\f1a3";
}
.mbsc-ic-material-camera-roll::before {
  content: "\f1a4";
}
.mbsc-ic-material-camera::before {
  content: "\f1a5";
}
.mbsc-ic-material-cancel::before {
  content: "\f1a6";
}
.mbsc-ic-material-card-giftcard::before {
  content: "\f1a7";
}
.mbsc-ic-material-card-membership::before {
  content: "\f1a8";
}
.mbsc-ic-material-card-travel::before {
  content: "\f1a9";
}
.mbsc-ic-material-casino::before {
  content: "\f1aa";
}
.mbsc-ic-material-cast-connected::before {
  content: "\f1ab";
}
.mbsc-ic-material-cast::before {
  content: "\f1ac";
}
.mbsc-ic-material-center-focus-strong::before {
  content: "\f1ad";
}
.mbsc-ic-material-center-focus-weak::before {
  content: "\f1ae";
}
.mbsc-ic-material-change-history::before {
  content: "\f1af";
}
.mbsc-ic-material-chat-bubble-outline::before {
  content: "\f1b0";
}
.mbsc-ic-material-chat-bubble::before {
  content: "\f1b1";
}
.mbsc-ic-material-chat::before {
  content: "\f1b2";
}
.mbsc-ic-material-check-box-outline-blank::before {
  content: "\f1b3";
}
.mbsc-ic-material-check-box::before {
  content: "\f1b4";
}
.mbsc-ic-material-check-circle::before {
  content: "\f1b5";
}
.mbsc-ic-material-check::before {
  content: "\f1b6";
}
.mbsc-ic-material-chevron-left::before {
  content: "\f1b7";
}
.mbsc-ic-material-chevron-right::before {
  content: "\f1b8";
}
.mbsc-ic-material-child-care::before {
  content: "\f1b9";
}
.mbsc-ic-material-child-friendly::before {
  content: "\f1ba";
}
.mbsc-ic-material-class::before {
  content: "\f1bb";
}
.mbsc-ic-material-clear-all::before {
  content: "\f1bc";
}
.mbsc-ic-material-clear::before {
  content: "\f1bd";
}
.mbsc-ic-material-close::before {
  content: "\f1be";
}
.mbsc-ic-material-closed-caption::before {
  content: "\f1bf";
}
.mbsc-ic-material-cloud-circle::before {
  content: "\f1c0";
}
.mbsc-ic-material-cloud-done::before {
  content: "\f1c1";
}
.mbsc-ic-material-cloud-download::before {
  content: "\f1c2";
}
.mbsc-ic-material-cloud-off::before {
  content: "\f1c3";
}
.mbsc-ic-material-cloud-queue::before {
  content: "\f1c4";
}
.mbsc-ic-material-cloud-upload::before {
  content: "\f1c5";
}
.mbsc-ic-material-cloud::before {
  content: "\f1c6";
}
.mbsc-ic-material-code::before {
  content: "\f1c7";
}
.mbsc-ic-material-collections-bookmark::before {
  content: "\f1c8";
}
.mbsc-ic-material-collections::before {
  content: "\f1c9";
}
.mbsc-ic-material-color-lens::before {
  content: "\f1ca";
}
.mbsc-ic-material-colorize::before {
  content: "\f1cb";
}
.mbsc-ic-material-comment::before {
  content: "\f1cc";
}
.mbsc-ic-material-compare-arrows::before {
  content: "\f1cd";
}
.mbsc-ic-material-compare::before {
  content: "\f1ce";
}
.mbsc-ic-material-computer::before {
  content: "\f1cf";
}
.mbsc-ic-material-contact-mail::before {
  content: "\f1d0";
}
.mbsc-ic-material-contact-phone::before {
  content: "\f1d1";
}
.mbsc-ic-material-contacts::before {
  content: "\f1d2";
}
.mbsc-ic-material-content-copy::before {
  content: "\f1d4";
}
.mbsc-ic-material-content-cut::before {
  content: "\f1d3";
}
.mbsc-ic-material-content-paste::before {
  content: "\f1d5";
}
.mbsc-ic-material-control-point-duplicate::before {
  content: "\f1d6";
}
.mbsc-ic-material-control-point::before {
  content: "\f1d7";
}
.mbsc-ic-material-copyright::before {
  content: "\f1d8";
}
.mbsc-ic-material-create-new-folder::before {
  content: "\f1d9";
}
.mbsc-ic-material-create::before {
  content: "\f1da";
}
.mbsc-ic-material-credit-card::before {
  content: "\f1db";
}
.mbsc-ic-material-crop-16-9::before {
  content: "\f1dc";
}
.mbsc-ic-material-crop-3-2::before {
  content: "\f1dd";
}
.mbsc-ic-material-crop-5-4::before {
  content: "\f1de";
}
.mbsc-ic-material-crop-7-5::before {
  content: "\f1df";
}
.mbsc-ic-material-crop-din::before {
  content: "\f1e0";
}
.mbsc-ic-material-crop-free::before {
  content: "\f1e1";
}
.mbsc-ic-material-crop-landscape::before {
  content: "\f1e2";
}
.mbsc-ic-material-crop-original::before {
  content: "\f1e3";
}
.mbsc-ic-material-crop-portrait::before {
  content: "\f1e4";
}
.mbsc-ic-material-crop-rotate::before {
  content: "\f1e5";
}
.mbsc-ic-material-crop-square::before {
  content: "\f1e6";
}
.mbsc-ic-material-crop::before {
  content: "\f1e7";
}
.mbsc-ic-material-d-rotation::before {
  content: "\f1e8";
}
.mbsc-ic-material-dashboard::before {
  content: "\f1e9";
}
.mbsc-ic-material-data-usage::before {
  content: "\f1ea";
}
.mbsc-ic-material-date-range::before {
  content: "\f1eb";
}
.mbsc-ic-material-dehaze::before {
  content: "\f1ec";
}
.mbsc-ic-material-delete::before {
  content: "\f1ed";
}
.mbsc-ic-material-description::before {
  content: "\f1ee";
}
.mbsc-ic-material-desktop-mac::before {
  content: "\f1ef";
}
.mbsc-ic-material-desktop-windows::before {
  content: "\f1f0";
}
.mbsc-ic-material-details::before {
  content: "\f1f1";
}
.mbsc-ic-material-developer-board::before {
  content: "\f1f2";
}
.mbsc-ic-material-developer-mode::before {
  content: "\f1f3";
}
.mbsc-ic-material-device-hub::before {
  content: "\f1f4";
}
.mbsc-ic-material-devices-other::before {
  content: "\f1f5";
}
.mbsc-ic-material-devices::before {
  content: "\f1f6";
}
.mbsc-ic-material-dialer-sip::before {
  content: "\f1f7";
}
.mbsc-ic-material-dialpad::before {
  content: "\f1f8";
}
.mbsc-ic-material-directions-bike::before {
  content: "\f1f9";
}
.mbsc-ic-material-directions-boat::before {
  content: "\f1fa";
}
.mbsc-ic-material-directions-bus::before {
  content: "\f1fb";
}
.mbsc-ic-material-directions-car::before {
  content: "\f1fc";
}
.mbsc-ic-material-directions-ferry::before {
  content: "\f1fd";
}
.mbsc-ic-material-directions-railway::before {
  content: "\f1fe";
}
.mbsc-ic-material-directions-run::before {
  content: "\f1ff";
}
.mbsc-ic-material-directions-subway::before {
  content: "\f200";
}
.mbsc-ic-material-directions-train::before {
  content: "\f201";
}
.mbsc-ic-material-directions-transit::before {
  content: "\f202";
}
.mbsc-ic-material-directions-walk::before {
  content: "\f203";
}
.mbsc-ic-material-directions::before {
  content: "\f204";
}
.mbsc-ic-material-disc-full::before {
  content: "\f205";
}
.mbsc-ic-material-dnd-forwardslash::before {
  content: "\f206";
}
.mbsc-ic-material-dnd-on::before {
  content: "\f207";
}
.mbsc-ic-material-dns::before {
  content: "\f208";
}
.mbsc-ic-material-do-not-disturb::before {
  content: "\f209";
}
.mbsc-ic-material-dock::before {
  content: "\f20a";
}
.mbsc-ic-material-domain::before {
  content: "\f20b";
}
.mbsc-ic-material-done-all::before {
  content: "\f20c";
}
.mbsc-ic-material-done::before {
  content: "\f20d";
}
.mbsc-ic-material-donut-large::before {
  content: "\f20e";
}
.mbsc-ic-material-donut-small::before {
  content: "\f20f";
}
.mbsc-ic-material-drafts::before {
  content: "\f210";
}
.mbsc-ic-material-drag-handle::before {
  content: "\f211";
}
.mbsc-ic-material-drive-eta::before {
  content: "\f212";
}
.mbsc-ic-material-dvr::before {
  content: "\f213";
}
.mbsc-ic-material-edit-location::before {
  content: "\f214";
}
.mbsc-ic-material-edit::before {
  content: "\f215";
}
.mbsc-ic-material-eject::before {
  content: "\f216";
}
.mbsc-ic-material-email::before {
  content: "\f217";
}
.mbsc-ic-material-equalizer::before {
  content: "\f218";
}
.mbsc-ic-material-error::before {
  content: "\f219";
}
.mbsc-ic-material-event-available::before {
  content: "\f21a";
}
.mbsc-ic-material-event-busy::before {
  content: "\f21b";
}
.mbsc-ic-material-event-note::before {
  content: "\f21c";
}
.mbsc-ic-material-event::before {
  content: "\f21d";
}
.mbsc-ic-material-exit-to-app::before {
  content: "\f21e";
}
.mbsc-ic-material-expand-less::before {
  content: "\f21f";
}
.mbsc-ic-material-expand-more::before {
  content: "\f220";
}
.mbsc-ic-material-explicit::before {
  content: "\f221";
}
.mbsc-ic-material-explore::before {
  content: "\f222";
}
.mbsc-ic-material-exposure-minus-1::before {
  content: "\f223";
}
.mbsc-ic-material-exposure-minus-2::before {
  content: "\f224";
}
.mbsc-ic-material-exposure-plus-1::before {
  content: "\f225";
}
.mbsc-ic-material-exposure-plus-2::before {
  content: "\f226";
}
.mbsc-ic-material-exposure-zero::before {
  content: "\f227";
}
.mbsc-ic-material-exposure::before {
  content: "\f228";
}
.mbsc-ic-material-extension::before {
  content: "\f229";
}
.mbsc-ic-material-face-unlock::before {
  content: "\f22a";
}
.mbsc-ic-material-face::before {
  content: "\f22b";
}
.mbsc-ic-material-fast-forward::before {
  content: "\f22c";
}
.mbsc-ic-material-fast-rewind::before {
  content: "\f22d";
}
.mbsc-ic-material-favorite-outline::before {
  content: "\f22e";
}
.mbsc-ic-material-favorite::before {
  content: "\f22f";
}
.mbsc-ic-material-feedback::before {
  content: "\f230";
}
.mbsc-ic-material-fiber-manual-record::before {
  content: "\f231";
}
.mbsc-ic-material-fiber-new::before {
  content: "\f232";
}
.mbsc-ic-material-fiber-pin::before {
  content: "\f233";
}
.mbsc-ic-material-fiber-smart-record::before {
  content: "\f234";
}
.mbsc-ic-material-file-download::before {
  content: "\f235";
}
.mbsc-ic-material-file-upload::before {
  content: "\f236";
}
.mbsc-ic-material-film::before {
  content: "\f237";
}
.mbsc-ic-material-filter-1::before {
  content: "\f238";
}
.mbsc-ic-material-filter-2::before {
  content: "\f239";
}
.mbsc-ic-material-filter-3::before {
  content: "\f23a";
}
.mbsc-ic-material-filter-4::before {
  content: "\f23b";
}
.mbsc-ic-material-filter-5::before {
  content: "\f23c";
}
.mbsc-ic-material-filter-6::before {
  content: "\f23d";
}
.mbsc-ic-material-filter-7::before {
  content: "\f23e";
}
.mbsc-ic-material-filter-8::before {
  content: "\f23f";
}
.mbsc-ic-material-filter-9-plus::before {
  content: "\f240";
}
.mbsc-ic-material-filter-9::before {
  content: "\f241";
}
.mbsc-ic-material-filter-b-and-w::before {
  content: "\f242";
}
.mbsc-ic-material-filter-center-focus::before {
  content: "\f243";
}
.mbsc-ic-material-filter-drama::before {
  content: "\f244";
}
.mbsc-ic-material-filter-frames::before {
  content: "\f245";
}
.mbsc-ic-material-filter-hdr::before {
  content: "\f246";
}
.mbsc-ic-material-filter-list::before {
  content: "\f247";
}
.mbsc-ic-material-filter-none::before {
  content: "\f248";
}
.mbsc-ic-material-filter-tilt-shift::before {
  content: "\f249";
}
.mbsc-ic-material-filter-vintage::before {
  content: "\f24a";
}
.mbsc-ic-material-filter::before {
  content: "\f24b";
}
.mbsc-ic-material-find-in-page::before {
  content: "\f24c";
}
.mbsc-ic-material-find-replace::before {
  content: "\f24d";
}
.mbsc-ic-material-fingerprint::before {
  content: "\f24e";
}
.mbsc-ic-material-fitness-center::before {
  content: "\f24f";
}
.mbsc-ic-material-flag::before {
  content: "\f250";
}
.mbsc-ic-material-flare::before {
  content: "\f251";
}
.mbsc-ic-material-flash-auto::before {
  content: "\f252";
}
.mbsc-ic-material-flash-off::before {
  content: "\f253";
}
.mbsc-ic-material-flash-on::before {
  content: "\f254";
}
.mbsc-ic-material-flight-land::before {
  content: "\f255";
}
.mbsc-ic-material-flight-takeoff::before {
  content: "\f256";
}
.mbsc-ic-material-flight::before {
  content: "\f257";
}
.mbsc-ic-material-flip-to-back::before {
  content: "\f258";
}
.mbsc-ic-material-flip-to-front::before {
  content: "\f259";
}
.mbsc-ic-material-flip::before {
  content: "\f25a";
}
.mbsc-ic-material-folder-open::before {
  content: "\f25b";
}
.mbsc-ic-material-folder-shared::before {
  content: "\f25c";
}
.mbsc-ic-material-folder-special::before {
  content: "\f25d";
}
.mbsc-ic-material-folder::before {
  content: "\f25e";
}
.mbsc-ic-material-font-download::before {
  content: "\f25f";
}
.mbsc-ic-material-format-align-center::before {
  content: "\f260";
}
.mbsc-ic-material-format-align-justify::before {
  content: "\f261";
}
.mbsc-ic-material-format-align-left::before {
  content: "\f262";
}
.mbsc-ic-material-format-align-right::before {
  content: "\f263";
}
.mbsc-ic-material-format-bold::before {
  content: "\f264";
}
.mbsc-ic-material-format-clear::before {
  content: "\f265";
}
.mbsc-ic-material-format-color-fill::before {
  content: "\f266";
}
.mbsc-ic-material-format-color-reset::before {
  content: "\f267";
}
.mbsc-ic-material-format-color-text::before {
  content: "\f268";
}
.mbsc-ic-material-format-indent-decrease::before {
  content: "\f269";
}
.mbsc-ic-material-format-indent-increase::before {
  content: "\f26a";
}
.mbsc-ic-material-format-ital::before {
  content: "\f26b";
}
.mbsc-ic-material-format-line-spacing::before {
  content: "\f26c";
}
.mbsc-ic-material-format-list-bulleted::before {
  content: "\f26d";
}
.mbsc-ic-material-format-list-numbered::before {
  content: "\f26e";
}
.mbsc-ic-material-format-paint::before {
  content: "\f26f";
}
.mbsc-ic-material-format-quote::before {
  content: "\f270";
}
.mbsc-ic-material-format-shapes::before {
  content: "\f271";
}
.mbsc-ic-material-format-size::before {
  content: "\f272";
}
.mbsc-ic-material-format-strikethrough::before {
  content: "\f273";
}
.mbsc-ic-material-format-textdirection-l-to-r::before {
  content: "\f274";
}
.mbsc-ic-material-format-textdirection-r-to-l::before {
  content: "\f275";
}
.mbsc-ic-material-format-underline::before {
  content: "\f276";
}
.mbsc-ic-material-forum::before {
  content: "\f277";
}
.mbsc-ic-material-forward-10::before {
  content: "\f278";
}
.mbsc-ic-material-forward-30::before {
  content: "\f279";
}
.mbsc-ic-material-forward-5::before {
  content: "\f27a";
}
.mbsc-ic-material-forward::before {
  content: "\f27b";
}
.mbsc-ic-material-free-breakfast::before {
  content: "\f27c";
}
.mbsc-ic-material-fullscreen-exit::before {
  content: "\f27d";
}
.mbsc-ic-material-fullscreen::before {
  content: "\f27e";
}
.mbsc-ic-material-functions::before {
  content: "\f27f";
}
.mbsc-ic-material-gamepad::before {
  content: "\f280";
}
.mbsc-ic-material-games::before {
  content: "\f281";
}
.mbsc-ic-material-gavel::before {
  content: "\f282";
}
.mbsc-ic-material-gesture::before {
  content: "\f283";
}
.mbsc-ic-material-get-app::before {
  content: "\f284";
}
.mbsc-ic-material-gif::before {
  content: "\f285";
}
.mbsc-ic-material-golf-course::before {
  content: "\f286";
}
.mbsc-ic-material-gps-fixed::before {
  content: "\f287";
}
.mbsc-ic-material-gps-not-fixed::before {
  content: "\f288";
}
.mbsc-ic-material-gps-off::before {
  content: "\f289";
}
.mbsc-ic-material-grade::before {
  content: "\f28a";
}
.mbsc-ic-material-gradient::before {
  content: "\f28b";
}
.mbsc-ic-material-grain::before {
  content: "\f28c";
}
.mbsc-ic-material-graphic-eq::before {
  content: "\f28d";
}
.mbsc-ic-material-grid-off::before {
  content: "\f28e";
}
.mbsc-ic-material-grid-on::before {
  content: "\f28f";
}
.mbsc-ic-material-group-add::before {
  content: "\f290";
}
.mbsc-ic-material-group-work::before {
  content: "\f291";
}
.mbsc-ic-material-group::before {
  content: "\f292";
}
.mbsc-ic-material-hd::before {
  content: "\f293";
}
.mbsc-ic-material-hdr-off::before {
  content: "\f294";
}
.mbsc-ic-material-hdr-on::before {
  content: "\f295";
}
.mbsc-ic-material-hdr-strong::before {
  content: "\f296";
}
.mbsc-ic-material-hdr-weak::before {
  content: "\f297";
}
.mbsc-ic-material-headset-m::before {
  content: "\f298";
}
.mbsc-ic-material-headset::before {
  content: "\f299";
}
.mbsc-ic-material-healing::before {
  content: "\f29a";
}
.mbsc-ic-material-hearing::before {
  content: "\f29b";
}
.mbsc-ic-material-help-outline::before {
  content: "\f29c";
}
.mbsc-ic-material-help::before {
  content: "\f29d";
}
.mbsc-ic-material-high-quality::before {
  content: "\f29e";
}
.mbsc-ic-material-highlight-remove::before {
  content: "\f29f";
}
.mbsc-ic-material-history::before {
  content: "\f2a0";
}
.mbsc-ic-material-home::before {
  content: "\f2a1";
}
.mbsc-ic-material-hot-tub::before {
  content: "\f2a2";
}
.mbsc-ic-material-hotel::before {
  content: "\f2a3";
}
.mbsc-ic-material-hourglass-empty::before {
  content: "\f2a4";
}
.mbsc-ic-material-hourglass-full::before {
  content: "\f2a5";
}
.mbsc-ic-material-http::before {
  content: "\f2a6";
}
.mbsc-ic-material-https::before {
  content: "\f2a7";
}
.mbsc-ic-material-image-aspect-ratio::before {
  content: "\f2a8";
}
.mbsc-ic-material-image::before {
  content: "\f2a9";
}
.mbsc-ic-material-import-contacts::before {
  content: "\f2aa";
}
.mbsc-ic-material-import-export::before {
  content: "\f2ab";
}
.mbsc-ic-material-important-devices::before {
  content: "\f2ac";
}
.mbsc-ic-material-inbox::before {
  content: "\f2ad";
}
.mbsc-ic-material-indeterminate-check-box::before {
  content: "\f2ae";
}
.mbsc-ic-material-info-outline::before {
  content: "\f2af";
}
.mbsc-ic-material-info::before {
  content: "\f2b0";
}
.mbsc-ic-material-input::before {
  content: "\f2b1";
}
.mbsc-ic-material-insert-chart::before {
  content: "\f2b2";
}
.mbsc-ic-material-insert-comment::before {
  content: "\f2b3";
}
.mbsc-ic-material-insert-drive-file::before {
  content: "\f2b4";
}
.mbsc-ic-material-insert-emoticon::before {
  content: "\f2b5";
}
.mbsc-ic-material-insert-invitation::before {
  content: "\f2b6";
}
.mbsc-ic-material-insert-link::before {
  content: "\f2b7";
}
.mbsc-ic-material-insert-photo::before {
  content: "\f2b8";
}
.mbsc-ic-material-invert-colors-off::before {
  content: "\f2b9";
}
.mbsc-ic-material-invert-colors-on::before {
  content: "\f2ba";
}
.mbsc-ic-material-invert-colors::before {
  content: "\f2bb";
}
.mbsc-ic-material-iso::before {
  content: "\f2bc";
}
.mbsc-ic-material-keyboard-alt::before {
  content: "\f2bd";
}
.mbsc-ic-material-keyboard-arrow-down::before {
  content: "\f2be";
}
.mbsc-ic-material-keyboard-arrow-left::before {
  content: "\f2bf";
}
.mbsc-ic-material-keyboard-arrow-right::before {
  content: "\f2c0";
}
.mbsc-ic-material-keyboard-arrow-up::before {
  content: "\f2c1";
}
.mbsc-ic-material-keyboard-backspace::before {
  content: "\f2c2";
}
.mbsc-ic-material-keyboard-capslock::before {
  content: "\f2c3";
}
.mbsc-ic-material-keyboard-control::before {
  content: "\f2c4";
}
.mbsc-ic-material-keyboard-hide::before {
  content: "\f2c5";
}
.mbsc-ic-material-keyboard-return::before {
  content: "\f2c6";
}
.mbsc-ic-material-keyboard-tab::before {
  content: "\f2c7";
}
.mbsc-ic-material-keyboard-voice::before {
  content: "\f2c8";
}
.mbsc-ic-material-keyboard::before {
  content: "\f2c9";
}
.mbsc-ic-material-kitchen::before {
  content: "\f2ca";
}
.mbsc-ic-material-label-outline::before {
  content: "\f2cb";
}
.mbsc-ic-material-label::before {
  content: "\f2cc";
}
.mbsc-ic-material-landscape::before {
  content: "\f2cd";
}
.mbsc-ic-material-language::before {
  content: "\f2ce";
}
.mbsc-ic-material-laptop-chromebook::before {
  content: "\f2cf";
}
.mbsc-ic-material-laptop-mac::before {
  content: "\f2d0";
}
.mbsc-ic-material-laptop-windows::before {
  content: "\f2d1";
}
.mbsc-ic-material-laptop::before {
  content: "\f2d2";
}
.mbsc-ic-material-launch::before {
  content: "\f2d3";
}
.mbsc-ic-material-layers-clear::before {
  content: "\f2d4";
}
.mbsc-ic-material-layers::before {
  content: "\f2d5";
}
.mbsc-ic-material-leak-add::before {
  content: "\f2d6";
}
.mbsc-ic-material-leak-remove::before {
  content: "\f2d7";
}
.mbsc-ic-material-lens::before {
  content: "\f2d8";
}
.mbsc-ic-material-lightbulb-outline::before {
  content: "\f2d9";
}
.mbsc-ic-material-line-style::before {
  content: "\f2da";
}
.mbsc-ic-material-line-weight::before {
  content: "\f2db";
}
.mbsc-ic-material-linear-scale::before {
  content: "\f2dc";
}
.mbsc-ic-material-link::before {
  content: "\f2dd";
}
.mbsc-ic-material-linked-camera::before {
  content: "\f2de";
}
.mbsc-ic-material-list::before {
  content: "\f2df";
}
.mbsc-ic-material-live-help::before {
  content: "\f2e0";
}
.mbsc-ic-material-local-activity::before {
  content: "\f2e1";
}
.mbsc-ic-material-local-airport::before {
  content: "\f2e2";
}
.mbsc-ic-material-local-atm::before {
  content: "\f2e3";
}
.mbsc-ic-material-local-attraction::before {
  content: "\f2e4";
}
.mbsc-ic-material-local-bar::before {
  content: "\f2e5";
}
.mbsc-ic-material-local-cafe::before {
  content: "\f2e6";
}
.mbsc-ic-material-local-car-wash::before {
  content: "\f2e7";
}
.mbsc-ic-material-local-convenience-store::before {
  content: "\f2e8";
}
.mbsc-ic-material-local-dining::before {
  content: "\f2e9";
}
.mbsc-ic-material-local-drink::before {
  content: "\f2ea";
}
.mbsc-ic-material-local-florist::before {
  content: "\f2eb";
}
.mbsc-ic-material-local-gas-station::before {
  content: "\f2ec";
}
.mbsc-ic-material-local-grocery-store::before {
  content: "\f2ed";
}
.mbsc-ic-material-local-hospital::before {
  content: "\f2ee";
}
.mbsc-ic-material-local-hotel::before {
  content: "\f2ef";
}
.mbsc-ic-material-local-laundry-service::before {
  content: "\f2f0";
}
.mbsc-ic-material-local-library::before {
  content: "\f2f1";
}
.mbsc-ic-material-local-mall::before {
  content: "\f2f2";
}
.mbsc-ic-material-local-movies::before {
  content: "\f2f3";
}
.mbsc-ic-material-local-offer::before {
  content: "\f2f4";
}
.mbsc-ic-material-local-parking::before {
  content: "\f2f5";
}
.mbsc-ic-material-local-pharmacy::before {
  content: "\f2f6";
}
.mbsc-ic-material-local-phone::before {
  content: "\f2f7";
}
.mbsc-ic-material-local-pizza::before {
  content: "\f2f8";
}
.mbsc-ic-material-local-play::before {
  content: "\f2f9";
}
.mbsc-ic-material-local-post-office::before {
  content: "\f2fa";
}
.mbsc-ic-material-local-print-shop::before {
  content: "\f2fb";
}
.mbsc-ic-material-local-restaurant::before {
  content: "\f2fc";
}
.mbsc-ic-material-local-see::before {
  content: "\f2fd";
}
.mbsc-ic-material-local-shipping::before {
  content: "\f2fe";
}
.mbsc-ic-material-local-taxi::before {
  content: "\f2ff";
}
.mbsc-ic-material-location-city::before {
  content: "\f300";
}
.mbsc-ic-material-location-disabled::before {
  content: "\f301";
}
.mbsc-ic-material-location-history::before {
  content: "\f302";
}
.mbsc-ic-material-location-off::before {
  content: "\f303";
}
.mbsc-ic-material-location-on::before {
  content: "\f304";
}
.mbsc-ic-material-location-searching::before {
  content: "\f305";
}
.mbsc-ic-material-lock-open::before {
  content: "\f306";
}
.mbsc-ic-material-lock-outline::before {
  content: "\f307";
}
.mbsc-ic-material-lock::before {
  content: "\f308";
}
.mbsc-ic-material-looks-3::before {
  content: "\f309";
}
.mbsc-ic-material-looks-4::before {
  content: "\f30a";
}
.mbsc-ic-material-looks-5::before {
  content: "\f30b";
}
.mbsc-ic-material-looks-6::before {
  content: "\f30c";
}
.mbsc-ic-material-looks-one::before {
  content: "\f30d";
}
.mbsc-ic-material-looks-two::before {
  content: "\f30e";
}
.mbsc-ic-material-looks::before {
  content: "\f30f";
}
.mbsc-ic-material-loop::before {
  content: "\f310";
}
.mbsc-ic-material-loupe::before {
  content: "\f311";
}
.mbsc-ic-material-loyalty::before {
  content: "\f312";
}
.mbsc-ic-material-mail-outline::before {
  content: "\f313";
}
.mbsc-ic-material-mail::before {
  content: "\f314";
}
.mbsc-ic-material-map::before {
  content: "\f315";
}
.mbsc-ic-material-markunread-mailbox::before {
  content: "\f316";
}
.mbsc-ic-material-markunread::before {
  content: "\f317";
}
.mbsc-ic-material-memory::before {
  content: "\f318";
}
.mbsc-ic-material-menu::before {
  content: "\f319";
}
.mbsc-ic-material-merge-type::before {
  content: "\f31a";
}
.mbsc-ic-material-message::before {
  content: "\f31b";
}
.mbsc-ic-material-messenger::before {
  content: "\f31c";
}
.mbsc-ic-material-mic-none::before {
  content: "\f31d";
}
.mbsc-ic-material-mic-off::before {
  content: "\f31e";
}
.mbsc-ic-material-mic::before {
  content: "\f31f";
}
.mbsc-ic-material-mms::before {
  content: "\f320";
}
.mbsc-ic-material-mnone::before {
  content: "\f321";
}
.mbsc-ic-material-mode-comment::before {
  content: "\f322";
}
.mbsc-ic-material-mode-edit::before {
  content: "\f323";
}
.mbsc-ic-material-moff::before {
  content: "\f324";
}
.mbsc-ic-material-money-off::before {
  content: "\f325";
}
.mbsc-ic-material-monochrome-photos::before {
  content: "\f326";
}
.mbsc-ic-material-mood-bad::before {
  content: "\f327";
}
.mbsc-ic-material-mood::before {
  content: "\f328";
}
.mbsc-ic-material-more-horiz::before {
  content: "\f329";
}
.mbsc-ic-material-more-vert::before {
  content: "\f32a";
}
.mbsc-ic-material-more::before {
  content: "\f32b";
}
.mbsc-ic-material-motorcycle::before {
  content: "\f32c";
}
.mbsc-ic-material-mouse::before {
  content: "\f32d";
}
.mbsc-ic-material-move-to-inbox::before {
  content: "\f32e";
}
.mbsc-ic-material-movie-creation::before {
  content: "\f32f";
}
.mbsc-ic-material-movie-filter::before {
  content: "\f330";
}
.mbsc-ic-material-movie::before {
  content: "\f331";
}
.mbsc-ic-material-multitrack-audio::before {
  content: "\f332";
}
.mbsc-ic-material-music-note::before {
  content: "\f333";
}
.mbsc-ic-material-music-video::before {
  content: "\f334";
}
.mbsc-ic-material-my-library-add::before {
  content: "\f335";
}
.mbsc-ic-material-my-library-books::before {
  content: "\f336";
}
.mbsc-ic-material-my-library-mus::before {
  content: "\f337";
}
.mbsc-ic-material-my-location::before {
  content: "\f338";
}
.mbsc-ic-material-nature-people::before {
  content: "\f339";
}
.mbsc-ic-material-nature::before {
  content: "\f33a";
}
.mbsc-ic-material-navigate-before::before {
  content: "\f33b";
}
.mbsc-ic-material-navigate-next::before {
  content: "\f33c";
}
.mbsc-ic-material-navigation::before {
  content: "\f33d";
}
.mbsc-ic-material-near-me::before {
  content: "\f33e";
}
.mbsc-ic-material-network-cell::before {
  content: "\f33f";
}
.mbsc-ic-material-network-locked::before {
  content: "\f340";
}
.mbsc-ic-material-network-wifi::before {
  content: "\f341";
}
.mbsc-ic-material-new-releases::before {
  content: "\f342";
}
.mbsc-ic-material-next-week::before {
  content: "\f343";
}
.mbsc-ic-material-nfc::before {
  content: "\f344";
}
.mbsc-ic-material-no-sim::before {
  content: "\f345";
}
.mbsc-ic-material-not-interested::before {
  content: "\f346";
}
.mbsc-ic-material-note-add::before {
  content: "\f347";
}
.mbsc-ic-material-notifications-none::before {
  content: "\f348";
}
.mbsc-ic-material-notifications-off::before {
  content: "\f349";
}
.mbsc-ic-material-notifications-on::before {
  content: "\f34a";
}
.mbsc-ic-material-notifications-paused::before {
  content: "\f34b";
}
.mbsc-ic-material-notifications::before {
  content: "\f34c";
}
.mbsc-ic-material-now-wallpaper::before {
  content: "\f34d";
}
.mbsc-ic-material-now-widgets::before {
  content: "\f34e";
}
.mbsc-ic-material-offline-pin::before {
  content: "\f34f";
}
.mbsc-ic-material-opacity::before {
  content: "\f350";
}
.mbsc-ic-material-open-in-browser::before {
  content: "\f351";
}
.mbsc-ic-material-open-in-new::before {
  content: "\f352";
}
.mbsc-ic-material-open-with::before {
  content: "\f353";
}
.mbsc-ic-material-pages::before {
  content: "\f354";
}
.mbsc-ic-material-pageview::before {
  content: "\f355";
}
.mbsc-ic-material-palette::before {
  content: "\f356";
}
.mbsc-ic-material-pan-tool::before {
  content: "\f357";
}
.mbsc-ic-material-panorama-fisheye::before {
  content: "\f358";
}
.mbsc-ic-material-panorama-horizontal::before {
  content: "\f359";
}
.mbsc-ic-material-panorama-vertical::before {
  content: "\f35a";
}
.mbsc-ic-material-panorama-wide-angle::before {
  content: "\f35b";
}
.mbsc-ic-material-panorama::before {
  content: "\f35c";
}
.mbsc-ic-material-party-mode::before {
  content: "\f35d";
}
.mbsc-ic-material-pause-circle-fill::before {
  content: "\f35e";
}
.mbsc-ic-material-pause-circle-outline::before {
  content: "\f35f";
}
.mbsc-ic-material-pause::before {
  content: "\f360";
}
.mbsc-ic-material-payment::before {
  content: "\f361";
}
.mbsc-ic-material-people-outline::before {
  content: "\f362";
}
.mbsc-ic-material-people::before {
  content: "\f363";
}
.mbsc-ic-material-perm-camera-m::before {
  content: "\f364";
}
.mbsc-ic-material-perm-contact-cal::before {
  content: "\f365";
}
.mbsc-ic-material-perm-data-setting::before {
  content: "\f366";
}
.mbsc-ic-material-perm-device-info::before {
  content: "\f367";
}
.mbsc-ic-material-perm-identity::before {
  content: "\f368";
}
.mbsc-ic-material-perm-media::before {
  content: "\f369";
}
.mbsc-ic-material-perm-phone-msg::before {
  content: "\f36a";
}
.mbsc-ic-material-perm-scan-wifi::before {
  content: "\f36b";
}
.mbsc-ic-material-person-add::before {
  content: "\f36c";
}
.mbsc-ic-material-person-outline::before {
  content: "\f36d";
}
.mbsc-ic-material-person-pin-circle::before {
  content: "\f36e";
}
.mbsc-ic-material-person-pin::before {
  content: "\f36f";
}
.mbsc-ic-material-person::before {
  content: "\f370";
}
.mbsc-ic-material-pets::before {
  content: "\f371";
}
.mbsc-ic-material-phone-android::before {
  content: "\f372";
}
.mbsc-ic-material-phone-bluetooth-speaker::before {
  content: "\f373";
}
.mbsc-ic-material-phone-forwarded::before {
  content: "\f374";
}
.mbsc-ic-material-phone-in-talk::before {
  content: "\f375";
}
.mbsc-ic-material-phone-iphone::before {
  content: "\f376";
}
.mbsc-ic-material-phone-locked::before {
  content: "\f377";
}
.mbsc-ic-material-phone-missed::before {
  content: "\f378";
}
.mbsc-ic-material-phone-paused::before {
  content: "\f379";
}
.mbsc-ic-material-phone::before {
  content: "\f37a";
}
.mbsc-ic-material-phonelink-erase::before {
  content: "\f37b";
}
.mbsc-ic-material-phonelink-lock::before {
  content: "\f37c";
}
.mbsc-ic-material-phonelink-off::before {
  content: "\f37d";
}
.mbsc-ic-material-phonelink-ring::before {
  content: "\f37e";
}
.mbsc-ic-material-phonelink-setup::before {
  content: "\f381";
}
.mbsc-ic-material-phonelink::before {
  content: "\f382";
}
.mbsc-ic-material-photo-album::before {
  content: "\f37f";
}
.mbsc-ic-material-photo-camera::before {
  content: "\f380";
}
.mbsc-ic-material-photo-filter::before {
  content: "\f383";
}
.mbsc-ic-material-photo-library::before {
  content: "\f384";
}
.mbsc-ic-material-photo-size-select-actual::before {
  content: "\f385";
}
.mbsc-ic-material-photo-size-select-large::before {
  content: "\f386";
}
.mbsc-ic-material-photo-size-select-small::before {
  content: "\f387";
}
.mbsc-ic-material-photo::before {
  content: "\f388";
}
.mbsc-ic-material-picture-as-pdf::before {
  content: "\f389";
}
.mbsc-ic-material-picture-in-picture-alt::before {
  content: "\f38a";
}
.mbsc-ic-material-picture-in-picture::before {
  content: "\f38b";
}
.mbsc-ic-material-pin-drop::before {
  content: "\f38c";
}
.mbsc-ic-material-place::before {
  content: "\f38d";
}
.mbsc-ic-material-play-arrow::before {
  content: "\f38e";
}
.mbsc-ic-material-play-circle-fill::before {
  content: "\f38f";
}
.mbsc-ic-material-play-circle-outline::before {
  content: "\f390";
}
.mbsc-ic-material-play-download::before {
  content: "\f391";
}
.mbsc-ic-material-play-for-work::before {
  content: "\f392";
}
.mbsc-ic-material-play-install::before {
  content: "\f393";
}
.mbsc-ic-material-play-shopping-bag::before {
  content: "\f394";
}
.mbsc-ic-material-playlist-add-check::before {
  content: "\f395";
}
.mbsc-ic-material-playlist-add::before {
  content: "\f396";
}
.mbsc-ic-material-playlist-play::before {
  content: "\f397";
}
.mbsc-ic-material-plus-one::before {
  content: "\f398";
}
.mbsc-ic-material-poll::before {
  content: "\f399";
}
.mbsc-ic-material-polymer::before {
  content: "\f39a";
}
.mbsc-ic-material-portable-wifi-off::before {
  content: "\f39b";
}
.mbsc-ic-material-portrait::before {
  content: "\f39c";
}
.mbsc-ic-material-power-input::before {
  content: "\f39d";
}
.mbsc-ic-material-power-settings-new::before {
  content: "\f39e";
}
.mbsc-ic-material-pregnant-woman::before {
  content: "\f39f";
}
.mbsc-ic-material-present-to-all::before {
  content: "\f3a0";
}
.mbsc-ic-material-print::before {
  content: "\f3a1";
}
.mbsc-ic-material-publ::before {
  content: "\f3a2";
}
.mbsc-ic-material-public::before {
  content: "\f3a3";
}
.mbsc-ic-material-publish::before {
  content: "\f3a4";
}
.mbsc-ic-material-query-builder::before {
  content: "\f3a5";
}
.mbsc-ic-material-question-answer::before {
  content: "\f3a6";
}
.mbsc-ic-material-queue-mus::before {
  content: "\f3a7";
}
.mbsc-ic-material-queue-play-next::before {
  content: "\f3a8";
}
.mbsc-ic-material-queue::before {
  content: "\f3a9";
}
.mbsc-ic-material-quick-contacts-dialer::before {
  content: "\f3aa";
}
.mbsc-ic-material-quick-contacts-mail::before {
  content: "\f3ab";
}
.mbsc-ic-material-radio-button-off::before {
  content: "\f3ac";
}
.mbsc-ic-material-radio-button-on::before {
  content: "\f3ad";
}
.mbsc-ic-material-radio::before {
  content: "\f3ae";
}
.mbsc-ic-material-rate-review::before {
  content: "\f3af";
}
.mbsc-ic-material-receipt::before {
  content: "\f3b0";
}
.mbsc-ic-material-recent-actors::before {
  content: "\f3b1";
}
.mbsc-ic-material-record-voice-over::before {
  content: "\f3b2";
}
.mbsc-ic-material-redeem::before {
  content: "\f3b3";
}
.mbsc-ic-material-redo::before {
  content: "\f3b5";
}
.mbsc-ic-material-refresh::before {
  content: "\f3b4";
}
.mbsc-ic-material-remove-circle-outline::before {
  content: "\f3b6";
}
.mbsc-ic-material-remove-circle::before {
  content: "\f3b7";
}
.mbsc-ic-material-remove-from-queue::before {
  content: "\f3b8";
}
.mbsc-ic-material-remove-red-eye::before {
  content: "\f3b9";
}
.mbsc-ic-material-remove::before {
  content: "\f3ba";
}
.mbsc-ic-material-reorder::before {
  content: "\f3bb";
}
.mbsc-ic-material-repeat-one::before {
  content: "\f3bc";
}
.mbsc-ic-material-repeat::before {
  content: "\f3bd";
}
.mbsc-ic-material-replay-10::before {
  content: "\f3be";
}
.mbsc-ic-material-replay-30::before {
  content: "\f3bf";
}
.mbsc-ic-material-replay-5::before {
  content: "\f3c0";
}
.mbsc-ic-material-replay::before {
  content: "\f3c1";
}
.mbsc-ic-material-reply-all::before {
  content: "\f3c2";
}
.mbsc-ic-material-reply::before {
  content: "\f3c3";
}
.mbsc-ic-material-report-problem::before {
  content: "\f3c4";
}
.mbsc-ic-material-report::before {
  content: "\f3c5";
}
.mbsc-ic-material-restaurant-menu::before {
  content: "\f3c6";
}
.mbsc-ic-material-restore::before {
  content: "\f3c7";
}
.mbsc-ic-material-ring-volume::before {
  content: "\f3c8";
}
.mbsc-ic-material-room-service::before {
  content: "\f3c9";
}
.mbsc-ic-material-room::before {
  content: "\f3ca";
}
.mbsc-ic-material-rotate-90-degrees-ccw::before {
  content: "\f3cb";
}
.mbsc-ic-material-rotate-left::before {
  content: "\f3cc";
}
.mbsc-ic-material-rotate-right::before {
  content: "\f3cd";
}
.mbsc-ic-material-rounded-corner::before {
  content: "\f3ce";
}
.mbsc-ic-material-router::before {
  content: "\f3cf";
}
.mbsc-ic-material-rowing::before {
  content: "\f3d0";
}
.mbsc-ic-material-satellite::before {
  content: "\f3d2";
}
.mbsc-ic-material-save::before {
  content: "\f3d1";
}
.mbsc-ic-material-scanner::before {
  content: "\f3d3";
}
.mbsc-ic-material-schedule::before {
  content: "\f3d4";
}
.mbsc-ic-material-school::before {
  content: "\f3d5";
}
.mbsc-ic-material-screen-lock-landscape::before {
  content: "\f3d6";
}
.mbsc-ic-material-screen-lock-portrait::before {
  content: "\f3d7";
}
.mbsc-ic-material-screen-lock-rotation::before {
  content: "\f3d8";
}
.mbsc-ic-material-screen-rotation::before {
  content: "\f3d9";
}
.mbsc-ic-material-screen-share::before {
  content: "\f3da";
}
.mbsc-ic-material-sd-card::before {
  content: "\f3db";
}
.mbsc-ic-material-search::before {
  content: "\f3de";
}
.mbsc-ic-material-security::before {
  content: "\f3dc";
}
.mbsc-ic-material-select-all::before {
  content: "\f3dd";
}
.mbsc-ic-material-send::before {
  content: "\f3df";
}
.mbsc-ic-material-settings-applications::before {
  content: "\f3e0";
}
.mbsc-ic-material-settings-backup-restore::before {
  content: "\f3e1";
}
.mbsc-ic-material-settings-bluetooth::before {
  content: "\f3e2";
}
.mbsc-ic-material-settings-brightness::before {
  content: "\f3e3";
}
.mbsc-ic-material-settings-cell::before {
  content: "\f3e4";
}
.mbsc-ic-material-settings-display::before {
  content: "\f3e5";
}
.mbsc-ic-material-settings-ethernet::before {
  content: "\f3e6";
}
.mbsc-ic-material-settings-input-antenna::before {
  content: "\f3e7";
}
.mbsc-ic-material-settings-input-component::before {
  content: "\f3e8";
}
.mbsc-ic-material-settings-input-composite::before {
  content: "\f3e9";
}
.mbsc-ic-material-settings-input-hdmi::before {
  content: "\f3ea";
}
.mbsc-ic-material-settings-input-svideo::before {
  content: "\f3eb";
}
.mbsc-ic-material-settings-overscan::before {
  content: "\f3ec";
}
.mbsc-ic-material-settings-phone::before {
  content: "\f3ed";
}
.mbsc-ic-material-settings-power::before {
  content: "\f3ee";
}
.mbsc-ic-material-settings-remote::before {
  content: "\f3ef";
}
.mbsc-ic-material-settings-system-daydream::before {
  content: "\f3f0";
}
.mbsc-ic-material-settings-voice::before {
  content: "\f3f1";
}
.mbsc-ic-material-settings::before {
  content: "\f3f2";
}
.mbsc-ic-material-share::before {
  content: "\f3f3";
}
.mbsc-ic-material-shop-two::before {
  content: "\f3f4";
}
.mbsc-ic-material-shop::before {
  content: "\f3f5";
}
.mbsc-ic-material-shopping-basket::before {
  content: "\f3f6";
}
.mbsc-ic-material-shopping-cart::before {
  content: "\f3f7";
}
.mbsc-ic-material-short-text::before {
  content: "\f3f8";
}
.mbsc-ic-material-shuffle::before {
  content: "\f3f9";
}
.mbsc-ic-material-signal-cellular-4-bar::before {
  content: "\f3fa";
}
.mbsc-ic-material-signal-cellular-connected-no-internet-4-bar::before {
  content: "\f3fb";
}
.mbsc-ic-material-signal-cellular-no-sim::before {
  content: "\f3fc";
}
.mbsc-ic-material-signal-cellular-null::before {
  content: "\f3fd";
}
.mbsc-ic-material-signal-cellular-off::before {
  content: "\f3fe";
}
.mbsc-ic-material-signal-wifi-4-bar-lock::before {
  content: "\f3ff";
}
.mbsc-ic-material-signal-wifi-4-bar::before {
  content: "\f400";
}
.mbsc-ic-material-signal-wifi-off::before {
  content: "\f401";
}
.mbsc-ic-material-signal-wifi-statusbar-connected-no-internet::before {
  content: "\f402";
}
.mbsc-ic-material-signal-wifi-statusbar-not-connected::before {
  content: "\f403";
}
.mbsc-ic-material-signal-wifi-statusbar-null::before {
  content: "\f404";
}
.mbsc-ic-material-sim-card-alert::before {
  content: "\f405";
}
.mbsc-ic-material-sim-card::before {
  content: "\f406";
}
.mbsc-ic-material-skip-next::before {
  content: "\f407";
}
.mbsc-ic-material-skip-previous::before {
  content: "\f408";
}
.mbsc-ic-material-slideshow::before {
  content: "\f409";
}
.mbsc-ic-material-slow-motion-video::before {
  content: "\f40a";
}
.mbsc-ic-material-smartphone::before {
  content: "\f40b";
}
.mbsc-ic-material-sms-failed::before {
  content: "\f40c";
}
.mbsc-ic-material-sms::before {
  content: "\f40d";
}
.mbsc-ic-material-snooze::before {
  content: "\f40e";
}
.mbsc-ic-material-sort::before {
  content: "\f40f";
}
.mbsc-ic-material-spa::before {
  content: "\f410";
}
.mbsc-ic-material-space-bar::before {
  content: "\f411";
}
.mbsc-ic-material-speaker-group::before {
  content: "\f412";
}
.mbsc-ic-material-speaker-notes::before {
  content: "\f413";
}
.mbsc-ic-material-speaker-phone::before {
  content: "\f414";
}
.mbsc-ic-material-speaker::before {
  content: "\f415";
}
.mbsc-ic-material-spellcheck::before {
  content: "\f416";
}
.mbsc-ic-material-star-border::before {
  content: "\f417";
}
.mbsc-ic-material-star-half::before {
  content: "\f418";
}
.mbsc-ic-material-star-outline::before {
  content: "\f419";
}
.mbsc-ic-material-star-rate::before {
  content: "\f41a";
}
.mbsc-ic-material-star::before {
  content: "\f41b";
}
.mbsc-ic-material-stars::before {
  content: "\f41c";
}
.mbsc-ic-material-stay-current-landscape::before {
  content: "\f41d";
}
.mbsc-ic-material-stay-current-portrait::before {
  content: "\f41e";
}
.mbsc-ic-material-stay-primary-landscape::before {
  content: "\f41f";
}
.mbsc-ic-material-stay-primary-portrait::before {
  content: "\f420";
}
.mbsc-ic-material-stop-screen-share::before {
  content: "\f421";
}
.mbsc-ic-material-stop::before {
  content: "\f422";
}
.mbsc-ic-material-storage::before {
  content: "\f423";
}
.mbsc-ic-material-store-mall-directory::before {
  content: "\f424";
}
.mbsc-ic-material-store::before {
  content: "\f425";
}
.mbsc-ic-material-straighten::before {
  content: "\f426";
}
.mbsc-ic-material-strikethrough-s::before {
  content: "\f427";
}
.mbsc-ic-material-style::before {
  content: "\f428";
}
.mbsc-ic-material-subdirectory-arrow-left::before {
  content: "\f429";
}
.mbsc-ic-material-subdirectory-arrow-right::before {
  content: "\f42a";
}
.mbsc-ic-material-subject::before {
  content: "\f42b";
}
.mbsc-ic-material-subscriptions::before {
  content: "\f42c";
}
.mbsc-ic-material-subtitles::before {
  content: "\f42d";
}
.mbsc-ic-material-supervisor-account::before {
  content: "\f42e";
}
.mbsc-ic-material-surround-sound::before {
  content: "\f42f";
}
.mbsc-ic-material-swap-calls::before {
  content: "\f430";
}
.mbsc-ic-material-swap-horiz::before {
  content: "\f431";
}
.mbsc-ic-material-swap-vert-circle::before {
  content: "\f432";
}
.mbsc-ic-material-swap-vert::before {
  content: "\f433";
}
.mbsc-ic-material-switch-camera::before {
  content: "\f434";
}
.mbsc-ic-material-switch-video::before {
  content: "\f435";
}
.mbsc-ic-material-sync-disabled::before {
  content: "\f436";
}
.mbsc-ic-material-sync-problem::before {
  content: "\f437";
}
.mbsc-ic-material-sync::before {
  content: "\f438";
}
.mbsc-ic-material-system-update-tv::before {
  content: "\f439";
}
.mbsc-ic-material-system-update::before {
  content: "\f43a";
}
.mbsc-ic-material-tab-unselected::before {
  content: "\f43b";
}
.mbsc-ic-material-tab::before {
  content: "\f43c";
}
.mbsc-ic-material-tablet-android::before {
  content: "\f43d";
}
.mbsc-ic-material-tablet-mac::before {
  content: "\f43e";
}
.mbsc-ic-material-tablet::before {
  content: "\f43f";
}
.mbsc-ic-material-tag-faces::before {
  content: "\f440";
}
.mbsc-ic-material-tap-and-play::before {
  content: "\f441";
}
.mbsc-ic-material-terrain::before {
  content: "\f442";
}
.mbsc-ic-material-text-fields::before {
  content: "\f443";
}
.mbsc-ic-material-text-format::before {
  content: "\f444";
}
.mbsc-ic-material-textsms::before {
  content: "\f445";
}
.mbsc-ic-material-texture::before {
  content: "\f446";
}
.mbsc-ic-material-theaters::before {
  content: "\f447";
}
.mbsc-ic-material-thumb-down::before {
  content: "\f448";
}
.mbsc-ic-material-thumb-up::before {
  content: "\f449";
}
.mbsc-ic-material-thumbs-up-down::before {
  content: "\f44a";
}
.mbsc-ic-material-time-to-leave::before {
  content: "\f44b";
}
.mbsc-ic-material-timelapse::before {
  content: "\f44c";
}
.mbsc-ic-material-timeline::before {
  content: "\f44d";
}
.mbsc-ic-material-timer-10::before {
  content: "\f44e";
}
.mbsc-ic-material-timer-3::before {
  content: "\f44f";
}
.mbsc-ic-material-timer-auto::before {
  content: "\f450";
}
.mbsc-ic-material-timer-off::before {
  content: "\f451";
}
.mbsc-ic-material-timer::before {
  content: "\f452";
}
.mbsc-ic-material-toc::before {
  content: "\f453";
}
.mbsc-ic-material-today::before {
  content: "\f454";
}
.mbsc-ic-material-toll::before {
  content: "\f455";
}
.mbsc-ic-material-tonality::before {
  content: "\f456";
}
.mbsc-ic-material-touch-app::before {
  content: "\f457";
}
.mbsc-ic-material-toys::before {
  content: "\f458";
}
.mbsc-ic-material-track-changes::before {
  content: "\f459";
}
.mbsc-ic-material-traff::before {
  content: "\f45a";
}
.mbsc-ic-material-traffic::before {
  content: "\f45b";
}
.mbsc-ic-material-transform::before {
  content: "\f45c";
}
.mbsc-ic-material-translate::before {
  content: "\f45d";
}
.mbsc-ic-material-trending-down::before {
  content: "\f45e";
}
.mbsc-ic-material-trending-neutral::before {
  content: "\f45f";
}
.mbsc-ic-material-trending-up::before {
  content: "\f460";
}
.mbsc-ic-material-tune::before {
  content: "\f461";
}
.mbsc-ic-material-turned-in-not::before {
  content: "\f462";
}
.mbsc-ic-material-turned-in::before {
  content: "\f463";
}
.mbsc-ic-material-tv::before {
  content: "\f464";
}
.mbsc-ic-material-unarchive::before {
  content: "\f465";
}
.mbsc-ic-material-undo::before {
  content: "\f466";
}
.mbsc-ic-material-unfold-less::before {
  content: "\f467";
}
.mbsc-ic-material-unfold-more::before {
  content: "\f468";
}
.mbsc-ic-material-update::before {
  content: "\f469";
}
.mbsc-ic-material-usb::before {
  content: "\f46a";
}
.mbsc-ic-material-verified-user::before {
  content: "\f46b";
}
.mbsc-ic-material-vertical-align-bottom::before {
  content: "\f46c";
}
.mbsc-ic-material-vertical-align-center::before {
  content: "\f46d";
}
.mbsc-ic-material-vertical-align-top::before {
  content: "\f46e";
}
.mbsc-ic-material-vibration::before {
  content: "\f46f";
}
.mbsc-ic-material-video-collection::before {
  content: "\f470";
}
.mbsc-ic-material-video-library::before {
  content: "\f471";
}
.mbsc-ic-material-videocam-off::before {
  content: "\f472";
}
.mbsc-ic-material-videocam::before {
  content: "\f473";
}
.mbsc-ic-material-videogame-asset::before {
  content: "\f474";
}
.mbsc-ic-material-view-agenda::before {
  content: "\f475";
}
.mbsc-ic-material-view-array::before {
  content: "\f476";
}
.mbsc-ic-material-view-carousel::before {
  content: "\f477";
}
.mbsc-ic-material-view-column::before {
  content: "\f478";
}
.mbsc-ic-material-view-comfy::before {
  content: "\f479";
}
.mbsc-ic-material-view-compact::before {
  content: "\f47a";
}
.mbsc-ic-material-view-day::before {
  content: "\f47b";
}
.mbsc-ic-material-view-headline::before {
  content: "\f47c";
}
.mbsc-ic-material-view-list::before {
  content: "\f47d";
}
.mbsc-ic-material-view-module::before {
  content: "\f47e";
}
.mbsc-ic-material-view-quilt::before {
  content: "\f47f";
}
.mbsc-ic-material-view-stream::before {
  content: "\f480";
}
.mbsc-ic-material-view-week::before {
  content: "\f481";
}
.mbsc-ic-material-vignette::before {
  content: "\f482";
}
.mbsc-ic-material-visibility-off::before {
  content: "\f483";
}
.mbsc-ic-material-visibility::before {
  content: "\f484";
}
.mbsc-ic-material-voice-chat::before {
  content: "\f485";
}
.mbsc-ic-material-voicemail::before {
  content: "\f486";
}
.mbsc-ic-material-volume-down::before {
  content: "\f487";
}
.mbsc-ic-material-volume-mute::before {
  content: "\f488";
}
.mbsc-ic-material-volume-off::before {
  content: "\f489";
}
.mbsc-ic-material-volume-up::before {
  content: "\f48a";
}
.mbsc-ic-material-vpn-key::before {
  content: "\f48b";
}
.mbsc-ic-material-vpn-lock::before {
  content: "\f48c";
}
.mbsc-ic-material-wallet-giftcard::before {
  content: "\f48d";
}
.mbsc-ic-material-wallet-membership::before {
  content: "\f48e";
}
.mbsc-ic-material-wallet-travel::before {
  content: "\f48f";
}
.mbsc-ic-material-warning::before {
  content: "\f490";
}
.mbsc-ic-material-watch-later::before {
  content: "\f491";
}
.mbsc-ic-material-watch::before {
  content: "\f492";
}
.mbsc-ic-material-wb-auto::before {
  content: "\f493";
}
.mbsc-ic-material-wb-cloudy::before {
  content: "\f494";
}
.mbsc-ic-material-wb-incandescent::before {
  content: "\f495";
}
.mbsc-ic-material-wb-irradescent::before {
  content: "\f496";
}
.mbsc-ic-material-wb-sunny::before {
  content: "\f497";
}
.mbsc-ic-material-web-asset::before {
  content: "\f498";
}
.mbsc-ic-material-web::before {
  content: "\f499";
}
.mbsc-ic-material-weekend::before {
  content: "\f49a";
}
.mbsc-ic-material-whatshot::before {
  content: "\f49b";
}
.mbsc-ic-material-wifi-lock::before {
  content: "\f49c";
}
.mbsc-ic-material-wifi-tethering::before {
  content: "\f49d";
}
.mbsc-ic-material-work::before {
  content: "\f49e";
}
.mbsc-ic-material-wrap-text::before {
  content: "\f49f";
}
.mbsc-ic-material-youtube-searched-for::before {
  content: "\f4a0";
}
.mbsc-ic-material-zoom-in::before {
  content: "\f4a1";
}
.mbsc-ic-material-zoom-out::before {
  content: "\f4a2";
}
.mbsc-ic-menu::before {
  content: "\f4a3";
}
.mbsc-ic-menu2::before {
  content: "\f4a4";
}
.mbsc-ic-meteo-Celsius::before {
  content: "\f4a5";
}
.mbsc-ic-meteo-Fahrenheit::before {
  content: "\f4a6";
}
.mbsc-ic-meteo-cloud::before {
  content: "\f4a7";
}
.mbsc-ic-meteo-cloud2::before {
  content: "\f4a8";
}
.mbsc-ic-meteo-cloud3::before {
  content: "\f4a9";
}
.mbsc-ic-meteo-cloud4::before {
  content: "\f4aa";
}
.mbsc-ic-meteo-cloud5::before {
  content: "\f4ab";
}
.mbsc-ic-meteo-cloudy::before {
  content: "\f4ac";
}
.mbsc-ic-meteo-cloudy2::before {
  content: "\f4ad";
}
.mbsc-ic-meteo-cloudy3::before {
  content: "\f4ae";
}
.mbsc-ic-meteo-cloudy4::before {
  content: "\f4af";
}
.mbsc-ic-meteo-compass::before {
  content: "\f4b0";
}
.mbsc-ic-meteo-lightning::before {
  content: "\f4b2";
}
.mbsc-ic-meteo-lightning2::before {
  content: "\f4b1";
}
.mbsc-ic-meteo-lightning3::before {
  content: "\f4b3";
}
.mbsc-ic-meteo-lightning4::before {
  content: "\f4b4";
}
.mbsc-ic-meteo-lightning5::before {
  content: "\f4b5";
}
.mbsc-ic-meteo-lines::before {
  content: "\f4b6";
}
.mbsc-ic-meteo-moon::before {
  content: "\f4b7";
}
.mbsc-ic-meteo-moon2::before {
  content: "\f4b8";
}
.mbsc-ic-meteo-none::before {
  content: "\f4b9";
}
.mbsc-ic-meteo-rainy::before {
  content: "\f4ba";
}
.mbsc-ic-meteo-rainy2::before {
  content: "\f4bc";
}
.mbsc-ic-meteo-rainy3::before {
  content: "\f4bb";
}
.mbsc-ic-meteo-rainy4::before {
  content: "\f4bd";
}
.mbsc-ic-meteo-snowflake::before {
  content: "\f4be";
}
.mbsc-ic-meteo-snowy::before {
  content: "\f4bf";
}
.mbsc-ic-meteo-snowy2::before {
  content: "\f4c0";
}
.mbsc-ic-meteo-snowy3::before {
  content: "\f4c1";
}
.mbsc-ic-meteo-snowy4::before {
  content: "\f4c2";
}
.mbsc-ic-meteo-snowy5::before {
  content: "\f4c3";
}
.mbsc-ic-meteo-sun::before {
  content: "\f4c4";
}
.mbsc-ic-meteo-sun2::before {
  content: "\f4c5";
}
.mbsc-ic-meteo-sun3::before {
  content: "\f4c6";
}
.mbsc-ic-meteo-sunrise::before {
  content: "\f4c7";
}
.mbsc-ic-meteo-thermometer::before {
  content: "\f4c8";
}
.mbsc-ic-meteo-weather::before {
  content: "\f4c9";
}
.mbsc-ic-meteo-weather2::before {
  content: "\f4ca";
}
.mbsc-ic-meteo-weather3::before {
  content: "\f4cb";
}
.mbsc-ic-meteo-weather4::before {
  content: "\f4cc";
}
.mbsc-ic-meteo-weather5::before {
  content: "\f4cd";
}
.mbsc-ic-meteo-wind::before {
  content: "\f4ce";
}
.mbsc-ic-meteo-windy::before {
  content: "\f4cf";
}
.mbsc-ic-meteo-windy2::before {
  content: "\f4d0";
}
.mbsc-ic-meteo-windy3::before {
  content: "\f4d1";
}
.mbsc-ic-meteo-windy4::before {
  content: "\f4d2";
}
.mbsc-ic-meteo-windy5::before {
  content: "\f4d3";
}
.mbsc-ic-meter::before {
  content: "\f4d4";
}
.mbsc-ic-meter2::before {
  content: "\f4d5";
}
.mbsc-ic-minus::before {
  content: "\f4d6";
}
.mbsc-ic-mobile::before {
  content: "\f4d7";
}
.mbsc-ic-mobile2::before {
  content: "\f4d8";
}
.mbsc-ic-mug::before {
  content: "\f4d9";
}
.mbsc-ic-music::before {
  content: "\f4da";
}
.mbsc-ic-neutral::before {
  content: "\f4db";
}
.mbsc-ic-neutral2::before {
  content: "\f4dc";
}
.mbsc-ic-new-tab::before {
  content: "\f4dd";
}
.mbsc-ic-newspaper::before {
  content: "\f4de";
}
.mbsc-ic-next::before {
  content: "\f4df";
}
.mbsc-ic-notebook::before {
  content: "\f4e0";
}
.mbsc-ic-notification::before {
  content: "\f4e1";
}
.mbsc-ic-numbered-list::before {
  content: "\f4e2";
}
.mbsc-ic-office::before {
  content: "\f4e3";
}
.mbsc-ic-omega::before {
  content: "\f4e4";
}
.mbsc-ic-opera::before {
  content: "\f4e5";
}
.mbsc-ic-pacman::before {
  content: "\f4e6";
}
.mbsc-ic-paint-format::before {
  content: "\f4e7";
}
.mbsc-ic-paragraph-center::before {
  content: "\f4e8";
}
.mbsc-ic-paragraph-center2::before {
  content: "\f4e9";
}
.mbsc-ic-paragraph-justify::before {
  content: "\f4ea";
}
.mbsc-ic-paragraph-justify2::before {
  content: "\f4eb";
}
.mbsc-ic-paragraph-left::before {
  content: "\f4ec";
}
.mbsc-ic-paragraph-left2::before {
  content: "\f4ed";
}
.mbsc-ic-paragraph-right::before {
  content: "\f4ee";
}
.mbsc-ic-paragraph-right2::before {
  content: "\f4ef";
}
.mbsc-ic-paste::before {
  content: "\f4f0";
}
.mbsc-ic-paste2::before {
  content: "\f4f1";
}
.mbsc-ic-paste3::before {
  content: "\f4f2";
}
.mbsc-ic-pause::before {
  content: "\f4f3";
}
.mbsc-ic-pause2::before {
  content: "\f4f4";
}
.mbsc-ic-pawn::before {
  content: "\f4f5";
}
.mbsc-ic-paypal::before {
  content: "\f4f6";
}
.mbsc-ic-paypal2::before {
  content: "\f4f7";
}
.mbsc-ic-paypal3::before {
  content: "\f4f8";
}
.mbsc-ic-pen::before {
  content: "\f4f9";
}
.mbsc-ic-pencil::before {
  content: "\f4fa";
}
.mbsc-ic-pencil2::before {
  content: "\f4fb";
}
.mbsc-ic-phone-hang-up::before {
  content: "\f4fc";
}
.mbsc-ic-phone::before {
  content: "\f4fd";
}
.mbsc-ic-picassa::before {
  content: "\f4fe";
}
.mbsc-ic-picassa2::before {
  content: "\f4ff";
}
.mbsc-ic-pie::before {
  content: "\f500";
}
.mbsc-ic-pilcrow::before {
  content: "\f501";
}
.mbsc-ic-pinterest::before {
  content: "\f502";
}
.mbsc-ic-pinterest2::before {
  content: "\f503";
}
.mbsc-ic-play::before {
  content: "\f504";
}
.mbsc-ic-play2::before {
  content: "\f505";
}
.mbsc-ic-play3::before {
  content: "\f506";
}
.mbsc-ic-plus::before {
  content: "\f507";
}
.mbsc-ic-podcast::before {
  content: "\f508";
}
.mbsc-ic-point-down::before {
  content: "\f509";
}
.mbsc-ic-point-left::before {
  content: "\f50a";
}
.mbsc-ic-point-right::before {
  content: "\f50b";
}
.mbsc-ic-point-up::before {
  content: "\f50c";
}
.mbsc-ic-power-cord::before {
  content: "\f50d";
}
.mbsc-ic-previous::before {
  content: "\f50e";
}
.mbsc-ic-print::before {
  content: "\f50f";
}
.mbsc-ic-profile::before {
  content: "\f510";
}
.mbsc-ic-pushpin::before {
  content: "\f511";
}
.mbsc-ic-qrcode::before {
  content: "\f512";
}
.mbsc-ic-question::before {
  content: "\f513";
}
.mbsc-ic-quill::before {
  content: "\f514";
}
.mbsc-ic-quotes-left::before {
  content: "\f515";
}
.mbsc-ic-radio-checked::before {
  content: "\f516";
}
.mbsc-ic-radio-unchecked::before {
  content: "\f517";
}
.mbsc-ic-reddit::before {
  content: "\f518";
}
.mbsc-ic-redo::before {
  content: "\f519";
}
.mbsc-ic-redo2::before {
  content: "\f51a";
}
.mbsc-ic-remove::before {
  content: "\f51b";
}
.mbsc-ic-remove2::before {
  content: "\f51c";
}
.mbsc-ic-reply::before {
  content: "\f51d";
}
.mbsc-ic-right-toleft::before {
  content: "\f51e";
}
.mbsc-ic-road::before {
  content: "\f51f";
}
.mbsc-ic-rocket::before {
  content: "\f520";
}
.mbsc-ic-sad::before {
  content: "\f521";
}
.mbsc-ic-sad2::before {
  content: "\f522";
}
.mbsc-ic-safari::before {
  content: "\f523";
}
.mbsc-ic-scissors::before {
  content: "\f525";
}
.mbsc-ic-screen::before {
  content: "\f524";
}
.mbsc-ic-search::before {
  content: "\f526";
}
.mbsc-ic-settings::before {
  content: "\f527";
}
.mbsc-ic-share::before {
  content: "\f528";
}
.mbsc-ic-shield::before {
  content: "\f529";
}
.mbsc-ic-shocked::before {
  content: "\f52a";
}
.mbsc-ic-shocked2::before {
  content: "\f52b";
}
.mbsc-ic-shuffle::before {
  content: "\f52c";
}
.mbsc-ic-sigma::before {
  content: "\f52d";
}
.mbsc-ic-signup::before {
  content: "\f52e";
}
.mbsc-ic-skype::before {
  content: "\f52f";
}
.mbsc-ic-smiley::before {
  content: "\f530";
}
.mbsc-ic-smiley2::before {
  content: "\f531";
}
.mbsc-ic-soundcloud::before {
  content: "\f532";
}
.mbsc-ic-soundcloud2::before {
  content: "\f533";
}
.mbsc-ic-spades::before {
  content: "\f534";
}
.mbsc-ic-spam::before {
  content: "\f535";
}
.mbsc-ic-spell-check::before {
  content: "\f536";
}
.mbsc-ic-spinner::before {
  content: "\f537";
}
.mbsc-ic-spinner2::before {
  content: "\f538";
}
.mbsc-ic-spinner3::before {
  content: "\f539";
}
.mbsc-ic-spinner4::before {
  content: "\f53a";
}
.mbsc-ic-spinner5::before {
  content: "\f53b";
}
.mbsc-ic-spinner6::before {
  content: "\f53c";
}
.mbsc-ic-stack::before {
  content: "\f53d";
}
.mbsc-ic-stackoverflow::before {
  content: "\f53e";
}
.mbsc-ic-star::before {
  content: "\f53f";
}
.mbsc-ic-star2::before {
  content: "\f540";
}
.mbsc-ic-star3::before {
  content: "\f541";
}
.mbsc-ic-stats::before {
  content: "\f542";
}
.mbsc-ic-steam::before {
  content: "\f543";
}
.mbsc-ic-steam2::before {
  content: "\f544";
}
.mbsc-ic-stop::before {
  content: "\f545";
}
.mbsc-ic-stop2::before {
  content: "\f546";
}
.mbsc-ic-stopwatch::before {
  content: "\f547";
}
.mbsc-ic-storage::before {
  content: "\f548";
}
.mbsc-ic-stream-armchair-chair::before {
  content: "\f549";
}
.mbsc-ic-stream-arrow-target::before {
  content: "\f54a";
}
.mbsc-ic-stream-backpack-trekking::before {
  content: "\f54b";
}
.mbsc-ic-stream-bag-shopping::before {
  content: "\f54c";
}
.mbsc-ic-stream-barbecue-eat-food::before {
  content: "\f54d";
}
.mbsc-ic-stream-barista-coffee-espresso::before {
  content: "\f54e";
}
.mbsc-ic-stream-bomb-bug::before {
  content: "\f54f";
}
.mbsc-ic-stream-book-dowload::before {
  content: "\f550";
}
.mbsc-ic-stream-book-read::before {
  content: "\f551";
}
.mbsc-ic-stream-browser-window::before {
  content: "\f552";
}
.mbsc-ic-stream-brush-paint::before {
  content: "\f553";
}
.mbsc-ic-stream-bubble-comment-talk::before {
  content: "\f554";
}
.mbsc-ic-stream-bubble-love-talk::before {
  content: "\f555";
}
.mbsc-ic-stream-caddie-shop-shopping::before {
  content: "\f556";
}
.mbsc-ic-stream-caddie-shopping::before {
  content: "\f557";
}
.mbsc-ic-stream-camera-photo-polaroid::before {
  content: "\f558";
}
.mbsc-ic-stream-camera-photo::before {
  content: "\f559";
}
.mbsc-ic-stream-camera-video::before {
  content: "\f55a";
}
.mbsc-ic-stream-chaplin-hat-movie::before {
  content: "\f55b";
}
.mbsc-ic-stream-chef-food-restaurant::before {
  content: "\f55c";
}
.mbsc-ic-stream-clock-time::before {
  content: "\f55d";
}
.mbsc-ic-stream-cocktail-mojito::before {
  content: "\f55e";
}
.mbsc-ic-stream-coffee::before {
  content: "\f55f";
}
.mbsc-ic-stream-computer-imac::before {
  content: "\f560";
}
.mbsc-ic-stream-computer-macintosh-vintage::before {
  content: "\f561";
}
.mbsc-ic-stream-computer-network::before {
  content: "\f562";
}
.mbsc-ic-stream-computer::before {
  content: "\f563";
}
.mbsc-ic-stream-cook-pan-pot::before {
  content: "\f564";
}
.mbsc-ic-stream-crop::before {
  content: "\f565";
}
.mbsc-ic-stream-crown-king::before {
  content: "\f566";
}
.mbsc-ic-stream-danger-death-delete-destroy-skull::before {
  content: "\f567";
}
.mbsc-ic-stream-dashboard-speed::before {
  content: "\f568";
}
.mbsc-ic-stream-database::before {
  content: "\f569";
}
.mbsc-ic-stream-delete-garbage::before {
  content: "\f56a";
}
.mbsc-ic-stream-design-graphic-tablet-tablet::before {
  content: "\f56b";
}
.mbsc-ic-stream-design-pencil-rule::before {
  content: "\f56c";
}
.mbsc-ic-stream-diving-leisure-sea-sport::before {
  content: "\f56d";
}
.mbsc-ic-stream-drug-medecine-syringue::before {
  content: "\f56e";
}
.mbsc-ic-stream-earth-globe::before {
  content: "\f56f";
}
.mbsc-ic-stream-eat-food-fork-knife::before {
  content: "\f570";
}
.mbsc-ic-stream-eat-food-hotdog::before {
  content: "\f571";
}
.mbsc-ic-stream-edit-modify::before {
  content: "\f572";
}
.mbsc-ic-stream-email-mail::before {
  content: "\f573";
}
.mbsc-ic-stream-envellope-mail::before {
  content: "\f574";
}
.mbsc-ic-stream-eye-dropper::before {
  content: "\f576";
}
.mbsc-ic-stream-factory-lift-warehouse::before {
  content: "\f575";
}
.mbsc-ic-stream-first-aid-medecine-shield::before {
  content: "\f577";
}
.mbsc-ic-stream-food-ice-cream::before {
  content: "\f578";
}
.mbsc-ic-stream-frame-picture::before {
  content: "\f579";
}
.mbsc-ic-stream-grid-lines::before {
  content: "\f57a";
}
.mbsc-ic-stream-handle-vector::before {
  content: "\f57b";
}
.mbsc-ic-stream-happy-smiley::before {
  content: "\f57c";
}
.mbsc-ic-stream-headset-sound::before {
  content: "\f57d";
}
.mbsc-ic-stream-home-house::before {
  content: "\f57e";
}
.mbsc-ic-stream-ibook-laptop::before {
  content: "\f57f";
}
.mbsc-ic-stream-ink-pen::before {
  content: "\f580";
}
.mbsc-ic-stream-ipad::before {
  content: "\f581";
}
.mbsc-ic-stream-iphone::before {
  content: "\f582";
}
.mbsc-ic-stream-ipod-mini-music::before {
  content: "\f583";
}
.mbsc-ic-stream-ipod-music::before {
  content: "\f584";
}
.mbsc-ic-stream-ipod::before {
  content: "\f585";
}
.mbsc-ic-stream-japan-tea::before {
  content: "\f586";
}
.mbsc-ic-stream-laptop-macbook::before {
  content: "\f587";
}
.mbsc-ic-stream-like-love::before {
  content: "\f588";
}
.mbsc-ic-stream-link::before {
  content: "\f589";
}
.mbsc-ic-stream-lock-locker::before {
  content: "\f58a";
}
.mbsc-ic-stream-locker-unlock::before {
  content: "\f58b";
}
.mbsc-ic-stream-macintosh::before {
  content: "\f58c";
}
.mbsc-ic-stream-magic-magic-wand::before {
  content: "\f58d";
}
.mbsc-ic-stream-magnet::before {
  content: "\f58e";
}
.mbsc-ic-stream-man-people-user::before {
  content: "\f58f";
}
.mbsc-ic-stream-map-pin::before {
  content: "\f590";
}
.mbsc-ic-stream-map-user::before {
  content: "\f591";
}
.mbsc-ic-stream-micro-record::before {
  content: "\f592";
}
.mbsc-ic-stream-monocle-mustache::before {
  content: "\f593";
}
.mbsc-ic-stream-music-note::before {
  content: "\f594";
}
.mbsc-ic-stream-music-speaker::before {
  content: "\f595";
}
.mbsc-ic-stream-notebook::before {
  content: "\f596";
}
.mbsc-ic-stream-paint-bucket::before {
  content: "\f597";
}
.mbsc-ic-stream-painting-pallet::before {
  content: "\f598";
}
.mbsc-ic-stream-painting-roll::before {
  content: "\f599";
}
.mbsc-ic-stream-pen::before {
  content: "\f59a";
}
.mbsc-ic-stream-photo-pictures::before {
  content: "\f59b";
}
.mbsc-ic-stream-picture::before {
  content: "\f59c";
}
.mbsc-ic-stream-receipt-shopping::before {
  content: "\f59d";
}
.mbsc-ic-stream-remote-control::before {
  content: "\f59e";
}
.mbsc-ic-stream-settings::before {
  content: "\f59f";
}
.mbsc-ic-stream-shoes-snickers::before {
  content: "\f5a0";
}
.mbsc-ic-stream-speech-talk-user::before {
  content: "\f5a1";
}
.mbsc-ic-stream-stamp::before {
  content: "\f5a2";
}
.mbsc-ic-stream-suitcase-travel::before {
  content: "\f5a3";
}
.mbsc-ic-stream-sync::before {
  content: "\f5a4";
}
.mbsc-ic-stream-umbrella-weather::before {
  content: "\f5a5";
}
.mbsc-ic-strikethrough::before {
  content: "\f5a6";
}
.mbsc-ic-stumbleupon::before {
  content: "\f5a7";
}
.mbsc-ic-stumbleupon2::before {
  content: "\f5a8";
}
.mbsc-ic-support::before {
  content: "\f5a9";
}
.mbsc-ic-switch::before {
  content: "\f5aa";
}
.mbsc-ic-tab::before {
  content: "\f5ab";
}
.mbsc-ic-table::before {
  content: "\f5ac";
}
.mbsc-ic-table2::before {
  content: "\f5ad";
}
.mbsc-ic-tablet::before {
  content: "\f5ae";
}
.mbsc-ic-tag::before {
  content: "\f5af";
}
.mbsc-ic-tags::before {
  content: "\f5b0";
}
.mbsc-ic-target::before {
  content: "\f5b1";
}
.mbsc-ic-text-height::before {
  content: "\f5b2";
}
.mbsc-ic-text-width::before {
  content: "\f5b3";
}
.mbsc-ic-thumbs-up::before {
  content: "\f5b4";
}
.mbsc-ic-thumbs-up2::before {
  content: "\f5b5";
}
.mbsc-ic-ticket::before {
  content: "\f5b6";
}
.mbsc-ic-tongue::before {
  content: "\f5b7";
}
.mbsc-ic-tongue2::before {
  content: "\f5b8";
}
.mbsc-ic-tree::before {
  content: "\f5b9";
}
.mbsc-ic-trophy::before {
  content: "\f5ba";
}
.mbsc-ic-truck::before {
  content: "\f5bb";
}
.mbsc-ic-tumblr::before {
  content: "\f5bc";
}
.mbsc-ic-tumblr2::before {
  content: "\f5c0";
}
.mbsc-ic-tux::before {
  content: "\f5bd";
}
.mbsc-ic-tv::before {
  content: "\f5be";
}
.mbsc-ic-twitter::before {
  content: "\f5bf";
}
.mbsc-ic-twitter2::before {
  content: "\f5c1";
}
.mbsc-ic-twitter3::before {
  content: "\f5c2";
}
.mbsc-ic-underline::before {
  content: "\f5c3";
}
.mbsc-ic-undo::before {
  content: "\f5c4";
}
.mbsc-ic-undo2::before {
  content: "\f5c5";
}
.mbsc-ic-unlocked::before {
  content: "\f5c6";
}
.mbsc-ic-upload::before {
  content: "\f5c7";
}
.mbsc-ic-upload2::before {
  content: "\f5c8";
}
.mbsc-ic-upload3::before {
  content: "\f5c9";
}
.mbsc-ic-user::before {
  content: "\f5ca";
}
.mbsc-ic-user2::before {
  content: "\f5cb";
}
.mbsc-ic-user3::before {
  content: "\f5cc";
}
.mbsc-ic-user4::before {
  content: "\f5cd";
}
.mbsc-ic-users::before {
  content: "\f5ce";
}
.mbsc-ic-users2::before {
  content: "\f5d1";
}
.mbsc-ic-vimeo::before {
  content: "\f5cf";
}
.mbsc-ic-vimeo2::before {
  content: "\f5d0";
}
.mbsc-ic-vimeo3::before {
  content: "\f5d2";
}
.mbsc-ic-volume-decrease::before {
  content: "\f5d3";
}
.mbsc-ic-volume-high::before {
  content: "\f5d4";
}
.mbsc-ic-volume-increase::before {
  content: "\f5d5";
}
.mbsc-ic-volume-low::before {
  content: "\f5d6";
}
.mbsc-ic-volume-medium::before {
  content: "\f5d7";
}
.mbsc-ic-volume-mute::before {
  content: "\f5d8";
}
.mbsc-ic-volume-mute2::before {
  content: "\f5d9";
}
.mbsc-ic-wand::before {
  content: "\f5da";
}
.mbsc-ic-warning::before {
  content: "\f5db";
}
.mbsc-ic-windows::before {
  content: "\f5dc";
}
.mbsc-ic-windows8::before {
  content: "\f5dd";
}
.mbsc-ic-wink::before {
  content: "\f5de";
}
.mbsc-ic-wink2::before {
  content: "\f5df";
}
.mbsc-ic-wondering::before {
  content: "\f5e2";
}
.mbsc-ic-wondering2::before {
  content: "\f5e0";
}
.mbsc-ic-wordpress::before {
  content: "\f5e1";
}
.mbsc-ic-wordpress2::before {
  content: "\f5e3";
}
.mbsc-ic-wrench::before {
  content: "\f5e4";
}
.mbsc-ic-xing::before {
  content: "\f5e5";
}
.mbsc-ic-xing2::before {
  content: "\f5e6";
}
.mbsc-ic-yahoo::before {
  content: "\f5e7";
}
.mbsc-ic-yelp::before {
  content: "\f5e8";
}
.mbsc-ic-youtube::before {
  content: "\f5e9";
}
.mbsc-ic-youtube2::before {
  content: "\f5ea";
}
.mbsc-ic-zoom-in::before {
  content: "\f5eb";
}
.mbsc-ic-zoom-out::before {
  content: "\f5ec";
}

}

@include exports("frame") {

  .mbsc-fr-w,
  .mbsc-fr-overlay {
    -webkit-transform: translateZ(0);
  }

  .mbsc-fr {
    pointer-events: none;
    z-index: 99998;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: 100%;
  }

  .mbsc-fr-focus {
    outline: 0;
  }

  .mbsc-fr-lock-ctx {
    position: relative;
  }

  .mbsc-fr-lock.mbsc-fr-lock-ios {
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
  }

  .mbsc-fr-pos {
    visibility: hidden;
  }

  .mbsc-fr-scroll {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }

  .mbsc-fr-popup {
    max-width: 98%;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    font-size: 12px;
    text-shadow: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
  }

  .mbsc-rtl {
    direction: rtl;
  }


  /* Box sizing */

  .mbsc-fr-popup,
  .mbsc-fr-btn-cont,
  .mbsc-fr-arr {
    box-sizing: border-box;
  }

  .mbsc-fr .mbsc-fr-w {
    box-sizing: content-box;
  }

  .mbsc-fr-w {
    min-width: 256px;
    max-width: 100%;
    overflow: hidden;
    text-align: center;
    font-family: arial, verdana, sans-serif;
  }


  /* Modal overlay */

  .mbsc-fr,
  .mbsc-fr-persp,
  .mbsc-fr-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .mbsc-fr-lock .mbsc-fr-persp {
    -ms-touch-action: none;
    touch-action: none;
  }

  .mbsc-fr-lock-ctx > .mbsc-fr,
  .mbsc-fr-lock-ctx .mbsc-fr-persp,
  .mbsc-fr-lock-ctx .mbsc-fr-overlay {
    position: absolute;
  }

  .mbsc-fr-persp {
    pointer-events: auto;
    overflow: hidden;
  }

  .mbsc-fr-overlay {
    z-index: 1;
    background: rgba(0, 0, 0, .7);
  }


  /* Liquid mode */

  .mbsc-fr-liq .mbsc-fr-popup {
    max-width: 100%;
  }


  /* Top/Bottom mode */

  .mbsc-fr-top .mbsc-fr-popup,
  .mbsc-fr-bottom .mbsc-fr-popup {
    width: 100%;
    max-width: 100%;
  }

  .mbsc-fr-top .mbsc-fr-w,
  .mbsc-fr-bottom .mbsc-fr-w {
    padding-left: constant(safe-area-inset-left);
    padding-left: env(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    padding-right: env(safe-area-inset-right);
  }

  .mbsc-fr-bottom .mbsc-fr-w {
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }

  .mbsc-fr-top .mbsc-fr-popup {
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
  }


  /* Inline mode */

  .mbsc-fr-inline {
    position: relative;
    pointer-events: auto;
    z-index: 0;
  }

  .mbsc-fr-inline .mbsc-fr-popup {
    position: static;
    max-width: 100%;
  }


  /* Bubble mode */

  .mbsc-fr-bubble,
  .mbsc-fr-bubble .mbsc-fr-persp {
    position: absolute;
  }

  .mbsc-fr-bubble .mbsc-fr-arr-w {
    position: absolute;
    z-index: 1;
    left: 0;
    width: 100%;
    overflow: hidden;
  }

  .mbsc-fr-bubble-top .mbsc-fr-arr-w {
    top: 100%;
  }

  .mbsc-fr-bubble-bottom .mbsc-fr-arr-w {
    bottom: 100%;
  }

  .mbsc-fr-bubble .mbsc-fr-arr-i {
    margin: 0 1.75em;
    position: relative;
    direction: ltr;
  }

  .mbsc-fr-bubble .mbsc-fr-arr {
    display: block;
  }

  .mbsc-fr-arr {
    display: none;
    position: relative;
    left: 0;
    width: 2em;
    height: 2em;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    margin-left: -1em;
  }

  .mbsc-fr-bubble-bottom .mbsc-fr-arr {
    top: 1.333334em;
  }

  .mbsc-fr-bubble-top .mbsc-fr-arr {
    top: -1.333334em;
  }

  .mbsc-fr-hdn {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    overflow: hidden;
  }


  /* Header */

  .mbsc-fr-hdr {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }


  /* Buttons */

  .mbsc-fr-btn {
    overflow: hidden;
    display: block;
    text-decoration: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: top;
  }

  .mbsc-fr-btn-e {
    cursor: pointer;
  }

  .mbsc-fr-btn.mbsc-disabled {
    cursor: not-allowed;
  }


  /* Button container */

  .mbsc-fr-btn-cont {
    display: table;
    width: 100%;
    text-align: center;
    white-space: normal;
  }

  .mbsc-fr-btn-cont .mbsc-disabled {
    opacity: .3;
  }


  /* Button wrapper */

  .mbsc-fr-btn-w {
    vertical-align: top;
    display: table-cell;
    position: relative;
    z-index: 5;
  }

  .mbsc-fr-btn-w .mbsc-fr-btn:before {
    padding: .375em;
  }

  /* Desktop view */

  .mbsc-fr-pointer {

    /* Embedded components */
    &.mbsc-fr .mbsc-fr-w .mbsc-fr-inline .mbsc-fr-w {
      box-shadow: none;
      border-radius: 0;
    }

    .mbsc-ltr .mbsc-fr-w,
    .mbsc-ltr .mbsc-sc-whl .mbsc-sel-gr {
      text-align: left;
    }

    .mbsc-rtl .mbsc-fr-w,
    .mbsc-rtl .mbsc-sc-whl .mbsc-sel-gr {
      text-align: right;
    }

    &.mbsc-fr-top .mbsc-fr-w,
    &.mbsc-fr-bottom .mbsc-fr-w {
      pointer-events: auto;
      display: inline-block;
      margin-top: 3em;
      margin-bottom: 3em;
      max-width: 98%;
    }

    &.mbsc-fr-top .mbsc-fr-popup,
    &.mbsc-fr-bottom .mbsc-fr-popup {
      text-align: center;
      pointer-events: none;
    }

    &.mbsc-fr-bubble .mbsc-fr-arr-w {
      display: none;
    }

    .mbsc-sel-empty {
      text-align: center;
    }
  }
}


@include exports("frame.mobiscroll") {
  .mbsc-mobiscroll {
    .mbsc-fr-w {
      min-width: 16em;
      font-size: 16px;
    }

    .mbsc-fr-hdr {
      padding: 0 .6666em;
      padding-top: .6666em;
      font-size: .75em;
      text-transform: uppercase;
      min-height: 2em;
      line-height: 2em;
    }

    .mbsc-fr-btn-cont {
      display: block;
      overflow: hidden;
      text-align: right;
      padding: 0 .5em .5em .5em;
    }

    .mbsc-ltr .mbsc-fr-btn-cont {
      text-align: right;
    }

    .mbsc-rtl .mbsc-fr-btn-cont {
      text-align: left;
    }

    .mbsc-fr-btn-w {
      display: inline-block;
    }

    .mbsc-fr-btn {
      height: 2.5em;
      line-height: 2.5em;
      padding: 0 1em;
      text-transform: uppercase;
    }

    /* Display modes */
    &.mbsc-fr-center .mbsc-fr-w,
    &.mbsc-fr-bubble .mbsc-fr-w {
      border-radius: .25em;
    }

    &.mbsc-fr-no-overlay {
      .mbsc-fr-arr {
        box-shadow: 0 0 1em rgba(0, 0, 0, .2);
      }

      .mbsc-fr-w {
        box-shadow: 0 .125em 1em rgba(0, 0, 0, .3);
      }

      &.mbsc-fr-bubble .mbsc-fr-w {
        border-radius: .25em;
      }
    }
  }

  @include mbsc-mobiscroll-frame(mobiscroll, $mbsc-mobiscroll-colors);
}





@mixin mbsc-mobiscroll-scroller($theme, $params) {
  @include exports("scroller.#{$theme}.colors") {

    @include mbsc-mobiscroll-frame($theme, $params);

    $background: map-get($params, 'background');
    $accent: map-get($params, 'accent');
    $text: map-get($params, 'text');

    $background-param: map-get($params, 'frame-background');
    $text-param: map-get($params, 'frame-text');
    $accent-param: map-get($params, 'frame-accent');

    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
    $accent: if($accent-param, $accent-param, $accent);

    .mbsc-#{$theme} {
      .mbsc-sc-lbl {
        // color: $accent;
        color: $mbsc-mobiscroll-dark-text;
        font-weight: bold;
        font-size: 0.8em;
      }

      .mbsc-sc-whl-l {
        border-top: 1px solid $mbsc-mobiscroll-dark-text;
        border-bottom: 1px solid $mbsc-mobiscroll-dark-text;
      }

      .mbsc-sc-btn {
        color: $accent;
        background: $background;
      }

      &.mbsc-no-touch .mbsc-sc-itm.mbsc-btn-e:hover,
      .mbsc-sc-itm:focus {
        background: rgba($text, .1);
      }

      &.mbsc-no-touch .mbsc-sc-btn:hover:before,
      &.mbsc-sc .mbsc-sc-whl .mbsc-sc-itm.mbsc-active,
      .mbsc-sc-btn.mbsc-active:before {
        background: rgba($accent, .3);
      }

      /* Multiple select */
      .mbsc-sc-whl-multi {
        .mbsc-sc-itm-sel:before {
          color: $accent;
        }
      }
    }
  }
}




@include exports("scroller") {

  .mbsc-sc-whl-o,
  .mbsc-sc-btn {
    /* Prevent flickering on animation */
    -webkit-transform: translateZ(0);
  }

  /* Force content box */

  .mbsc-sc .mbsc-sc-whl-c,
  .mbsc-sc .mbsc-sc-whl-l,
  .mbsc-sc .mbsc-sc-whl {
    box-sizing: content-box;
  }

  /* Force border box */

  .mbsc-sc-whl-gr-c,
  .mbsc-sc-itm {
    box-sizing: border-box;
  }

  .mbsc-sc-whl-gr-c {
    position: relative;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
    overflow: hidden;
  }

  .mbsc-fr-bottom .mbsc-sc-whl-gr-c:first-child:last-child,
  .mbsc-fr-top .mbsc-sc-whl-gr-c:first-child:last-child,
  .mbsc-fr-inline .mbsc-sc-whl-gr-c:first-child:last-child,
  .mbsc-fr-liq .mbsc-sc-whl-gr-c {
    display: block;
  }

  .mbsc-sc-whl-gr {
    margin: 0 auto;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .mbsc-sc-whl-w {
    max-width: 100%;
    position: relative;
    -ms-touch-action: none;
    touch-action: none;
  }

  .mbsc-fr-pointer .mbsc-sc-whl-w,
  .mbsc-fr-liq .mbsc-sc-whl-w {
    -webkit-box-flex: 1;
    -webkit-flex: 1 auto;
    -ms-flex: 1 auto;
    flex: 1 auto;
  }

  .mbsc-sc-whl-o {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    display: none;
  }

  .mbsc-sc-whl-l {
    display: none;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    pointer-events: none;
  }

  .mbsc-sc-whl-w .mbsc-sc-whl-l {
    display: block;
  }

  .mbsc-sc-whl {
    overflow: hidden;
    /* Forces IE to respect overflow hidden while animating */
    /* Looks like this is not needed, also, it brakes rendering on Samsung S5 Mini */
    /* border-radius: 1px; */
    /* Fixes Firefox rendering issues */
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    margin: -1px 0;
  }

  .mbsc-sc-whl-c {
    position: relative;
    z-index: 1;
    top: 50%;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
  }

  .mbsc-sc-whl-sc {
    position: relative;
  }

  .mbsc-sc-itm {
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mbsc-sc-itm-inv,
  .mbsc-sc-itm-inv-h {
    opacity: .3;
  }

  .mbsc-sc-lbl {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;
    display: none;
  }

  .mbsc-sc-lbl-v .mbsc-sc-lbl {
    display: block;
  }

  .mbsc-sc-btn {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    cursor: pointer;
    opacity: 1;
    text-align: center;
    transition: opacity .2s linear;
  }

  .mbsc-sc-btn:before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }

  .mbsc-sc-whl-a .mbsc-sc-btn {
    opacity: 0;
  }

  .mbsc-sc-btn-plus {
    bottom: 0;
  }

  .mbsc-sc-btn-minus {
    top: 0;
  }

  /* 3D */

  .mbsc-sc-whl-gr-3d {
    -webkit-perspective: 1200px;
    perspective: 1200px;
  }

  .mbsc-sc-whl-gr-3d .mbsc-sc-whl {
    /* For iOS to respect z-index */
    overflow: visible;
  }

  .mbsc-sc-whl-gr-3d .mbsc-sc-whl-c {
    overflow: hidden;
  }

  .mbsc-sc-whl-gr-3d .mbsc-sc-whl-w,
  .mbsc-sc-whl-gr-3d .mbsc-sc-whl {
    /* For Edge and Firefox */
    transform-style: preserve-3d;
  }

  .mbsc-sc-whl-3d {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }

  .mbsc-sc-itm-3d {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .mbsc-sc-itm-del {
    display: none;
  }

  /* Multiline */

  .mbsc-sc-itm-ml {
    width: 100%;
    height: auto;
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
  }

  /* Multiple */

  .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 1em;
    text-align: center;
  }

  /* Desktop view */

  .mbsc-fr-pointer {

    .mbsc-fr-w .mbsc-sc-whl-gr-c,
    .mbsc-fr-w .mbsc-sc-whl-gr {
      padding: 0;
    }

    .mbsc-sc-whl-gr-c:first-child:last-child {
      display: block;
    }
  }

  .mbsc-sc-bar-c {
    position: absolute;
    z-index: 4;
    top: 0;
    bottom: 0;
    right: 0;
    width: 10px;
    opacity: 0;
    background: rgba(0, 0, 0, .05);
    transform: translateZ(0);
    transition: opacity .2s;
  }

  .mbsc-sc-bar {
    position: absolute;
    right: 0;
    top: 0;
    width: 10px;
    height: 100%;
  }

  .mbsc-sc-bar:after {
    content: '';
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    background: rgba(0, 0, 0, .5);
    border-radius: 3px;
  }

  .mbsc-sc-whl-w:hover .mbsc-sc-bar-c,
  .mbsc-sc-whl-anim .mbsc-sc-bar-c {
    opacity: 1;
  }
}


@include exports("scroller.mobiscroll") {
  .mbsc-mobiscroll {
    .mbsc-sc-whl-gr {
      padding: .5em .25em;
    }

    .mbsc-sc-whl-w {
      // margin: 0 .25em;
    }

    .mbsc-sc-lbl-v .mbsc-sc-whl-w {
      margin-top: 1.875em;
    }

    .mbsc-sc-lbl {
      font-size: .75em;
      line-height: 2.5em;
      text-transform: uppercase;
    }

    .mbsc-sc-cp .mbsc-sc-whl-w {
      padding: 2em 0;
    }

    .mbsc-sc-btn {
      height: 2em;
      line-height: 2em;
    }

    .mbsc-sc-btn:before {
      font-size: 1.5em;
    }

    .mbsc-sc-itm {
      padding: 0 .25em;
      font-size: 0.9em;
      // font-size: 16px;
      font-family: 'DegularText-Bold';
    }

    &.mbsc-no-touch .mbsc-sc-itm.mbsc-btn-e:hover,
    .mbsc-sc-itm:focus {
      outline: 0;
    }

    /* Multiple select */
    .mbsc-sc-whl-multi {
      .mbsc-sc-itm {
        padding: 0 1.818181em;
      }

      .mbsc-sc-itm-sel:before {
        font-size: 1.818181em;
      }
    }

    /* Desktop view */
    &.mbsc-fr-pointer {
      .mbsc-sc-lbl {
        padding-left: 1.666667em;
        padding-right: 1.666667em;
      }

      .mbsc-sc-whl-w {
        margin-left: 0;
        margin-right: 0;
      }

      .mbsc-sc-itm {
        font-size: 1em;
        padding: 0 1.25em;
      }

      .mbsc-sc-whl-multi {
        .mbsc-sc-itm {
          padding: 0 2.5em;
        }

        .mbsc-sc-itm-sel:before {
          font-size: 2em;
        }
      }

      .mbsc-ltr .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
        left: .125em;
      }

      .mbsc-rtl .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
        right: .125em;
      }
    }
  }

  @include mbsc-mobiscroll-scroller(mobiscroll, $mbsc-mobiscroll-colors);
}





// Theme specific variables - inherited from global variables

// Background
$mbsc-mobiscroll-input-background: $mbsc-input-background-light !default;
$mbsc-mobiscroll-dark-input-background: $mbsc-input-background-dark !default;
// Text
$mbsc-mobiscroll-input-text: $mbsc-input-text-light !default;
$mbsc-mobiscroll-dark-input-text: $mbsc-input-text-dark !default;
// Accent
$mbsc-mobiscroll-input-accent: $mbsc-input-accent-light !default;
$mbsc-mobiscroll-dark-input-accent: $mbsc-input-accent-dark !default;
// Error
$mbsc-mobiscroll-input-error: $mbsc-input-error-light !default;
$mbsc-mobiscroll-dark-input-error: $mbsc-input-error-dark !default;

$mbsc-mobiscroll-colors: map-merge($mbsc-mobiscroll-colors, (
  'input-background': $mbsc-mobiscroll-input-background,
  'input-text': $mbsc-mobiscroll-input-text,
  'input-accent': $mbsc-mobiscroll-input-accent,
  'input-error': $mbsc-mobiscroll-input-error,
));
$mbsc-mobiscroll-dark-colors: map-merge($mbsc-mobiscroll-dark-colors, (
  'input-background': $mbsc-mobiscroll-dark-input-background,
  'input-text': $mbsc-mobiscroll-dark-input-text,
  'input-accent': $mbsc-mobiscroll-dark-input-accent,
  'input-error': $mbsc-mobiscroll-dark-input-error,
));

@mixin mbsc-mobiscroll-input($theme, $params) {
  @include exports("input.#{$theme}.colors") {

    $background: map-get($params, 'background');
    $accent: map-get($params, 'accent');
    $text: map-get($params, 'text');
    // get custom params
    $input-background-param: map-get($params, 'input-background');
    $input-text-param: map-get($params, 'input-text');
    $input-accent-param: map-get($params, 'input-accent');
    $input-error-param: map-get($params, 'input-error');

    $text: if($input-text-param, $input-text-param, $text);
    $accent: if($input-accent-param, $input-accent-param, $accent);

    $input-box: '';
    @if (lightness($background) > 50%) {
      $input-box: darken($background, 10%);
    }
    @else {
      $input-box: lighten($background, 10%);
    }


    $colors: mbsc-mobiscroll-colors($params);
    $error: map-get($colors, error);
    $white-text: map-get($colors, 'white-text');
    $input-icon: lighten($text, 20%);
    $color-input: darken($text, 15%);

    $input-box: if($input-background-param, $input-background-param, $input-box);
    $error: if($input-error-param, $input-error-param, $error);

    .mbsc-#{$theme} {

      /* Textbox */
      &.mbsc-input .mbsc-control {
        // border-bottom: 1px solid $input-icon;
        color: $color-input;
        background: #414141;
        padding: 10px;
        margin: 6px 0 !important;      }

      &.mbsc-input .mbsc-control:focus,
      &.mbsc-input select:focus ~ input .mbsc-input textarea:focus {
        //border-color: $accent;
      }

      .mbsc-input-ic {
        color: $input-icon;
      }

      /* Box Input */
      &.mbsc-input-box {
        &.mbsc-input .mbsc-input-wrap {
          background: $input-box;
        }
      }

      /* Outline Input */
      &.mbsc-input-outline {

        &.mbsc-input .mbsc-control {
          border: 1px solid $input-icon;

          
        }

        &.mbsc-input .mbsc-label {
          background: $background;
        }

        &.mbsc-label-inline.mbsc-input .mbsc-label,
        &.mbsc-label-floating:not(.mbsc-label-floating-active).mbsc-input .mbsc-label {
          background: none;
        }

        &.mbsc-input .mbsc-control:focus,
        &.mbsc-input select:focus ~ input,
        &.mbsc-err input {
          border-color: $accent;
        }

        &.mbsc-input textarea:focus {
          border-color: $accent;
        }
      }

      &.mbsc-input.mbsc-err input,
      &.mbsc-input.mbsc-err textarea {
        border-color: $error;
      }
    }
  }
}



@include exports("input") {
  @keyframes autofill {
    from {
      opacity: 1;
    }

    to {
      opacity: 1;
    }
  }

  .mbsc-input {
    display: block;
    margin: 0;
    z-index: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .mbsc-input.mbsc-control-w.mbsc-ltr,
  .mbsc-input.mbsc-control-w.mbsc-rtl {
    // v5 compatibility
    width: auto;
    font-size: 1em;
    line-height: normal;
    max-width: none;
    position: relative;
  }

  .mbsc-input.mbsc-label-floating.mbsc-control-w.mbsc-ltr,
  .mbsc-input.mbsc-label-floating.mbsc-control-w.mbsc-rtl,
  .mbsc-input.mbsc-label-stacked.mbsc-control-w.mbsc-ltr,
  .mbsc-input.mbsc-label-stacked.mbsc-control-w.mbsc-rtl {
    // v5 compatibility
    top: 0;
    left: 0;
    right: 0;
    transform: none;
  }

  .mbsc-input .mbsc-control:-webkit-autofill {
    animation-name: autofill;
  }

  .mbsc-input input,
  .mbsc-input select,
  .mbsc-input textarea {
    margin: 0;
    padding: 0;
    border: 0;
    border-radius: 0;
    outline: 0;
    font-family: DegularText-Regular; // DegularText-Bold
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .mbsc-input-wrap,
  .mbsc-input input,
  .mbsc-input textarea {
    box-sizing: border-box;
  }

  .mbsc-input input,
  .mbsc-input select,
  .mbsc-input textarea {
    display: block;
    width: 100%;
    font-size: 1em;
  }

  .mbsc-input input:focus,
  .mbsc-input select:focus,
  .mbsc-input textarea:focus {
    outline: 0;
  }

  .mbsc-input-wrap {
    position: relative;
    display: block;
  }

  /* Textbox, textarea, select */

  .mbsc-input .mbsc-label {
    font-size: .875em;
    white-space: nowrap;
  }

  .mbsc-input-ic {
    position: absolute;
    height: 2em;
    width: 2em;
    line-height: 2em;
    text-align: center;
  }

  .mbsc-input-toggle {
    cursor: pointer;
  }

  .mbsc-ic-right .mbsc-input-ic {
    right: .5em;
  }

  .mbsc-ic-left .mbsc-input-ic {
    left: .5em;
  }

  .mbsc-ic-right input {
    padding-right: 2.4em;
  }

  .mbsc-ic-left input {
    padding-left: 2.4em;
  }

  /* Inline labels */

  .mbsc-label-inline.mbsc-input.mbsc-control-w {

    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    .mbsc-input-wrap {
      -webkit-box-flex: 1;
      -webkit-flex: 1 auto;
      -moz-box-flex: 1;
      -moz-flex: 1 auto;
      -ms-flex: 1 auto;
      flex: 1 auto;
    }

    .mbsc-label {
      position: static;
      -webkit-box-flex: 0;
      -webkit-flex: 0 auto;
      -moz-box-flex: 0;
      -moz-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      font-size: 1em;
      width: 30%;
      max-width: 12.5em;
      overflow: hidden;
      text-overflow: ellipsis;
      box-sizing: content-box;
    }
  }

  /* Floating labels */

  .mbsc-label-floating.mbsc-input {
    .mbsc-label {
      pointer-events: none;
      transition: transform .2s;
    }

    .mbsc-label~.mbsc-input-wrap {

      /* WebKit browsers */
      .mbsc-control::-webkit-input-placeholder {
        opacity: 0;
      }

      /* Mozilla Firefox 4 to 18 */
      .mbsc-control:-moz-placeholder {
        opacity: 0;
      }

      /* Mozilla Firefox 19+ */
      .mbsc-control::-moz-placeholder {
        opacity: 0;
      }

      /* Internet Explorer 10+ */
      .mbsc-control:-ms-input-placeholder {
        opacity: 0;
      }
    }

    &.mbsc-label-floating-active .mbsc-label~.mbsc-input-wrap {

      /* WebKit browsers */
      .mbsc-control::-webkit-input-placeholder {
        opacity: 1;
        transition: opacity .2s;
      }

      /* Mozilla Firefox 4 to 18 */
      .mbsc-control:-moz-placeholder {
        opacity: 1;
        transition: opacity .2s;
      }

      /* Mozilla Firefox 19+ */
      .mbsc-control::-moz-placeholder {
        opacity: 1;
        transition: opacity .2s;
      }

      /* Internet Explorer 10+ */
      .mbsc-control:-ms-input-placeholder {
        opacity: 1;
        transition: opacity .2s;
      }
    }
  }

  .mbsc-ltr.mbsc-label-floating.mbsc-input .mbsc-label {
    -webkit-transform-origin: top left;
    transform-origin: top left;
  }

  .mbsc-rtl.mbsc-label-floating.mbsc-input .mbsc-label {
    -webkit-transform-origin: top right;
    transform-origin: top right;
  }

  /* file type */
  .mbsc-input-wrap .mbsc-control[type="file"] {
    position: absolute;
    left: 0;
    opacity: 0;
  }
}

@include exports("input.mobiscroll") {
  .mbsc-mobiscroll {

    /* Textbox */
    &.mbsc-input input,
    &.mbsc-input textarea,
    &.mbsc-color-input {
      height: 2.125em;
      padding: 0 0 1px 0;
      background: transparent;
      font-size: 1em;
    }

    &.mbsc-input input:disabled,
    &.mbsc-input textarea:disabled,
    &.mbsc-input input:disabled ~ .mbsc-color-input,
    &.mbsc-input .mbsc-control:disabled ~ input,
    &.mbsc-input .mbsc-control:disabled ~ .mbsc-select-ic {
      opacity: .4;
    }

    /* Input */
    &.mbsc-input {
      margin: 1.5em 1em;
      // margin: 1.5em 0.3em 1.5em 1em;
      // margin: 1.5em 1em 1.5em 0.3em;

      .mbsc-label ~ .mbsc-input-wrap {
        padding-top: .875em;

        .mbsc-input-ic {
          top: .9375em;
        }
      }
    }

    .left {
      label {
        margin: 1.5em 0.3em 1.5em 1em !important;
      }
    }

    .right {
      label {
        margin: 1.5em 1em 1.5em 0.3em;
      }
    }

    .mbsc-input-ic {
      top: .0625em;
      margin: -2px 0 0 0;
    }

    /* Textarea */
    &.mbsc-input textarea {
      padding-top: .25em;
    }

    /* Select */
    .mbsc-select-ic {
      position: absolute;
      display: block;
      height: 1.25em;
      width: 1.25em;
      top: .5625em;
      text-align: center;
    }

    &.mbsc-ltr .mbsc-select-ic {
      left: auto;
      right: .6875em;
    }

    &.mbsc-rtl .mbsc-select-ic {
      right: auto;
      left: .6875em;
    }

    .mbsc-label ~ .mbsc-input-wrap .mbsc-select-ic {
      top: 1.4375em;
    }

    &.mbsc-ltr.mbsc-select {
      input {
        padding-right: 1.25em;
      }

      &.mbsc-ic-right input {
        padding-right: 3em;
      }

      .mbsc-input-wrap .mbsc-select-ic {
        left: auto;
        right: 0;
      }

      &.mbsc-ic-right .mbsc-input-wrap .mbsc-select-ic {
        right: 1.75em;
      }
    }

    &.mbsc-rtl.mbsc-select {
      input {
        padding-left: 1.25em;
      }

      &.mbsc-ic-right input {
        padding-left: 3em;
      }

      .mbsc-input-wrap .mbsc-select-ic {
        right: auto;
        left: 0;
      }

      &.mbsc-ic-right .mbsc-input-wrap .mbsc-select-ic {
        left: 1.75em;
      }
    }

    /* Select inline */
    .mbsc-select-inline .mbsc-select-ic {
      display: none;
    }

    /* LTR left icon */
    &.mbsc-ltr.mbsc-input.mbsc-ic-left {

      input,
      textarea {
        padding-left: 1.625em;
      }

      .mbsc-left-ic {
        right: auto;
        left: -.375em
      }

      .mbsc-label,
      .mbsc-err-msg {
        left: 2.166667em;
      }
    }

    /* LTR right icon */
    &.mbsc-ltr.mbsc-input.mbsc-ic-right {

      input,
      textarea {
        padding-right: 1.625em;
      }

      .mbsc-right-ic {
        left: auto;
        right: -.375em
      }
    }

    /* RTL left icon */
    &.mbsc-rtl.mbsc-input.mbsc-ic-left {

      input,
      textarea {
        padding-right: 1.625em;
      }

      .mbsc-left-ic {
        left: auto;
        right: -.375em
      }

      .mbsc-label,
      .mbsc-err-msg {
        right: 2.166667em;
      }
    }

    /* RTL right icon */
    &.mbsc-rtl.mbsc-input.mbsc-ic-right {

      input,
      textarea {
        padding-left: 1.625em;
      }

      .mbsc-right-ic {
        right: auto;
        left: -.375em
      }
    }

    &.mbsc-input .mbsc-label {
      position: absolute;
      top: 0;
      font-size: .65em;
      font-family: IBMPlexMono-Regular;
      text-transform: uppercase;
    }

    /* Inline labels */
    &.mbsc-label-inline.mbsc-input {
      .mbsc-label {
        line-height: 2em;
      }

      .mbsc-label + .mbsc-input-wrap {
        padding-top: 0;

        .mbsc-input-ic {
          top: .0625em;
        }
      }

      &.mbsc-select .mbsc-label ~ .mbsc-input-wrap .mbsc-select-ic {
        top: .5625em;
      }
    }

    /* Floating labels */
    &.mbsc-label-floating.mbsc-input {
      .mbsc-label {
        font-size: 1em;
        line-height: 2em;
        top: .875em;
      }

      &.mbsc-label-floating-active .mbsc-label {
        -webkit-transform: translateY(-1.125em) scale(.75);
        transform: translateY(-1.125em) scale(.75);
      }
    }

    &.mbsc-ltr.mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
      left: 1.625em;
    }

    &.mbsc-rtl.mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
      right: 1.625em;
    }

    /* Box Input */
    &.mbsc-input-box {
      &.mbsc-input .mbsc-input-wrap {
        //border-radius: 4px 4px 0 0;
        background-color: #43BE5F;
      }

      &.mbsc-input input,
      &.mbsc-input .mbsc-color-input {
        height: 2.25em;
        padding: .0625em 1em;
        border-radius: 4px 4px 0 0;
      }

      &.mbsc-input textarea {
        padding: .375em 1em .0625em 1em;
        height: 2.25em;
      }

      &.mbsc-input .mbsc-label ~ .mbsc-input-wrap {
        padding-top: 0;

        input,
        .mbsc-color-input {
          padding-top: 1.25em;
          height: 3.5em;
        }

        textarea:focus {
          padding-bottom: 0;
        }

        .mbsc-input-ic {
          top: .875em;
        }
      }

      .mbsc-input-ic {
        top: .25em;
      }

      &.mbsc-input .mbsc-label {
        padding: .666667em 0;
        z-index: 1;
        line-height: normal;
      }

      &.mbsc-textarea .mbsc-label ~ .mbsc-input-wrap {
        padding-top: 1.625em;

        textarea {
          height: 1.866667em;
          padding-top: 0;
        }
      }

      &.mbsc-select {
        .mbsc-input-wrap .mbsc-select-ic {
          top: .625em;
        }

        .mbsc-label ~ .mbsc-input-wrap .mbsc-select-ic {
          top: 1.375em;
        }
      }

      /* Inline labels */
      &.mbsc-label-inline {
        .mbsc-label {
          padding-top: .125em;
          padding-bottom: .125em;
          line-height: 2em;
        }

        .mbsc-label ~ .mbsc-input-wrap {
          padding-top: 0;

          input,
          .mbsc-color-input {
            height: 2.25em;
            padding-top: .0625em;
          }
        }

        .mbsc-label ~ .mbsc-input-wrap .mbsc-input-ic {
          top: .1875em;
        }

        &.mbsc-textarea .mbsc-label ~ .mbsc-input-wrap {
          padding-top: .375em;
        }

        &.mbsc-select .mbsc-label ~ .mbsc-input-wrap .mbsc-select-ic {
          top: .625em;
        }
      }

      /* Floating labels */
      &.mbsc-label-floating {
        .mbsc-label {
          top: .125em;
          line-height: 2em;
        }

        &.mbsc-label-floating-active .mbsc-label {
          line-height: normal;
          -webkit-transform: translateY(-.125em) scale(.75);
          transform: translateY(-.125em) scale(.75);
        }
      }
    }

    &.mbsc-ltr.mbsc-input-box {
      .mbsc-err-msg {
        left: 1.333334em;
      }

      .mbsc-label {
        left: 1.333334em;
        right: auto;
      }

      &.mbsc-ic-left {
        .mbsc-err-msg {
          left: 4em;
        }

        .mbsc-label {
          left: 4em;
        }

        .mbsc-left-ic {
          left: .5em;
        }

        input,
        textarea {
          padding-left: 3em;
        }
      }

      &.mbsc-ic-right {

        input,
        textarea {
          padding-right: 3em;
        }

        .mbsc-right-ic {
          right: .5em;
        }

        .mbsc-input-wrap .mbsc-select-ic {
          right: 2em;
        }
      }

      &.mbsc-select {
        input {
          padding-right: 2em;
        }

        .mbsc-input-wrap .mbsc-select-ic {
          right: .6875em;
        }

        &.mbsc-ic-right {
          input {
            padding-right: 4em;
          }

          .mbsc-input-wrap .mbsc-select-ic {
            right: 2.75em;
          }
        }
      }

      /* Floating labels */
      &.mbsc-label-floating.mbsc-input .mbsc-label {
        left: 1em;
      }

      &.mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
        left: 3em;
      }
    }

    &.mbsc-rtl.mbsc-input-box {
      .mbsc-err-msg {
        right: 1.333334em;
      }

      .mbsc-label {
        right: 1.333334em;
        left: auto;
      }

      &.mbsc-ic-left {
        .mbsc-err-msg {
          right: 4em;
        }

        .mbsc-label {
          right: 4em;
        }

        .mbsc-left-ic {
          right: .5em;
        }

        input,
        textarea {
          padding-right: 3em;
        }
      }

      &.mbsc-ic-right {

        input,
        textarea {
          padding-left: 3em;
        }

        .mbsc-right-ic {
          left: .5em;
        }

        .mbsc-input-wrap .mbsc-select-ic {
          left: 2em;
        }
      }

      &.mbsc-select {
        input {
          padding-left: 2em;
        }

        .mbsc-input-wrap .mbsc-select-ic {
          left: .6875em;
        }

        &.mbsc-ic-right {
          input {
            padding-left: 4em;
          }

          .mbsc-input-wrap .mbsc-select-ic {
            left: 2.75em;
          }
        }
      }

      /* Floating labels */
      &.mbsc-label-floating.mbsc-input .mbsc-label {
        right: 1em;
      }

      &.mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
        right: 3em;
      }
    }

    /* Outline Input */
    &.mbsc-input-outline {

      &.mbsc-input input,
      &.mbsc-input textarea,
      &.mbsc-input .mbsc-color-input {
        border-radius: 4px;
        height: 3.375em;
      }

      &.mbsc-input input,
      &.mbsc-input .mbsc-color-input {
        padding: .0625em 1em;
      }

      &.mbsc-input textarea {
        padding: .875em 1em .4375em 1em;
      }

      &.mbsc-input .mbsc-label {
        top: -.166667em;
        z-index: 1;
        padding: 0 .333334em;
      }

      .mbsc-input-ic {
        top: .8125em;
      }

      &.mbsc-input .mbsc-label ~ .mbsc-input-wrap {
        padding-top: .25em;

        .mbsc-input-ic {
          top: 1.0625em;
        }
      }

      &.mbsc-input input:focus,
      &.mbsc-input select:focus ~ input,
      &.mbsc-err input {
        padding-top: 0;
        padding-bottom: 0;
      }

      &.mbsc-err input,
      &.mbsc-err textarea {
        border-color: #de3226;
      }

      &.mbsc-select {
        .mbsc-input-wrap .mbsc-select-ic {
          top: 1.3125em;
        }

        .mbsc-label ~ .mbsc-input-wrap .mbsc-select-ic {
          top: 1.5625em;
        }
      }

      /* Inline labels */
      &.mbsc-label-inline {
        .mbsc-label {
          line-height: 3.375em;
        }

        .mbsc-label ~ .mbsc-input-wrap {
          padding-top: 0;
        }

        .mbsc-label ~ .mbsc-input-wrap .mbsc-input-ic {
          top: .8125em;
        }

        &.mbsc-select .mbsc-label ~ .mbsc-input-wrap .mbsc-select-ic {
          top: 1.3125em;
        }
      }

      /* Floating labels */
      &.mbsc-label-floating.mbsc-input {
        .mbsc-label {
          top: .9375em;
          padding: 0 .25em;
        }

        &.mbsc-label-floating-active .mbsc-label {
          -webkit-transform: translateY(-1.375em) scale(.75);
          transform: translateY(-1.375em) scale(.75);
        }
      }
    }

    &.mbsc-ltr.mbsc-input-outline {
      .mbsc-err-msg {
        left: 1.416667em;
      }

      .mbsc-label {
        left: 1.083334em;
        right: auto;
      }

      &.mbsc-ic-left {
        .mbsc-err-msg {
          left: 4.083334em;
        }

        .mbsc-label {
          left: 3.75em;
        }

        .mbsc-left-ic {
          left: .5em;
        }

        input,
        textarea {
          padding-left: 3em;
        }
      }

      &.mbsc-ic-right {

        input,
        textarea {
          padding-right: 3em;
        }

        .mbsc-right-ic {
          right: .5em;
        }
      }

      &.mbsc-select {
        input {
          padding-right: 2em;
        }

        .mbsc-input-wrap .mbsc-select-ic {
          right: .6875em;
        }

        &.mbsc-ic-right {
          input {
            padding-right: 4em;
          }

          .mbsc-input-wrap .mbsc-select-ic {
            right: 3em;
          }
        }
      }

      /* Floating labels */
      &.mbsc-label-floating.mbsc-input .mbsc-label {
        left: .875em;
      }

      &.mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
        left: 2.875em;
      }
    }

    &.mbsc-rtl.mbsc-input-outline {
      .mbsc-err-msg {
        right: 1.416667em;
      }

      .mbsc-label {
        right: 1.083334em;
        left: auto;
      }

      &.mbsc-ic-left {
        .mbsc-err-msg {
          right: 4.083334em;
        }

        .mbsc-label {
          right: 3.75em;
        }

        .mbsc-left-ic {
          right: .5em;
        }

        input,
        textarea {
          padding-right: 3em;
        }
      }

      &.mbsc-ic-right {

        input,
        textarea {
          padding-left: 3em;
        }

        .mbsc-right-ic {
          left: .5em;
        }
      }

      &.mbsc-select {
        input {
          padding-left: 2em;
        }

        .mbsc-input-wrap .mbsc-select-ic {
          left: .6875em;
        }

        &.mbsc-ic-right {
          input {
            padding-left: 4em;
          }

          .mbsc-input-wrap .mbsc-select-ic {
            left: 3em;
          }
        }
      }

      /* Floating labels */
      &.mbsc-label-floating.mbsc-input .mbsc-label {
        right: .875em;
      }

      &.mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
        right: 2.875em;
      }
    }
  }

  @include mbsc-mobiscroll-input(mobiscroll, $mbsc-mobiscroll-colors);
}






@mixin mbsc-mobiscroll-select($theme, $params) {
  @include exports("select.#{$theme}.colors") {

    @include mbsc-mobiscroll-scroller($theme, $params);
    @include mbsc-mobiscroll-input($theme, $params);

    $text: map-get($params, text);
    $accent: map-get($params, accent);

    .mbsc-#{$theme} {
      .mbsc-sel-gr-whl.mbsc-sel-one .mbsc-sc-itm-sel {
        color: $accent;
      }

      &.mbsc-fr-pointer .mbsc-sel-one.mbsc-sc-whl-multi .mbsc-sc-itm-sel {
        color: $text;
        background: rgba($accent, .3);
      }
    }
  }
}





@include exports("select") {
  .mbsc-sel-hdn {
    position: absolute;
    height: 1px !important;
    width: 1px !important;
    margin: 0 !important;
    padding: 0 !important;
    left: 0;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }

  .mbsc-sel .mbsc-sel-gr {
    padding: 0 .25em;
    opacity: 1;
    font-weight: bold;
    text-align: left;
  }

  .mbsc-sel .mbsc-fr-c {
    position: relative;
  }

  .mbsc-sel-multi .mbsc-sc-whl-w .mbsc-sc-whl-l {
    display: none;
  }

  .mbsc-sel-empty {
    display: none;
    opacity: .6;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2em;
    margin-top: -1em;
    font-size: 1.5em;
    line-height: 2em;
  }

  .mbsc-sel-empty-v {
    display: block;
  }

  .mbsc-sel-filter-clear {
    position: absolute;
    top: 0;
    height: 100%;
    width: 1.5em;
    display: none;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    font-size: 1.125em;
  }

  .mbsc-sel-filter-show-clear {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .mbsc-sel-input-wrap {
    position: relative;
  }

  .mbsc-sel .mbsc-ltr .mbsc-sel-filter-cont .mbsc-sel-filter-input {
    padding-right: 1.5em;
  }

  .mbsc-sel .mbsc-rtl .mbsc-sel-filter-cont .mbsc-sel-filter-input {
    padding-left: 1.5em;
  }

  .mbsc-ltr .mbsc-sel-filter-clear {
    right: 0;
  }

  .mbsc-rtl .mbsc-sel-filter-clear {
    left: 0;
  }

  .mbsc-sel.mbsc-fr-pointer.mbsc-no-touch .mbsc-sc-whl .mbsc-sc-itm.mbsc-sel-gr:hover {
    background: none;
  }
}


@include exports("select.mobiscroll") {
  .mbsc-mobiscroll {
    .mbsc-sc-whl .mbsc-sel-gr {
      padding: 0 .25em;
      font-size: 1.125em;
    }

    .mbsc-sel-gr-whl {
      .mbsc-sc-itm {
        padding: 0 .25em;
      }

      .mbsc-sc-itm::before {
        display: none;
      }
    }

    /* Filter style */

    .mbsc-sel-filter-cont {
      margin: .25em 1em;
    }

    &.mbsc-fr-pointer {

      /* Single select */
      .mbsc-sel-one {
        .mbsc-sc-itm {
          padding: 0 1.25em;
        }

        .mbsc-sc-itm-sel:before {
          display: none;
        }
      }

      /* Group headers */
      .mbsc-sc-itm.mbsc-sel-gr-itm {
        padding: 0 2.5em;
      }
    }
  }

  @include mbsc-mobiscroll-select(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}



// datetime component






@mixin mbsc-mobiscroll-datetime($theme, $params) {
  @include exports("datetime.#{$theme}.colors") {
    @include mbsc-mobiscroll-scroller($theme, $params);
  }
}





@include exports("datetime.mobiscroll") {
  @include mbsc-mobiscroll-datetime(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}



// image component






@mixin mbsc-mobiscroll-image($theme, $params) {
  @include exports("image.#{$theme}.colors") {
    @include mbsc-mobiscroll-scroller($theme, $params);
  }
}




@include exports("image") {
  .mbsc-ltr .mbsc-img-w {
    text-align: left;
  }

  .mbsc-ltr .mbsc-img-txt {
    margin: 0 0 0 .5em;
  }

  .mbsc-rtl .mbsc-img-w {
    text-align: right;
  }

  .mbsc-rtl .mbsc-img-txt {
    margin: 0 .5em 0 0;
  }

  .mbsc-img-w .mbsc-ic {
    display: inline-block;
    margin: 0 .5em;
  }

  .mbsc-img-txt {
    display: inline-block;
  }

  .mbsc-img-c {
    min-width: 50px;
    height: 28px;
    margin-top: -2px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    line-height: normal;
  }

  .mbsc-img {
    max-height: 28px;
  }
}


@include exports("image.mobiscroll") {
  @include mbsc-mobiscroll-image(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}



// treelist component






@mixin mbsc-mobiscroll-treelist($theme, $params) {
  @include exports("treelist.#{$theme}.colors") {
    @include mbsc-mobiscroll-scroller($theme, $params);
  }
}





@include exports("treelist.mobiscroll") {
  @include mbsc-mobiscroll-treelist(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}



// calendar component







$mbsc-mobiscroll-calendar-mark: $mbsc-calendar-mark-light !default;
$mbsc-mobiscroll-dark-calendar-mark: $mbsc-calendar-mark-dark !default;

$mbsc-mobiscroll-colors: map-merge($mbsc-mobiscroll-colors, (
  'calendar-mark': $mbsc-mobiscroll-calendar-mark,
));
$mbsc-mobiscroll-dark-colors: map-merge($mbsc-mobiscroll-dark-colors, (
  'calendar-mark': $mbsc-mobiscroll-dark-calendar-mark,
));

@mixin mbsc-mobiscroll-calbase($theme, $params) {
  @include exports("calbase.#{$theme}.colors") {

    @include mbsc-mobiscroll-scroller($theme, $params);

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    $accent: map-get($params, 'accent');

    $background-param: map-get($params, 'frame-background');
    $text-param: map-get($params, 'frame-text');
    $accent-param: map-get($params, 'frame-accent');
    $mark-param: map-get($params, 'calendar-mark');

    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
    $accent: if($accent-param, $accent-param, $accent);
    $mark: if($mark-param, $mark-param, $text);

    .mbsc-#{$theme} {
      .mbsc-cal-picker {
        background: $background;
      }

      .mbsc-cal-days > div {
        border-bottom: 1px solid $accent;
        color: $accent;
      }

      .mbsc-cal-today {
        color: $accent;
      }

      /* Hover, focus */
      &.mbsc-no-touch:not(.mbsc-ev-cal) .mbsc-cal-cell:not(.mbsc-disabled):hover .mbsc-cal-cell-txt,
      &.mbsc-no-touch .mbsc-cal-day:not(.mbsc-disabled) .mbsc-cal-day-date:hover,
      .mbsc-cal-cell:focus .mbsc-cal-cell-txt {
        background: rgba($accent, .3);
      }

      /* Selected */
      .mbsc-cal-c .mbsc-cal .mbsc-cal-body .mbsc-cal-row .mbsc-selected .mbsc-cal-cell-txt {
        background: $accent;
        color: $background;
      }

      .mbsc-cal-day-colored.mbsc-selected .mbsc-cal-day-date {
        border-color: $accent;
      }

      /* Week numbers */
      .mbsc-cal-week-nr {
        color: $accent;
      }

      .mbsc-cal-tab {
        border: 1px solid $accent;
        color: $text;
      }

      .mbsc-fr-w .mbsc-cal-tabs-c .mbsc-cal-tabs .mbsc-cal-tab.mbsc-selected {
        background: $accent;
        color: $background;
      }

      /* Marks */
      .mbsc-cal-mark {
        background: $mark;
      }

      .mbsc-cal-txt {
        color: $background;
        background: $text;
      }

      .mbsc-cal-icons {
        color: $text;
      }

    }
  }
}




@include exports("calbase") {
  /* Box sizing */

  .mbsc-cal-slide,
  .mbsc-cal-day,
  .mbsc-cal-day-i {
    box-sizing: border-box;
  }

  .mbsc-cal .mbsc-cal-day-date {
    text-align: center;
    box-sizing: content-box;
  }

  /* Flex layout */

  .mbsc-cal-hdr,
  .mbsc-cal-btn-w,
  .mbsc-cal-days-c,
  .mbsc-cal-days,
  .mbsc-cal-tabbed .mbsc-fr-c .mbsc-sc-whl-gr-c {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .mbsc-cal-btn-w,
  .mbsc-cal-month,
  .mbsc-cal-year,
  .mbsc-cal-days,
  .mbsc-cal-days > div {
    -webkit-box-flex: 1;
    -webkit-flex: 1 auto;
    -ms-flex: 1 auto;
    flex: 1 auto;
  }

  .mbsc-cal-c {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
  }

  /* Header */

  .mbsc-cal-btn-w {
    width: 50%;
  }

  .mbsc-cal-month,
  .mbsc-cal-year {
    width: 1%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }

  .mbsc-cal-btn.mbsc-disabled {
    opacity: .3;
    cursor: not-allowed;
  }

  /* Week days */

  .mbsc-cal-days {
    line-height: 1.875em;
    text-align: center;
  }

  .mbsc-cal-days > div {
    width: 14.285715%;
  }

  .mbsc-cal-body {
    position: relative;
  }

  .mbsc-cal-scroll-c {
    overflow: hidden;
    height: 100%;
  }

  .mbsc-cal-scroll {
    width: 100%;
    height: 100%;
  }

  .mbsc-cal-slide {
    position: absolute;
    z-index: 0; // Needed by Edge
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .mbsc-cal-slide:first-child {
    position: relative;
  }

  .mbsc-cal-table {
    display: table;
    width: 100%;
    height: 100%;
    table-layout: fixed;
    // Needed for iOS Safari to prevent animation flicker
    -webkit-transform: translateZ(0);
  }

  .mbsc-cal-row {
    display: table-row;
  }

  .mbsc-cal-cell {
    display: table-cell;
    vertical-align: middle;

    &:focus {
      outline: 0;
    }
  }

  .mbsc-cal-picker {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: .9375em 0;
    transition: all .2s ease-in-out;
    will-change: opacity;

    .mbsc-cal-cell {
      width: 33.333333%;
      height: 25%;
      cursor: pointer;
    }
  }

  .mbsc-cal-h {
    visibility: hidden;
    opacity: 0;
  }

  .mbsc-cal-day {
    max-width: 14.285715%; // if only width is used, layout is broken with week numbers displayed
    height: 16.666667%; // IE needs it in fullscreen
    position: relative;
    text-align: center;
    cursor: pointer;
  }

  .mbsc-cal-day-i {
    position: relative;
    height: 100%;
  }

  .mbsc-cal-day-markup {
    // Prevent margin-collapse
    overflow: hidden;
  }

  .mbsc-cal-day-diff .mbsc-cal-day-date {
    opacity: .5;
  }

  .mbsc-cal-week-view .mbsc-cal-day-date,
  .mbsc-cal-day-diff.mbsc-selected .mbsc-cal-day-date {
    opacity: 1;
  }

  .mbsc-cal-cell.mbsc-disabled {
    cursor: not-allowed;

    .mbsc-cal-cell-txt {
      opacity: .2;
    }
  }

  /* Hide outer days */

  .mbsc-cal-hide-diff {
    .mbsc-cal-day-diff {
      cursor: default;

      .mbsc-cal-day-i {
        visibility: hidden;
      }
    }
  }

  /* Week numbers */

  .mbsc-ltr .mbsc-cal-weeks .mbsc-cal-days {
    margin-left: 1.625em;
  }

  .mbsc-rtl .mbsc-cal-weeks .mbsc-cal-days {
    margin-right: 1.625em;
  }

  .mbsc-cal-week-nr {
    width: 1.625em;
    text-align: center;
  }

  /* Multiple month */

  .mbsc-cal-multi {
    .mbsc-cal-hdr {
      display: block;
    }

    .mbsc-cal-btn-w {
      width: auto;
    }

    .mbsc-cal-days,
    .mbsc-cal-slide {
      padding: 0 .5em;
    }
  }

  /* Liquid mode */

  .mbsc-fr-liq {
    .mbsc-cal-c {
      display: block;
    }
  }

  /* Full screen mode */

  .mbsc-calendar.mbsc-fr-liq.mbsc-fr-center {
    .mbsc-fr-overlay {
      display: none;
    }

    .mbsc-fr-w {
      border-radius: 0;
      padding-left: constant(safe-area-inset-left);
      padding-left: env(safe-area-inset-left);
      padding-right: constant(safe-area-inset-right);
      padding-right: env(safe-area-inset-right);
      padding-bottom: constant(safe-area-inset-bottom);
      padding-bottom: env(safe-area-inset-bottom);
    }
  }

  /* Tabs */

  .mbsc-cal-tabs-c {
    display: none;
  }

  .mbsc-cal-tabs {
    display: table;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .mbsc-cal-tab {
    position: relative;
    width: 33.33%;
    display: table-cell;
    overflow: hidden;
    cursor: pointer;
    text-align: center;
  }

  .mbsc-cal-tabbed {
    .mbsc-fr-c {
      position: relative;
    }

    .mbsc-w-p {
      transition: opacity .2s ease-in-out;
    }

    .mbsc-sc-whl-gr-c {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      max-width: none;
      height: 100%;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-box-align: center;
      align-items: center;
    }

    .mbsc-cal-c,
    .mbsc-cal-tabs-c {
      display: block;
    }

    /* During positioning */
    .mbsc-fr-pos {
      .mbsc-sc-whl-gr-c {
        width: auto;
      }
    }
  }

  /* Marks */

  .mbsc-cal-has-marks .mbsc-cal-day {
    padding-bottom: .25em;
  }

  .mbsc-cal-marks {
    position: absolute;
    left: 0;
    right: 0;
    height: .375em;
    margin-top: -.125em;
    overflow: hidden;
    white-space: nowrap;
  }

  .mbsc-cal-mark {
    display: inline-block;
    vertical-align: top;
    width: .25em;
    height: .25em;
    margin: .0625em;
    border-radius: .25em;
  }

  /* Text & icons */

  // .mbsc-cal-has-txt .mbsc-cal-day {
  //     padding-bottom: 1em;

  //     .mbsc-cal-icons {
  //         background: none;
  //     }
  // }

  .mbsc-cal-txt-w {
    position: absolute;
    z-index: 2;
    pointer-events: none;
  }

  .mbsc-ltr .mbsc-cal-txt {
    text-align: left;
  }

  .mbsc-rtl .mbsc-cal-txt {
    text-align: right;
  }

  .mbsc-cal-txt,
  .mbsc-cal-txt-ph,
  .mbsc-cal-txt-more {
    position: relative;
    height: 1.6em;
    line-height: 1.6em;
    overflow: hidden;
    padding: 0 .3em;
    font-size: .625em;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0 .1em .2em .1em;
  }

  .mbsc-cal-day .mbsc-cal-txt.mbsc-cal-txt-only {
    color: inherit;
    text-align: center;
  }

  .mbsc-no-touch .mbsc-cal-txt:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color .2s;
  }

  .mbsc-no-touch .mbsc-cal-txt-only:before {
    display: none;
  }

  .mbsc-no-touch .mbsc-cal-txt.mbsc-hover:before {
    background-color: rgba(0, 0, 0, .2);
  }

  .mbsc-no-touch .mbsc-cal-txt-more:hover {
    background-color: rgba(0, 0, 0, .1);
  }

  .mbsc-cal-txt-more {
    text-align: left;
    transition: background-color .2s;
  }

  // .mbsc-cal-txt {
  //     position: absolute;
  //     left: .1em;
  //     right: .1em;
  //     height: 1.6em;
  //     line-height: 1.6em;
  //     overflow: hidden;
  //     padding: 0 .2em;
  //     font-size: .625em;
  //     white-space: nowrap;
  // }

  .mbsc-cal .mbsc-cal-btn {
    position: relative;
    z-index: 1;
  }
}



@include exports("calbase.mobiscroll") {
  .mbsc-mobiscroll {
    .mbsc-cal-c {
      padding: .5em;
    }

    .mbsc-cal-btn-w {
      font-size: 1.125em;
      line-height: 2.222223em;
    }

    .mbsc-cal .mbsc-cal-btn {
      width: 2.666667em;
      height: 2.666667em;
      line-height: 2.666667em;
      padding: 0;
      border-radius: 2em;
      margin: -.222222em;
    }

    .mbsc-cal-picker {
      border-top: 1px solid transparent;

      .mbsc-cal-cell-i {
        display: inline-block;
        height: 2em;
        padding: 0 1em;
        line-height: 2em;
        border-radius: 2em;
        white-space: nowrap;
      }
    }

    .mbsc-cal-days > div {
      font-size: .6875em;
    }

    .mbsc-cal-day-date {
      display: inline-block;
      width: 2.153847em;
      height: 2.153847em;
      margin: .307692em 0;
      line-height: 2.153847em;
      font-size: .8125em;
      border: 2px solid transparent;
      border-radius: 2em;
    }

    /* Week numbers */
    .mbsc-cal-week-nr {
      width: 2.363637em;
      font-size: .6875em;
    }

    /* Tabs */
    .mbsc-cal-tabs-c {
      line-height: 1.875em;
      padding: .5em .5em 0 .5em;
      text-transform: uppercase;
    }

    .mbsc-cal-tab {
      font-size: .7em;
      padding: 0 1.2em;
    }
  }

  @include mbsc-mobiscroll-calbase(mobiscroll, $mbsc-mobiscroll-colors);
}





@mixin mbsc-mobiscroll-calendar($theme, $params) {
  @include exports("calendar.#{$theme}.colors") {
    @include mbsc-mobiscroll-calbase($theme, $params);
  }
}





@include exports("calendar.mobiscroll") {
  @include mbsc-mobiscroll-calendar(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}



// timespan component






@mixin mbsc-mobiscroll-timespan($theme, $params) {
  @include exports("timespan.#{$theme}.colors") {
    @include mbsc-mobiscroll-scroller($theme, $params);
  }
}




@include exports("timespan") {
  .mbsc-ts .mbsc-ts-lbl {
    display: none;
  }
}


@include exports("timespan.mobiscroll") {
  @include mbsc-mobiscroll-timespan(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}



// timer component






@mixin mbsc-mobiscroll-timer($theme, $params) {
  @include exports("timer.#{$theme}.colors") {
    @include mbsc-mobiscroll-scroller($theme, $params);
  }
}




@include exports("timer") {
  .mbsc-timer .mbsc-timer-lbl {
    display: none;
  }
}


@include exports("timer.mobiscroll") {
  .mbsc-mobiscroll {
    &.mbsc-timer {
      .mbsc-fr-btn-cont {
        display: table;

        .mbsc-fr-btn-w {
          width: 1px;
          text-align: left;
          display: table-cell;
          float: none;
        }

        .mbsc-fr-btn-w:first-child {
          width: auto;
          text-align: left;
        }

        .mbsc-fr-btn-w .mbsc-fr-btn {
          display: inline-block;
          vertical-align: middle;
          text-align: center;
          text-decoration: none;
        }
      }
    }
  }

  @include mbsc-mobiscroll-timer(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}



// color component








// Theme specific variables - inherited from global variables

// background
$mbsc-mobiscroll-page-background: $mbsc-page-background-light !default;
$mbsc-mobiscroll-dark-page-background: $mbsc-page-background-dark !default;

// text
$mbsc-mobiscroll-page-text: $mbsc-page-text-light !default;
$mbsc-mobiscroll-dark-page-text: $mbsc-page-text-dark !default;

// add variables to color maps
$mbsc-mobiscroll-colors: map-merge($mbsc-mobiscroll-colors, (
  'page-background': $mbsc-mobiscroll-page-background,
  'page-text': $mbsc-mobiscroll-page-text,
));
$mbsc-mobiscroll-dark-colors: map-merge($mbsc-mobiscroll-dark-colors, (
  'page-background': $mbsc-mobiscroll-dark-page-background,
  'page-text': $mbsc-mobiscroll-dark-page-text,
));

@mixin mbsc-mobiscroll-page($theme, $params) {
  @include exports("page.#{$theme}.colors") {
    @include mbsc-mobiscroll-common($theme, $params);

    $text: map-get($params, 'text');
    $accent: map-get($params, 'accent');
    $background: map-get($params, 'background');
    // get custom params
    $text-param: map-get($params, 'page-text');
    $background-param: map-get($params, 'page-background');
    // overwrite params with custom variables
    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));

    .mbsc-#{$theme} {
      &.mbsc-page {
        background: $background;
        color: $text;
      }

      a {
        color: $accent;
      }
    }
  }
}




@include exports("page") {
  [mbsc-page]:not(.mbsc-page) {
    // Prevent FOUC
    visibility: hidden;
  }

  .mbsc-page-ctx {
    margin: 0;
    padding: 0;
    // height: 100%;
  }

  .mbsc-page {
    min-height: 100%;
    font-size: 16px;
    font-family: arial, verdana, sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-text-size-adjust: 100%;
    padding-left: constant(safe-area-inset-left);
    padding-left: env(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    padding-right: env(safe-area-inset-right);
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }

  .mbsc-page,
  .mbsc-page * {
    box-sizing: border-box;
  }

  .mbsc-page:before,
  .mbsc-page:after {
    content: '';
    display: table;
  }


  /* RTL mode */

  .mbsc-rtl {
    direction: rtl;
  }


  /* Typography */

  .mbsc-page h1,
  .mbsc-page h2,
  .mbsc-page h3,
  .mbsc-page h4,
  .mbsc-page h5,
  .mbsc-page h6,
  .mbsc-h1,
  .mbsc-h2,
  .mbsc-h3,
  .mbsc-h4,
  .mbsc-h5,
  .mbsc-h6 {
    margin: 0;
    padding: 0;
    color: inherit;
    font-weight: normal;
    font-family: Nekst-Bold;
  }

  .mbsc-page p,
  .mbsc-p {
    margin: 1em 0;
    padding: 0;
    line-height: 1.5;
  }

  .mbsc-page a,
  .mbsc-a {
    text-decoration: none;
  }

  .mbsc-page a:hover,
  .mbsc-a:hover {
    text-decoration: underline;
  }

  .mbsc-page a.mbsc-btn:hover,
  .mbsc-a.mbsc-btn:hover {
    text-decoration: none;
  }

  .mbsc-page h1,
  .mbsc-h1 {
    margin: .347826em 0;
    font-size: 2.875em;
  }

  .mbsc-page h2,
  .mbsc-h2 {
    margin: .470588em 0;
    font-size: 2.125em;
  }

  .mbsc-page h3,
  .mbsc-h3 {
    margin: .666666em 0;
    font-size: 1.5em;
  }

  .mbsc-page h4,
  .mbsc-h4 {
    margin: .8em 0;
    font-size: 1.25em;
  }

  .mbsc-page h5,
  .mbsc-h5 {
    margin: 1.066666em 0;
    font-size: .9375em;
  }

  .mbsc-page h6,
  .mbsc-h6 {
    margin: 1.333333em 0;
    font-size: .75em;
  }

  /* Padding, margin */

  .mbsc-padding {
    padding: 1em;
  }

  .mbsc-padding > p:first-child {
    margin-top: 0;
  }

  .mbsc-padding > p:last-child {
    margin-bottom: 0;
  }

  .mbsc-margin {
    margin: 1em 0;
  }

  .mbsc-margin:first-child {
    margin-top: 0;
  }

  .mbsc-margin:last-child {
    margin-bottom: 0;
  }

  /* Lists */

  .mbsc-page ul,
  .mbsc-page ol,
  .mbsc-ul,
  .mbsc-ol {
    padding: 0;
    margin: 1em 0 1em 1.25em;
    line-height: 1.5;
  }

  .mbsc-page ul ul,
  .mbsc-page ol ol,
  .mbsc-ul .mbsc-ul,
  .mbsc-ol .mbsc-ol {
    margin: 0 0 0 1.25em;
  }

  /* Font sizes */

  .mbsc-txt-xs {
    font-size: .625em
  }

  .mbsc-txt-s {
    font-size: .75em;
  }

  .mbsc-txt-m {
    font-size: 1.25em;
  }

  .mbsc-txt-l {
    font-size: 1.5em;
  }

  .mbsc-txt-xl {
    font-size: 2em;
  }

  .mbsc-txt-muted {
    opacity: .6;
  }

  /* Line heights */
  .mbsc-line-height-xs {
    line-height: 1;
  }

  .mbsc-line-height-s {
    line-height: 1.25;
  }

  .mbsc-line-height-m {
    line-height: 1.5;
  }

  .mbsc-line-height-l {
    line-height: 1.75;
  }

  .mbsc-line-height-xl {
    line-height: 2;
  }

  /* Font weights */

  .mbsc-ultra-bold {
    font-weight: 900;
  }

  .mbsc-bold {
    font-weight: bold;
  }

  .mbsc-medium {
    font-weight: 500;
  }

  .mbsc-light {
    font-weight: 300;
  }

  .mbsc-thin {
    font-weight: 100;
  }

  .mbsc-italic {
    font-style: italic;
  }

  /* Text align */

  .mbsc-align-left {
    text-align: left;
  }

  .mbsc-align-right {
    text-align: right;
  }

  .mbsc-align-center {
    text-align: center;
  }

  .mbsc-align-justify {
    text-align: justify;
  }

  /* Float */

  .mbsc-pull-right {
    float: right;
  }

  .mbsc-pull-left {
    float: left;
  }

  /* Image section */

  .mbsc-media-fluid {
    display: block;
    width: 100%;
  }

  .mbsc-img-thumbnail {
    width: 6em;
    height: 6em;
    margin: 1em;
  }

  /* Avatar image */

  .mbsc-avatar {
    width: 2.5em;
    height: 2.5em;
    padding: 0;
    border-radius: 1.25em;
  }

  /* Note */

  .mbsc-note {
    position: relative;
    padding: .75em 1.25em;
    margin: 1em;
    border: 1px solid transparent;
    font-size: .875em;
  }

  @media screen and (max-width: 600px) {
    .mbsc-note {
      text-align: center;
    }
  }
}


@include exports("page.mobiscroll") {
  .mbsc-mobiscroll {

    /* Note */
    .mbsc-note {
      border-radius: .1875em;
    }

    .mbsc-note,
    .mbsc-note-primary {
      color: darken($mbsc-mobiscroll-primary, 30%);
      background-color: lighten($mbsc-mobiscroll-primary, 20%);
    }

    .mbsc-note-secondary {
      color: darken($mbsc-mobiscroll-secondary, 30%);
      background-color: lighten($mbsc-mobiscroll-secondary, 20%);
    }

    .mbsc-note-success {
      color: darken($mbsc-mobiscroll-success, 30%);
      background-color: lighten($mbsc-mobiscroll-success, 20%);
    }

    .mbsc-note-danger {
      color: darken($mbsc-mobiscroll-danger, 30%);
      background-color: lighten($mbsc-mobiscroll-danger, 20%);
    }

    .mbsc-note-warning {
      color: darken($mbsc-mobiscroll-warning, 25%);
      background-color: lighten($mbsc-mobiscroll-warning, 20%);
    }

    .mbsc-note-info {
      color: darken($mbsc-mobiscroll-info, 30%);
      background-color: lighten($mbsc-mobiscroll-info, 20%);
    }

    .mbsc-note-light {
      color: darken($mbsc-mobiscroll-light, 60%);
      background-color: darken($mbsc-mobiscroll-light, 5%);
    }

    .mbsc-note-dark {
      color: darken($mbsc-mobiscroll-dark, 30%);
      background-color: lighten($mbsc-mobiscroll-dark, 40%);
    }
  }

  @include mbsc-mobiscroll-page(mobiscroll, $mbsc-mobiscroll-colors);
}






@mixin mbsc-mobiscroll-progress($theme, $params) {
  @include exports("progress.#{$theme}.colors") {

    $background: map-get($params, 'background');
    $accent: map-get($params, 'accent');
    $text: map-get($params, 'text');
    // derived colors
    $input-icon: lighten($text, 20%);

    $track: '';
    // Light background
    @if (lightness($background) > 50%) {
      $track: darken($background, 10%);
    }
    // Dark background
    @else {
      $track: lighten($background, 8%);
    }

    .mbsc-#{$theme} {
      &.mbsc-progress .mbsc-input-ic {
        color: $input-icon;
      }

      .mbsc-progress-track {
        background: $track;
      }

      .mbsc-progress-bar {
        background: $accent;
      }
    }
  }
}




@include exports("progress") {
  .mbsc-progress {
    position: relative;
    display: block;
    margin: 0;
    z-index: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .mbsc-progress progress {
    display: none;
  }

  .mbsc-progress .mbsc-input-wrap {
    position: relative;
    display: block;
  }

  .mbsc-progress .mbsc-input-ic {
    position: absolute;
    height: 2em;
    width: 2em;
    line-height: 2em;
    text-align: center;
  }

  .mbsc-progress-cont {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
  }

  .mbsc-progress-track {
    position: relative;
    display: block;
    box-sizing: border-box;
  }

  .mbsc-progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
  }

  .mbsc-rtl .mbsc-progress-bar {
    left: auto;
    right: 0;
  }

  .mbsc-progress-anim .mbsc-progress-bar {
    transition: width .1s ease-in-out;
  }

  .mbsc-progress-value {
    position: absolute;
    top: 50%;
    width: 3em;
    overflow: hidden;
    margin-top: -.5em;
    line-height: 1em;
  }

  .mbsc-progress-value-right .mbsc-progress-value {
    right: 0;
    text-align: right;
  }

  .mbsc-rtl.mbsc-progress-value-right .mbsc-progress-value {
    right: auto;
    left: 0;
    text-align: left;
  }

  .mbsc-progress-value-left .mbsc-progress-value {
    left: 0;
    text-align: left;
  }

  .mbsc-rtl.mbsc-progress-value-left .mbsc-progress-value {
    left: auto;
    right: 0;
    text-align: right;
  }

  .mbsc-progress-step-label {
    position: absolute;
    top: 1em;
    width: 3.5em;
    font-size: .75em;
    text-align: center;
    overflow: hidden;
  }

  .mbsc-ltr .mbsc-progress-step-label {
    margin-left: -1.75em;
  }

  .mbsc-rtl .mbsc-progress-step-label {
    margin-right: -1.75em;
  }

  /* Inline labels */

  .mbsc-label-inline.mbsc-progress.mbsc-control-w {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    .mbsc-input-wrap {
      -webkit-box-flex: 1;
      -webkit-flex: 1 auto;
      -moz-box-flex: 1;
      -moz-flex: 1 auto;
      -ms-flex: 1 auto;
      flex: 1 auto;
    }

    .mbsc-label {
      position: static;
      -webkit-box-flex: 0;
      -webkit-flex: 0 auto;
      -moz-box-flex: 0;
      -moz-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      font-size: 1em;
      line-height: 2.125em;
      width: 30%;
      max-width: 12.5em;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}


@include exports("progress.mobiscroll") {
  .mbsc-mobiscroll {
    &.mbsc-progress {
      padding: 1em;
    }

    &.mbsc-progress .mbsc-label {
      font-size: .75em;
    }

    &.mbsc-progress .mbsc-input-ic {
      top: .0625em;
      margin: 0;
    }

    &.mbsc-ltr.mbsc-progress.mbsc-ic-left .mbsc-input-wrap,
    &.mbsc-rtl.mbsc-progress.mbsc-ic-right .mbsc-input-wrap {
      padding-left: 2.75em;
    }

    &.mbsc-ltr.mbsc-progress.mbsc-ic-right .mbsc-input-wrap,
    &.mbsc-rtl.mbsc-progress.mbsc-ic-left .mbsc-input-wrap {
      padding-right: 2.75em;
    }

    &.mbsc-ltr.mbsc-progress.mbsc-ic-left .mbsc-left-ic,
    &.mbsc-rtl.mbsc-progress.mbsc-ic-right .mbsc-right-ic {
      right: auto;
      left: -.375em;
    }

    &.mbsc-ltr.mbsc-progress.mbsc-ic-right .mbsc-right-ic,
    &.mbsc-rtl.mbsc-progress.mbsc-ic-left .mbsc-left-ic {
      left: auto;
      right: -.375em;
    }

    .mbsc-progress-cont {
      padding: 1em 0;
    }

    .mbsc-progress-track {
      border-radius: 1em;
      height: .125em;
    }

    .mbsc-progress-value {
      width: 3.75em;
      font-size: .875em;
    }

    &.mbsc-ltr.mbsc-progress.mbsc-progress-value-left .mbsc-input-wrap,
    &.mbsc-rtl.mbsc-progress.mbsc-progress-value-right .mbsc-input-wrap {
      padding-left: 3.5em;
    }

    &.mbsc-ltr.mbsc-progress.mbsc-progress-value-right .mbsc-input-wrap,
    &.mbsc-rtl.mbsc-progress.mbsc-progress-value-left .mbsc-input-wrap {
      padding-right: 3.5em;
    }

    &.mbsc-ltr.mbsc-progress-value-left.mbsc-ic-left .mbsc-input-wrap,
    &.mbsc-rtl.mbsc-progress-value-right.mbsc-ic-right .mbsc-input-wrap {
      padding-left: 5.5em;
    }

    &.mbsc-ltr.mbsc-progress-value-right.mbsc-ic-right .mbsc-input-wrap,
    &.mbsc-rtl.mbsc-progress-value-left.mbsc-ic-left .mbsc-input-wrap {
      padding-right: 5.5em;
    }

    &.mbsc-ltr.mbsc-progress-value-left.mbsc-ic-left .mbsc-progress-value,
    &.mbsc-rtl.mbsc-progress-value-right.mbsc-ic-right .mbsc-progress-value {
      left: 2.25em;
      right: auto;
    }

    &.mbsc-ltr.mbsc-progress-value-right.mbsc-ic-right .mbsc-progress-value,
    &.mbsc-rtl.mbsc-progress-value-left.mbsc-ic-left .mbsc-progress-value {
      left: auto;
      right: 2.25em;
    }

    .mbsc-progress-primary .mbsc-progress-bar {
      background: $mbsc-mobiscroll-primary;
    }

    .mbsc-progress-secondary .mbsc-progress-bar {
      background: $mbsc-mobiscroll-secondary;
    }

    .mbsc-progress-success .mbsc-progress-bar {
      background: $mbsc-mobiscroll-success;
    }

    .mbsc-progress-danger .mbsc-progress-bar {
      background: $mbsc-mobiscroll-danger;
    }

    .mbsc-progress-warning .mbsc-progress-bar {
      background: $mbsc-mobiscroll-warning;
    }

    .mbsc-progress-info .mbsc-progress-bar {
      background: $mbsc-mobiscroll-info;
    }
  }

  @include mbsc-mobiscroll-progress(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}






@mixin mbsc-mobiscroll-slider($theme, $params) {
  @include exports("slider.#{$theme}.colors") {

    @include mbsc-mobiscroll-page($theme, $params);
    @include mbsc-mobiscroll-progress($theme, $params);

    $background: map-get($params, background);
    $accent: map-get($params, accent);

    $colors: mbsc-mobiscroll-colors($params);
    $tooltip: map-get($colors, tooltip);

    .mbsc-#{$theme} {
      .mbsc-slider-step {
        background: $background;
      }

      .mbsc-slider-handle {
        background: $accent;
      }

      .mbsc-slider-tooltip {
        color: $tooltip;
        background-color: $accent;
      }
    }
  }
}





@include exports("slider") {
  .mbsc-progress.mbsc-slider input {
    display: none;
  }

  .mbsc-ltr .mbsc-progress-anim .mbsc-slider-handle-cont {
    transition: left .1s ease-in-out;
  }

  .mbsc-rtl .mbsc-progress-anim .mbsc-slider-handle-cont {
    transition: right .1s ease-in-out;
  }

  .mbsc-slider-handle-cont {
    position: absolute;
    width: 2em;
    height: 2em;
    top: .0625em;
    margin-top: -1em;
    cursor: pointer;
  }

  .mbsc-ltr .mbsc-slider-handle-cont {
    right: -1em;
  }

  .mbsc-rtl .mbsc-slider-handle-cont {
    left: -1em;
  }

  .mbsc-ltr .mbsc-slider-handle-cont.mbsc-slider-handle-left {
    left: 0;
  }

  .mbsc-rtl .mbsc-slider-handle-cont.mbsc-slider-handle-left {
    right: 0;
  }

  .mbsc-ltr .mbsc-progress-track .mbsc-slider-handle-cont {
    margin-left: -1em;
  }

  .mbsc-rtl .mbsc-progress-track .mbsc-slider-handle-cont {
    margin-right: -1em;
  }

  .mbsc-slider-handle {
    position: absolute;
    top: .125em;
    right: 50%;
    margin: -.5em -.5em 0 0;
    z-index: 2;
  }

  .mbsc-slider-handle-cont .mbsc-slider-handle {
    box-sizing: content-box;
  }

  .mbsc-slider .mbsc-slider-handle:focus,
  .mbsc-slider .mbsc-active .mbsc-slider-handle {
    outline: 0;
    z-index: 15;
  }

  .mbsc-slider .mbsc-handle-curr {
    z-index: 16;
  }

  .mbsc-slider-tooltip {
    position: absolute;
    right: 50%;
    opacity: 0;
    color: #000;
  }

  .mbsc-slider-step {
    position: absolute;
    top: 0;
    width: .125em;
    height: 100%;
    margin-left: -.0625em;
  }
}


@include exports("slider.mobiscroll") {
  .mbsc-mobiscroll {
    &.mbsc-slider .mbsc-input-wrap {
      padding: 0 .5em;
    }

    .mbsc-slider-handle {
      top: 50%;
      width: 1em;
      height: 1em;
      border-radius: 1.125em;
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transition: -webkit-transform .2s ease-in-out;
      transition: transform .2s ease-in-out;
    }

    .mbsc-slider-handle:focus,
    .mbsc-active .mbsc-slider-handle {
      -webkit-transform: scale(1.3);
      transform: scale(1.3);
    }

    &.mbsc-slider input:disabled ~ .mbsc-progress-cont .mbsc-progress-track {
      opacity: .4;
    }

    &.mbsc-slider input:disabled ~ .mbsc-progress-cont .mbsc-slider-handle {
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    .mbsc-slider-tooltip {
      top: -1.91666em;
      width: 2.33333333em;
      height: 2.33333333em;
      border-radius: 2em;
      margin-right: -1.1666em;
      line-height: 2.33333333em;
      text-align: center;
      font-size: .75em;
      z-index: 5;
      -webkit-transform: scale(.4) translate3d(0, 5em, 0);
      transform: scale(.4) translate3d(0, 5em, 0);
      -webkit-transition: -webkit-transform .2s ease-in-out, opacity .2s ease-in-out;
      transition: transform .2s ease-in-out, opacity .2s ease-in-out;
    }

    .mbsc-slider-handle:focus ~ .mbsc-slider-tooltip,
    .mbsc-active .mbsc-slider-tooltip {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0) scale(1);
      transform: translate3d(0, 0, 0) scale(1);
    }

    &.mbsc-slider-has-tooltip .mbsc-slider-handle:focus,
    &.mbsc-slider-has-tooltip .mbsc-active .mbsc-slider-handle {
      -webkit-transform: scale(.5);
      transform: scale(.5);
    }

    &.mbsc-slider-primary {

      .mbsc-slider-tooltip,
      .mbsc-slider-handle,
      .mbsc-progress-bar {
        background: $mbsc-mobiscroll-primary;
      }
    }

    &.mbsc-slider-secondary {

      .mbsc-slider-tooltip,
      .mbsc-slider-handle,
      .mbsc-progress-bar {
        background: $mbsc-mobiscroll-secondary;
      }
    }

    &.mbsc-slider-success {

      .mbsc-slider-tooltip,
      .mbsc-slider-handlem .mbsc-progress-bar {
        background: $mbsc-mobiscroll-success;
      }
    }

    &.mbsc-slider-danger {

      .mbsc-slider-tooltip,
      .mbsc-slider-handle,
      .mbsc-progress-bar {
        background: $mbsc-mobiscroll-danger;
      }
    }

    &.mbsc-slider-warning {

      .mbsc-slider-tooltip,
      .mbsc-slider-handle,
      .mbsc-progress-bar {
        background: $mbsc-mobiscroll-warning;
      }
    }

    &.mbsc-slider-info {

      .mbsc-slider-tooltip,
      .mbsc-slider-handle,
      .mbsc-progress-bar {
        background: $mbsc-mobiscroll-info;
      }
    }
  }

  @include mbsc-mobiscroll-slider(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}






@mixin mbsc-mobiscroll-color($theme, $params) {
  @include exports("color.#{$theme}.colors") {

    @include mbsc-mobiscroll-frame($theme, $params);
    @include mbsc-mobiscroll-slider($theme, $params);

    $accent: map-get($params, 'accent');
    $text: map-get($params, 'text');
    // derived colors
    $input-icon: lighten($text, 20%);
    $color-input: darken($text, 15%);

    .mbsc-#{$theme} {

      .mbsc-color-active,
      &.mbsc-no-touch .mbsc-color-item-c:hover {
        box-shadow: inset 0 0 0 .1875em $accent;
      }

      &.mbsc-input .mbsc-color-input {
        border-bottom: 1px solid $input-icon;
        color: $color-input;
      }
    }
  }
}




@include exports("color") {
  .mbsc-color {
    font-size: 16px;
  }

  .mbsc-color-scroll-cont {
    padding: .125em 0 .1875em 0;
    overflow: hidden;
    box-sizing: content-box;
  }

  .mbsc-color-cont {
    display: table;
    table-layout: fixed;
    margin: 0 auto;
  }

  .mbsc-color-item-c {
    position: relative;
    display: table-cell;
    padding: .18755em;
    max-width: 4.25em;
    max-height: 4.25em;
  }

  .mbsc-color-clear-item,
  .mbsc-color-item {
    position: relative;
    min-width: 3.5em;
    min-height: 3.5em;
    margin: .1875em;
    cursor: pointer;
  }

  .mbsc-color-clear-item {
    background: rgba(223, 222, 222, .6);
  }

  .mbsc-color-clear-cross {
    position: absolute;
    height: 100%;
    width: 2px;
    top: 0;
    left: 50%;
    margin-left: -1px;
    background: red;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .mbsc-color-row {
    padding: 0 .5em;
  }

  .mbsc-color.mbsc-fr-bubble .mbsc-fr-w,
  .mbsc-color.mbsc-fr-center .mbsc-fr-w {
    max-width: 286px;
    min-width: 280px;
  }

  .mbsc-color .mbsc-fr-c {
    padding: .25em 0;
  }


  /* vertical scroll override */

  .mbsc-color-vertical .mbsc-color-cont {
    width: 100%;
    height: 100%;
    white-space: normal;
    text-align: start;
  }

  .mbsc-color-vertical .mbsc-color-item-c {
    display: inline-block;
    vertical-align: top;
    text-align: center;
  }


  /* slider overrides */

  .mbsc-color .mbsc-color-slider-cont.mbsc-progress {
    padding: 0;
  }

  .mbsc-color .mbsc-color-slider-cont .mbsc-progress-cont {
    padding: 1.5em 0;
  }

  .mbsc-color .mbsc-color-slider-cont .mbsc-progress-track {
    height: .25em;
    border: 0;
  }

  .mbsc-color .mbsc-color-slider-cont .mbsc-slider-handle-cont {
    top: .125em;
  }

  .mbsc-color .mbsc-color-slider-cont .mbsc-slider-step {
    display: none;
  }


  /* checkmark */

  .mbsc-color-clear-item:before,
  .mbsc-color-item:before {
    position: absolute;
    width: 1em;
    height: 1em;
    top: 50%;
    left: 50%;
    margin-top: -.5em;
    margin-left: -.5em;
    color: #f7f7f7;
    display: none;
    font-size: 1.5em;
  }

  .mbsc-color-clear-item:before {
    display: block;
  }

  .mbsc-color-clear-item,
  .mbsc-color-input-item-close:before,
  .mbsc-color-selected::before {
    display: block;
    text-shadow: 0 0 .125em #000;
  }


  /* refine mode multiple select */

  .mbsc-color-refine-preview {
    display: table;
    padding: 0 .25em;
  }

  .mbsc-color-preview-cont {
    border-top: 1px solid #ccc;
    overflow: hidden;
    padding-top: .25em;
  }

  .mbsc-color-preview-item-c {
    display: table-cell;
  }

  .mbsc-color-preview-item {
    min-width: 1.875em;
    width: 1.875em;
    height: 1.875em;
    margin: .25em;
    cursor: pointer;
    background: transparent;
    box-sizing: border-box;
  }

  .mbsc-color-preview-item-empty {
    border: 1px dashed;
    border-color: #bbb;
  }


  /* input style */

  .mbsc-color-input {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #aaa;
    min-height: 2.125em;
    width: 100%;
    padding: .125em;
    box-sizing: border-box;
  }

  .mbsc-color-input:empty:not(:focus):before {
    content: attr(data-placeholder);
    position: absolute;
    top: 50%;
    overflow: hidden;
    margin-top: -1em;
    opacity: .6;
    font-size: 1em;
    line-height: 2em;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .mbsc-label-floating .mbsc-color-input:empty:not(:focus):before {
    opacity: 0;
  }

  .mbsc-label-floating-active .mbsc-color-input:empty:not(:focus):before {
    opacity: 1;
  }

  .mbsc-color-input-item {
    display: inline-block;
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    position: relative;
    margin: .125em;
    height: 1.5em;
    width: 4.0625em;
    vertical-align: middle;
  }

  .mbsc-color-input-hdn {
    position: absolute;
    height: 1px !important;
    width: 1px !important;
    margin: 0 !important;
    padding: 0 !important;
    left: 0;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }

  .mbsc-color-input-item-close {
    position: absolute;
    top: 10%;
    right: 0;
    height: 80%;
    width: 1.75em;
    cursor: pointer;
    color: #fff;
    border-left: 1px solid #fff;
    text-align: center;
  }

  .mbsc-color-input-item-close:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.5em;
    margin: -.75em 0 0 -.75em;
  }

  .mbsc-input .mbsc-color-input {
    margin: 0;
    padding: 0;
    border: 0;
    border-radius: 0;
    outline: 0;
    font-family: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-sizing: border-box;
  }


  /* JQuery Mobile */

  .ui-input-text .mbsc-color-input {
    border-color: transparent;
  }
}


@include exports("color.mobiscroll") {
  .mbsc-mobiscroll {
    .mbsc-color-preview-item {
      border-radius: 1em;
    }

    .mbsc-color-clear-item,
    .mbsc-color-item {
      border-radius: 1.75em;
    }

    .mbsc-color-active,
    &.mbsc-no-touch .mbsc-color-item-c:hover {
      border-radius: 3em;
    }

    .mbsc-color-preview-cont {
      padding: .25em 0 0 .25em;
    }

    &.mbsc-input .mbsc-color-input {
      height: auto;
      min-height: 2.125em;
      padding: 0 0 1px 0;
      background: transparent;
      font-size: 1em;
    }

    &.mbsc-input .mbsc-color-input-item {
      border-radius: .125em;
    }

    &.mbsc-input-box.mbsc-label-stacked .mbsc-color-input:empty:not(:focus):before {
      margin-top: -.375em;
    }
  }

  @include mbsc-mobiscroll-color(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}



// range component






@mixin mbsc-mobiscroll-range($theme, $params) {
  @include exports("range.#{$theme}.colors") {

    @include mbsc-mobiscroll-calbase($theme, $params);

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    $accent: map-get($params, 'accent');

    $background-param: map-get($params, 'frame-background');
    $text-param: map-get($params, 'frame-text');
    $accent-param: map-get($params, 'frame-accent');

    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
    $accent: if($accent-param, $accent-param, $accent);

    .mbsc-#{$theme} {
      .mbsc-range-btn {
        border: 1px solid $accent;
      }

      &.mbsc-range {
        .mbsc-range-btn-t .mbsc-range-btn.mbsc-selected {
          background: $accent;
          color: $background;
        }

        .mbsc-cal .mbsc-cal-row .mbsc-cal-day.mbsc-selected .mbsc-cal-day-date {
          background: none;
          border-color: transparent;
          color: $text;
        }

        .mbsc-cal .mbsc-cal-row .mbsc-cal-day.mbsc-cal-day-hl .mbsc-cal-day-date {
          background: $accent;
          border-color: $accent;
          color: $background;
        }

        .mbsc-cal-day.mbsc-selected:after {
          background: rgba($accent, .3);
        }
      }
    }
  }
}




@include exports("range") {

  .mbsc-range-btn,
  .mbsc-range-btn-t,
  .mbsc-range-btn-c {
    box-sizing: border-box;
  }

  .mbsc-range-btn-t {
    display: table;
    width: 100%;
  }

  .mbsc-range-btn-c {
    display: table-cell;
    width: 50%;
  }

  .mbsc-range-btn {
    text-decoration: none;
    text-align: center;
    display: block;
    cursor: pointer;
  }

  .mbsc-cal-hide-diff .mbsc-cal-day-diff:after {
    display: none;
  }
}


@include exports("range.mobiscroll") {
  .mbsc-mobiscroll {
    .mbsc-range-btn-t {
      padding: .5em .25em 0 .25em;
    }

    .mbsc-range-btn {
      margin: 0 .3333em;
      padding: .3333em;
      font-size: .75em;
      line-height: 1.666667em;
    }

    &.mbsc-range {
      .mbsc-fr-w {
        padding: 0;
      }

      .mbsc-fr-hdr {
        padding-top: .6666em;
      }

      .mbsc-cal .mbsc-cal-row .mbsc-cal-day.mbsc-selected .mbsc-cal-day-date {
        background: none;
        border-color: transparent;
      }

      .mbsc-cal-day.mbsc-selected:after {
        content: '';
        position: absolute;
        z-index: -1;
        top: .25em;
        left: 0;
        right: 0;
        height: 2em;
      }

      .mbsc-ltr .mbsc-cal-sel-start:after,
      .mbsc-rtl .mbsc-cal-sel-end:after {
        left: 50%;
        margin-left: -1em;
      }

      .mbsc-ltr .mbsc-cal-sel-end:after,
      .mbsc-rtl .mbsc-cal-sel-start:after {
        right: 50%;
        margin-right: -1em;
      }

      .mbsc-ltr .mbsc-cal-sel-start:after,
      .mbsc-ltr .mbsc-cal-day:first-child:after,
      .mbsc-ltr .mbsc-cal-hide-diff .mbsc-cal-day-first:after,
      .mbsc-rtl .mbsc-cal-sel-end:after,
      .mbsc-rtl .mbsc-cal-day:last-child:after,
      .mbsc-rtl .mbsc-cal-hide-diff .mbsc-cal-day-last:after {
        border-top-left-radius: 2em;
        border-bottom-left-radius: 2em;
      }

      .mbsc-ltr .mbsc-cal-sel-end:after,
      .mbsc-ltr .mbsc-cal-day:last-child:after,
      .mbsc-ltr .mbsc-cal-hide-diff .mbsc-cal-day-last:after,
      .mbsc-rtl .mbsc-cal-sel-start:after,
      .mbsc-rtl .mbsc-cal-day:first-child:after,
      .mbsc-rtl .mbsc-cal-hide-diff .mbsc-cal-day-first:after {
        border-top-right-radius: 2em;
        border-bottom-right-radius: 2em;
      }
    }
  }

  @include mbsc-mobiscroll-range(mobiscroll, $mbsc-mobiscroll-colors);
}



// scroller component



// listview component





// Theme specific variables - inherited from global variables

// background
$mbsc-mobiscroll-listview-background: $mbsc-listview-background-light !default;
$mbsc-mobiscroll-dark-listview-background: $mbsc-listview-background-dark !default;
// text
$mbsc-mobiscroll-listview-text: $mbsc-listview-text-light !default;
$mbsc-mobiscroll-dark-listview-text: $mbsc-listview-text-dark !default;
// accent
$mbsc-mobiscroll-listview-accent: $mbsc-listview-accent-light !default;
$mbsc-mobiscroll-dark-listview-accent: $mbsc-listview-accent-dark !default;
// header background
$mbsc-mobiscroll-listview-header-background: $mbsc-listview-header-background-light !default;
$mbsc-mobiscroll-dark-listview-header-background: $mbsc-listview-header-background-dark !default;
// header text
$mbsc-mobiscroll-listview-header-text: $mbsc-listview-header-text-light !default;
$mbsc-mobiscroll-dark-listview-header-text: $mbsc-listview-header-text-dark !default;

// add variables to the color maps
$mbsc-mobiscroll-colors: map-merge($mbsc-mobiscroll-colors, (
  'listview-background': $mbsc-mobiscroll-listview-background,
  'listview-text': $mbsc-mobiscroll-listview-text,
  'listview-accent': $mbsc-mobiscroll-listview-accent,
  'listview-header-background': $mbsc-mobiscroll-listview-header-background,
  'listview-header-text': $mbsc-mobiscroll-listview-header-text,
));
$mbsc-mobiscroll-dark-colors: map-merge($mbsc-mobiscroll-dark-colors, (
  'listview-background': $mbsc-mobiscroll-dark-listview-background,
  'listview-text': $mbsc-mobiscroll-dark-listview-text,
  'listview-accent': $mbsc-mobiscroll-dark-listview-accent,
  'listview-header-background': $mbsc-mobiscroll-dark-listview-header-background,
  'listview-header-text': $mbsc-mobiscroll-dark-listview-header-text,
));

@mixin mbsc-mobiscroll-listview-base($theme, $params) {
  @include exports("listview-base.#{$theme}.colors") {

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    $accent: map-get($params, 'accent');

    // get custom variables
    $background-param: map-get($params, 'listview-background');
    $text-param: map-get($params, 'listview-text');
    $accent-param: map-get($params, 'listview-accent');
    $header-background-param: map-get($params, 'listview-header-background');
    $header-text-param: map-get($params, 'listview-header-text');
    // overwrite variables with custom ones when provided
    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
    $accent: if($accent-param, $accent-param, $accent);
    $header-background: if($header-background-param, $header-background-param, $accent);
    $header-text: if($header-text-param, $header-text-param, $background);

    $lv-background: '';
    // Light background
    @if (lightness($background) > 50%) {
      $lv-background: darken($background, 19%);
    }
    // Dark background
    @else {
      $lv-background: lighten($background, 14%);
    }

    .mbsc-#{$theme} {
      .mbsc-lv {
        background: $lv-background;
      }

      .mbsc-lv-item {
        background: $background;
        color: $text;
      }

      .mbsc-lv-gr-title {
        background: $header-background;
        color: $header-text;
      }

      &.mbsc-no-touch .mbsc-lv-item-actionable:hover:before,
      .mbsc-lv-item-active.mbsc-lv-item-actionable:before,
      .mbsc-lv-item-actionable.mbsc-focus:before {
        background: rgba($accent, .15);
      }
    }
  }
}



@include exports("listview-base") {
  .mbsc-lv-cont {
    overflow: hidden;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -ms-touch-action: pan-y;
    user-select: none;
    touch-action: pan-y;
    font-size: 16px;
    font-family: arial, verdana, sans-serif;
    padding-top: 1px;
    /* Needed by iPad OS 13, if list is inside a scrollable container */
    will-change: opacity;
  }

  /* List */

  .mbsc-lv-cont .mbsc-lv {
    list-style: none;
    line-height: normal;
    margin: 0;
    padding: 0;
  }

  .mbsc-lv {
    display: none;
    position: relative;
  }

  .mbsc-lv-v {
    display: block;
  }

  /* Group title */

  .mbsc-lv-gr-title {
    position: relative;
    z-index: 3;
    display: block;
  }

  /* Items */

  .mbsc-lv .mbsc-lv-item {
    position: relative;
    z-index: 2;
    list-style: none;
    display: block;
    box-sizing: border-box;
  }

  .mbsc-lv-item-actionable {
    cursor: pointer;
    outline: 0;
  }

  .mbsc-lv .mbsc-lv-item.mbsc-input {
    margin: -1px 0 0 0;
  }

  .mbsc-lv-item-actionable:before,
  .mbsc-lv-item.mbsc-selected:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    transition: background-color .2s ease-out;
  }
}


@include exports("listview-base.mobiscroll") {
  .mbsc-mobiscroll {
    &.mbsc-lv-cont {
      font-family: arial, verdana, sans-serif;
      padding-top: 1px;
    }

    .mbsc-lv-item {
      padding: 1.25em;
      font-weight: normal;
    }

    .mbsc-lv-gr-title {
      padding: 0 1.25em;
      font-size: .75em;
      text-transform: uppercase;
      line-height: 2em;
      margin-top: -1px;
    }
  }

  @include mbsc-mobiscroll-listview-base(mobiscroll, $mbsc-mobiscroll-colors);
}





@mixin mbsc-mobiscroll-listview($theme, $params) {
  @include exports("listview.#{$theme}.colors") {

    @include mbsc-mobiscroll-listview-base($theme, $params);
    @include mbsc-mobiscroll-common($theme, $params);

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    $accent: map-get($params, 'accent');

    $colors: mbsc-mobiscroll-colors($params);
    $white-text: map-get($colors, 'white-text');

    // get custom variables
    $background-param: map-get($params, 'listview-background');
    $text-param: map-get($params, 'listview-text');
    $accent-param: map-get($params, 'listview-accent');
    // overwrite variables with custom ones when provided
    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
    $accent: if($accent-param, $accent-param, $accent);

    // derived colors
    $lv-item: darken($background, 4%);

    .mbsc-#{$theme} {
      &.mbsc-lv-alt-row .mbsc-lv-item:nth-child(even) {
        background: $lv-item;
      }

      .mbsc-lv-item-hl:before {
        background: rgba($accent, .15);
      }

      .mbsc-lv-ic-m {
        color: $white-text;
      }

      .mbsc-lv-arr {
        color: $accent;
      }

      .mbsc-lv-handle-bar {
        background: $accent;
      }

      .mbsc-lv-item.mbsc-lv-item-dragging {
        background: $accent;
        color: $background;
      }

      .mbsc-lv-item-dragging .mbsc-lv-handle-bar {
        background: $background;
      }

      .mbsc-lv-item-dragging .mbsc-lv-arr {
        color: $background;
      }

      .mbsc-lv-loading .mbsc-ic {
        color: $text;
      }

      &.mbsc-lv-cont .mbsc-lv-item.mbsc-selected:before {
        background: rgba($accent, .3);
      }
    }
  }
}






@include exports("listview") {
  /* Animations */

  @-webkit-keyframes mbsc-lv-spin {
    from {
      -webkit-transform: rotate(180deg);
      opacity: 0;
    }

    to {
      -webkit-transform: rotate(0);
      opacity: 1;
    }
  }

  @-webkit-keyframes mbsc-lv-remove-right {
    from {}

    /* Needed by Android 2.3 */
    to {
      -webkit-transform: translateX(100%);
    }
  }

  @-webkit-keyframes mbsc-lv-remove-left {
    from {}

    /* Needed by Android 2.3 */
    to {
      -webkit-transform: translateX(-100%);
    }
  }

  @-webkit-keyframes mbsc-lv-add-right {
    from {
      -webkit-transform: translateX(100%);
    }

    to {
      -webkit-transform: translateX(0);
    }
  }

  @-webkit-keyframes mbsc-lv-add-left {
    from {
      -webkit-transform: translateX(-100%);
    }

    to {
      -webkit-transform: translateX(0);
    }
  }

  @-webkit-keyframes mbsc-lv-pop-in {
    from {
      -webkit-transform: scale(0);
      opacity: 0;
    }

    to {
      -webkit-transform: scale(1);
      opacity: 1;
    }
  }

  @-webkit-keyframes mbsc-lv-pop-out {
    from {
      -webkit-transform: scale(1);
      opacity: 1;
    }

    to {
      -webkit-transform: scale(0);
      opacity: 0;
    }
  }

  @-webkit-keyframes mbsc-lv-collapse {
    from {
      padding: 0;
      border: 0;
    }

    to {
      padding: 0;
      border: 0;
      height: 0;
    }
  }

  @-webkit-keyframes mbsc-lv-expand {
    from {
      padding: 0;
      border: 0;
      height: 0;
    }

    to {
      padding: 0;
      border: 0;
    }
  }

  @-webkit-keyframes mbsc-lv-fill {
    from {
      -webkit-transform: scale(1, 0);
    }

    to {
      -webkit-transform: scale(1, 1);
    }
  }

  @keyframes mbsc-lv-spin {
    from {
      transform: rotate(180deg);
      opacity: 0;
    }

    to {
      transform: rotate(0);
      opacity: 1;
    }
  }

  @keyframes mbsc-lv-remove-right {
    to {
      transform: translateX(100%);
    }
  }

  @keyframes mbsc-lv-remove-left {
    to {
      transform: translateX(-100%);
    }
  }

  @keyframes mbsc-lv-add-right {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(0);
    }
  }

  @keyframes mbsc-lv-add-left {
    from {
      transform: translateX(-100%);
    }

    to {
      transform: translateX(0);
    }
  }

  @keyframes mbsc-lv-pop-in {
    from {
      transform: scale(0);
      opacity: 0;
    }

    to {
      transform: scale(1);
    }
  }

  @keyframes mbsc-lv-pop-out {
    from {
      transform: scale(1);
    }

    to {
      transform: scale(0);
      opacity: 0;
    }
  }

  @keyframes mbsc-lv-collapse {
    from {
      padding: 0;
      border: 0;
    }

    to {
      padding: 0;
      border: 0;
      height: 0;
    }
  }

  @keyframes mbsc-lv-expand {
    from {
      padding: 0;
      border: 0;
      height: 0;
    }

    to {
      padding: 0;
      border: 0;
    }
  }

  @keyframes mbsc-lv-fill {
    from {
      transform: scale(1, 0);
    }

    to {
      transform: scale(1, 1);
    }
  }

  /* Loading animation */

  @keyframes mbsc-lv-rotating {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes mbsc-lv-rotating {
    from {
      -webkit-transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(360deg);
    }
  }

  /* Backface visibility */

  .mbsc-lv-stage-c-v,
  .mbsc-lv-item,
  .mbsc-lv-gr-title,
  .mbsc-lv-ic-text {
    -webkit-transform: translateZ(0);
  }

  .mbsc-lv,
  .mbsc-lv-fill-item {
    -webkit-backface-visibility: hidden;
  }

  .mbsc-rtl {
    direction: rtl;
  }

  /* List */

  .mbsc-lv.mbsc-lv-dummy {
    display: block;
    margin-top: -1px;
    -webkit-backface-visibility: visible;
  }

  /* Fixed header */

  .mbsc-lv-fixed-header {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    list-style: none;
    width: 100%;
    z-index: 10;
  }

  .mbsc-lv-fixed-header-ctx {
    position: absolute;
  }

  /* Items */

  .mbsc-lv .mbsc-lv-item.mbsc-lv-item-swiping {
    z-index: 1;
    overflow: visible;
  }

  .mbsc-lv-stage-c {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: none;
  }

  .mbsc-lv-stage-c-v {
    display: block;
  }

  .mbsc-lv-item-new-left {
    -webkit-transform: translateX(-100%);
  }

  .mbsc-lv-item-new-right {
    -webkit-transform: translateX(100%);
  }

  .mbsc-lv-item-remove-right {
    -webkit-animation: mbsc-lv-remove-right 125ms;
    -webkit-animation-delay: 1ms;
    /* Needed for iOS8 to prevent flicker */
    animation: mbsc-lv-remove-right 125ms;
  }

  .mbsc-lv-item-remove-left {
    -webkit-animation: mbsc-lv-remove-left 125ms;
    -webkit-animation-delay: 1ms;
    /* Needed for iOS8 to prevent flicker */
    animation: mbsc-lv-remove-left 125ms;
  }

  .mbsc-lv-item-add-right {
    -webkit-animation: mbsc-lv-add-right 125ms;
    animation: mbsc-lv-add-right 125ms;
  }

  .mbsc-lv-item-add-left {
    -webkit-animation: mbsc-lv-add-left 125ms;
    animation: mbsc-lv-add-left 125ms;
  }

  .mbsc-lv-item-pop-in {
    -webkit-animation: mbsc-lv-pop-in 200ms;
    animation: mbsc-lv-pop-in 200ms;
  }

  .mbsc-lv-item-pop-out {
    -webkit-animation: mbsc-lv-pop-out 200ms;
    animation: mbsc-lv-pop-out 200ms;
  }

  .mbsc-lv-item-collapse {
    visibility: hidden;
    -webkit-animation: mbsc-lv-collapse 125ms;
    animation: mbsc-lv-collapse 125ms;
  }

  .mbsc-lv-item-expand {
    visibility: hidden;
    -webkit-animation: mbsc-lv-expand 125ms;
    animation: mbsc-lv-expand 125ms;
  }

  .mbsc-lv .mbsc-lv-item.mbsc-lv-item-undo {
    position: absolute;
    left: 0;
    right: 0;
  }

  /* Reorder */

  .mbsc-lv-cont .mbsc-lv-item.mbsc-lv-item-dragging {
    z-index: 100;
    position: absolute;
    left: 0;
    right: 0;
    opacity: .9;
    box-shadow: 0 0 0.625em rgba(0, 0, 0, .5);
    cursor: pointer;
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }

  .mbsc-lv-item-dragging .mbsc-lv-handle-c {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }

  .mbsc-lv .mbsc-lv-item.mbsc-lv-ph {
    padding: 0;
    border: 0;
    visibility: hidden;
  }

  .mbsc-lv-fill-item {
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .1;
  }

  /* Sort handle */

  .mbsc-lv-handle-c {
    position: absolute;
    z-index: 4;
    top: 0;
    height: 100%;
    width: 2.5em;
    -ms-touch-action: none;
    touch-action: none;
    cursor: pointer;
    cursor: -webkit-grab;
    cursor: grab;
  }

  .mbsc-lv-item-h-right {
    right: 0;
  }

  .mbsc-lv-item-h-left {
    left: 0;
  }

  .mbsc-lv-cont.mbsc-lv-handle-left .mbsc-lv .mbsc-lv-item {
    padding-left: 2.5em;
  }

  .mbsc-lv-cont.mbsc-lv-handle-right .mbsc-lv .mbsc-lv-item {
    padding-right: 2.5em;
  }

  .mbsc-lv-handle-bar-c {
    position: absolute;
    width: 100%;
    top: 50%;
    margin-top: -7px;
  }

  .mbsc-lv-handle-bar {
    position: relative;
    height: 2px;
    margin: 2px .625em;
    background: #888;
  }

  /* Icon and text */

  .mbsc-lv-ic,
  .mbsc-lv-ic-m {
    top: 0;
    text-align: center;
    text-shadow: none;
    font-size: .75em;
  }

  /* Stage icon */

  .mbsc-lv-ic-s {
    position: absolute;
    top: 50%;
    margin-top: -0.9375em;
    width: 2.8em;
    height: 1.875em;
    line-height: 1.875em;
    font-size: 1.25em;
  }

  /* Stage icon container */

  .mbsc-lv-ic-c {
    position: absolute;
    top: 0;
    height: 100%;
    line-height: 1.875em;
    color: #fff;
    cursor: pointer;
  }

  /* Stage text */

  .mbsc-lv-ic-text {
    text-shadow: none;
    font-size: 1em;
  }

  /* Empty icon */

  .mbsc-ic-none {
    border: 0;
  }

  .mbsc-ic-none:before {
    content: "0";
    font-size: 0;
    color: rgba(0, 0, 0, 0);
  }

  /* Stage icon animation */

  .mbsc-lv-ic-anim .mbsc-lv-ic-s {
    opacity: 0;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .mbsc-lv-ic-anim .mbsc-lv-ic-a {
    opacity: 1;
    -webkit-animation: mbsc-lv-spin 200ms;
    -webkit-transform: rotate(0);
    animation: mbsc-lv-spin 200ms;
    transform: rotate(0);
  }

  .mbsc-lv-ic-anim .mbsc-lv-ic-v {
    opacity: 1;
    -webkit-animation: none;
    -webkit-transform: rotate(0);
    animation: none;
    transform: rotate(0);
  }

  /* Stage icon and text left and right positioning */

  .mbsc-lv-ic-left {
    left: 0;
  }

  .mbsc-lv-ic-move-left {
    left: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  .mbsc-lv-ic-right .mbsc-lv-ic,
  .mbsc-lv-ic-move-left .mbsc-lv-ic {
    right: 0;
  }

  .mbsc-lv-ic-right .mbsc-lv-ic-text,
  .mbsc-lv-ic-move-left .mbsc-lv-ic-text {
    padding: 0 3.5em 0 0.625em;
  }

  .mbsc-lv-ic-right {
    right: 0;
  }

  .mbsc-lv-ic-move-right {
    right: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  .mbsc-lv-ic-left .mbsc-lv-ic,
  .mbsc-lv-ic-move-right .mbsc-lv-ic {
    left: 0;
  }

  .mbsc-lv-ic-left .mbsc-lv-ic-text,
  .mbsc-lv-ic-move-right .mbsc-lv-ic-text {
    padding: 0 0.625em 0 3.5em;
  }

  .mbsc-lv-ic-c .mbsc-lv-ic-only {
    width: 0;
    padding: 0 0 0 3.5em;
  }

  .mbsc-lv-ic-c .mbsc-lv-ic-text-only {
    padding: 0 0.625em;
  }

  /* Multiple icons */

  .mbsc-lv-multi-c {
    height: 100%;
  }

  .mbsc-lv-multi {
    display: none;
    width: 90%;
    height: 100%;
    table-layout: fixed;
  }

  .mbsc-lv-multi-ic-right {
    float: right;
  }

  .mbsc-lv-multi-ic-left {
    float: left;
  }

  .mbsc-lv-right .mbsc-lv-multi-ic-right {
    display: table;
  }

  .mbsc-lv-left .mbsc-lv-multi-ic-left {
    display: table;
  }

  .mbsc-lv-ic-m {
    display: table-cell;
    vertical-align: middle;
    padding: 0 .25em;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mbsc-lv-ic-m:before {
    display: block;
    padding: .25em 0;
    font-size: 1.333334em;
  }

  .mbsc-lv-ic-disabled {
    opacity: .5;
  }

  /* Hierarchy slide containers */

  .mbsc-lv-sl-c {
    position: relative;
    -webkit-animation-fill-mode: forwards;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  .mbsc-lv-sl-r {
    -webkit-animation: mbsc-lv-remove-left 300ms;
    animation: mbsc-lv-remove-left 300ms;
  }

  .mbsc-lv-sl-l {
    -webkit-animation: mbsc-lv-remove-right 300ms;
    animation: mbsc-lv-remove-right 300ms;
  }

  .mbsc-lv-sl-l .mbsc-lv-sl-curr,
  .mbsc-lv-sl-r .mbsc-lv-sl-curr {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .mbsc-lv-sl-r .mbsc-lv-sl-new {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  .mbsc-lv-sl-l .mbsc-lv-sl-new {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  /* Hierarchy arrows */

  .mbsc-lv-arr {
    position: absolute;
    top: 50%;
    width: 2em;
    height: 2em;
    font-size: 1em;
    line-height: 2em;
    margin-top: -1em;
  }

  .mbsc-lv .mbsc-lv-back {
    z-index: 3;
  }

  .mbsc-ltr .mbsc-lv-item.mbsc-lv-parent {
    padding-right: 2.25em;
  }

  .mbsc-ltr .mbsc-lv-item.mbsc-lv-back {
    padding-left: 2.25em;
  }

  .mbsc-ltr .mbsc-lv-parent .mbsc-lv-arr {
    right: 0;
  }

  .mbsc-ltr.mbsc-lv-handle-right .mbsc-lv-parent .mbsc-lv-arr {
    right: 40px;
  }

  .mbsc-ltr .mbsc-lv-back .mbsc-lv-arr {
    left: 0;
  }

  .mbsc-rtl .mbsc-lv-arr {
    -webkit-transform: scale(-1, 1);
    transform: scale(-1, 1);
  }

  .mbsc-rtl .mbsc-lv-item.mbsc-lv-parent {
    padding-left: 2.25em;
  }

  .mbsc-rtl .mbsc-lv-item.mbsc-lv-back {
    padding-right: 2.25em;
  }

  .mbsc-rtl .mbsc-lv-parent .mbsc-lv-arr {
    left: 0;
  }

  .mbsc-rtl.mbsc-lv-handle-left .mbsc-lv-parent .mbsc-lv-arr {
    left: 40px;
  }

  .mbsc-rtl .mbsc-lv-back .mbsc-lv-arr {
    right: 0;
  }

  /* Text enhance */

  .mbsc-lv .mbsc-lv-txt {
    margin: 0;
    font-weight: normal;
  }

  .mbsc-lv h1.mbsc-lv-txt {
    font-size: 2em;
  }

  .mbsc-lv h2.mbsc-lv-txt {
    font-size: 1.5em;
  }

  .mbsc-lv h3.mbsc-lv-txt {
    font-size: 1.125em;
  }

  .mbsc-lv h4.mbsc-lv-txt {
    font-size: 1em;
  }

  .mbsc-lv h5.mbsc-lv-txt {
    font-size: .875em;
  }

  .mbsc-lv h6.mbsc-lv-txt {
    font-size: .625em;
  }

  .mbsc-lv p.mbsc-lv-txt {
    font-size: .75em;
  }

  /* Image and text enhance */

  .mbsc-lv-img {
    position: absolute;
    max-height: 2.5em;
    max-width: 2.5em;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .mbsc-lv-cont .mbsc-lv-item.mbsc-lv-img-left {
    padding-left: 5em;
  }

  .mbsc-lv-cont .mbsc-lv-item.mbsc-lv-img-right {
    padding-right: 5em;
  }

  .mbsc-lv-img-left .mbsc-lv-img {
    left: 2.5em;
  }

  .mbsc-lv-img-right .mbsc-lv-img {
    right: 0;
  }

  .mbsc-lv-handle-left .mbsc-lv .mbsc-lv-item.mbsc-lv-img-left {
    padding-left: 6.25em;
  }

  .mbsc-lv-handle-left .mbsc-lv-img-left .mbsc-lv-img {
    left: 3.75em;
  }

  .mbsc-lv-handle-right .mbsc-lv .mbsc-lv-item.mbsc-lv-img-right {
    padding-right: 6.25em;
  }

  .mbsc-lv-handle-right .mbsc-lv-img-right .mbsc-lv-img {
    right: 1.25em;
  }

  /* Icon and text enhance */

  .mbsc-lv-item-ic {
    position: absolute;
    top: 50%;
    margin-top: -1em;
    width: 2em;
    height: 2em;
    line-height: 2em;
    text-align: center;
  }

  .mbsc-lv-cont .mbsc-lv .mbsc-lv-item.mbsc-lv-item-ic-left {
    padding-left: 4em;
  }

  .mbsc-lv-cont .mbsc-lv .mbsc-lv-item.mbsc-lv-item-ic-right {
    padding-right: 4em;
  }

  .mbsc-lv-item-ic-left .mbsc-lv-item-ic {
    left: 1em;
  }

  .mbsc-lv-item-ic-right .mbsc-lv-item-ic {
    right: 1em;
  }

  .mbsc-lv-handle-left.mbsc-lv-cont .mbsc-lv .mbsc-lv-item-ic-left {
    padding-left: 5.5em;
  }

  .mbsc-lv-handle-left .mbsc-lv-item-ic-left .mbsc-lv-item-ic {
    left: 2.5em;
  }

  .mbsc-lv-handle-right.mbsc-lv-cont .mbsc-lv .mbsc-lv-item-ic-right {
    padding-right: 5.5em;
  }

  .mbsc-lv-handle-right .mbsc-lv-item-ic-right .mbsc-lv-item-ic {
    right: 2.5em;
  }

  /* Loading animation */

  .mbsc-lv-loading {
    text-align: center;
    opacity: 0;
    height: 0;

    .mbsc-ic {
      font-size: 1.375em;
    }

    .mbsc-ic:before {
      display: inline-block;
      -webkit-animation: mbsc-lv-rotating .6s linear infinite;
      animation: mbsc-lv-rotating .6s linear infinite;
    }
  }

  .mbsc-show-lv-loading.mbsc-lv-loading {
    opacity: 1;
    height: auto;
    padding: 1em;
  }
}


@include exports("listview.mobiscroll") {
  .mbsc-mobiscroll {
    .mbsc-lv-item.mbsc-lv-item-dragging {
      margin: 0;
      box-shadow: none;
    }

    &.mbsc-lv-handle-left .mbsc-lv-item.mbsc-checkbox.mbsc-rtl {
      padding-left: 4.25em;

      .mbsc-checkbox-box {
        left: 2.5em;
      }
    }
  }

  @include mbsc-mobiscroll-listview(mobiscroll, $mbsc-mobiscroll-colors);
}



// number component






@mixin mbsc-mobiscroll-number($theme, $params) {
  @include exports("number.#{$theme}.colors") {
    @include mbsc-mobiscroll-scroller($theme, $params);
  }
}





@include exports("number.mobiscroll") {
  @include mbsc-mobiscroll-number(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}



// numpad component






@mixin mbsc-mobiscroll-numpad($theme, $params) {
  @include exports("numpad.#{$theme}.colors") {

    @include mbsc-mobiscroll-frame($theme, $params);

    $accent: map-get($params, 'accent');
    $accent-param: map-get($params, 'frame-accent');
    $accent: if($accent-param, $accent-param, $accent);

    .mbsc-#{$theme} {
      .mbsc-np-del {
        color: $accent;
      }
    }
  }
}




@include exports("numpad") {
  .mbsc-np-hdr {
    position: relative;
  }

  .mbsc-np-dsp {
    direction: ltr;
    padding: .3125em 1.75em .3125em .5em;
    text-align: left;
    font-size: 2em;
    line-height: normal;
  }

  .mbsc-np-sup {
    display: inline-block;
    font-size: .375em;
    vertical-align: top;
  }

  .mbsc-np-del {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 3.5em;
    text-align: center;
  }

  .mbsc-np-del:before {
    display: block;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -.5em;
    font-size: 2em;
    line-height: 1;
  }

  .mbsc-np-ph {
    display: inline-block;
    min-width: .5625em;
    text-align: center;
    vertical-align: top;
  }

  .mbsc-np-tbl-c {
    min-width: 15em;
    /*display: inline-block;*/
  }

  .mbsc-fr-top .mbsc-np-tbl-c,
  .mbsc-fr-bottom .mbsc-np-tbl-c {
    display: block;
  }

  .mbsc-np-tbl {
    direction: ltr;
    display: table;
    width: 100%;
    font-size: 1.375em;
  }

  .mbsc-np-row {
    display: table-row;
  }

  .mbsc-np-btn {
    position: relative;
    z-index: 0;
    width: 33.3333333333%;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    height: 3em;
    -webkit-user-select: none;
  }

  .mbsc-np-btn.mbsc-active {
    opacity: 1;
  }


  /* Timespan */

  .mbsc-np-time {
    text-transform: uppercase;
  }

  .mbsc-np-ts-h,
  .mbsc-np-ts-m {
    font-weight: bold;
  }
}


@include exports("numpad.mobiscroll") {
  .mbsc-mobiscroll {
    .mbsc-np-tbl-c {
      padding: .5em;
    }

    .mbsc-np-btn.mbsc-disabled {
      opacity: .5;
    }
  }

  @include mbsc-mobiscroll-numpad(mobiscroll, $mbsc-mobiscroll-colors);
}



// forms component





@include exports("collapsible") {
  .mbsc-collapsible.mbsc-form-group {
    margin: 0;
  }

  .mbsc-collapsible-header {
    position: relative;
    padding-right: 3em;
  }

  .mbsc-collapsible .mbsc-collapsible-icon {
    position: absolute;
    width: 1em;
    height: 1em;
    top: 50%;
    right: 1em;
    margin-top: -.5em;
    line-height: 1em;
    font-size: 1em;
    transition: transform .125s ease-out;
  }

  .mbsc-collapsible .mbsc-collapsible-content {
    overflow: hidden;
    transition: height .125s ease;
    box-sizing: content-box;
  }

  .mbsc-collapsible:not(.mbsc-collapsible-open) .mbsc-collapsible-content {
    height: 0;
    padding-bottom: 0;
  }

  .mbsc-collapsible-open > .mbsc-collapsible-header .mbsc-collapsible-icon {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
  }

  /* Collapsible rtl */

  .mbsc-rtl {

    .mbsc-collapsible.mbsc-form-group,
    .mbsc-collapsible.mbsc-card {

      .mbsc-collapsible-header,
      .mbsc-collapsible-header.mbsc-form-group-title {
        padding-right: 1em;
        padding-left: 3em;

        .mbsc-collapsible-icon {
          right: auto;
          left: 1em;
        }
      }
    }
  }
}


@include exports("collapsible.mobiscroll") {
  .mbsc-mobiscroll .mbsc-collapsible {
    .mbsc-form-group-title {
      padding: 1em;
    }

    .mbsc-collapsible-header {
      padding-right: 3em;
    }
  }
}










@mixin mbsc-mobiscroll-rating($theme, $params) {
  @include exports("rating.#{$theme}.colors") {

    @include mbsc-mobiscroll-progress($theme, $params);

    $accent: map-get($params, accent);

    .mbsc-#{$theme}.mbsc-rating {
      .mbsc-progress-track {
        color: $accent;
      }
    }
  }
}




@include exports("rating") {
  .mbsc-rating {
    &.mbsc-progress .mbsc-progress-cont {
      display: inline-block;
      width: auto;
      padding: 0 .5em;
      margin: 0 -.681818em;
      cursor: pointer;
    }

    .mbsc-progress-cont .mbsc-progress-track {
      height: auto;
      background: none;
    }

    .mbsc-ic {
      font-size: 1.375em;
      margin: 0 0.181818em;
    }

    &.mbsc-progress input {
      display: none;
    }

    &.mbsc-progress .mbsc-progress-cont .mbsc-slider-handle {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      margin: 0;
      top: 0;
      left: 0;
      right: 0;
      background: none;
      box-shadow: none;
      border: 0;
      -webkit-transform: none;
      transform: none;
    }

    .mbsc-progress-cont .mbsc-progress-bar {
      background: none;
      overflow: hidden;
      white-space: nowrap;
      border-radius: 0;
      transition: none;
    }
  }
}


@include exports("rating.mobiscroll") {
  .mbsc-mobiscroll.mbsc-rating {
    .mbsc-label {
      padding-bottom: .5em;
    }

    input:disabled ~ .mbsc-progress-cont .mbsc-progress-track {
      opacity: .4;
    }

    &.mbsc-rating-primary .mbsc-progress-track {
      color: $mbsc-mobiscroll-primary;
    }

    &.mbsc-rating-secondary .mbsc-progress-track {
      color: $mbsc-mobiscroll-secondary;
    }

    &.mbsc-rating-success .mbsc-progress-track {
      color: $mbsc-mobiscroll-success;
    }

    &.mbsc-rating-danger .mbsc-progress-track {
      color: $mbsc-mobiscroll-danger;
    }

    &.mbsc-rating-warning .mbsc-progress-track {
      color: $mbsc-mobiscroll-warning;
    }

    &.mbsc-rating-info .mbsc-progress-track {
      color: $mbsc-mobiscroll-info;
    }
  }

  @include mbsc-mobiscroll-rating(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}








@mixin mbsc-mobiscroll-popup($theme, $params) {
  @include exports("popup.#{$theme}.colors") {

    @include mbsc-mobiscroll-frame($theme, $params);
  }
}




@include exports("popup") {

  .mbsc-wdg .mbsc-wdg-c {
    position: relative;
    z-index: 0;
    font-size: 14px;
    white-space: normal;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }

  .mbsc-wdg .mbsc-ltr .mbsc-wdg-c {
    text-align: left;
  }

  .mbsc-wdg .mbsc-rtl .mbsc-wdg-c {
    text-align: right;
  }

  .mbsc-wdg .mbsc-wdg-c.mbsc-w-p {
    padding: 1em;
  }

  .mbsc-no-padding.mbsc-wdg .mbsc-wdg-c.mbsc-w-p {
    padding: 0;
  }
}


@include exports("popup.mobiscroll") {
  @include mbsc-mobiscroll-popup(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}





@mixin mbsc-mobiscroll-notifications($theme, $params) {
  @include exports("notifications.#{$theme}.colors") {

    @include mbsc-mobiscroll-popup($theme, $params);

    $background: map-get($params, background);

    $colors: mbsc-mobiscroll-colors($params);
    $notification: map-get($colors, notification);
    $white-text: map-get($colors, white-text);

    .mbsc-#{$theme} {

      &.mbsc-snackbar .mbsc-fr-w,
      &.mbsc-toast .mbsc-toast-msg {
        background: $notification;
        color: $white-text;
      }

      /* Snackbar and Toast color presets */
      &.mbsc-snackbar.mbsc-primary .mbsc-fr-w,
      &.mbsc-toast.mbsc-primary .mbsc-toast-msg,
      &.mbsc-snackbar.mbsc-secondary .mbsc-fr-w,
      &.mbsc-toast.mbsc-secondary .mbsc-toast-msg,
      &.mbsc-snackbar.mbsc-success .mbsc-fr-w,
      &.mbsc-toast.mbsc-success .mbsc-toast-msg,
      &.mbsc-snackbar.mbsc-danger .mbsc-fr-w,
      &.mbsc-toast.mbsc-danger .mbsc-toast-msg,
      &.mbsc-snackbar.mbsc-warning .mbsc-fr-w,
      &.mbsc-toast.mbsc-warning .mbsc-toast-msg,
      &.mbsc-snackbar.mbsc-info .mbsc-fr-w,
      &.mbsc-toast.mbsc-info .mbsc-toast-msg,
      &.mbsc-snackbar.mbsc-primary .mbsc-snackbar-btn,
      &.mbsc-snackbar.mbsc-secondary .mbsc-snackbar-btn,
      &.mbsc-snackbar.mbsc-success .mbsc-snackbar-btn,
      &.mbsc-snackbar.mbsc-danger .mbsc-snackbar-btn,
      &.mbsc-snackbar.mbsc-warning .mbsc-snackbar-btn,
      &.mbsc-snackbar.mbsc-info .mbsc-snackbar-btn {
        color: $background;
      }
    }
  }
}




@include exports("notifications") {
  /* Toast, snackbar */

  .mbsc-toast .mbsc-fr-persp,
  .mbsc-snackbar .mbsc-fr-persp {
    pointer-events: none;
  }

  .mbsc-toast .mbsc-fr-overlay,
  .mbsc-snackbar .mbsc-fr-overlay {
    display: none;
  }

  .mbsc-toast.mbsc-fr .mbsc-fr-persp .mbsc-fr-popup,
  .mbsc-snackbar.mbsc-fr .mbsc-fr-persp .mbsc-fr-popup {
    border: 0;
    line-height: 1.428572;
  }

  /* Snackbar */

  .mbsc-snackbar.mbsc-fr .mbsc-fr-w {
    min-width: 18em;
    max-width: 36em;
    margin: 0 auto;
    pointer-events: auto;
    box-shadow: none;
  }

  .mbsc-snackbar-cont {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .mbsc-snackbar-msg {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 0 1em;
  }

  .mbsc-snackbar .mbsc-snackbar-cont .mbsc-snackbar-btn {
    margin: -1em 0;
    padding: 1em;
    line-height: 1.428572;
    font-size: 1em;
    font-weight: 400;
  }

  .mbsc-snackbar.mbsc-no-touch.mbsc-fr .mbsc-fr-c .mbsc-btn-flat:not(:disabled):hover {
    background: none;
    box-shadow: none;
  }


  /* Toast */

  .mbsc-toast.mbsc-fr .mbsc-fr-w {
    background: none;
    box-shadow: none;
  }

  .mbsc-toast.mbsc-fr .mbsc-fr-c.mbsc-wdg-c {
    text-align: center;
    padding-bottom: 4em;
  }

  .mbsc-toast .mbsc-toast-msg {
    display: inline-block;
    min-width: 10em;
    max-width: 50em;
    padding: .857143em 2em;
  }

  /* Alert, confirm, prompt */

  .mbsc-alert.mbsc-fr .mbsc-fr-c.mbsc-wdg-c {
    padding: 1.714286em;
    max-width: 20em;
  }

  .mbsc-alert .mbsc-fr-c .mbsc-input {
    padding: 0;
    margin: 0;
  }

  .mbsc-alert.mbsc-fr h2 {
    margin: 0 0 1em 0;
    padding: 0;
    font-size: 1.428572em;
    font-weight: bold;
  }

  .mbsc-alert.mbsc-fr p {
    margin: 1em 0;
    padding: 0;
    font-size: 1em;
  }
}

@include exports("notifications.mobiscroll") {
  .mbsc-mobiscroll {
    &.mbsc-toast .mbsc-toast-msg {
      opacity: .9;
      border-radius: .5em;
    }

    /* Snackbar and Toast color presets */
    &.mbsc-snackbar.mbsc-primary .mbsc-fr-w,
    &.mbsc-toast.mbsc-primary .mbsc-toast-msg {
      background: $mbsc-mobiscroll-primary;
    }

    &.mbsc-snackbar.mbsc-secondary .mbsc-fr-w,
    &.mbsc-toast.mbsc-secondary .mbsc-toast-msg {
      background: $mbsc-mobiscroll-secondary;
    }

    &.mbsc-snackbar.mbsc-success .mbsc-fr-w,
    &.mbsc-toast.mbsc-success .mbsc-toast-msg {
      background: $mbsc-mobiscroll-success;
    }

    &.mbsc-snackbar.mbsc-danger .mbsc-fr-w,
    &.mbsc-toast.mbsc-danger .mbsc-toast-msg {
      background: $mbsc-mobiscroll-danger;
    }

    &.mbsc-snackbar.mbsc-warning .mbsc-fr-w,
    &.mbsc-toast.mbsc-warning .mbsc-toast-msg {
      background: $mbsc-mobiscroll-warning;
    }

    &.mbsc-snackbar.mbsc-info .mbsc-fr-w,
    &.mbsc-toast.mbsc-info .mbsc-toast-msg {
      background: $mbsc-mobiscroll-info;
    }
  }

  @include mbsc-mobiscroll-notifications(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}









// Theme specific variables - inherited from global variables

// General colors for the form
// Background
$mbsc-mobiscroll-form-background: $mbsc-form-background-light !default;
$mbsc-mobiscroll-dark-form-background: $mbsc-form-background-dark !default;
// Text
$mbsc-mobiscroll-form-text: $mbsc-form-text-light !default;
$mbsc-mobiscroll-dark-form-text: $mbsc-form-text-dark !default;
// Accent
$mbsc-mobiscroll-form-accent: $mbsc-form-accent-light !default;
$mbsc-mobiscroll-dark-form-accent: $mbsc-form-accent-dark !default;

// Button
// Button background
$mbsc-mobiscroll-button-color: $mbsc-button-color-light !default;
$mbsc-mobiscroll-dark-button-color: $mbsc-button-color-dark !default;
// Button text
$mbsc-mobiscroll-button-text: $mbsc-button-text-light !default;
$mbsc-mobiscroll-dark-button-text: $mbsc-button-text-dark !default;

// Form Group
// text
$mbsc-mobiscroll-form-group-title-text: $mbsc-form-group-title-text-light !default;
$mbsc-mobiscroll-dark-form-group-title-text: $mbsc-form-group-title-text-dark !default;

// add variables to light and dark color maps
$mbsc-mobiscroll-colors: map-merge($mbsc-mobiscroll-colors, (
  'form-background': $mbsc-mobiscroll-form-background,
  'form-text': $mbsc-mobiscroll-form-text,
  'form-accent': $mbsc-mobiscroll-form-accent,
  'button-color': $mbsc-mobiscroll-button-color,
  'button-text': $mbsc-mobiscroll-button-text,
  'form-group-title-text': $mbsc-mobiscroll-form-group-title-text,
));
$mbsc-mobiscroll-dark-colors: map-merge($mbsc-mobiscroll-dark-colors, (
  'form-background': $mbsc-mobiscroll-dark-form-background,
  'form-text': $mbsc-mobiscroll-dark-form-text,
  'form-accent': $mbsc-mobiscroll-dark-form-accent,
  'button-color': $mbsc-mobiscroll-dark-button-color,
  'button-text': $mbsc-mobiscroll-dark-button-text,
  'form-group-title-text': $mbsc-mobiscroll-dark-form-group-title-text,
));

@mixin mbsc-mobiscroll-forms($theme, $params) {
  @include exports("forms.#{$theme}.colors") {

    @include mbsc-mobiscroll-page($theme, $params);
    @include mbsc-mobiscroll-input($theme, $params);
    @include mbsc-mobiscroll-slider($theme, $params);
    @include mbsc-mobiscroll-rating($theme, $params);
    @include mbsc-mobiscroll-notifications($theme, $params);

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    $accent: map-get($params, 'accent');
    // Generic
    $background-param: map-get($params, 'form-background');
    $text-param: map-get($params, 'form-text');
    $accent-param: map-get($params, 'form-accent');
    // Button
    $button-background-param: map-get($params, 'button-color');
    $button-text-param: map-get($params, 'button-text');
    // Form group
    $group-title-param: map-get($params, 'form-group-title-text');

    $colors: mbsc-mobiscroll-colors($params);
    $input-disabled: map-get($colors, 'input-disabled');
    // static colors
    $white-text: map-get($colors, 'white-text');
    $dark-text: map-get($colors, 'dark-text');
    $error: map-get($colors, 'error');

    // overwrite custom variables
    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
    $accent: if($accent-param, $accent-param, $accent);

    // derived colors
    $btn-light: '';
    $btn-disabled: '';
    $switch: '';
    $handle: '';
    $checkbox: '';
    $checked: desaturate(lighten($accent, 25%), 6%);
    // Light background
    @if (lightness($background) > 50%) {
      $btn-light: darken($background, 3%);
      $btn-disabled: darken($background, 10%);
      $switch: darken($background, 19%);
      $handle: darken($background, 8%);
      $checkbox: lighten($background, 3%);
    }
    // Dark background
    @else {
      $btn-light: $background;
      $btn-disabled: lighten($background, 8%);
      $switch: lighten($background, 14%);
      $handle: lighten($background, 7%);
      $checkbox: $mbsc-mobiscroll-dark-text;
    }

    $button-background: if($button-background-param, $button-background-param, $accent);
    $button-text: if($button-text-param, $button-text-param, if($button-background-param, get-contrast-color($button-background-param), $btn-light));
    $button-active: darken($button-background, 4%);
    $group-title: if($group-title-param, $group-title-param, $text);

    .mbsc-#{$theme} {

      &.mbsc-form {
        // background: $background;
        color: $text;
      }

      // when controls are outside of form
      &.mbsc-control-w {
        color: $text;
      }

      &.mbsc-form *::-moz-selection,
      &.mbsc-control-w *::-moz-selection,
      &.mbsc-form *::selection,
      &.mbsc-control-w *::selection {
        color: $white-text;
        background: $accent;
      }

      .mbsc-divider,
      .mbsc-form-group-title {
        // background: $background;
        color: $group-title;
      }

      .mbsc-err-msg,
      .mbsc-err .mbsc-label {
        color: $error;
      }

      /* Form grid */
      .mbsc-checkbox-box {
        background: $accent;
      }

      .mbsc-checkbox-box:after {
        border-bottom: .125em solid $checkbox;
        border-left: .125em solid $checkbox;
      }

      &.mbsc-checkbox input:disabled+.mbsc-checkbox-box {
        background: $input-disabled;
      }

      /* Radio */
      .mbsc-radio-box {
        border: .125em solid $accent;
      }

      .mbsc-radio-box:after {
        background: $accent;
      }

      &.mbsc-radio input:checked+.mbsc-radio-box {
        background: transparent;
      }

      &.mbsc-radio input:disabled+.mbsc-radio-box {
        border-color: $input-disabled;
      }

      &.mbsc-radio input:disabled+.mbsc-radio-box:after {
        background: $input-disabled;
      }

      /* Buttons */
      &.mbsc-btn {
        background: $button-background;
        color: $button-text;
      }

      &.mbsc-btn:disabled {
        background: $btn-disabled;
      }

      &.mbsc-no-touch.mbsc-btn-flat:not(:disabled):not(.mbsc-active):hover {
        background: rgba($background, .1);
      }

      &.mbsc-btn-flat {
        background: transparent;
        color: $button-background;
        border-color: transparent;
      }

      &.mbsc-btn-flat.mbsc-btn.mbsc-active {
        background: rgba($button-background, .3)
      }

      &.mbsc-btn-flat:disabled {
        color: $switch;
        background: transparent;
      }

      &.mbsc-btn-light.mbsc-btn {
        color: $dark-text;
      }

      /* Outline buttons */
      &.mbsc-btn-outline.mbsc-btn {
        border: 1px solid $button-background;
        color: $button-background;

        &.mbsc-active {
          background: $button-background;
          color: $background;
        }

        &.mbsc-btn-primary.mbsc-active,
        &.mbsc-btn-secondary.mbsc-active,
        &.mbsc-btn-success.mbsc-active,
        &.mbsc-btn-danger.mbsc-active,
        &.mbsc-btn-warning.mbsc-active,
        &.mbsc-btn-info.mbsc-active,
        &.mbsc-btn-dark.mbsc-active {
          color: $background;
        }
      }

      &.mbsc-btn.mbsc-btn-outline:disabled {
        color: $btn-disabled;
        border-color: $btn-disabled;
        background: transparent;
      }

      /* Switch */
      .mbsc-switch-track {
        background: #404040;
        left: 1em;
        right: auto;
      }

      .mbsc-switch-handle {
        background: $mbsc-mobiscroll-dark-text;
      }

      &.mbsc-switch input:checked+.mbsc-switch-track {
        background: $checked;
      }

      &.mbsc-switch input:checked+.mbsc-switch-track .mbsc-switch-handle {
        background: $accent;
      }

      &.mbsc-switch input:disabled+.mbsc-switch-track {
        background: $input-disabled;
      }

      &.mbsc-switch input:disabled+.mbsc-switch-track .mbsc-switch-handle {
        background: $handle;
      }

      /* Stepper and Segmented */
      .mbsc-segmented-content {
        border: .142858em solid $accent;
        color: $accent;
      }

      &.mbsc-stepper input {
        color: $text;
      }

      &.mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active .mbsc-segmented-content,
      &.mbsc-segmented .mbsc-segmented-item input:checked+.mbsc-segmented-content {
        background: $accent;
        color: $background;
      }

      &.mbsc-segmented .mbsc-segmented-item input.mbsc-active+.mbsc-segmented-content {
        background: rgba($accent, .3);
      }

      &.mbsc-segmented input:disabled~.mbsc-segmented-item .mbsc-segmented-content,
      &.mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-disabled .mbsc-segmented-content,
      &.mbsc-segmented .mbsc-segmented-item input:disabled+.mbsc-segmented-content {
        color: $input-disabled;
        border-color: $input-disabled;
      }

      .mbsc-stepper input:disabled {
        color: $input-disabled;
        -webkit-text-fill-color: $input-disabled;
      }

      &.mbsc-segmented input:disabled:checked+.mbsc-segmented-content {
        border-color: $btn-disabled;
        background: $btn-disabled;
        color: $btn-light;
      }

      &.mbsc-stepper .mbsc-active.mbsc-disabled .mbsc-segmented-content {
        background: transparent;
        color: $input-disabled;
      }

      &.mbsc-no-touch .mbsc-segmented-item:hover .mbsc-segmented-content {
        background: rgba($accent, .1)
      }
    }
  }
}










@include exports("forms") {
  /* Forms */

  [mbsc-form]:not(.mbsc-form) {
    // Prevent FOUC
    visibility: hidden;
  }

  .mbsc-form,
  .mbsc-control-w,
  .mbsc-btn,
  .mbsc-segmented {
    font-size: 16px;
    font-family: arial, verdana, sans-serif;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }

  .mbsc-form:before,
  .mbsc-form:after {
    content: '';
    display: table;
  }

  .mbsc-form-group {
    margin: 1.5em 0;
  }

  .mbsc-form-group-inset {
    margin: 2em 1.5em;
  }

  .mbsc-form-group,
  .mbsc-form-group-inset {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  /* No background inside widget */

  .mbsc-wdg .mbsc-w-p .mbsc-form {
    background: none;
  }

  /* Other mobiscroll components inline */

  .mbsc-input .mbsc-fr-inline .mbsc-fr-popup {
    display: block;
  }

  .mbsc-err-msg {
    display: block;
    font-size: .75em;
  }

  .mbsc-checkbox,
  .mbsc-switch,
  .mbsc-radio,
  .mbsc-stepper-cont {
    line-height: 1.25em;
  }

  .mbsc-checkbox,
  .mbsc-switch,
  .mbsc-btn,
  .mbsc-radio,
  .mbsc-segmented,
  .mbsc-stepper-cont {
    position: relative;
    display: block;
    margin: 0;
    z-index: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .mbsc-checkbox input,
  .mbsc-switch input,
  .mbsc-radio input,
  .mbsc-segmented input,
  .mbsc-btn {
    margin: 0;
    padding: 0;
    border: 0;
    border-radius: 0;
    outline: 0;
    font-family: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .mbsc-checkbox input,
  .mbsc-switch input,
  .mbsc-radio input,
  .mbsc-select select,
  .mbsc-segmented input {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    opacity: 0;
    margin: 0;
  }

  .mbsc-btn,
  .mbsc-checkbox-box,
  .mbsc-checkbox-box:after,
  .mbsc-radio-box,
  .mbsc-radio-box:after,
  .mbsc-switch-track,
  .mbsc-segmented,
  .mbsc-progress progress,
  .mbsc-stepper-cont {
    box-sizing: border-box;
  }

  .mbsc-segmented .mbsc-segmented-content,
  .mbsc-btn-flat .mbsc-btn-ic {
    box-sizing: content-box;
  }

  .mbsc-desc {
    display: block;
    font-size: .75em;
    opacity: .6;
  }

  label.mbsc-input,
  .mbsc-label {
    margin: 0;
    display: block;
    font-weight: normal;
  }

  .mbsc-control-w {
    // Override bootstrap defaults
    max-width: none;
    margin: 0;
    font-size: 1em;
    font-weight: normal;
  }

  .mbsc-control-w .mbsc-label {
    // v5 compatibility
    position: static;
    line-height: normal;
  }

  /* Textarea */

  .mbsc-input textarea {
    resize: none;
    overflow: hidden;
    line-height: 1.5em;
  }

  .mbsc-input .mbsc-textarea-scroll {
    overflow: auto;
  }

  /* Select */

  .mbsc-select select,
  .mbsc-select input {
    cursor: pointer;
  }

  .mbsc-select select option {
    color: initial;
  }

  .mbsc-select-ic {
    display: none;
  }

  /* Checkbox */

  .mbsc-checkbox {
    padding: .5em;
  }

  .mbsc-checkbox-box {
    position: absolute;
    top: 50%;
    display: block;
    width: 1.375em;
    height: 1.375em;
  }

  .mbsc-checkbox-box:after {
    content: '';
    position: absolute;
    display: block;
    opacity: 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .mbsc-checkbox input:checked + .mbsc-checkbox-box:after {
    opacity: 1;
  }

  /* Radio */

  .mbsc-radio {
    padding: .5em;
  }

  .mbsc-radio-box {
    position: absolute;
    top: 50%;
    display: block;
    width: 1.25em;
    height: 1.25em;
    border-radius: 1.25em;
  }

  .mbsc-radio-box:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: .625em;
    height: .625em;
    margin-top: -.3125em;
    margin-left: -.3125em;
    border-radius: .625em;
    opacity: 0;
  }

  .mbsc-radio input:checked + .mbsc-radio-box:after {
    opacity: 1;
  }

  /* Switch */

  .mbsc-switch {
    padding: .5em;
  }

  .mbsc-switch-track {
    position: absolute;
    top: 50%;
    display: block;
    width: 3.375em;
    height: 1.625em;
    transition: background-color .2s ease-in-out, border .2s ease-in-out;
    z-index: 4;
  }

  .mbsc-switch .mbsc-switch-track .mbsc-progress-track {
    height: 100%;
    background: none;
  }

  .mbsc-switch .mbsc-switch-track .mbsc-slider-handle-cont {
    top: 50%;
  }

  .mbsc-switch-handle {
    position: absolute;
    display: block;
  }

  .mbsc-switch-txt-off,
  .mbsc-switch-txt-on {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    font-size: .625em;
    text-align: center;
    line-height: 2em;
  }

  /* Segmented control & Stepper */

  .mbsc-segmented {
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  .mbsc-segmented .mbsc-segmented-item {
    margin: 0;
    display: table-cell;
    position: relative;
    vertical-align: top;
    text-align: center;
    font-size: 1em;
  }

  .mbsc-segmented-content {
    position: relative;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: .875em;
    font-weight: normal;
    z-index: 2;
  }

  // Angular + Bootstrap compatibility
  .mbsc-segmented-item label {
    display: block;
    margin: 0;
  }

  .mbsc-segmented input:disabled ~ .mbsc-segmented-item .mbsc-segmented-content,
  .mbsc-disabled .mbsc-segmented-content,
  .mbsc-segmented input:disabled + .mbsc-segmented-content {
    z-index: 0;
  }

  .mbsc-stepper.mbsc-segmented {
    position: absolute;
    display: block;
    width: auto;
    right: 1em;
    top: 50%;
    // v5 compatibility
    overflow: visible;
  }

  .mbsc-rtl .mbsc-stepper {
    right: auto;
    left: 1em;
  }

  .mbsc-stepper-cont .mbsc-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .mbsc-segmented-item:focus {
    outline: 0;
  }

  .mbsc-stepper input {
    position: absolute;
    left: 4.142857em;
    width: 4.142857em;
    height: 100%;
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
    box-shadow: none;
    font-size: .875em;
    text-align: center;
    opacity: 1;
    z-index: 4;
    background: transparent;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    appearance: none;
  }

  .mbsc-stepper input::-webkit-outer-spin-button,
  .mbsc-stepper input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .mbsc-ltr.mbsc-stepper-val-right .mbsc-stepper input {
    left: auto;
    right: 0;
  }

  .mbsc-rtl.mbsc-stepper-val-right .mbsc-stepper input {
    right: auto;
    left: 0;
  }

  .mbsc-ltr.mbsc-stepper-val-left .mbsc-stepper input {
    left: 0;
  }

  .mbsc-rtl.mbsc-stepper-val-left .mbsc-stepper input {
    right: 0;
  }

  .mbsc-stepper .mbsc-segmented-item {
    width: 3.625em;
  }

  .mbsc-stepper-cont.mbsc-stepper-val-left .mbsc-stepper .mbsc-segmented-item:nth-child(2) .mbsc-segmented-content,
  .mbsc-stepper-cont.mbsc-stepper-val-right .mbsc-stepper .mbsc-segmented-item:last-child .mbsc-segmented-content {
    /* Strong rule is needed to override disabled styling */
    border: 0;
    background: transparent;
  }

  .mbsc-control-w.mbsc-stepper-cont .mbsc-stepper {
    padding: 0;
  }

  .mbsc-segmented-item .mbsc-control,
  .mbsc-stepper .mbsc-segmented-content {
    cursor: pointer;
  }

  .mbsc-disabled .mbsc-segmented-content,
  .mbsc-segmented input:disabled,
  .mbsc-segmented input:disabled ~ .mbsc-segmented-item .mbsc-segmented-content {
    cursor: not-allowed;
  }

  /* Buttons */

  .mbsc-btn {
    position: relative;
    display: inline-block;
    overflow: hidden;
    vertical-align: middle;
    text-align: center;
    text-overflow: ellipsis;
    font-size: 1em;
    font-family: DegularText-Bold;
    cursor: pointer;
  }

  .mbsc-btn:disabled {
    cursor: not-allowed;

    * {
      /* Prevents click event firing for the Angular mbsc-button component, when disabled */
      pointer-events: none;
    }
  }

  .mbsc-btn:focus {
    outline: 0;
  }

  .mbsc-btn-ic {
    line-height: 1;
  }

  .mbsc-btn-group,
  .mbsc-btn-group-block,
  .mbsc-btn-group-justified {
    // Prevent margin collision
    border: 1px solid transparent;
  }

  .mbsc-btn-group-block .mbsc-btn,
  .mbsc-btn-block {
    display: block;
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mbsc-btn-group-justified,
  .mbsc-btn-group-justified mbsc-button {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .mbsc-btn-group-justified .mbsc-btn,
  .mbsc-btn-group-justified mbsc-button {
    -webkit-box-flex: 1;
    -webkit-flex: 1 auto;
    -ms-flex: 1 auto;
    flex: 1 auto;
  }

  input:disabled + span .mbsc-slider-handle-cont {
    cursor: not-allowed;
  }

  /* Form in grid */

  .mbsc-form .mbsc-form-grid .mbsc-form-group-title {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
}


@include exports("forms.mobiscroll") {

  .mbsc-mobiscroll {

    .mbsc-divider,
    .mbsc-form-group-title {
      padding: 0 1em; //  padding: .5em 1em;
    }

    .mbsc-err-msg {
      position: absolute;
      padding-top: .333334em;
      font-size: .75em;
    }

    /* Form grid */

    .mbsc-form-grid {
      margin-top: .75em;
      margin-bottom: .75em;
    }

    .mbsc-form-grid .mbsc-input-box,
    .mbsc-form-grid .mbsc-input-outline {
      margin: .75em 1em;
    }

    /* Checkbox, switch, radio */
    &.mbsc-checkbox .mbsc-err-msg,
    &.mbsc-switch .mbsc-err-msg,
    &.mbsc-radio .mbsc-err-msg {
      padding-top: 0;
    }

    /* Checkbox */
    &.mbsc-ltr.mbsc-checkbox {
      padding: 1em 3.125em 1em 1em;
    }

    &.mbsc-rtl.mbsc-checkbox {
      padding: 1em 1em 1em 3.125em;
    }

    .mbsc-checkbox-box {
      margin-top: -.5625em;
      width: 1.125em;
      height: 1.125em;
      right: 1em;
    }

    &.mbsc-rtl .mbsc-checkbox-box {
      right: auto;
      left: 1em;
    }

    .mbsc-checkbox-box:after {
      top: 0.25em;
      left: .185em;
      width: .8125em;
      height: .4375em;
    }

    /* Checkbox color presets */
    &.mbsc-checkbox-primary .mbsc-checkbox-box {
      background: $mbsc-mobiscroll-primary;
    }

    &.mbsc-checkbox-secondary .mbsc-checkbox-box {
      background: $mbsc-mobiscroll-secondary;
    }

    &.mbsc-checkbox-success .mbsc-checkbox-box {
      background: $mbsc-mobiscroll-success;
    }

    &.mbsc-checkbox-danger .mbsc-checkbox-box {
      background: $mbsc-mobiscroll-danger;
    }

    &.mbsc-checkbox-warning .mbsc-checkbox-box {
      background: $mbsc-mobiscroll-warning;
    }

    &.mbsc-checkbox-info .mbsc-checkbox-box {
      background: $mbsc-mobiscroll-info;
    }

    /* Radio */
    &.mbsc-radio {
      padding: 1em 3.125em 1em 1em;
    }

    &.mbsc-rtl.mbsc-radio {
      padding: 1em 1em 1em 3.125em;
    }

    .mbsc-radio-box {
      width: 1.125em;
      height: 1.125em;
      margin-top: -.5625em;
      background: transparent;

    }

    &.mbsc-rtl .mbsc-radio-box {
      right: auto;
      left: 1em;
    }

    .mbsc-radio-box:after {
      width: .5em;
      height: .5em;
      margin-top: -.25em;
      margin-left: -.25em;
      border-radius: .625em;
    }

    &.mbsc-radio input:checked + .mbsc-radio-box {
      background: transparent;
    }

    /* Radio color presets */
    &.mbsc-radio-primary {
      .mbsc-radio-box {
        border-color: $mbsc-mobiscroll-primary;
      }

      .mbsc-radio-box:after {
        background: $mbsc-mobiscroll-primary;
      }
    }

    &.mbsc-radio-secondary {
      .mbsc-radio-box {
        border-color: $mbsc-mobiscroll-secondary;
      }

      .mbsc-radio-box:after {
        background: $mbsc-mobiscroll-secondary;
      }
    }

    &.mbsc-radio-success {
      .mbsc-radio-box {
        border-color: $mbsc-mobiscroll-success;
      }

      .mbsc-radio-box:after {
        background: $mbsc-mobiscroll-success;
      }
    }

    &.mbsc-radio-danger {
      .mbsc-radio-box {
        border-color: $mbsc-mobiscroll-danger;
      }

      .mbsc-radio-box:after {
        background: $mbsc-mobiscroll-danger;
      }
    }

    &.mbsc-radio-warning {
      .mbsc-radio-box {
        border-color: $mbsc-mobiscroll-warning;
      }

      .mbsc-radio-box:after {
        background: $mbsc-mobiscroll-warning;
      }
    }

    &.mbsc-radio-info {
      .mbsc-radio-box {
        border-color: $mbsc-mobiscroll-info;
      }

      .mbsc-radio-box:after {
        background: $mbsc-mobiscroll-info;
      }
    }

    /* Checkbox, Switch, Radio */
    &.mbsc-checkbox input:disabled ~ .mbsc-label,
    &.mbsc-checkbox input:disabled ~ .mbsc-desc,
    &.mbsc-radio input:disabled ~ .mbsc-label,
    &.mbsc-radio input:disabled ~ .mbsc-desc,
    &.mbsc-switch input:disabled ~ .mbsc-label,
    &.mbsc-switch input:disabled ~ .mbsc-desc {
      opacity: .4;
    }

    .mbsc-checkbox-box:after,
    .mbsc-radio-box:after {
      opacity: 1;
      -webkit-transform: scale(0) rotate(-45deg);
      transform: scale(0) rotate(-45deg);
      transition: transform .1s ease-out;
    }

    &.mbsc-checkbox input:checked + .mbsc-checkbox-box:after,
    &.mbsc-radio input:checked + .mbsc-radio-box:after {
      opacity: 1;
      -webkit-transform: scale(1) rotate(-45deg);
      transform: scale(1) rotate(-45deg);
    }

    /* Buttons */
    &.mbsc-btn {
      margin: .5em;
      padding: .6875em;
      font-size: 1em;
      text-transform: uppercase;
    }

    .mbsc-btn-ic {
      padding-right: .6875em;
    }

    .mbsc-btn-icon-only .mbsc-btn-ic {
      padding: 0 .5em;
    }

    &.mbsc-no-touch.mbsc-btn:not(:disabled):not(.mbsc-active):hover {
      opacity: .8;
    }

    &.mbsc-btn.mbsc-active {
      opacity: .6;
    }

    &.mbsc-no-touch.mbsc-btn-flat:not(:disabled):not(.mbsc-active):hover {
      opacity: 1;
    }

    &.mbsc-btn-flat {
      background: transparent;
      border-color: transparent;
    }

    &.mbsc-btn-flat.mbsc-btn.mbsc-active {
      opacity: 1;
    }

    &.mbsc-btn-flat:disabled {
      background: transparent;
    }

    /* Button color presets */
    &.mbsc-btn-primary.mbsc-btn {
      background: $mbsc-mobiscroll-primary;

      &.mbsc-btn-flat {
        color: $mbsc-mobiscroll-primary;

        &:not(:disabled):not(.mbsc-active):hover {
          background: rgba($mbsc-mobiscroll-primary, .2);
        }

        &.mbsc-active {
          background: rgba($mbsc-mobiscroll-primary, .3);
        }
      }
    }

    &.mbsc-btn-secondary.mbsc-btn {
      background: $mbsc-mobiscroll-secondary;

      &.mbsc-btn-flat {
        color: $mbsc-mobiscroll-secondary;

        &:not(:disabled):not(.mbsc-active):hover {
          background: rgba($mbsc-mobiscroll-secondary, .2);
        }

        &.mbsc-active {
          background: rgba($mbsc-mobiscroll-secondary, .3);
        }
      }
    }

    &.mbsc-btn-success.mbsc-btn {
      background: $mbsc-mobiscroll-success;

      &.mbsc-btn-flat {
        color: $mbsc-mobiscroll-success;

        &:not(:disabled):not(.mbsc-active):hover {
          background: rgba($mbsc-mobiscroll-success, .2);
        }

        &.mbsc-active {
          background: rgba($mbsc-mobiscroll-success, .3);
        }
      }
    }

    &.mbsc-btn-danger.mbsc-btn {
      background: $mbsc-mobiscroll-danger;

      &.mbsc-btn-flat {
        color: $mbsc-mobiscroll-danger;

        &:not(:disabled):not(.mbsc-active):hover {
          background: rgba($mbsc-mobiscroll-danger, .2);
        }

        &.mbsc-active {
          background: rgba($mbsc-mobiscroll-danger, .3);
        }
      }
    }

    &.mbsc-btn-warning.mbsc-btn {
      background: $mbsc-mobiscroll-warning;

      &.mbsc-btn-flat {
        color: $mbsc-mobiscroll-warning;

        &:not(:disabled):not(.mbsc-active):hover {
          background: rgba($mbsc-mobiscroll-warning, .2);
        }

        &.mbsc-active {
          background: rgba($mbsc-mobiscroll-warning, .3);
        }
      }
    }

    &.mbsc-btn-info.mbsc-btn {
      background: $mbsc-mobiscroll-info;

      &.mbsc-btn-flat {
        color: $mbsc-mobiscroll-info;

        &:not(:disabled):not(.mbsc-active):hover {
          background: rgba($mbsc-mobiscroll-info, .2);
        }

        &.mbsc-active {
          background: rgba($mbsc-mobiscroll-info, .3);
        }
      }
    }

    &.mbsc-btn-light.mbsc-btn {
      background: $mbsc-mobiscroll-light;

      &.mbsc-btn-flat {
        color: darken($mbsc-mobiscroll-light, 25%);

        &:not(:disabled):not(.mbsc-active):hover {
          color: darken($mbsc-mobiscroll-light, 25%);
          background: rgba(darken($mbsc-mobiscroll-light, 25%), .2);
        }

        &.mbsc-active {
          background: rgba(darken($mbsc-mobiscroll-light, 25%), .3);
        }
      }
    }

    &.mbsc-btn-dark.mbsc-btn {
      background: $mbsc-mobiscroll-dark;

      &.mbsc-btn-flat {
        color: $mbsc-mobiscroll-dark;

        &:not(:disabled):not(.mbsc-active):hover {
          background: rgba($mbsc-mobiscroll-dark, .2);
        }

        &.mbsc-active {
          background: rgba($mbsc-mobiscroll-dark, .3);
        }
      }
    }

    &.mbsc-btn-flat.mbsc-btn,
    &.mbsc-btn-outline.mbsc-btn {
      background: transparent;
    }

    .mbsc-btn-group,
    .mbsc-btn-group-justified {
      margin: .5em;
    }

    .mbsc-btn-group-block {
      margin: .5em 1em;
    }

    /* Outline buttons */
    &.mbsc-btn-outline.mbsc-btn.mbsc-active {
      opacity: 1;
    }

    /* Outline buttons */
    &.mbsc-btn-outline.mbsc-btn {
      &.mbsc-btn-primary {
        border-color: $mbsc-mobiscroll-primary;
        color: $mbsc-mobiscroll-primary;

        &.mbsc-active {
          background: $mbsc-mobiscroll-primary;
        }
      }

      &.mbsc-btn-secondary {
        border-color: $mbsc-mobiscroll-secondary;
        color: $mbsc-mobiscroll-secondary;

        &.mbsc-active {
          background: $mbsc-mobiscroll-secondary;
        }
      }

      &.mbsc-btn-success {
        border-color: $mbsc-mobiscroll-success;
        color: $mbsc-mobiscroll-success;

        &.mbsc-active {
          background: $mbsc-mobiscroll-success;
        }
      }

      &.mbsc-btn-danger {
        border-color: $mbsc-mobiscroll-danger;
        color: $mbsc-mobiscroll-danger;

        &.mbsc-active {
          background: $mbsc-mobiscroll-danger;
        }
      }

      &.mbsc-btn-warning {
        border-color: $mbsc-mobiscroll-warning;
        color: $mbsc-mobiscroll-warning;

        &.mbsc-active {
          background: $mbsc-mobiscroll-warning;
        }
      }

      &.mbsc-btn-info {
        border-color: $mbsc-mobiscroll-info;
        color: $mbsc-mobiscroll-info;

        &.mbsc-active {
          background: $mbsc-mobiscroll-info;
        }
      }

      &.mbsc-btn-light {
        border-color: darken($mbsc-mobiscroll-light, 25%);
        color: darken($mbsc-mobiscroll-light, 25%);

        &.mbsc-active {
          background: darken($mbsc-mobiscroll-light, 25%);
          color: $mbsc-mobiscroll-light;
        }
      }

      &.mbsc-btn-dark {
        border-color: $mbsc-mobiscroll-dark;
        color: $mbsc-mobiscroll-dark;

        &.mbsc-active {
          background: $mbsc-mobiscroll-dark;
        }
      }
    }

    /* Switch */
    &.mbsc-ltr.mbsc-switch {
      padding: 1em 1em 1em 3.2em;
    }

    &.mbsc-rtl.mbsc-switch {
      padding: 1em 1em 1em 4.375em;
    }

    .mbsc-switch-track {
      right: 1em;
      width: 1.6em;
      height: .875em;
      padding: 0.4em;
      margin-top: -.4375em;
      border-radius: 1.25em;
    }

    &.mbsc-rtl .mbsc-switch-track {
      right: auto;
      left: 1em;
    }

    .mbsc-switch-handle {
      // top: .25em;
      // left: .25em;
      // margin: 0;
      // width: 1.5em;
      // height: 1.5em;
      // border-radius: 1.25em;
      top: 0.58em;
      left: .58em;
      margin: 0;
      width: 0.78em;
      height: 0.78em;
      border-radius: 1.25em;
    }

    &.mbsc-rtl .mbsc-switch-handle {
      right: .25em;
    }

    .mbsc-active .mbsc-switch-handle {
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    &.mbsc-switch input:disabled + .mbsc-switch-track {
      opacity: .7;
    }

    /* Switch color presets */
    &.mbsc-switch.mbsc-switch-primary input:checked {
      + .mbsc-switch-track {
        background: lighten($mbsc-mobiscroll-primary, 20%);
      }

      + .mbsc-switch-track .mbsc-switch-handle {
        background: $mbsc-mobiscroll-primary;
      }
    }

    &.mbsc-switch.mbsc-switch-secondary input:checked {
      + .mbsc-switch-track {
        background: lighten($mbsc-mobiscroll-secondary, 20%);
      }

      + .mbsc-switch-track .mbsc-switch-handle {
        background: $mbsc-mobiscroll-secondary;
      }
    }

    &.mbsc-switch.mbsc-switch-success input:checked {
      + .mbsc-switch-track {
        background: lighten($mbsc-mobiscroll-success, 20%);
      }

      + .mbsc-switch-track .mbsc-switch-handle {
        background: $mbsc-mobiscroll-success;
      }
    }

    &.mbsc-switch.mbsc-switch-danger input:checked {
      + .mbsc-switch-track {
        background: lighten($mbsc-mobiscroll-danger, 20%);
      }

      + .mbsc-switch-track .mbsc-switch-handle {
        background: $mbsc-mobiscroll-danger;
      }
    }

    &.mbsc-switch.mbsc-switch-warning input:checked {
      + .mbsc-switch-track {
        background: lighten($mbsc-mobiscroll-warning, 20%);
      }

      + .mbsc-switch-track .mbsc-switch-handle {
        background: $mbsc-mobiscroll-warning;
      }
    }

    &.mbsc-switch.mbsc-switch-info input:checked {
      + .mbsc-switch-track {
        background: lighten($mbsc-mobiscroll-info, 20%);
      }

      + .mbsc-switch-track .mbsc-switch-handle {
        background: $mbsc-mobiscroll-info;
      }
    }

    /* Stepper and Segmented */
    &.mbsc-segmented {
      padding: .5em 1em;
    }

    .mbsc-segmented-content {
      height: 2.28571428em;
      margin: 0 -.071428em;
      line-height: 2.28575em;
      padding: 0 .285714em;
      text-transform: uppercase;
    }

    &.mbsc-ltr.mbsc-stepper-cont {
      padding: 1.75em 12.875em 1.75em 1em;
    }

    &.mbsc-rtl.mbsc-stepper-cont {
      padding: 1.75em 1em 1.75em 12.875em;
    }

    .mbsc-stepper {
      margin-top: -1.125em;
    }

    &.mbsc-segmented input:disabled ~ .mbsc-segmented-item .mbsc-segmented-content,
    &.mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-disabled .mbsc-segmented-content,
    &.mbsc-segmented .mbsc-segmented-item input:disabled + .mbsc-segmented-content {
      background: transparent;
    }

    /* Segmented color presets */
    .mbsc-segmented-primary {
      .mbsc-segmented-content {
        border-color: $mbsc-mobiscroll-primary;
        color: $mbsc-mobiscroll-primary;
      }

      &.mbsc-segmented-item input.mbsc-control:checked + .mbsc-segmented-content {
        background: $mbsc-mobiscroll-primary;
      }

      &.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
        background: rgba($mbsc-mobiscroll-primary, .35)
      }
    }

    .mbsc-segmented-secondary {
      .mbsc-segmented-content {
        border-color: $mbsc-mobiscroll-secondary;
        color: $mbsc-mobiscroll-secondary;
      }

      &.mbsc-segmented-item input.mbsc-control:checked + .mbsc-segmented-content {
        background: $mbsc-mobiscroll-secondary;
      }

      &.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
        background: rgba($mbsc-mobiscroll-secondary, .35)
      }
    }

    .mbsc-segmented-success {
      .mbsc-segmented-content {
        border-color: $mbsc-mobiscroll-success;
        color: $mbsc-mobiscroll-success;
      }

      &.mbsc-segmented-item input.mbsc-control:checked + .mbsc-segmented-content {
        background: $mbsc-mobiscroll-success;
      }

      &.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
        background: rgba($mbsc-mobiscroll-success, .35)
      }
    }

    .mbsc-segmented-danger {
      .mbsc-segmented-content {
        border-color: $mbsc-mobiscroll-danger;
        color: $mbsc-mobiscroll-danger;
      }

      &.mbsc-segmented-item input.mbsc-control:checked + .mbsc-segmented-content {
        background: $mbsc-mobiscroll-danger;
      }

      &.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
        background: rgba($mbsc-mobiscroll-danger, .35)
      }
    }

    .mbsc-segmented-warning {
      .mbsc-segmented-content {
        border-color: $mbsc-mobiscroll-warning;
        color: $mbsc-mobiscroll-warning;
      }

      &.mbsc-segmented-item input.mbsc-control:checked + .mbsc-segmented-content {
        background: $mbsc-mobiscroll-warning;
      }

      &.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
        background: rgba($mbsc-mobiscroll-warning, .35)
      }
    }

    .mbsc-segmented-info {
      .mbsc-segmented-content {
        border-color: $mbsc-mobiscroll-info;
        color: $mbsc-mobiscroll-info;
      }

      &.mbsc-segmented-item input.mbsc-control:checked + .mbsc-segmented-content {
        background: $mbsc-mobiscroll-info;
      }

      &.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
        background: rgba($mbsc-mobiscroll-info, .35)
      }
    }

    /* Stepper color presets */
    &.mbsc-stepper-primary {
      .mbsc-segmented-content {
        border-color: $mbsc-mobiscroll-primary;
        color: $mbsc-mobiscroll-primary;
      }

      .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active .mbsc-segmented-content {
        background: $mbsc-mobiscroll-primary;
      }
    }

    &.mbsc-stepper-secondary {
      .mbsc-segmented-content {
        border-color: $mbsc-mobiscroll-secondary;
        color: $mbsc-mobiscroll-secondary;
      }

      .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active .mbsc-segmented-content {
        background: $mbsc-mobiscroll-secondary;
      }
    }

    &.mbsc-stepper-success {
      .mbsc-segmented-content {
        border-color: $mbsc-mobiscroll-success;
        color: $mbsc-mobiscroll-success;
      }

      .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active .mbsc-segmented-content {
        background: $mbsc-mobiscroll-success;
      }
    }

    &.mbsc-stepper-danger {
      .mbsc-segmented-content {
        border-color: $mbsc-mobiscroll-danger;
        color: $mbsc-mobiscroll-danger;
      }

      .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active .mbsc-segmented-content {
        background: $mbsc-mobiscroll-danger;
      }
    }

    &.mbsc-stepper-warning {
      .mbsc-segmented-content {
        border-color: $mbsc-mobiscroll-warning;
        color: $mbsc-mobiscroll-warning;
      }

      .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active .mbsc-segmented-content {
        background: $mbsc-mobiscroll-warning;
      }
    }

    &.mbsc-stepper-info {
      .mbsc-segmented-content {
        border-color: $mbsc-mobiscroll-info;
        color: $mbsc-mobiscroll-info;
      }

      .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active .mbsc-segmented-content {
        background: $mbsc-mobiscroll-info;
      }
    }

    &.mbsc-no-touch {

      /* Segmented hover color styles */
      .mbsc-segmented-primary {
        &.mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-mobiscroll-primary, .35)
        }
      }

      .mbsc-segmented-secondary {
        &.mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-mobiscroll-secondary, .35)
        }
      }

      .mbsc-segmented-success {
        &.mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-mobiscroll-success, .35)
        }
      }

      .mbsc-segmented-danger {
        &.mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-mobiscroll-danger, .35)
        }
      }

      .mbsc-segmented-warning {
        &.mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-mobiscroll-warning, .35)
        }
      }

      .mbsc-segmented-info {
        &.mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-mobiscroll-info, .35)
        }
      }

      /* Stepper hover color styles */
      &.mbsc-stepper-primary {
        .mbsc-stepper .mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-mobiscroll-primary, .35)
        }
      }

      &.mbsc-stepper-secondary {
        .mbsc-stepper .mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-mobiscroll-secondary, .35)
        }
      }

      &.mbsc-stepper-success {
        .mbsc-stepper .mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-mobiscroll-success, .35)
        }
      }

      &.mbsc-stepper-danger {
        .mbsc-stepper .mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-mobiscroll-danger, .35)
        }
      }

      &.mbsc-stepper-warning {
        .mbsc-stepper .mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-mobiscroll-warning, .35)
        }
      }

      &.mbsc-stepper-info {
        .mbsc-stepper .mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-mobiscroll-info, .35)
        }
      }
    }
  }

  @include mbsc-mobiscroll-forms(mobiscroll, $mbsc-mobiscroll-colors);
}



// eventcalendar component








@mixin mbsc-mobiscroll-eventcalendar($theme, $params) {
  @include exports("eventcalendar.#{$theme}.colors") {

    @include mbsc-mobiscroll-calbase($theme, $params);
    @include mbsc-mobiscroll-listview-base($theme, $params);
    @include mbsc-mobiscroll-common($theme, $params);

    $background: map-get($params, 'background');
    $accent: map-get($params, 'accent');
    $text: map-get($params, 'text');

    $background-param: map-get($params, 'frame-background');
    $text-param: map-get($params, 'frame-text');
    $accent-param: map-get($params, 'frame-accent');

    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
    $accent: if($accent-param, $accent-param, $accent);

    $event-border: lighten($text, 25%);
    $table-border: '';
    // Light background
    @if (lightness($background) > 50%) {
      $table-border: darken($background, 10%);
    }
    // Dark background
    @else {
      $table-border: lighten($background, 8%);
    }

    .mbsc-#{$theme} {
      &.mbsc-cal-events-popup {
        .mbsc-fr-w {
          border: .375em solid $event-border;
        }

        .mbsc-fr-arr {
          background: $event-border;
        }
      }

      .mbsc-cal-event-color {
        background: $accent;
      }

      .mbsc-cal-event-time,
      .mbsc-cal-event-dur {
        color: $accent;
      }

      /* Event listing */
      .mbsc-event-list .mbsc-lv-gr-title {
        background: $background;
        color: $accent;
      }

      .mbsc-event-color {
        background: rgba($text, .3);
      }

      /* Desktop grid mode */
      &.mbsc-ev-cal.mbsc-fr-md {
        .mbsc-cal-table {
          border-top: 1px solid $table-border;
        }

        .mbsc-cal-cell {
          border-left: 1px solid $table-border;
          border-bottom: 1px solid $table-border;
        }
      }
    }
  }
}





@include exports("eventcalendar") {
  .mbsc-cal-events-popup {
    .mbsc-fr-persp {
      pointer-events: none;
    }

    .mbsc-fr-popup {
      pointer-events: auto;
    }
  }

  .mbsc-cal-events {
    box-sizing: content-box;
    font-size: .857143em;
    max-width: 100%;
  }

  .mbsc-cal-events-i {
    max-height: 24em;
  }

  .mbsc-cal-events-sc {
    position: relative;
    -webkit-backface-visibility: hidden;
  }

  .mbsc-cal-event {
    line-height: 1.666667em;
    list-style: none;
    position: relative;
    white-space: nowrap;
    cursor: pointer;
  }

  .mbsc-ltr .mbsc-cal-events .mbsc-cal-event {
    padding-right: 5em;
    text-align: left;
  }

  .mbsc-rtl .mbsc-cal-events .mbsc-cal-event {
    padding-left: 5em;
    text-align: right;
  }

  .mbsc-cal-event-color {
    position: absolute;
    top: 0;
    left: 0;
    width: .5em;
    height: 100%;
  }

  .mbsc-cal-event-text {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mbsc-cal-event-time {
    display: inline-block;
    width: 5em;
    white-space: normal;
    line-height: 1.166667em;
    vertical-align: middle;
  }

  .mbsc-cal-event-dur {
    position: absolute;
    top: 50%;
    height: 2em;
    margin-top: -1em;
    line-height: 2em;
  }

  .mbsc-ltr .mbsc-cal-event-dur {
    right: 1em;
  }

  .mbsc-rtl .mbsc-cal-event-dur {
    left: 1em;
  }

  /* Event list styling */

  .mbsc-event-list {
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .mbsc-event-list-h {
    display: none;
  }

  .mbsc-event-list .mbsc-lv .mbsc-lv-gr-title {
    padding: .5em 1em;
    line-height: 1.5em;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    margin: 0;
  }

  .mbsc-event-list .mbsc-lv .mbsc-lv-item {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: .875em;
    padding: .714286em 1em;
    cursor: pointer;
  }

  /* Empty view */

  .mbsc-event-list .mbsc-lv .mbsc-lv-gr-title.mbsc-event-empty {
    text-transform: none;
    font-size: 1em;
  }

  .mbsc-ltr .mbsc-event-list {
    text-align: left;
  }

  .mbsc-rtl .mbsc-event-list {
    text-align: right;
  }

  .mbsc-event-color,
  .mbsc-event-time {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .mbsc-event-time {
    width: 6em;
    font-size: .714286em;
    line-height: 1.6;
    min-height: 3.2em;
    opacity: .7;
  }

  .mbsc-ltr .mbsc-event-time {
    text-align: right;
  }

  .mbsc-rtl .mbsc-event-time {
    text-align: left;
  }

  .mbsc-event-color {
    width: 1.5px;
    margin: -0.285714em 0.714286em;
  }

  .mbsc-event-txt {
    line-height: 1.142857;
  }

  /* Desktop grid mode */

  .mbsc-ev-cal.mbsc-fr-md {
    .mbsc-cal-hdr {
      padding: .5em;
    }

    .mbsc-cal-scroll-c {
      margin-left: -1px;
    }

    .mbsc-cal-day-i {
      min-height: 4em;
    }

    .mbsc-cal-marks {
      top: 100%;
    }

    .mbsc-cal-txt,
    .mbsc-cal-txt-ph,
    .mbsc-cal-txt-more {
      font-size: .75em;
      height: 1.5em;
      line-height: 1.5em;
      padding: 0 .333334em;
      margin: 0 .333334em .166667em .333334em;
    }
  }

  /* Scrollbar */

  .mbsc-cal-events-i:hover .mbsc-sc-bar-c {
    opacity: 1;
  }
}


@include exports("eventcalendar.mobiscroll") {
  .mbsc-mobiscroll {
    &.mbsc-cal-events-popup {
      .mbsc-fr-w {
        border-radius: 0;
      }

      .mbsc-fr-bubble-top .mbsc-fr-arr {
        border-bottom-color: transparent;
      }
    }

    .mbsc-cal-events {
      width: 32em;
    }

    .mbsc-cal-event {
      padding: 1em 1em 1em 1.25em;
    }

    /* Desktop grid mode */
    &.mbsc-ev-cal.mbsc-fr-md {
      .mbsc-cal-c {
        padding: 0;
      }

      .mbsc-cal-days > div {
        border: 0;
        padding: 0 .785714em;
        font-size: .875em;
      }

      .mbsc-cal-picker {
        padding-top: 1.875em;
        padding-bottom: 0;
      }

      .mbsc-cal-day-date {
        margin: .230769em;
        margin-bottom: .461538em;
      }

      .mbsc-cal-has-labels .mbsc-cal-day-i {
        min-height: 5.125em;
      }

      .mbsc-cal-marks {
        margin: -.5em 1em 0 1em;
      }

      .mbsc-ltr {

        .mbsc-cal-days,
        .mbsc-cal-day {
          text-align: left;
        }
      }

      .mbsc-rtl {

        .mbsc-cal-days,
        .mbsc-cal-day {
          text-align: right;
        }
      }
    }
  }

  @include mbsc-mobiscroll-eventcalendar(mobiscroll, $mbsc-mobiscroll-colors);
}



// measurement component






@mixin mbsc-mobiscroll-measurement($theme, $params) {
  @include exports("measurement.#{$theme}.colors") {
    @include mbsc-mobiscroll-scroller($theme, $params);
  }
}




@include exports("measurement") {
  .mbsc-msr .mbsc-sc-whl-gr {
    direction: ltr;
  }

  .mbsc-msr .mbsc-rtl .mbsc-sc-whl-w {
    -webkit-box-ordinal-group: 2;
    -moz-box-ordinal-group: 2;
    -ms-flex-order: 2;
    order: 2;
  }

  .mbsc-msr .mbsc-rtl .mbsc-msr-whl-unit {
    -webkit-box-ordinal-group: 1;
    -moz-box-ordinal-group: 1;
    -ms-flex-order: 1;
    order: 1;
  }
}


@include exports("measurement.mobiscroll") {
  @include mbsc-mobiscroll-measurement(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}



// navigation component






@mixin mbsc-mobiscroll-scrollview($theme, $params) {
  @include exports("scrollview.#{$theme}.colors") {}
}




@include exports("scrollview") {
  .mbsc-scv-c {
    overflow: hidden;
    position: relative;
    font-size: 16px;
    font-family: arial, verdana, sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -ms-touch-action: pan-y;
    user-select: none;
    touch-action: pan-y;
    /*height: 100%;*/
  }

  .mbsc-scv,
  .mbsc-scv-sc {
    position: relative;
  }

  .mbsc-rtl {
    direction: rtl;
  }


  /* Flex */

  .mbsc-scv,
  .mbsc-scv-sc,
  .mbsc-scv-item {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    height: 100%;
  }

  // Needed by IE and iOS9
  .mbsc-scv,
  .mbsc-scv-item {
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .mbsc-scv.mbsc-comp {
    padding: 0;
    margin: 0 auto;
    list-style: none;
    line-height: normal;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
  }

  .mbsc-scv-item {
    height: auto;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    box-sizing: border-box;
  }

  /* Liquid layout */

  .mbsc-scv-liq,
  .mbsc-scv-liq .mbsc-scv-item {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
  }
}


@include exports("scrollview.mobiscroll") {
  @include mbsc-mobiscroll-scrollview(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}





// Theme specific variables - inherited from global variables

// background
$mbsc-mobiscroll-nav-background: $mbsc-nav-background-light !default;
$mbsc-mobiscroll-dark-nav-background: $mbsc-nav-background-dark !default;
// text
$mbsc-mobiscroll-nav-text: $mbsc-nav-text-light !default;
$mbsc-mobiscroll-dark-nav-text: $mbsc-nav-text-dark !default;
// accent
$mbsc-mobiscroll-nav-accent: $mbsc-nav-accent-light !default;
$mbsc-mobiscroll-dark-nav-accent: $mbsc-nav-accent-dark !default;

// add variables to the light and dark color maps
$mbsc-mobiscroll-colors: map-merge($mbsc-mobiscroll-colors, (
  'nav-background': $mbsc-mobiscroll-nav-background,
  'nav-text': $mbsc-mobiscroll-nav-text,
  'nav-accent': $mbsc-mobiscroll-nav-accent,
));
$mbsc-mobiscroll-dark-colors: map-merge($mbsc-mobiscroll-dark-colors, (
  'nav-background': $mbsc-mobiscroll-dark-nav-background,
  'nav-text': $mbsc-mobiscroll-dark-nav-text,
  'nav-accent': $mbsc-mobiscroll-dark-nav-accent,
));

@mixin mbsc-mobiscroll-navigation-base($theme, $params) {
  @include exports("navigation-base.#{$theme}.colors") {

    @include mbsc-mobiscroll-scrollview($theme, $params);

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    $accent: map-get($params, 'accent');

    $nav-background: map-get($params, 'nav-background');
    $nav-text: map-get($params, 'nav-text');
    $nav-accent: map-get($params, 'nav-accent');

    $background: if($nav-background, $nav-background, $background);
    $text: if($nav-text, $nav-text, if($nav-background, get-contrast-color($nav-background), $text));
    $accent: if($nav-accent, $nav-accent, $accent);

    $border: '';
    @if (lightness($background) > 50%) {
      $border: darken($background, 17%);
    }
    @else {
      $border: lighten($background, 17%);
    }

    .mbsc-#{$theme} {
      &.mbsc-ms-c {
        background: $background;
      }

      .mbsc-ms .mbsc-ms-item {
        color: $text;
      }

      &.mbsc-ms-c .mbsc-scv-sc .mbsc-ms .mbsc-ms-item.mbsc-active {
        //background: rgba($accent, .3);
      }

      &.mbsc-no-touch .mbsc-ms-item:not(.mbsc-disabled):hover {
        background: rgba($accent, .1);
      }

      &.mbsc-ms-b {
        .mbsc-ms-item-sel {
          border-bottom-color: $accent;
        }

        &.mbsc-ms-top {
          border-bottom: 1px solid $accent;
        }

        &.mbsc-ms-bottom {
          border-top: 1px solid $accent;

          .mbsc-ms-item-sel {
            border-top-color: $accent;
            border-bottom-color: transparent;
          }
        }
      }

      &.mbsc-ms-a {
        &.mbsc-ms-top {
          border-bottom: 1px solid $border;
        }

        &.mbsc-ms-bottom {
          border-top: 1px solid $border;
        }

        .mbsc-ms-item-sel {
          // color: $accent;
        }
      }
    }
  }
}





@include exports("navigation-base") {
  .mbsc-ms-c {
    text-align: center;
  }

  .mbsc-ms-top.mbsc-ms-c,
  .mbsc-ms-bottom.mbsc-ms-c {
    z-index: 9999;
    position: fixed;
    left: 0;
    right: 0;
    height: auto;
  }

  .mbsc-ms-top.mbsc-ms-c .mbsc-ms,
  .mbsc-ms-bottom.mbsc-ms-c .mbsc-ms {
    padding-left: constant(safe-area-inset-left);
    padding-left: env(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    padding-right: env(safe-area-inset-right);
  }

  .mbsc-ms-top.mbsc-ms-c {
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
  }

  .mbsc-ms-bottom.mbsc-ms-c {
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }

  .mbsc-ms-top.mbsc-scv-ctx,
  .mbsc-ms-bottom.mbsc-scv-ctx {
    position: absolute;
  }

  .mbsc-ms-top {
    top: 0;
  }

  .mbsc-ms-bottom {
    bottom: 0;
  }

  .mbsc-ms {
    text-align: center;
    white-space: nowrap;
  }

  .mbsc-ms-item {
    position: relative;
    margin: 0;
    font-size: 1em;
    line-height: 1.25;
    min-width: 4em;
    padding: .75em .5em;
    text-decoration: none;
    cursor: pointer;
    overflow: hidden;
    transition-property: color, background, border-color, text-shadow;
    transition-duration: 200ms;
  }

  .mbsc-ms-item:focus {
    outline: 0;
  }

  .mbsc-scv-hasw .mbsc-ms-item {
    white-space: normal;
    min-width: 0;
  }

  .mbsc-ms.mbsc-comp a.mbsc-ms-item {
    font-weight: normal; //color: inherit;
    text-decoration: none;
  }

  .mbsc-ms .mbsc-ms-item.mbsc-disabled {
    opacity: .5;
    cursor: not-allowed;
  }


  /* Icon styling */

  .mbsc-ms-icons .mbsc-ms-item {
    min-width: 6em;
    padding: 1.666667em .5em;
    font-size: .75em;
  }


  /* Icon + text styling */

  .mbsc-ms-icons.mbsc-ms-txt .mbsc-ms-item {
    padding-top: 3em;
    padding-bottom: .5em;
  }

  .mbsc-ms-ic:before {
    position: absolute;
    top: .25em;
    left: 0;
    right: 0;
    display: block;
    text-align: center;
    line-height: 1em;
  }

  .mbsc-ms-base .mbsc-ms-ic:before {
    font-size: 2em;
  }
}


@include exports("navigation-base.mobiscroll") {
  .mbsc-mobiscroll {
    &.mbsc-ms-b {
      .mbsc-ms-item {
        border: 2px solid transparent;
        border-width: 2px 0;
      }
    }
  }

  @include mbsc-mobiscroll-navigation-base(mobiscroll, $mbsc-mobiscroll-colors);
}







@mixin mbsc-mobiscroll-navigation($theme, $params) {
  @include exports("navigation.#{$theme}.colors") {
    @include mbsc-mobiscroll-navigation-base($theme, $params);
    @include mbsc-mobiscroll-popup($theme, $params);
  }
}





@include exports("navigation") {
  .mbsc-ms-badge {
    position: absolute;
    top: .25em;
    min-width: 1.666667em;
    height: 1.666667em;
    padding: 0 .25em;
    border-radius: 2em;
    line-height: 1.666667em;
    background: red;
    color: #fff;
    text-align: center;
    box-sizing: border-box;
  }

  .mbsc-ltr .mbsc-ms-badge {
    left: 50%;
    margin-left: .5em;
  }

  .mbsc-rtl .mbsc-ms-badge {
    right: 50%;
    margin-right: .5em;
  }

  .mbsc-ms-txt:not(.mbsc-ms-icons) {
    .mbsc-ms-badge {
      font-size: .75em;
    }

    &.mbsc-ltr .mbsc-ms-badge {
      left: auto;
      right: 0;
    }

    &.mbsc-rtl .mbsc-ms-badge {
      left: 0;
      right: auto;
    }
  }

  /* Show more item */

  .mbsc-ms-item.mbsc-menu-item-ic {
    padding: 1.666667em .5em;
    font-size: .75em;

    &:before {
      top: 50%;
      margin-top: -.5em;
    }
  }

  .mbsc-ms-icons .mbsc-ms-item.mbsc-menu-item-ic:before {
    top: .25em;
    margin-top: 0;
  }


  /* Hamburger Menu icon */

  .mbsc-ms-hamburger.mbsc-ms-c.mbsc-ms-inline {
    display: inline-block;
    vertical-align: middle;
    background: none;
    border: 0;
  }


  /* Show More bubble */

  .mbsc-ms-more {
    .mbsc-ms {
      display: block;
    }

    .mbsc-ms-item {
      min-height: 2.857143em;
    }

    .mbsc-ms-item.mbsc-ms-ic:before {
      top: 50%;
      width: 2.333334em;
      margin-top: -.5em;
      line-height: 1em;
      font-size: 1.714286em;
    }

    .mbsc-ms-badge {
      top: 50%;
      font-size: .857143em;
      margin-top: -0.833333em;
    }

    /* LTR specific */
    .mbsc-ltr {
      .mbsc-ms {
        text-align: left;

        .mbsc-ms-ic {
          padding-left: 4em;
        }
      }

      .mbsc-ms-ic:before {
        right: auto;
      }

      .mbsc-ms-badge {
        left: auto;
        right: .5em;
        margin-left: 0;
      }
    }

    /* RTL specific */
    .mbsc-rtl {
      .mbsc-ms {
        text-align: right;

        .mbsc-ms-ic {
          padding-right: 4em;
        }
      }

      .mbsc-ms-ic:before {
        left: auto;
      }

      .mbsc-ms-badge {
        right: auto;
        left: .5em;
        margin-right: 0;
      }
    }

    /* Icons only */
    &.mbsc-ms-more-icons {
      .mbsc-fr-popup .mbsc-fr-w {
        min-width: 0;
      }

      .mbsc-wdg-c.mbsc-w-p {
        padding: 0;
      }

      .mbsc-ms .mbsc-ms-ic {
        padding-left: .714286em;
        padding-right: .714286em;
      }

      .mbsc-ms-ic:before {
        position: static;
        width: 1.5em;
        margin: 0;
      }
    }
  }
}


@include exports("navigation.mobiscroll") {
  @include mbsc-mobiscroll-navigation(mobiscroll, $mbsc-mobiscroll-colors);
}



// scrollview component



// optionlist component






@mixin mbsc-mobiscroll-optionlist($theme, $params) {
  @include exports("optionlist.#{$theme}.colors") {
    @include mbsc-mobiscroll-navigation-base($theme, $params);
  }
}





@include exports("optionlist.mobiscroll") {
  @include mbsc-mobiscroll-optionlist(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}



// cards component






// Theme specific variables - inherited from global

// background
$mbsc-mobiscroll-card-background: $mbsc-card-background-light !default;
$mbsc-mobiscroll-dark-card-background: $mbsc-card-background-dark !default;
// text
$mbsc-mobiscroll-card-text: $mbsc-card-text-light !default;
$mbsc-mobiscroll-dark-card-text: $mbsc-card-text-dark !default;

// add variables to color maps
$mbsc-mobiscroll-colors: map-merge($mbsc-mobiscroll-colors, (
  'card-background': $mbsc-mobiscroll-card-background,
  'card-text': $mbsc-mobiscroll-card-text,
));
$mbsc-mobiscroll-dark-colors: map-merge($mbsc-mobiscroll-dark-colors, (
  'card-background': $mbsc-mobiscroll-dark-card-background,
  'card-text': $mbsc-mobiscroll-dark-card-text,
));

@mixin mbsc-mobiscroll-cards($theme, $params) {
  @include exports("cards.#{$theme}.colors") {

    @include mbsc-mobiscroll-forms($theme, $params);

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    // get customized params
    $background-param: map-get($params, 'card-background');
    $text-param: map-get($params, 'card-text');
    // overwrite params with customized ones
    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
    // calculated colors
    $subtitle-color: rgba($text, 0.6);
    $border: '';
    @if (lightness($background) > 50%) {
      $border: darken($background, 17%);
    }
    @else {
      $border: lighten($background, 17%);
    }

    .mbsc-#{$theme} {
      &.mbsc-card.mbsc-form {
        background: lighten($background, 4%);
        color: $text;
      }

      .mbsc-card-subtitle {
        color: $subtitle-color;
      }

      &.mbsc-card .mbsc-control-w {
        color: $text;
      }

      &.mbsc-card .mbsc-lv-item {
        background: lighten($background, 4%);
        color: $text;
      }

      .mbsc-card-footer {
        border-color: $border;
      }

      &.mbsc-card .mbsc-input.mbsc-input-outline .mbsc-label {
        background: lighten($background, 4%);
      }
    }
  }
}





@include exports("cards") {

  [mbsc-card]:not(.mbsc-card) {
    // Prevent FOUC
    visibility: hidden;
  }

  .mbsc-card {
    position: relative;
    z-index: 1;
    border-radius: 2px;
    overflow: hidden;
  }

  .mbsc-card-header {
    padding: 1em;
    overflow: hidden;
  }

  .mbsc-card-content {
    padding: 1em;
    overflow: hidden;
  }

  .mbsc-card-content > p:first-child {
    margin-top: 0;
  }

  .mbsc-card-content > p:last-child {
    margin-bottom: 0;
  }

  .mbsc-card-content.mbsc-no-padding {
    padding: 0;
  }

  .mbsc-card-header + .mbsc-card-content {
    padding-top: 0;
  }

  .mbsc-card-footer {
    padding: 1em;
    border-top: 1px solid transparent;
    clear: both;
  }

  /* Card elemets */

  .mbsc-card h2.mbsc-card-title,
  .mbsc-card-title {
    font-size: 1.5em;
    font-weight: normal;
    margin: 0;
  }

  .mbsc-card h3.mbsc-card-subtitle,
  .mbsc-card-subtitle {
    font-size: 0.875em;
    font-weight: normal;
    margin: 0;
  }

  .mbsc-card h3.mbsc-card-subtitle + *,
  .mbsc-card-subtitle + *,
  .mbsc-card h2.mbsc-card-title + *,
  .mbsc-card-title + * {
    margin-top: .25em;
  }

  /* Card image styling */

  .mbsc-card video,
  .mbsc-card img:not(.mbsc-avatar):not(.mbsc-img-thumbnail) {
    display: block;
    width: 100%;
  }

  .mbsc-card.mbsc-ltr .mbsc-avatar {
    float: left;
    margin-right: .5em;
  }

  .mbsc-card.mbsc-rtl .mbsc-avatar {
    float: right;
    margin-left: .5em;
  }

  .mbsc-avatar ~ .mbsc-card-title,
  mbsc-avatar ~ .mbsc-card-title {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;
  }

  /* Form overrides */

  .mbsc-card .mbsc-card-footer .mbsc-btn {
    margin: -.6875em 0 -.6875em 0;
  }

  .mbsc-card .mbsc-card-content .mbsc-control-w {
    margin-left: 0;
    margin-right: 0;
  }

  /* Listview overrides */

  .mbsc-lv .mbsc-lv-item.mbsc-card {
    margin: .75em;
    padding: 0;
  }

  .mbsc-card.mbsc-form .mbsc-lv-cont {
    margin: 0;
  }

  .mbsc-card .mbsc-card-content .mbsc-lv-cont {
    margin: 0 -1em;
  }

  .mbsc-card-list .mbsc-lv-cont .mbsc-lv,
  .mbsc-lv-cont .mbsc-card-list.mbsc-lv {
    background: none;
  }

  .mbsc-card-list .mbsc-lv-item:not(.mbsc-card) {
    padding: 0;
    background: none;
  }
}


@include exports("cards.mobiscroll") {
  .mbsc-mobiscroll {
    &.mbsc-card.mbsc-form {
      box-shadow: 0 1px 2px rgba(0, 0, 0, .3);
    }
  }

  @include mbsc-mobiscroll-cards(mobiscroll, $mbsc-mobiscroll-colors);
}



// popup component



// grid-layout component



@mixin mbsc-grid-sm() {
  .mbsc-col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .mbsc-col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .mbsc-col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .mbsc-col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .mbsc-col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .mbsc-col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .mbsc-col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .mbsc-col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .mbsc-col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .mbsc-col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .mbsc-col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .mbsc-col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .mbsc-col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .mbsc-col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .mbsc-offset-sm-0 {
    margin-left: 0;
  }

  .mbsc-offset-sm-1 {
    margin-left: 8.333333%;
  }

  .mbsc-offset-sm-2 {
    margin-left: 16.666667%;
  }

  .mbsc-offset-sm-3 {
    margin-left: 25%;
  }

  .mbsc-offset-sm-4 {
    margin-left: 33.333333%;
  }

  .mbsc-offset-sm-5 {
    margin-left: 41.666667%;
  }

  .mbsc-offset-sm-6 {
    margin-left: 50%;
  }

  .mbsc-offset-sm-7 {
    margin-left: 58.333333%;
  }

  .mbsc-offset-sm-8 {
    margin-left: 66.666667%;
  }

  .mbsc-offset-sm-9 {
    margin-left: 75%;
  }

  .mbsc-offset-sm-10 {
    margin-left: 83.333333%;
  }

  .mbsc-offset-sm-11 {
    margin-left: 91.666667%;
  }

  .mbsc-push-sm-0 {
    left: auto;
  }

  .mbsc-push-sm-1 {
    left: 8.33333333%;
  }

  .mbsc-push-sm-2 {
    left: 16.66666667%;
  }

  .mbsc-push-sm-3 {
    left: 25%;
  }

  .mbsc-push-sm-4 {
    left: 33.33333333%;
  }

  .mbsc-push-sm-5 {
    left: 41.66666667%;
  }

  .mbsc-push-sm-6 {
    left: 50%;
  }

  .mbsc-push-sm-7 {
    left: 58.33333333%;
  }

  .mbsc-push-sm-8 {
    left: 66.66666667%;
  }

  .mbsc-push-sm-9 {
    left: 75%;
  }

  .mbsc-push-sm-10 {
    left: 83.33333333%;
  }

  .mbsc-push-sm-11 {
    left: 91.66666667%;
  }

  .mbsc-push-sm-12 {
    left: 100%;
  }

  .mbsc-pull-sm-0 {
    right: auto;
  }

  .mbsc-pull-sm-1 {
    right: 8.33333333%;
  }

  .mbsc-pull-sm-2 {
    right: 16.66666667%;
  }

  .mbsc-pull-sm-3 {
    right: 25%;
  }

  .mbsc-pull-sm-4 {
    right: 33.33333333%;
  }

  .mbsc-pull-sm-5 {
    right: 41.66666667%;
  }

  .mbsc-pull-sm-6 {
    right: 50%;
  }

  .mbsc-pull-sm-7 {
    right: 58.33333333%;
  }

  .mbsc-pull-sm-8 {
    right: 66.66666667%;
  }

  .mbsc-pull-sm-9 {
    right: 75%;
  }

  .mbsc-pull-sm-10 {
    right: 83.33333333%;
  }

  .mbsc-pull-sm-11 {
    right: 91.66666667%;
  }

  .mbsc-pull-sm-12 {
    right: 100%;
  }
}

@mixin mbsc-grid-md() {
  .mbsc-col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .mbsc-col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .mbsc-col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .mbsc-col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .mbsc-col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .mbsc-col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .mbsc-col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .mbsc-col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .mbsc-col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .mbsc-col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .mbsc-col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .mbsc-col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .mbsc-col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .mbsc-col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .mbsc-offset-md-0 {
    margin-left: 0;
  }

  .mbsc-offset-md-1 {
    margin-left: 8.333333%;
  }

  .mbsc-offset-md-2 {
    margin-left: 16.666667%;
  }

  .mbsc-offset-md-3 {
    margin-left: 25%;
  }

  .mbsc-offset-md-4 {
    margin-left: 33.333333%;
  }

  .mbsc-offset-md-5 {
    margin-left: 41.666667%;
  }

  .mbsc-offset-md-6 {
    margin-left: 50%;
  }

  .mbsc-offset-md-7 {
    margin-left: 58.333333%;
  }

  .mbsc-offset-md-8 {
    margin-left: 66.666667%;
  }

  .mbsc-offset-md-9 {
    margin-left: 75%;
  }

  .mbsc-offset-md-10 {
    margin-left: 83.333333%;
  }

  .mbsc-offset-md-11 {
    margin-left: 91.666667%;
  }

  .mbsc-push-md-0 {
    left: auto;
  }

  .mbsc-push-md-1 {
    left: 8.33333333%;
  }

  .mbsc-push-md-2 {
    left: 16.66666667%;
  }

  .mbsc-push-md-3 {
    left: 25%;
  }

  .mbsc-push-md-4 {
    left: 33.33333333%;
  }

  .mbsc-push-md-5 {
    left: 41.66666667%;
  }

  .mbsc-push-md-6 {
    left: 50%;
  }

  .mbsc-push-md-7 {
    left: 58.33333333%;
  }

  .mbsc-push-md-8 {
    left: 66.66666667%;
  }

  .mbsc-push-md-9 {
    left: 75%;
  }

  .mbsc-push-md-10 {
    left: 83.33333333%;
  }

  .mbsc-push-md-11 {
    left: 91.66666667%;
  }

  .mbsc-push-md-12 {
    left: 100%;
  }

  .mbsc-pull-md-0 {
    right: auto;
  }

  .mbsc-pull-md-1 {
    right: 8.33333333%;
  }

  .mbsc-pull-md-2 {
    right: 16.66666667%;
  }

  .mbsc-pull-md-3 {
    right: 25%;
  }

  .mbsc-pull-md-4 {
    right: 33.33333333%;
  }

  .mbsc-pull-md-5 {
    right: 41.66666667%;
  }

  .mbsc-pull-md-6 {
    right: 50%;
  }

  .mbsc-pull-md-7 {
    right: 58.33333333%;
  }

  .mbsc-pull-md-8 {
    right: 66.66666667%;
  }

  .mbsc-pull-md-9 {
    right: 75%;
  }

  .mbsc-pull-md-10 {
    right: 83.33333333%;
  }

  .mbsc-pull-md-11 {
    right: 91.66666667%;
  }

  .mbsc-pull-md-12 {
    right: 100%;
  }
}

@mixin mbsc-grid-lg() {
  .mbsc-col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .mbsc-col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .mbsc-col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .mbsc-col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .mbsc-col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .mbsc-col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .mbsc-col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .mbsc-col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .mbsc-col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .mbsc-col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .mbsc-col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .mbsc-col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .mbsc-col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .mbsc-col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .mbsc-offset-lg-0 {
    margin-left: 0;
  }

  .mbsc-offset-lg-1 {
    margin-left: 8.333333%;
  }

  .mbsc-offset-lg-2 {
    margin-left: 16.666667%;
  }

  .mbsc-offset-lg-3 {
    margin-left: 25%;
  }

  .mbsc-offset-lg-4 {
    margin-left: 33.333333%;
  }

  .mbsc-offset-lg-5 {
    margin-left: 41.666667%;
  }

  .mbsc-offset-lg-6 {
    margin-left: 50%;
  }

  .mbsc-offset-lg-7 {
    margin-left: 58.333333%;
  }

  .mbsc-offset-lg-8 {
    margin-left: 66.666667%;
  }

  .mbsc-offset-lg-9 {
    margin-left: 75%;
  }

  .mbsc-offset-lg-10 {
    margin-left: 83.333333%;
  }

  .mbsc-offset-lg-11 {
    margin-left: 91.666667%;
  }

  .mbsc-push-lg-0 {
    left: auto;
  }

  .mbsc-push-lg-1 {
    left: 8.33333333%;
  }

  .mbsc-push-lg-2 {
    left: 16.66666667%;
  }

  .mbsc-push-lg-3 {
    left: 25%;
  }

  .mbsc-push-lg-4 {
    left: 33.33333333%;
  }

  .mbsc-push-lg-5 {
    left: 41.66666667%;
  }

  .mbsc-push-lg-6 {
    left: 50%;
  }

  .mbsc-push-lg-7 {
    left: 58.33333333%;
  }

  .mbsc-push-lg-8 {
    left: 66.66666667%;
  }

  .mbsc-push-lg-9 {
    left: 75%;
  }

  .mbsc-push-lg-10 {
    left: 83.33333333%;
  }

  .mbsc-push-lg-11 {
    left: 91.66666667%;
  }

  .mbsc-push-lg-12 {
    left: 100%;
  }

  .mbsc-pull-lg-0 {
    right: auto;
  }

  .mbsc-pull-lg-1 {
    right: 8.33333333%;
  }

  .mbsc-pull-lg-2 {
    right: 16.66666667%;
  }

  .mbsc-pull-lg-3 {
    right: 25%;
  }

  .mbsc-pull-lg-4 {
    right: 33.33333333%;
  }

  .mbsc-pull-lg-5 {
    right: 41.66666667%;
  }

  .mbsc-pull-lg-6 {
    right: 50%;
  }

  .mbsc-pull-lg-7 {
    right: 58.33333333%;
  }

  .mbsc-pull-lg-8 {
    right: 66.66666667%;
  }

  .mbsc-pull-lg-9 {
    right: 75%;
  }

  .mbsc-pull-lg-10 {
    right: 83.33333333%;
  }

  .mbsc-pull-lg-11 {
    right: 91.66666667%;
  }

  .mbsc-pull-lg-12 {
    right: 100%;
  }
}

@mixin mbsc-grid-xl() {
  .mbsc-col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .mbsc-col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .mbsc-col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .mbsc-col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .mbsc-col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .mbsc-col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .mbsc-col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .mbsc-col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .mbsc-col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .mbsc-col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .mbsc-col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .mbsc-col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .mbsc-col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .mbsc-col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .mbsc-offset-xl-0 {
    margin-left: 0;
  }

  .mbsc-offset-xl-1 {
    margin-left: 8.333333%;
  }

  .mbsc-offset-xl-2 {
    margin-left: 16.666667%;
  }

  .mbsc-offset-xl-3 {
    margin-left: 25%;
  }

  .mbsc-offset-xl-4 {
    margin-left: 33.333333%;
  }

  .mbsc-offset-xl-5 {
    margin-left: 41.666667%;
  }

  .mbsc-offset-xl-6 {
    margin-left: 50%;
  }

  .mbsc-offset-xl-7 {
    margin-left: 58.333333%;
  }

  .mbsc-offset-xl-8 {
    margin-left: 66.666667%;
  }

  .mbsc-offset-xl-9 {
    margin-left: 75%;
  }

  .mbsc-offset-xl-10 {
    margin-left: 83.333333%;
  }

  .mbsc-offset-xl-11 {
    margin-left: 91.666667%;
  }

  .mbsc-push-xl-0 {
    left: auto;
  }

  .mbsc-push-xl-1 {
    left: 8.33333333%;
  }

  .mbsc-push-xl-2 {
    left: 16.66666667%;
  }

  .mbsc-push-xl-3 {
    left: 25%;
  }

  .mbsc-push-xl-4 {
    left: 33.33333333%;
  }

  .mbsc-push-xl-5 {
    left: 41.66666667%;
  }

  .mbsc-push-xl-6 {
    left: 50%;
  }

  .mbsc-push-xl-7 {
    left: 58.33333333%;
  }

  .mbsc-push-xl-8 {
    left: 66.66666667%;
  }

  .mbsc-push-xl-9 {
    left: 75%;
  }

  .mbsc-push-xl-10 {
    left: 83.33333333%;
  }

  .mbsc-push-xl-11 {
    left: 91.66666667%;
  }

  .mbsc-push-xl-12 {
    left: 100%;
  }

  .mbsc-pull-xl-0 {
    right: auto;
  }

  .mbsc-pull-xl-1 {
    right: 8.33333333%;
  }

  .mbsc-pull-xl-2 {
    right: 16.66666667%;
  }

  .mbsc-pull-xl-3 {
    right: 25%;
  }

  .mbsc-pull-xl-4 {
    right: 33.33333333%;
  }

  .mbsc-pull-xl-5 {
    right: 41.66666667%;
  }

  .mbsc-pull-xl-6 {
    right: 50%;
  }

  .mbsc-pull-xl-7 {
    right: 58.33333333%;
  }

  .mbsc-pull-xl-8 {
    right: 66.66666667%;
  }

  .mbsc-pull-xl-9 {
    right: 75%;
  }

  .mbsc-pull-xl-10 {
    right: 83.33333333%;
  }

  .mbsc-pull-xl-11 {
    right: 91.66666667%;
  }

  .mbsc-pull-xl-12 {
    right: 100%;
  }
}

@include exports("grid-layout") {

  .mbsc-grid,
  .mbsc-grid-unresp,
  .mbsc-grid-fixed {
    width: 100%;
    padding-right: 1em;
    padding-left: 1em;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;

    * {
      box-sizing: border-box;
    }
  }

  .mbsc-no-padding .mbsc-col,
  .mbsc-no-padding [class*="mbsc-col-"],
  .mbsc-form-grid .mbsc-col,
  .mbsc-form-grid [class*="mbsc-col-"] {
    padding-right: 0;
    padding-left: 0;
  }

  .mbsc-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -1em;
    margin-left: -1em;
  }

  .mbsc-col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .mbsc-col-1,
  .mbsc-col-2,
  .mbsc-col-3,
  .mbsc-col-4,
  .mbsc-col-5,
  .mbsc-col-6,
  .mbsc-col-7,
  .mbsc-col-8,
  .mbsc-col-9,
  .mbsc-col-10,
  .mbsc-col-11,
  .mbsc-col-12,
  .mbsc-col,
  .mbsc-col-auto,
  .mbsc-col-sm-1,
  .mbsc-col-sm-2,
  .mbsc-col-sm-3,
  .mbsc-col-sm-4,
  .mbsc-col-sm-5,
  .mbsc-col-sm-6,
  .mbsc-col-sm-7,
  .mbsc-col-sm-8,
  .mbsc-col-sm-9,
  .mbsc-col-sm-10,
  .mbsc-col-sm-11,
  .mbsc-col-sm-12,
  .mbsc-col-sm,
  .mbsc-col-sm-auto,
  .mbsc-col-md-1,
  .mbsc-col-md-2,
  .mbsc-col-md-3,
  .mbsc-col-md-4,
  .mbsc-col-md-5,
  .mbsc-col-md-6,
  .mbsc-col-md-7,
  .mbsc-col-md-8,
  .mbsc-col-md-9,
  .mbsc-col-md-10,
  .mbsc-col-md-11,
  .mbsc-col-md-12,
  .mbsc-col-md,
  .mbsc-col-md-auto,
  .mbsc-col-lg-1,
  .mbsc-col-lg-2,
  .mbsc-col-lg-3,
  .mbsc-col-lg-4,
  .mbsc-col-lg-5,
  .mbsc-col-lg-6,
  .mbsc-col-lg-7,
  .mbsc-col-lg-8,
  .mbsc-col-lg-9,
  .mbsc-col-lg-10,
  .mbsc-col-lg-11,
  .mbsc-col-lg-12,
  .mbsc-col-lg,
  .mbsc-col-lg-auto,
  .mbsc-col-xl-1,
  .mbsc-col-xl-2,
  .mbsc-col-xl-3,
  .mbsc-col-xl-4,
  .mbsc-col-xl-5,
  .mbsc-col-xl-6,
  .mbsc-col-xl-7,
  .mbsc-col-xl-8,
  .mbsc-col-xl-9,
  .mbsc-col-xl-10,
  .mbsc-col-xl-11,
  .mbsc-col-xl-12,
  .mbsc-col-xl,
  .mbsc-col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 1em;
    padding-left: 1em;
  }

  .mbsc-col-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .mbsc-col-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .mbsc-col-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .mbsc-col-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .mbsc-col-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .mbsc-col-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .mbsc-col-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .mbsc-col-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .mbsc-col-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .mbsc-col-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .mbsc-col-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .mbsc-col-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .mbsc-col-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .mbsc-offset-1 {
    margin-left: 8.333333%;
  }

  .mbsc-offset-2 {
    margin-left: 16.666667%;
  }

  .mbsc-offset-3 {
    margin-left: 25%;
  }

  .mbsc-offset-4 {
    margin-left: 33.333333%;
  }

  .mbsc-offset-5 {
    margin-left: 41.666667%;
  }

  .mbsc-offset-6 {
    margin-left: 50%;
  }

  .mbsc-offset-7 {
    margin-left: 58.333333%;
  }

  .mbsc-offset-8 {
    margin-left: 66.666667%;
  }

  .mbsc-offset-9 {
    margin-left: 75%;
  }

  .mbsc-offset-10 {
    margin-left: 83.333333%;
  }

  .mbsc-offset-11 {
    margin-left: 91.666667%;
  }

  .mbsc-grid-sm {
    &.mbsc-grid-fixed {
      max-width: 540px;
    }

    @include mbsc-grid-sm();
  }

  @media (min-width: 576px) {
    .mbsc-grid {
      &.mbsc-grid-fixed {
        max-width: 540px;
      }

      @include mbsc-grid-sm();
    }
  }

  .mbsc-grid-md {
    &.mbsc-grid-fixed {
      max-width: 720px;
    }

    @include mbsc-grid-md();
  }

  @media (min-width: 768px) {
    .mbsc-grid {
      &.mbsc-grid-fixed {
        max-width: 720px;
      }

      @include mbsc-grid-md();
    }
  }

  .mbsc-grid-lg {
    &.mbsc-grid-fixed {
      max-width: 960px;
    }

    @include mbsc-grid-lg();
  }

  @media (min-width: 992px) {
    .mbsc-grid {
      &.mbsc-grid-fixed {
        max-width: 960px;
      }

      @include mbsc-grid-lg();
    }
  }

  .mbsc-grid-xl {
    &.mbsc-grid-fixed {
      max-width: 1140px;
    }

    @include mbsc-grid-xl();
  }

  @media (min-width: 1200px) {
    .mbsc-grid {
      &.mbsc-grid-fixed {
        max-width: 1140px;
      }

      @include mbsc-grid-xl();
    }
  }

  .mbsc-align-items-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .mbsc-align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .mbsc-align-items-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .mbsc-justify-content-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .mbsc-justify-content-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .mbsc-justify-content-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .mbsc-justify-content-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .mbsc-justify-content-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
}




// Theme builder function for mobiscroll theme

@mixin mbsc-mobiscroll-theme($theme, $colors) {
    @include mbsc-mobiscroll-select($theme, $colors);
    @include mbsc-mobiscroll-datetime($theme, $colors);
    @include mbsc-mobiscroll-image($theme, $colors);
    @include mbsc-mobiscroll-treelist($theme, $colors);
    @include mbsc-mobiscroll-calendar($theme, $colors);
    @include mbsc-mobiscroll-timespan($theme, $colors);
    @include mbsc-mobiscroll-timer($theme, $colors);
    @include mbsc-mobiscroll-color($theme, $colors);
    @include mbsc-mobiscroll-range($theme, $colors);
    @include mbsc-mobiscroll-scroller($theme, $colors);
    @include mbsc-mobiscroll-listview($theme, $colors);
    @include mbsc-mobiscroll-number($theme, $colors);
    @include mbsc-mobiscroll-numpad($theme, $colors);
    @include mbsc-mobiscroll-forms($theme, $colors);
    @include mbsc-mobiscroll-eventcalendar($theme, $colors);
    @include mbsc-mobiscroll-measurement($theme, $colors);
    @include mbsc-mobiscroll-navigation($theme, $colors);
    @include mbsc-mobiscroll-scrollview($theme, $colors);
    @include mbsc-mobiscroll-optionlist($theme, $colors);
    @include mbsc-mobiscroll-cards($theme, $colors);
    @include mbsc-mobiscroll-popup($theme, $colors);
}

@mixin mbsc-custom-theme($theme, $base-theme, $colors) {
    @if $base-theme==mobiscroll {
        @include mbsc-mobiscroll-theme($theme, $colors);
    }
}

@include mbsc-mobiscroll-theme(mobiscroll-dark, $mbsc-mobiscroll-dark-colors);

