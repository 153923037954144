.support-menuItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0;
  font-family: 'DegularText-Bold' !important;
  font-size: 16px;
  font-weight: bold;

  .arrowSize {
    font-size: 22px;
  }
  
  .menuItem-headline {
    max-width: 260px;
    text-align: center;
  }
}

.support-headline {
  justify-content: center;
  margin: 29px 0 0 !important;

  .arrowLeft {
    font-size: 22px;
    position: absolute;
    left: 1.3em;
  }
}

.menuItem-bottom {
  margin-top: 32px;
}

.support-content {
  margin-top: 24px;
  margin-bottom: 40px;

  img {
    object-fit: contain;
    height: 100%;
    max-height: 312px;
    width: 100%;
    margin: 26px 0;
  }
}