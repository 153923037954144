.label_mbsc{
    font-size: .65em;
    font-family: IBMPlexMono-Regular;
    text-transform: uppercase;
}
.clubThumb{
    width: 100px;
    height: 100px;
    border-radius: 100%;
    border: 4px solid black;
}