@import '../../_variables.scss';

.page-recordingPlan {
  display: flex;
  padding: 20px 0px 20px;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  height: 100%;
  min-height: 580px;

  .recordingPlan-calendar {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;

    .calendar-label {
      display: flex;
      // align-items: center;
      gap: 2px;
      align-self: stretch;
      height: 100%;

      .label-colorBar {
        width: 4px;
        height: 100%;
        // flex: 1 0 0;
        border-radius: 2px;
        // background: var(--violet-v-400, #9067F1);
      }

      .lable-event {
        display: flex;
        padding: 2px 4px;
        align-items: center;
        flex: 1 0 0;
        border-radius: 2px;
        // background: var(--neutral-n-600, #363436);

        width: 100%;

        .event-teams {
          display: flex;
          align-items: center;
          
          .teams-team {
            display: flex;
            width: 20px;
            height: 20px;
            padding: 3px;
            justify-content: center;
            align-items: center;
            border-radius: 100px;
            border: 1px solid var(--neutral-n-600, #363436);
            background: #717171;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .teams-team:last-child {
            margin-left: -6px;
          }
        }

        @media screen and (max-width: 1306px) {
          .event-teams {
            zoom: 0.7;
          }
        }

        .event-info {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 2px;
          flex: 1 0 0;

          width: 100%;
          overflow: hidden;

          @media screen and (max-width: 1480px) {
            .info-age {
              display: none;
            }
          }

          .info-time {
            color: var(--neutral-n-400, #999);
            font-family: Nekst;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 14px; /* 140% */

            min-width: 30px;
            display: flex;
            justify-content: flex-end;
          }

          .info-live {
            height: 8px;
            width: 8px;
            margin: 2px;
            background: #d90000;
            border-radius: 100%;
          }
        }
      }
    }

    .calendar-legende {
      display: flex;
      align-items: center;
      gap: 12px;
      align-self: stretch;

      .legende-item {
        display: flex;
        align-items: center;
        gap: 4px;

        .item-dot {
          width: 8px;
          height: 8px;
          border-radius: 1000px;
          background: var(--Violet-V400, #9067F1);
        }

        .item-title {
          color: #FFF;
          font-family: Nekst;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px; /* 140% */
        }
      }

      .legende-item-neutral {
        .item-dot {
          background: var(--Neutral-N100, #F6F6F6);
        }
      }

      .legende-item-green {
        .item-dot {
          background: var(--Green-G400, #26BD83);
        }
      }
    }

    .lable-eventPast {
      opacity: 0.33;
    }

    .lable-eventPast:hover {
      // opacity: 1;
    }
  }

  .recordingPlan-sideContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    height: 100%;

    
    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }
    ::-webkit-scrollbar:vertical {
      width: 8px;
    }
    ::-webkit-scrollbar:horizontal {
      height: 10px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 10px;
      border: 2px solid #525252;
    }
    ::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #525252;
    }

    .sideContent-events {
      display: flex;
      width: 300px;
      flex-direction: column;
      align-items: flex-start;
      gap: 6px;
      flex: 1 0 0;
      background: var(--neutral-n-700, #201D20);
      height: calc(100% - 64px);

      .events-new {
        display: flex;
        padding: 8px 12px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        align-self: stretch;
      }

      .events-event {
        display: flex;
        padding-bottom: 0px;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        width: 100%;
        text-decoration: none;

        .event-top {
          display: flex;
          height: 34px;
          padding: 8px;
          align-items: center;
          gap: 16px;
          align-self: stretch;
          border-bottom: 1px solid var(--neutral-n-50, rgba(246, 246, 246, 0.16));

          .top-left {
            display: flex;
            align-items: center;
            gap: 8px;
            flex: 1 0 0;

            .left-time {
              display: flex;
              align-items: center;
              gap: 4px;

              .time-text {
                color: var(--neutral-n-100, #F6F6F6);
                font-family: Nekst;
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: 14px; /* 140% */
                letter-spacing: 0.3px;
                text-transform: uppercase;
                white-space: nowrap;
              }
            }

            .left-paywall {
              padding-left: 4px;
              padding-top: 2px;
            }
          }

          .top-right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 4px;
            flex: 1 0 0;

            .right-text {
              color: var(--neutral-n-100, #F6F6F6);
              font-family: Nekst;
              font-size: 10px;
              font-style: normal;
              font-weight: 500;
              line-height: 14px; /* 140% */
              letter-spacing: 0.3px;
              text-transform: uppercase;
              white-space: nowrap;
            }
          }
        }

        .event-main {
          display: flex;
          padding: 12px;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 4px;
          align-self: stretch;

          .main-teams {
            display: flex;
            align-items: center;
            gap: -10px;
            align-self: stretch;

            .teams-team {
              display: flex;
              width: 40px;
              height: 40px;
              padding: 6px;
              justify-content: center;
              align-items: center;
              border-radius: 100px;
              border: 1px solid var(--neutral-n-600, #363436);
              background: #5b5b5b;

              img {
                height: 100%;
                width: 100%;
              }
            }

            .teams-team:last-child {
              margin-left: -10px;
            }
          }

          .main-title {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            color: var(--neutral-n-100, #F6F6F6);
            text-overflow: ellipsis;
            /* Paragraph/P-M - Medium */
            font-family: Nekst;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 22.4px; /* 140% */
          }

          .main-badges {
            display: flex;
            padding-top: 4px;
            align-items: center;
            align-content: center;
            gap: 4px;
            align-self: stretch;
            flex-wrap: wrap;
          }
        }
      }

      .mbsc-calendar {
        width: 100%;
      }

      .mbsc-calendar-wrapper {
        display: none;
      }

      .mbsc-list-header {
        background: var(--neutral-n-700, #201D20);
        border: 0;
      }

      .mbsc-list  {
        background: var(--neutral-n-700, #201D20);
      }

      .mbsc-event-list-scroll {
        background: var(--neutral-n-700, #201D20);
      }

      .mbsc-list-item {
        margin: 0 12px;
        border-radius: 4px;
        background: var(--neutral-n-600, #363436);
        z-index: none;
        padding: 0;
      }

      .mbsc-ios-dark.mbsc-event.mbsc-list-item:before {
        border-color: transparent;
      }
      .mbsc-event.mbsc-list-item:after {
        border-color: transparent;
      }

      .mbsc-event-group {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding-bottom: 20px;

        .mbsc-event-day {
          color: var(--neutral-n-400, #999);
          font-family: Nekst;
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
          line-height: 14px;
          letter-spacing: 0.3px;
          text-transform: uppercase;
        }
      }
    }
  }
}

// calendar

.mbsc-calendar-label-hover {
  .calendar-label {
    .lable-eventPast {
      opacity: 0.8 !important;
    }
  }
}

.mbsc-calendar-labels {
  max-height: 100%;
  overflow: auto;
  top: 36px;
  bottom: 0;
}

.mbsc-flex-1-0 {
  min-height: 0 !important;
}

.mbsc-calendar-row {
  min-height: 0 !important;
}

.mbsc-calendar-controls {
  padding: 0 !important;
  min-height: 0 !important;
}
.calendar-header {
  background-color: #121212;
  height: 32px !important;

  .header-buttonBar {
    height: 32px !important;
  }
}

.mbsc-calendar-title {
  color: var(--neutral-n-100, #F6F6F6) !important;
  font-family: Nekst !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 100% !important; 
  text-transform: uppercase !important;
}

.mbsc-calendar-button-today {
  color: var(--neutral-n-100, #F6F6F6) !important;
  font-family: Nekst !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 900 !important;
  line-height: 100% !important;
  letter-spacing: 0.4px !important;
  text-transform: uppercase !important;
  margin: 0px 12px !important;
}

.buttonBar-days {
  .mbsc-button-icon {
    height: 16px !important;
  }
}

.mbsc-calendar-table {
  border-right: 1px solid var(--neutral-n-600, #363436) !important;
}

.mbsc-calendar-week-day {
  background-color: #121212;
  border-top: 1px solid var(--neutral-n-600, #363436) !important;
  // margin-left: 1px !important;
  background: var(--neutral-n-700, #201D20);
  border-left: 1px solid var(--neutral-n-600, #363436) !important;
}

.mbsc-calendar-body-inner {
  margin-left: 0 !important;
}

.mbsc-ios-dark.mbsc-calendar-cell  {
  border-color: var(--neutral-n-600, #363436) !important;
  ;
}

/*.mbsc-ltr {
  text-align: center !important;
}*/

.mbsc-ios.mbsc-calendar-width-md .mbsc-calendar-text {
  height: 2.4em !important;
}

.mbsc-calendar-body {
  margin-right: 1px;
}

.mbsc-ios.mbsc-button {
  padding: 0 !important;
}

.mbsc-calendar-title:first-child {
  padding: 0 !important;
}

@media screen and (max-width: $breakpoints-mobile) {
  .page-recordingPlan {
    flex-direction: column;
    padding: 0;

    .recordingPlan-calendar {
      .calendar-legende {
        display: none;
      }
    }

    .recordingPlan-sideContent {
      overflow: hidden;

      .mbsc-event-list {
        display: block !important;
      }

      .sideContent-events {
        width: 100%;

        .events-new {
          display: none;
        }
      }
    }
  }

  .mbsc-event-list {
    display: none !important;
  }
  
  .mbsc-calendar-row {
    border-left: 1px solid #363436;
  }
}
