@import '../../../_variables.scss';

.userManagement {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: 40px 0 30px;

  .bottomInfoText {
    text-align: -webkit-center;
    color: #b3b3b3;
    margin: 30px 0 0 0;
    font-family: 'IBMPlexMono-Regular';
  }

  .userManagement-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // gap: 16px;
    align-self: stretch;

    .content-btn {
      display: flex;
      flex-direction: row;
      gap: 8px;
      flex: 1 0;
      justify-content: flex-end;
      width: 100%;
    }

    .content-userBox {
      display: flex;
      width: 516px;
      gap: 8px;
      flex-direction: column;

      .userBox-new {
        color: var(--neutral-n-100, #F6F6F6);
        /* Paragraph/P-S - Medium */
        font-family: Nekst;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 19.6px; /* 140% */
        margin-top: 32px;
      }

      .userBox {
        display: flex;
        padding: 0px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;

        .userBox-profile {
          display: flex;
          width: 64px;
          height: 64px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 100px;
          border: 1px solid var(--neutral-n-100, #F6F6F6);
          background: var(--neutral-n-50, rgba(246, 246, 246, 0.16));

          img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
          }
        }

        .userBox-info {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          align-self: stretch;

          .name {
            align-self: stretch;
            color: var(--neutral-n-100, #F6F6F6);
            /* Paragraph/P-L - Medium */
            font-family: Nekst;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 25.2px; /* 140% */
          }

          .mail {
            align-self: stretch;
            color: var(--neutral-n-400, #999);
            /* Paragraph/P-S - Regular */
            font-family: Nekst;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 19.6px; /* 140% */
          }
        }
      }

      .roleBox {
        display: flex;
        padding: 16px 24px;
        align-items: center;
        gap: 24px;
        border-radius: 4px;
        background: var(--neutral-n-700, #201D20);

        .roleBox-left {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          flex: 1 0 0;

          .role-title {
            align-self: stretch;

            color: var(--neutral-n-100, #F6F6F6);
            /* Paragraph/P-L - Medium */
            font-family: Nekst;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 25.2px; /* 140% */
          }

          .role-infoText {
            align-self: stretch;
            color: var(--neutral-n-400, #999);
            /* Paragraph/P-S - Regular */
            font-family: Nekst;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 19.6px; /* 140% */
          }


        }

        .roleBox-right {

          .mbsc-mobiscroll.mbsc-ltr.mbsc-switch {
            padding: 0 0 0 2em !important;
          }
        }
      }

      .bottom-btn {
        display: none;
      }
    }
  }
}

@media screen and (max-width: $breakpoints-mobile) {
  .userManagement {
    margin-top: 20px;

    .userManagement-content  {
      margin-bottom: 58px;

      .content-btn {
        display: none;
      }

      .content-userBox {
        width: 100%;

        .userBox {
          align-items: center;

          .userBox-info {
            .name {
              align-self: center;
            }

            .mail {
              align-self: center;
            }
          }
        }

        .bottom-btn {
          z-index: 100;
          display: flex;
          padding: 16px;
          justify-content: center;
          /* align-items: flex-end; */
          /* gap: 16px; */
          border-top: 1px solid var(--neutral-n-600, #363436);
          background: var(--neutral-n-800, #151315);
          position: fixed;
          bottom: 66px;
          right: 0;
          left: 0;
        }
      }
    }
  }
}


.userEdit {
  height: calc(100% - 67px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .mbsc-input.mbsc-control-w.mbsc-ltr, .mbsc-input.mbsc-control-w.mbsc-rtl {
    margin: 0 0 1.5em 0 !important;
  }
}

.backButton {
  cursor: pointer;
  margin: 10px;
  font-size: 1.2em;
}