@import '../../../_variables.scss';

.main-eventStep {
  display: flex;
  padding-bottom: 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch !important;

  .eventStep-form {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    background: var(--neutral-n-700, #201D20);
    width: 100%;

    .form-title {
      align-self: stretch;
      color: var(--neutral-n-400, #999);
      /* Overline/O01 */
      font-family: Nekst;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: 14px; /* 140% */
      letter-spacing: 0.3px;
      text-transform: uppercase;
    }

    .form-dateTime {
      display: flex;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
    }

    .form-eventType {
      display: flex;
      align-items: flex-end;
      gap: 24px;
      align-self: stretch;

      .eventType-inputBottomText {
        color: var(--Neutral-N400, #999);
        font-family: Nekst;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        // position: absolute;
        padding-top: 2px;
      }

      .eventType-tags {
        display: flex;
        height: 32px;
        align-items: center;
        gap: 8px;
      }

      .eventType-tagsContainer {
        display: flex;
        height: 100%;
        flex-direction: column;
        margin-bottom: 16px;

        .eventType-tagsLabel {
          display: flex;
          height: 100%;
          align-items: center;
          margin-top: 6px;
          color: var(--Neutral-N100, #F6F6F6);
          /* Paragraph/P-S - Medium */
          font-family: Nekst;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 19.6px; /* 140% */
          text-wrap: nowrap;
          justify-content: flex-end;

          .innerDiv {
            text-wrap: wrap;
          }
        }

        .eventType-tags {
          display: flex;
          height: 32px;
          align-items: center;
          gap: 8px;
        }
      }

      .eventType-type {
        width: 100%;
        margin-top: 24px;

        .mbsc-form-control-wrapper {
          margin: 0 !important;
        }

        .mbsc-label {
          color: var(--neutral-n-100, #F6F6F6) !important;
          font-family: Nekst !important;
          font-size: 14px !important;
          font-style: normal !important;
          font-weight: 500 !important;
          line-height: 19.6px !important;
          text-transform: none !important;
        }
      }

      .mbsc-control {
        margin-top: 14px !important;
      }
    }
  }

  .eventStep-activeBox {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    background: var(--neutral-n-700, #201D20);

    .activeBox-header {
      display: flex;
      flex-direction: row;
      display: flex;
      // align-items: flex-start;
      align-self: stretch;
      align-items: center;
      user-select: none;
    }

    .form-leftText {
      flex: 1 0 0;
      color: var(--neutral-n-100, #F6F6F6);
      /* Paragraph/P-S - Medium */
      font-family: Nekst;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 19.6px; /* 140% */
    }

    .form-toggle {
      .toggle-btn {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;
      }
    }

    .wrapper-cameraMode {
      display: flex;
      padding-top: 16px;
      align-items: flex-start;
      align-content: flex-start;
      gap: 12px;
      align-self: stretch;
      flex-wrap: wrap;
    
      .cameraMode-box {
        display: flex;
        width: 307px;
        height: 309px;
        padding: 12px;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        border-radius: 4px;
        border: 1px solid var(--neutral-n-600, #363436);
        cursor: pointer;
        user-select: none;
      
        .box-headerTitel {
          display: flex;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
      
          .headerTitel-titel {
            color: #FFF;
            /* Paragraph/P-M - Medium */
            font-family: Nekst;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 22.4px; /* 140% */
          }
      
          .tag-btn {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-blend-mode: multiply;
            width: 16px;
            height: 16px;
            border-radius: 100%;
            border: 1px solid var(--transparent-transparent, #FFF);
      
            .btn-radio {
              background: var(--transparent-transparent, #FFF);
              height: 8px;
              width: 8px;
              border-radius: 100%;
            }
          }
          
        }
      
        .box-content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          align-self: stretch;
      
          .content-field {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            position: relative;
      
            .field-cameraMan {
              width: 100px;
              height: 150px;
              flex-shrink: 0;
              border: 2px solid var(--neutral-n-100, #F6F6F6);
              background: var(--neutral-n-50, rgba(246, 246, 246, 0.16));
              position: absolute;
              top: 12px;
      
              .cameraMan-arrow {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: 100%;
              }
            }
      
            .quarterBoxPanorama {
              right: 0;
            }
      
            .scoutingFeedSoccer {
              width: 146px;
              height: 175px;
              top: 0;
            }
      
            .fullField {
              width: 100%;
              height: 175px;
              top: 0;
            }
      
            .field-camera {
              width: 11px;
              height: 11px;
              flex-shrink: 0;
            }
          }
      
          .content-infoText {
            align-self: stretch;
            color: var(--neutral-n-400, #999);
            /* Paragraph/P-XS - Regular */
            font-family: Nekst;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16.8px; /* 140% */
          }
        }
      }
    }
    
    .cameraMode-boxActive {
      border: 1px solid var(--neutral-n-100, #F6F6F6);
      background: var(--neutral-n-50, rgba(246, 246, 246, 0.16));
    }
    
  }

  
  .eventStep-connection {
    display: flex;
    padding: 24px;
    flex-direction: row;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    background: var(--neutral-n-700, #201D20);
    padding-bottom: 34px;

    .activeBox-header {
      display: flex;
      flex-direction: row;
      display: flex;
      align-items: flex-start;
      align-self: stretch;
    }

    .form-leftText {
      flex: 1 0 0;
      color: var(--neutral-n-100, #F6F6F6);
      /* Paragraph/P-S - Medium */
      font-family: Nekst;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 19.6px; /* 140% */
    }

    .mbsc-input:last-child {
      width: 140px !important;
    }
  }

  .eventStep-toggle {
    display: flex;
    padding-top: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    .toggle-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;

      .bar-title {
        display: flex;
        align-items: center;

        color: var(--neutral-n-100, #F6F6F6);
        /* Paragraph/P-S - Medium */
        font-family: Nekst;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 19.6px; /* 140% */
      }
    }
  }

  .mbsc-input {
    margin: -6px 0 !important;
    width: 100% !important;

    .mbsc-label {
      color: var(--neutral-n-100, #F6F6F6) !important;
      /* Paragraph/P-S - Medium */
      font-family: Nekst!important;
      font-size: 14px!important;
      font-style: normal!important;
      font-weight: 500!important;
      line-height: 19.6px!important; /* 140% */
      text-transform: none !important;
    }
  }

  .mbsc-input-wrap {
    margin-top: 8px !important;
  }
}

@media screen and (max-width: $breakpoints-mobile) {
  .main-eventStep  {

    .eventStep-form {
      padding: 12px;

      .form-eventType {
        align-self: stretch !important;
        flex-direction: column;
        align-items: stretch !important;

        .eventType-tagsContainer {
          margin-bottom: 0px;

          .eventType-tagsLabel {
            margin-top: 0px;
            margin-bottom: 6px;
          }
        }
      }
    }

    .eventStep-activeBox {
      padding: 12px;

      .wrapper-cameraMode {
        justify-content: center;
      }
    }
  } 
}

